import React from 'react';
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {CButton, CFormSelect} from "@coreui/react-pro";
import DataNotFound from "../../../../components/DataNotFound";
import QuestionnaireReportTable from "./QuestionnaireReportTable";
import {useReportContext} from "../../../../context/admin/ReportProvider";
import AssessmentReportTable from "./AssessmentReportTable";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {removeFields} from "../../../../constant";

function MainReport() {
    const {
        cultureFitData,
        personalityData,
        softSkillsData,
        handleSelectUser,
        quizType,
        page,
        data,
        isLoading,
        isError,
        setPage,
        usersData,
        setLimit
    } = useReportContext()
    const csvConfig = mkConfig({ useKeysAsHeaders: true });

    const exportUsersData = () => {
        const users_data = usersData?.data?.data?.items

        // Array of fields to remove
        const fieldsToRemove = ["id", "userId", "null", "nullPercent", "user", "quizId"];

        users_data.forEach(item => {
            item.firstName = item?.user?.firstName
            item.lastName = item?.user?.lastName
            item.otherName = item?.user?.otherName
            item.email = item?.user?.email
            item.phoneNumber = item?.user?.phoneNumber?.toString()
            item.stateOfOrigin = item?.user?.profile?.stateOfOrigin
            item.gender = item?.user?.profile?.gender
            removeFields(item, fieldsToRemove);
        });

        const csv = generateCsv(csvConfig)(users_data);

        // generateCsv
        download(csvConfig)(csv);
    }

    return (
        <>
            <div className="d-flex flex-row justify-content-end me-1">
                <button className="btn btn-sm btn-primary text-white" onClick={exportUsersData}>Export Users Data</button>
            </div>
            <div className="pb-1">
                <div className="mx-1">
                    {
                        (quizType === "Personality Test" || quizType === "Culture Fit Test" || quizType === "Soft Skills Test") ?
                            <QuestionnaireReportTable
                                cultureFitData={cultureFitData}
                                personalityData={personalityData}
                                softSkillsData={softSkillsData}
                                handleSelectUser={handleSelectUser}
                                quizType={quizType}
                            />
                            : <AssessmentReportTable
                                quizType={quizType}
                                handleSelectUser={handleSelectUser}
                                usersData={usersData}
                            />

                    }
                    {
                        usersData?.data?.data?.items.length > 0 &&
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-flex flex-row gap-3"}>
                                <div className={"m-2"}>Show</div>
                                <CFormSelect
                                    aria-label="Default select example"
                                    options={[
                                        {label: '10', value: '10'},
                                        {label: '25', value: '25'},
                                        {label: '100', value: '100'},
                                        {label: '200', value: '200'}
                                    ]}
                                    onChange={(e) => setLimit(e.target.value)}
                                />
                                <div className={"m-2"}>Entries</div>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                         className={`mx-3 text-white py-1 pb-2 px-3`}>
                                    <BiLeftArrow/>
                                </CButton>

                                <CButton
                                    disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                                    onClick={() => setPage(page + 1)} className="mx-3 text-white py-1 pb-2 px-3">
                                    <BiRightArrow/>
                                </CButton>
                            </div>
                        </div>
                    }

                </div>

                {
                    (quizType !== "Personality Test" && quizType !== "Culture Fit Test" && quizType !== "Soft Skills Test" && usersData?.data?.data?.meta?.totalItems === 0) &&
                    <DataNotFound/>
                }
                {
                    (quizType === "Personality Test" && personalityData?.data?.data?.items?.length === 0) &&
                    <DataNotFound/>
                }
                {
                    (quizType === "Culture Fit Test" && cultureFitData?.data?.data?.items?.length === 0) &&
                    <DataNotFound/>}
                {
                    (quizType === "Soft Skills Test" && softSkillsData?.data?.data?.items?.length === 0) &&
                    <DataNotFound/>
                }
            </div>

        </>
    );
}

export default MainReport;