import React, {useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import Education from "../../../assets/icons/Education.svg";
import InfoTitle from "./InfoTitle";
import {fail_notify} from "../../../constant";
import {CButton, CCol, CForm, CLoadingButton, CRow} from "@coreui/react-pro";
import LanguagesCard from "./LanguagesCard";
import CVTextEditor from "../CVTextEditor";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const Languages = () => {

    const {status, setStatus, setLanguagesData, cvData, setSaveCVForJob} = useCVBuilderContext()
    const [languages, setLanguages] = useState(cvData?.data?.data?.data[0]?.languages || []);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();
    const [editorState, setEditorState] = useState(languages[0] ?? '');
    const [hasLanguages, setHasLanguages] = useState(false);

    const {
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: {errors},
    } = useForm({
        defaultValues: {
            languages: " "
        }

    });

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        setLanguagesData(watchAllFields);
    }, [watchAllFields])

    useEffect(() => {
        setValue("languages", [editorState]);
    }, [editorState])

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0].languages = getValues().languages;
            setValue("languages", [editorState]);
            if (editorState.length > 0) {
                setHasLanguages(false);
            } else {
                setHasLanguages(true);
            }
            setSaveCVForJob(false);
        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (!editorState) {
            setHasLanguages(true)
        }
    }, [])

    const onSubmit = (data) => {
        const formattedData = parseAndFormatHTML(data?.languages)
        if (formattedData) {
            mutate(data)
        }

    }

    const deleteLanguages = () => {
        setEditorState([]);
        mutate({languages: []})
    }

    return (<>
        {
            status === 7 &&
            <>
                <InfoTitle title={"Languages"} icon={Education}/>
                {
                    !hasLanguages &&
                    <>
                        <LanguagesCard
                            hasLanguages={hasLanguages}
                            setHasLanguages={setHasLanguages}
                            deleteLanguages={deleteLanguages}
                            languages={editorState}
                        />
                        <center
                            className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-5">
                            <CButton
                                onClick={() => {
                                    setStatus(6)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Back
                            </CButton>
                        </center>
                    </>
                }
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    {
                        hasLanguages &&
                        <>
                            <div className="d-flex">
                                <div className="w-100">
                                    <CRow className="g-3 py-2">
                                        <CCol>
                                            <div>
                                                <CVTextEditor
                                                    editorState={editorState}
                                                    setEditorState={setEditorState}
                                                />
                                            </div>
                                        </CCol>

                                    </CRow>
                                </div>
                            </div>
                            <center
                                className="d-flex flex-column flex-sm-row align-items-center justify-content-center ">
                                <CButton
                                    onClick={() => {
                                        setHasLanguages(!hasLanguages)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Back
                                </CButton>
                                <CLoadingButton loading={isLoading} disabled={isLoading} type="submit"
                                                className="py-3 cv-btn m-1 text-white">
                                    SAVE
                                </CLoadingButton>
                            </center>
                        </>
                    }
                </CForm>
            </>
        }
    </>)
}
export default Languages

function parseAndFormatHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim() + "\n").join(' ');
    return formattedText;
}
