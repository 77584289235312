import React, {useEffect, useState} from 'react'
import {CCol, CRow} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import {
    useGetAllStates,
    useGetInstitutions,
    useGetUserByUUID,
} from "../../hooks/user/userApiHooks";
import ShowLoading from "../ShowLoading";
import filterPreloadedUserData from "../../constant/userPreloadedData";
import {useAppContext} from "../../context/user/UserAppProvider";
import BasicInformation from "../profile-update/BasicInformation";
import {BiArrowBack} from "react-icons/bi";
import JobExperience from "../profile-update/JobExperience";
import {AiFillCheckCircle} from "react-icons/ai";
import Education from "../profile-update/Education";
import Certification from "../profile-update/Certification";
import PortfolioProject from "../profile-update/PortfolioProject";
import PersonalSummary from "../profile-update/PersonalSummary";

const UserProfileUpdate = () => {

    const navigate = useNavigate()

    const {user} = useAppContext()
    const {isLoading: isLoadingUser, data: fetchedUser} = useGetUserByUUID(user?.data?.userUuid)
    const [userData, setUserData] = useState(filterPreloadedUserData(fetchedUser?.data?.data))
    const [tab, setTab] = useState(1)
    const [institutionOptions, setInstitutionOptions] = useState([])
    const states = useGetAllStates()
    const {data: institutions, isLoading: isLoadingInstitutions} = useGetInstitutions()

    useEffect(() => {
        setUserData(filterPreloadedUserData(fetchedUser?.data?.data))
        setInstitutionOptions(institutions?.data?.data?.map(({name}, index) => (
            {
                value: index,
                text: name,
            }
        )))
    }, [fetchedUser, isLoadingUser, institutions])

    if (isLoadingUser || isLoadingInstitutions) {
        return <ShowLoading/>
    }

    return (
        <>
            {(userData && institutionOptions) &&
                <CRow className="">
                    <CCol sm={12} md={3}>
                        <div className="d-flex align-items-center mb-1 w-100">
                            <p><span className="cursor-pointer" onClick={() => {
                                navigate(-1)
                            }}><BiArrowBack/>Go Back</span></p>
                        </div>
                        <div className="bg-white w-100 px-3 py-3 rounded-1">
                            <div
                                className={`${tab >= 1 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(1)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.address ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Basic Information
                            </div>
                            <div
                                className={`${tab >= 2 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(2)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.workExperience.length > 0 ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Job Experience
                            </div>
                            <div
                                className={`${tab >= 3 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(3)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.educations.length > 0 ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Education
                            </div>
                            <div
                                className={`${tab >= 4 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(4)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.certificates.length > 0 ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Certifications
                            </div>
                            <div
                                className={`${tab >= 5 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(5)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.summary ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Summary, Skills, & Preference
                            </div>
                            <div
                                className={`${tab >= 6 ? "bg-green-tinted text-white" : "text-black-50 border border-2"} my-3 rounded-1 p-2 cursor-pointer`}
                                onClick={() => {
                                    setTab(6)
                                }}>
                                <AiFillCheckCircle color="#1FAB89"
                                                   className={`${userData?.projects.length > 0 ? "bg-white" : "bg-primary"} rounded rounded-4`}
                                                   size={20}/> Project/Portfolio Links
                            </div>
                        </div>
                    </CCol>
                    <CCol sm={12} md={9}>
                        <div className="mb-1 fw-bolder">
                            {tab === 1 && "BASIC INFORMATION"}
                            {tab === 2 && "JOB EXPERIENCE"}
                            {tab === 3 && "EDUCATION"}
                            {tab === 4 && "CERTIFICATIONS"}
                            {tab === 5 && "PERSONAL SUMMARY & SKILLS"}
                            {tab === 6 && "PROJECT/PORTFOLIO LINKS"}
                        </div>
                        {tab === 1 && <BasicInformation userData={userData} states={states} tab={tab} setTab={setTab}/>}
                        {tab === 2 && <JobExperience userData={userData} tab={tab} setTab={setTab}/>}
                        {tab === 3 && <Education userData={userData} institutionOptions={[...institutionOptions, {
                            value: 1000,
                            text: 'Others'
                        }]} tab={tab} setTab={setTab}/>}
                        {tab === 4 && <Certification userData={userData} tab={tab} setTab={setTab}/>}
                        {tab === 5 && <PersonalSummary userData={userData} tab={tab} setTab={setTab}/>}
                        {tab === 6 && <PortfolioProject userData={userData}/>}
                    </CCol>
                </CRow>
            }
        </>
    )
}

export default UserProfileUpdate
