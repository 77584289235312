import React from "react";
import {CCol, CTooltip} from "@coreui/react-pro";

const UserProfileTag = ({tag, tips}) => {
    return (
        <>
            {
                tips ?
                    <CTooltip content={tips} placement="top">
                        <CCol md={6} className="my-2">
                            <div className="bg-grey">
                                <h6 className="p-3 m-0"><strong>{tag}</strong></h6>
                            </div>
                        </CCol>
                        {/*<div*/}
                        {/*    className="skills_box  user-tag cursor-pointer text-truncate  d-flex justify-content-center align-items-center  text-primary py-2 px-1 "*/}
                        {/*>*/}
                        {/*    <small className="text-truncate px-1 ">{tag}</small>*/}
                        {/*</div>*/}

                    </CTooltip>
                    : <CCol md={6} className="my-2">
                        <div className="bg-grey">
                            <h6 className="p-3 m-0"><strong>{tag}</strong></h6>
                        </div>
                    </CCol>
            }
        </>
    )
}

export default UserProfileTag
