import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import QuestionnaireCard from "./QuestionnaireCard";
import {useGetAllSkillSetQuery} from "../../../hooks/user/userApiHooks";
import {useAppContext} from "../../../context/user/UserAppProvider";
import ShowLoading from "../../../components/ShowLoading";

function TakeQuestionnaires(props) {
    const {user} = useAppContext()
    const {data, isSuccess, isLoading} = useGetAllSkillSetQuery(user?.data?.id)
    const [skills, setSkills] = useState([])

    useEffect(() => {
        const skillSets = data?.data?.data?.skills
        if (skillSets) {
            setSkills(skillSets)
        }
    }, [data?.data?.data])

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer className="w-100 min-vh-100 align-items-center px-4 mb-5 pb-5">
            <CRow>
                {
                    skills.map((skill, index) =>
                        <CCol key={index} sm={4} className="mb-4 mt-2">
                            <QuestionnaireCard title={skill?.title} data={data}/>
                        </CCol>)
                }
            </CRow>
        </CContainer>

    );
}

export default TakeQuestionnaires;
