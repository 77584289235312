import React from 'react'

function CVProgress({number, completed, title}) {


    return (
        <>

            {completed ? <span className="d-flex gap-1 mx-1">
                    <button className="text-white bg-primary cv-progress border-0 px-2">
                        {number}
                    </button>
                    <small className="text-primary d-none d-xl-block block mt-1">{title}</small>
                    </span> : <span className="d-flex gap-1 mx-1">
                    <button style={{border: "solid 1px #c0bfbf"}} className="text-black-50 bg-transparent cv-progress px-2">
                        {number}
                    </button>
                    <small className="text-black-50 d-none d-xl-block mt-1">{title}</small>
                    </span>}


        </>
    )
}

export default CVProgress
