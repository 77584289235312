import {
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {fail_notify, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {projectsSchema} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useUpdateUserQuery} from "../../hooks/user/userApiHooks";
import {AiFillPlusSquare, AiOutlineDelete} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

const PortfolioProject = ({userData}) => {
    const navigate = useNavigate()
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()
    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            navigate('/user/profile')
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, error, isSuccess])
    const [formData, setFormData] = useState(userData?.projects);
    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const updatedData = [...formData];
        updatedData[index][name] = value;
        setFormData(updatedData);
        setValue('projects', updatedData)
    };

    const handleAdd = () => {
        const newExperience = {
            projectName: "",
            projectUrl: "",
        };
        setFormData([...formData, newExperience]);
        setValue('projects', [...formData, newExperience])
    };

    const handleRemove = (index) => {
        const updatedData = [...formData];
        updatedData.splice(index, 1);
        setFormData(updatedData);
        setValue('projects', updatedData)
    };

    const {
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(projectsSchema),
        defaultValues: { projects: formData },
    })

    const handleFormSubmit = (data) => {
         userData.projects = data.projects
        mutate(userData)
    }

    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="bg-white w-100 px-4 py-3 rounded-1">
                {formData.map((experience, index) => (
                    <div key={index}>
                        <div onClick={() => handleRemove(index)}
                             className="w-100 d-flex justify-content-end align-items-center">
                           <span>
                               <AiOutlineDelete onClick={() => handleRemove(index)} className="cursor-pointer" color="red" size={16}/>
                               <small onClick={() => handleRemove(index)} className=" text-danger cursor-pointer px-1">Delete</small>
                           </span>
                        </div>

                        <CRow>
                            <CCol className="my-1">
                                <CFormLabel className="m-1 label">Project/Portfolio Name</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="projectName"
                                    placeholder="Worknation"
                                    name="projectName"
                                    value={experience.projectName}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.projects?.[index]?.projectName &&
                                    <small className="text-danger">{errors.projects[index].projectName.message}</small>
                                }
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="my-1">
                                <CFormLabel className="m-1 label">Project/Portfolio URL</CFormLabel>
                                <CFormInput
                                    type="url"
                                    id="projectUrl"
                                    placeholder="www.worknation.com"
                                    name="projectUrl"
                                    value={experience.projectUrl}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.projects?.[index]?.projectUrl &&
                                    <small className="text-danger">{errors.projects[index].projectUrl.message}</small>
                                }
                            </CCol>
                        </CRow>
                        <hr/>
                    </div>
                ))}

                <div onClick={handleAdd} className="w-100 d-flex justify-content-center align-items-center">
                    <AiFillPlusSquare className="cursor-pointer" color="#1FAB89" size={30}/>
                    <p className="small-text text-primary cursor-pointer px-1 text-decoration-underline">ADD PROJECT/PORTFOLIO</p>
                </div>
            </div>

            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}
                    >
                        SAVE & GO TO PROFILE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default PortfolioProject
