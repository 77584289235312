import React, {useEffect} from 'react'
// import {Outlet} from "react-router-dom";
import {CImage} from '@coreui/react-pro'
import {PropTypes} from 'prop-types'

const EntryFormLayoutWithText = ({image, form}) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    return (
        <div className="d-flex flex-column flex-md-row w-100 h-100 my-5 py-5">
            <div className="d-flex flex-column w-100 justify-content-center align-items-center">
                <div className="d-flex flex-column justify-content-center align-items-center w-75">
                    <CImage src={image} alt="Hire" className="w-75"/>
                </div>
                <div className="">
                    <h2 className="text-center text-primary"> You Are a Few Key Strokes </h2>
                    <h5 className="text-center text-primary"> From Hiring and Training Your Next Employee</h5>
                </div>
            </div>
            <div className="d-flex flex-column py-1 w-100 justify-content-center align-items-center">


                {form}

            </div>

        </div>

    )
}

EntryFormLayoutWithText.propTypes = {
    image: PropTypes.string,
    form: PropTypes.element,
}

export default EntryFormLayoutWithText
