import React from 'react';
import {CModal, CModalBody} from "@coreui/react-pro";
import {AiFillCloseCircle} from "react-icons/ai";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

function ViewResumeModal({setVisible, pdfFile}) {
    return (
        <>
            <CModal size="lg" visible={true} onClose={() => setVisible(false)}>
                <div className={'d-flex flex-row justify-content-between px-5 py-3 border-b-grey'}>
                    <div className={'d-flex flex-row justify-content-end gap-3 '}>
                        <AiFillCloseCircle size={30} onClick={() => setVisible(false)}/>
                    </div>
                </div>
                <CModalBody>
                    <Document file={pdfFile?.cvUrl}>
                        <Page pageNumber={1}/>
                    </Document>
                </CModalBody>
            </CModal>
        </>
    );
}

export default ViewResumeModal;