import React from 'react'
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import ResumeImage1 from "../../assets/images/resume-template-1.png"
import ResumeImage2 from "../../assets/images/resume-template-2.png"
import ResumeImage3 from "../../assets/images/resume-template-3.png"

const templateImages = [{design:ResumeImage1, title:'scattered'}, {design: ResumeImage2, title: 'vertical'}, {design:ResumeImage3, title: 'horizontal'}]

const ResumeTemplates = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className="my-1">
                <p className="text-start">
                    <span className="cursor-pointer" onClick={() => {
                        navigate(-1)
                    }}><BiArrowBack/>Go Back</span>
                </p>
            </div>
            <CContainer className="shadow-lg min-vh-100 mb-5">
                <CRow>
                    <CCol md={12} className="text-center p-5">
                        <h4 className="pt-3">CAREER-CHANGING RESUME TEMPLATES</h4>
                        <small className="px-5">
                            Each resume template is precisely crafted and adheres to the
                            precise &quot;resume guidelines&quot; that hiring managers need.
                            With WorkNation customised resume templates, you will stand out and land a job
                            faster.
                        </small>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={12} className="d-flex flex-row justify-content-between align-items-center px-5 pb-5">
                        <button className="btn text-black-50 px-5">All Templates</button>
                        <button className="btn text-black-50 px-5">Creative</button>
                        <button className="btn text-black-50 px-5">Simple</button>
                        <button className="btn btn-primary text-white px-5">Professional</button>
                    </CCol>
                </CRow>
                <CRow>
                    {
                        templateImages.map((template, index) => (
                            <CCol
                                key={index}
                                md={4}
                                className="d-flex flex-row justify-content-center align-items-center py-5">
                                <CImage src={template.design} alt="ScatteredGrayTemplate" className="w-75"/>
                                <button
                                    className={`btn btn-primary position-absolute text-white px-3 py-2 shadow-lg`}
                                    onClick={() => {
                                        navigate(`/user/select-options`, {
                                            replace: true,
                                            state: {title: template.title}
                                        })
                                    }}
                                >
                                    Use this template
                                </button>
                            </CCol>
                        ))
                    }
                </CRow>
            </CContainer>
        </>
    )
}

export default ResumeTemplates