import React, {useEffect} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useGetApplicantBookings} from "../../hooks/user/userApiHooks";
import DataNotFound from "../../components/DataNotFound";
import ShowLoading from "../../components/ShowLoading";
import {fail_notify} from "../../constant";

const ApplicantBookings = () => {
    const {user} = useAppContext()
    const {data, isLoading, isError, error} = useGetApplicantBookings(user?.data?.userUuid)

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [error?.response?.data?.message, isError]);
    
    if (isLoading) {
        return <ShowLoading/>
    }
    
    return (
        <CContainer>
            <div>
                <h2 className="text-2">Applicant Interview Bookings</h2>
                <hr/>
            </div>
            <CRow>
                <CCol md={12}>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Job Title</th>
                            <th>Date/Time</th>
                            <th>Attendees</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.data?.data?.items?.map(({user, job, interviewDate, interviewTime, emailCC, interviewLink}, index) => {
                            return <tr key={index}>
                                <td className="td-single-line fw-bolder">{job?.stakeholder?.name}</td>
                                <td>{job?.stakeholder?.email}</td>
                                <td>{job?.title}</td>
                                <td>{interviewDate} - {interviewTime}</td>
                                <td>{
                                    emailCC?.map((email, index) => <span className="p-2 border" key={index}>{email}</span>)
                                }</td>
                                <td>
                                    <a href={interviewLink} target='_blank' rel="noreferrer">
                                        <button className="btn btn-sm btn-primary text-white">Join</button>
                                    </a>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    {data?.data?.data?.items?.length === 0 && <DataNotFound/>}
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default ApplicantBookings;