import {useCallback, useEffect, useMemo, useState} from 'react';
import {useCheckQuestionnaireStatusQuery} from "./userApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import {fail_notify} from "../../constant";

//Function to re-Arrange the Questions according to their Subheading
function groupQuestionsBySubheading(questions) {
    return questions.reduce((groupedQuestions, question) => {
        const subHeading = question.subHeading;
        groupedQuestions[subHeading] = groupedQuestions[subHeading] || [];
        groupedQuestions[subHeading].push(question);
        return groupedQuestions;
    }, {});
}

const useQuestionnaire = (data) => {
    const {user} = useAppContext()
    //Set the Number of Questions for each Page
    const [page] = useState(5)
    //Set the Step Size
    const [step, setStep] = useState(1)
    //Initialize the next number of Questions
    const [next, setNext] = useState(page)
    //Set Previous State
    let [prev, setPrev] = useState(0)
    //Initialize Disable State for Prev Button
    const [disablePrev, setDisablePrev] = useState(false)
    //Initialize Disable State for Next Button
    const [disableNext, setDisableNext] = useState(false)
    //Initialize State to add restructure the questionnaires to account for user answer choice
    const [questions, setQuestions] = useState([])
    //Set Questionnaire Error
    const [questionnaireError, setQuestionnaireError] = useState("")

    //Extract the Questions from the prop data
    const questionsArray = data?.data?.data?.questions

    //Initialize the section number
    const [sectionNumber, setSectionNumber] = useState(1)

    //Initialization of section titles
    const [currentSection, setCurrentSection] = useState('')

    //State to hold the re-arranged questions according to their sections
    const [groupedQuestions, setGroupedQuestions] = useState({})

    //initialization of total number of sections per questionnaire/test
    const [sections, SetSections] = useState(0)

    // Initialization of State to hold answers of each section
    const [allSectionAnswers, setAllSectionAnswers] = useState([])

    useEffect(() => {
        const groupedQuestions = groupQuestionsBySubheading(questionsArray)
        const questionnaireSections = Object.keys(groupedQuestions)
        SetSections(questionnaireSections?.length)
        setCurrentSection(questionnaireSections[sectionNumber - 1])
        setGroupedQuestions(groupedQuestions)
    }, [data, sections, sectionNumber, currentSection])

    //UntimedQuestionnaireLayout Scroller
    window.addEventListener('scroll', e => {
        const el = document?.getElementById('jsScroll');
        if (window.scrollY > 200) {
            el?.classList?.add('visible');
        } else {
            el?.classList?.remove('visible');
        }
    });

    //Scroll back to top after every next or prev click
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    //Check if User Already Answered Questionnaire
    const {
        data: questData,
    } = useCheckQuestionnaireStatusQuery(user?.data?.id)

    //Re-arrangement of Questions format to be rendered by the UI
    useEffect(() => {
        setQuestions(() =>
            groupedQuestions[currentSection]?.map((q, index) => ({
                i: index + 1,
                id: q.id,
                question: q.question,
                isMultipleChoice: q.isMultipleChoice,
                answer: q.isMultipleChoice ? [] : null,
                options: [
                    ...q.options?.map((op) => ({
                        id: op.id,
                        text: op.text,
                    })),
                ],
                selections: q?.selections && [...q?.selections],
            })),
        )
    }, [data, groupedQuestions, currentSection])

    //function to handle the of update answered Questions
    const submitAnswer = useCallback(
        (id, answer) => {
            const updatedQ = questions.map((q) => {
                if (q.id === id) {
                    if (q.isMultipleChoice) {
                        if (q.answer.includes(answer)) {
                            let arraysChecked = q.answer.filter((a) => a !== answer);
                            return {...q, answer: [...arraysChecked]};
                        }
                        return {...q, answer: [...q.answer, answer]};
                    }
                    return {...q, answer}
                } else return q
            })
            setQuestions(() => updatedQ)

        }, [questions])

    /*
    Calculate the total number of Steps for
    every section of the questionnaire/test
    */

    const totalSteps = useMemo(() => {
        return Math.ceil(questions?.length / page)
    }, [questions, page, currentSection])

    /*
    Slicing of the Questions according to the
    previous value and next value
    */
    const questionsChunk = questions?.slice(prev, next)

    /*
    Use effect to handle the disablement
    of the next and previous button if
    pending on the step count
    e.g. if step = 1 the previous button will be disabled
    */
    useEffect(() => {
        if (step >= 1) {
            if (step > 1) {
                setDisablePrev(false)
                setDisableNext(false)
            }
            if (step === 1) {
                setDisablePrev(true)
                setDisableNext(false)
            }

        } else {
            setDisablePrev(true)
        }
        if (step === totalSteps) {
            setDisableNext(true)
        }
    }, [step, totalSteps])

    //Function to validate if all the Questions are answered
    const confirmAnswers = () => {
        return questionsChunk.filter((q) => q.answer !== null)
    }

    const questionnaireChecker = (quizTitle) => {
        if (quizTitle) {
            return questData?.data?.data.includes(quizTitle)
        } else {
            return questData?.data?.data.length
        }
    }

    // Function to control the next button without validation
    const handleNext = async () => {
        setQuestionnaireError("")
        scrollToTop()
        if (step < totalSteps) {
            setNext(next + 5)
            setPrev(prev + 5)
            setStep(step + 1)
            setDisableNext(false)
            setDisablePrev(false)
        } else {

            setSectionNumber(prev => prev + 1)
            setDisableNext(false)
            setPrev(0)
            setNext(5)
            setStep(1)
            if (sectionNumber === sections) {
                setDisableNext(true)
            }

        }
    }
    // Function to control the next button with validation
    const handleNextWithValidation = async () => {
        setQuestionnaireError("")
        /*
        Check using the confirm answer function if the length of answered
        Questions equals the total slice of questions else raise error
        **/
        if (confirmAnswers().length === questionsChunk.length) {
            scrollToTop()
            if (step < totalSteps) {
                setNext(next + 5)
                setPrev(prev + 5)
                setStep(step + 1)
                setDisableNext(false)
                setDisablePrev(false)
            } else {
                //Keeping track of answered Questions as user transit from section to section
                setAllSectionAnswers((prev) => [...prev, ...questions.map((q) => q.answer)])
                //Increasing the Section Number count
                setSectionNumber(prev => prev + 1)
                setDisableNext(false)
                setPrev(0)
                setNext(5)
                setStep(1)
                if (sectionNumber === sections) {
                    setDisableNext(true)
                }
            }
        } else {
            fail_notify('One or More Fields are not Answered.')
            setQuestionnaireError("One or More Fields are not Answered.")
        }

    }

    //Function to handle the Previous Button
    const handlePrev = () => {
        if (step > 1) {
            setNext(next - 5)
            setPrev(prev - 5)
            setStep(step - 1)
        }

    }

    return {
        questionnaireError,
        totalSteps,
        step,
        questionsChunk,
        submitAnswer,
        disablePrev,
        handleNext,
        handleNextWithValidation,
        disableNext,
        handlePrev,
        confirmAnswers,
        setQuestionnaireError,
        questionnaireChecker,
        sections,
        sectionNumber,
        currentSection,
        allSectionAnswers
    }
}
export default useQuestionnaire;