const useSkillSetStatus = (data) => {


    // useEffect(() => {
    //     if (isSuccess) {
    //         const skills = data?.data?.data
    //         if (skills.length > 0) {
    //             setChosenSkillSet(true)
    //             setUserSkills(skills)
    //         }
    //     }
    // }, [isSuccess, userSkills])

    const hasSkillSetProfile = () => {
        const skills = data?.data?.data?.skills
        if (skills?.length > 0) {
            return true
        }
    }

    return {
        hasSkillSetProfile
    }

}
export default useSkillSetStatus;