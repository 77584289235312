import {CButton, CModal, CModalBody, CModalTitle} from "@coreui/react-pro";
import React from "react";
import excitement from "../../assets/icons/excited.jpg";

const GeneralQuestionnaireWelcomeModal = ({visible, setVisible, title, text}) => {
    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalBody className="text-center p-5">
                    <img src={excitement} height={70} alt="MOG"/>
                    <CModalTitle className="text-title mb-3"> {title} </CModalTitle>
                    <p className="pb-3">{text}</p>
                    <div className="d-flex flex-row justify-content-center align-items-center pt-2">
                        <CButton
                            color="primary"
                             onClick={() => {setVisible(false)}}
                             className="text-white px-5 mx-1"
                        >
                            Proceed To Questionnaire
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </>
    )
}

export default GeneralQuestionnaireWelcomeModal
