import React, {useEffect, useState} from 'react'
import Table from "../../components/tables/table";
import {useGetEmployerInvoice} from "../../hooks/employer/employerApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useGetEmployerByUUID} from "../../hooks/user/userApiHooks";
import DataNotFound from "../../components/DataNotFound";
import {fail_notify} from "../../constant";
import {
    CButton, CCol,
    CForm, CFormFeedback,
    CFormInput,
    CFormSelect, CLoadingButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle, CRow
} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import ShowLoading from "../../components/ShowLoading";
import {FaEllipsisV} from "react-icons/fa";
import ParkwayWalletVerification from "../../components/User/ParkwayWalletVerification";

const Wallet = () => {
    /*
        const {user} = useAppContext()
        const [employerData, setEmployerData] = useState("")
        const [page, setPage] = useState(1)
        const [limit, setLimit] = useState(10)
        const employer = useGetEmployerByUUID(user?.data?.stakeholderUuid)
        const phoneNum = 0 + String(employerData?.phoneNumber || "").slice(-10);
        const {isSuccess, isLoading, error, isError, data} = useGetEmployerInvoice(user?.data?.email, phoneNum, page, limit)
        useEffect(() => {
            if (employer?.isSuccess) {
                setEmployerData(employer?.data?.data?.data)
            }
            if (employer?.isError) {
                fail_notify(employer?.error?.response?.data?.message)
            }
        }, [employer?.isSuccess, employer?.data, employer?.isLoading, employer?.isSuccess])

        useEffect(() => {
            if (isError) {
                fail_notify(error?.response?.data?.message)
            }
        }, [isSuccess, data, error, isError, isLoading])

    */
    const [visible, setVisible] = useState(false)
    const {userId: id, user} = useAppContext()

    const headers = [
        {name: "Service Type"},
        {name: "Amount", sort: false},
        {name: "Status", sort: false},
        {name: "Date", sort: false},
        {name: "Action"}]

    const items = [
        {
            serviceType: "Purchase",
            amount: "10000",
            status: "Successful",
            date: "20/01/2024",
        },
        {
            serviceType: "Purchase",
            amount: "10000",
            status: "Successful",
            date: "20/01/2024",
        },
        {
            serviceType: "Purchase",
            amount: "10000",
            status: "Successful",
            date: "20/01/2024",
        },
        {
            serviceType: "Purchase",
            amount: "10000",
            status: "Successful",
            date: "20/01/2024",
        },
        {
            serviceType: "Purchase",
            amount: "10000",
            status: "Successful",
            date: "20/01/2024",
        },
    ]

    const card = (title, value) => {
        return (
            <div className="bg-white rounded rounded-4 p-4" style={{width: "350px",}}>
                <h5 className="mb-3 text-black-50">{title}</h5>
                <h5 className="fw-bolder">{value}</h5>
            </div>)
    }

    return (
        <>
            {!user?.data?.parkway?.walletAccountVerified || true ?
                <ParkwayWalletVerification/> :
                <div className="">
                    <div className="d-flex justify-content-around justify-content-md-between flex-wrap gap-3">
                        {card("Balance", "₦ 20,000,000")}
                        {card("Wallet ID", "123456789")}
                        {card("Total Amount Spent", "₦ 90,000,000")}
                    </div>
                    <div className="mt-4">Transactions</div>
                    <hr/>
                    {false ? <ShowLoading/> :
                        <>
                            {items?.length > 0 ?
                                <>
                                    <Table headers={headers}>
                                        {items?.map((item, index) => (
                                            <tr className="bg-white border"
                                                key={index}
                                                //onClick={() => setIsAnalysisVisible(true)}
                                            >
                                                <td className="td-single-line fw-medium">{item?.serviceType}</td>
                                                <td className="fw-medium">₦{item?.amount}</td>
                                                <td className="fw-medium">{item?.status}</td>
                                                <td className={`fw-medium`}>{item?.date}</td>
                                                <td className="fw-medium" style={{width: "80px"}}>
                                                    <span className="mt-2 cursor-pointer" onClick={() => {
                                                        setVisible(!visible)
                                                    }}><FaEllipsisV/></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </Table>
                                    {/*<div className={"d-flex justify-content-between pb-3"}>
                                    <div className="d-flex align-items-center gap-1">
                                        <small className={""}>Items per page:</small>

                                        <div><CFormSelect
                                            aria-label="Default select example"
                                            value={limit}
                                            options={[
                                                {label: '5', value: '5'},
                                                {label: '10', value: '10'},
                                                {label: '20', value: '20'},
                                                {label: '50', value: '50'},
                                                {label: '100', value: '100'}
                                            ]}
                                            onChange={(e) => setLimit(e.target.value)}
                                        /></div>
                                    </div>
                                    <div className="d-flex  flex-row justify-content-center align-items-center">
                                        <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                                 className={`mx-3 bg-white text-primary py-1 pb-2 px-3`}>
                                            <BiLeftArrow/>
                                        </CButton>

                                        <CButton
                                            disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                                            onClick={() => setPage(page + 1)}
                                            className="mx-3 bg-white text-primary py-1 pb-2 px-3">
                                            <BiRightArrow/>
                                        </CButton>
                                    </div>
                                </div>*/}
                                </>
                                : <DataNotFound/>}
                        </>}
                </div>
            }
            <CModal
                visible={visible}
                onClose={() => setVisible(false)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setVisible(false)}>
                    <CModalTitle id="LiveDemoExampleLabel"></CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="">
                        <h5 className="fw-bold">Transaction Details</h5>
                        <div className="d-flex flex-column gap-3">
                            <small>Transaction ID: 123456</small>
                            <small>Transaction Type: Purchase</small>
                            <small>Amount : ₦10,000.00</small>
                            <small>Time : 12:30pm</small>
                            <small>Date : Tuesday 24 jan,2023</small>
                            <small>Status : Successful</small>
                            <small>Description : Javascript Course</small>
                        </div>
                        <br/>
                    </div>
                </CModalBody>

            </CModal>
        </>
    )
}

export default Wallet
