import React from 'react'
import {
    CCol,
    CForm,
    CFormLabel, CFormSelect, CImage, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader
} from "@coreui/react-pro";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {format} from "date-fns";
import filter from "../../assets/icons/filter.svg";


const JobFilterModal = (
  {
    visible,
    setVisible,
      setFilter,
      setPage,
  }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,

    } = useForm({})

    const handleJobFilter = (data)=>{
        let filter = '';


        if(data.jobType){
            filter = filter + "&jobType=" + data.jobType
        }
        if(data.from){
            const startDate = new Date(data?.from)

            filter = filter + "&from=" + format(startDate, "YYY-MM-dd")
        }
        if(data.to){
            const endDate = new Date(data?.to)
            filter = filter + "&to=" + format(endDate, "YYY-MM-dd")

        }
        setPage(1)
        setFilter(filter)
        setVisible(false)
    }


  return (
    <>
        <div className="bg-transparent cursor-pointer border-0 p-none"
                 onClick={() => setVisible(true)}>
            <CImage src={filter}/>
        </div>
      <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          Filter Jobs
        </CModalHeader>
        <CModalBody>
          <CForm className="row g-3" onSubmit={handleSubmit(handleJobFilter)}>

            <CCol md={12}>
              <CFormLabel htmlFor="role">Job Type</CFormLabel>
                <CFormSelect
                    aria-label="Job type"
                    id="jobType"
                    {...register('jobType')}
                    onChange={(val) => val.target.value}
                >
                    <option value={null}>

                    </option>
                    <option value={'Full Time'}>Full Time</option>
                    <option value={'Part Time'}>Part Time</option>
                </CFormSelect>
            </CCol>

            <CCol md={6}>
              <CFormLabel htmlFor="from">From</CFormLabel>
                <Controller
                    control={control}
                    name="from"
                    render={({ field }) => (
                        <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            placeholderText={'Select Date'}
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            maxDate={new Date()}
                            isClearable
                            className={`form-control`}
                        />
                    )}
                />
            </CCol>

            <CCol md={6}>
              <CFormLabel htmlFor="to">To</CFormLabel>
                <Controller
                    control={control}
                    name="to"
                    render={({ field }) => (
                        <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            placeholderText={'Select Date'}
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            maxDate={new Date()}
                            isClearable
                            className={`form-control`}
                        />
                    )}
                />
            </CCol>
              <CModalFooter className="d-flex justify-content-center align-items-center">
                  <CLoadingButton type="submit" className="w-100 text-white">Filter</CLoadingButton>
              </CModalFooter>
          </CForm>
        </CModalBody>

      </CModal>
    </>
  )
}

export default JobFilterModal