import {
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {fail_notify, nigeriaStates, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {workExperienceSchema} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useUpdateUserQuery} from "../../hooks/user/userApiHooks";
import {useNavigate} from "react-router-dom";
import {AiFillPlusSquare, AiOutlineDelete} from "react-icons/ai";

const JobExperience = ({userData, tab, setTab}) => {
    const navigate = useNavigate()
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            setTab(tab + 1)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])
    const [formData, setFormData] = useState(userData?.workExperience);

    const handleChange = (event, index) => {
        const { name, value, type, checked } = event.target;
        const updatedData = [...formData];
        if (type === 'checkbox') {
            updatedData[index][name] = checked;
            if(updatedData[index][name]){
                updatedData[index]['endDate'] = '';
            }
        } else {
            updatedData[index][name] = value;
            if(name ==='endDate'){
                updatedData[index]['currentlyWorking'] = false;
            }
        }
        setFormData(updatedData);
        setValue('workExperience', updatedData)
       // triggerValidation('workExperience');
    };

    const handleAddExperience = () => {
        const newExperience = {
            companyName: '',
            jobTitle: '',
            preference: '',
            startDate: '',
            endDate: '',
            location: '',
            description: '',
            currentlyWorking: false,
        };
        setFormData([...formData, newExperience]);
        setValue('workExperience', [...formData, newExperience])

    };
    const handleRemoveExperience = (index) => {
        const updatedData = [...formData];
        updatedData.splice(index, 1);
        setFormData(updatedData);
        setValue('workExperience', updatedData)
    };

    const {
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(workExperienceSchema),
        defaultValues: { workExperience: formData },
    })
    const handleJobExperience = (data) => {
        userData.workExperience = data.workExperience
        mutate(userData)
    }

    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleJobExperience)}>
           <div className="bg-white w-100 px-4 py-3 rounded-1">
               {formData.map((experience, index) => (
                   <div key={index}>
                       <div className="w-100 d-flex justify-content-end align-items-center">
                           <span>
                               <AiOutlineDelete onClick={() => handleRemoveExperience(index)} className="cursor-pointer" color="red" size={16}/>
                               <small onClick={() => handleRemoveExperience(index)} className=" text-danger cursor-pointer px-1">Delete</small>
                           </span>
                       </div>
                       <CRow>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Employer Name</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="employerName"
                                   name="companyName"
                                   placeholder="Outsource Global"
                                   value={experience.companyName}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"
                               />

                               {errors.workExperience?.[index]?.companyName &&
                                   <small className="text-danger">{errors.workExperience[index].companyName.message}</small>
                               }

                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Role</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="role"
                                   name="jobTitle"
                                   placeholder="Front End Developer"
                                   value={experience.jobTitle}
                                   onChange={(e) => handleChange(e, index)}
                                   invalid={!!errors.role}
                                   className="form-control mb-2"

                               />
                               {errors.workExperience?.[index]?.jobTitle &&
                                   <small className="text-danger">{errors.workExperience[index].jobTitle.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Job Type</CFormLabel>
                               <CFormSelect
                                   id="preference"
                                   name="preference"
                                   className="form-control mb-2"
                                   value={experience.preference}
                                   onChange={(e) => handleChange(e, index)}
                                   invalid={!!errors.preference}
                               >
                                   <option value=""></option>
                                   <option value="onsite">Onsite</option>
                                   <option value="remote">Remote</option>
                                   <option value="hybrid">Hybrid</option>

                               </CFormSelect>
                               {errors.workExperience?.[index]?.preference &&
                                   <small className="text-danger">{errors.workExperience[index].preference.message}</small>
                               }
                           </CCol>

                       </CRow>
                       <CRow>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Start Date</CFormLabel>
                               <CFormInput
                                   type="month"
                                   id="startDate"
                                   name="startDate"
                                   value={experience.startDate}
                                   onChange={(e) => handleChange(e, index)}
                                   invalid={!!errors.startDate}
                                   className="form-control mb-2"

                               />
                               {errors.workExperience?.[index]?.startDate &&
                                   <small className="text-danger">{errors.workExperience[index].startDate.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">End date</CFormLabel>
                               <CFormInput
                                   type="month"
                                   id="month"
                                   name="endDate"
                                   value={experience.endDate}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               {errors.workExperience?.[index]?.endDate &&
                                   <small className="text-danger">{errors.workExperience[index].endDate.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Location</CFormLabel>
                               <CFormSelect
                                   id="location"
                                   className="form-control mb-2"
                                   name="location"
                                   value={experience.location}
                                   onChange={(e) => handleChange(e, index)}
                               >
                                   <option value=""></option>
                                   {
                                       nigeriaStates?.map((option, index) => (
                                           <option
                                               value={option}
                                               key={index}
                                           >
                                               {option}
                                           </option>
                                       ))
                                   }
                               </CFormSelect>
                               {errors.workExperience?.[index]?.location &&
                                   <small className="text-danger">{errors.workExperience[index].location.message}</small>
                               }
                           </CCol>

                           <CCol md={12} className="my-1">
                               <CFormCheck id="currentlyWorking"
                                           name="currentlyWorking"
                                           checked={experience.currentlyWorking}
                                           onChange={(e) => handleChange(e, index)}
                                           label="I am currently working in this role"/>
                               {errors.workExperience?.[index]?.currentlyWorking &&
                                   <small className="text-danger">{errors.workExperience[index].currentlyWorking.message}</small>
                               }
                           </CCol>
                       </CRow>

                       <CRow>
                           <CCol md={12} className="my-1">
                               <CFormLabel className="m-1 label">Description</CFormLabel>
                               <CFormTextarea
                                   type="text"
                                   id="description"
                                   className="form-control mb-2"
                                   placeholder="Give a description of your role in the organization"
                                   rows={3}
                                   name="description"
                                   value={experience.description}
                                   onChange={(e) => handleChange(e, index)}
                               >
                               </CFormTextarea>
                               {errors.workExperience?.[index]?.description &&
                                   <small className="text-danger">{errors.workExperience[index].description.message}</small>
                               }
                           </CCol>
                       </CRow>
                       <hr/>
                   </div>
               ))}


                   <div onClick={handleAddExperience} className="w-100 d-flex justify-content-center align-items-center">
                       <AiFillPlusSquare className="cursor-pointer" color="#1FAB89" size={30}/>
                       <p className="small-text text-primary cursor-pointer px-1 text-decoration-underline">ADD JOB EXPERIENCE</p>
                   </div>
           </div>
            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}

                    >
                        SAVE & CONTINUE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default JobExperience
