import React, {useState} from 'react';
import {CRow} from "@coreui/react-pro";
import UploadMyCV from "./UploadMyCV";
import {BiBadgeCheck} from "react-icons/bi";
import {useLocation, useNavigate} from "react-router-dom";

function CreateCvOptions() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [isSelected, setIsSelected] = useState(true)
    const location = useLocation()

    return (
        <CRow className={"w-75 mx-auto"}>
            <div
                className="d-flex flex-row justify-content-center align-items-center mt-15">
                <div className={`select-cv-card ${isSelected ? 'select-cv-card-active' : ''}`}
                     onClick={() => {
                         navigate(`/user/build-resume`, {
                             replace: true,
                             state: {title: location.state.title}
                         })
                     }}
                >
                    <h4>Create a New Resume {isSelected && <BiBadgeCheck className="mb-2"/>}</h4>
                    <p>{`We'll re-format it and fill in your information so you don't have to.`}</p>
                </div>
                <div className={`select-cv-card ${!isSelected ? 'select-cv-card-active' : ''}`}
                     onClick={() => setVisible(true)}>
                    <h4>Upload Your Own Resume {!isSelected && <BiBadgeCheck className="mb-2"/>}</h4>
                    <p>{`We'll re-format it and fill in your information so you don't have to.`}</p>
                </div>
            </div>

            <UploadMyCV visible={visible} setVisible={setVisible}/>
        </CRow>
    );
}

export default CreateCvOptions;