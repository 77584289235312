import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import DefaultBanner from "../../../assets/images/applicant-profile-banner.jpg";
import DummyProfilePic from "../../../assets/images/dummy-profile-pic.png";
import HRIcon from "../../../assets/images/shared-profile-hr.png";
import DummyInstitutionPic from "../../../assets/brand/default-company-logo.png";
import {MdOutlineEdit} from "react-icons/md";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {AiFillCopy} from "react-icons/ai";
import ShowLoading from "../../../components/ShowLoading";
import {fail_notify} from "../../../constant";
import {useGetUserByUUID} from "../../../hooks/user/userApiHooks";
import {getDomain} from "../../../Environment.helpers";

const ApplicantProfile = ({ userData }) => {

    const {userId, user} = useAppContext()
    const [profileLink, setProfileLink] = useState('')
    const [copySuccess, setCopySuccess] = useState('');
    const {data, isLoading, isError, error} = useGetUserByUUID(user?.data?.userUuid)

    useEffect(() => {
        const domain = getDomain();
        setProfileLink(`${domain}/${data?.data?.data?.user?.profileSlugUrl}`)
    }, [data?.data?.data?.user])

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [error?.response?.data?.message, isError])

    if (isLoading) {
        return <ShowLoading/>
    }

    const handleCopyLink = async () => {
        await navigator.clipboard.writeText(profileLink)
        setCopySuccess('Copied!')
        setTimeout(() => {
            setCopySuccess('')
        }, 3000)
    }

    const setDefaultSrc = (e) => {
        e.target.src = DummyProfilePic
    }

    return (
        <CContainer>
            <CRow>
                <div className="border-radius-12 bg-white border-bottom-3 p-0">
                    <CCol md={12} className="profile-banner-pic">
                        <CImage src={DefaultBanner} className="banner"/>
                        <CImage
                            src={userData?.userDP || DummyProfilePic}
                            onError={(e) => setDefaultSrc(e)}
                            id="DP"
                            alt="Profile"
                            className="picture rounded-circle"
                        />
                        {!!userId && <div className="d-flex justify-content-end share-button me-3">
                            <button
                                className="btn btn-primary btn-sm p-2"
                                onClick={handleCopyLink}
                            >
                                <AiFillCopy color={"#FFFFFF"}/>
                                <span className="small-text-gray text-white"> Copy Profile Link</span>
                            </button>
                            <p className="text-primary ms-2">{copySuccess}</p>
                        </div>}
                    </CCol>
                    <CCol md={12} className="">
                        <div className="px-4 mx-4">
                            <h2 className="m-0">{`${userData?.firstName} ${userData?.lastName}`}</h2>
                            <p className="small-text-gray">{userData?.occupation}</p>
                            <p className="mt-2 small-text-gray">
                                {userData?.address ? userData?.address+', ' : ''}
                                {userData?.localGovernmentOfResidence ? userData?.localGovernmentOfResidence+', ' : ''}
                                {userData?.stateOfResidence? userData?.stateOfResidence+', ' : ''}</p>
                        </div>
                        <div className="mx-4 mt-5 px-4">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <h5 className="mb-3">Personal Statement</h5>
                                {!!userId && <MdOutlineEdit className="text-primary mb-3" size={24}/>}
                            </div>
                            <p>{userData?.summary || 'Nil'}</p>
                        </div>
                    </CCol>
                </div>
                <CCol md={12} className="mt-3 mb-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Experience</h5>
                            {!!userId && <MdOutlineEdit className="text-primary mb-3" size={24}/>}
                        </div>
                        {userData?.workExperience?.map(({companyName, description, endDate, jobTitle, location, preference, startDate}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{companyName}</h6>
                                    <p className="small-text-gray">{jobTitle} - {preference}</p>
                                    <p className="small-text-gray">{startDate} - {endDate}</p>
                                    <p className="small-text-gray">{location}</p>
                                    <p className="small-text-gray mt-2">{description}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
                <CCol md={12} className="my-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Education</h5>
                            {!!userId && <MdOutlineEdit className="text-primary mb-3" size={24}/>}
                        </div>
                        {userData?.educations?.map(({degree, description, endDate, fieldOfStudy, grade, location, majors, positionHeld, schoolName, startDate}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{schoolName} - {location}</h6>
                                    <p className="small-text-gray">{degree} - {fieldOfStudy} - {grade}</p>
                                    <p className="small-text-gray">{startDate} - {endDate}</p>
                                    <p className="small-text-gray">Majors: {majors?.map((major, index) => <small className="small-text-gray mx-1" key={index}>{major}</small>)}</p>
                                    <p className="small-text-gray mt-2">{description}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
                <CCol md={12} className="my-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Licenses &amp; Certifications</h5>
                            {!!userId && <MdOutlineEdit className="text-primary mb-3" size={24}/>}
                        </div>
                        {userData?.certificates?.map(({certificateId, certificateTitle, certificateUrl, expiredDate, issuedDate, majors, organization}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{organization}</h6>
                                    <p className="small-text-gray">{certificateTitle}</p>
                                    <p className="small-text-gray">{issuedDate} - {expiredDate}</p>
                                    <p className="small-text-gray">Majors: {majors?.map((major, index) => <small className="small-text-gray mx-1" key={index}>{major}</small>)}</p>
                                    <p className="small-text-gray">ID: {certificateId}</p>
                                    <p className="small-text-gray">URL: {certificateUrl}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default ApplicantProfile