import * as Yup from 'yup'

export const employerDocUpdateSchema = Yup.object().shape({

    regCert: Yup.mixed().typeError('Registration Certificate is required').test("required", "Registration Certificate is required", (value) => {
        //console.log(value)
        return value
    }).test("fileSize", "File must not be more 3MB", (value) => {
        if(value?.size){

        return value?.size <= (3 * 1024 * 1024)
        }
        else {

        return true
        }
    }),

    taxClearance: Yup.mixed().typeError('Logo Required').test("required", "Tax Clearance Certificate is required", (value) => {
        return value
    }).test("fileSize", "Image must not be more 3MB", (value) => {

        if(value?.size){

            return value?.size <= (3 * 1024 * 1024)
        }
        else {

            return true
        }
    }),

})
