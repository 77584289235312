import React from 'react';
import {HiOutlinePencilAlt} from "react-icons/hi";
import {RiDeleteBin6Line} from "react-icons/ri";

function PublicationsCard({setHasPublications, hasPublications, publications, deletePublications}) {
    return (
        <>
            <div
                className="d-flex px-4 py-2 justify-content-between align-content-center rounded shadow-sm-user px-10 mt-4">
                <div className="d-flex">
                    <div>
                        <p>{parseAndFormatHTML(publications)}</p>
                    </div>
                </div>
                <div className={"d-flex gap-3"}>
                    <div className="fw-bold" onClick={() => setHasPublications(!hasPublications)}>
                        <HiOutlinePencilAlt size={16} color={'currentColor'}/>
                    </div>
                    <div className="fw-bold" onClick={() => deletePublications()}>
                        <RiDeleteBin6Line size={16} color={'currentColor'}/>
                    </div>
                </div>

            </div>
        </>
    );
}

export default PublicationsCard;

function parseAndFormatHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim() + "\n").join(' ');
    return formattedText;
}