import * as Yup from 'yup'

export const employerProfileUpdateSchema = Yup.object().shape({
    phoneNumber: Yup.string()
        .typeError('Phone number is required')
        .required('Phone number is required')
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Must be a valid phone number'),
    dateEstablished: Yup.string().typeError('Established Date is required').required(
        'Established Date is required',
    ),

    contactPersonPhoneNumber: Yup.string().typeError('Contact Person Phone Number is required').required('Contact Person Phone Number is required').matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Must be a valid phone number'),
    contactPersonEmail: Yup.string().email('Must be a valid email').required('Email is required'),
    contactPersonFirstName: Yup.string().typeError('Contact Person First Name is required').required('Contact Person First Name is required'),
    contactPersonLastName: Yup.string().typeError('Contact Person Last Name is required').required('Contact Person Last Name is required'),
    address: Yup.string().typeError('Address is required').required('Address is required'),
    state: Yup.string().typeError('State is required').required('State is required'),
    companySize: Yup.number().typeError('Company Size is required').required('Company Size is required'),
    localGovernment: Yup.string().typeError('LGA is required').required('LGA is required'),
    aboutCompany: Yup.string().typeError('About company is required').required('About company is required'),
    industryType: Yup.string().typeError('Industry type is required').required('Industry type is required'),

})
