import React from 'react';
import {CCol, CRow} from "@coreui/react-pro";
import CVTextEditor from "../../CVTextEditor";

function AchievementsForm({setEditorState, editorState}) {
    return (<>
        <div className="w-100">
            <CRow className="g-3 py-2">
                <CCol>
                    <div>
                        <CVTextEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                        />
                    </div>
                </CCol>

            </CRow>
        </div>
    </>);
}

export default AchievementsForm;
