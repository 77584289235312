import * as Yup from 'yup'

export const JobSchema = Yup.object().shape({
    title: Yup.string().required('Job Title Required'),
    numberOfVacancy: Yup.number().required('Number of Vacancy is required')
        .typeError('Number of Vacancy Must be Numeric'),
    jobType: Yup.string().required('Choose Job Type'),

    qualification: Yup.string().required('Education level is required'),
    tags: Yup.array()
        .typeError('Add the job key tag')
        .required('Add the job key tag'),
    closingDate: Yup.string().required('Date of birth is required'),
    location: Yup.string().required('Location is required'),
    //salary: Yup.number().typeError('Salary can only take numbers'),
    levelOfExperience: Yup.string().required('Choose Employment Experience'),

})
