import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import appApiCalls from '../../services/api'

export function useUploadCV() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.uploadUserCV(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Fetch User Cvs'])
            return data
        },
    })
}

export function useDeleteCV() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.deleteUserCV(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Fetch User Cvs'])
            return data
        },
    })
}

export function useApplyForScholarship() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.applyForScholarship(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Apply For Scholarship'])
            return data
        }
    })
}

export function useFetchUserCVs() {
    return useQuery(['Fetch User Cvs'], () => appApiCalls.fetchUserCv(), {
        staleTime: Infinity,
    })
}

export function useGetAllScholarships(page, limit, search) {
    return useQuery(['Fetch All Scholarships', page, limit, search], () => appApiCalls.getAllScholarships(page, limit, search), {
        staleTime: Infinity
    })
}

export function useGetAllSponsoredCourse(page, limit, search) {
    return useQuery(['Fetch All Sponsored Course', page, limit, search], () => appApiCalls.getAllSponsoredCourse(page, limit, search), {
        staleTime: Infinity
    })
}
export function useGetAppliedBeneficiaries(sponsorshipId, page) {
    return useQuery(['Fetch All Beneficiaries', page], () => appApiCalls.getBeneficiariesBySponsorship(sponsorshipId,page), {
        staleTime: Infinity
    })
}


export function useApproveSponsorship() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.approveSponsorshipForSponsors(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Fetch All Scholarships'])
            return data
        }
    })
}
export function useApproveForBeneficiaries() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.beneficiariesSponsorshipApproval(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Beneficiaries Approval'])
            return data
        }
    })
}

export function useGetSponsorshipGraphData(sponsorId) {
    return useQuery(['Get Sponsorship Graph Data'], () => appApiCalls.getSponsorshipGraphData(sponsorId), {
        staleTime: Infinity,
        keepPreviousData: false
    })
}
