import React, {useEffect} from 'react';
import {
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect, CLoadingButton, CModal, CModalBody, CModalHeader, CModalTitle,
    CRow
} from "@coreui/react-pro";
import GoBack from "../GoBack";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {NewTrainerSchema} from "../../form-schema/admin-form-schema/newTrainerSchema";
import {useGetAllStates} from "../../hooks/user/userApiHooks";
import {useAddNewTrainer} from "../../hooks/admin/adminApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";

const AddTrainerModal = ({visible, setVisible}) => {

    const {header} = useAppContext()

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(NewTrainerSchema)
    })

    const {data} = useGetAllStates()
    const { mutate, isLoading, isSuccess, isError, error } = useAddNewTrainer()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Trainer Added Successfully')
            reset()
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isError])

    const handleNewTrainer = (data) => {
        mutate({data, header})
    }

    return <CModal
        backdrop="static"
        visible={visible}
        onClose={() => {
            setVisible(false)
        }}
    >
        <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>Add New Trainer</CModalTitle>
        </CModalHeader>
        <CModalBody>
                <CContainer>
                    <CRow>
                        <CCol md={12}>
                            <CForm onSubmit={handleSubmit(handleNewTrainer)}>
                                <CRow className="my-3">
                                    <CCol md={6}>
                                        <CFormLabel>First Name</CFormLabel>
                                        <CFormInput
                                            className="form-control py-2"
                                            placeholder="Jon"
                                            type="text"
                                            id="firstName"
                                            {...register('firstName')}
                                            invalid={!!errors.firstName}
                                        />
                                        <CFormFeedback invalid={!!errors.firstName}>
                                            {errors.firstName?.message}
                                        </CFormFeedback>
                                    </CCol>
                                    <CCol md={6}>
                                        <CFormLabel>Last Name</CFormLabel>
                                        <CFormInput
                                            className="form-control py-2"
                                            placeholder="Doe"
                                            type="text"
                                            id="lastName"
                                            {...register('lastName')}
                                            invalid={!!errors.lastName}
                                        />
                                        <CFormFeedback invalid={!!errors.lastName}>
                                            {errors.lastName?.message}
                                        </CFormFeedback>
                                    </CCol>
                                </CRow>
                                <CRow className="my-3">
                                    <CCol md={6}>
                                        <CFormLabel>Email</CFormLabel>
                                        <CFormInput
                                            className="form-control py-2"
                                            placeholder="jon.doe@domain.com"
                                            type="text"
                                            id="email"
                                            {...register('email')}
                                            invalid={!!errors.email}
                                        />
                                        <CFormFeedback invalid={!!errors.email}>
                                            {errors.email?.message}
                                        </CFormFeedback>
                                    </CCol>
                                    <CCol md={6}>
                                        <CFormLabel>Phone Number</CFormLabel>
                                        <CFormInput
                                            className="form-control py-2"
                                            placeholder="08000000000"
                                            type="text"
                                            id="phoneNumber"
                                            {...register('phoneNumber')}
                                            invalid={!!errors.phoneNumber}
                                        />
                                        <CFormFeedback invalid={!!errors.phoneNumber}>
                                            {errors.phoneNumber?.message}
                                        </CFormFeedback>
                                    </CCol>
                                </CRow>
                                <CRow className="my-3">
                                    <CCol md={6}>
                                        <CFormLabel>Center</CFormLabel>
                                        <CFormInput
                                            className="form-control py-2"
                                            placeholder="Outsource global"
                                            type="text"
                                            id="center"
                                            {...register('center')}
                                            invalid={!!errors.center}
                                        />
                                        <CFormFeedback invalid={!!errors.center}>
                                            {errors.center?.message}
                                        </CFormFeedback>
                                    </CCol>
                                    <CCol md={6}>
                                        <CFormLabel>State</CFormLabel>
                                        <CFormSelect
                                            className="form-control py-2"
                                            id="state"
                                            {...register('state')}
                                            invalid={!!errors.state}
                                        >
                                            <option default>Select</option>
                                            {data?.data?.data?.map(({stateName}, index) => (
                                                <option key={index}>{stateName}</option>
                                            ))}
                                        </CFormSelect>
                                        <CFormFeedback invalid={!!errors.state}>
                                            {errors.state?.message}
                                        </CFormFeedback>
                                    </CCol>
                                </CRow>
                                <div className="d-flex flex-row justify-content-end my-5">
                                    <CLoadingButton
                                        type="submit"
                                        className="btn btn-primary px-5 py-2 text-small text-white"
                                        loading={isLoading}
                                    >ADD</CLoadingButton>
                                </div>
                            </CForm>
                        </CCol>
                    </CRow>
                </CContainer>
        </CModalBody>
    </CModal>
}

export default AddTrainerModal;