import React from 'react';
import {CFormCheck, CFormInput, CFormSelect, CImage} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {ChangePasswordSchema} from "../../form-schema/user-form-schema/changePasswordSchema";
import {educationLevelOptions} from "../../constant";


function AttendancePersonalInformation({profileDetail}) {
    const data = {
        firstName: profileDetail?.user?.firstName,
        lastName:profileDetail?.user?.lastName,
        phoneNumber:profileDetail?.user?.phoneNumber,
        course:profileDetail?.course,
        email:profileDetail?.user?.email,
    }
    const course = ['Data Science', 'Software Engineering', 'Customer Care Service'];
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(ChangePasswordSchema),
        defaultValues: data
    })
    return (
        <div>
            <div className="d-flex gap-3">
                <div className="w-100 py-2">
                    <small className="text-black-50 text-small">First Name</small>
                    <CFormInput type="text" id="fName"
                                placeholder="Chukwudi"
                                className="rounded-1"
                                {...register(`firstName`)}
                    />
                </div>
                <div className="w-100 py-2">
                    <small className="text-black-50 text-small">Last Name</small>
                    <CFormInput type="text" id="lName"
                                placeholder="Ayuba"
                                className="rounded-1"
                                {...register(`lastName`)}
                    />
                </div>
            </div>
            <div className="py-2">
                <small className="text-black-50 text-small">Email</small>
                <CFormInput type="email" id="lName"
                            placeholder="ayuba@gmail.com"
                            className="rounded-1"
                            {...register(`email`)}
                />
            </div>

            <div className="py-2">
                <small className="text-black-50 text-small">Phone Number</small>
                <CFormInput type="text" id="lName"
                            placeholder="09023456789"
                            className="rounded-1"
                            {...register(`phoneNumber`)}
                />
            </div>

            <div className="py-2">
                <small className="text-black-50 text-small">Course</small>

                <CFormSelect
                    className="rounded-1"
                    {...register(`course`)}
                >
                    {
                        course?.map((option, index) => (
                            <option
                                value={option}
                                key={index}
                            >
                                {option}
                            </option>
                        ))
                    }

                </CFormSelect>
            </div>
        </div>
    );
}

export default AttendancePersonalInformation;
