import React from "react";
import {CCol, CTooltip} from "@coreui/react-pro";

const UserProfileTagWithRating = ({tag, tips}) => {
    const splitTag = tag.split(":")

    return (
        <>
            {
                tips ?
                    <CTooltip content={tips} placement="top">
                        <CCol md={6} className="my-2">
                            <div className="bg-grey p-2">
                                <h5 className="m-0"><strong>{splitTag[0]}</strong></h5>
                                <h6 className="p-0 m-0 mt-4"><strong>{splitTag[1]}</strong></h6>
                                <small className="small-text-gray m-0 p-0">Aggregate</small>
                            </div>
                        </CCol>
                    </CTooltip>
                    : <CCol md={6} className="my-2">
                        <div className="bg-grey p-2">
                            <h5 className="m-0"><strong>{splitTag[0]}</strong></h5>
                            <h6 className="p-0 m-0 mt-4"><strong>{splitTag[1]}</strong></h6>
                            <small className="small-text-gray m-0 p-0">Aggregate</small>
                        </div>
                    </CCol>}
        </>
    )
}

export default UserProfileTagWithRating
