import React, {useEffect, useState} from 'react';
import {CButton, CCol, CContainer, CFormSelect, CLoadingButton, CRow} from "@coreui/react-pro";
import JobCard from "../../components/employer/Jobs/JobCard";
import JobApplicants from "../../components/employer/Jobs/JobApplicants";
import HeaderCard from "../../components/employer/HeaderCard";
import {Link} from "react-router-dom";
import {useGetEmployersJobs, useGetJobApplicants} from "../../hooks/employer/employerApiHooks";
import ShowLoading from "../../components/ShowLoading";
import {fail_notify} from "../../constant";
import DataNotFound from "../../components/DataNotFound";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import CategoryCard from "../../admin/pages/dashboard/SkillReports/CategoryCard";
import {TestTips} from "../../constant/testTips";

function JobListing() {
    const [page, setPage] = useState(1)
    const [jobPage, setJobPage] = useState(1)
    const [limit, setLimit] = useState(10)
    let employerJobs = useGetEmployersJobs(jobPage)
    const [jobId, setJobId] = useState("")
    const [title, setTitle] = useState("")
    const [activeTap, setActiveTap] = useState("all")
    const [fitLevel, setFitLevel] = useState("")
    const [assessment, setAssessment] = useState("")
    const [subAssessments, setSubAssessments] = useState([])
    const [assessmentCategory, setAssessmentCategory] = useState("")
    const [assessmentCount, setAssessmentCount] = useState(null)
    const [queryKey, setQueryKey] = useState(new Date())
    const [sortableApplicants, setSortableApplicants] = useState([])

    let jobApplicants = useGetJobApplicants(queryKey, jobId, page, limit, fitLevel, assessment)

    useEffect(() => {
        setActiveTap("all")
        setAssessment("")
        setAssessmentCount(jobApplicants?.data?.data?.data)
        setSortableApplicants(jobApplicants?.data?.data?.data?.items)

    }, [jobId, jobApplicants])

    useEffect(() => {
        setAssessmentCategory("&" + assessments[0]?.key + "=")
        setSubAssessments(assessments[0]?.value);
    }, [])

    const taps = [
        {name: "All applicants", key: "all", value: ''},
        {name: "Good fit", key: "goodFit", value: "&fitLevel=Good fit",},
        {name: "May be", key: "mayBeFit", value: "&fitLevel=Maybe"},
        {name: "Not a good fit", key: "notAGoodFit", value: "&fitLevel=Not a good fit"},
    ]

    const assessments = [
        {
            name: "Personality Assessment",
            key: "personalityCategory",
            value: ["Expressive", "Analytical", "Driver", "Amiable"]
        },
        {name: "Culture Fitness", key: "cultureCategory", value: ["Adhocracy", "Clan", "Hierarchy", "Market"],},
        {
            name: "Soft Skills",
            key: "softSkillsCategory",
            value: ["Adaptability", "Communication", "Problem Solving", "RelationShip Building"]
        },
    ]

    const handleAssessment = (e) => {
        const data = e.target.value.split(',')
        const sub_assessment = data.slice(1)
        setSubAssessments(sub_assessment);
        setAssessmentCategory("&" + data[0] + "=")
        //setAssessment("&" + data[0] + "=" + data[1])
        setQueryKey(new Date())
    }

    useEffect(() => {
        if (employerJobs?.data?.data?.data?.items.length) {
            if (jobId === "") {
                setJobId(employerJobs?.data?.data?.data?.items[0]?.id)
            }
            if (title === "") {
                setTitle(employerJobs?.data?.data?.data?.items[0]?.title)
            }
        }

    }, [employerJobs])

    if (employerJobs?.isLoading) {
        return <ShowLoading/>
    }

    if (employerJobs?.isError) {
        fail_notify(employerJobs?.error?.response?.data?.message)
    }

    const assessmentFun = (value) => {
        if (value === "Expressive") {
            return assessmentCount?.personalityCounts?.expressive
        }
        if (value === "Analytical") {
            return assessmentCount?.personalityCounts?.analytical
        }
        if (value === "Driver") {
            return assessmentCount?.personalityCounts?.driver
        }
        if (value === "Amiable") {
            return assessmentCount?.personalityCounts?.amiable
        }

        if (value === "Adhocracy") {
            return assessmentCount?.cultureCounts?.adhocracy
        }
        if (value === "Clan") {
            return assessmentCount?.cultureCounts?.clan
        }
        if (value === "Market") {
            return assessmentCount?.cultureCounts?.market
        }
        if (value === "Hierarchy") {
            return assessmentCount?.cultureCounts?.hierarchy
        }

        if (value === "Communication") {
            return assessmentCount?.softSkillsCounts?.communication
        }
        if (value === "Adaptability") {
            return assessmentCount?.softSkillsCounts?.adaptability
        }
        if (value === "Problem Solving") {
            return assessmentCount?.softSkillsCounts?.problemsolving
        }
        if (value === "Relationship Building") {
            return assessmentCount?.softSkillsCounts?.relationshipbuilding
        }
    }

    const handleSortInterviewing = () => {
        // get applicants IDs
        const usersIds = jobApplicants?.data?.data?.data?.items?.map(({id}) => id)

        // get all applicants marked for interview, using id
        let sortedInterviews = employerJobs?.data?.data?.data?.items?.map((applied) => {
            return applied?.applyForJob?.map((applicant) => {
                if (applicant?.applicationStatus?.includes('interview') && usersIds?.includes(applicant?.userId)) {
                    return jobApplicants?.data?.data?.data?.items?.filter(({id}) => applicant?.userId === id)
                }
            })
        })
        setSortableApplicants(sortedInterviews[0][0])
    }

    return (
        <CContainer>
            <CRow>
                <CCol>
                    <h3 className="sub-title"> My Jobs and Applicants </h3>
                </CCol>
                <CCol className="d-flex justify-content-end">
                    <Link to="/employer/create-job-posting">
                        <CLoadingButton
                            type="button"
                            title="Add Posting"
                            className="text-white"
                        >
                            Add a Job
                        </CLoadingButton>
                    </Link>

                </CCol>
            </CRow>
            <hr/>
            <CRow>
                {employerJobs?.data?.data?.data?.items?.length === 0 &&
                    <>
                        <DataNotFound/>
                    </>
                }
                {employerJobs?.data?.data?.data?.items?.length > 0 &&
                    <>
                        <CCol sm={4}>
                            <div className="bg-white rounded-2">

                                {
                                    employerJobs?.data?.data?.data?.items.map((detail, index) => {
                                        return <div key={index}
                                                    className={jobId === detail.id ? `bg-light-green-2` : ``}>
                                            <JobCard setJobId={setJobId} setTitle={setTitle} detail={detail}/>

                                        </div>
                                    })
                                }

                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                                <CButton disabled={jobPage <= 1} onClick={() => setJobPage(jobPage - 1)}
                                         className="bg-white text-black border-0 mx-3 py-1 pb-2 px-3">
                                    <BiLeftArrow/> Previous
                                </CButton>
                                <CButton
                                    disabled={jobPage === employerJobs?.data?.data?.data?.meta?.totalPages || employerJobs?.isLoading || employerJobs?.isError}
                                    onClick={() => setJobPage(jobPage + 1)}
                                    className="bg-white text-black mx-3 border-0 py-1 pb-2 px-3">
                                    Next <BiRightArrow/> </CButton>
                            </div>
                        </CCol>
                        <CCol sm={8}>
                            <HeaderCard title={title}/>
                            <br/>
                            <div className="d-flex flex-wrap center-item">
                                {taps.map((tap) => {
                                    return (
                                        <small key={tap.key}
                                               className={`cursor-pointer py-none py-2 px-2 rounded border border-1 border-green-100 m-1 ${activeTap === tap.key ? 'text-white bg-green-100' : 'bg-transparent'}`}
                                               onClick={() => {
                                                   setFitLevel(tap?.value)
                                                   setQueryKey(new Date())
                                                   setActiveTap(tap.key)
                                               }
                                               }
                                        >{`${tap.name} [${tap?.key === "all" ? jobApplicants?.data?.data?.data?.meta?.totalItems : jobApplicants?.data?.data?.data?.categories?.[tap.key]}]`}</small>
                                    );
                                })}
                                <div>
                                    <CFormSelect
                                        id="assessment"
                                        className="form-control rounded-0"
                                        onChange={handleAssessment}
                                    >
                                        {
                                            assessments.map((assessment) => {
                                                return (
                                                    <option key={assessment?.key}
                                                            value={`${assessment?.key},${assessment?.value}`}>{assessment.name}</option>
                                                )
                                            })
                                        }

                                    </CFormSelect>
                                </div>
                                <small
                                    className="cursor-pointer py-none py-2 px-2 rounded border border-1 border-green-100 m-1 text-white bg-green-100"
                                    onClick={handleSortInterviewing}
                                >
                                    Interviewing
                                </small>
                            </div>
                            <div className="d-flex flex-wrap gap-2 py-2 center-item">
                                {subAssessments.map((subAssessment, index) => {
                                    return (
                                        <div key={index}
                                             className="cursor-pointer"
                                             onClick={() => {
                                                 //setAssessment(subAssessment)
                                                 setAssessment(assessmentCategory + subAssessment)
                                                 setQueryKey(new Date())

                                             }
                                             }
                                        >
                                            {assessmentCategory.includes("personalityCategory") && <CategoryCard
                                                label={subAssessment}
                                                count={assessmentFun(subAssessment)} key={index}
                                                color_index={index}
                                                tip={TestTips.personalityTest[index]?.tip}
                                                qualifier={TestTips.personalityTest[index]?.qualifier}
                                            />}
                                            {assessmentCategory.includes("softSkillsCategory") && <CategoryCard
                                                label={subAssessment}
                                                count={assessmentFun(subAssessment)} key={index}
                                                color_index={index}
                                                tip={subAssessment}
                                                qualifier={TestTips.softSkillsTest[index]?.qualifier}
                                            />}
                                            {assessmentCategory.includes("cultureCategory") && <CategoryCard
                                                label={subAssessment}
                                                count={assessmentFun(subAssessment)} key={index}
                                                color_index={index}
                                                tip={TestTips.cultureFitTest[index]?.tip}
                                                qualifier={TestTips.cultureFitTest[index]?.qualifier}
                                            />}
                                        </div>
                                    );
                                })}
                            </div>

                            <JobApplicants
                                jobApplicants={sortableApplicants}
                                setLimit={setLimit}
                                limit={limit}
                                setQueryKey={setQueryKey}
                                jobId={jobId}
                                employerJobs={employerJobs}
                            />
                        </CCol>
                    </>
                }
            </CRow>
            <br/>
        </CContainer>
    );
}

export default JobListing;
