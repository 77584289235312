import React, {useEffect} from 'react';
import {
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {NewCohortSchema} from "../../form-schema/admin-form-schema/newCohortSchema";
import {useAddNewCohort} from "../../hooks/admin/adminApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";

const AddCohortModal = ({visible, setVisible}) => {

    const {header} = useAppContext()

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(NewCohortSchema)
    })

    const {mutate, isError, error, isSuccess, isLoading} = useAddNewCohort()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Cohort Added Successfully')
            reset()
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isError])

    const handleNewCohort = (data) => {
        mutate({data, header})
        setVisible(false)
    }

    return <CModal
        backdrop="static"
        visible={visible}
        onClose={() => {
            setVisible(false)
        }}
    >
        <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>Add New Cohort</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CContainer>
                <CRow>
                    <CCol md={12}>
                        <CForm onSubmit={handleSubmit(handleNewCohort)}>
                            <div className="my-3">
                                <CFormLabel>Cohort Name</CFormLabel>
                                <CFormInput
                                    className="form-control px-3 py-2"
                                    type="text"
                                    id="name"
                                    {...register('name')}
                                    invalid={!!errors.name}
                                />
                                <CFormFeedback invalid={!!errors.name}>
                                    {errors.name?.message}
                                </CFormFeedback>
                            </div>
                            <div className="d-flex flex-row justify-content-end my-5">
                                <CLoadingButton
                                    type="submit"
                                    className="btn btn-primary px-5 py-2 text-small text-white"
                                    loading={isLoading}
                                >ADD</CLoadingButton>
                            </div>
                        </CForm>
                    </CCol>
                </CRow>
            </CContainer>
        </CModalBody>
    </CModal>
}

export default AddCohortModal;