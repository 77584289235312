import React from 'react';
import InfoTitle from "../InfoTitle";
import careerObjective from "../../../../assets/icons/careerObjective.svg";
import {CCol, CFormFeedback, CFormLabel, CRow} from "@coreui/react-pro";
import CVTextEditor from "../../CVTextEditor";

function CareerObjective({errors, setEditorState, editorState}) {
    return (
        <>
            <InfoTitle title={"CAREER OBJECTIVE"} icon={careerObjective}/>
            <CRow className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Description</CFormLabel>
                    <CVTextEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                    />
                    <CFormFeedback invalid={!!errors.careerObjective}>
                        {errors.careerObjective?.message}
                    </CFormFeedback>
                </CCol>

            </CRow>

        </>
    );
}


export default CareerObjective;
