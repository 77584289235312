import React, {useEffect, useState} from 'react'
import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalTitle, CRow
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {fail_notify, success_notify} from "../../constant";
import {useScheduleInterview} from "../../hooks/employer/employerApiHooks";
import {InterviewSchedulingSchema} from "../../form-schema/stake-holder-schema/interviewSchedulingSchema";
import TextEditor from "../TextEditor";

const InterviewSchedulingModal = ({visible, setVisible, jobId, userId}) => {
    const {isError, error, isLoading, isSuccess, mutate} = useScheduleInterview()
    const [emailContent, setEmailContent] = useState('')
    const [emailCopy, setEmailCopy] = useState('')
    const [emailCopies, setEmailCopies] = useState([])
    const [date, setDate] = useState('')
    const [time, setTime] = useState('')
    const [link, setLink] = useState('')
    const {
        register,
        handleSubmit,
        formState: {errors},
        clearErrors,
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(InterviewSchedulingSchema)
    })

    useEffect(() => {
        if (isSuccess) {
            success_notify("Interview Scheduled Successfully!")
            setVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, isSuccess])

    const handleEmailCopies = (e) => {
        e.preventDefault()
        if (emailCopy.length === 0) return
        setEmailCopies([...emailCopies, emailCopy])
        setEmailCopy('')
        setValue('emailCC', emailCopies)
        clearErrors('emailCC')
    }

    const removeTag = (index) => {
        setEmailCopies(emailCopies.filter((_, i) => i !== index));
    };

    const onSubmit = (data) => {
        if (emailCopies?.length > 0) {
            data.emailCC = emailCopies
            clearErrors('emailCC')
        } else {
            setError('emailCC', {message: 'Email CC is required'})
        }

        if (link?.length > 0) {
            data.bookingIframe = link
            data.interviewLink = link
            clearErrors('bookingIframe')
        } else {
            setError('bookingIframe', {message: 'Interview Link is required'})
        }

        if (emailContent?.length > 0) {
            data.emailContent = emailContent
            clearErrors('emailContent')
        } else {
            setError('emailContent', {message: 'Email Content is required'})
        }

        if (date?.length > 0) {
            data.interviewDate = date
            clearErrors('interviewDate')
        } else {
            setError('interviewDate', {message: 'Interview Date is required'})
        }

        if (time?.length > 0) {
            data.interviewTime = time
            clearErrors('interviewTime')
        } else {
            setError('interviewTime', {message: 'Interview Time is required'})
        }

        if (link?.length > 0) {
            data.interviewLink = link
            clearErrors('interviewLink')
        } else {
            setError('interviewLink', {message: 'Interview Link is required'})
        }
        data.userId = userId
        data.jobId = jobId
        data.isVirtual = true
        mutate(data)
    }

    return (
        <CModal size={"lg"} alignment="center" visible={visible} onClose={() => {
        }}>
            <CModalBody style={{backgroundColor: '#F5F5F5'}} className="rounded">
                <CModalTitle className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <span>Interview Scheduling</span>
                </CModalTitle>
            </CModalBody>
            <CRow className="d-flex flex-row justify-content-center align-items-center m-3">
                <CCol md={12}>
                    <CForm onSubmit={handleSubmit(onSubmit)}>
                        <CRow className="my-3">
                            <CCol md={6} className="mb-3">
                                <CFormLabel>Email CC</CFormLabel>
                                {emailCopies.map((item, index) => (
                                    <span className="bg-light-green-2 mx-1 px-2 py-1 rounded-4" key={index}>
                                        {item} &nbsp;
                                        <svg onClick={() => removeTag(index)} xmlns="http://www.w3.org/2000/svg"
                                             width="16" height="16"
                                             fill="currentColor" className="bi bi-x cursor-pointer text-red"
                                             viewBox="0 0 16 16">
                                          <path
                                              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </span>))
                                }
                                <CFormInput
                                    type="text"
                                    id="emailCC"
                                    placeholder="name@domain.com"
                                    {...register('emailCC')}
                                    invalid={!!errors.emailCC}
                                    value={emailCopy}
                                    onChange={(e) => setEmailCopy(e.target.value)}
                                />
                                <CButton disabled={emailCopies.length >= 4} className="btn-sm text-white mt-2"
                                         onClick={handleEmailCopies}>
                                    Add Email</CButton>
                                <CFormFeedback invalid={!!errors.emailCC}>{errors.emailCC?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6} className="mb-3">
                                <CFormLabel>Meeting Link or Calendar</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="bookingIframe"
                                    placeholder="https://meet.google.com/ess-whxc-uet"
                                    {...register('bookingIframe')}
                                    invalid={!!errors.bookingIframe}
                                    onChange={(e) => setLink(e.target.value)}
                                />
                                <CFormFeedback
                                    invalid={!!errors.bookingIframe}>{errors.bookingIframe?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6} className="mb-3">
                                <CFormLabel>Interview Date</CFormLabel>
                                <CFormInput
                                    type="date"
                                    id="interviewDate"
                                    {...register('interviewDate')}
                                    invalid={!!errors.interviewDate}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                                <CFormFeedback
                                    invalid={!!errors.interviewDate}>{errors.interviewDate?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6} className="mb-3">
                                <CFormLabel>Interview Time</CFormLabel>
                                <CFormInput
                                    type="time"
                                    id="interviewTime"
                                    {...register('interviewTime')}
                                    invalid={!!errors.interviewTime}
                                    onChange={(e) => setTime(e.target.value)}
                                />
                                <CFormFeedback
                                    invalid={!!errors.interviewDate}>{errors.interviewTime?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormLabel>Email Content</CFormLabel>
                                <TextEditor
                                    editorState={emailContent}
                                    setEditorState={setEmailContent}
                                    invalid={!!errors.emailContent}
                                />
                                <CFormFeedback
                                    invalid={!!errors.emailContent}>{errors.emailContent?.message}</CFormFeedback>
                            </CCol>
                        </CRow>
                        <div className="d-flex flex-row justify-content-end">
                            <CButton
                                className="px-2 py-2 mt-3 me-2 text-white btn-danger"
                                onClick={() => setVisible(false)}
                            >Cancel</CButton>
                            <CLoadingButton
                                type="submit"
                                className="px-3 w-25 py-2 mt-3 text-white"
                                loading={isLoading}
                                disabled={isLoading}
                            >
                                Schedule Interview
                            </CLoadingButton>
                        </div>
                    </CForm>
                </CCol>
            </CRow>
        </CModal>
    )
}

export default InterviewSchedulingModal