import React, {useEffect, useState} from "react";
import {CForm, CLoadingButton} from "@coreui/react-pro";
import {useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// import careerObjective from "../../../assets/icons/careerObjective.svg";
import {fail_notify} from "../../../constant";
import {PersonalDetailSchema} from "../../../form-schema/user-form-schema/cv-builder/PersonalDetails";
import personalDetail from "../../../constant/cvBuilder/personalDetail";
import BioDetails from "./PersonalDetails/BioDetails";
import CvTips from "./CVTips";
import CareerObjective from "./PersonalDetails/CareerObjective";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const PersonalDetails = () => {

    const {status, setStatus, setPersonalData, personalData, preData, cvData} = useCVBuilderContext()

    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();
    const [editorState, setEditorState] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);

    const {
        register,
        handleSubmit,
        getValues,
        control,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(PersonalDetailSchema),
        defaultValues: personalDetail(cvData?.data?.data?.data[0], preData.data?.data?.data?.user)
    })

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0] = getValues();
            setStatus(2);
        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (!initialLoad) {
            setValue("careerObjective", editorState);
        }
    }, [editorState])

    useEffect(() => {
        setPersonalData(watchAllFields);
    }, [watchAllFields])

    useEffect(() => {
        if (initialLoad && personalData) {
            setEditorState(() => personalData?.careerObjective);
            setInitialLoad(false);
        }
    }, [personalData])

    const onSubmit = (data) => {
        // data.profession = "NIL";
        mutate(data);
    }
    return (
        <>
            {(status === 1 && preData.isSuccess) &&
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>

                    <BioDetails
                        errors={errors}
                        register={register}
                    />

                    <CareerObjective
                        errors={errors}
                        editorState={editorState}
                        setEditorState={setEditorState}
                    />
                    <CvTips/>

                    <center className="">
                        <CLoadingButton
                            loading={isLoading}
                            disabled={isLoading}
                            type="submit"
                            className="py-3  cv-btn m-1 text-white">
                            SAVE & CONTINUE
                        </CLoadingButton>
                    </center>
                </CForm>}

        </>
    )
}

export default PersonalDetails
