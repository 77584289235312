export function parseAndFormatHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim() + "\n").join(' ');

    if (formattedText) {
        return formattedText;
    }

    return html
}

export function isDisplay(array) {
    const obj = array[0]

    if (!obj) {
        return false
    }
    const hasValue = Object.values(obj).some(value => {
        if (typeof value === 'boolean') {
            return value;
        } else {
            return value.trim() !== '';
        }
    });

    if (hasValue) {
        return true
    }
}

export function hasText(array) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = array;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim()).join(" ");

    return !!formattedText;

}