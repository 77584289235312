import React from 'react'
import {CButton, CContainer, CImage} from '@coreui/react-pro'
import { Link } from 'react-router-dom'
import CompletedIcon from '../../../assets/images/completed.svg'
// import DefaultLayout from '../../layouts/DefaultLayout'

const Verified = () => {
  return (
    <CContainer className="min-vh-100 d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center flex-column w-100">
        <h3 className="pb-5">Registration Completed!</h3>
        <CImage src={CompletedIcon} alt="Completed" style={{width: '15%'}}/>
        <p className="pt-3 text-black">
          Thank you for completing your registration. <br/>Your data have been updated successfully.
        </p>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <Link to="/login" className="w-100">
            <CButton variant="outline" className="w-100 py-2">
              Proceed To Login
            </CButton>
          </Link>
        </div>
      </div>
    </CContainer>
  )
}

export default Verified
