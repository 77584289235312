import * as Yup from 'yup'

export const TinSchema = Yup.object().shape({

    stakeHolderType: Yup.string().required("Please Select User Type"),

    institutionType: Yup.string().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Institution',
        then: Yup.string().test('len', 'Institution Type is required', (val) => val != 0),
    }),

    tin: Yup.string().required('TIN is required'),

    organizationStatus: Yup.mixed().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'NGO',
        then: Yup.string().test('len', 'OrganizationStatus Status is required', (val) => val != 0),
    })
})

