import React, {useState} from 'react'
import {
    CButton,
    CCol, CDatePicker,
    CForm,
    CFormInput,
    CFormLabel, CFormSelect, CFormText, CImage, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader
} from "@coreui/react-pro";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {stakeholderRegSchema} from "../../form-schema/stake-holder-schema/stakeholderRegSchema";
import {format} from "date-fns";
import {useGetJobsQuery} from "../../hooks/user/userApiHooks";
import filter from "../../assets/icons/filter.svg";


const TrainingFilterModal = (
  {
    visible,
    setVisible,
      setFilter,
      setPage,
  }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,

    } = useForm({})

    const handleJobFilter = (data)=>{
        let filter = '';
        if(data.search){
            filter = filter + "&search=" + data.search
        }

        if(data.trainingType){
            filter = filter + "&trainingType=" + data.trainingType
        }
        if(data.modeOfTraining){
            filter = filter + "&modeOfTraining=" + data.modeOfTraining
        }
        setPage(1)
        setFilter(filter)
        setVisible(false)
    }


  return (
    <>
        <div className="bg-transparent cursor-pointer border-0 p-none"
                 onClick={() => setVisible(true)}>
            <CImage src={filter}/>
        </div>
      <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          Filter Trainings
        </CModalHeader>
        <CModalBody>
          <CForm className="row g-3" onSubmit={handleSubmit(handleJobFilter)}>
              <CCol md={12}>
                  <CFormLabel htmlFor="searchBy">Search</CFormLabel>
                  <CFormInput type="text" id="searchBy" {...register('search')}/>
                  <CFormText component="span" id="searchByText">
                      Search by title, address,training duration, category name
                  </CFormText>
              </CCol>

            <CCol md={12}>
              <CFormLabel htmlFor="role">Training Type</CFormLabel>
                <CFormSelect

                    id="trainingType"
                    {...register('trainingType')}
                    onChange={(val) => val.target.value}
                >
                    <option defaultValue value="">
                        Select Training Type
                    </option>
                    <option value={'Paid'}>Paid</option>
                    <option value={'Free'}>Free</option>
                </CFormSelect>
            </CCol>
            <CCol md={12}>
              <CFormLabel htmlFor="modeOfTraining">Mode Of Training</CFormLabel>
                <CFormSelect

                    id="modeOfTraining"
                    {...register('modeOfTraining')}
                    onChange={(val) => val.target.value}
                >
                    <option defaultValue value="">
                        Select Mode Of Training
                    </option>
                    <option value={'Remote'}>Remote</option>
                    <option value={'onSite'}>OnSite</option>
                </CFormSelect>
            </CCol>

              <CModalFooter className="d-flex justify-content-center align-items-center">
                  <CLoadingButton type="submit" className="w-100 text-white">Filter</CLoadingButton>
              </CModalFooter>
          </CForm>
        </CModalBody>

      </CModal>
    </>
  )
}

export default TrainingFilterModal