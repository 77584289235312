import * as Yup from 'yup'

export const SignUpSchema = Yup.object().shape({
  // applicantNin: Yup.number('NIN must be 11 digits!'),
  applicantBvn: Yup.number('BVN must be 11 digits!'),
  password: Yup.string()
    .required('Password is required')
    .min(3, 'Password must be at 3 char long')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>?]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password field is mandatory')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
  email: Yup.string().email('Must be a valid email').required('Email is required'),
  confirmEmail: Yup.string()
      .required('Confirm Email field is mandatory')
      .oneOf([Yup.ref('email')], 'Email does not match'),

  phone: Yup.string().matches(/^(\+)?(234|0)[0-9]{10}$/, 'Must be a valid phone number'),
  marketingEmails: Yup.boolean().nullable(),
})
