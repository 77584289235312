import {
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {educationLevelOptions, fail_notify, nigeriaStates, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {educationSchema} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useUpdateUserQuery} from "../../hooks/user/userApiHooks";
import {useNavigate} from "react-router-dom";
import {AiFillPlusSquare, AiOutlineDelete} from "react-icons/ai";

const Education = ({userData, institutionOptions, tab, setTab}) => {
    const navigate = useNavigate()
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            setTab(tab + 1)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])

    const [formData, setFormData] = useState(userData?.educations);

    const handleChange = (event, index) => {
        const { name, value} = event.target;
        const updatedData = [...formData];
        if(name ==='majors'){
            updatedData[index]['majors'] = [value];
        }
        else {
        updatedData[index][name] = value;
        }

        setFormData(updatedData);
        setValue('educations', updatedData)

    };

    const handleAdd = () => {
        const newExperience = {
            schoolName: "",
            degree: "",
            fieldOfStudy: "",
            startDate: "",
            endDate: "",
            description: "",
            location: "",
            grade: "",
            positionHeld: "",
            majors: []
        };
        setFormData([...formData, newExperience]);
        setValue('educations', [...formData, newExperience])

    };
    const handleRemove = (index) => {
        const updatedData = [...formData];
        updatedData.splice(index, 1);
        setFormData(updatedData);
        setValue('educations', updatedData)
    };
    const {
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(educationSchema),
        defaultValues: { educations: formData },
    })
    //console.log(watch('educations'))
    const handleFormSubmit = (data) => {
        userData.educations = data.educations
        mutate(userData)
    }
    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleFormSubmit)}>
           <div className="bg-white w-100 px-4 py-3 rounded-1">
               {formData.map((experience, index) => (
                   <div key={index}>
                       <div className="w-100 d-flex justify-content-end align-items-center">
                           <span>
                               <AiOutlineDelete onClick={() => handleRemove(index)} className="cursor-pointer" color="red" size={16}/>
                               <small onClick={() => handleRemove(index)} className=" text-danger cursor-pointer px-1">Delete</small>
                           </span>
                       </div>
                       <CRow>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">School</CFormLabel>
                               <CFormSelect
                                   id="schoolName"
                                   className="form-control mb-2"
                                   name="schoolName"
                                   value={experience.schoolName}
                                   onChange={(e) => handleChange(e, index)}
                               >
                                   <option value=""></option>
                                   {
                                       institutionOptions?.map((option, index) => (
                                           <option
                                               value={option?.text}
                                               key={index}
                                           >
                                               {option?.text}
                                           </option>
                                       ))
                                   }
                               </CFormSelect>
                               {errors.educations?.[index]?.schoolName &&
                                   <small className="text-danger">{errors.educations[index].schoolName.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Class of Degree</CFormLabel>
                               <CFormSelect
                                   id="degree"
                                   className="form-control mb-2"
                                   name="degree"
                                   value={experience.degree}
                                   onChange={(e) => handleChange(e, index)}

                               >
                                   <option value=""></option>
                                   {
                                       educationLevelOptions?.map((option, index) => (
                                           <option
                                               value={option}
                                               key={index}
                                           >
                                               {option}
                                           </option>
                                       ))
                                   }

                               </CFormSelect>
                               {errors.educations?.[index]?.degree &&
                                   <small className="text-danger">{errors.educations[index].degree.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Course of Study</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="fieldOfStudy"
                                   placeholder="Computer Science"
                                   name="fieldOfStudy"
                                   value={experience.fieldOfStudy}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               {errors.educations?.[index]?.fieldOfStudy &&
                                   <small className="text-danger">{errors.educations[index].fieldOfStudy.message}</small>
                               }
                           </CCol>

                       </CRow>
                       <CRow>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Location</CFormLabel>
                               <CFormSelect
                                   id="location"
                                   className="form-control mb-2"
                                   name="location"
                                   value={experience.location}
                                   onChange={(e) => handleChange(e, index)}

                               >
                                   <option value=""></option>
                                   {
                                       nigeriaStates?.map((option, index) => (
                                           <option
                                               value={option}
                                               key={index}
                                           >
                                               {option}
                                           </option>
                                       ))
                                   }

                               </CFormSelect>
                               {errors.educations?.[index]?.location &&
                                   <small className="text-danger">{errors.educations[index].location.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Start Date</CFormLabel>
                               <CFormInput
                                   type="month"
                                   id="startDate"
                                   name="startDate"
                                   value={experience.startDate}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               {errors.educations?.[index]?.startDate &&
                                   <small className="text-danger">{errors.educations[index].startDate.message}</small>
                               }
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">End date</CFormLabel>
                               <CFormInput
                                   type="month"
                                   id="endDate"
                                   name="endDate"
                                   value={experience.endDate}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               {errors.educations?.[index]?.endDate &&
                                   <small className="text-danger">{errors.educations[index].endDate.message}</small>
                               }
                           </CCol>

                       </CRow>
                       <CRow>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Grade</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="grade"
                                   placeholder="4.50"
                                   name="grade"
                                   value={experience.grade}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               <CFormFeedback invalid={!!errors.grade}>
                                   {errors.grade?.message}
                               </CFormFeedback>
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Position Held</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="positionHeld"
                                   placeholder="Class Representative"
                                   name="positionHeld"
                                   value={experience.positionHeld}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               <CFormFeedback invalid={!!errors.positionHeld}>
                                   {errors.positionHeld?.message}
                               </CFormFeedback>
                           </CCol>
                           <CCol md={4} className="my-1">
                               <CFormLabel className="m-1 label">Majors</CFormLabel>
                               <CFormInput
                                   type="text"
                                   id="majors"
                                   placeholder="Change Management, Business Policy..."
                                   name="majors"
                                   value={experience.majors}
                                   onChange={(e) => handleChange(e, index)}
                                   className="form-control mb-2"

                               />
                               <CFormFeedback invalid={!!errors.grade}>
                                   {errors.grade?.message}
                               </CFormFeedback>
                           </CCol>
                       </CRow>
                       <CRow>
                           <CCol md={12} className="my-1">
                               <CFormLabel className="m-1 label">Description</CFormLabel>
                               <CFormTextarea
                                   type="text"
                                   id="description"
                                   className="form-control mb-2"
                                   placeholder="Type here"
                                   rows={3}
                                   name="description"
                                   value={experience.description}
                                   onChange={(e) => handleChange(e, index)}
                               >
                               </CFormTextarea>
                               {errors.educations?.[index]?.description &&
                                   <small className="text-danger">{errors.educations[index].description.message}</small>
                               }
                           </CCol>
                       </CRow>
                       <hr/>
                   </div>
               ))}


                   <div onClick={handleAdd} className="w-100 d-flex justify-content-center align-items-center">
                       <AiFillPlusSquare className="cursor-pointer" color="#1FAB89" size={30}/>
                       <p className="small-text text-primary cursor-pointer px-1 text-decoration-underline">ADD EDUCATION</p>
                   </div>
           </div>
            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}
                    >
                        SAVE & CONTINUE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default Education
