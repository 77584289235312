import React from 'react';
import {sortTable} from "../../constant";
import {BiSort} from "react-icons/bi";
import {CFormCheck, CFormInput, CFormSelect, CImage} from "@coreui/react-pro";
import FailIcon from "../../assets/icons/assessment-fail-icon.png";
import PassIcon from "../../assets/icons/assessment-pass-icon.png";
import Table from "../tables/table";
import {FaEdit, FaTrashAlt} from "react-icons/fa";
import avatarUrl from "../../assets/images/jobseeker-header.jpg";


function AttendanceActivity({activities}) {
    return (
        <div className="position-relative border border-start-3 border-bottom-0 border-top-0 border-end-0 ms-5">

            {
                activities?.map((item, index) => {
                    return (
                        <div key={index}>
                            <div style={{left: -62}}
                                 className="position-absolute d-flex align-items-center gap-3 text-black-50 text-small">
                                <small className="fw-bold text-primary">{item?.time}</small>
                                <div style={{width: "25px", height: "25px"}}
                                     className="bg-primary rounded-circle border border-4"></div>
                                <div className="d-flex align-items-center">
                                    {item?.icon}
                                    <small
                                        className="fw-bold text-primary">&nbsp; {item?.name} &nbsp;</small>
                                    <small className="text-black">{item?.activity}</small>
                                </div>
                            </div>
                            <br/><br/> <br/>
                        </div>
                    )
                })
            }

        </div>
    );
}

export default AttendanceActivity;
