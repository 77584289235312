import React, {useEffect, useState} from 'react'
import bookmark from "../../assets/icons/bookmark.svg";

import {CCardImage, CLoadingButton,} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";
import {fail_notify, success_notify} from "../../constant";
import {useSaveJobQuery} from "../../hooks/user/userApiHooks";
import {userSavedJob} from "./JobApplicatonUtility";

function SaveJob({job, page, filter, limit, search}) {

    const {storeUser, user, userId} = useAppContext()
    const {
        isError: saveJobIsError,
        isSuccess: saveJobIsSuccess,
        isLoading: saveJobIsLoading,
        error: saveJobError,
        data: saveJobData,
        mutate
    } = useSaveJobQuery(page, "", limit, filter, search)

    const navigate = useNavigate()

    useEffect(() => {
        if (saveJobIsSuccess) {
            success_notify('Job saved successfully')
        }
        if (saveJobIsError) {
            fail_notify(saveJobError?.response?.data?.message)
        }
        storeUser(user)
    }, [saveJobIsError, saveJobIsSuccess, saveJobIsLoading, saveJobError, saveJobData, mutate])

    const [id, setId] = useState("");

    const saveJobHandler = (jobID, isSave) => {
        if (user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) {
            return;
        }
        if (!user?.accessToken) {
            localStorage.setItem("redirectUrl", "/user/jobs");
            navigate(`/login`, {
                replace: true,
            })
            return
        }

        const data = {
            jobId: job.id,
            isSave: isSave
        }
        mutate(data);

    }

    return (
        <>
            {
                saveJobIsLoading && id === job.id ?
                    <CLoadingButton loading={saveJobIsLoading} className="bg-transparent border-0 p-0 text-primary">

                    </CLoadingButton>
                    : <>
                        {
                            !userSavedJob(job, userId) ?
                                <CCardImage data-coreui-toggle="tooltip" title="Save Job" onClick={() => {
                                    if (!saveJobIsLoading) {
                                        setId(job.id)
                                        saveJobHandler(job.id, true)
                                    }
                                }} orientation="top" className="job-bookmark cursor-pointer -mt-4" src={bookmark}/> :
                                <div data-coreui-toggle="tooltip" title="Your Saved Job"
                                     className="bg-transparent text-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                         style={{width: "16px", marginTop: "-10px"}}
                                         className="bi bi-bookmark-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z"/>
                                    </svg>
                                </div>
                        }
                    </>
            }

<style>
    {
        `
        .-mt-4{
        margin-top:-10px !important;
        }
        `
    }
</style>
        </>
    )
}

export default SaveJob
