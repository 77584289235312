import React, {useState} from 'react'
import {CButton, CCard, CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import {AiOutlineDoubleRight} from "react-icons/ai";
import DummyPic from "../../assets/images/dummy-profile-pic.png";
import {useGetAllScholarships, useGetAllSponsoredCourse,} from "../../hooks/user/jobApiHooks";
import ShowLoading from "../../components/ShowLoading";
import DataNotFound from "../../components/DataNotFound";
import {retrieveLoggedInUser} from "../../localStorage";
import ParkwayWalletAd from "../../components/User/dashboard/ParkwayWalletAd";
import SponsorshipApplication from "./SponsorshipApplication";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {fail_notify} from "../../constant";

function SponsoredePrograms() {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    //const {isLoading, data} = useGetAllScholarships()
    const {isLoading, isError, data, error} = useGetAllSponsoredCourse(page, limit)
    //console.log(tesstt);
    const currentUser = retrieveLoggedInUser()
    const [sponsorshipId, setSponsorshipId] = useState(0)
    const [viewSponsorship, setViewSponsorship] = useState(false)
    const [sponsorshipData, setSponsorshipData] = useState(null)

    if (isLoading) {
        return <ShowLoading/>
    }
    if (isError) {
        fail_notify(error?.response?.data?.message)
    }

    if (!currentUser.data.parkway.walletAccountNumber) {
        return <div className="d-flex flex-column justify-content-center align-items-center py-5">
            <h4 className="py-3">ACCESS DENIED</h4>
            <p>To access sponsored courses, you will need a Parkway Wallet to Proceed</p>
            <div className={'mt-3'}>
                <ParkwayWalletAd/>

            </div>
        </div>;
    }

    return (
        <>
            {data?.data?.data?.items?.length === 0 && <DataNotFound/>}
            {(data?.data?.data?.items?.length > 0 && !viewSponsorship) ? <CContainer className="mb-5">
                    <h3 className="text-3 mb-4 text-primary">Sponsored Courses</h3>
                    <hr/>
                    <CRow xs={{cols: 1}} md={{cols: 2}} lg={{cols: 3}} xl={{cols: 3}} className="g-3 pb-2">
                        {data?.data?.data?.items?.map(({id, sponsor, title, approvalStatus, sponsorshipType, loanTenure, sponsorshipAmount, interestRate}, index) => {
                            return (approvalStatus === 'Approved' && <CCol xs key={index}>
                                <CCard
                                    className="d-flex flex-column justify-content-between align-items-start h-100"
                                    style={{minHeight: "375px"}}>
                                    <div style={{width: '100%', height: '250px'}}>
                                        <CImage
                                            src={sponsor?.logoUrl || DummyPic}
                                            alt="Training"
                                            className="rounded-top"
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                        />
                                        <div className="tag rounded-2 px-3">Sponsored</div>
                                        <div className="badge-div"></div>
                                        <center className="py-3">
                                            <p className="py-0 m-0 text-truncate"><b>{title || 'Title'}</b></p>
                                            <p className="text-truncate small-text-gray">By: {sponsor?.name || 'Sponsor'}</p>
                                        </center>
                                    </div>
                                    <Link to={`#`}
                                          className="text-decoration-none w-100 p-2">
                                        <div
                                            className="d-flex enroll-btn flex-row justify-content-between align-items-center w-100 p-2"
                                            onClick={() => {
                                                setSponsorshipId(id)
                                                setViewSponsorship(true)
                                                setSponsorshipData({
                                                    sponsor: sponsor,
                                                    title,
                                                    sponsorshipType,
                                                    loanTenure,
                                                    sponsorshipAmount,
                                                    interestRate
                                                })
                                            }}
                                        >
                                            <b className="text-white">Apply For Sponsorship</b>
                                            <AiOutlineDoubleRight className="text-white"/>
                                        </div>
                                    </Link>
                                </CCard>
                            </CCol>)
                        })}
                    </CRow>
                <br/>
                    <div className="d-flex flex-row justify-content-end align-items-center">
                        <CButton
                            disabled={page <= 1} onClick={() => setPage(page - 1)}
                            className={`mx-3 text-white py-1 pb-2 px-3`}>
                            <BiLeftArrow/>
                        </CButton>
                        <CButton
                            onClick={() => setPage(page + 1)}
                            className="mx-3 text-white py-1 pb-2 px-3"
                            disabled={page >= data?.data?.data?.meta?.totalPages || isLoading || isError}
                        >
                            <BiRightArrow/>
                        </CButton>
                    </div>
                </CContainer>
                :
                <SponsorshipApplication sponsorshipId={sponsorshipId} sponsorshipData={sponsorshipData}/>
            }
        </>
    )
}

export default SponsoredePrograms
