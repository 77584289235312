import {
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput, CFormLabel,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import GoBack from "../../components/GoBack";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {UploadTestimonialSchema} from "../../form-schema/admin-form-schema/uploadTestimonialSchema";
import {useCreateTestimonial} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {TiImage} from "react-icons/ti";

const UploadTestimonials = () => {

    const {isLoading, mutate, isSuccess, isError, error} = useCreateTestimonial()
    const [fileText, setFileText] = useState("")
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        resolver: yupResolver(UploadTestimonialSchema),
    })

    useEffect(() => {
        if (isSuccess) {
            success_notify("Testimonial Uploaded Successfully!")
            setValue('fullName', "")
            setValue('jobTitle', "")
            setValue('testimonial', "")
            setValue('avatar', "")
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, isSuccess])

    const handleFileSelect = (e) => {
        const fileName = e.target.files[0].name
        setFileText(fileName)
    }

    const onSubmit = (data) => {
        const form = document.forms.namedItem('testimonial-form')
        const formData = new FormData(form)
        mutate(formData)
    }

    return (
        <CContainer>
            <CRow className="d-flex flex-row justify-content-center align-items-center">
                <CCol md={6} className="mt-5">
                    <h1 className="pb-4 text-center">Create Testimonial</h1>
                    <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)} name="testimonial-form">
                        <div className="d-flex flex-column gap-3">
                            <CCol md={12}>
                                <CFormLabel>Full Name</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter Full Name"
                                    {...register('fullName')}
                                    invalid={!!errors.fullName}
                                />
                                <CFormFeedback invalid={!!errors.fullName}>{errors.fullName?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormLabel>Job Title</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="jobTitle"
                                    placeholder="Enter Job Title"
                                    {...register('jobTitle')}
                                    invalid={!!errors.jobTitle}
                                />
                                <CFormFeedback invalid={!!errors.jobTitle}>{errors.jobTitle?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormLabel>Testimonial</CFormLabel>
                                <CFormTextarea
                                    type="password"
                                    id="testimonial"
                                    {...register('testimonial')}
                                    placeholder="Type Testmonial Here..."
                                    rows={4}
                                    invalid={!!errors.testimonial}
                                />
                                <CFormFeedback invalid={!!errors.testimonial}>
                                    {errors.testimonial?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormLabel>Avatar</CFormLabel>
                                <br/>
                                <label className="custom-file-upload" htmlFor="avatar">
                                    <TiImage size={36} className="text-primary"/> Click to select image<br/>
                                    <br/>{fileText}
                                </label>
                                <CFormInput
                                    type="file"
                                    id="avatar"
                                    {...register('avatar')}
                                    invalid={!!errors.avatar}
                                    className="custom-file-input"
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                    style={{maxWidth: '100vh'}}
                                />

                                <CFormFeedback invalid={!!errors.avatar}>
                                    {errors.avatar?.message}
                                </CFormFeedback>
                            </CCol>
                        </div>
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-100 py-2 mt-5"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Upload Testimonial
                        </CLoadingButton>
                    </CForm>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <GoBack/>
                    </div>
                </CCol>
            </CRow>

        </CContainer>
    )
}

export default UploadTestimonials