import ErrorReports from "../ErrorReports";
import {
    CButton,
    CForm,
    CFormFeedback,
    CFormInput,
    CModal,
    CModalBody,
    CModalTitle
} from "@coreui/react-pro";
import React, {useEffect, useState} from "react";
import {useVerifyNinStatus} from "../../hooks/user/userApiHooks";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {VerifyNinSchema} from "../../form-schema/user-form-schema/verifyNinSchema";
import {retrieveLoggedInUser, storeLoggedInUser} from "../../localStorage";

const NinVerificationModal = ({visible, setVisible}) => {
    const [isVerified, setIsVerified] = useState(false)
    const user = retrieveLoggedInUser()

    const {
        handleSubmit,
        formState: {errors},
        register
    } = useForm({
        resolver: yupResolver(VerifyNinSchema)
    })

    const {
        isLoading,
        isSuccess,
        data,
        mutate,
        isError,
        error
    } = useVerifyNinStatus()

    useEffect(() => {
        if (isSuccess) {
            user.data.nin = data?.data?.nationalIdentityNumber
            storeLoggedInUser(user)
            setIsVerified(true)
        }
    }, [isSuccess])

    const handleVerifyNin = (data) => {
        data.email = user?.data?.email
        mutate(data)
    }

    return <CModal size={'lg'} alignment="center" visible={visible}>
        {isError && <ErrorReports error={error}/>}
        {
            // (!isLoading && isSuccess) &&
            <CModalBody className="text-center m-5">

                <CModalTitle className="text-title mb-2">NIN Verification</CModalTitle>
                {!isVerified &&
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="mt-2 mb-4 fw-bold">Please verify your NIN before proceeding</p>
                        <CForm className="w-75" onSubmit={handleSubmit(handleVerifyNin)}>
                            <CFormInput
                                type="text"
                                className="my-2 text-center"
                                placeholder="Enter NIN here"
                                {...register('nin')}
                                invalid={!!errors.nin}
                            />
                            <CFormFeedback invalid={!!errors.nin}>
                                {errors.nin?.message}
                            </CFormFeedback>
                            <CButton
                                color="primary"
                                type="submit"
                                className="text-white my-3 px-5"
                            >
                                {isLoading ? 'Verifying...' : 'Verify NIN'}
                            </CButton>
                        </CForm>
                    </div>
                }
                {isVerified && <div>
                    <h3 className="my-3">Your NIN has been verified successfully!</h3>
                    <CButton className="text-white" onClick={() => setVisible(false)}>Close</CButton>
                </div>}
            </CModalBody>
        }

    </CModal>
}

export default NinVerificationModal