import toast from 'react-hot-toast'
import Facebook from '../assets/images/facebook.svg'
import Instagram from '../assets/images/instagram.svg'
import Twitter from '../assets/images/twitter.svg'
import Youtube from '../assets/images/youtube.svg'
import Linkedin from '../assets/images/linkedin.svg'
export const employmentOptions = ['Employed', 'Unemployed', 'Self employed']
export const genderOptions = ['Male', 'Female']
export const idTypeOptions = [
  `National Identification Number (NIN)`,
  `Residence/Work Permit (For Foreigners)`,
  `Permanent Voter's Card`,
  `Driver's license`,
  `International Passport`,
]

export const social = [
  { icon: Facebook, path: 'https://web.facebook.com/Worknation-100423836232607' },
  { icon: Instagram, path: 'https://www.instagram.com/worknationng' },
  { icon: Twitter, path: 'https://twitter.com/WorknationNG' },
  {
    icon: Youtube,
    path: 'https://www.youtube.com/channel/UCTZ0d3nAeEtHwtq_ADARlzg',
  },
  {
    icon: Linkedin,
    path: 'https://www.linkedin.com/company/worknation',
  },
]
export const success_notify = (str) => toast.success(str)
export const loading_notify = (str) => toast.loading(str)
export const fail_notify = (str) => toast.error(str)
export const submit_success = (str) => toast.success(str)
