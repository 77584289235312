import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import cvBuilderApiCalls from "../../services/cvBuilderApi";

export function useUploadCV() {
    const queryClient = useQueryClient()
    return useMutation((arg) => cvBuilderApiCalls.uploadUserCV(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Fetch User Cvs'])
            return data
        },
    })
}

export function useDeleteCV() {
    const queryClient = useQueryClient()
    return useMutation((arg) => cvBuilderApiCalls.deleteUserCV(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Fetch User Cvs'])
            return data
        },
    })
}

export function useFetchUserCVs() {
    return useQuery(['Fetch User Cvs'], () => cvBuilderApiCalls.fetchUserCv(), {
        staleTime: Infinity,
    })
}

// this function handles user cv builder data
export function useCVBuilder() {
    const queryClient = useQueryClient()
    return useMutation((arg) => cvBuilderApiCalls.CVBuilder(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useGetCVBuilderQuery'])
            return data
        },
    })
}

// this function handles user cv builder data
export function useGeneratePDF() {
    const queryClient = useQueryClient()
    return useMutation((arg) => cvBuilderApiCalls.generatePDF(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useGetCVBuilderQuery'])
            return data
        },
    })
}

export function useGetCVBuilderQuery() {
    return useQuery(['useGetCVBuilderQuery'], () => cvBuilderApiCalls.getCVBuilder(), {
        keepPreviousData: true,
        staleTime: Infinity,
    })
}
