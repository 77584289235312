import React from "react";
import HRIcon from "../../assets/images/shared-profile-hr.png";
import {CCol, CImage, CRow} from "@coreui/react-pro";

const PreferencesSection2 = ({userProfileData}) => {
  return (
      <div className="my-2">
          <div className="d-flex flex-column py-2">
              <h6 className="fw-bold">Availability</h6>
              <CRow className="gx-3">
                  <CCol md={6} className="my-2">
                      <div className="bg-grey p-2">
                          <h6 className="p-0 m-0 my-2"><strong>{userProfileData?.jobPreference || "Nil"}</strong></h6>
                      </div>
                  </CCol>
              </CRow>
          </div>
          <CImage src={HRIcon} style={{width: '100%'}}/>
          <div className="d-flex flex-column py-2">
              <h6 className="fw-bold">Job Preference</h6>
              <CRow className="gx-3">
                  <CCol md={6} className="my-2">
                      <div className="bg-grey p-2">
                          <h6 className="p-0 m-0 my-2"><strong>{userProfileData?.availability || "Nil"}</strong></h6>
                      </div>
                  </CCol>
              </CRow>
          </div>
      </div>
  )
}

export default PreferencesSection2