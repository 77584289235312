import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CLoadingButton, CRow} from "@coreui/react-pro";
import TrainingCard from "../../components/employer/Training/TrainingCard";
import JobApplicants from "../../components/employer/Jobs/JobApplicants";
import HeaderCard from "../../components/employer/HeaderCard";
import {Link} from "react-router-dom";
import {useGetEmployersPrograms, useGetProgramTrainees,} from "../../hooks/employer/employerApiHooks";
import ShowLoading from "../../components/ShowLoading";
import {fail_notify} from "../../constant";
import DataNotFound from "../../components/DataNotFound";
import ProgramTrainees from "../../components/employer/Training/ProgramTrainees";

function TrainingProgram(props) {
  const [page, setPage] = useState(1)
  let myPrograms = useGetEmployersPrograms(page)
  const [programId, setProgramId] = useState("")
  const [title, setTitle] = useState("")
  let Trainees = useGetProgramTrainees(programId)
  useEffect(() => {
    if (myPrograms?.data?.data?.data?.items.length) {

      if (programId === "") {
        setProgramId(myPrograms?.data?.data?.data?.items[0]?.id)
      }
      if (title === "") {
        setTitle(myPrograms?.data?.data?.data?.items[0]?.title)
      }
    }

  }, [myPrograms])

  if (myPrograms?.isLoading) {
    return <ShowLoading/>
  }
  if (myPrograms?.isError) {
    fail_notify(myPrograms?.error?.response?.data?.message)
  }
  return (
      <CContainer>
        <CRow>
          <CCol>
            <h3 className="sub-title"> My Programs and Trainings </h3>
          </CCol>
          <CCol className="d-flex justify-content-end">
            <Link to="/employer/create-training">
              <CLoadingButton
                  type="button"
                  title="Add Posting"
                  className="text-white"
              >
                Add a Training
              </CLoadingButton>
            </Link>

          </CCol>
        </CRow>
        <hr/>
        <CRow>
          {myPrograms?.data?.data?.data?.items?.length === 0 &&
              <>
                <DataNotFound/>
              </>
          }
          {myPrograms?.data?.data?.data?.items?.length > 0 &&
              <>
                <CCol sm={4}>
                  <div className="bg-white rounded-2">

                    {
                      myPrograms?.data?.data?.data?.items.map((detail, index) => {
                        return <div key={index}
                                    className={programId === detail.id ? `bg-light-green-2` : ``}>
                          <TrainingCard setProgramId={setProgramId} setTitle={setTitle} detail={detail}/>

                        </div>
                      })
                    }
                  </div>

                </CCol>
                <CCol sm={8}>
                  <HeaderCard title={title}/>
                  <br/>
                  <div className="d-flex flex-wrap">
                    <small
                        className="ps-3 text-primary">Applicants({Trainees?.data?.data?.data?.meta?.totalItems || "0"})</small>

                  </div>
                  <ProgramTrainees Trainees={Trainees}/>
                </CCol>
              </>
          }
        </CRow>
        <br/>
      </CContainer>
  );
}

export default TrainingProgram;