import React, {useEffect, useState} from 'react';
import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CLoadingButton, CModal,
    CModalBody, CModalFooter, CModalHeader,
    CModalTitle,
    CRow
} from "@coreui/react-pro";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {verifyParkwayWalletSchema} from "../../form-schema/user-form-schema/verifyParkwayWalletSchema";
import {useVerifyParkwayWallet} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useNavigate} from "react-router-dom";
import SignUpSuccessImage from "../../assets/images/sign-up-success.svg";

function ParkwayWalletVerification() {
    const [visible, setVisible] = useState(true)
    const {user, storeUser} = useAppContext()
    const navigate = useNavigate()

    const {
        handleSubmit,
        getValues,
        formState: {errors},
        register
    } = useForm({
        resolver: yupResolver(verifyParkwayWalletSchema)
    })

    const {isLoading, isSuccess, data, isError, error, mutate} = useVerifyParkwayWallet()

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }

        if (isSuccess) {
            let updatedObject = {...user}
            updatedObject.data.parkway.walletAccountNumber = getValues("accountNumber")
            updatedObject.data.parkway.walletAccountVerified = true
            storeUser(updatedObject)
            setVisible(false)
            success_notify('Verification Successful')
            navigate(-1)

        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    const handleVerification = (data) => {
        mutate(data)
    }
    return (
        <>
            <CModal
                backdrop="static"
                alignment="center"
                visible={visible}
                onClose={() => {
                    setVisible(false)
                    navigate(-1)
                }}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setVisible(false)}>
                    <div className="d-flex w-100 justify-content-center text-3 fw-bolder text-center">Parkway Wallet
                        Verification
                    </div>
                </CModalHeader>
                <CModalBody>
                    {user.data.parkway.walletAccountVerified ? <div className="d-flex flex-column text-primary justify-content-center align-items-center">
                            <img src={SignUpSuccessImage} alt="Sign up"/>
                            <br/>
                            <h5 className="text-primary">Your Parkway Wallet is verified</h5>
                            <br/>
                        </div> :
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className="mt-2 mb-4 fw-bold">Please verify your Parkway Wallet</p>
                            <CForm className="w-75" onSubmit={handleSubmit(handleVerification)}>
                                <div className="mb-2">
                                    <label>Account Number:</label>
                                    <CFormInput
                                        type="number"
                                        className="text-center"
                                        placeholder="Enter wallet account number"
                                        {...register('accountNumber')}
                                        invalid={!!errors.accountNumber}
                                    />
                                    <CFormFeedback invalid={!!errors.accountNumber}>
                                        {errors.accountNumber?.message}
                                    </CFormFeedback>
                                </div>

                                <div className="my-2">
                                    <label>Authorization Token:</label>
                                    <CFormInput
                                        type="password"
                                        className="text-center"
                                        placeholder="Enter wallet authorization token"
                                        {...register('token')}
                                        invalid={!!errors.token}
                                    />
                                    <CFormFeedback invalid={!!errors.token}>
                                        {errors.token?.message}
                                    </CFormFeedback>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <CLoadingButton loading={isLoading} disabled={isLoading}
                                                    color="primary"
                                                    type="submit"
                                                    className="text-white my-3 px-5"
                                    >
                                        {isLoading ? 'Verifying...' : 'Verify'}
                                    </CLoadingButton>
                                </div>
                            </CForm>
                        </div>}
                </CModalBody>

            </CModal>
        </>
    );
}

export default ParkwayWalletVerification;
