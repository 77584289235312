export const userEnrolled = (training, userId) => {
    //console.log(training);
    let enrollment = false
    for (let i = 0; i < training?.trainingEnrollments?.length; i++){
        if (training?.trainingEnrollments[i].userId === userId) {
            enrollment = true
            break
        }
    }
    return enrollment
}
