import * as Yup from 'yup'

export const AddTraineeToCohortSchema = Yup.object().shape({
    cohortId: Yup.number().typeError('Cohort is required').required('Cohort is required'),
})

export const AddTraineeToTrainerSchema = Yup.object().shape({
    instructorId: Yup.number().typeError('Instructor is required').required('Instructor is required'),
})

