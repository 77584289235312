import React, {useEffect} from 'react';
import {CCol, CContainer, CForm, CLoadingButton} from "@coreui/react-pro";
import {fail_notify, success_notify} from "../../constant";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {RegistrationSchema} from "../../form-schema/user-form-schema/nassimsRegSchema";
import {useNavigate} from "react-router-dom";
import {useNasimSignUpQuery} from "../../hooks/user/userApiHooks";
import ErrorReports from "../../components/ErrorReports";
import {useAppContext} from "../../context/user/UserAppProvider";
import nasimPreloadedData from "../../constant/nasimPreloadedData";
import BioData from "../../components/nasim-registration/BioData";
import AddressMeansOfId from "../../components/nasim-registration/AddressMeansOfId";
import DocumentUpload from "../../components/nasim-registration/DocumentUpload";

function RegistrationForm(nasimData) {
    const navigate = useNavigate()
    const {storeUser} = useAppContext()
    const preloadedData = nasimPreloadedData(nasimData)
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        watch,
        resetField,
    } = useForm({
        resolver: yupResolver(RegistrationSchema),
        defaultValues: preloadedData
    })
    const {isLoading, isSuccess, isError, error, data, mutate} = useNasimSignUpQuery()
    const handleRegistration = (data) => {
        data.passport = data.passport?.[0]
        data.validId = data.validId?.[0]
        mutate(data)
    }
    useEffect(() => {
        if (isSuccess) {
            storeUser(data?.data)
            success_notify('Successfully submitted')
            navigate(`/user/questionnaire/Level-One-Questionnaire`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, error, storeUser, isSuccess])

    return (
        <CContainer>
            <div
                className="bg-light w-100 min-vh-100 border-radius-12 pb-2 px-3 d-flex flex-column align-items-center my-4">
                <h3 className="text-capitalize mb-0 text-black">CONFIRM YOUR PERSONAL INFORMATION</h3>
                <p className="text-medium-emphasis">
                    Kindly go through your data to confirm its validity before you proceed
                </p>
                {
                    isError && <ErrorReports error={error}/>
                }
                <CForm className="w-100" onSubmit={handleSubmit(handleRegistration)}>
                    {/*Bio Data Component*/}
                    <BioData
                        errors={errors}
                        register={register}
                        nasimData={nasimData}
                    />

                    {/* AddressMeansOfId and  Means of Id  */}

                    <AddressMeansOfId
                        register={register}
                        errors={errors}
                    />

                    {/*File Uploads*/}
                    <DocumentUpload
                        control={control}
                        watch={watch}
                        errors={errors}
                        Controller={Controller}
                        resetField={resetField}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                        <CCol xs={6}>
                            <CLoadingButton
                                type="submit"
                                loading={isLoading}
                                disabled={isLoading}
                                className="reg-btn px-4 w-100 py-2"
                            >
                                Submit
                            </CLoadingButton>
                        </CCol>
                    </div>
                </CForm>
            </div>
        </CContainer>
    )
        ;
}

export default RegistrationForm;
