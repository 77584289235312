import {
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {fail_notify, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {certificatesSchema,} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useUpdateUserQuery} from "../../hooks/user/userApiHooks";
import {useNavigate} from "react-router-dom";
import {AiFillPlusSquare, AiOutlineDelete} from "react-icons/ai";

const Certification = ({userData, tab, setTab}) => {
    const navigate = useNavigate()
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()
    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            setTab(tab + 1)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])
    const [formData, setFormData] = useState(userData?.certificates);

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const updatedData = [...formData];
        if(name ==='majors'){
            updatedData[index]['majors'] = [value];
        }
        else {
            updatedData[index][name] = value;
        }

        setFormData(updatedData);
        setValue('certificates', updatedData)

    };

    const handleAdd = () => {
        const newExperience = {
            organization: "",
            certificateTitle: "",
            certificateId: "",
            issuedDate: "",
            expiredDate: "",
            majors: [],
            certificateUrl: "",

        };
        setFormData([...formData, newExperience]);
        setValue('certificates', [...formData, newExperience])

    };
    const handleRemove = (index) => {
        const updatedData = [...formData];
        updatedData.splice(index, 1);
        setFormData(updatedData);
        setValue('certificates', updatedData)
    };
    const {
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(certificatesSchema),
        defaultValues: { certificates: formData },
    })

    const handleFormSubmit = (data) => {
        userData.certificates = data.certificates
        mutate(userData)
    }

    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="bg-white w-100 px-4 py-3 rounded-1">
                {formData.map((experience, index) => (
                    <div key={index}>
                        <div className="w-100 d-flex justify-content-end align-items-center">
                             <span>
                               <AiOutlineDelete onClick={() => handleRemove(index)} className="cursor-pointer" color="red" size={16}/>
                               <small onClick={() => handleRemove(index)} className=" text-danger cursor-pointer px-1">Delete</small>
                           </span>
                        </div>

                        <CRow>
                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Issuing Organisation</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="organization"
                                    placeholder="ICAN"
                                    name="organization"
                                    value={experience.organization}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.organization &&
                                    <small className="text-danger">{errors.certificates[index].organization.message}</small>
                                }
                            </CCol>
                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Certified As</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="certificateTitle"
                                    placeholder="Associate Chartered Accountant"
                                    name="certificateTitle"
                                    value={experience.certificateTitle}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.certificateTitle &&
                                    <small className="text-danger">{errors.certificates[index].certificateTitle.message}</small>
                                }
                            </CCol>

                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Certification ID</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="certificateId"
                                    placeholder="ICAN23443218"
                                    name="certificateId"
                                    value={experience.certificateId}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.certificateId &&
                                    <small className="text-danger">{errors.certificates[index].certificateId.message}</small>
                                }
                            </CCol>

                        </CRow>
                        <CRow>

                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Issued Date</CFormLabel>
                                <CFormInput
                                    type="date"
                                    id="issuedDate"
                                    name="issuedDate"
                                    value={experience.issuedDate}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.issuedDate &&
                                    <small className="text-danger">{errors.certificates[index].issuedDate.message}</small>
                                }
                            </CCol>
                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Expiration Date</CFormLabel>
                                <CFormInput
                                    type="date"
                                    id="expiredDate"
                                    name="expiredDate"
                                    value={experience.expiredDate}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.expiredDate &&
                                    <small className="text-danger">{errors.certificates[index].expiredDate.message}</small>
                                }
                            </CCol>
                            <CCol md={4} className="my-1">
                                <CFormLabel className="m-1 label">Majors</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="majors"
                                    placeholder="Change Management, Business Policy..."
                                    name="majors"
                                    value={experience.majors}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.majors &&
                                    <small className="text-danger">{errors.certificates[index].majors.message}</small>
                                }
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="my-1">
                                <CFormLabel className="m-1 label">Certification URL</CFormLabel>
                                <CFormInput
                                    type="url"
                                    id="certificateUrl"
                                    placeholder="Type Certificate link"
                                    name="certificateUrl"
                                    value={experience.certificateUrl}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control mb-2"

                                />
                                {errors.certificates?.[index]?.certificateUrl &&
                                    <small className="text-danger">{errors.certificates[index].certificateUrl.message}</small>
                                }
                            </CCol>
                        </CRow>
                        <hr/>
                    </div>
                ))}

                <div onClick={handleAdd} className="w-100 d-flex justify-content-center align-items-center">
                    <AiFillPlusSquare className="cursor-pointer" color="#1FAB89" size={30}/>
                    <p className="small-text text-primary cursor-pointer px-1 text-decoration-underline">ADD CERTIFICATIONS</p>
                </div>
            </div>
            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}
                    >
                        SAVE & CONTINUE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default Certification
