import React from 'react';
import {CImage} from "@coreui/react-pro";

import {FaTrashAlt} from "react-icons/fa";
import avatarUrl from "../../assets/images/dummy-profile-pic.png";


function AttendanceProfile({profileTab, setProfTap, profTap, profileDetail, userType}) {

    return (
        <div className="bg-white py-4 h-100 rounded-2" style={{maxWidth: '270px'}}>

            <div className="w-100 d-flex flex-column px-2 align-items-center">
                <CImage src={profileDetail?.user?.profile?.passportUrl || avatarUrl} className="rounded-2"
                        style={{width: '70px', height: '70px'}}/>
                <div className="fw-bolder text-3 p-0 m-0">{`${profileDetail?.user?.firstName}  ${profileDetail?.user?.lastName}`}</div>
                <small>{profileDetail?.course}</small>
                <small className="bg-grey px-4 py-1">{userType}</small>
            </div>
            <br/>
            <br/>
            {profileTab?.map((item, index) => {
                return (
                    <div key={index}
                         onClick={() => {
                             //setProfTap(index);
                             setProfTap(0);
                         }}
                         className={`${profTap == index ? "bg-primary text-white" : "text-black-50"} border border-start-0 border-end-0 border-top-0  px-4 py-3 gap-2 d-flex align-items-center cursor-pointer`}>
                        {item?.icon} <small>{item?.title}</small>
                    </div>

                )
            })}
            <div
                onClick={() => {
                }}
                className="text-danger  border border-start-0 border-end-0 border-top-0 border-danger px-4 py-3 gap-2 d-flex align-items-center cursor-pointer">
                <FaTrashAlt/> <small>Remove Trainer</small>
            </div>
        </div>
    );
}

export default AttendanceProfile;
