import * as Yup from 'yup'

export const basicInformationSchema = Yup.object().shape({
  lastName: Yup.string().required('Last Name is required'),
  firstName: Yup.string().required('First Name is required'),
  otherName: Yup.string(),
  gender: Yup.string().required('Gender is required'),
  address: Yup.string()
    .typeError('Address is required')
    .required('Address is required'),
  stateOfOrigin: Yup.string()
    .required('State of origin is required')
    .typeError('State of origin is required'),
  stateOfResidence: Yup.string().typeError('State of residence is required').required('State of residence is required'),
  dateOfBirth: Yup.string().typeError('Date of birth is required').required('Date of birth is required'),
  localGovernmentOfOrigin: Yup.string().typeError('Local government of origin is required').required('Local government of origin is required'),
  localGovernmentOfResidence: Yup.string().typeError('Local government of residence is required').required('Local government of residence is required'),

  occupation: Yup.string()
    .typeError('Occupation is required')
    .required('Occupation is required'),
   city: Yup.string().typeError("City is required").required("City is required"),
  })

export const personalSummarySchema = Yup.object().shape({
    employmentType: Yup.string().typeError('Employment Type is required').required('Employment Type is required'),
    careerLevel: Yup.string().typeError('Career Level is required').required('Career Level is required'),
    summary: Yup.string().typeError('Personal summary is required').required('Personal summary is required'),
    professionalSkills: Yup.array().typeError('Professional Skills is required').required('Professional Skills is required'),
    jobPreference: Yup.string()
        .typeError('Job Preference is required')
        .required('Job Preference is required'),
    availability: Yup.string()
        .required('Availability is required')
        .typeError('Availability is required'),

})

const workExperience = Yup.array().of(
    Yup.object().shape({
      companyName: Yup.string().required('Company Name is required'),
      jobTitle: Yup.string().required('Job Title is required'),
      preference: Yup.string().required('Preference is required'),
      startDate: Yup.string().required('Start Date is required'),
      currentlyWorking: Yup.boolean(),
      endDate: Yup.string().when('currentlyWorking', {
            is: false,
            then: Yup.string().required('End Date is required'),}),
      location: Yup.string().required('Location is required'),
      description: Yup.string().required('Description is required'),
    })
);

const Education = Yup.array().of(
    Yup.object().shape({
        schoolName: Yup.string().required('School Name is required'),
        degree: Yup.string().required('Degree is required'),
        fieldOfStudy: Yup.string().required('Field Of Study is required'),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        location: Yup.string().required('Location is required'),
        description: Yup.string().required('Description is required'),
        //grade: Yup.string().required('Description is required'),
        //positionHeld: Yup.string().required('Description is required'),
        //majors: Yup.array().required('Description is required'),
    })
);

const Certificates = Yup.array().of(
    Yup.object().shape({
        organization: Yup.string().required('Issuing Organisation name is required'),
        certificateTitle: Yup.string().required('Certificate Title is required'),
        //certificateId: Yup.string().required('Certification ID is required'),
        issuedDate: Yup.string().required('Issued Date is required'),
        //expiredDate: Yup.string().required('End Date is required'),
        //certificateUrl: Yup.string().required('Location is required'),
        //majors: Yup.array().required('Description is required'),
    })
);

const Project = Yup.array().of(
    Yup.object().shape({
        projectName: Yup.string().required('Project/Portfolio name is required'),
        projectUrl: Yup.string().required('Project/Portfolio URL is required'),

    })
);

export const workExperienceSchema =Yup.object().shape({
    workExperience: workExperience,
});

export const educationSchema =Yup.object().shape({
    educations: Education,
});
export const certificatesSchema =Yup.object().shape({
    certificates: Certificates,
});

export const projectsSchema =Yup.object().shape({
    projects: Project,
});


export const userUpdateProfileSchema = Yup.object().shape({
  lastName: Yup.string().required('Last Name is required'),
  firstName: Yup.string().required('First Name is required'),
  otherName: Yup.string(),
  gender: Yup.string().required('Gender is required'),
  address: Yup.string()
      .typeError('Address is required')
      .required('Address is required'),
  stateOfOrigin: Yup.string()
      .required('State of origin is required')
      .typeError('State of origin is required'),
  stateOfResidence: Yup.string().required('State of residence is required'),
  dateOfBirth: Yup.string().required('Date of birth is required'),
  localGovernmentOfOrigin: Yup.string().required('Local government of origin is required'),
  localGovernmentOfResidence: Yup.string().required('Local government of residence is required'),
  disability: Yup.string().required('Disability status is required'),
  educationLevel: Yup.string().required('Education level is required'),
  employmentStatus: Yup.string()
      .typeError('Employment status is required')
      .required('Employment status is required'),
  occupation: Yup.string()
      .typeError('Occupation is required')
      .required('Occupation is required'),
  professionalSkills: Yup.array().nullable(),
  employmentType: Yup.array().required("Employment type is required"),
  availability: Yup.string().required("Availability is required"),
  jobPreference: Yup.string().required("Job preference is required"),
  careerLevel: Yup.string().required("Career level is required"),
  city: Yup.string().required("City is required"),
  schoolAttended: Yup.string().required("School Attended level is required"),
})


