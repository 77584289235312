import React, {useEffect} from 'react'
import {
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CLoadingButton,
    CRow,
} from '@coreui/react-pro'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useNavigate} from 'react-router-dom'
import ErrorReports from '../../components/ErrorReports'
import 'react-datepicker/dist/react-datepicker.css'
import {useStakehoderinSignUpQuery,} from 'src/hooks/employer/employerApiHooks'
import {stakeholderRegSchema} from 'src/form-schema/stake-holder-schema/stakeholderRegSchema'
import DatePicker from 'react-datepicker'
import ShowLoading from 'src/components/ShowLoading'
import {fail_notify, success_notify} from "../../constant";
import RegSuccess from "../../user/pages/user-registration/RegSuccess";

function EmployerRegistration({prevState, validatedData}) {

    const preLoadedData = {
        name: validatedData?.TaxPayerName,
        phoneNumber: validatedData?.Phone,
        address: validatedData?.Address,
        email: validatedData?.Email,
        password: null
    }
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
    } = useForm({
        defaultValues: preLoadedData,
        resolver: yupResolver(stakeholderRegSchema)
    })

    const {isLoading, isSuccess, isError, error, data, mutate} = useStakehoderinSignUpQuery()

    const handleRegistration = (data) => {
        const formData = Object.assign(data, prevState)
        mutate(formData)
    }

    useEffect(() => {
        if (isSuccess) {
            success_notify('successfully created')
            setTimeout(() => {
                navigate(`/login`, {
                    replace: true,
                })

            }, 3000)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])
    return (
        <CContainer>
            <div className="min-vh-100 w-100 d-flex flex-column align-items-center">
                <div className="border-radius-12 py-3 st-card d-flex flex-column align-items-center">
                    <h3 className="text-capitalize mb-0 text-black">EMPLOYER REGISTRATION</h3>

                    {isError && <ErrorReports error={error}/>}
                    {isLoading && <ShowLoading/>}
                    <CForm className="w-100" onSubmit={handleSubmit(handleRegistration)}>
                        <hr className="mt-1"/>
                        <CCol md={12} className="mb-5">
                            <CRow className="gy-5">
                                <CCol md={12} className=" section-card">
                                    <CRow className="gy-4">
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Name</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                id="name"
                                                placeholder="Name"
                                                {...register('name')}
                                                invalid={!!errors.name}
                                            />
                                            <CFormFeedback
                                                invalid={!!errors.name}>{errors.name?.message}</CFormFeedback>
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Date Established</CFormLabel>

                                            <Controller
                                                control={control}
                                                name="dateEstablished"
                                                render={({field}) => (
                                                    <DatePicker
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={'Date Established'}
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                        maxDate={new Date()}
                                                        isClearable
                                                        className={`form-control  ${!!errors.dateEstablished && 'is-invalid'}`}
                                                    />
                                                )}
                                            />
                                            <CFormInput type="hidden" invalid={!!errors.dateEstablished}/>
                                            <CFormFeedback invalid={!!errors.dateEstablished}>
                                                {errors.dateEstablished?.message}
                                            </CFormFeedback>
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Email Address</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Email Address"
                                                id="email"
                                                {...register('email')}
                                                invalid={!!errors.email}
                                                //disabled={!!nasimData}
                                            />
                                            <CFormFeedback invalid={!!errors.email}>
                                                {errors.email?.message}
                                            </CFormFeedback>
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Phone Number</CFormLabel>
                                            <CFormInput
                                                type="text"
                                                placeholder="Phone Number"
                                                id="phoneNumber"
                                                {...register('phoneNumber')}
                                                invalid={!!errors.phoneNumber}

                                            />
                                            <CFormFeedback invalid={!!errors.phoneNumber}>
                                                {errors.phoneNumber?.message}
                                            </CFormFeedback>
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Password</CFormLabel>
                                            <CFormInput
                                                type="password"
                                                placeholder="Password"
                                                id="password"
                                                {...register('password')}
                                                invalid={!!errors.password}

                                            />
                                            <CFormFeedback invalid={!!errors.password}>
                                                {errors.password?.message}
                                            </CFormFeedback>
                                        </CCol>
                                        <CCol md={6}>
                                            <CFormLabel className="m-1 label">Confirm Password</CFormLabel>
                                            <CFormInput
                                                type="password"
                                                placeholder="Confirm Password"
                                                id="confirmPassword"
                                                {...register('confirmPassword')}
                                                invalid={!!errors.confirmPassword}
                                            />
                                            <CFormFeedback invalid={!!errors.confirmPassword}>
                                                {errors.confirmPassword?.message}
                                            </CFormFeedback>
                                        </CCol>
                                        <CFormLabel className="m-1 label">Enter Address</CFormLabel>

                                        <CCol md={12}>
                                            <CFormLabel className="m-1 label">Organization Address</CFormLabel>

                                            <CFormTextarea
                                                id="address"
                                                rows="3"
                                                placeholder="Enter Address"
                                                {...register('address')}
                                                invalid={!!errors.address}
                                            ></CFormTextarea>

                                            <CFormFeedback invalid={!!errors.address}>
                                                {errors.address?.message}
                                            </CFormFeedback>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </CCol>

                        <div className="d-flex justify-content-left align-items-center">
                            <CCol md={4}>
                                <CLoadingButton
                                    type="submit"
                                    className="reg-btn px-4 w-100 py-2"
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    Submit
                                </CLoadingButton>
                            </CCol>
                        </div>
                    </CForm>
                </div>
            </div>

            {
                isSuccess &&
                <RegSuccess/>

            }
        </CContainer>
    )
}

export default EmployerRegistration
