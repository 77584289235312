import React from 'react';
import ReportProvider from "../../../context/admin/ReportProvider";
import SkillsReport from "./SkillsReport";

const AllSkillsReports = () => {

    return (
        <>
            <ReportProvider>
                <SkillsReport/>
            </ReportProvider>
        </>
    )
}
export default AllSkillsReports
