import React from 'react';
import {sortTable} from "../../constant";
import {BiSort} from "react-icons/bi";
import {CFormCheck} from "@coreui/react-pro";

function Table({headers, children, checkBox}) {
    return (
        <div className="table-responsive">
            <table className="table table-responsive" id="reports-tables">
                <thead className="thead-inverse bg-transparent text-primary">
                <tr>
                    {
                        checkBox &&
                        <th className="ps-2">
                            <CFormCheck id="flexCheckDefault" label=""/>
                        </th>
                    }
                    {headers?.map((header, index) => {

                            return (
                                <th className={`${header?.sort && "cursor-pointer"} ${header?.className}`} key={index}
                                    onClick={() => {
                                        if (header?.sort) {
                                            sortTable("reports-tables", index)
                                        }
                                    }}>
                                    <div className="d-flex align-content-center align-items-center ">
                                        {header?.leftIcon && <div>{header?.leftIcon}</div>}
                                        {header?.name}
                                        <div>{header?.sort && <BiSort size={15}/>}</div>
                                        {header?.rightIcon && <div>{header?.rightIcon}</div>}
                                    </div>
                                </th>
                            )
                        }
                    )}

                </tr>
                </thead>
                <tbody>
                {children}
                </tbody>
            </table>
        </div>

    );
}

export default Table;
