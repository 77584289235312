import React from 'react';

function ProfileProgress({progress, text}) {

    return (
        <div className="w-100">
            {text ? <div className="job-body pt-1 w-100 d-flex justify-content-between" style={{color: "#D9A902 "}}>
                <span className="text-white fw-bold">{text}</span>
                <span >{progress}</span>
                </div>
                :<div className="job-body pt-1 w-100 d-flex justify-content-end"
                  style={{color: "#D9A902 "}}>{progress}</div>

            }            <div className="progress my-1 rounded w-100" style={{height: "10px" }}>
                <div className="progress-bar rounded-0" style={{width: progress, backgroundColor: "#D9A902"}} role="progressbar"
                     aria-valuenow="0" aria-valuemin="0"
                     aria-valuemax="100">

                </div>
            </div>
        </div>
    );
}

export default ProfileProgress;
