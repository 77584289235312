import React, {useEffect, useState} from 'react';
import {CButton, CFormSelect} from "@coreui/react-pro";
import {BiEnvelope} from "react-icons/bi";
// import {BsTelephone} from "react-icons/bs";
import moment from "moment/moment";
import {useApplicationStatusUpdate} from "../../../hooks/employer/employerApiHooks";
import {fail_notify} from "../../../constant";
import DataNotFound from "../../DataNotFound";
import JobAplicantView from "../../modals/JobAplicantView";
// import {isInt} from "@fullcalendar/react";
import InterviewSchedulingModal from "../../modals/InterviewSchedulingModal";

function JobApplicants({jobApplicants, setQueryKey, jobId, employerJobs, setLimit, limit}) {

    const [interStatus, setInterStatus] = useState("Open")
    const [visible, setVisible] = useState(false)
    const [applicantDetail, setApplicantDetail] = useState(false)
    const {isLoading, isSuccess, data, isError, error, mutate} = useApplicationStatusUpdate()
    const [isInterviewVisible, setIsInterviewVisible] = useState(false)
    const [interviewUserId, setInterviewUserId] = useState(null)
    const usersIds = jobApplicants?.map(({id}) => id)
    const [applicationStatus, setApplicationStatus] = useState('')

    const interviewStatus = [
        {status: 'Open'},
        // {status: 'Sieving'},
        {status: 'Interview schedule'},
        {status: 'Rejected'},
        {status: 'Passed'},
    ]

    useEffect(() => {
        setQueryKey(new Date())
        if (isError) {
            fail_notify("Status no updated Please try again")
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    // employerJobs?.data?.data?.data?.items?.map((applied, index) => {
    //     return applied?.applyForJob?.map((applicant) => {
    //         if (usersIds?.includes(applicant?.userId)) {
    //             return applicant?.applicationStatus
    //         }
    //         return 'Open'
    //     })
    // })

    const structureContact = (email) => {
        return <span className="my-2"><BiEnvelope/> {email}</span>
    }

    const convertAction = (action) => {
        return <CButton
            className="py-2 px-2 rounded text-white bg-primary"
            onClick={() => window.location.replace(action?.JobsApplied[0]?.cvUrl)}>
            Download CV
        </CButton>
    }

    const InterviewStatus = (userId) => {
        return <CFormSelect size="sm" className="mb-2" aria-label="Small select example" onChange={(e) => {
            if (e.target.value === 'Interview schedule') {
                setIsInterviewVisible(true)
                setInterviewUserId(userId)
            }
            mutate({interviewStatus: e.target.value})

        }}>
            <option value={interStatus}>{interStatus}</option>

            {interviewStatus.map((item, index) => {

                if (item.status !== interStatus) {
                    return (
                        <option key={index} value={item.status}>{item.status}</option>
                    );
                }
            })}
        </CFormSelect>
    }

    const convertUserDisplay = (user) => {

        return (
            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                <div style={{marginBottom: "0", maxWidth: "160px"}} className="text-truncate">
                    <b>{user?.firstName} {user?.lastName}</b></div>
            </div>
        )
    }

    const displayApplicationStatus = () => {
        let status = 'Open'
        employerJobs?.data?.data?.data?.items?.map((applied, index) => {
            return applied?.applyForJob?.map((applicant) => {
                if (usersIds?.includes(applicant?.userId) && index === 0) {
                    status = applicant?.applicationStatus
                    return status
                }
                return ''
            })
        })
        return status
    }

    return (
        <div className="table-responsive">
            <div className="border border-2 rounded-3 table-border-grey bg-white">
                <table className="table">
                    <thead>
                    <tr style={{backgroundColor: "#f8f8f8", color: "gray"}}>
                        <th>Applicants</th>
                        <th>Contact</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {jobApplicants?.map((item, index) => {
                        return <tr key={index} className="applicant-table-row">
                            <td className="td-single-line">{convertUserDisplay(item)}</td>
                            <td className="td-single-line">{structureContact(item.email)}</td>
                            <td className="td-single-line">{moment(item?.JobsApplied[0]?.createdAt).format("ll") || 'Nil'}</td>
                            <td className="td-single-line">{displayApplicationStatus()}</td>
                            <td className="td-single-line">{InterviewStatus(item?.id)}</td>
                            <td className="td-single-line">
                                <small className="cursor-pointer py-none py-2 px-2 rounded text-white bg-green-100"
                                       onClick={() => {
                                           setApplicantDetail(item)
                                           setVisible(true)
                                       }}
                                >View details</small>
                            </td>

                        </tr>
                    })}

                    </tbody>
                </table>
                {jobApplicants?.length < 1 &&
                    <DataNotFound/>
                }

            </div>
            {visible && <JobAplicantView data={applicantDetail} downloadCV={convertAction} setVisible={setVisible}/>}
            {isInterviewVisible &&
                <InterviewSchedulingModal
                    visible={isInterviewVisible}
                    setVisible={setIsInterviewVisible}
                    jobId={jobId}
                    userId={interviewUserId}
                />
            }
        </div>
    );
}

export default JobApplicants;
