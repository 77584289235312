import {CCol, CRow} from "@coreui/react-pro";
import React, {useEffect, useState} from "react";
import TrainerProfile from "./TrainerProfile";
import TransferIcon from "../../assets/icons/transfer.svg";
import TrainerToCohortModal from "../../components/modals/TrainerToCohortModal";
import {useGetKPITrainers} from "../../hooks/admin/adminApiHooks";
import ShowLoading from "../../components/ShowLoading";
import TableNavigation from "../../components/TableNavigation";
import AddCohortModal from "../../components/modals/AddCohortModal";
import AddTrainerModal from "../../components/modals/AddTrainerModal";
import KPIManagerBanner from "./KPIManagerBanner";

const TrainerDashboard = ({cohortModalVisible, setCohortModalVisible, trainerModalVisible, setTrainerModalVisible}) => {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [viewProfile, setViewProfile] = useState(null)
    const [trainerData, setTrainerData] = useState(null)
    const [trainerCohortModalVisible, setTrainerCohortModalVisible] = useState(false)
    const [instructorId, setInstructorId] = useState(0)

    const {
        data,
        isLoading,
        refetch
    } = useGetKPITrainers(page, limit)

    useEffect(() => {
        refetch()
    }, [page, limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    const TableRow = ({ rowData }) => {
        return (
            <tr className="py-2 my-1">
                <td>
                    <div className="d-flex flex-row align-items-center">
                        <input type="checkbox" className="me-2 larger-checkbox"/>
                        <div className="ms-2" onClick={() => {
                            setTrainerData(rowData)
                            setViewProfile(1)
                        }}>
                            <h6 className="m-0 p-0">{rowData?.firstName} {rowData?.lastName}</h6>
                            <p className="m-0 p-0 kpi-table-email">{rowData?.email}</p>
                        </div>
                    </div>
                </td>
                <td>{rowData?.phoneNumber}</td>
                <td>{rowData?.state}</td>
                <td>{rowData?.center}</td>
                <td onClick={() => {
                    setTrainerCohortModalVisible(true)
                    setInstructorId(rowData?.id)
                }}>
                    <img src={TransferIcon} alt="Transfer"/>
                    <span className="px-2 text-primary d-inline">Assign Cohort/Course</span>
                </td>
            </tr>
        )
    }

    return <div>
        {!!viewProfile && <TrainerProfile data={trainerData} setViewProfile={setViewProfile}/>}
        {!viewProfile &&
            <>
                <CRow className="mb-3 bg-white p-3">
                    <KPIManagerBanner
                        setTrainerModalVisible={setTrainerModalVisible}
                        setCohortModalVisible={setCohortModalVisible}
                        title={'Trainers'}
                        totalItems={data?.data?.data?.meta?.totalItems}
                    />
                </CRow>
                <CRow>
                    <CCol md={12}>
                        <table className="table border-0" id="reports-table">
                            <thead className="thead-inverse">
                            <tr className="bg-transparent text-primary">
                                <th>Trainers</th>
                                <th>Phone Number</th>
                                <th>State</th>
                                <th>Center</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data?.data?.data?.items?.map((item, index) => (
                                    <TableRow key={index} rowData={item} />
                                ))
                            }
                            </tbody>
                        </table>
                        <TableNavigation
                            page={page}
                            setLimit={setLimit}
                            setPage={setPage}
                            totalPages={data?.data?.data?.meta?.totalPages}
                        />
                    </CCol>
                </CRow>
            </>
        }

        <TrainerToCohortModal
            setVisible={setTrainerCohortModalVisible}
            visible={trainerCohortModalVisible}
            instructorId={instructorId}
        />

        <AddCohortModal
            setVisible={setCohortModalVisible}
            visible={cohortModalVisible}
        />

        <AddTrainerModal
            setVisible={setTrainerModalVisible}
            visible={trainerModalVisible}
        />
    </div>
}

export default TrainerDashboard