const personalDetail = (data, preDate) => {
  return {
    firstName: data?.firstName || preDate?.firstName,
    lastName: data?.lastName || preDate?.lastName,
    address: data?.address || preDate?.profile?.address,
    city: data?.city || preDate?.profile?.city,
    profession: data?.profession || preDate?.profile?.occupation,
    otherName: data?.otherName || preDate?.otherName,
    email: data?.email || preDate?.email,
    phoneNumber: data?.phoneNumber || preDate?.phoneNumber,
    nationality: data?.nationality,
    languages: data?.languages,
    socialLinks: data?.socialLinks,
    zipCode: data?.zipCode,
    careerObjective: data?.careerObjective,
    passport: preDate?.profile?.passportUrl
  }
}

export default personalDetail
