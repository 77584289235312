import React from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import WelcomeImage from '../../assets/images/welcome-image.svg';
import TalentPoolImage from '../../assets/icons/national-talent-pool.svg';
import SegmentationImage from '../../assets/icons/talent-segmenation.svg';
import CareerImage from '../../assets/icons/career-building.svg';
import CapacityImage from '../../assets/icons/capacity-buiding.svg';
import DeploymentImage from '../../assets/icons/talent-deployment.svg';
import {Link} from "react-router-dom";

const WelcomeSection = () => {
    return <CContainer className="p-5" id="about">
        <CRow>
            <CCol md={6}>
                <div>
                    <h1><strong>Welcome to<br/><span className="text-primary">WorkNation</span></strong></h1>
                    <p>A labour administration platform designed to address Africa&apos;s talent gaps, aligning them
                        with global labour and digital economy needs. Through strategic partnerships with international
                        organizations, national and subnational government agencies, sponsors, training academies and
                        employers, we match talents with national, regional and global opportunities</p>

                    <p className={'mt-4'}>WorkNation, serves as the digital platform of the <span
                        className={'text-primary '}> <a href={'https://otni.ogtlprojects.com/'} className={'text-primary'}>Outsource to Nigeria Initiative (OTNI)</a></span>, a
                        private sector-led and government-enabled initiative focused on national job creation. By
                        powering OTNI, we enable partners to source, train, and deploy talents tailored to their needs,
                        contributing to job creation, economic growth, and poverty alleviation.</p>

                    <Link to="/user/signup">
                        <button className="btn btn-primary btn-lg text-white w-40 py-3 smaller-text-12 mt-4">GET
                            STARTED
                        </button>
                    </Link>
                </div>
                <div className="mt-3">
                    <CImage src={WelcomeImage} alt="Welcome" style={{width: '100%'}}/>
                </div>
            </CCol>
            <CCol md={6} className="mt-5 pt-5">
                <h1><strong>Our <span className="text-primary">End-to-End</span> Process</strong></h1>
                <div
                    className="d-flex flex-row justify-content-start align-items-start pb-4 mt-3 landing-section-border process-div">
                    <CImage src={TalentPoolImage} className="pt-2"/>
                    <div className="ms-2">
                        <h6 className="m-0 mt-2 mb-1 process-heading"><strong>Talent Pool</strong></h6>
                        <p className="process-body">Worknation has collaborated with esteemed organizations (government
                            and non-government agencies) to create an extensive database of skilled professionals and
                            graduates. This ensures a comprehensive talent pool tailored to meet specific workforce
                            demands.</p>
                    </div>
                </div>
                <div
                    className="d-flex flex-row justify-content-start align-items-start pb-4 mt-3 landing-section-border process-div">
                    <CImage src={SegmentationImage} className="pt-2"/>
                    <div className="ms-2">
                        <h6 className="m-0 mt-2 mb-1 process-heading"><strong>Talent Segmentation</strong></h6>
                        <p className="process-body">Through an innovative demand-driven approach, we are constantly
                            identifying and curating relevant industry verticals with the potential for large scale
                            employment globally.</p>
                    </div>
                </div>
                <div
                    className="d-flex flex-row justify-content-start align-items-start pb-4 mt-3 landing-section-border process-div">
                    <CImage src={CareerImage} className="pt-2"/>
                    <div className="ms-2">
                        <h6 className="m-0 mt-2 mb-1 process-heading"><strong>Career Building</strong></h6>
                        <p className="process-body">We provide talents with a platform to create professional
                            profiles/resumes and employ a rigorous process to match talents with the curated domestic
                            and international industry verticals. Our advanced algorithms and personalized assessments
                            guarantee a seamless alignment of skills with job and industry requirements.</p>
                    </div>
                </div>
                <div
                    className="d-flex flex-row justify-content-start align-items-start pb-4 mt-3 landing-section-border process-div">
                    <CImage src={CapacityImage} className="pt-2"/>
                    <div className="ms-2">
                        <h6 className="m-0 mt-2 mb-1 process-heading"><strong>Training & Capacity Building</strong></h6>
                        <div className="process-body">
                            <p>Through established partnerships with renowned local and international training
                                institutions, we offer online and on-premise training and capacity building courses
                                across diverse industry verticals, preparing talents for the identified industry
                                opportunities.</p>
                            <br/>
                            <p className="">The platform provides tools for sponsors (development partners, government
                                agencies, Training Academies and employers) to monitor and administer program
                                sponsorships (training, internships, etc).</p>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-row justify-content-start align-items-start pb-4 mt-3 landing-section-border process-div">
                    <CImage src={DeploymentImage} className="pt-2"/>
                    <div className="ms-2">
                        <h6 className="m-0 mt-2 mb-1 process-heading"><strong>Talent Deployment</strong></h6>
                        <div className="process-body">
                            <p>The platform provides employers and their HR managers with a full-fledged recruitment
                                suite with automated job talent matching, personality and culture fit analysis,
                                interview scheduling and digital resume reporting to facilitate speedy recruitment and
                                onboarding of talents from Worknation.
                            </p>
                            <br/>
                            <p>The platform also provides direct talent deployment through the connected global Business
                                Process Outsourcing (BPO) partners.</p>
                        </div>
                    </div>
                </div>
            </CCol>
        </CRow>
    </CContainer>
}

export default WelcomeSection;
