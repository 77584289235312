import React from "react";
import create_cv from "../../assets/icons/create_cv.svg";
import {Link} from "react-router-dom";

const CreateResume = () => {
    return (
        <>
            <div className="bg-grey-resume pt-4 px-2 rounded-3 my-2 h-100">
                <h6 className="fw-bold text-center">Create My Resume</h6>
                <p className="text-center sub-body">Select this option if you want to create your résumé with
                    WorkNation, afterwards it will be uploaded to your profile.</p>
                <br/>
                <center className="w-100">
                    <Link to="/user/resume-builder" className="text-primary nav-link">
                        <label className="w-100 cursor-pointer">
                            <div className="py-3 w-100 border rounded border-dark">
                                <img src={create_cv} className="cursor-pointer" alt="Upload CV"/> <br/><br/>
                                <small>{"Create Resume Here"}</small>
                            </div>

                        </label>
                    </Link>
                </center>

            </div>
        </>
    )
}

export default CreateResume