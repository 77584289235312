import React, {useEffect, useState} from 'react';
import {
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import GroupRadioButton from "../../buttons/GroupRadioButton";
import TrainingPreview from "../../modals/TrainingPreview";
import {TrainingSchema} from "../../../form-schema/stake-holder-schema/TrainingSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {useStakeholderCreateTraining} from "../../../hooks/employer/employerApiHooks";
import {format} from "date-fns";
import {fail_notify, success_notify} from "../../../constant";
import TextEditor from "../../TextEditor";

const modeOfTraining = [
    {id: 1, title: 'Remote'},
    {id: 2, title: 'On Site'},
    {id: 2, title: 'Both'},

]

const trainingType = [
    {id: 1, title: 'Paid'},
    {id: 2, title: 'Free'},

]

function TrainingForm() {

    const [preview, setPreview] = useState(false)
    const [formData, setFormData] = useState({})
    const {isSuccess, isLoading, error, mutate, isError, data} = useStakeholderCreateTraining()

    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');
    const [courseContent, setCourseContent] = useState('');
    const [descWarning, setDescWarning] = useState('');
    const [reqWarning, setReqWarning] = useState('');
    const [contentWarning, setContentWarning] = useState('');

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
        watch,
        reset
    } = useForm({
        resolver: yupResolver(TrainingSchema)
    })

    useEffect(() => {
        if (isSuccess) {
            success_notify('Training Successfully Added')
            setDescription('')
            setRequirements('')
            setCourseContent('')
            reset()
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, error, isSuccess])

    const handleTraining = (data) => {
        if (!description) {
            setDescWarning('Description is required')
            return
        } else { setDescWarning('') }

        if (!courseContent) {
            setContentWarning('Course Content is required')
            return
        } else { setContentWarning('') }

        if (!requirements) {
            setReqWarning('Requirements is required')
            return
        } else { setReqWarning('') }

        const endDate = new Date(data?.endDate)
        const startDate = new Date(data?.startDate)
        data.endDate = format(endDate, "MM-dd-yyyy")
        data.startDate = format(startDate, "MM-dd-yyyy")

        const formData = new FormData();
        const fileInput = document.getElementById("file");
        const file = fileInput.files[0];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (key === "file") {
                    formData.append("file", file);
                } else if (Array.isArray(data[key])) {
                    data[key].forEach((item, i) => {
                        for (const subkey in item) {
                            if (item.hasOwnProperty(subkey)) {
                                if (subkey === "contents") {
                                    item[subkey].forEach((content, j) => {
                                        for (const contentKey in content) {
                                            formData.append(`${key}[${i}][${subkey}][${j}][${contentKey}]`, content[contentKey]);
                                        }
                                    });
                                } else {
                                    formData.append(`${key}[${i}][${subkey}]`, item[subkey]);
                                }
                            }
                        }
                    });
                } else {
                    formData.append(key, data[key]);
                }
            }
        }

        data.isPublished = true
        const fullDate = new Date(data?.endDate)
        data.closingDate = format(fullDate, "MM-dd-yyyy")
        data.description = description
        data.courseContent = courseContent
        data.requirements = requirements

        Object.assign(formData, data)
        formData.append("description", description)
        formData.append("courseContent", courseContent)
        formData.append("requirements", requirements)

        mutate(formData)
    }

    return (
        <CRow className="form-card p-4">
            <CRow className="d-flex flex-row  py-20">
                <TrainingPreview visible={preview} setVisible={setPreview} data={formData}/>
                <CCol sm={8}>
                    <h3 className="sub-title">TRAINING INFORMATION</h3>
                </CCol>
                <hr/>
            </CRow>
            <CForm className="w-100" onSubmit={handleSubmit(handleTraining)}>
                <CRow>
                    <CCol className="">
                        <CRow>
                            <CCol lg={6} className="my-1">
                                <CFormLabel className="m-1 label">Enter Training Title</CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="Enter Training Title"
                                    {...register('title')}
                                    invalid={!!errors.title?.toString()}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.title?.toString()}>
                                    {errors.title?.message}
                                </CFormFeedback>
                            </CCol>

                            <CCol lg={6} className="my-1">
                                <CFormLabel className="m-1 label">Training Duration</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="trainingDuration"
                                    placeholder="Example 1 month, 2 months, 4 months"
                                    {...register('trainingDuration')}
                                    invalid={!!errors.trainingDuration?.toString()}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.trainingDuration?.toString()}>
                                    {errors.trainingDuration?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                    </CCol>

                </CRow>
                <CRow className="mt-4">
                    <CCol md={6} className="">
                        <CFormLabel className="m-1 label"> Start Date</CFormLabel>
                        <Controller
                            control={control}
                            name="startDate"
                            render={({field}) => (
                                <DatePicker
                                    showYearDropdown
                                    showMonthDropdown
                                    placeholderText={'Start Date'}
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    minDate={new Date()}
                                    isClearable
                                    className={`form-control  ${!!errors.startDate && 'is-invalid'}`}
                                />
                            )}
                        />

                    </CCol>
                    <CCol md={6}>
                        <CFormLabel className="m-1 label"> End Date</CFormLabel>
                        <Controller
                            control={control}
                            name="endDate"
                            render={({field}) => (
                                <DatePicker
                                    showYearDropdown
                                    showMonthDropdown
                                    placeholderText={'End Date'}
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    minDate={new Date()}
                                    isClearable
                                    className={`form-control  ${!!errors.startDate && 'is-invalid'}`}
                                />
                            )}
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CRow>
                        <CCol className="mt-4" md={6}>
                            <h3 className="sub-title"> Mode Of Training</h3>
                            <Controller
                                control={control}
                                name="modeOfTraining"
                                render={({field}) => (<GroupRadioButton
                                    name="modeOfTraining"
                                    data={modeOfTraining}
                                    onChange={field.onChange}
                                    value={field.value}
                                />)}
                            />
                            <CFormFeedback invalid={!!errors.modeOfTraining?.toString()}>
                                {errors.modeOfTraining?.message}
                            </CFormFeedback>
                        </CCol>
                        <CCol className="mt-4" md={6}>
                            <h3 className="sub-title">Training Type</h3>
                            <Controller
                                control={control}
                                name="trainingType"
                                render={({field}) => (<GroupRadioButton
                                    name="trainingType"
                                    data={trainingType}
                                    onChange={field.onChange}
                                    value={field.value}
                                />)}
                            />
                            <CFormFeedback invalid={!!errors.trainingType?.toString()}>
                                {errors.trainingType?.message}
                            </CFormFeedback>
                        </CCol>
                    </CRow>
                    <CRow className="mt-4">
                        {watch('modeOfTraining') === "On Site" && <CCol className="my-1">
                            <CFormLabel className="m-1 sub-title">Training Address</CFormLabel>
                            <CFormTextarea
                                id="address"
                                rows="3"
                                placeholder="Enter Training Address"
                                {...register('address')}
                                invalid={!!errors.address}
                            ></CFormTextarea>
                            <CFormFeedback invalid={!!errors.address?.toString()}>
                                {errors.address?.message}
                            </CFormFeedback>
                        </CCol>}
                    </CRow>
                    <CCol className="mt-4">
                        <h3 className="sub-title">Training Cover Image</h3>
                        <CFormInput
                            type="file"
                            name="file"
                            id="file"
                            accept=".png, .jpg, .jpeg"
                            {...register('file')}
                            invalid={!!errors.file?.toString()}
                            className="mb-2"
                        />
                        <CFormFeedback invalid={!!errors.file?.toString()}>
                            {errors.file?.message}
                        </CFormFeedback>
                    </CCol>
                    <CRow className="mt-4 pe-0">
                        <CCol md={12} className="my-1 pe-0">
                            <CFormLabel className="m-1 sub-title">Training Description</CFormLabel>
                            <TextEditor
                                editorState={description}
                                setEditorState={setDescription}
                            />
                            <p className="input-warning">{descWarning}</p>
                        </CCol>
                    </CRow>
                    <CCol className="mt-4">
                        <h3 className="sub-title">Course Content</h3>
                        <TextEditor
                            editorState={courseContent}
                            setEditorState={setCourseContent}
                        />
                        <p className="input-warning">{contentWarning}</p>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <h3 className="sub-title mt-3">Requirements</h3>
                        <TextEditor
                            editorState={requirements}
                            setEditorState={setRequirements}
                        />
                        <p className="input-warning">{reqWarning}</p>
                    </CCol>
                </CRow>
                <CRow className="d-flex flex-row justify-content-end my-3">
                    <CCol sm={4} className="d-flex flex-row justify-content-end">
                        <CLoadingButton
                            type="submit"
                            loading={isLoading}
                            disabled={isLoading}
                            className="text-white">
                            Submit
                        </CLoadingButton>
                    </CCol>
                </CRow>
            </CForm>
        </CRow>
    );
}

export default TrainingForm;
