import React, {useEffect} from 'react';
import {AiFillCloseCircle} from "react-icons/ai";
import {CirclePicker} from "react-color";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";

function ColorFormatter({setVisible, visible, setMainColor}) {
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();

    const handleChangeComplete = (color) => {
        const themes = {
            mainColor: color.hex
        }
        setMainColor(color.hex)
        mutate({themes})
    };


    return (
        <>
            <div className={'mt-3 d-flex gap-5'}>
                <div className={'mb-2 cursor-pointer'}>
                    <AiFillCloseCircle
                        size={30}
                        color={"green"}
                        onClick={() => setVisible(!visible)}
                    />
                </div>
                <div>
                    <CirclePicker
                        onChangeComplete={handleChangeComplete}
                        colors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', '#045F2D']}
                    />
                </div>
            </div>
        </>
    );
}

export default ColorFormatter;