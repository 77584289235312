import {
    CCol,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {fail_notify, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import appApiCalls from "../../services/api";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    basicInformationSchema,
} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useGetNigerianCities, useUpdateUserQuery} from "../../hooks/user/userApiHooks";
import {useNavigate} from "react-router-dom";

const BasicInformation = ({userData, states, tab, setTab}) => {
    const navigate = useNavigate()
    const [lgasOfResidence, setlgasOfResidence] = useState([])
    const [lgasOfOrigin, setlgasOfOrigin] = useState([])
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()
    const {data: cities} = useGetNigerianCities()
    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            setTab(tab + 1)
        }
        loadSelectStateOfResidence(userData?.stateOfResidence)
        loadSelectStateOfOrigin(userData?.stateOfOrigin)
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(basicInformationSchema),
        defaultValues: {...userData}
    })
    const handleSelectStateOfResidence = async (event) => {
        const {id} = states?.data?.data?.data?.filter(({stateName}) => stateName === event.target.value)[0]

        const returnData = await appApiCalls.getLGA(id)
        setlgasOfResidence(returnData?.data?.data?.map(({localGovernmentName}) => {
            return localGovernmentName
        }))
    }

    const handleSelectStateOfOrigin = async (event) => {
        const {id} = states?.data?.data?.data?.filter(({stateName}) => stateName === event.target.value)[0]

        const returnData = await appApiCalls.getLGA(id)
        setlgasOfOrigin(returnData?.data?.data?.map(({localGovernmentName}) => {
            return localGovernmentName
        }))
    }

    const loadSelectStateOfResidence = async (state) => {
        if (!state) {
            return null;
        }
        const {id} = states?.data?.data?.data?.filter(({stateName}) => stateName === state)[0]
        const returnData = await appApiCalls.getLGA(id)
        setlgasOfResidence(returnData?.data?.data?.map(({localGovernmentName}) => {
            return localGovernmentName
        }))
    }

    const loadSelectStateOfOrigin = async (state) => {
        if (!state) {
            return null;
        }
        const {id} = states?.data?.data?.data?.filter(({stateName}) => stateName === state)[0]
        const returnData = await appApiCalls.getLGA(id)
        setlgasOfOrigin(returnData?.data?.data?.map(({localGovernmentName}) => {
            return localGovernmentName
        }))
    }

    const handleFormSubmit = (data) => {
        data.firstName = userData.firstName
        data.lastName = userData.lastName
        //delete data.phoneNumber
        delete data.email
        mutate(data)
    }

    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="bg-white w-100 px-4 py-3 rounded-1">
                <CRow>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">First Name</CFormLabel>
                        <CFormInput
                            type="text"
                            id="firstName"
                            placeholder="Enter Your First Name"
                            {...register('firstName')}
                            invalid={!!errors.firstName}
                            className="form-control mb-2"
                            readOnly
                        />
                        <CFormFeedback invalid={!!errors.firstName}>
                            {errors.firstName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">Middle Name</CFormLabel>
                        <CFormInput
                            type="text"
                            id="otherName"
                            {...register('otherName')}
                            invalid={!!errors.otherName}
                            className="form-control mb-2"
                            readOnly
                        />
                        <CFormFeedback invalid={!!errors.otherName}>
                            {errors.otherName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">Last Name</CFormLabel>
                        <CFormInput
                            type="text"
                            id="lastName"
                            placeholder="Enter Your Last Name"
                            {...register('lastName')}
                            invalid={!!errors.lastName}
                            className="form-control mb-2"
                            readOnly
                        />
                        <CFormFeedback invalid={!!errors.lastName}>
                            {errors.lastName?.message}
                        </CFormFeedback>
                    </CCol>

                </CRow>
                <CRow>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">Phone Number</CFormLabel>
                        <CFormInput
                            type="text"
                            id="phoneNumber"
                            placeholder="Enter Your Phone Number"
                            {...register('phoneNumber')}
                            invalid={!!errors.phoneNumber}
                            className="form-control mb-2"
                            readOnly
                        />
                        <CFormFeedback invalid={!!errors.phoneNumber}>
                            {errors.phoneNumber?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">Email</CFormLabel>
                        <CFormInput
                            type="text"
                            id="email"
                            placeholder="Enter Your Email"
                            {...register('email')}
                            invalid={!!errors.email}
                            className="form-control mb-2"
                            readOnly
                        />
                        <CFormFeedback invalid={!!errors.email}>
                            {errors.email?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">Occupation</CFormLabel>
                        <CFormInput
                            type="text"
                            id="occupation"
                            className="form-control mb-2"
                            placeholder="Enter occupation"
                            {...register('occupation')}
                            invalid={!!errors.occupation}
                        />
                        <CFormFeedback
                            invalid={!!errors.occupation}>{errors.occupation?.message}</CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={3} className="my-1">
                        <CFormLabel className="m-1 label">Gender</CFormLabel>
                        <CRow>
                            <CCol md={12}>
                                <div className="form-control d-inline-flex"
                                     style={{padding: '0.08rem 1rem', borderRadius: '0'}}>
                                    <CCol md={6} className="my-1">
                                        <CFormCheck
                                            type="radio"
                                            name="gender"
                                            id="female-gender"
                                            label="Female"
                                            value="Female"
                                            checked={userData?.gender === "Female"}
                                            disabled={true}
                                        />
                                    </CCol>
                                    <CCol md={6} className="my-1">
                                        <CFormCheck
                                            type="radio"
                                            name="gender"
                                            id="male-gender"
                                            label="Male"
                                            value="Male"
                                            checked={userData?.gender === "Male"}
                                            disabled={true}
                                        />
                                    </CCol>
                                </div>
                            </CCol>
                        </CRow>
                        <CFormFeedback invalid={!!errors.gender}>{errors.gender?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={3} className="my-1">
                        <CFormLabel className="m-1 label">Date of Birth</CFormLabel>
                        <CFormInput
                            id="dateOfBirth"
                            className={`form-control mb-2 ${!!errors.dateOfBirth ? 'is-invalid' : ''}`}
                            {...register('dateOfBirth')}
                            readOnly
                        />
                        <CFormFeedback
                            invalid={!!errors.dateOfBirth}>{errors.dateOfBirth?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={3} className="my-1">
                        <CFormLabel className="m-1 label">State of Origin</CFormLabel>
                        {userData?.stateOfOrigin ? <CFormInput
                                id="stateOfOrigin"
                                className={`form-control mb-2 ${!!errors.stateOfOrigin ? 'is-invalid' : ''}`}
                                {...register('stateOfOrigin')}
                                readOnly
                            />
                            :
                            <CFormSelect
                                id="stateOfOrigin"
                                className="form-control mb-2"
                                {...register('stateOfOrigin')}
                                invalid={!!errors.stateOfOrigin}
                                onChange={(option) => handleSelectStateOfOrigin(option)}
                            >
                                <option value=""></option>
                                {
                                    states?.data?.data?.data?.map(({stateName}, index) => (
                                        <option
                                            value={stateName || ""}
                                            key={index}
                                        >
                                            {stateName}
                                        </option>
                                    ))
                                }
                            </CFormSelect>}
                        <CFormFeedback
                            invalid={!!errors.stateOfOrigin}>{errors.stateOfOrigin?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={3} className="my-1">
                        <CFormLabel className="m-1 label">LGA of Origin</CFormLabel>
                        {userData?.localGovernmentOfOrigin ? <CFormInput
                                id="localGovernmentOfOrigin"
                                className={`form-control mb-2 ${!!errors.localGovernmentOfOrigin ? 'is-invalid' : ''}`}
                                {...register('localGovernmentOfOrigin')}
                                readOnly
                            /> :
                            <CFormSelect
                                id="localGovernmentOfOrigin"
                                className="form-control mb-2"
                                value={watch('localGovernmentOfOrigin') || ''}
                                invalid={!!errors.localGovernmentOfOrigin}
                                onChange={(event) => {
                                    const {value} = event.target;
                                    setValue('localGovernmentOfOrigin', value)
                                }}
                            >
                                <option value=""></option>
                                {
                                    lgasOfOrigin?.map((localGovernmentName, index) => (
                                        <option
                                            value={localGovernmentName}
                                            key={index}
                                        >
                                            {localGovernmentName}
                                        </option>
                                    ))
                                }
                            </CFormSelect>}
                        <CFormFeedback
                            invalid={!!errors.localGovernmentOfOrigin}>{errors.localGovernmentOfOrigin?.message}</CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">City/Town</CFormLabel>
                        <CFormSelect
                            id="city"
                            className="form-control mb-2"
                            placeholder="Enter city"
                            {...register('city')}
                            invalid={!!errors.city}
                        >
                            {cities?.data?.data?.map(({name}, index) => (
                                <option value={name} key={index} selected={name === userData?.city}>{name}</option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback
                            invalid={!!errors.city}>{errors.city?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">State of Residence</CFormLabel>
                        <CFormSelect
                            id="stateOfResidence"
                            className="form-control mb-2"
                            {...register('stateOfResidence')}
                            invalid={!!errors.stateOfResidence}
                            onChange={(option) => handleSelectStateOfResidence(option)}
                        >
                            <option value=""></option>
                            {
                                states?.data?.data?.data?.map(({stateName}, index) => (
                                    <option
                                        value={stateName || ""}
                                        key={index}
                                    >
                                        {stateName}
                                    </option>
                                ))
                            }
                        </CFormSelect>
                        <CFormFeedback
                            invalid={!!errors.stateOfResidence}>{errors.stateOfResidence?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={4} className="my-1">
                        <CFormLabel className="m-1 label">LGA of Residence</CFormLabel>
                        <CFormSelect
                            id="localGovernmentOfResidence"
                            className="form-control mb-2"
                            value={watch('localGovernmentOfResidence') || ''}
                            invalid={!!errors.localGovernmentOfResidence}
                            onChange={(event) => {
                                const {value} = event.target;
                                setValue('localGovernmentOfResidence', value)
                            }}
                        >
                            <option value=""></option>
                            {
                                lgasOfResidence?.map((localGovernmentName, index) => (
                                    <option
                                        value={localGovernmentName}
                                        key={index}
                                    >
                                        {localGovernmentName}
                                    </option>
                                ))
                            }
                        </CFormSelect>
                        <CFormFeedback
                            invalid={!!errors.localGovernmentOfResidence}>{errors.localGovernmentOfResidence?.message}</CFormFeedback>
                    </CCol>
                </CRow>
                <CRow>

                    <CCol md={12} className="my-1">
                        <CFormLabel className="m-1 label">Address</CFormLabel>
                        <CFormTextarea
                            type="text"
                            id="address"
                            className="form-control mb-2"
                            placeholder="Enter address"
                            rows={3}
                            {...register('address')}
                            invalid={!!errors.address}
                        >
                        </CFormTextarea>
                        <CFormFeedback invalid={!!errors.address}>{errors.address?.message}</CFormFeedback>
                    </CCol>
                </CRow>

            </div>
            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}
                    >
                        SAVE & CONTINUE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default BasicInformation
