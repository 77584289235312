import React from 'react';
import {CCard, CCardBody, CCardText, CCardTitle, CCol} from "@coreui/react-pro";
// import {MdCardMembership, MdOutlineDashboard, MdOutlineHelpCenter,} from 'react-icons/md'
import {useNavigate} from "react-router-dom";
import {formatNumber} from "../../constant";

function StatisticCard({pinned, index, width, card_colors, textColor}) {

    const navigate = useNavigate()
    const handleRoute = (to)=>{
        navigate(to, {
            replace: true,
        })
    }

    return (
        <div className="cursor-pointer" onClick={()=>handleRoute(pinned?.to)}>

                <div style={{minWidth:"300px", height:"80px"}} className={`rounded d-flex px-3 justify-content-between align-items-center text-decoration-none ${card_colors[index]}`}>
                    <div className="d-flex align-items-center align-content-start gap-1">
                        <pinned.icon style={{width:"30px"}} className={`${textColor ? textColor : 'text-white'}`}/>
                        <div style={{width:"160px"}} className={`${textColor ? textColor : 'text-white'} fw-medium`}>
                            {pinned?.title}
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <div className={`text-3 fw-bolder bg-black p-2 px-3 bg-opacity-15 rounded ${textColor ? textColor : 'text-white'}`}>{formatNumber(pinned?.total)}</div>

                    </div>
                </div>

        </div>

    );
}

export default StatisticCard;
