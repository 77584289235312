import React, {useEffect, useState} from 'react';
import {
    CButton, CCol, CForm, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CLoadingButton, CRow
} from "@coreui/react-pro";
import {Controller, useForm} from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import GroupRadioButton from "../../buttons/GroupRadioButton";
import JobPreview from "../../modals/JobPreview";
import {JobSchema} from "../../../form-schema/stake-holder-schema/JobSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {useStakeholderPostJobs} from "../../../hooks/employer/employerApiHooks";
import {format} from "date-fns";
import {educationLevelOptions, fail_notify, success_notify} from "../../../constant";
import TextEditor from "../../TextEditor";
import {Tips} from "../../../constant/testTips";

const experience = [{id: 1, title: 'Less than 1 year'}, {id: 2, title: '1-3 years'}, {
    id: 3,
    title: '3-5 years'
}, {id: 4, title: 'More than 5 years'},]

const jobTypes = [{id: 1, title: 'Full Time'}, {id: 2, title: 'Part Time'}, // {id: 3, title: 'Contractual'},
    {id: 4, title: 'Remote'}, {id: 5, title: 'Hybrid'}, {id: 6, title: 'Internship'},
]

const personalityPreference = [
    {id: 1, title: 'Amiable', tip:Tips?.personalityTest?.amiable},

    {id: 2, title: 'Analytical', tip:Tips?.personalityTest?.analytical },
    {id: 4, title: 'Driver', tip: Tips?.personalityTest?.driver},
    {id: 5, title: 'Expressive', tip: Tips?.personalityTest?.expressive}
]
const cultureFitPreference = [
    {id: 1, title: 'Adhocracy', tip: Tips?.cultureFitTest?.adhocracy},
    {id: 2, title: 'Clan', tip: Tips?.cultureFitTest?.clan},
    {id: 4, title: 'Hierarchy', tip:  Tips?.cultureFitTest?.hierarchy},
    {id: 5, title: 'Market', tip:  Tips?.cultureFitTest?.market}
]
const softSkillPreference = [{id: 1, title: 'Adaptability'}, {id: 2, title: 'Communication'},
    {id: 4, title: 'Problem Solving'}, {id: 5, title: 'Relationship Building'}
]

function JobPostingForm() {

    const [preview, setPreview] = useState(false)
    const [formData, setFormData] = useState({})
    const [tag, setTag] = useState('')
    const [tags, setTags] = useState([])
    const [salary, setSalary] = useState(null);
    const [otherError, setOtherError] = useState(null);
    const {isSuccess, isLoading, error, mutate, isError, data} = useStakeholderPostJobs()
    const [description, setDescription] = useState('');
    const [responsibilities, setResponsibilities] = useState('');
    const [requirement, setRequirement] = useState('');
    const {
        register, handleSubmit, control, formState: {errors}, getValues, reset,

    } = useForm({
        resolver: yupResolver(JobSchema), defaultValues: {
            description: description, responsibilities: responsibilities, requirement: requirement, tags: []
        }
    })
    //console.log(errors)
    const handleTags = (e) => {
        e.preventDefault()
        if (tag.length === 0) return
        setTags([...tags, tag])
        setTag('')
    }
    const removeTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const handleJobPreview = () => {
        setFormData({...getValues(), tags})
        setPreview(true)
    }

    const handleJobPosting = (data) => {
        let error = {}
        if (!description) {
            error.description = "Description is required"
        } else {

        }
        if (!responsibilities) {
            error.responsibilities = "Responsibilities is required"

        }
        if (!requirement) {
            error.requirement = "Requirement is required"

        }

        if (Object.keys(error).length !== 0) {
            setOtherError(error)
            error = null
            return;
        }

        data.isPublished = true
        data.description = description;
        data.responsibilities = responsibilities;
        data.requirement = requirement;
        const fullDate = new Date(data?.closingDate)
        data.closingDate = format(fullDate, "MM-dd-yyyy")
        //localStorage.setItem('content1', JSON.stringify(data.description));
        data.tags = tags
        if (salary) {
            data.salary = parseInt(salary.replace(/,/g, ''));
        }
        // remove modal
        setPreview(false)
        mutate(data)


    }

    const handleSalaryChange = (event) => {
        const rawValue = event.target.value
        const salary = parseInt(rawValue.replace(/,/g, ''));

        if (rawValue < 1) {
            setSalary(null)
            return
        }
        // Get the raw input
        const formattedValue = new Intl.NumberFormat("en-US").format(salary); // Format the input value as currency
        setSalary(formattedValue); // Update the state with the formatted value
    };

    useEffect(() => {
        if (isSuccess) {
            success_notify('Job Posted Successfully')
            // reset form
            setSalary(null)
            setRequirement('')
            setDescription('')
            setResponsibilities('')
            setTags([])
            reset()
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isLoading, error, mutate, isError, data])


    return (<CRow className="form-card p-4">
        <CRow className="d-flex flex-row  py-20">
            <JobPreview
                visible={preview}
                setVisible={setPreview}
                data={formData}
                handleJobPosting={handleJobPosting}
            />
            <CCol sm={10}>
                <h3 className="sub-title">BASIC INFORMATION</h3>
                <hr/>
            </CCol>
            <CCol sm={2}>
                <div className="d-flex flex-row">
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="outline-btn px-4 text-primary"*/}
                    {/*    onClick={handleJobPreview}*/}
                    {/*>*/}
                    {/*    Preview*/}
                    {/*</button>*/}
                    {/*<button type="button" className="outline-btn px-4 text-primary">*/}
                    {/*    Publish*/}
                    {/*</button>*/}
                </div>
            </CCol>
        </CRow>
        <CForm className="w-100" onSubmit={handleSubmit(handleJobPosting)}>
            <CRow>
                <CCol className=" align-middle gap-3">
                    <CCol className="">
                        <CFormLabel className="m-1 label">Enter Job Title</CFormLabel>
                        <CFormInput
                            type="text"
                            placeholder="Enter Job Title"
                            {...register('title')}
                            invalid={!!errors.title?.toString()}
                            className="mb-2"
                        />
                        <CFormFeedback invalid={!!errors.title?.toString()}>
                            {errors.title?.message}
                        </CFormFeedback>
                    </CCol>

                    <CCol className="">
                        <CFormLabel className="m-1 label">Number of Vacancies</CFormLabel>
                        <CFormInput
                            type="text"
                            id="numberOfVacancy"
                            placeholder="Example 1, 2, 4"
                            {...register('numberOfVacancy')}
                            invalid={!!errors.numberOfVacancy?.toString()}
                            className="mb-2"
                        />
                        <CFormFeedback invalid={!!errors.numberOfVacancy?.toString()}>
                            {errors.numberOfVacancy?.message}
                        </CFormFeedback>
                    </CCol>
                </CCol>

            </CRow>
            <CRow>
                <h3 className="sub-title pt-3"> Job Types</h3>
                <div>
                    <Controller
                        control={control}
                        name="jobType"
                        render={({field}) => (<GroupRadioButton
                            name="jobType"
                            data={jobTypes}
                            onChange={field.onChange}
                            value={field.value}
                        />)}
                    />

                    <CFormFeedback invalid={!!errors.jobType?.toString()}>
                        {errors.jobType?.message}
                    </CFormFeedback>
                </div>
            </CRow>
            <CRow>
                <h3 className="sub-title pt-3"> Experience Level</h3>
                <div>
                    <Controller
                        control={control}
                        name="levelOfExperience"
                        render={({field}) => (<GroupRadioButton
                            name="levelOfExperience"
                            data={experience}
                            onChange={field.onChange}
                            value={field.value}

                        />)}
                    />
                    <CFormFeedback invalid={!!errors.levelOfExperience?.toString()}>
                        {errors.levelOfExperience?.message}
                    </CFormFeedback>
                </div>
            </CRow>
            <CRow>
                <h3 className="sub-title pt-3">Personality (Optional)</h3>
                <div>
                    <Controller
                        control={control}
                        name="personalityPreference"
                        render={({field}) => (<GroupRadioButton
                            name="personalityPreference"
                            data={personalityPreference}
                            onChange={field.onChange}
                            value={field.value}

                        />)}
                    />
                    {/*<CFormFeedback invalid={!!errors.levelOfExperience?.toString()}>
                        {errors.levelOfExperience?.message}
                    </CFormFeedback>*/}
                </div>
            </CRow>
            <CRow>
                <h3 className="sub-title pt-3"> Culture Fitness (Optional)</h3>
                <div>
                    <Controller
                        control={control}
                        name="cultureFitPreference"
                        render={({field}) => (<GroupRadioButton
                            name="cultureFitPreference"
                            data={cultureFitPreference}
                            onChange={field.onChange}
                            value={field.value}

                        />)}
                    />
                    {/*<CFormFeedback invalid={!!errors.levelOfExperience?.toString()}>
                        {errors.levelOfExperience?.message}
                    </CFormFeedback>*/}
                </div>
            </CRow>
            <CRow>
                <h3 className="sub-title pt-3">Soft Skill (Optional)</h3>
                <div>
                    <Controller
                        control={control}
                        name="softSkillPreference"
                        render={({field}) => (<GroupRadioButton
                            name="softSkillPreference"
                            data={softSkillPreference}
                            onChange={field.onChange}
                            value={field.value}

                        />)}
                    />
                   {/* <CFormFeedback invalid={!!errors.levelOfExperience?.toString()}>
                        {errors.levelOfExperience?.message}
                    </CFormFeedback>*/}
                </div>
            </CRow>
            <CRow>

                <h3 className="sub-title pt-3">Job Description</h3>
                <div>
                    <TextEditor editorState={description} setEditorState={setDescription}/>
                    {/*<Controller
                            control={control}
                            name="description"
                            render={({field}) => (
                                <CFormTextarea
                                    type="text"
                                    id="description"
                                    placeholder="Write a job description"
                                    {...register('description')}
                                    invalid={!!errors.description?.toString()}
                                    className="mb-2"
                                    rows={5}
                                    style={{resize: 'none', height: 'fit-content'}}
                                />
                            )}
                        />*/}
                    <label className="text-danger">
                        {otherError?.description}
                    </label>
                </div>
            </CRow>
            <CRow className="">
                <CCol className="d-flex flex-column flex-md-row align-middle gap-3">
                    <div>

                        <CFormLabel className="m-1 label">Application Deadline</CFormLabel>
                        <Controller
                            control={control}
                            name="closingDate"
                            render={({field}) => (<DatePicker
                                showYearDropdown
                                showMonthDropdown
                                placeholderText={'Closing Date'}
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                minDate={new Date()}
                                isClearable
                                className={`form-control  ${!!errors.closingDate && 'is-invalid'}`}
                            />)}
                        />
                    </div>
                    <div>
                        <CFormLabel className="m-1 label">Location</CFormLabel>
                        <CFormInput
                            type="text"
                            id="location"
                            placeholder="Abuja, Jos"
                            {...register('location')}
                            invalid={!!errors.location?.toString()}
                            className="mb-2"
                        />
                        <CFormFeedback invalid={!!errors.location?.toString()}>
                            {errors.location?.message}
                        </CFormFeedback>
                    </div>

                </CCol>

            </CRow>
            <CRow className="">
                <CCol className="d-flex flex-column flex-md-row align-middle gap-3">
                    <div>

                        <CFormLabel className="m-1 label">Education Level</CFormLabel>
                        <CFormSelect
                            id="educationLevel"
                            className="form-control mb-2"
                            {...register('qualification')}
                            invalid={!!errors.qualification}
                        >
                            <option value="">Select Education Level</option>
                            {educationLevelOptions?.map((option, index) => (<option value={option} key={index}>
                                {option}
                            </option>))}
                        </CFormSelect>
                        <CFormFeedback
                            invalid={!!errors.qualification}>{errors.qualification?.message}</CFormFeedback>
                    </div>

                    <div>
                        <div>
                            <CFormLabel className="m-1 label">Salary in ₦ (optional)</CFormLabel>
                            <CFormInput
                                type="text"
                                placeholder="300,000"
                                onChange={handleSalaryChange}

                                value={salary}
                                invalid={!!errors.salary?.toString()}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.salary?.toString()}>
                                {errors.salary?.message}
                            </CFormFeedback>
                        </div>
                    </div>

                </CCol>
            </CRow>
            <CRow className="">
                <CCol>
                    <h3 className="sub-title">Experience Required</h3>
                    <TextEditor editorState={responsibilities} setEditorState={setResponsibilities}/>
                    {/* <JobAppend
                            register={register}
                            errors={errors}
                            name={"responsibilities"}
                            control={control}
                        />*/}
                    <label className="text-danger">
                        {otherError?.responsibilities}
                    </label>
                </CCol>
            </CRow>
            <CRow className="">
                <CCol>
                    <h3 className="sub-title"> Job Requirements</h3>
                    <TextEditor editorState={requirement} setEditorState={setRequirement}/>
                    {/*<JobAppend
                            register={register}
                            errors={errors}
                            name={"requirement"}
                            control={control}
                        />*/}
                    <label className="text-danger">
                        {otherError?.requirement}
                    </label>
                </CCol>
            </CRow>
            <CRow className="">
                <CCol>
                    <h3 className="sub-title">Job Tags</h3>
                    <div style={{fontSize: '14px'}} className="mb-2">
                        {tags.map((item, index) => (
                            <span className="bg-light-green-2 mx-1 px-2 py-1 rounded-4" key={index}>
                                        {item} &nbsp;
                                <svg onClick={() => removeTag(index)} xmlns="http://www.w3.org/2000/svg"
                                     width="16" height="16"
                                     fill="currentColor" className="bi bi-x cursor-pointer text-red"
                                     viewBox="0 0 16 16">
                                          <path
                                              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </span>))}
                    </div>
                    <CFormInput
                        type="text"
                        placeholder="e.g Javascript"
                        className="mb-2"
                        disabled={tags.length >= 4}
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                    />
                    <CButton disabled={tags.length >= 4} className="btn-sm text-white" onClick={handleTags}>Add
                        Tag</CButton>

                </CCol>
            </CRow>
            <CRow className="d-flex flex-row justify-content-end">
                <CCol sm={4} className="d-flex flex-row justify-content-end">
                    <CLoadingButton type={"submit"} className="text-white"> Submit</CLoadingButton>
                </CCol>
            </CRow>
        </CForm>

    </CRow>);
}

export default JobPostingForm;
