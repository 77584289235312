import React from 'react';
import {CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem} from "@coreui/react-pro";

function Faq({index, text, title}) {
    return (
        <>
            <CAccordion flush className="faqs">
                <CAccordionItem itemKey={index}>
                    <CAccordionHeader>{title}</CAccordionHeader>
                    {
                        text?.map((answer, index) =>
                            <CAccordionBody key={index}>
                                {
                                    answer?.text
                                }
                            </CAccordionBody>)
                    }
                </CAccordionItem>
            </CAccordion>
        </>
    );
}

export default Faq;