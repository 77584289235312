import React from 'react';
import {CCol, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import CategoryCard from "./CategoryCard";
import {TestTips} from "../../../../constant/testTips";
import {useReportContext} from "../../../../context/admin/ReportProvider";

function FilterCards() {

    const {
        handleChangeAssessment,
        allSkills,
        setFromPercent,
        quizType,
        personalityData,
        cultureFitData,
        softSkillsData,
        data,
        fromPercent,
        toPercent,
        setToPercent,
        setAssessmentCategory,
        states,
        handleChangeState
    } = useReportContext()

    return (
        <>
            <div className="py-2 m-3">
                <CRow className="bg-white shadow-sm rounded py-3 px-1">
                    <CCol md={4} className="mb-3">
                        <CRow>
                            <CCol md={8}>
                                <CFormLabel className="mb-0 pb-0">Filter Assessments</CFormLabel>
                                <CFormSelect
                                    id="industryType"
                                    className="form-control"
                                    onChange={(event) => {
                                        handleChangeAssessment(event)
                                    }}
                                >
                                    <option value=" ">All Assessments</option>
                                    <option value="Personality Test">Personality Test</option>
                                    <option value="Culture Fit Test">Culture Fit Test</option>
                                    <option value="Soft Skills Test">Soft Skills Test</option>
                                    {
                                        allSkills?.data?.data?.map(({id, title}) => (
                                            <option
                                                value={title}
                                                key={id}
                                            >
                                                {title}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="mb-0 pb-0">From (%)</CFormLabel>
                                <CFormInput value={fromPercent}
                                            onChange={(e) => setFromPercent(e.target.value)}></CFormInput>
                            </CCol>
                        </CRow>
                        <CRow className="mt-2">
                            <CCol md={8}>
                                <CFormLabel className="mb-0 pb-0">State of Origin</CFormLabel>
                                <CFormSelect className="form-control" onChange={(event) => handleChangeState(event.target.value)}>
                                    <option value="">Select State</option>
                                    {
                                        states?.data?.data?.data?.map(({stateName}, index) => (
                                            <option
                                                value={stateName}
                                                key={index}
                                            >
                                                {stateName}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="mb-0 pb-0">To (%)</CFormLabel>
                                <CFormInput value={toPercent}
                                            onChange={(e) => setToPercent(e.target.value)}></CFormInput>
                            </CCol>
                        </CRow>
                    </CCol>

                    {(quizType === "Personality Test") &&
                        <CCol md={8} className="d-flex flex-row justify-content-between align-items-center">
                            {personalityData?.data?.data?.aggregates?.map(({
                                                                               personalityCategory,
                                                                               count
                                                                           }, index) => {
                                return <CategoryCard
                                    label={personalityCategory}
                                    count={count} key={index}
                                    color_index={index}
                                    tip={TestTips.personalityTest[index]?.tip}
                                    qualifier={TestTips.personalityTest[index]?.qualifier}
                                    setAssessmentCategory={setAssessmentCategory}
                                />
                            })}
                        </CCol>
                    }
                    {(quizType === "Culture Fit Test") &&
                        <CCol md={8} className="d-flex flex-row justify-content-between align-items-center">
                            {cultureFitData?.data?.data?.aggregates?.map(({cultureCategory, count}, index) => {
                                return <CategoryCard
                                    label={cultureCategory}
                                    count={count} key={index}
                                    color_index={index}
                                    tip={TestTips.cultureFitTest[index]?.tip}
                                    qualifier={TestTips.cultureFitTest[index]?.qualifier}
                                    setAssessmentCategory={setAssessmentCategory}
                                />
                            })}
                        </CCol>
                    }
                    {(quizType === "Soft Skills Test") &&
                        <CCol md={8} className="d-flex flex-row justify-content-between align-items-center">
                            {softSkillsData?.data?.data?.aggregates?.data?.map(({
                                                                                    softSkillsCategory,
                                                                                    count
                                                                                }, index) => {
                                return <CategoryCard
                                    label={softSkillsCategory}
                                    count={count} key={index}
                                    color_index={index}
                                    tip={TestTips.softSkillsTest[index]?.content}
                                    qualifier={TestTips.softSkillsTest[index]?.qualifier}
                                    setAssessmentCategory={setAssessmentCategory}
                                />
                            })}
                        </CCol>
                    }

                    {(quizType !== " " && quizType !== "Personality Test" && quizType !== "Culture Fit Test" && quizType !== "Soft Skills Test") &&
                        <CCol md={8} className="d-flex flex-row justify-content-between align-items-center">
                            <CategoryCard label={"All Test Taken"} count={data?.data?.data?.meta?.totalItems}
                                          color_index={0}/>
                            <CategoryCard label={"Passed Tests"}
                                          count={data?.data?.data?.assessments?.totalPassed} color_index={1}/>
                            <CategoryCard label={"Failed Tests"}
                                          count={data?.data?.data?.assessments?.totalFailed} color_index={2}/>
                        </CCol>
                    }
                </CRow>
            </div>
        </>
    );
}

export default FilterCards;