import {CNavLink, CRow} from "@coreui/react-pro";
import React from "react";
import UserProfileTag from "./UserProfileTag";
import {BsChevronDown} from "react-icons/bs";

const UserProfileTest = ({data}) => {
    return (
        <>
            <div className="d-flex px-4 py-2 justify-content-between  shadow-sm-user ">
                <div className="d-flex">
                    <small className="fw-bold ps-2">
                        {data[2]?.title}
                    </small>
                </div>
                <div className="fw-bold justify-content-end">
                    <BsChevronDown size={20} fill="currentColor" />
                </div>
            </div>


            <CRow className="my-2 d-flex gap-2 px-2 justify-content-center">
                {
                    data[0]?.data?.map((item, index) => {
                        return (<UserProfileTag key={index} tag={item.content} tips={item.tip}/>)
                    })
                }

            </CRow>
            <div >
                {
                    data[1]?.takeAssessment &&
                    <CNavLink
                        href={`/user/questionnaire/${data[3]?.testType.replace(/\s/g, '-')}`}
                              className={`font-color text-center text-3 mt-2 mb-2`}>
                        Take Assessment
                    </CNavLink>
                }
            </div>


        </>
    )
}

export default UserProfileTest
