import React from 'react'
// import resendIcon from '../../assets/images/resend.svg'
import {CContainer} from '@coreui/react-pro'
import {FcCancel} from "react-icons/fc";
import {Link} from "react-router-dom";

const AccessDenied = () => {
    return (
        <CContainer
            className="d-flex align-items-center mx-auto flex-column justify-content-center  min-vh-100 min-vw-100 bg-opacity-50 "
            style={{zIndex: 1000, position: 'fixed', top: '0px', left: '0px'}}
        >
            <div
                className={`d-flex align-items-center flex-column justify-content-center mt-5 bg-light border-radius-12 sign-up-success ${
                    window.innerWidth > 700 ? 'w-50 p-5' : 'w-100 p-2'
                }`}
            >
                <FcCancel size={100}/>
                <p className="text-danger m-0 text-2">Access Denied!!</p>
                <p className="text-black text-center">{`We're sorry, but it seems like you're unable to
                 access this at the moment. Please try again later or contact WorkNation support for further assistance.
                  Thank you for your understanding!`}.</p>

                <Link to="/employer/dashboard" className="text-decoration-none m-0 font-color">
                    Go Back
                </Link>

            </div>
        </CContainer>
    )
}

export default AccessDenied
