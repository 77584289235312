import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import {useGetAppStats} from "../../hooks/user/userApiHooks";
import ShowLoading from "../ShowLoading";

const Statistics = () => {
    const [totalEmployers, setTotalApplicants] = useState(0)
    const [totalApplicants, setTotalUsers] = useState(0)
    const [totalJobs, setTotalJobs] = useState(0)

    const {
        isLoading: isStatLoading,
        isSuccess: isStatSuccess,
        data: statData
    } = useGetAppStats()

    useEffect(() => {
        setTotalApplicants(statData?.data?.data?.employers)
        setTotalUsers(statData?.data?.data?.users)
        setTotalJobs(statData?.data?.data?.jobs)
    }, [statData])

    if (isStatLoading) {
        return <ShowLoading/>
    }
    
    return <CContainer className="px-5 py-3 landing-page-statistics">
        {isStatSuccess && <CRow>
            <CCol md={4}>
                <div className="d-flex flex-column justify-content-center align-items-center w-auto my-sm-3">
                    <h2 className="text-primary-dark"><strong>{totalApplicants}</strong></h2>
                    <p className="text-primary">Applicants</p>
                </div>
            </CCol>
            <CCol md={4}>
                <div className="d-flex flex-column justify-content-center align-items-center w-auto my-sm-3">
                    <h2 className="text-primary-dark"><strong>{totalEmployers}</strong></h2>
                    <p className="text-primary">Employers</p>
                </div>
            </CCol>
            <CCol md={4}>
                <div className="d-flex flex-column justify-content-center align-items-center w-auto my-sm-3">
                    <h2 className="text-primary-dark"><strong>{totalJobs}</strong></h2>
                    <p className="text-primary">Current Vacancies</p>
                </div>
            </CCol>
        </CRow>}
    </CContainer>
}

export default Statistics;
