import React, {useEffect, useState} from 'react';
import {CCard, CCardBody, CCardTitle, CNavLink} from "@coreui/react-pro";
import useQuestionnaireStatus from "../../../hooks/user/questionnaireStatus";
import ScoresModal from "../../../components/modals/ScoresModal";

function QuestionnaireCard({title, data}) {

    const url = title.replace(' ', '-')
    const {questionnaireChecker,  dataIsLoading} = useQuestionnaireStatus()
    const [isAnswered, setIsAnswered] = useState(false)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const isAnswered = questionnaireChecker(title)
        if (isAnswered) {
            setIsAnswered(isAnswered)
        }
    }, [data, dataIsLoading])

    const handleClickSeeResult = (e) => {
        e.preventDefault()
        setVisible(!visible)
    }

    return (
        <CCard className={`w-100 ${isAnswered ? "disabled" : ""}`}>
            <CCardBody>
                <CCardTitle>{title}</CCardTitle>
                {
                    isAnswered ?
                        <>
                            <CNavLink href="#" className={`font-color`} onClick={handleClickSeeResult}>
                                See Result
                            </CNavLink>
                            {
                                visible &&
                                <ScoresModal setVisible={setVisible} visible={visible} title={title}/>
                            }
                        </>
                        :
                        <CNavLink href={url.replace(" ","")} className={`font-color ${isAnswered ? "disabled" : ""}`}
                        >Take Test</CNavLink>
                }
            </CCardBody>
        </CCard>
    );
}

export default QuestionnaireCard;
