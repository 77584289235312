import {CButton, CImage, CModal, CModalBody} from "@coreui/react-pro";
import React, {useState} from "react";
import EditProfileIcon from "../../assets/icons/edit-profile-icon.svg";
import CamIcon from "../../assets/icons/cam-icon.svg";

const CompleteProfileModal = () => {
    const [visible, setVisible] = useState(true)
    return (
        <CModal backdrop="static" alignment="center" visible={visible} onClose={() => setVisible(false)}>
            <CModalBody className="p-5 ">
                <strong className="mb-3 text-black">Hey there!</strong>
                <p className="text-justify mt-2 mb-2">
                    {
                        `It looks like you haven't uploaded a profile picture and completed your profile yet. Doing so helps you stand out and unlocks many benefits`
                    }
                </p>
                <div className="mt-2">
                    <p className="text-justify">
                        <strong>Increased job visibility: </strong>
                        A complete profile with a picture makes you more discoverable to potential employers.
                    </p>
                </div>
                <div className="mt-2">
                    <p className="text-justify">
                        <strong> {`It's quick and easy!`} </strong>
                        Click the Photo Icon <CImage src={CamIcon} style={{width: '28px', height: '28px'}}/> {" "} on
                        your Profile to upload a picture and the Edit <CImage src={EditProfileIcon}/>{" "} Profile Icon
                        to update your profile.
                    </p>
                </div>
                <div className="pt-3 pb-3">
                    <strong>Best regards,</strong>
                    <p>The WorkNation Team</p>
                </div>
                <CButton color="primary"
                         onClick={() => {
                             setVisible(false)
                         }}
                         className="text-white px-5 mx-1"
                >
                    Close
                </CButton>

            </CModalBody>
        </CModal>
    )
}

export default CompleteProfileModal

