import * as Yup from 'yup'
import {employmentOptions, genderOptions, idTypeOptions} from "../../../constant";

export const PersonalDetailSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number required').matches(/^(\+)?(234|0)[0-9]{10}$/, 'Must be a valid phone number'),
    //zipCode: Yup.number().typeError('Must be a valid zip code'),
    firstName: Yup.string('First name Must be a string')
        .required('First name is required')
        .test('len', 'Must be longer than or equal to 3 characters', (val) => val?.length >= 3),
    //otherName: Yup.string().typeError().test('len', 'Must be longer than or equal to 3 characters', (val) => val?.length == 0 || val?.length >= 3 ),
    lastName: Yup.string('Last name Must be a string')
        .required('Last name is required')
        .test('len', 'Must be longer than or equal to 3 characters', (val) => val?.length >= 3),
    profession: Yup.string().typeError('Profession is required').required('Profession is required'),
    city: Yup.string().typeError('City is required').required('City is required'),
    //careerObjective: Yup.string().typeError('Career Objective is required').required('Career Objective is required'),

    //socialLinks: Yup.string().typeError('Social Links is required').required('Social Links is required').matches(
    //    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //    'Enter correct url!'
   // ),
    passport: Yup.string().nullable(),
    address: Yup.string().typeError('Home address is required').required('Home address is required'),
    nationality: Yup.string()
        .typeError('nationality fff is required')
        .required('nationality is required'),

})
