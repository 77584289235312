import React, {useEffect, useState} from "react";
import {CButton, CCol, CForm, CLoadingButton, CRow} from "@coreui/react-pro";

import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ExperienceSchema, initialExperience} from "../../../form-schema/user-form-schema/cv-builder/experience";
import ExperienceIcon from "../../../assets/icons/Exeriences.svg";
import InfoTitle from "./InfoTitle";
import {fail_notify} from "../../../constant";
import experience from "../../../constant/cvBuilder/experience";
import {AiOutlinePlus} from "react-icons/ai";
import ExperienceForm from "./Experience/ExperienceForm";
import WorkExperienceCard from "./WorkExperienceCard";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const Experience = () => {

    const {status, setStatus, setExperienceData, cvData} = useCVBuilderContext()
    const [workExperience] = useState(cvData?.data?.data?.data[0]?.workExperience || [initialExperience]);
    const [editorState, setEditorState] = useState(workExperience);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();
    const [activeExperience, setActiveExperience] = useState(-1);
    const {
        register,
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(ExperienceSchema),
        defaultValues: experience(workExperience)
    })

    const {append} = useFieldArray({
        control,
        name: "workExperience"
    });

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        setExperienceData(watchAllFields);
    }, [watchAllFields])

    useEffect(() => {
        setValue("workExperience", editorState);
    }, [editorState])

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0].workExperience = getValues();
            if (editorState?.length === 0) {
                setActiveExperience(0);
                append();
            } else {
                setActiveExperience(-1);

            }
            setValue("workExperience", editorState);
        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (activeExperience === -1 && editorState.length === 0) {
            setActiveExperience(0);
            append();
        }
    }, [])

    const onSubmit = (data) => {
        data.workExperience.map((item) => {
            if (item.currentlyWorking === true) {
                item.endDate = "";
                item.endMonth = "";
            }
        })
        mutate(data)
    }

    const deleteExperience = (id) => {
        editorState.pop(id);
        mutate({workExperience: editorState});
    }
    return (
        <>
            {
                status === 2 &&
                <>
                    <InfoTitle title={"EXPERIENCES"} icon={ExperienceIcon}/>
                    {
                        activeExperience < 0 && <>
                            {
                                editorState.map((experience, index) => {
                                    return (
                                        <WorkExperienceCard
                                            key={index}
                                            experienceId={index}
                                            setActiveExperience={setActiveExperience}
                                            experience={experience}
                                            deletedExperience={deleteExperience}
                                        />

                                    )

                                })
                            }
                            <CRow className="g-3 py-2">
                                <CCol>
                                    <CButton onClick={() => {
                                        setActiveExperience(editorState.length)
                                        append()
                                    }}
                                             className="border rounded text-black-50 bg-transparent d-flex  cursor-pointer justify-content-center py-2 px-3">
                                        <div>
                                            <AiOutlinePlus size={20} color={'currentColor'}/>
                                        </div>
                                        <div className="">
                                            <div className="ps-2">Add Experience</div>
                                        </div>
                                    </CButton>
                                </CCol>

                            </CRow>
                            <center
                                className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                <CButton
                                    onClick={() => {
                                        setStatus(1)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Back
                                </CButton>
                                <CButton
                                    onClick={() => {
                                        setStatus(3)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Next
                                </CButton>
                            </center>
                        </>
                    }
                    <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        {
                            activeExperience >= 0 &&
                            <>
                                <div className="d-flex">
                                    <ExperienceForm
                                        watchAllFields={watchAllFields}
                                        setEditorState={setEditorState}
                                        editorState={editorState}
                                        errors={errors}
                                        index={activeExperience}
                                        register={register}
                                    />
                                </div>
                                <center
                                    className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                    <CButton onClick={() => {
                                        setActiveExperience(-1)
                                    }} className="py-3 cv-btn m-1 text-white">
                                        Back
                                    </CButton>
                                    <CLoadingButton
                                        loading={isLoading}
                                        disabled={isLoading} type="submit"
                                        className="py-3 cv-btn text-white m-1">
                                        SAVE
                                    </CLoadingButton>
                                </center>
                            </>
                        }
                    </CForm>

                </>
            }
        </>
    )
}
export default Experience

