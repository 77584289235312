import React from 'react';
import {CRow} from "@coreui/react-pro";
import JobRecommendation from "./JobRecommendation";
import DataNotFound from "../../DataNotFound";

function Recommendations({recommendations}) {
    return (
        <div>
            <CRow>
                <h2 className="text-2">Recommended Jobs</h2>
            </CRow>
            <div>
                {recommendations.length < 1 ? <DataNotFound/> : <div>
                    {
                        recommendations?.map((job, index) => {
                            return <div className="mt-2" key={index}>
                                <JobRecommendation job={job} />
                            </div>
                        })
                    }

                </div>}
            </div>
        </div>
    );
}

export default Recommendations;