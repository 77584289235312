import React, {useState} from 'react'

import notFound from '../../assets/icons/404.svg'
import GoBack from "../../components/GoBack";


function NotF0und() {

    return (
        <>

            <div  className=" vh-100 p-5">

                <center className="d-flex flex-column justify-content-center h-100">
                    <div>
                    <img src={notFound} style={{maxWidth:'400px'}}/>
                    <center><h3>Page Not Found</h3></center>
                        <GoBack/>
                    </div>
                </center>
            </div>
        </>
    )
}

export default NotF0und
