import React from 'react';
import {CCol, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import {educationLevelOptions, months} from "../../../../constant";
import RichTextEditorForCv from "../../RichTextEditorForCv";
import {useGetNigerianCities} from "../../../../hooks/user/userApiHooks";

function EducationForm({
                           errors,
                           register,
                           index,
                           institution,
                           setEditorState,
                           otherError,
                           watchAllFields
                       }) {
    const {data: cities} = useGetNigerianCities()

    return (
        <>
            <div className="w-100">
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel id="degree" className="text-black mb-0">Education Level</CFormLabel>
                        <CFormSelect
                            invalid={errors?.educations?.[index]?.schoolName?.message}
                            {...register(`educations.${index}.degree`)}
                            id="degree"
                        >
                            <option value="">Select Education Level</option>
                            {
                                educationLevelOptions?.map((option, index) => (
                                    <option
                                        value={option}
                                        key={index}
                                    >
                                        {option}
                                    </option>
                                ))
                            }
                        </CFormSelect>
                        <CFormFeedback
                            className="text-danger"
                            invalid={!!errors?.educations?.[index]?.degree?.messagee}>
                            {errors?.educations?.[index]?.degree?.message}
                        </CFormFeedback>

                    </CCol>
                    <CCol>
                        <CFormLabel id="city" className="text-black mb-0">City/Town</CFormLabel>
                        {/*<CFormInput*/}
                        {/*    type="text"*/}
                        {/*    id="city"*/}
                        {/*    placeholder="Abuja"*/}
                        {/*    invalid={errors?.educations?.[index]?.city?.message}*/}
                        {/*    {...register(`educations.${index}.city`)}*/}
                        {/*    className="mb-2"*/}
                        {/*/>*/}
                        <CFormSelect
                            id="city"
                            className="form-control mb-2"
                            {...register('city')}
                            invalid={!!errors.city}
                        >
                            {cities?.data?.data?.map(({name}, index) => (
                                <option value={name} key={index}>{name}</option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.educations?.[index]?.city?.messagee}>
                            {errors?.educations?.[index]?.city?.message}
                        </CFormFeedback>

                    </CCol>
                </CRow>
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Institution</CFormLabel>
                        <CFormSelect
                            invalid={errors?.educations?.[index]?.schoolName?.message}
                            {...register(`educations.${index}.schoolName`)}
                            id="schoolName"
                        >
                            <option value=""> Select Institution</option>
                            {institution?.data?.data?.data.map((institution, i) => (
                                <option key={i} value={institution.name}>
                                    {institution.name}
                                </option>
                            ))}

                        </CFormSelect>
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.educations?.[index]?.schoolName?.messagee}>
                            {errors?.educations?.[index]?.schoolName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol>
                        <CFormLabel className="text-black mb-0">Field of Study</CFormLabel>
                        <CFormInput
                            type="text"
                            id="fieldOfStudy"
                            placeholder="E.g Computer Science"
                            {...register(`educations.${index}.fieldOfStudy`)}
                            invalid={errors?.educations?.[index]?.fieldOfStudy?.message}
                            className="mb-2"
                        />
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.educations?.[index]?.fieldOfStudy?.messagee}>
                            {errors?.educations?.[index]?.fieldOfStudy?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Start Date</CFormLabel>
                        <div className="d-flex gap-2">
                            <div className="w-100">
                                <CFormSelect
                                    {...register(`educations.${index}.startMonth`)}
                                    invalid={errors?.educations?.[index]?.startMonth?.message}
                                    id="startMonth" aria-label="Default select example">
                                    <option value={""}>Month</option>
                                    {months.map((month, i) => (
                                        <option key={i} value={month}>
                                            {month}
                                        </option>
                                    ))}

                                </CFormSelect>
                                <CFormFeedback className="text-danger"
                                               invalid={!!errors?.educations?.[index]?.startMonth?.messagee}>
                                    {errors?.educations?.[index]?.startMonth?.message}
                                </CFormFeedback>

                            </div>
                            <div className="w-100">
                                <CFormSelect
                                    {...register(`educations.${index}.startDate`)}
                                    invalid={errors?.educations?.[index]?.startDate?.message}
                                    id="startDate" aria-label="Default select example">
                                    <option value={""}>Year</option>
                                    {[...Array(150)].map((_, i) => (
                                        <option key={i} value={new Date().getFullYear() - i}>
                                            {new Date().getFullYear() - i}
                                        </option>
                                    ))}

                                </CFormSelect>
                                <CFormFeedback
                                    className="text-danger"
                                    invalid={!!errors?.educations?.[index]?.startDate?.messagee}
                                >
                                    {errors?.educations?.[index]?.startDate?.message}
                                </CFormFeedback>

                            </div>
                        </div>
                    </CCol>
                    <CCol>
                        <CFormLabel className="text-black mb-0">End Date</CFormLabel>
                        <div className="d-flex gap-2">
                            <div className="w-100">
                                <CFormSelect
                                    {...register(`educations.${index}.endMonth`)}
                                    invalid={errors?.educations?.[index]?.endMonth?.message}
                                    id="endMonth" aria-label="Default select example">
                                    <option value={""}>Month</option>
                                    {months.map((month, i) => (
                                        <option key={i} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </CFormSelect>
                                <CFormFeedback className="text-danger"
                                               invalid={!!errors?.educations?.[index]?.endMonth?.messagee}>
                                    {errors?.educations?.[index]?.endMonth?.message}
                                </CFormFeedback>

                            </div>
                            <div className="w-100">
                                <CFormSelect
                                    {...register(`educations.${index}.endDate`)}
                                    invalid={errors?.educations?.[index]?.endDate?.message}
                                    id="endDate" aria-label="Default select example">
                                    <option value={""}>Year</option>
                                    {[...Array(150)].map((_, i) => (
                                        <option key={i} value={new Date().getFullYear() - i}>
                                            {new Date().getFullYear() - i}
                                        </option>
                                    ))}
                                </CFormSelect>
                                <CFormFeedback className="text-danger"
                                               invalid={!!errors?.educations?.[index]?.endDate?.messagee}>
                                    {errors?.educations?.[index]?.endDate?.message}
                                </CFormFeedback>

                            </div>
                        </div>
                    </CCol>
                </CRow>
                <CRow className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-2">Description</CFormLabel>
                        <div>
                            <RichTextEditorForCv
                                editorState={watchAllFields?.educations}
                                descriptionId={index}
                                setEditorState={setEditorState}
                            />
                            <label className="text-danger">
                                {otherError?.description}
                            </label>
                        </div>

                        <CFormFeedback
                            className="text-danger"
                            invalid={!!errors?.educations?.[index]?.description?.messagee}>
                            {errors?.educations?.[index]?.description?.message}
                        </CFormFeedback>
                    </CCol>

                </CRow>
            </div>
        </>
    );
}

export default EducationForm;
