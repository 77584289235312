import React, {useEffect, useState} from 'react'
import {CLoadingButton, CModal, CModalBody,} from "@coreui/react-pro";
import {useLocation, useNavigate} from "react-router-dom";
import {AiOutlineClose} from "react-icons/ai";
import {fail_notify, success_notify} from "../../constant";
import {useAddRole, useAddSponsorRole,} from "../../hooks/employer/employerApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useGetEmployerByUUID} from "../../hooks/user/userApiHooks";

const AddRole = () => {
    const {user, storeUser, stakeholderRoles} = useAppContext()
    const [visible, setVisible] = useState(true)
    const [role, setRole] = useState('')
    const [sponsor, setSponsor] = useState(false)
    const location = useLocation();
    const navigate = useNavigate()

    const {
        data: employerData
    } = useGetEmployerByUUID(user?.data?.stakeholderUuid)

    const {
        isLoading,
        mutate, data,
        isSuccess,
        isError, error
    } = useAddRole()

    const addSponsor = useAddSponsorRole()

    useEffect(() => {
        if (location?.pathname?.toLowerCase()?.includes('become-an-academy')) {
            setRole('Academy')
        } else if (location?.pathname?.toLowerCase()?.includes('become-an-employer')) {
            setRole('Employer')
        } else if (location?.pathname?.toLowerCase()?.includes('become-a-sponsor')) {
            setSponsor(true)
        } else {
            navigate(-1)
        }
    }, [])

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message);
            navigate(-1)
        }
        if (isSuccess) {
            success_notify('Successful')
            let updatedObject = {...user}
            updatedObject.data.roles = ["Employer", "Academy"]
            storeUser(updatedObject)
            setVisible(false)
            navigate(-1)

        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    useEffect(() => {
        if (addSponsor.isError) {
            fail_notify(error?.response?.data?.message);
            navigate(-1)
        }

        if (addSponsor.isSuccess) {
            success_notify('Successful')
            let updatedObject = {...user}
            updatedObject.data.isSponsor = true
            storeUser(updatedObject)
            setVisible(false)
            navigate(-1)
        }
    }, [addSponsor.isLoading, addSponsor.isSuccess, addSponsor.data, addSponsor.isError, addSponsor.error, addSponsor.mutate])

    const handleAddRole = () => {
        if (stakeholderRoles.length > 1) {
            fail_notify("You are not authorized")
            return
        }
        let data = {addRole: role}
        mutate(data)

    }

    const handleCreateUserOnCbS = () => {
        const empData = employerData?.data?.data
        if (empData.state && empData.localGovernment) {
            const lgaCode = empData.state.toUpperCase().slice(0, 3)
            const data = {
                sponsor: true,
                stateCode: lgaCode,
                lgaCode: empData.localGovernment,
                payerCategory: 1
            }
            addSponsor.mutate(data)

        } else {
            fail_notify("Please complete your profile")

        }
    }

    return (
        <>
            {
                !sponsor ?
                    <CModal backdrop="static" alignment="center" visible={visible}>
                        <div className="d-flex justify-content-end p-2">
                            <AiOutlineClose className="cursor-pointer" onClick={() => {
                                setVisible(false)
                                navigate(-1)
                            }}/>
                        </div>
                        <CModalBody>
                            You are about to become an {role.toLowerCase()}, click on the proceed button to continue.
                        </CModalBody>
                        <div className="d-flex gap-2 w-100 justify-content-end px-3 pb-3">
                            <CLoadingButton
                                loading={isLoading}
                                disabled={isLoading}
                                color="primary text-white"
                                onClick={() => {
                                    handleAddRole()
                                }}>
                                Proceed
                            </CLoadingButton>
                        </div>
                    </CModal> :
                    <CModal backdrop="static" alignment="center" visible={visible}>
                        <div className="d-flex justify-content-end p-2">
                            <AiOutlineClose
                                className="cursor-pointer"
                                onClick={() => {
                                    setVisible(false)
                                    navigate(-1)
                                }}/>
                        </div>
                        <CModalBody>
                            You are about to become a Sponsor, click on the proceed button to continue.
                        </CModalBody>
                        <div className="d-flex gap-2 w-100 justify-content-end px-3 pb-3">
                            <CLoadingButton
                                loading={addSponsor.isLoading}
                                disabled={addSponsor.isLoading}
                                color="primary text-white"
                                onClick={() => {
                                    handleCreateUserOnCbS()
                                }}>Proceed</CLoadingButton>
                        </div>
                    </CModal>
            }
        </>
    )
}

export default AddRole




