import React, {useState} from 'react'
import {CCol, CContainer, CFormRange, CImage, CRow} from "@coreui/react-pro";
import {GoLocation, GoMail} from "react-icons/go";
import {BsGenderAmbiguous} from "react-icons/bs";
import DefaultPic from "../../../../assets/images/dummy-profile-pic.png"
import TrophyIcon from "../../../../assets/icons/trophy-icon.png"
import MedalIcon from "../../../../assets/icons/medal-icon.png"
import {BiArrowBack} from "react-icons/bi";
import {AiOutlinePhone} from "react-icons/ai";
import moment from "moment";
import {useReportContext} from "../../../../context/admin/ReportProvider";

const AssessmentAnalysisPage = () => {

    const {userTestData, setIsAnalysisVisible} = useReportContext()
    const [webCamIndex, setWebCamIndex] = useState(0)
    const [screenShotsIndex, setScreenShotsIndex] = useState(0)

    return (
        <CContainer className="mb-5">
            <p onClick={() => setIsAnalysisVisible(false)} style={{cursor: 'pointer'}}><BiArrowBack/> Go Back</p>
            <h4 className="my-3">Assessment - {userTestData?.quizType}</h4>
            <CRow>
                <CCol md={4} style={{height: 'fit-content'}}>
                    <div className="p-2 bg-white rounded me-1">
                        <h6 className="bg-grey-100 p-2 gray-paragraph">Applicant Details</h6>
                        <CRow>
                            <CCol md={4} className="pe-0">
                                <CImage
                                    src={userTestData?.profile?.passportUrl || DefaultPic}
                                    style={{width: '100%'}}
                                    onError={(e) => {
                                        e.target.src = DefaultPic
                                    }}
                                />
                            </CCol>
                            <CCol md={8} className="d-flex flex-column justify-content-between ps-1">
                                <div>
                                    <h6 className="mb-0">{userTestData?.firstName} {userTestData?.lastName}</h6>
                                    <p className="smaller-text-12 gray-paragraph">{userTestData?.role}</p>
                                </div>
                                <div>
                                    <p className="smaller-text-12 gray-paragraph">
                                        <AiOutlinePhone size={14}/> {userTestData?.phoneNumber}
                                    </p>
                                    <p className="smaller-text-12 text-truncate gray-paragraph">
                                        <GoLocation size={16}/> {userTestData?.email}
                                    </p>
                                    <p className="smaller-text-12 gray-paragraph">
                                        <BsGenderAmbiguous
                                            size={16}/> {userTestData?.profile?.gender === 'M' ? 'Male' : 'Female'}
                                    </p>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol md={8} className="p-2 bg-white rounded">
                    <h6 className="bg-grey-100 p-2 gray-paragraph">Assessment Analysis</h6>
                    <CRow>
                        <CCol md={2}>
                            <div>
                                <div
                                    className="d-flex flex-row justify-content-between align-items-center shadow-sm rounded p-2">
                                    <div>
                                        <h6 className="m-0 text-small">SCORE</h6>
                                        <h5 className="m-0 text-warning">{userTestData?.passedPercent || '0'}%</h5>
                                    </div>
                                    <div className="">
                                        <CImage src={TrophyIcon} style={{width: '20px'}}/>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-row justify-content-between align-items-center shadow-sm rounded p-2 mt-3">
                                    <div>
                                        <h6 className="m-0 text-small">STATUS</h6>
                                        <h5 className={`m-0 ${userTestData?.passedPercent >= 50 ? 'text-primary' : 'text-danger'}`}>
                                            {userTestData?.passedPercent > 50 ? 'Pass' : 'Fail'}
                                        </h5>
                                    </div>
                                    <div className="">
                                        <CImage src={MedalIcon} style={{width: '20px'}}/>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                        <CCol md={10} className="pb-2">
                            <div className="border border-1 table-border-grey px-2 pb-2">
                                <h6 className="my-2">Anti-Cheating History</h6>
                                <hr/>
                                {userTestData?.quizProctoring?.length > 0 &&
                                    <>
                                        <CRow>
                                            <CCol md={6}>
                                                <CRow className="mb-3">
                                                    <CCol md={5}>
                                                        <p className="smaller-text-12 gray-paragraph">Date:</p>
                                                    </CCol>
                                                    <CCol md={7}>
                                                        <p className="smaller-text-12">{
                                                            userTestData?.quizProctoring[0]?.createdAt ?
                                                                moment(userTestData?.quizProctoring[0]?.createdAt).format('MMMM Do YYYY, h:mm:ss a') : 'Nil'
                                                        }</p>
                                                    </CCol>
                                                </CRow>
                                                <CRow className="mb-3">
                                                    <CCol md={5}>
                                                        <p className="smaller-text-12 gray-paragraph">Device:</p>
                                                    </CCol>
                                                    <CCol md={7}>
                                                        <p className="smaller-text-12">Desktop</p>
                                                    </CCol>
                                                </CRow>
                                                <CRow className="mb-3">
                                                    <CCol md={5}>
                                                        <p className="smaller-text-12 gray-paragraph">Source:</p>
                                                    </CCol>
                                                    <CCol md={7}>
                                                        <p className="smaller-text-12">WorkNation</p>
                                                    </CCol>
                                                </CRow>
                                                <CRow className="mb-3">
                                                    <CCol md={5}>
                                                        <p className="smaller-text-12 gray-paragraph">Scoring
                                                            Method:</p>
                                                    </CCol>
                                                    <CCol md={7}>
                                                        <p className="smaller-text-12">Percentile</p>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            <CCol md={6}>
                                                <CRow
                                                    className="mb-3 d-flex flex-row justify-content-start align-items-center">
                                                    <CCol md={8}>
                                                        <p className="smaller-text-12 gray-paragraph mb-0">Is test
                                                            approved?</p>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <button
                                                            className={`btn btn-sm ${userTestData?.quizProctoring[0]?.isApprove ? 'btn-primary' : 'btn-danger'} text-white px-3 rounded-0`}>
                                                            {userTestData?.quizProctoring[0]?.isApprove ? 'Yes' : 'No'}
                                                        </button>
                                                    </CCol>
                                                </CRow>
                                                <CRow
                                                    className="mb-3 d-flex flex-row justify-content-start align-items-center">
                                                    <CCol md={8}>
                                                        <p className="smaller-text-12 gray-paragraph">Webcam
                                                            enabled?</p>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <button
                                                            className={`btn btn-sm ${userTestData?.quizProctoring[0]?.webCam?.length > 0 ? 'btn-primary' : 'btn-danger'} text-white px-3 rounded-0`}>
                                                            {userTestData?.quizProctoring[0]?.webCam?.length > 0 ? 'Yes' : 'No'}
                                                        </button>
                                                    </CCol>
                                                </CRow>
                                                <CRow
                                                    className="mb-3 d-flex flex-row justify-content-start align-items-center">
                                                    <CCol md={8}>
                                                        <p className="smaller-text-12 gray-paragraph">Full screen mode
                                                            always active?</p>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <button
                                                            className={`btn btn-sm ${userTestData?.quizProctoring[0]?.quiz?.isActive ? 'btn-primary' : 'btn-danger'} text-white px-3 rounded-0`}>
                                                            {userTestData?.quizProctoring[0]?.quiz?.isActive ? 'Yes' : 'No'}
                                                        </button>
                                                    </CCol>
                                                </CRow>
                                                <CRow
                                                    className="mb-3 d-flex flex-row justify-content-start align-items-center">
                                                    <CCol md={8}>
                                                        <p className="smaller-text-12 gray-paragraph">User did not
                                                            change
                                                            tab?</p>
                                                    </CCol>
                                                    <CCol md={4}>
                                                        <button
                                                            className={`btn btn-sm ${userTestData?.quizProctoring[0]?.changeTab ? 'btn-primary' : 'btn-danger'} text-white px-3 rounded-0`}>
                                                            {userTestData?.quizProctoring[0]?.changeTab ? 'Yes' : 'No'}
                                                        </button>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mt-3">
                                            <CCol md={6}>
                                                <h6 className="text-primary text-decoration-underline">Web Cam
                                                    Shots</h6>
                                                <CCol md={12}>
                                                    <CImage
                                                        src={userTestData?.quizProctoring[0]?.webCam?.[webCamIndex]?.Location}
                                                        className="assessment-images"
                                                    />
                                                    <CFormRange
                                                        min={0}
                                                        max={userTestData?.quizProctoring[0]?.webCam?.length - 1}
                                                        label="WebCam Range Slider"
                                                        defaultValue="0"
                                                        id="webcam-range-slider"
                                                        onChange={(e) => setWebCamIndex(+e.target.value)}
                                                        steps={1}
                                                    />
                                                    <p className="small-text">
                                                        {webCamIndex + 1}/{userTestData?.quizProctoring[0]?.webCam?.length}
                                                    </p>
                                                </CCol>
                                            </CCol>
                                            <CCol md={6}>
                                                <h6 className="text-primary text-decoration-underline">Screen Shots</h6>
                                                <CCol md={12}>
                                                    <CImage
                                                        src={userTestData?.quizProctoring[0]?.screenShots?.[screenShotsIndex]?.Location}
                                                        className="assessment-images"
                                                    />
                                                    <CFormRange
                                                        min={0}
                                                        max={userTestData?.quizProctoring[0]?.screenShots?.length - 1}
                                                        label="Screenshots Range Slider"
                                                        defaultValue="0"
                                                        id="screenshot-range-slider"
                                                        onChange={(e) => setScreenShotsIndex(+e.target.value)}
                                                        steps={1}
                                                    />
                                                    <p className="small-text">
                                                        {screenShotsIndex + 1}/{userTestData?.quizProctoring[0]?.screenShots?.length}
                                                    </p>
                                                </CCol>
                                            </CCol>
                                        </CRow>
                                    </>
                                }
                            </div>
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default AssessmentAnalysisPage
