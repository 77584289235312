import {CButton, CModal, CModalBody, CModalTitle} from "@coreui/react-pro";
import React from "react";

const ConfirmModal = ({visible, setVisible, title, data, handler, message}) => {
    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                <CModalBody className="text-center py-3">
                    <CModalTitle className="text-title mb-5"> {title}</CModalTitle>
                    <p className="pb-3">{message}</p>
                    <div className="d-flex flex-row justify-content-center align-items-center py-3">
                        <CButton onClick={() => setVisible(false)} className="text-white btn-warning mx-1">
                            No, Cancel
                        </CButton>
                        <CButton color="primary"
                                 onClick={() => {
                                     handler(data)
                                     setVisible(false)
                                 }}
                                 className="text-white px-5 mx-1"
                        >
                            Yes
                        </CButton>

                    </div>
                </CModalBody>
            </CModal>
        </>
    )
}

export default ConfirmModal