import React, {useEffect, useState} from "react";
import {CCol, CFormCheck, CFormInput, CFormSelect, CLoadingButton} from "@coreui/react-pro";
import TextEditor from "../TextEditor";
import {FiSave} from "react-icons/fi";
import {FaPlus, FaMinusCircle} from "react-icons/fa";
import {usePostQuestionQuery} from "../../hooks/admin/adminApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useNavigate, useParams} from "react-router-dom";

const PostTest = () => {
    const {uuid, title} = useParams()
    const navigate = useNavigate()
    const [questionTitle, setQuestionTitle] = useState('')
    const [question, setQuestion] = useState(null)
    const optionStuct = [
        {text: null, isCorrect: false},
        {text: null, isCorrect: false},
    ]
    const [options, setOptions] = useState([...optionStuct]);
    const schema = yup.object().shape({
        questionTitle: yup.string().typeError('Assessment title is required').required('Assessment title is required'),
        question: yup.string().typeError('Question is required').required('Question is required'),
        level: yup.string().typeError('Question level is required').required('Question level is required'),
        options: yup.array().of(
            yup.object().shape({
                text: yup.string().typeError('Option text is required').required('Option text is required'),
            })
        ),
    });
    const {
        register,
        trigger, // Add trigger function
        setValue,
        watch,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });

    //console.log(watch())
    const {isLoading, isSuccess, data, isError, error, mutate} = usePostQuestionQuery(uuid)

    const handleQuestionArray = () => {
        // Manually trigger validation for all fields
        setValue('title', uuid ? title.replace('-', ' ') : questionTitle)
        setValue('question', question)
        setValue('options', options)
        setValue('options', options)


        trigger().then((isValid) => {
            if (isValid) {
                // Validation succeeded, proceed with adding the question
                setValue('questions', [{
                    question: question,
                    isMultipleChoice: watch("isMultipleChoice"),
                    level: watch("level"),
                    options: watch("options")
                }])
                saveQuestionHandler()

            } else {
                // Validation failed, handle errors if needed
            }
        });
    };

    function capitalizeFirstLetter(sentence) {
        return sentence.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    const saveQuestionHandler = () => {
        let data = {};
        data.title = capitalizeFirstLetter(watch("questionTitle"));
        data.questions = watch("questions");
        mutate(data);
    }
    useEffect(() => {
        if (isSuccess) {
            success_notify('Questions added successfully')
            setValue("questions", [])
            setValue("options", optionStuct)
            setQuestion(null)
            setOptions(optionStuct)
            if (!uuid || uuid == undefined) {
                navigate(`/admin/upload-questions/${data?.data?.data?.quizUuid}/${data?.data?.data?.title.replace(' ', '-')}`, {
                    replace: true,
                })

            }
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])


    const handleOptionCorrectChange = (event, index) => {
        const newOptions = [...options];
        newOptions[index].isCorrect = event.target.checked;
        setOptions(newOptions);
    };

    const handleOptionTextChange = (editorState, index) => {
        const newOptions = [...options];
        if (!editorState || editorState == "<p><br></p>") {
            newOptions[index].text = null;
        } else {
            newOptions[index].text = editorState;
        }
        setOptions(newOptions);
    };
    const handleQuestionChange = (editorState) => {

        if (!editorState || editorState == "<p><br></p>") {
            setQuestion(null);
            return
        }
        setQuestion(editorState);

    };

    const handleAddOption = () => {
        setOptions([...options, {text: '', isCorrect: false}]);
    };

    const handleRemoveOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };

    return (
        <>
            <CCol md={10} className="bg-white w-100 min-vh-100 border-radius-12 p-3 box-shadow">
                {/*<center>
                    <div>Total Questions Added is {totalQuestion.length}</div>
                </center>*/}
                <strong>Assessment Title</strong>
                <CFormInput
                    type={uuid ? "hidden" : "text"}
                    placeholder="Enter assessment title"
                    className="rounded-0"
                    {...register("questionTitle")}
                    value={uuid ? title.replace('-', ' ') : questionTitle}
                    onChange={(e) => {
                        setQuestionTitle(e.target.value)
                    }}
                />

                <CFormInput
                    type={(!uuid || uuid == undefined) ? "hidden" : "text"}
                    placeholder="Enter assessment title"
                    className="rounded-0"
                    disabled={true}
                    {...register("questionTitle")}
                    value={uuid ? title.replace('-', ' ') : questionTitle}
                    onChange={(e) => {
                        setQuestionTitle(e.target.value)
                    }}
                />

                {errors.questionTitle && <p className="text-danger">{errors.questionTitle.message}</p>}
                <br/>
                <strong>Assessment Level</strong>
                <CFormSelect
                    aria-label="Default select example"
                    className="rounded-0"
                    {...register(`level`)}
                >
                    <option value="">Select question Level</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </CFormSelect>
                {errors.level && <p className="text-danger">{errors.level.message}</p>}
                <br/>
                <CFormCheck className='fw-semibold' id="flexCheckDefault" label="Multiple choice type question"
                            {...register(`isMultipleChoice`)}
                />
                <br/>
                <strong>Question</strong>
                <TextEditor
                    editorState={question}
                    setEditorState={(editorState) => handleQuestionChange(editorState)}
                />
                {errors.question && <p className="text-danger">{errors.question.message}</p>}
                {
                    options?.map((option, index) => {
                        return (
                            <div key={index}>
                                <br/>
                                <div className="d-flex justify-content-between">
                                    <strong>Option {index + 1}</strong>
                                    <CFormCheck id="flexCheckDefault" label="Click if correct answer"
                                                checked={option.isCorrect}
                                                onChange={(event) => handleOptionCorrectChange(event, index)}/>
                                </div>
                                <TextEditor
                                    editorState={option?.text}
                                    setEditorState={(editorState) => handleOptionTextChange(editorState, index)}
                                />
                                {index > 1 && <div onClick={() => handleRemoveOption(index)}
                                                   className="text-danger cursor-pointer">
                                    <FaMinusCircle
                                        className="text-red"/> Remove option
                                </div>}
                                {errors?.options?.[index]?.text &&
                                    <p className="text-danger">{errors.options[index].text.message}</p>}
                            </div>
                        )
                    })
                }
                <CLoadingButton className="text-white mt-2 px-3" onClick={handleAddOption}><FaPlus
                    className="text-white"/> Add Option</CLoadingButton>
                <div className="d-flex gap-1 justify-content-end flex-wrap">
                    {/* <div className="d-flex justify-content-end">
                        <CLoadingButton disabled={totalQuestion.length < 1} className="text-white mt-2 px-3"
                                        onClick={saveQuestionHandler}><FiSave/> Save
                            Question</CLoadingButton>
                    </div>*/}
                    <div className="d-flex justify-content-end">
                        <CLoadingButton disabled={isLoading} loading={isLoading} className="text-white mt-2 px-3"
                                        onClick={handleQuestionArray}>
                            {!isLoading && <FaPlus className="text-white"/>} Add Question</CLoadingButton>
                    </div>
                </div>
            </CCol>
        </>
    )
}

export default PostTest
