import React from 'react';
import {CCol, CFormCheck, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import {months} from "../../../../constant";
import RichTextEditorForCv from "../../RichTextEditorForCv";
import {useGetNigerianCities} from "../../../../hooks/user/userApiHooks";

function ExperienceForm({errors, index, register, setEditorState, watchAllFields}) {
    const {data: cities} = useGetNigerianCities()

    return (
        <>
            <div className="w-100">
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Job Title</CFormLabel>
                        <CFormInput
                            type="text"
                            id="jobTitle"
                            placeholder="Software Developer"
                            {...register(`workExperience[${index}].jobTitle`)}
                            className="mb-2"
                            invalid={errors?.workExperience?.[index]?.jobTitle?.message}
                        />
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.workExperience?.[index]?.jobTitle?.messagee}>
                            {errors?.workExperience?.[index]?.jobTitle?.message}
                        </CFormFeedback>

                    </CCol>
                    <CCol>
                        <CFormLabel className="text-black mb-0">City/Town</CFormLabel>
                        {/*<CFormInput*/}
                        {/*    type="text"*/}
                        {/*    id="city"*/}
                        {/*    placeholder="Abuja"*/}
                        {/*    {...register(`workExperience[${index}].city`)}*/}
                        {/*    className="mb-2"*/}
                        {/*    invalid={errors?.workExperience?.[index]?.city?.message}*/}
                        {/*/>*/}
                        <CFormSelect
                            id="city"
                            className="form-control mb-2"
                            {...register(`workExperience[${index}].city`)}
                            invalid={errors?.workExperience?.[index]?.city?.message}
                        >
                            {cities?.data?.data?.map(({name}, index) => (
                                <option value={name} key={index}>{name}</option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.workExperience?.[index]?.city?.messagee}>
                            {errors?.workExperience?.[index]?.city?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Employer</CFormLabel>
                        <CFormInput
                            type="text"
                            id="companyName"

                            placeholder="Outsource Global"
                            {...register(`workExperience[${index}].companyName`)}
                            className="mb-2"
                            invalid={errors?.workExperience?.[index]?.companyName?.message}
                        />
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.workExperience?.[index]?.companyName?.messagee}>
                            {errors?.workExperience?.[index]?.companyName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol>
                        <CFormLabel className="text-black mb-0"></CFormLabel>
                        <CFormCheck id="currentlyWorking"
                                    {...register(`workExperience[${index}].currentlyWorking`)}
                                    label="I am currently working in this role"/>
                    </CCol>
                </CRow>
                <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}}
                      className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Start Date</CFormLabel>
                        <div className="d-flex gap-2">
                            <div className="w-100">
                                <CFormSelect id="startMonth"
                                             invalid={errors?.workExperience?.[index]?.startMonth?.message}
                                             {...register(`workExperience[${index}].startMonth`)}>
                                    <option value={""}>Month</option>
                                    {months.map((month, i) => (
                                        <option key={i} value={month}>
                                            {month}
                                        </option>
                                    ))}

                                </CFormSelect>
                                <CFormFeedback className="text-danger"
                                               invalid={!!errors?.workExperience?.[index]?.startMonth?.messagee}>
                                    {errors?.workExperience?.[index]?.startMonth?.message}
                                </CFormFeedback>
                            </div>
                            <div className="w-100">
                                <CFormSelect id="startDate"
                                             invalid={errors?.workExperience?.[index]?.startDate?.message}
                                             {...register(`workExperience[${index}].startDate`)}>
                                    <option value={""}>Year</option>
                                    {[...Array(150)].map((_, i) => (
                                        <option key={i} value={new Date().getFullYear() - i}>
                                            {new Date().getFullYear() - i}
                                        </option>
                                    ))}

                                </CFormSelect>
                                <CFormFeedback className="text-danger"
                                               invalid={!!errors?.workExperience?.[index]?.startDate?.messagee}>
                                    {errors?.workExperience?.[index]?.startDate?.message}
                                </CFormFeedback>
                            </div>
                        </div>
                    </CCol>
                    <CCol>
                        <CFormLabel className="text-black mb-0">End Date</CFormLabel>

                        {
                            watchAllFields?.workExperience?.[index]?.currentlyWorking ?
                                <div className="d-flex gap-2">
                                    <div className="w-100">
                                        <CFormSelect disabled>
                                            <option value={""}>Month</option>
                                        </CFormSelect>

                                    </div>
                                    <div className="w-100">
                                        <CFormSelect disabled>
                                            <option value={""}>Year</option>

                                        </CFormSelect>

                                    </div>
                                </div> :
                                <div className="d-flex gap-2">
                                    <div className="w-100">
                                        <CFormSelect id="endMonth"
                                                     invalid={errors?.workExperience?.[index]?.endMonth?.message}
                                                     {...register(`workExperience[${index}].endMonth`)}>
                                            <option value={""}>Month</option>
                                            {months.map((month, i) => (
                                                <option key={i} value={month}>
                                                    {month}
                                                </option>
                                            ))}
                                        </CFormSelect>
                                        <CFormFeedback className="text-danger"
                                                       invalid={!!errors?.workExperience?.[index]?.endMonth?.messagee}>
                                            {errors?.workExperience?.[index]?.endMonth?.message}
                                        </CFormFeedback>
                                    </div>
                                    <div className="w-100">
                                        <CFormSelect id="endDate"
                                                     invalid={errors?.workExperience?.[index]?.endDate?.message}
                                                     {...register(`workExperience[${index}].endDate`)}>
                                            <option value={""}>Year</option>
                                            {[...Array(150)].map((_, i) => (
                                                <option key={i}
                                                        value={new Date().getFullYear() - i}>
                                                    {new Date().getFullYear() - i}
                                                </option>
                                            ))}
                                        </CFormSelect>
                                        <CFormFeedback
                                            className="text-danger"
                                            invalid={!!errors?.workExperience?.[index]?.endDate?.messagee}
                                        >
                                            {errors?.workExperience?.[index]?.endDate?.message}
                                        </CFormFeedback>
                                    </div>
                                </div>
                        }
                    </CCol>
                </CRow>
                <CRow className="g-3 py-2">
                    <CCol>
                        <CFormLabel className="text-black mb-0">Description</CFormLabel>

                        <RichTextEditorForCv
                            editorState={watchAllFields?.workExperience}
                            descriptionId={index}
                            setEditorState={setEditorState}
                        />
                        <CFormFeedback className="text-danger"
                                       invalid={!!errors?.workExperience?.[index]?.description?.messagee}>
                            {errors?.workExperience?.[index]?.description?.message}
                        </CFormFeedback>
                    </CCol>

                </CRow>

            </div>
        </>
    );
}

export default ExperienceForm;
