import React, {useEffect, useState} from 'react';
import {CCol, CNavLink} from "@coreui/react-pro";
import ScoresModal from "../../modals/ScoresModal";
import useQuestionnaireStatus from "../../../hooks/user/questionnaireStatus";
import {BsFillFileLock2Fill} from 'react-icons/bs'
import {AiFillCheckCircle} from 'react-icons/ai'
import {useAppContext} from "../../../context/user/UserAppProvider";

function SkillSetCard({skill, setAllAnswered, setUnAnsweredTest, isMandatoryTestTaken}) {
    const url = skill?.title.replace(' ', '-')
    const {questionnaireChecker, dataIsLoading} = useQuestionnaireStatus()
    const [isAnswered, setIsAnswered] = useState(false)
    const [visible, setVisible] = useState(false)
    const {user} = useAppContext()

    useEffect(() => {
        const isAnswered = questionnaireChecker(skill?.title)
        if (isAnswered) {
            setIsAnswered(isAnswered)
        }

        if (!isAnswered) {
            setUnAnsweredTest(unAnsweredTest => [...unAnsweredTest, skill?.title])
            setAllAnswered(false)
        }

    }, [skill, dataIsLoading])

    const handleClickSeeResult = (e) => {
        e.preventDefault()
        setVisible(!visible)
    }

    return (
        <CCol className="bg-grey-100 rounded-2 px-2 d-flex justify-content-between align-content-center mt-3 py-3">
            <div>
                <h5 className="text-wn-16">{skill?.title} </h5>
            </div>
            <div className="d-flex flex-column justify-content-center">
                {
                    (isMandatoryTestTaken && user?.data?.isProfileCompleted)
                        ? <div className="-mt-2 justify-content-end align-content-end text-left">
                            {
                                isAnswered ?
                                    <>
                                        <CNavLink href="#" className={`font-color`} onClick={handleClickSeeResult}>
                                            <AiFillCheckCircle/>
                                            {
                                                `  See Result`
                                            }
                                        </CNavLink>
                                        {
                                            visible &&
                                            <ScoresModal setVisible={setVisible} visible={visible} title={skill?.title}/>
                                        }
                                    </>
                                    :
                                    <CNavLink href={`assessment/${url.replace(" ", "-")}`}
                                              className={`bg-green-500 px-2 rounded-1 text-white text-wn-12`}
                                    >Take Test</CNavLink>
                            }
                        </div>

                        :

                        <div className="-mt-2 justify-content-end align-content-end text-left">
                            <BsFillFileLock2Fill width={90} height={90} color={'red'}/>
                        </div>
                }
            </div>
        </CCol>
    );
}

export default SkillSetCard;
