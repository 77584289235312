import {CImage, CNavLink, CRow} from "@coreui/react-pro";
import React from "react";
import UserProfileTagV2 from "./UserProfileTagV2";
import HRIcon from "../../assets/images/shared-profile-hr.png";

const UserProfileTestWithPercent = ({data}) => {

    return (
        <div className="my-2">
            <h6 className="fw-bold">{data[2]?.title}</h6>
            <CRow className="gx-3">
                {
                    data[0]?.data?.map((item, index) => {
                        return (<UserProfileTagV2 key={index} tag={item.content} tips={item.tip}/>)
                    })
                }
            </CRow>
            <div>
                {
                    data[1]?.takeAssessment &&
                    <CNavLink href={`/user/questionnaire/${data[3]?.testType.replace(/\s/g, '-')}`}
                              className={`font-color text-center text-3 mt-2 mb-2`}>
                        Take Assessment
                    </CNavLink>
                }
            </div>
            <CImage src={HRIcon} style={{width: '100%'}}/>
        </div>
    )
}

export default UserProfileTestWithPercent
