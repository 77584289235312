export const getEnvironment = () => {
    const hostName = window?.location?.hostname?.trim?.()?.toLowerCase() || ''

    let env = undefined
    if (hostName.includes('nexit-admin.ogtlprojects.com')) {
        env = 'dev'
    } else if (hostName.includes('worknation.ng')) {
        env = 'prod'
    } else if (hostName.includes('localhost')) {
        env = 'localhost'
    }

    return env
}

export const isLocalHost = () => getEnvironment() === 'localhost'
export const isStaging = () => getEnvironment() === 'dev'
export const isProd = () => getEnvironment() === 'prod'


export const getApiEndpoint = () => {

    if (isProd()) {
        return 'https://api.worknation.ng/api/v1'
    }

    return 'https://worknation-development.ogtlprojects.com/api/v1/'

}

export const getDomain = () => {
    const hostName = window?.location?.hostname?.trim?.()?.toLowerCase() || ''
    return hostName.includes('worknation.ng') ? 'https://worknation.ng' : 'https://nexit-admin.ogtlprojects.com';
}
