import {
    CButton,
    CCol,
    CForm, CImage,
    CModal,
    CModalBody, CModalFooter,
    CModalHeader,
    CRow
} from "@coreui/react-pro";
import React, {useState} from "react";
import HRIcon from "../../assets/images/shared-profile-hr.png";

const UserProfileBasicInfo = ({userProfileData}) => {

   const [visible, setVisible] = useState(false)
  return (
      <>
          <div className="d-flex justify-content-between align-items-center">
              <h6 className="fw-bold">Basic Information</h6>
          </div>
          <CImage src={HRIcon} style={{width: '100%'}}/>
          <CRow className="my-4">
              <CCol md={4}>
                  <h6 className="fw-bold">State of Origin</h6>
                  <p>{`${userProfileData?.stateOfOrigin ? userProfileData?.stateOfOrigin : 'Nil'}`}</p>
              </CCol>
              <CCol md={4}>
                  <h6 className="fw-bold">LGA of Origin</h6>
                  <p>{`${userProfileData?.localGovernmentOfOrigin ? userProfileData?.localGovernmentOfOrigin : 'Nil'}`}</p>
              </CCol>
              <CCol md={4}>
                  <h6 className="fw-bold">State of Residence</h6>
                  <p>{`${userProfileData?.stateOfResidence ? userProfileData?.stateOfResidence : 'Nil'}`}</p>
              </CCol>
          </CRow>
          <CRow className="my-4">
              <CCol md={4}>
                  <h6 className="fw-bold">LGA of Residence</h6>
                  <p>{`${userProfileData?.localGovernmentOfResidence ? userProfileData?.localGovernmentOfResidence : 'Nil'}`}</p>
              </CCol>
              <CCol md={4}>
                  <h6 className="fw-bold">Address</h6>
                  <p>{`${userProfileData?.address ? userProfileData?.address : 'Nil'}`}</p>
              </CCol>
          </CRow>

          <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
              <CModalHeader className="fw-bolder">
                  Basic Information
              </CModalHeader>
              <CModalBody>
                  <CForm className="row g-3">
                  </CForm>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end align-items-center">
                  <CButton className=" text-white">Submit</CButton>
              </CModalFooter>
          </CModal>
      </>
  )
}

export default UserProfileBasicInfo
