import React from 'react';
import {CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react-pro";

function CompleteYourTestNotification({unAnsweredSkills, setAllAnswered}) {
    return (
        <>
            <CModal alignment="center" visible={true} onClose={() => setAllAnswered(false)}>
                <CModalHeader className="px-4">
                    <CModalTitle>Skill Sets Test</CModalTitle>
                </CModalHeader>
                <CModalBody className="p-4">
                    <p>
                        You are yet to complete our revised assessments to help us better understand your
                        skills, abilities & training needs. You can complete the assessments by clicking on
                        &quot;Take Test&quot; in the skill sets section.
                        <br/>
                        Below {unAnsweredSkills.length > 1 ? 'are' : 'is '} your pending Test
                        {unAnsweredSkills.length > 1 && 's'}
                    </p>
                    <ol>
                        {unAnsweredSkills.map((skill, index) => {
                            return <li key={index}>{skill}</li>
                        })}
                    </ol>
                    <strong>Good Luck!</strong>
                    <br/>
                </CModalBody>
            </CModal>
        </>
    );
}

export default CompleteYourTestNotification;
