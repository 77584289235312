import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditor({editorState, setEditorState, placeholder = "", onChange, name}) {
    const handleEditorChange = (content) => {
        if(onChange){
            onChange(content, name);
        }
        setEditorState(content);
    };

    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <>
            <ReactQuill
                value={editorState}
                onChange={handleEditorChange}
                theme="snow"
                modules={modules}
                placeholder={placeholder}
            />
        </>
    );
}

export default TextEditor;
