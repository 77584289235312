import React from "react";

const KPIManagerBanner = ({title, totalItems, setTrainerModalVisible, setCohortModalVisible}) => {

    return <div className="d-flex flex-row justify-content-between align-items-center">
        <div>
            <h5>Complete List of {title}</h5>
            <p className="small-text-gray">{totalItems} {title}</p>
        </div>
        <div className="d-flex flex-row">
            <div className="mx-3">
                <button
                    className="btn btn-primary px-4 py-2 text-white"
                    onClick={() => setTrainerModalVisible(true)}
                >+ ADD NEW TRAINER
                </button>
            </div>
            <div>
                <button
                    className="btn btn-primary px-4 py-2 text-white"
                    onClick={() => setCohortModalVisible(true)}
                >+ CREATE COHORT
                </button>
            </div>
        </div>
    </div>
}

export default KPIManagerBanner