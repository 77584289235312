import React, {useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import Education from "../../../assets/icons/Education.svg";
import InfoTitle from "./InfoTitle";
import {fail_notify} from "../../../constant";
import {CButton, CCol, CForm, CLoadingButton, CRow} from "@coreui/react-pro";
import PublicationsCard from "./PublicationsCard";
import CVTextEditor from "../CVTextEditor";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const Publications = () => {

    const {status, setStatus, setPublicationsData, cvData} = useCVBuilderContext()
    const [publications, setPublications] = useState(cvData?.data?.data?.data[0]?.publications || []);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder()
    const [editorState, setEditorState] = useState(publications[0] ?? '');
    const [hasPublications, setHasPublications] = useState(false)

    const {
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: {errors},
    } = useForm({
        defaultValues: {
            publications: " "
        }

    });

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        setPublicationsData(watchAllFields)
    }, [watchAllFields])

    useEffect(() => {
        setValue("publications", [editorState])
    }, [editorState])

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0].publications = getValues().publications
            setValue("publications", [editorState])
            if (editorState.length > 0) {
                setHasPublications(false)
            } else {
                setHasPublications(true)
            }

        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (!editorState) {
            setHasPublications(true)
        }
    }, [])

    const onSubmit = (data) => {
        const formattedData = parseAndFormatHTML(data?.publications)
        if (formattedData) {
            mutate(data)
            return
        } else {
            setStatus(7)
        }

    }

    const deletePublications = () => {
        setEditorState([]);
        mutate({publications: []})
    }

    return (<>
        {
            status === 6 &&
            <>
                <InfoTitle title={"Publications"} icon={Education}/>
                {
                    !hasPublications &&

                    <>
                        <PublicationsCard
                            hasPublications={hasPublications}
                            setHasPublications={setHasPublications}
                            deletePublications={deletePublications}
                            publications={editorState}
                        />
                        <center
                            className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-5">
                            <CButton
                                onClick={() => {
                                    setStatus(5)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Back
                            </CButton>
                            <CLoadingButton
                                onClick={() => {
                                    setStatus(7)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Next
                            </CLoadingButton>
                        </center>
                    </>
                }
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    {
                        hasPublications &&
                        <>
                            <div className="d-flex">
                                <div className="w-100">
                                    <CRow className="g-3 py-2">
                                        <CCol>
                                            <div>
                                                <CVTextEditor
                                                    editorState={editorState}
                                                    setEditorState={setEditorState}
                                                />
                                            </div>
                                        </CCol>

                                    </CRow>
                                </div>
                            </div>
                            <center
                                className="d-flex flex-column flex-sm-row align-items-center justify-content-center ">
                                <CButton
                                    onClick={() => {
                                        setHasPublications(!hasPublications)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Back
                                </CButton>
                                <CLoadingButton loading={isLoading} disabled={isLoading} type="submit"
                                                className="py-3 cv-btn m-1 text-white">
                                    SAVE
                                </CLoadingButton>
                            </center>
                        </>
                    }
                </CForm>

            </>
        }
    </>)
}
export default Publications

function parseAndFormatHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim() + "\n").join(' ');
    return formattedText;
}
