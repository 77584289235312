import React, {useEffect, useState} from 'react'
import JobsCard from "../../components/job/JobsCard";
import {CButton, CContainer, CFormSelect} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {
    useGetAppliedJobsQuery,
    useGetJobsQuery,
    useGetRecommendedJobsQuery,
    useGetSaveJobsQuery,
} from "../../hooks/user/userApiHooks";
import AuthRoute from "../../components/User/AuthRoute";
import {useAppContext} from "../../context/user/UserAppProvider";
import ErrorReports from "../../components/ErrorReports";
import ShowLoading from "../../components/ShowLoading";
import BreadCrumb from "../../components/job/BreadCrumb";
import JobFilterModal from "../../components/modals/JobFilterModal";
import DataNotFound from "../../components/DataNotFound";

function Jobs() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [visible, setVisible] = useState(false);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState("");
    const [userLogin, setUserLogin] = useState("");
    const {user, userId} = useAppContext()
    const jobs = useGetJobsQuery(page, limit, '', filter, search, userLogin)
    const [enable, setEnable] = useState(false)
    const [searchEnable, setSearchEnable] = useState(false)
    const applied = useGetAppliedJobsQuery(page, limit, filter, search, enable)
    const saved = useGetSaveJobsQuery(page, limit, filter, search, enable)
    const searches = useGetRecommendedJobsQuery(searchEnable)
    const [activeTap, setActiveTap] = useState(1)
    const noDataFound = ((activeTap === 1 && jobs?.data?.data?.data?.meta?.totalItems === 0) ||
        (activeTap === 2 && saved?.data?.data?.data?.meta?.totalItems === 0) ||
        (activeTap === 3 && applied?.data?.data?.data?.meta?.totalItems === 0))

    useEffect(() => {
        if ((user?.accessToken && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) || !user?.accessToken) {
            setSearchEnable(false)
        }
        if (user?.accessToken && user?.data?.role === "Admin") {
            setUserLogin("&isAdmin=true")
        }else if (user?.accessToken && ((user?.data?.role === "NASIMS") || (user?.data?.role === "NYSC") || (user?.data?.role === "User") || (user?.data?.role === "OG Academy"))) {
            setUserLogin()
        } else if (user?.accessToken && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) {
            setUserLogin("")
        } else {
            setUserLogin("")
        }
    }, [])

    if (jobs.isError || searches.isError) {
        return <ErrorReports error={"Oops Something went wrong"}/>
    }

    if (jobs.isLoading || searches.isError) {
        return <ShowLoading/>
    }

    const pageTap = (active_tap) => {

        if (active_tap !== activeTap) {
            setPage(1)
        }

    }

    const nextPage = () => {
        if (activeTap === 1) {
            return <CButton
                disabled={page === jobs?.data?.data?.data?.meta?.totalPages || !jobs?.data?.data?.data?.meta?.totalItems}
                onClick={() => setPage(page + 1)} className="mx-3 text-primary bg-white py-1 pb-2 px-3">
                <BiRightArrow/>
            </CButton>
        }
        if (activeTap === 2) {
            return <CButton
                disabled={page === saved?.data?.data?.data?.meta?.totalPages || !saved?.data?.data?.data?.meta?.totalItems}
                onClick={() => setPage(page + 1)} className="mx-3 text-primary bg-white py-1 pb-2 px-3">
                <BiRightArrow/>
            </CButton>
        }
        if (activeTap === 3) {
            return <CButton
                disabled={page === applied?.data?.data?.data?.meta?.totalPages || !applied?.data?.data?.data?.meta?.totalItems}
                onClick={() => setPage(page + 1)} className="mx-3 text-primary bg-white py-1 pb-2 px-3">
                <BiRightArrow/>
            </CButton>
        }
    }

    return (
        <div>

            <div className="mb-5">
                <AuthRoute protectedRoute={'/user/jobs'} openRoute={'/jobs'}/>

                {(user?.accessToken && user?.data?.role && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy')))
                    && <div className="d-flex gap-2 flex-column flex-sm-row">
                        <button onClick={() => {
                            pageTap(1)
                            setActiveTap(1)
                        }}
                                className={`fw-normal btn-tap rounded-2 px-3 py-1 border-0 ${activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'}`}>
                            <small>Published Jobs </small></button>
                        <button onClick={() => {
                            pageTap(2)
                            setEnable(true)
                            setActiveTap(2)

                        }}
                                className={`fw-normal btn-tap rounded-2 px-3 py-1 border-0 ${activeTap === 2 ? 'text-white bg-primary' : 'bg-grey'}`}>
                            <small>Saved Jobs </small></button>

                        <button onClick={() => {
                            pageTap(3)
                            setEnable(true)
                            setActiveTap(3)
                        }}
                                className={`fw-normal btn-tap rounded-2 px-3 py-1 border-0  ${activeTap === 3 ? 'text-white bg-primary' : 'bg-grey'}`}>
                            <small>Jobs Applied </small></button>

                    </div>}
                <br/>
                <BreadCrumb setSearch={setSearch} setPage={setPage} title={"Find Exciting Jobs"}
                            desc={"Gain access to more than 10,000+ jobs on WorkNation that best fits your knowledge, talents, skills and qualifications."}/>
                <br/>

                <CContainer>
                    <div className="d-flex flex-column flex-md-row flex-column-reverse justify-content-between">
                        {
                            activeTap === 1 &&
                            <div className="align-self-start align-self-md-center">
                                <strong>{jobs?.data?.data?.data?.meta?.totalItems || "0"} </strong>
                                Job(s) Found
                            </div>
                        }
                        {
                            activeTap === 2 &&
                            <div className="align-self-start align-self-md-center">
                                <strong>{saved?.data?.data?.data?.meta?.totalItems || "0"} </strong>
                                Saved Job(s) Found
                            </div>
                        }
                        {
                            activeTap === 3 &&
                            <div className="align-self-start align-self-md-center">
                                <strong>{applied?.data?.data?.data?.meta?.totalItems || "0"} </strong>Applied
                                Job(s) Found
                            </div>
                        }
                        <div className="d-flex flex-md-row ">

                            <JobFilterModal setFilter={setFilter} setPage={setPage} visible={visible}
                                            setVisible={setVisible}/>
                            <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                     className="mx-3 text-primary bg-white py-1 pb-2 px-3">
                                <BiLeftArrow/>
                            </CButton>
                            {nextPage()}
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <small>Items per page:</small>
                        <CFormSelect
                            onChange={(e) => {
                                setLimit(e.target.value);
                            }}
                            value={limit}
                            className="my-2 mx-2"
                            style={{width: "70px"}}>

                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>

                        </CFormSelect>
                    </div>

                    {noDataFound ? <DataNotFound/> :
                        <>
                            {
                                activeTap === 1 && <JobsCard
                                    page={page}
                                    limit={limit}
                                    filter={filter}
                                    search={search}
                                    data={jobs?.data?.data?.data?.items}
                                />
                            }
                            {activeTap === 2 && <JobsCard data={saved?.data?.data?.data?.items} isJobSaved={true}/>}
                            {activeTap === 3 && <JobsCard data={applied?.data?.data?.data?.items} isJobApplied={true}/>}

                        </>
                    }

                </CContainer>

            </div>
        </div>
    )
}

export default Jobs
