import React from "react";
import SignUpImage from "../assets/images/sign-up.svg";
import SignUp from "../user/pages/user-registration/SignUp";
import LoginImage from "../assets/images/login.svg";
import Login from "../components/Login";
import ForgottenPasswordImage from "../assets/images/forgot-password.svg";
import ForgotPassword from "../user/pages/ForgotPassword";
import ResetPasswordImage from "../assets/images/reset-password.svg";
import ResetPassword from "../user/pages/ResetPassword";
import OTPImage from "../assets/images/otp.svg";
import ConfirmUser from "../user/pages/user-registration/ConfirmUser";
import ConfirmEmployer from "../employer/pages/ConfirmEmployer";
import AdminLoginImage from "../assets/images/admin-login.svg";
import CreateAdminImage from "../assets/images/create-admin.svg";
import GovLoginImage from "../assets/images/gov-login-image.svg";
import CreateAdmin from "../admin/pages/CreateAdmin";
import AdminResetPasswordImage from "../assets/images/admin-reset-password.svg";
import AdminResetPassword from "../admin/pages/AdminResetPassword";
import EntryFormLayout from "../layout/general/EntryFormLayout";
import LMSLogin from "../user/pages/LMSLogin";
import LMS from "../employer/pages/LMS";
import CreateCvOptions from "../user/cv-builder/CreateCVOptions";
import EmployerLogin from "../employer/pages/EmployerLogin";
const AdminLogin = React.lazy(() => import('../admin/pages/Login'))

const OtherRoutes = [
    {path: '/user/signup', image: SignUpImage, element: EntryFormLayout, form: SignUp},
    {path: '/login', image: LoginImage, element: EntryFormLayout, form: Login},
    {path: '/auth/login', image: GovLoginImage, element: EntryFormLayout, form: EmployerLogin},
    {path: '/user/lms/login', element: LMSLogin, form: LMSLogin},
    {path: '/forgot-password', image: ForgottenPasswordImage, element: EntryFormLayout, form: ForgotPassword},
    {path: '/reset-password', image: ResetPasswordImage, element: EntryFormLayout, form: ResetPassword},
    {path: '/verify', image: OTPImage, element: EntryFormLayout, form: ConfirmUser},
    {path: '/verify-account', image: OTPImage, element: EntryFormLayout, form: ConfirmEmployer},
    {path: '/admin/login', image: AdminLoginImage, element: EntryFormLayout, form: AdminLogin},
    {path: '/admin/create', image: CreateAdminImage, element: EntryFormLayout, form: CreateAdmin},
    {path: '/select-options', image: LoginImage, element: EntryFormLayout, form: CreateCvOptions},
    {
        path: '/admin/update-password',
        image: AdminResetPasswordImage,
        element: EntryFormLayout,
        form: AdminResetPassword
    },
    {path: '/transit/lms', element: LMS, form: LMS},


]


export default OtherRoutes