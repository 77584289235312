import React, {useEffect, useState} from 'react';
import {Font} from '@react-pdf/renderer'
import {hasText, isDisplay} from "../../../services/cvbuilder/cvBuilderHelpers";
import DOMPurify from "dompurify";
import {BsTelephoneInboundFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";
import {FaMapMarkerAlt} from "react-icons/fa";
import CVFooter from "../../../components/User/cv-builder/CVFooter";

const HorizontalGreen = ({
                             personalData,
                             skillsData,
                             educationsData,
                             experienceData,
                             achievementsData,
                             languagesData,
                             publicationsData,
                             mainColor
                         }) => {

    const hasSkills = hasText(skillsData?.skills);
    const hasPublications = hasText(publicationsData?.publications);
    const hasLanguages = hasText(languagesData?.languages);
    const hasObjective = hasText(personalData?.careerObjective);
    const hasAchievements = hasText(achievementsData?.achievements);

    const [hasEducation, setHasEducation] = useState(false)
    const [hasExperience, setHasExperience] = useState(false)

    useEffect(() => {
        if (experienceData?.workExperience?.length > 0) {
            setHasExperience(isDisplay(experienceData?.workExperience))
        }
        if (educationsData?.educations?.length > 0) {
            setHasEducation(isDisplay(educationsData?.educations))
        }

    }, [experienceData, educationsData])
    const bar = <div className="w-100 bg-grey rounded-2">
        <div className="w-50 bg-black rounded-2" style={{height: '4px'}} aria-valuenow="50">

        </div>
    </div>
    return (
        <>
            <div className="bg-white w-cv main-content">
                <div className="d-flex justify-content-between w-100 center-item pt-4 ">
                    <div className="ms-4 me-3 text-white">
                        <div className="d-flex center-item">
                            <div className="bg-green-temp px-2 py-1 h-100"><BsTelephoneInboundFill
                                className="bg-green-temp h-100"/></div>
                            <p className="lh-1 text-secondary ps-2">{personalData?.phoneNumber}</p></div>
                        <div className="d-flex center-item">
                            <div className="bg-green-temp px-2 py-1 h-100"><MdEmail className="bg-green-temp h-100"/>
                            </div>
                            <p className="lh-1 text-secondary ps-2">{personalData?.email}</p></div>
                        <div className="d-flex center-item">
                            <div className="bg-green-temp px-2 py-1 h-100"><FaMapMarkerAlt
                                className="bg-green-temp h-100"/></div>
                            <p className="lh-1 text-secondary ps-2">{personalData?.address}</p></div>
                    </div>
                    <div className="bg-green-temp text-white w-100 p-2">
                        <div>
                            <span className=" main-title text-uppercase">{personalData?.firstName}</span>&nbsp;
                            <span className="main-title text-uppercase">{personalData?.lastName}</span>&nbsp;
                            <span className="main-title text-uppercase">{personalData?.otherName}</span>
                        </div>
                        <div>
                            <p>{personalData?.profession}</p>
                        </div>
                        <p className="text-white text-truncate"><a className="text-white"
                                                                   href={personalData?.socialLinks || ''}>{personalData?.socialLinks || ''}</a>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                {hasObjective && <div className="w-100 px-4">
                    <center className="main-title text-uppercase"><strong>ABOUT ME</strong></center>
                    {bar}
                    <p className="text-secondary lh-1 text-justify pt-2"
                       dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(personalData?.careerObjective)}}>
                    </p>
                </div>}
                <div className="d-flex justify-content-between px-4">
                    <div className="w-40 pe-3">
                        {hasEducation && <>
                            <div className="main-title text-uppercase"><strong>EDUCATION</strong></div>
                            {bar}
                            {
                                educationsData?.educations?.map((item, index) => {
                                    return (
                                        <div key={index} className="">
                                            <br/>
                                            <div><h6 className="text-uppercase"><strong>{item?.degree}</strong></h6>
                                            </div>
                                            <div className="text-uppercase fw-bolder">
                                                <strong>{item?.fieldOfStudy}</strong></div>
                                            <strong
                                                className="text-secondary">{item?.schoolName} &nbsp; {item?.startDate} - {item?.endDate || 'Till date'}
                                            </strong>
                                            <br/>

                                        </div>
                                    )
                                })
                            }
                        </>}
                        {hasSkills && <>
                            <div className="main-title text-uppercase"><strong>SKILLS</strong></div>
                            {bar}
                            {
                                skillsData?.skills?.map((item, index) => {
                                    return (
                                        <div key={index} className="">
                                            <br/>
                                            <div className="">
                                                <strong
                                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>

                                                </strong>
                                            </div>

                                            <br/>

                                        </div>
                                    )
                                })
                            }
                        </>}

                        {hasPublications && <>
                            <div className="main-title text-uppercase"><strong>publications</strong></div>
                            {bar}
                            <div className="">
                                <br/>
                                <div className="text-secondary">
                                    <strong
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(publicationsData?.publications[0])}}>

                                    </strong>
                                </div>

                                <br/>

                            </div>
                        </>}

                        {hasLanguages && <>
                            <div className="main-title text-uppercase"><strong>languages</strong></div>
                            {bar}
                            <div className="">
                                <br/>
                                <div className="text-secondary">
                                    <strong
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(languagesData?.languages[0])}}>

                                    </strong>
                                </div>

                                <br/>

                            </div>
                        </>}

                    </div>
                    <div
                        className="w-60 ps-3 border border-start-3 border-end-0 border-bottom-0 border-top-0 border-dark">
                        <div className="main-title"><strong>EXPERIENCE</strong></div>
                        {bar}
                        {
                            experienceData?.workExperience?.map((item, index) => {
                                return (
                                    <div key={index} className="position-relative">
                                        <div className="rounded-circle bg-black position-absolute top-50"
                                             style={{width: "8px", height: "8px", left: "-21px"}}>

                                        </div>

                                        <br/>
                                        <div><h6 className="text-uppercase"><strong>{item?.jobTitle}</strong></h6>
                                        </div>
                                        <strong className="text-secondary"><span
                                            className="text-uppercase">{item?.companyName}</span> &nbsp; | &nbsp; {item?.startMonth} {item?.startDate} - {item?.endMonth || 'Till date'} {item?.endDate}
                                        </strong>
                                        <p className="text-secondary text-justify lh-1 pt-1"
                                           dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item?.description)}}>

                                        </p>
                                    </div>
                                )
                            })
                        }
                        {
                            hasAchievements && <>
                                <br/>
                                <div className="main-title text-uppercase"><strong>achievements</strong></div>
                                {bar}
                                <div className="">
                                    <br/>
                                    <div className="text-secondary">
                                        <strong
                                            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(achievementsData?.achievements[0])}}>

                                        </strong>
                                    </div>

                                    <br/>

                                </div>

                            </>
                        }
                    </div>
                </div>
                {/*CV Footer*/}
                {/*<CVFooter/>*/}
                <style>
                    {
                        `
                        .w-cv{
  min-width: 600px;
}
.main-title{
  font-size: 20px;
}
.bg-green-temp{
  background: ${mainColor};
}
                        
                        `
                    }
                </style>
            </div>
        </>
    );
}

Font.register(
    {
        family: 'Montserrat',
        fonts: [
            {
                src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf'
            },
            {
                src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf',
                fontWeight: 'bold',
            },
            {
                src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf',
                fontWeight: 'normal',

            },
        ]
    },
);

export default HorizontalGreen
