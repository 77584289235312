export const Tips = {
    personalityTest: {
        driver: "Direct, Driven, Decisive, Strong-willed, Self-confident, Daring Determined, Fast paced Objective-focused, Know what they want and how to get there! Communicates quickly, gets to the point Sometimes tactless and brusque, Can be an quot;ends justify the means&quot; type of person, Hardworking, high energy, Does not shy away from conflict",
        analytical: 'Cautious, Systematic, Private, Objective, Analytical, Diplomatic, Accurate, Reserved Highly detail oriented, Can have a difficult time making decisions without ALL the facts, Make great accountants and engineers, Tend to be highly critical people, Can tend to be pessimistic in nature, Very perceptive',
        amiable: "Calm, Patient, Predictable, Stable, Warm, Passive, Loyal Kind-hearted people who avoid conflict, Can blend into any situation well, Can appear wishy-washy, Has difficulty with firm decisions, Often loves art, music and poetry, Highly sensitive, Can be quiet and soft-spoken",
        expressive: "Charming collaborative, energising, trusting, enthusiastic, impulsive, persuasive, optimistic Natural salesmen or story-tellers, Warm and enthusiastic, Good motivators, communicators, Can be competitive, Can tend to exaggerate, leave out facts and details, Sometimes would rather talk about things than do them!",
    },


    cultureFitTest: {
        adhocracy: 'Dynamic, Entrepreneurial, Risk-taking, Rapid change, Innovation, Creativity',
        clan: 'Teamwork, Participation, Mentoring, Loyalty, Informality',
        market: 'Result oriented, Get the job done, Competition and achievement, Productivity, Ability to deal with tough and demanding leaders',
        hierarchy: 'Ability to follow rules and policies, Structure, Control, Coordination, Efficiency, Stability'
    },
}

export const TestTips = {
      personalityTest: [
          {
              content: "Driver",
              tip: Tips?.personalityTest?.driver,
              qualifier: "Task oriented"
          },
          {
              content: "Analytical",
              tip: Tips?.personalityTest?.analytical,
              qualifier: "Detail oriented"
          },
          {
              content: "Amiable",
              tip: Tips?.personalityTest?.amiable,
              qualifier: "People oriented"
          },
          {
              content: "Expressive",
              tip: Tips?.personalityTest?.expressive,
              qualifier: ""
          },
      ],
      softSkillsTest: [
          {
              content: "Communication",
              qualifier: ""
          },
          {
              content: "Adaptability",
              qualifier: ""
          },
          {
              content: "Problem Solving",
              qualifier: ""
          },
          {
              content: "Relationship Building",
              qualifier: ""
          },

      ],
      cultureFitTest: [
          {
              content: "Adhocracy",
              tip: Tips?.cultureFitTest?.adhocracy,
              qualifier: "Innovative"
          },
          {
              content: "Clan",
              tip: Tips?.cultureFitTest?.clan,
              qualifier: "Employee-focused"
          },
          {
              content: "Market",
              tip: Tips?.cultureFitTest?.market,
              qualifier: "Aggressive Result-driven"
          },
          {
              content: "Hierarchy",
              tip: Tips?.cultureFitTest?.hierarchy,
              qualifier: "Stable"
          },
      ],
  }


