import {useCheckQuestionnaireStatusQuery} from "./userApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";

const useQuestionnaireStatus = () => {
    const {user} = useAppContext()
    const {
        data: questData,
      isLoading: dataIsLoading
    } = useCheckQuestionnaireStatusQuery(user?.data?.id)

    const questionnaireChecker = (quizTitle) => {
        if (quizTitle) {
            return questData?.data?.data.includes(quizTitle)
        } else {
            return questData?.data?.data.length
        }
    }


    return {
        questionnaireChecker,
        dataIsLoading
    }
}
export default useQuestionnaireStatus;