import React, {useEffect, useState} from 'react'
import {CAlert, CButton, CContainer, CForm, CLoadingButton} from '@coreui/react-pro'
import {Controller, useForm} from 'react-hook-form'
import RadioButton from '../../../components/buttons/RadioButton'
import ErrorReports from '../../../components/ErrorReports'
import {success_notify} from '../../../constant'
import useQuestionnaire from '../../../hooks/user/questionnaire'
import DisplayTimer from "./DisplayTimer";
import TestCompleteModalOnTimer from "../../../components/modals/TestCompleteModalOnTimer";
import DOMPurify from "dompurify";
import {useTimedAssessment} from "../../../hooks/user/assessmentApiHooks";

const TimedQuestionnaireFormTemp = ({
                                        data,
                                        title,
                                        caption,
                                        startTest,
                                        screenShots,
                                        webCam,
                                        totalTimeCal,
                                        changeTab,
                                        handlePageUnload

                                    }) => {

    const {handleSubmit, control, getValues} = useForm()
    const [testExpired, setTestExpired] = useState(null)
    const [startTimerCounter] = useState(totalTimeCal)
    const [visible, setVisible] = useState(false)
    const romanNumerals = ["i", "ii", "iii", "iv", "v", "vi"]

    const {
        totalSteps,
        step,
        disableNext,
        disablePrev,
        handleNext,
        questionnaireError,
        handlePrev,
        questionsChunk,
        submitAnswer,
    } = useQuestionnaire(data)

    const {
        mutate,
        isSuccess: isSuccessQuestionnaire,
        isError: isErrorQuestionnaire,
        error: errorQuestionnaire,
        isLoading: isLoadingQuestionnaire,
    } = useTimedAssessment()

    useEffect(() => {
        if (isSuccessQuestionnaire) {
            success_notify(`${title} submitted`)
            setVisible(true)
            handlePageUnload()
        }

    }, [isSuccessQuestionnaire])

    const checkTimeOut = (totalTime) => {
        setTestExpired(totalTime)
    }

    const handleQuestionnaire = (data) => {
        let answers

        if (testExpired === 0) {
            answers = Object.values(getValues())
        } else {
            answers = Object.values(data)
        }

        mutate({answers, quizTitle: `${title}`, webCam, screenShots, changeTab})
    }

    useEffect(() => {
        if (testExpired === 0) {
            handleQuestionnaire()
        }
    }, [testExpired])

    return (
        <CContainer>
            {
                visible &&
                <TestCompleteModalOnTimer
                    title={title}
                    setVisible={setVisible}
                    visible={visible}
                />
            }

            <CForm className="w-100" onSubmit={handleSubmit(handleQuestionnaire)}>
                <div
                    className="bg-white w-100 min-vh-100 border-radius-12 py-5 px-3 d-flex flex-column align-items-center position-relative box-shadow">

                    <p className="text-medium-emphasis front-one mb-0">{caption}</p>
                    <h3 className="text-capitalize text-black fw-bold front-one mb-3">{title}</h3>
                    <div className="position-relative ">

                        <DisplayTimer timeAllotted={startTimerCounter} onTimeOut={checkTimeOut} startTest={startTest}/>
                    </div>

                    {questionnaireError && <CAlert color="danger">{questionnaireError}</CAlert>}
                    {isErrorQuestionnaire && <ErrorReports error={errorQuestionnaire}/>}
                    {isLoadingQuestionnaire && <p> Submitting Questionnaire</p>}


                    {
                        questionsChunk?.map((question, index) => (
                            <div className="front-one mb-4 w-100 px-5" key={index}>
                                <div className="d-flex gap-2">
                                    {question.i}.
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question?.question)}}>

                                    </div>
                                </div>
                                <div className="ps-5">
                                    <Controller
                                        control={control}
                                        name={`question${question.i}`}
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange, onBlur, ref},
                                                 }) => (
                                            <>
                                                {
                                                    question?.selections &&
                                                    <div>
                                                        {
                                                            question?.selections.map((item, i) =>
                                                                <p key={i} className="pb-2">
                                                                    {
                                                                        `(${romanNumerals[i]}).  ${item}`
                                                                    }
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                }
                                                {
                                                    question.options.map((o, i) => (
                                                        <div key={i} className="d-flex gap-2 center-item">
                                                            <RadioButton
                                                                type="radio"
                                                                id={`exampleRadios${index}${i}`}
                                                                onBlur={onBlur} // notify when input is touched
                                                                onChange={(e) => {
                                                                    onChange(e.target.value)
                                                                    submitAnswer(question.id, o.id)
                                                                }} // send value to hook form
                                                                value={`${o.id}`}
                                                                checked={`${question.answer}` === `${o.id}`}
                                                                label=""
                                                                inputRef={ref}
                                                                autoFocus
                                                            />
                                                            <div
                                                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(o?.text)}}>

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        ))}

                    <CContainer className="d-flex gap-3 justify-content-between w-75 front-one">
                        <CButton
                            className="d-flex justify-content-around reg-btn  align-items-center"
                            onClick={handlePrev}
                            disabled={disablePrev}
                            type={'button'}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                                    fill="#3F3D3D"
                                />
                            </svg>
                            <p className="m-0 text-black">Previous</p>
                        </CButton>
                        {step < totalSteps && (
                            <CLoadingButton
                                className="d-flex justify-content-around reg-btn align-items-center"
                                onClick={handleNext}
                                disabled={disableNext}
                                type={'button'}
                            >
                                <p className="m-0">Next</p>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.59 16.58L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.58Z"
                                        fill="white"
                                    />
                                </svg>
                            </CLoadingButton>
                        )}
                        {step === totalSteps && (
                            <CLoadingButton
                                className="d-flex justify-content-around reg-btn align-items-center"
                                type={'submit'}
                                loading={isLoadingQuestionnaire}
                                disabled={isLoadingQuestionnaire}
                            >
                                <p className="m-0">Submit</p>
                            </CLoadingButton>
                        )}
                    </CContainer>
                </div>
            </CForm>
        </CContainer>
    )
}

export default TimedQuestionnaireFormTemp
