import React, {useEffect, useState} from 'react';

function DisplayTimer({timeAllotted, startTest, onTimeOut}) {

    const [totalTime, setTotalTime] = useState(timeAllotted)
    const [timeCounter, setTimeCounter] = useState(0)

    useEffect(() => {

        if (!startTest) {
            if (timeCounter > 0) {
                setTimeout(() => setTimeCounter(timeCounter - 1), 1000)
            }
            if (timeCounter === 0 && totalTime > 0 && !startTest) {
                setTimeCounter(60)
                setTotalTime(totalTime - 1)
            }

            if (timeCounter === 0 && totalTime === 0 && !startTest) {
                onTimeOut(totalTime)
            }
        }

    }, [timeCounter, startTest])

    return (
        <div className="d-flex flex-row -mt-3">
            <p className="border border-2 border-dark px-2 py-2 w-25 mx-4">
                {
                    totalTime < 10 ? `0${totalTime}` : totalTime

                }
            </p>
            <p className="mt-2">
                :
            </p>
            <p className="border border-2 border-dark  px-2 py-2 w-25 mx-4">
                {
                    timeCounter < 10 ? `0${timeCounter}` : timeCounter
                }
            </p>

        </div>
    );
}

export default DisplayTimer;