const assessments = [
    {
        name: "Personality Assessment",
        count: "personalityCounts",
        category: "personalityCategory",
        value: [
            {
                title: "Expressive",
                description: "Task Oriented",
                key: "expressive",
                color: "text-black"
            },
            {
                title: "Analytical",
                description: "Detail Oriented",
                key: "analytical",
                color: "text-danger"
            },
            {
                title: "Driver",
                description: "People Oriented",
                key: "driver",
                color: "text-warning"
            },
            {
                title: "Amiable",
                description: "",
                key: "amiable",
                color: "text-success"
            }]
    },
    {
        name: "Culture Fitness",
        count: "cultureCounts",
        category: "cultureCategory",
        value: [
            {
                title: "Adhocracy",
                description: "Innovative",
                key: "adhocracy",
                color: "text-black"
            },
            {
                title: "Clan",
                description: "Employee-focused",
                key: "clan",
                color: "text-danger"
            },
            {
                title: "Hierarchy",
                description: "Stable",
                key: "hierarchy",
                color: "text-warning"
            },
            {
                title: "Market",
                description: "Aggressive Result-driven",
                key: "market",
                color: "text-success"
            }]
    },
    {
        name: "Soft Skills",
        count: "softSkillsCounts",
        category: "softSkillsCategory",
        value: [
            {
                title: "Adaptability",
                description: "",
                key: "adaptability",
                color: "text-black"
            },
            {
                title: "Communication",
                description: "",
                key: "communication",
                color: "text-danger"
            },
            {
                title: "Problem Solving",
                description: "",
                key: "problemsolving",
                color: "text-warning"
            },
            {
                title: "RelationShip Building",
                description: "",
                key: "relationshipbuilding",
                color: "text-success"
            }]
    },
]

export default assessments;
