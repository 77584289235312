import React from 'react';
import {CNavLink} from "@coreui/react-pro";

function MandatorySkills({mandatorySkills}) {

    const quizTitles = ['Culture Fit', 'Personality Assessment', 'Soft Skills', "Level One Questionnaire", "Integrity Test"]
    return (
        <div className={"row d-flex flex-row m-auto"}>
            {
                mandatorySkills?.map((skill, index) => {
                    return (<div key={index} className={"col-5 shadow-sm-user  mx-auto  mb-3 padded-fix text-wn-12"}>
                        <div className={"py-4 text-center"}>
                            <p>{quizTitles[index] || skill.test}</p>
                        </div>
                        {
                            skill?.status ?
                                <div
                                    className={'bg-grey-700 text-black-50 text-center padded-fix px-5 text-wn-10 card-btm'}>
                                    Completed
                                </div>
                                :
                                <div className={'bg-green text-white text-center text-wn-10 padded-fix px-5 card-btm'}>
                                    <CNavLink
                                        href={`/user/questionnaire/${(skill?.test.charAt(0).toUpperCase() + skill?.test.slice(1))
                                            .replace(/\s/g, '-')}`}
                                        className={`text-white`}
                                    >
                                        Take Assessment
                                    </CNavLink>

                                </div>

                        }
                    </div>)

                })

            }
            <style>
                {
                    `
                    
                    .padded-fix{
                    padding:0px !important;
                    border-radius: 0 0 6px 6px !important;
                 }
                 
                 .card-btm{
                    height:28px !important;
                    padding:5px !important;
                    }
                    `
                }
            </style>
        </div>

    );
}

export default MandatorySkills;
