import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const verifyParkwayWalletSchema = Yup.object().shape({
    accountNumber: Yup.string().matches(phoneRegExp, 'Account number must be 10 digits')
        .required(
            ("Account number required")
        ).typeError('Only numbers allow')
        .test('len', 'Account number must be 10 digits', (val) => val.toString().length === 10),

    token: Yup.string().matches(phoneRegExp, 'Authorization Token must be 6 digits')
        .required(
            ("Authorization Token required")
        ).typeError('Only numbers allow')
        .test('len', 'Authorization Token must be 6 digits', (val) => val.toString().length === 6)
})
