import React from 'react';
import {CContainer} from "@coreui/react-pro";
import ReportHeader from "./SkillReports/ReportHeader";
import FilterCards from "./SkillReports/FilterCards";
import {useReportContext} from "../../../context/admin/ReportProvider";
import MainReport from "./SkillReports/MainReport";
import AssessmentAnalysisPage from "./SkillReports/AssessmentAnalysisPage";

function SkillsReport() {
    const {isSuccess, isAnalysisVisible} = useReportContext()
    return (
        <>
            {
                isSuccess && !isAnalysisVisible &&
                <CContainer className="mb-5">
                    <ReportHeader/>
                    <FilterCards/>
                    <MainReport/>
                </CContainer>
            }
            {
                isAnalysisVisible &&

                <AssessmentAnalysisPage/>
            }
        </>
    );
}

export default SkillsReport;