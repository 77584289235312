import React, {useEffect, useState} from 'react'
import {
    CContainer,
} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import {
    useGetEmployerByUUID,
    useGetEmployerIndustryTypes,
    useUpdateEmployerQuery
} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {fail_notify, success_notify} from "../../../constant";
import GoBack from "../../../components/GoBack";
import UpdateProfile from "../../../components/employer/update/UpdateProfile";
import UpdateDocument from "../../../components/employer/update/UpdateDocument";

const EmployerProfileUpdate = () => {

    const navigate = useNavigate()
    const {user} = useAppContext()
    const [activeTap, setActiveTap] = useState(1)

    const {
        isLoading: isEmployerDataLoading,
        isSuccess: isEmployerDataSuccess,
        data: employerData
    } = useGetEmployerByUUID(user?.data?.stakeholderUuid)

    const {isLoading: isIndustriesLoading} = useGetEmployerIndustryTypes()
    const {
        isLoading: isUpdateLoading,
        data,
        isSuccess,
        isError,
        error
    } = useUpdateEmployerQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            navigate(`/employer/profile`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, isSuccess])

    if (isIndustriesLoading || isUpdateLoading || isEmployerDataLoading) {
        return <ShowLoading/>
    }

    return (
        <>
            {isEmployerDataSuccess &&
                <CContainer className="user-profile-update">
                    <GoBack/>
                    <div className="shadow p-3 mb-5 bg-white rounded">
                        <h4 className="text-black fw-bold text-center">Profile / Document Update</h4>
                        <div className="py-2 px-4">
                            <div
                                className="d-flex gap-2 border border-top-0 border-start-0 border-end-0 flex-row justify-content-start align-items-center w-100">
                                <button onClick={() => {
                                    setActiveTap(1)
                                }}
                                        className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'}`}>
                                    <small>Update Company Data</small>
                                </button>
                                <button onClick={() => {
                                    setActiveTap(2)
                                }}
                                        className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${activeTap === 2 ? 'text-white bg-primary' : 'bg-grey'}`}>
                                    <small>Upload / Update Document</small>
                                </button>
                            </div>

                            {activeTap === 1 && <UpdateProfile employerData={employerData}/>}
                            {activeTap === 2 && <UpdateDocument employerData={employerData}/>}
                        </div>
                    </div>
                </CContainer>
            }
        </>
    )
}

export default EmployerProfileUpdate