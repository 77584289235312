import React from 'react';
import {CButton, CCol} from "@coreui/react-pro";
import ShowLoading from "../../../../components/ShowLoading";

function DeviceSetup({
                         displayPrev,
                         videoRef,
                         handleFirstCapture,
                         isContinue,
                         handleTestTaking,
                         isShareScreen,
                         isPermission,
                         settingUpDevice
                     }) {

    return (
        <>
            <div className={'d-flex flex-row gap-5'}>
                <CCol sm={6} className={'mx-auto'}>
                    <div className={'mb-3'}>
                        <div className={'mb-2'}>
                            <div>
                                <h5>
                                    Device Setup
                                </h5>
                                <p>

                                    To maintain fairness and the integrity of our procedures, we will be capturing both
                                    your
                                    screen and webcam during this session. Please make sure you are positioned in front
                                    of
                                    your camera.

                                </p>
                                <p>
                                    {
                                        ` Kindly click the "Allow Recording" button to proceed.`
                                    }
                                </p>
                            </div>

                            {
                                isShareScreen &&
                                <div className={'h-60 p-3 mt-3 bg-warning'}>

                                    This Test Requires You share your Entire Screen to proceed.

                                </div>

                            }
                            {
                                isPermission &&
                                <div className={' h-60 p-3 mt-3 bg-danger text-white'}>

                                    This Test Requires You to grant permission for us to Record your screen

                                </div>
                            }
                        </div>
                        {
                            settingUpDevice &&
                            <div className={'mt-3 justify-content-center'}>

                                <ShowLoading/>

                            </div>
                        }
                        {
                            displayPrev &&
                            <>
                                <video
                                    ref={videoRef}
                                    style={{width: '100%', height: 'auto'}}
                                >
                                </video>
                            </>
                        }
                    </div>
                    <div className={'mt-3 d-flex justify-content-center mx-auto '}>
                        {
                            !displayPrev &&
                            <CButton onClick={handleFirstCapture} className={'px-3 text-white'}>
                                Allow Recording
                            </CButton>
                        }

                    </div>
                </CCol>
                <CCol sm={6} className={'mx-auto'}>
                    <div className={'shadow-sm-user m-3 p-3 me-5'}>
                        <p className={'text-black'}>
                            Trouble Shooting Tips:
                        </p>
                        <ul className={'blue-list'}>
                            <li className={'mb-2'}>
                                Kindly verify that you are utilizing a web browser that is supported.
                            </li>
                            <li className={'mb-2'}>
                                Kindly Grant the Browser Permission to your Camera
                            </li>
                            <li className={'mb-2'}>
                                If you have multiple cameras please ensure that you are given the browser to the right
                                camera
                            </li>
                            <li className={'mb-2'}>
                                Ensure that your camera has the appropriate drivers
                            </li>
                            <li className={'mb-2'}>
                                Please Kindly Restart your computer and begin the test afresh if issues persist
                            </li>
                        </ul>
                    </div>
                </CCol>
            </div>

            {
                isContinue &&
                <div className={'mt-3 d-flex justify-content-center mx-auto '}>
                    <CButton onClick={handleTestTaking} className={'px-3 text-white'}>
                        Continue
                    </CButton>
                </div>
            }
            <style>
                {
                    `                            .blue-list li::marker {
                              color: #00B68A; 
                              font-size:19px;
                              font-weight:900
                            }

                        `
                }
            </style>
        </>
    );
}

export default DeviceSetup;
