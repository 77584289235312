import React, {useEffect} from 'react'
import {fail_notify} from '../../constant'
import {useAppContext} from '../../context/user/UserAppProvider'
import {useNavigate} from 'react-router-dom'
import {useSignUpEmployerLMS} from '../../hooks/employer/employerApiHooks'
import ShowLoading from '../../components/ShowLoading'
import {useSignUpUserLMS} from "../../hooks/user/userApiHooks";
import {decryptText} from "../../components/Utility";

function LMS() {
    const {
        isSuccess: employerIsSuccess,
        data: employerData,
        isError: employerIsError,
        error: employerError,
        mutate: employerMutate
    } = useSignUpEmployerLMS()
    const {
        isSuccess: userIsSuccess,
        data: userData,
        isError: userIsError,
        error: userError,
        mutate: userMutate
    } = useSignUpUserLMS()
    const navigate = useNavigate()
    const {user, storeUser, stakeholderRoles} = useAppContext()

    useEffect(() => {

        if (!user?.data?.moodle || user?.data?.moodle === null) {
            const data = {
                password: decryptText(user?.LMSID, user?.accessToken),
            }

            if (stakeholderRoles?.includes('Employer') || stakeholderRoles?.includes('Government') || stakeholderRoles?.includes('Academy')) {
                employerMutate(data)
                return
            }
            userMutate(data)
        }
    }, [])
    useEffect(() => {
        if (employerIsError || userIsError) {
            //console.log(error?.response?.data?.message)
            fail_notify('Error while to transiting to LMS, please try again')
            navigate(-1)
            return
        }

        if (employerIsSuccess || userIsSuccess || user?.data?.moodle) {
            let updatedObject = {...user, data: {...user.data, moodle: true}}
            storeUser(updatedObject)
            navigate('/user/lms/login')
        }
    }, [employerData, userData, employerIsSuccess, employerIsError, userIsError, employerError, userError, userIsSuccess])

    return (
        <>
            <ShowLoading/>
        </>
    )
}

export default LMS
