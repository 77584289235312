import React, {useEffect} from 'react'
import {CCol} from '@coreui/react-pro'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {fail_notify} from '../../constant'
import ShowLoading from '../../components/ShowLoading'
import {useEmailVerificationQuery} from '../../hooks/employer/employerApiHooks'

const ConfirmEmployer = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const {isError, isLoading, isSuccess, data, mutate, error} = useEmailVerificationQuery()

    const token = searchParams.get('token')
    const id = searchParams.get('id')

    useEffect(() => {
        mutate({token, id})
    }, [token, id, mutate])

    useEffect(() => {
        if (isSuccess) {
            navigate('/verification-successful', {replace: true})
        }
        if (isError) {
            fail_notify(error?.response?.data?.message || 'Invalid Email Verification Request')
        }
    }, [data, isError, error, isSuccess, navigate])

    if (isLoading) <ShowLoading/>

    return (
        <>
            <CCol md={8}>
                <h1 className="text-1">Email Verification</h1>
                <p className="mt-1 mb-5">Verifying email...</p>
            </CCol>
        </>
    )
}

export default ConfirmEmployer
