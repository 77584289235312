import React, {useEffect} from 'react';
import {CCard, CCardBody, CContainer,} from "@coreui/react-pro";
import {useGetAllQuestionnairesQuery, useGetAllSkillSetQuery} from "../../hooks/user/userApiHooks";
import ShowLoading from "../../components/ShowLoading";
import ErrorReports from "../../components/ErrorReports";
import SkillSetForm from "../../components/User/SkillSetForm";
import useSkillSetStatus from "../../hooks/user/skillSetStatusChecker";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";

function SkillSet() {
    const {isError, isLoading, error, data} = useGetAllQuestionnairesQuery()
    const {user} = useAppContext()
    const navigate = useNavigate()
    const {data:allSkills, isSuccess:isSkill} = useGetAllSkillSetQuery(user?.data?.id)
    const availableSkills = data?.data?.data?.filter((item) => {
       return ("Quality Assurance" !== item?.title && "PM" !== item?.title && "Personality Test" !== item?.title && "Culture Fit Test" !== item?.title && "Level One Questionnaire" !== item?.title && "Quality Assurance Test" !== item?.title)
    })
    const {hasSkillSetProfile} = useSkillSetStatus(allSkills)
    useEffect(() => {
      const isChosen = hasSkillSetProfile()
        //console.log(data?.data?.data)
        //console.log(allSkills)
        if (isChosen && allSkills.data?.data?.updated) {
            navigate(`/user/dashboard`, {
                replace: true,
            })
        }
    }, [isSkill])


    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer className="w-100 min-vh-100 d-flex flex-column align-items-center px-4 mb-5">
            {
                isError && <ErrorReports error={error}/>
            }
            <CCard className=" pt-4 pb-5 px-2 w-100 ">
                <div className="text-center">
                    <h3 className="text-capitalize mb-0 text-black">CHOOSE A CAREER PATH</h3>
                    <p className="text-medium-emphasis">
                        Kindly select at least 3 career paths and your level of proficiency for each
                    </p>
                </div>
                <CCardBody>
                    <SkillSetForm skill ={allSkills} availableSkills={availableSkills}/>
                </CCardBody>
            </CCard>

        </CContainer>
    );
}

export default SkillSet;
