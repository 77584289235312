import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ErrorReports from "../../components/ErrorReports";
import {CCol, CForm, CFormCheck, CFormFeedback, CFormInput, CFormText, CLoadingButton, CRow} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    useCreateEmployerRole,
    useGetAdminPermissionsQuery,
    useGetEmployerPermissions
} from "../../hooks/admin/adminApiHooks";
import {success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";
import * as Yup from "yup";
import ShowLoading from "../../components/ShowLoading";

export const createRoleSchema = Yup.object().shape({
    name: Yup.string().required('Role Name is required'),
    permissions: Yup.array().required('At least one permission is required').nullable()
})
const CreateRoleAndPermissions = () => {
    const navigate = useNavigate()
    const {header} = useAppContext()
    const [permissionsData, setPermissionsData] = useState(null)

    const {
        isLoading: isPermissionsLoading,
        isSuccess: isPermissionsSuccess,
        isError: isPermissionsError,
        error: permissionsError,
        data: permissions
    } = useGetEmployerPermissions()


    const {
        isLoading: isCreateAdminLoading,
        isSuccess: isCreateRoleSuccess,
        isError: isCreateAdminError,
        error: createAdminError,
        data: createRolesData,
        mutate
    } = useCreateEmployerRole()

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(createRoleSchema),
    })

    const handleSignUp = (data) => {

        mutate({data, header})
    }

    useEffect(() => {
        setPermissionsData(permissions?.data?.data)
    }, [permissions])

    if (isCreateRoleSuccess) {
        success_notify('Role successfully created')
        navigate('/employer/role-permissions')
    }

    if (isPermissionsLoading) {
        return <ShowLoading/>
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center bg-white w-70 mx-auto px-10">
            <h3 className="my-5">Create Role</h3>
            {
                (isPermissionsError) &&
                <ErrorReports error={permissionsError}/>
            }
            <CForm noValidate className="needs-validation  mb-5" onSubmit={handleSubmit(handleSignUp)}>
                <div className="d-flex flex-column gap-lg-4">
                    <CRow>
                        <CCol md={12}>
                            <CFormInput
                                type="text"
                                id="name"
                                placeholder="Role Name"
                                {...register('name')}
                                invalid={!!errors.name}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.name}>
                                {errors.name?.message}
                            </CFormFeedback>
                        </CCol>
                        <CRow className="py-1">
                            <CFormText className="text-primary text-3 ">Add Permissions</CFormText>
                            {permissionsData?.map((item, index) => {
                                return (
                                    <CCol md={4} key={index}>
                                        <CFormCheck
                                            id={`permission-${index}`}
                                            {...register('permissions')}
                                            invalid={!!errors.permissions}
                                            value={item?.name}
                                            label={item?.name}
                                        />
                                        <CFormFeedback invalid={!!errors.permissions}>
                                            {errors.permissions?.message}
                                        </CFormFeedback>
                                    </CCol>
                                )
                            })}
                        </CRow>
                    </CRow>
                    <div className="text-center">
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-50 py-2 text-center"
                            loading={isCreateAdminLoading}
                            // disabled={isCreateAdminError}
                        >
                            Create Role
                        </CLoadingButton>
                    </div>
                </div>
            </CForm>
        </div>
    )
}

export default CreateRoleAndPermissions