import {apiClient} from './applicant'
const getLevelOneQuestions = async () => {
    return await apiClient.get(`/quiz/quiz-questions?quizType=Level One Questionnaire`)
}

const getQuestionnaires = async (url) => {
    return await apiClient.get(`/quiz/quiz-questions?quizType=${url}`)
}

const getQuestionnairesByUUId = async (uuid) => {
    return await apiClient.get(`/quiz/question/${uuid}`)
}

const levelOneQuizAnswers = async (data) => {
    const {answers, quizTitle, header} = data
    const formData = new FormData();
    formData.append('quizTitle', quizTitle);
    answers.forEach((answer, index) => {
        formData.append(`answers[${index}]`, answer);
    });
    //console.log(formData)
    return await apiClient.post('quiz-answers', formData)
}
const timedAssessmentQuiz = async (data) => {

    function blobToFile(blob, fileName) {
        // Create a new File object from the Blob
        return new File([blob], fileName, { type: blob.type });
    }

    function base64ToBlob(base64String) {
        // Remove the data URI prefix if present
        const base64 = base64String.split(',')[1];

        // Convert the Base64 string to a Blob
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: 'image/png' }); // Adjust the type based on the image format
    }


    const formData = new FormData();

    formData.append('quizTitle', data?.quizTitle);
    formData.append('changeTab', data?.changeTab);

    // Append webcam image as files
    //formData.append(`webCam`, blobToFile(base64ToBlob(data?.webCam[0]), `webCam.png`));

    // Append answers as plain text
    data?.answers.forEach((answer, index) => {
        formData.append(`answers[${index}]`, answer);
    });

    // Check if screenShots is not null or undefined
    if (data?.screenShots) {
        // Append each screenshot as a separate file input
        data.screenShots.forEach((base64String, index) => {
            const blob = base64ToBlob(base64String);
            formData.append(`screenShots`, blobToFile(blob, `screenShots${index}.png`));
        });
    }
    // Check if webcam is not null or undefined
    if (data?.webCam) {
        // Append each webcam as a separate file input
        data.webCam.forEach((base64String, index) => {
            const blob = base64ToBlob(base64String);
            formData.append(`webCam`, blobToFile(blob, `webCam${index}.png`));
        });
    }
    //return;
    return await apiClient.post('quiz-answers', formData)
}

const getTotalTestTakers = async () => {
    return await apiClient.get(`quiz-answers/all/test-takers?page=1&limit=10`)
}

const getTotalTestsPassed = async () => {
    return await apiClient.get(`users/all/total-pass`)
}

const getTotalTestsFailed = async () => {
    return await apiClient.get(`users/all/total-failure`)
}

const updateQuestion = async (id, data) => {

    return await apiClient.put(`question/${id}`, data)
}

const getQuestionnaireStatus = async (id) => {
    return await apiClient.get(`/users/quiz-taken/${id}`)
}

const getQuestionnaireAnswer = async (userId, title) => {
    return await apiClient.get(`/quiz-answers/?quizType=${title}`)
}
const getUseQuestionnaireAnswerById = async (userId) => {
    return await apiClient.get(`/quiz-results/${userId}`)
}

const getUserAssessmentById = async (userId) => {
    return await apiClient.get(`/quiz-answers/candidate-metrics/${userId}`)
}

const getAllQuestionnaires = async () => {
    return await apiClient.get(`quiz/quiz-title`)
}

const skillSet = async (formData) => {
    const {skills, header} = formData
    return await apiClient.post('skill-sets', {skills: skills}, header?.header)
}

const getUserSkillSet = async (id) => {
    return await apiClient.get(`skill-sets/${id}`)
}

const getSkillSetReports = async (page, limit, quizType, from = "0", to = "100") => {
    let qType = quizType !== ' ' ? '&quizType=' + quizType : ''
    return await apiClient.get(`quiz-results?page=${page}&limit=${limit}${qType}&fromPercentage=${from}&toPercentage=${to}`)
}

const getPersonalityResult = async (from = 0, to = 100, category) => {
    let url = `quiz-results/personality-results?from=${from}&to=${to}`
    if (category?.length > 0) {
        url += `&category=${category}`
    }
    return await apiClient.get(url)
}

const getCultureFitResult = async (from = 0, to = 100, category) => {
    let url = `quiz-results/culture-fit-results?from=${from}&to=${to}`
    if (category?.length > 0) {
        url += `&category=${category}`
    }
    return await apiClient.get(url)
}

const getSoftSkillResult = async (from = 0, to = 100, category) => {
    let url = `quiz-results/soft-skills-results?from=${from}&to=${to}`
    if (category?.length > 0) {
        url += `&category=${category}`
    }
    return await apiClient.get(url)
}

const uploadUserCV = async (formData) => {
    return await apiClient.post('job-cv/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

const assessmentsApi = {
    getTotalTestTakers,
    updateQuestion,
    getLevelOneQuestions,
    getQuestionnaires,
    getQuestionnairesByUUId,
    levelOneQuizAnswers,
    getQuestionnaireStatus,
    getUserSkillSet,
    getQuestionnaireAnswer,
    getUseQuestionnaireAnswerById,
    getUserAssessmentById,
    getAllQuestionnaires,
    skillSet,
    getTotalTestsPassed,
    getTotalTestsFailed,
    getSkillSetReports,
    getPersonalityResult,
    getCultureFitResult,
    getSoftSkillResult,
    uploadUserCV,
    timedAssessmentQuiz
}

export default assessmentsApi
