import React from 'react'

import {
    CCard,
    CCardFooter,
    CCardImage,
    CCol, CImage,
    CRow
} from "@coreui/react-pro";
import SaveJob from "./SaveJob";

function JobCardHeader() {


    return (
        <CCol className="opacity-25">
            <CCard className="job-card" style={{maxWidth:"350px"}}>
                <CRow className="p-2">
                    <CCol md={4} className="d-flex flex-column justify-content-between align-items-center">
                        <div className="bg-primary bg-opacity-10 h-75 d-flex justify-content-center align-items-center w-100 img-div">
                            <CImage src="https://www.outsourceglobal.com/logo.png" alt="Logo" className="job-image"/>
                        </div>
                        <button className="btn disabled btn-primary btn-sm text-white w-100"><small>APPLY FOR JOB</small></button>
                    </CCol>
                    <CCol md={8} className="d-flex flex-column justify-content-between ">
                        <div className="d-flex flex-row justify-content-between align-items-center pb-4">
                            <div className="w-75">
                                <h6><b  className="text-truncate">Senior Software Developer</b></h6>
                                <p className="text-truncate text-start">OUTSOURCE GLOBAL</p>
                            </div>
                            <span className="disabled">
                                <SaveJob/>
                            </span>
                        </div>
                        <div>
                            <button className="btn btn-ghost-primary btn-outline-primary btn-sm text-primary p-1 disabled" style={{fontSize: '10px'}}>Senior (4-5) yr(s)</button>
                            <div className="d-flex flex-row justify-content-between align-items-center pt-1">
                                <p><b>Full-Time</b></p>
                                <h6>
                                    <small>₦50000</small>
                                </h6>
                            </div>
                            <p className="text-start">Date posted 18 minutes ago</p>
                        </div>
                    </CCol>
                </CRow>
            </CCard>
        </CCol>
    )
}

export default JobCardHeader
