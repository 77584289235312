import React from 'react';
import {CCol, CTooltip} from "@coreui/react-pro";


function GroupRadioButton({name, data, label, onChange, value}) {
    return (

        <CCol className="d-flex flex-row flex-wrap is-invalid ">
            {
                data?.map((item, index) => {
                    return (<div key={index}>
                            {item?.tip ? <CTooltip
                                content={item?.tip}
                                placement="top"
                            >
                                <div className="m-1">

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name={name}
                                        id={item?.title}
                                        value={item?.title}
                                        onChange={onChange}
                                    />
                                    <label className="btn btn-outline-success"
                                           htmlFor={item?.title}>{item?.title}</label>
                                </div>
                            </CTooltip>:
                                <div className="m-1">

                                    <input
                                        type="radio"
                                        className="btn-check"
                                        name={name}
                                        id={item?.title}
                                        value={item?.title}
                                        onChange={onChange}
                                    />
                                    <label className="btn btn-outline-success"
                                           htmlFor={item?.title}>{item?.title}</label>
                                </div>}
                        </div>
                        )
                })

            }


        </CCol>

    );
}

export default GroupRadioButton;
