import {
    CForm,
    CFormFeedback,
    CFormInput,
    CLoadingButton, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react-pro";
import React from "react";

const VerificationModal = ({
    verify,
    setVerify,
    verificationHandleSubmit,
    handleVerification,
    verificationRegister,
    verificationErrors,
    isLoadingEmail,
    isLoading
}) => {
  return (
      <CModal
          backdrop="static"
          visible={verify}
          onClose={() => {
              setVerify(false)
          }}
          aria-labelledby="LiveDemoExampleLabel"
      >
          <CModalHeader onClose={() => setVerify(false)}>
              <CModalTitle id="LiveDemoExampleLabel">Resend Verification Link</CModalTitle>
          </CModalHeader>
          <CModalBody>
              <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mt-1 mb-4 text-danger">Please refer to your email for a
                      verification mail and click on the confirm button to verify your
                      email or enter your email below and click on the &quot;Resend Verification&quot; button
                      to resend the verification mail to your email </p>

                  <p className="mt-1 mb-4 text-danger">Also ensure to check you spam mail if your don&apos;t see the verification mail in your inbox</p>
                  <CForm className="w-75" onSubmit={verificationHandleSubmit(handleVerification)}>
                      <CFormInput
                          type="text"
                          className="my-2 text-center"
                          placeholder="Enter Email"
                          {...verificationRegister('email')}
                          invalid={!!verificationErrors?.email}
                      />
                      <CFormFeedback invalid={!!verificationErrors?.email}>
                          {verificationErrors?.email?.message}
                      </CFormFeedback>
                      <div className="d-flex justify-content-center">
                          <CLoadingButton loading={isLoading} disabled={isLoading}
                                          color="primary"
                                          type="submit"
                                          className="text-white my-3 px-5"
                          >
                              {isLoadingEmail ? 'Resend Verification...' : 'Resend Verification'}
                          </CLoadingButton>
                      </div>
                  </CForm>
              </div>
          </CModalBody>

      </CModal>
  )
}

export default VerificationModal