import React from "react";
import PersonalDetails from "./PersonalDetails";

function InfoTitle({title, icon}) {


    return (
        <>
                <div className="d-flex px-4 py-2 justify-content-between align-content-center rounded shadow-sm-user">
                    <div className="d-flex">
                        <div>
                            <img src={icon}/>
                        </div>
                        <div>
                            <small className="fw-bold ps-2">
                                {title}
                            </small>
                        </div>
                    </div>
                    <div className="fw-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-chevron-down fw-bold" viewBox="0 0 16 16">
                            <path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                </div>

        </>
    )
}

export default InfoTitle
