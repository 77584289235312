import React from "react";
import {CCol, CTooltip} from "@coreui/react-pro";

const UserProfileTagV2 = ({tag, tips}) => {
    const splitTag = tag.split(":")

    return (
        <>
            {
                tips ?
                    <CTooltip content={tips} placement="top">
                        <CCol md={6} className="my-2">
                            <div className="bg-grey p-2">
                                <h5 className="m-0"><strong>{splitTag[0]}</strong></h5>
                                <h6 className="p-0 m-0 mt-4"><strong>{splitTag[1]}</strong></h6>
                                <small className="small-text-gray m-0 p-0">Aggregate</small>
                            </div>
                        </CCol>
                        {/*<div*/}
                        {/*    className="skills_box  user-tag cursor-pointer text-truncate  d-flex  flex-column justify-content-center align-items-center  text-primary py-2 px-1 "*/}
                        {/*>*/}
                        {/*    <small className="text-truncate px-1 ">{splitTag[0]}</small>*/}
                        {/*    <small className="text-truncate px-1 ">{splitTag[1]}</small>*/}
                        {/*</div>*/}
                    </CTooltip>
                    : <CCol md={6} className="my-2">
                        <div className="bg-grey p-2">
                            <h5 className="m-0"><strong>{splitTag[0]}</strong></h5>
                            <h6 className="p-0 m-0 mt-4"><strong>{splitTag[1]}</strong></h6>
                            <small className="small-text-gray m-0 p-0">Aggregate</small>
                        </div>
                    </CCol>
            }
        </>
    )
}

export default UserProfileTagV2
