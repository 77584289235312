import React, {useEffect, useState} from 'react';
import ScatteredGrayStyles from "./ScatteredGrayStyle";
import {hasText, isDisplay, parseAndFormatHTML} from "../../../services/cvbuilder/cvBuilderHelpers";
// import CVFooter from "../../../components/User/cv-builder/CVFooter";

const ScatteredGrayTemplate = ({
                                   personalData,
                                   educationsData,
                                   skillsData,
                                   publicationsData,
                                   languagesData,
                                   achievementsData,
                                   experienceData,
                                   mainColor
                               }) => {
    const hasSkills = hasText(skillsData?.skills);
    const hasPublications = hasText(publicationsData?.publications);
    const hasLanguages = hasText(languagesData?.languages);
    const hasObjective = hasText(personalData?.careerObjective);
    const hasAchievements = hasText(achievementsData?.achievements);
    const [hasEducation, setHasEducation] = useState(false);
    const [hasExperience, setHasExperience] = useState(false);

    useEffect(() => {
        if (experienceData?.workExperience?.length > 0) {
            setHasExperience(isDisplay(experienceData?.workExperience));
        }
        if (educationsData?.educations?.length > 0 && educationsData?.educations[0] !== undefined) {
            setHasEducation(true);
        }
    }, [experienceData, educationsData])

    const styles = ScatteredGrayStyles({mainColor});

    return <div className="mx-auto bg-white px-5">
        <div className="row">
            <div style={styles.topLeftBox}></div>
            <div className="d-flex flex-row justify-content-between">
                <div style={styles.leftLine}></div>
                <div className="d-flex flex-column justify-content-center align-items-start mt-3 ms-2">
                    <h1 style={styles.name}>{personalData?.firstName?.toUpperCase()}</h1>
                    <h1 style={styles.name}>{personalData?.lastName?.toUpperCase()}</h1>
                    <p>{personalData?.profession}</p>
                    <div className="pt-5">
                        <p className="ps-2">{personalData?.socialLinks}</p>
                    </div>
                </div>
                <div className="mt-5" style={styles.rightBox}></div>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row">
                    <div>
                        <h1 style={styles.contactTag}>E</h1>
                    </div>
                    <div className="ms-1">
                        <div className="pt-2">
                            <h6 style={styles.contactText}>Email</h6>
                            <p style={styles.contactText}>{personalData?.email}</p>
                        </div>
                    </div>
                </div>
                <div style={styles.emailBorder}></div>
                <div className="d-flex flex-row">
                    <div>
                        <h1 style={styles.contactTag}>P</h1>
                    </div>
                    <div className="ms-1">
                        <div className="pt-2">
                            <h6 style={styles.contactText}>Phone</h6>
                            <p style={styles.contactText}>{personalData?.phoneNumber}</p>
                        </div>
                    </div>
                </div>
                <div style={styles.phoneBorder}></div>
                <div className="d-flex flex-row">
                    <div>
                        <h1 style={styles.contactTag}>L</h1>
                    </div>
                    <div className="ms-1">
                        <div className="pt-2">
                            <h6 style={styles.contactText}>Location</h6>
                            <p style={styles.contactText}>{personalData?.address}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row justify-content-between">

                <div style={styles.leftPaneBorder}></div>

                <div className="m-3">
                    {hasSkills &&
                        <div className="mb-3">
                            <h5>Skills</h5>
                            <p>{parseAndFormatHTML(skillsData?.skills)}</p>
                        </div>
                    }
                    {hasLanguages &&
                        <div className="mb-3">
                            <h5>Languages</h5>
                            <p>{parseAndFormatHTML(languagesData?.languages)}</p>
                        </div>
                    }
                </div>

                <div className="m-3">
                    <div>
                        <div className="mb-4">
                            <h5>Career Objective</h5>
                            {hasObjective && <p style={styles.bodyText}>
                                {parseAndFormatHTML(personalData?.careerObjective)}
                            </p>}
                        </div>
                        {hasExperience && <div className="mb-4">
                            <h5>Experience</h5>
                            {
                                experienceData?.workExperience?.map((item, index) => {
                                    return <div key={index} className="pb-2">
                                        <h6>{item.jobTitle}</h6>
                                        <p style={styles.bodyText}>
                                            {`${item?.companyName}  | ${item?.startMonth} ${item?.startDate
                                            } - ${item?.currentlyWorking ? " Till Date" : item?.endMonth}`}

                                        </p>
                                        <p className="small-text-gray">
                                            {parseAndFormatHTML(item?.description)}
                                        </p>
                                    </div>
                                })
                            }
                        </div>}

                        {
                            hasEducation &&
                            <div className="mb-4">
                                <h5>Education</h5>
                                {
                                    educationsData?.educations?.map((edu, index) => {
                                        return <div key={index}>
                                            <h6>{edu.schoolName}</h6>
                                            <p className="text-small">
                                                {edu.degree} {edu.fieldOfStudy}
                                            </p>
                                            <p>
                                                {
                                                    parseAndFormatHTML(edu?.description)
                                                }
                                            </p>
                                        </div>
                                    })
                                }
                            </div>

                        }

                        {
                            hasAchievements &&
                            <div className="mb-4">
                                <h5>Achievements</h5>
                                {
                                    <p>
                                        {parseAndFormatHTML(achievementsData?.achievements)}
                                    </p>
                                }
                            </div>}
                        {
                            hasPublications &&
                            <div className="mb-4">
                                <h5>Publications</h5>
                                {
                                    <p>
                                        {parseAndFormatHTML(publicationsData?.publications)}
                                    </p>}
                            </div>
                        }

                    </div>
                </div>

            </div>
        </div>
        {/*CV Footer*/}
        {/*<CVFooter/>*/}
    </div>
}

export default ScatteredGrayTemplate
