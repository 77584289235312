import React from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import NigMap from "../../assets/images/Nigmap.png";
import {RiMapPin2Fill} from "react-icons/ri";
import {MdArrowForwardIos} from "react-icons/md";

const TrainingLocations = () => {
    const locations = [
        {
            locacation: "Abuja",
            address: "Address: 2nd Floor, ASTA GALLERY Plot 1185, Parkway Road, Cadastral Zone, Mabushi District, Abuja FCT, Nigeria",
        },
        {
            locacation: "Gombe",
            address: "Address: Amina j. Muhammed skills Acquisition Centre Gombe bypass Gombe state.",
        },
        {
            locacation: "Kaduna",
            address: "Address: 47, Kanta Road, Unguwar Rimi, Kaduna State.",
        },
        {
            locacation: "Online",
            description: "Experience the flexibility of online training with Worknation. Access our courses and training programs from the comfort of your home or workplace. Engage in virtual learning sessions and interactive modules tailored to your schedule."
        }
    ]
    return <div className="bg-white">
        <CContainer className="px-md-5 bg-white">
            <br/>
            <div className="text-justify">
                <h1 className="text-primary-dark fw-bold">Our Training <span className="text-primary">Location</span>
                </h1>
                <div style={{maxWidth: "900px"}}>
                    <p className="text-justify">
                        Join us at any of these locations to enhance your skills and prepare for a successful career
                        journey. Whether you prefer in-person training, or the convenience of online learning,
                        Worknation
                        has a solution for you.
                    </p>
                </div>
                <br/>
                <div className="d-flex gap-4 justify-content-between flex-wrap flex-lg-nowrap align-items-center">
                    <div className="w-100 bg-danger" style={{height: "500px"}}>
                        <img src={NigMap} alt="Profile" className="image-cover w-100 h-100"/>
                    </div>
                    <div className="d-flex  w-100 justify-content-md-between flex-wrap">
                        <CRow xs={{cols: 1}} sm={{cols: 1}} lg={{cols: 1}}>

                            {locations?.map((item, index) => {
                                return <CCol key={index} className="pb-4">
                                    <div className="d-flex w-100 justify-content-start center-item">
                                        <RiMapPin2Fill size={20} className="text-danger"/>
                                        <h4 className={`text-primary-dark fw-bold pt-2 ps-1`}>{item?.locacation}</h4>
                                    </div>
                                    <div>
                                        <p style={{lineHeight: 1.0}}><small
                                            className="text-black-50 text-justify">{item?.description}</small></p>
                                    </div>

                                    {item?.address ?
                                        <div className={`d-flex align-items-center gap-2 border-2 border-bottom ${item?.description ? "pt-3" : ""} pb-3`}>
                                                <div
                                                    className="bg-primary rounded d-flex justify-content-center align-items-center"
                                                    style={{minHeight: "65px", minWidth: "30px"}}>
                                                    <MdArrowForwardIos size={18} className="text-white"/>
                                                </div>
                                                <div className="">
                                                    <p style={{lineHeight: 1.0}}><small
                                                        className="text-black fw-medium">  {item?.address}</small></p>
                                                </div>
                                        </div> :
                                        <div className={`border-2 border-bottom pb-3`}>

                                        </div>


                                    }

                                </CCol>
                            })}

                        </CRow>

                    </div>
                </div>

            </div>
            <br/> <br/>
        </CContainer>
    </div>
}

    export default TrainingLocations;
