import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ErrorReports from "../../components/ErrorReports";
import {CCol, CForm, CFormCheck, CFormFeedback, CFormInput, CFormText, CLoadingButton, CRow} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CreateAdminSchema} from "../../form-schema/admin-form-schema/createAdminSchema";
import {useCreateAdminQuery, useGetAdminPermissionsQuery, useGetAdminRolesQuery} from "../../hooks/admin/adminApiHooks";
import {success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";
import ShowLoading from "../../components/ShowLoading";

const CreateAdmin = () => {
  const navigate = useNavigate()
  const {header} = useAppContext()
  const [rolesData, setRolesData] = useState(null)
  const [permissionsData, setPermissionsData] = useState(null)

  const {
    isLoading: isRolesLoading,
    isSuccess: isRolesSuccess,
    isError: isRolesError,
    error: rolesError,
    data: roles
  } = useGetAdminRolesQuery()

  const {
    isLoading: isPermissionsLoading,
    isSuccess: isPermissionsSuccess,
    isError: isPermissionsError,
    error: permissionsError,
    data: permissions
  } = useGetAdminPermissionsQuery()

  const {
    isLoading: isCreateAdminLoading,
    isSuccess: isCreateAdminSuccess,
    isError: isCreateAdminError,
    error: createAdminError,
    data: createAdminData,
    mutate
  } = useCreateAdminQuery()

  const {
    register,
    handleSubmit,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(CreateAdminSchema),
  })

  const handleSignUp = (data) => {
    mutate({data, header})
  }

  useEffect(() => {
    setRolesData(roles?.data?.data)
    setPermissionsData(permissions?.data?.data)
  }, [roles, permissions])

  if (isCreateAdminSuccess) {
    success_notify('Admin successfully created')
    navigate('/admin/admins', {
      replace: true,
      state: {
        email: createAdminData?.data?.data?.email,
      },
    })
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h3 className="my-5">Create Admin</h3>
      {
        (isRolesError) &&
        <ErrorReports error={rolesError}/>
      }
      {
        (isPermissionsError) &&
        <ErrorReports error={permissionsError}/>
      }
      {
        isCreateAdminError &&
        <ErrorReports error={createAdminError}/>
      }
      {((isRolesLoading || isPermissionsLoading) || (!isRolesSuccess && !isPermissionsSuccess)) ?
        <ShowLoading/> :
        <CForm noValidate className="needs-validation w-50" onSubmit={handleSubmit(handleSignUp)}>
          <div className="d-flex flex-column gap-lg-4">
            <CRow>
              <CCol md={6}>
                <CFormInput
                  type="text"
                  id="firstName"
                  placeholder="Enter First Name"
                  {...register('firstName')}
                  invalid={!!errors.firstName}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.firstName}>
                  {errors.firstName?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={6}>
                <CFormInput
                  type="text"
                  id="lastName"
                  placeholder="Enter Last Name"
                  {...register('lastName')}
                  invalid={!!errors.lastName}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.lastName}>
                  {errors.lastName?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={6}>
                <CFormInput
                  type="text"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  {...register('phoneNumber')}
                  invalid={!!errors.phoneNumber}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.phoneNumber}>
                  {errors.phoneNumber?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={6}>
                <CFormInput
                  type="text"
                  id="email"
                  placeholder="Enter Email Address"
                  {...register('email')}
                  invalid={!!errors.email}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.email}>
                  {errors.email?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={6}>
                <CFormInput
                  type="password"
                  id="password"
                  {...register('password')}
                  placeholder="Enter Your Password"
                  invalid={!!errors.password}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.password}>
                  {errors.password?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={6}>
                <CFormInput
                  type="password"
                  id="confirmPassword"
                  {...register('confirmPassword')}
                  placeholder="Confirm Password"
                  invalid={!!errors.confirmPassword}
                  className="mb-2"
                />
                <CFormFeedback invalid={!!errors.confirmPassword}>
                  {errors.confirmPassword?.message}
                </CFormFeedback>
              </CCol>
              <CRow className="py-1">
                <CFormText className="text-black-50">Select Roles</CFormText>
                {rolesData?.map((item, index) => {
                  return (
                    <CCol md={6} key={index}>
                      <CFormCheck
                        id={`role-${index}`}
                        {...register('role')}
                        invalid={!!errors.role}
                        value={item?.title}
                        label={item?.title}
                      />
                      <CFormFeedback invalid={!!errors.role}>
                        {errors.role?.message}
                      </CFormFeedback>
                    </CCol>
                  )
                })
                }
              </CRow>
              <CRow className="py-1">
                <CFormText className="text-black-50">Add Permissions</CFormText>
                {permissionsData?.map((item, index) => {
                  return (
                    <CCol md={6} key={index}>
                      <CFormCheck
                        id={`permission-${index}`}
                        {...register('permissions')}
                        invalid={!!errors.permissions}
                        value={item?.title}
                        label={item?.title}
                      />
                      <CFormFeedback invalid={!!errors.permissions}>
                        {errors.permissions?.message}
                      </CFormFeedback>
                    </CCol>
                  )
                })}
              </CRow>
            </CRow>
            <div className="text-center">
              <CLoadingButton
                type="submit"
                className="reg-btn px-4 w-50 py-2 text-center"
                loading={isCreateAdminLoading}
                // disabled={isCreateAdminError}
              >
                Create Admin
              </CLoadingButton>
            </div>
          </div>
        </CForm>
      }
    </div>
  )
}

export default CreateAdmin