import * as Yup from 'yup'
// import { employmentOptions, genderOptions, idTypeOptions } from '../constant'
import { employmentOptions, idTypeOptions } from '../../constant'
export const RegistrationSchema = Yup.object().shape({
  gender: Yup.string('Gender must be a string'),
  educationLevel: Yup.string().required('Educational Qualification is required'),
  // not prefilled
  employmentStatus: Yup.string('Employment status must be a string')
    .oneOf(employmentOptions)
    .required('Employment status is required'),
  occupation: Yup.string().required('Occupation is required'),
  nin: Yup.string().required('Identity number is required').min(11),
  meansOfIdentification: Yup.string('ID Type must be a string')
    .oneOf(idTypeOptions)
    .required('ID Type is required'),

})
