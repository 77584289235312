import React, {useEffect, useRef, useState} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import ShowLoading from "../../../components/ShowLoading";
import {useNavigate, useParams} from "react-router-dom";
import {ImPhone} from "react-icons/im";
import {BiArrowBack, BiEnvelope} from "react-icons/bi";
import profile from "../../../assets/icons/profile.svg";
import {useGetAdminByUuid} from "../../../hooks/admin/adminApiHooks";
import adminPreloadedData from "../../../constant/adminPreloadedData";

const ViewAdminProfile = () => {

    const navigate = useNavigate()
    const {uuid} = useParams()

    const {isLoading, isSuccess, data} = useGetAdminByUuid(uuid)
    const [adminData, setAdminData] = useState("")
    const [activeTap, setActiveTap] = useState(1)
    const profilePic = useRef(profile)

    useEffect(() => {
        if (isSuccess) {
            setAdminData(adminPreloadedData(data?.data?.data))
        }
        profilePic.current = data?.data?.data?.user?.profile?.passportUrl
    }, [isSuccess, data, isLoading])

    if (isLoading) {
        return <ShowLoading/>
    }

    return <CContainer className="user-profile">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h6><span className="cursor-pointer" onClick={()=>{navigate(-1)}}><BiArrowBack/>Back</span></h6>
        </div>
        {isSuccess &&
            <CRow>
                <CCol lg={4} className="shadow p-3 mb-5 bg-white rounded h-75">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex flex-row justify-content-between align-items-center my-3">
                            <h6 className="fw-bold">{`${adminData?.firstName+' '+adminData?.lastName}`}</h6>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex w-100 flex-column">
                            <p className="text-truncate">
                                <ImPhone size={14}/>
                                <span className="ps-1 text-truncate">
                                    {adminData?.phoneNumber || 'Nil'}
                                </span>
                            </p>
                        </div>
                        <div className="d-flex w-50 flex-column">
                            <p className="text-truncate w-100 d-flex">
                                <BiEnvelope size={16}/>
                                <span className="ps-1 text-truncate w-100">
                                    {`${adminData?.email || 'Nil'}`}
                                </span>
                            </p>
                        </div>
                    </div>
                </CCol>
                <CCol lg={8} className="mb-5">
                    <div className="h-100 d-flex flex-column">
                        <div className="pb-1 flex-wrap d-flex justify-content-between">
                            <button onClick={() => {
                                setActiveTap(1)
                            }}
                                    className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'}`}>
                                <small>Basic Information</small>
                            </button>
                        </div>
                        <CCol className="shadow p-3 h-100 bg-white rounded">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="fw-bold">Basic Information</h6>
                            </div>
                            <hr/>
                            <CRow className="my-4">
                                <CCol md={4}>
                                    <h6 className="fw-bold">Roles</h6>
                                    <p>{adminData?.adminRole?.map((role, index) => {
                                        return <span key={index} className="me-2">{role}</span>
                                    }) || 'Nil'}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Permissions</h6>
                                    <p>{adminData?.permissions?.map((permission, index) => {
                                        return <span key={index} className="me-2">{permission}</span>
                                    }) || 'Nil'}</p>
                                </CCol>
                            </CRow>
                        </CCol>
                    </div>
                </CCol>
            </CRow>
        }
    </CContainer>
}

export default ViewAdminProfile;