import React from 'react';
import {HiOutlinePencilAlt} from "react-icons/hi";
import {RiDeleteBin6Line} from "react-icons/ri";
import {parseAndFormatHTML} from "../../../services/cvbuilder/cvBuilderHelpers";

function WorkExperienceCard({setActiveExperience, experienceId, experience, deletedExperience}) {

    const description = parseAndFormatHTML(experience.description)
    return (
        <>
            <div
                className="d-flex px-4 py-2 justify-content-between align-content-center rounded shadow-sm-user px-10 mt-4">
                <div className="d-flex">
                    <div>
                        <small className="fw-bold">
                            {
                                experience.companyName
                            }
                        </small>
                        <p>
                            {description?.slice(0, 200)}
                        </p>
                    </div>
                </div>
                <div className={"d-flex gap-3"}>
                    <div className="fw-bold" onClick={() => setActiveExperience(experienceId)}>
                        <HiOutlinePencilAlt size={16} color={'currentColor'}/>
                    </div>
                    <div className="fw-bold" onClick={() => deletedExperience(experienceId)}>
                        <RiDeleteBin6Line size={16} color={'currentColor'}/>
                    </div>
                </div>

            </div>
        </>
    );
}

export default WorkExperienceCard;

