import {
    CButton,
    CForm,
    CFormFeedback,
    CFormInput, CFormLabel,
    CLoadingButton, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react-pro";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {VerifyNinSchema} from "../../form-schema/user-form-schema/verifyNinSchema";

const AccountRecoveryModal = ({
                                  recovery,
                                  setRecovery
                              }) => {
    const [isValid, setIsValid] = useState(false)
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(VerifyNinSchema),
    })

    const handleRecovery = (data) => {
        //console.log(data)
        setIsValid(true)
    }

    return (
        <CModal
            backdrop="static"
            visible={recovery}
            onClose={() => {
                setRecovery(false)
            }}
        >
            <CModalHeader onClose={() => setRecovery(false)}>
                <CModalTitle id="">Recover Your Account</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {isValid ?
                    <div>
                        <p className="my-3">
                            We will send a login code to your email:
                            <br/>
                            <strong>j***@****</strong>
                        </p>
                        <CButton className="text-white mt-4 mb-2 px-5">Continue</CButton>
                    </div>
                    :
                    <div className="d-flex flex-column justify-content-center">
                        <p className="mt-1 mb-4">Ensure you provide a valid NIN for your account recovery.</p>
                        <CForm className="w-100" onSubmit={handleSubmit(handleRecovery)}>
                            <CFormLabel>NIN</CFormLabel>
                            <CFormInput
                                type="text"
                                className="my-2"
                                placeholder="Enter NIN"
                                {...register('nin')}
                                invalid={!!errors?.nin}
                            />
                            <CFormFeedback invalid={!!errors?.nin}>
                                {errors?.nin?.message}
                            </CFormFeedback>
                            <div className="d-flex">
                                <CLoadingButton
                                    // loading={isLoadingEmail}
                                    // disabled={isLoading}
                                    color="primary"
                                    type="submit"
                                    className="text-white my-3 px-5"
                                >
                                    Submit NIN
                                </CLoadingButton>
                            </div>
                        </CForm>
                    </div>
                }
            </CModalBody>

        </CModal>
    )
}

export default AccountRecoveryModal
