import React, {useEffect, useState} from 'react';
import {CCol} from "@coreui/react-pro";
import SkillSetCard from "./SkillSetCard";
import CompleteYourTestNotification from "./CompleteYourTestNotification";
import NotificationModal from "../../modals/NotificationModal";

function SkillSets({skillSets, isMandatoryTestTaken}) {
    const [allAnswered, setAllAnswered] = useState(true)
    const [unAnsweredTest, setUnAnsweredTest] = useState([])
    const [unAnsweredSkills, setUnAnsweredSkills] = useState([])
    const newSkillSets = skillSets?.filter(skillSet => {
        return (skillSet?.title !== "Project Manager" && skillSet?.title !== "Management Analysis")
    });

    useEffect(() => {
        setUnAnsweredSkills([...new Set(unAnsweredTest)])
    }, [unAnsweredTest])

    return (
        <>

            {
                !allAnswered &&
                <CompleteYourTestNotification unAnsweredSkills={unAnsweredSkills} setAllAnswered={setAllAnswered}/>
            }
            {
                newSkillSets?.map((skill, id) => {
                    return (
                        <CCol key={id}>
                            <SkillSetCard
                                skill={skill}
                                isMandatoryTestTaken={isMandatoryTestTaken}
                                setAllAnswered={setAllAnswered}
                                setUnAnsweredTest={setUnAnsweredTest}
                            />
                        </CCol>
                    )
                })
            }
            <NotificationModal/>
        </>
    );
}

export default SkillSets;
