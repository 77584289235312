import React from 'react';
import {BiRightArrowAlt} from "react-icons/bi";

import BusinessAnalysis from '../../assets/images/business-analysis.png';
import CustomerServiceRep from '../../assets/images/customer-service-rep.png';
import Accounting from '../../assets/images/accounting.png';

import FullStack from '../../assets/images/full-stack.png';
import DataAnalysis from '../../assets/images/data-analysis.png';
import PetroleumEngineering from '../../assets/images/petroleum-engineering.png';
import Construction from '../../assets/images/construction.png';
import CustomerService from '../../assets/images/customer-service.png';
import Aviation from '../../assets/images/aviation.png';

import HumanResources from '../../assets/images/human-resources.png';
import Marketing from '../../assets/images/marketing.png';
import SalesManagement from '../../assets/images/sales-management.png';
import FeaturedJobsRow from "./FeaturedJobsRow";

const FeaturedJobs = () => {

    return <div className="px-5 ">
        <div className="p-5 featured-jobs-sm">
            <div className="d-flex flex-lg-row justify-content-between align-items-center flex-xs-column">
                <h1><strong>Featured <span className="text-primary">Jobs</span></strong></h1>
                <a href="/jobs" className="text-primary">Find Your Dream Jobs <BiRightArrowAlt/></a>
            </div>
            <FeaturedJobsRow details={[
                {image: BusinessAnalysis, job: 'Business Analysis'},
                {image: CustomerServiceRep, job: 'Customer Service Representative'},
                {image: FullStack, job: 'Software Engineering'},
                // {image: Engineering, job: 'Engineering'},
                {image: Accounting, job: 'Accounting'}
            ]}/>

            <div className="d-xs-none p-0 m-0">
                <FeaturedJobsRow details={[
                    // {image: FullStack, job: 'Full Stack Development'},
                    {image: HumanResources, job: 'Human Resources'},
                    {image: DataAnalysis, job: 'Data Analysis'},
                    {image: PetroleumEngineering, job: 'Petroleum Engineering'},
                    {image: Aviation, job: 'Aviation'}
                    // {image: Management, job: 'Management'}
                ]}/>

                <FeaturedJobsRow details={[
                    // {image: Administrative, job: 'Administrative Assistant'},
                    {image: Construction, job: 'Construction'},
                    {image: CustomerService, job: 'Customer Service'},
                    // {image: Aviation, job: 'Aviation'}
                    {image: Marketing, job: 'Marketing'},
                    {image: SalesManagement, job: 'Sales Management'},

                ]}/>

                {/*<FeaturedJobsRow details={[*/}
                {/*    {image: HumanResources, job: 'HumanResources'},*/}
                {/*    {image: Marketing, job: 'Marketing'},*/}

                {/*    {image: MechanicalEngineering, job: 'Mechanical Engineering'}*/}
                {/*]}/>*/}
            </div>

        </div>
    </div>
}

export default FeaturedJobs;