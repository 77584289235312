import React, {useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import Education from "../../../assets/icons/Education.svg";
import InfoTitle from "./InfoTitle";
import {fail_notify} from "../../../constant";
import {CButton, CCol, CForm, CLoadingButton, CRow} from "@coreui/react-pro";
import SkillsCard from "./SkillsCard";
import CVTextEditor from "../CVTextEditor";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const Skills = () => {

    const {status, setStatus, setSkillsData, cvData} = useCVBuilderContext()

    const [skills, setSkills] = useState(cvData?.data?.data?.data[0]?.skills || []);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();
    const [editorState, setEditorState] = useState(skills?.join(' ') ?? '');
    const [hasSkills, setHasSkills] = useState(false);
    const {
        handleSubmit,
        setValue,
        control,
        getValues,

    } = useForm({
        defaultValues: {
            skills: []
        }

    });

    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        setSkillsData(watchAllFields);
    }, [watchAllFields])

    useEffect(() => {
        setValue("skills", [editorState]);
    }, [editorState])

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0].certificates = getValues().skills;
            setValue("skills", [editorState]);

            if (editorState.length > 0) {
                setHasSkills(false);
            } else {
                setHasSkills(true);
            }
        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (!editorState) {
            setHasSkills(true);
        }
    }, [])

    const onSubmit = (data) => {
        const formattedData = parseAndFormatHTML(data?.skills);
        if (formattedData) {
            mutate(data);
        } else {
            setStatus(6);
        }

    }

    const deleteSkills = () => {
        setEditorState([]);
        mutate({skills: []});
    }

    return (<>
        {
            status === 5 &&
            <>
                <InfoTitle title={"Skills"} icon={Education}/>
                {
                    !hasSkills &&

                    <>
                        <SkillsCard
                            hasSkills={hasSkills}
                            setHasSkills={setHasSkills}
                            deleteSkills={deleteSkills}
                            skills={editorState}
                        />
                        <center
                            className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-5">
                            <CButton
                                onClick={() => {
                                    setStatus(4)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Back
                            </CButton>
                            <CLoadingButton
                                onClick={() => {
                                    setStatus(6)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Next
                            </CLoadingButton>
                        </center>
                    </>
                }
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    {
                        hasSkills &&
                        <>
                            <div className="d-flex">
                                <div className="w-100">
                                    <CRow className="g-3 py-2">
                                        <CCol>
                                            <div>
                                                <CVTextEditor
                                                    editorState={editorState}
                                                    setEditorState={setEditorState}
                                                />
                                            </div>
                                        </CCol>

                                    </CRow>
                                </div>
                            </div>
                            <center
                                className="d-flex flex-column flex-sm-row align-items-center justify-content-center ">
                                <CButton
                                    onClick={() => {
                                        setHasSkills(!hasSkills)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Back
                                </CButton>
                                <CLoadingButton loading={isLoading} disabled={isLoading} type="submit"
                                                className="py-3 cv-btn m-1 text-white">
                                    SAVE
                                </CLoadingButton>
                            </center>
                        </>
                    }
                </CForm>

            </>
        }
    </>)
}
export default Skills

function parseAndFormatHTML(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const formattedText = Array.from(tempDiv.children).map(child => child.textContent.trim() + "\n").join(' ');
    return formattedText;
}
