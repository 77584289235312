import React from 'react';
import {CButton, CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import EmployerImage from '../../assets/images/employer-image.svg';
import GreenCheck from '../../assets/icons/green-check.svg';
import {Link} from "react-router-dom";

const EmployersSection = () => {
    return <CContainer className="p-5" id="employers">
        <CRow>
            <CCol md={5}>
                <CImage src={EmployerImage} alt="Employer" className="w-100"/>
            </CCol>
            <CCol md={1}></CCol>
            <CCol md={6}>
                <h1 className="text-primary-dark py-sm-3"><strong>Employers</strong></h1>
                <p>Secure your top choice from a pool of skilled candidates you&apos;ve been searching for. Discover the
                    ideal candidate</p>
                <div className="d-flex flex-row justify-content-start align-items-start my-4 py-3 landing-section-border">
                    <CImage src={GreenCheck} alt="Check" className="green-check-icon"/>
                    <div className="ms-4">
                        <h6 className="text-primary-dark"><strong>Create Your Account</strong></h6>
                        <p>Create an account and post your job to help you find dream talent.</p>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-start my-4 py-3 landing-section-border">
                    <CImage src={GreenCheck} alt="Check" className="green-check-icon"/>
                    <div className="ms-4">
                        <h6 className="text-primary-dark"><strong>Talent Funnel Management</strong></h6>
                        <p>Find, connect with, and manage talents you want to hire now or in the future.</p>
                    </div>
                </div>
                <Link to="/employer/signup">
                    <CButton className="btn btn-primary py-3 px-5 text-white w-auto smaller-text-12 mt-3">GET STARTED</CButton>
                </Link>
            </CCol>
        </CRow>
    </CContainer>
}

export default EmployersSection;