import {
    CForm,
    CFormFeedback,
    CFormLabel, CFormSelect,
    CLoadingButton, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react-pro";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAddTraineeToCohort, useGetAllCohorts} from "../../hooks/admin/adminApiHooks";
import ShowLoading from "../ShowLoading";
import {fail_notify, success_notify} from "../../constant";
import {AddTraineeToCohortSchema} from "../../form-schema/admin-form-schema/addTraineeToCohortSchema";
import {useAppContext} from "../../context/user/UserAppProvider";

const TraineeToCohortModal = ({visible, setVisible, applicantId}) => {

    const {header} = useAppContext()

    const {
        data: cohortsData,
        isLoading: isCohortLoading,
    } = useGetAllCohorts()
    const {mutate, isLoading, isSuccess, isError, error} = useAddTraineeToCohort()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully created')
            setVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, error, isSuccess])

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(AddTraineeToCohortSchema),
    })

    if (isCohortLoading) {
        return <ShowLoading/>
    }

    const handleAddApplicant = (data) => {
        data.applicantId = applicantId
        mutate({data, header})
    }

    return (
        <CModal
            backdrop="static"
            visible={visible}
            onClose={() => {
                setVisible(false)
            }}
        >
            <CModalHeader onClose={() => setVisible(false)}>
                <CModalTitle id="">Assign Trainee To Cohort</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {(isCohortLoading) ? <ShowLoading/> :
                    <div className="d-flex flex-column justify-content-center">
                        <p className="mt-1 mb-4">Select Cohort for Trainee.</p>
                        <CForm className="w-100" onSubmit={handleSubmit(handleAddApplicant)}>
                            <div>
                                <CFormLabel className="m-0 mb-1">Cohort</CFormLabel>
                                <CFormSelect
                                    className="m-0 mb-3"
                                    {...register('cohortId')}
                                    invalid={!!errors?.cohortId}
                                >
                                    <option default value={null}>Select</option>
                                    {cohortsData?.data?.data?.items?.map(({id, displayName}, index) => {
                                        return <option key={index} value={id}>{displayName}</option>
                                    })}
                                </CFormSelect>
                                <CFormFeedback invalid={!!errors?.cohortId}>
                                    {errors?.cohortId?.message}
                                </CFormFeedback>
                            </div>
                            <div className="d-flex justify-content-end">
                                <CLoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    color="primary"
                                    type="submit"
                                    className="text-white my-3 px-5"
                                >
                                    Submit
                                </CLoadingButton>
                            </div>
                        </CForm>
                    </div>
                }
            </CModalBody>
        </CModal>
    )
}

export default TraineeToCohortModal