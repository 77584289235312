import React from "react";
import {CTooltip} from "@coreui/react-pro";

const CategoryCard = ({label, count, color_index, tip = '', qualifier = '', setAssessmentCategory}) => {
    const border_colors = ['border-primary', 'border-danger', 'border-warning', 'border-secondary']

    const handleSelectCategory = () => {
        setAssessmentCategory(label)
    }

    return (
        <>
            {!!tip &&
                <CTooltip content={tip} placement="top">
                    <div className={`d-flex flex-column justify-content-center align-items-center p-3 border border-2 border-radius-12 ${border_colors[color_index]}`}
                         style={{minWidth: '150px', height: '115px !important', cursor: 'pointer'}}
                         onClick={handleSelectCategory}
                    >
                        <p className="text-small text-wrap text-center">
                            {label?.toString()?.toUpperCase()}
                            <br/>
                            {qualifier?.length > 0 ? `(${qualifier})` : '...'}
                        </p>
                        <h1 className="text-1">{count}</h1>
                    </div>
                </CTooltip>}
            {!tip && <div className={`d-flex flex-column justify-content-center align-items-center p-3 border border-2 border-radius-12 ${border_colors[color_index]}`}
                          style={{minWidth: '150px', height: '115px !important'}}
            >
                <p className="text-small text-wrap">{label?.toString()?.toUpperCase()}</p>
                <h1 className="text-1">{count}</h1>
            </div>
            }
        </>
    )
}

export default CategoryCard
