import React, {useEffect, useState} from 'react'
import {
    // useGetEnrolledProgramQuery,
    // useGetSponsoredProgramQuery,
    useGetTrainingProgramQuery
} from '../../hooks/user/userApiHooks'
import TrainingsProgramsCard from '../../components/training/TrainingsProgramsCard'
import AuthRoute from '../../components/User/AuthRoute'
// import trainingBg from "../../assets/images/training-bg.png";
import {useAppContext} from "../../context/user/UserAppProvider";
import { CFormSelect } from "@coreui/react-pro";
import TrainingFilterModal from "../../components/modals/TrainingFilterModal";
// import pointer from "../../assets/icons/pointer.svg";
// import JobCardHeader from "../../components/job/JobCardHeader";
// import BreadCrumb from "../../components/job/BreadCrumb";
import BreadCrumbTraining from "../../components/training/BreadCrumbTraining";

function UserTrainings(props) {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10);
    const [visible, setVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState('');
    const [enable, setEnable] = useState(false)
    const [userLogin, setUserLogin] = useState("");
    const {user, userId} = useAppContext()
    const [recommendedPage, setRecommendedPage] = useState(1)
    const training = useGetTrainingProgramQuery(page, limit, '', filter, userLogin)
    // const recommendedTraining = useGetTrainingProgramQuery(page, limit, '', filter)
    // const enrolledTraining = useGetEnrolledProgramQuery(page, limit, filter, enable)
    // const sponsoredTraining = useGetSponsoredProgramQuery(page, enable)
    const [activeTap, setActiveTap] = useState(1)

    useEffect(()=>{
        if(user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))){
            setUserLogin("&isAdmin=true")
        }else if(user?.accessToken && !(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))){
            setUserLogin("&userId=" + userId)
        }
        else{
            setUserLogin("")
        }

    }, [])

    const filterTraining = <TrainingFilterModal setFilter={setFilter} setPage={setPage} visible={visible}
                                        setVisible={setVisible} />
    const itemLimit = <div className="d-flex align-items-center justify-content-end">
        <small>Items per page:</small>
        <CFormSelect
            onChange={(e)=>{setLimit(e.target.value);}}
            value={limit}
            className="my-2 mx-2"
            style={{width:"70px"}}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>

        </CFormSelect>
    </div>

    return (
        <>
            <AuthRoute protectedRoute={'/user/training-programs'} openRoute={'/trainings'}/>
            {(user?.accessToken && (user?.data?.role || user?.data?.role) && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy')))
                && <>
                <div className="d-flex gap-2 flex-column flex-sm-row">
                    <button onClick={() => {
                        setActiveTap(1)
                    }} className={`fw-normal btn-tap rounded-2 px-3 py-1 border-0 ${activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'}`}> <small>Free Trainings & Programs</small></button>
                    <button onClick={() => {
                        setEnable(true)
                        setActiveTap(2)
                    }} className={`fw-normal btn-tap rounded-2 px-3 py-1 rounded-2 px-3 py-1 border-0 ${activeTap === 2 ? 'text-white bg-primary' : 'bg-grey'}`}> <small>Enrolled Trainings & Programs</small></button>

                    <button onClick={() => {
                        setEnable(true)
                        setActiveTap(3)
                    }} className={`fw-normal btn-tap rounded-2 px-3 py-1 border-0  ${activeTap === 3 ? 'text-white bg-primary' : 'bg-grey'}`}><small>Sponsored Trainings & Programs</small></button>
                </div>
                <br/>
                </>
            }
            <br/>
            <BreadCrumbTraining setSearch={setSearch} setPage={setPage} title={"Find Career-Based Trainings & Programs"} desc={"With the help of our specially crafted trainings and programs curriculum, you can advance your professional development for job success."}/>
            <br/>
            {activeTap === 1 && <TrainingsProgramsCard setLimit={setLimit} filterTraining={filterTraining} query={training} type={"1"} page={page} setPage={setPage}/>}
            {activeTap === 2 && <TrainingsProgramsCard setLimit={setLimit} filterTraining={filterTraining} query={training} type={"2"} page={page} setPage={setPage}/>}
            {activeTap === 2 && <TrainingsProgramsCard setLimit={setLimit} filterTraining={filterTraining} query={training} type={"1"} page={recommendedPage} setPage={setRecommendedPage} interested={true}/>}
            {activeTap === 3 && <TrainingsProgramsCard setLimit={setLimit} filterTraining={filterTraining} query={training} type={"3"} page={page} setPage={setPage}/>}
        </>
    )
}

export default UserTrainings
