import React from "react";
import HRIcon from "../../assets/images/shared-profile-hr.png";
import {CCol, CImage, CRow} from "@coreui/react-pro";

const PreferencesSection1 = ({userProfileData}) => {
    return (
        <div className="my-2">
            <div className="d-flex flex-column py-2">
                <h6 className="fw-bold">Career Level</h6>
                <CRow className="gx-3">
                    <CCol md={6} className="my-2">
                        <div className="bg-grey p-2">
                            <h6 className="p-0 m-0 my-2"><strong>{userProfileData?.careerLevel || "Nil"}</strong></h6>
                        </div>
                    </CCol>
                </CRow>

            </div>
            <CImage src={HRIcon} style={{width: '100%'}}/>
            <div className="d-flex flex-column py-2">
                <h6 className="fw-bold">Employment Type</h6>
                <CRow className="gx-3">
                    {
                        userProfileData?.employmentType?.map((empType, index) => {
                            return (
                                <CCol key={index} md={6} className="my-2">
                                    <div className="bg-grey p-2">
                                        <h6 className="p-0 m-0 my-2"><strong>{empType}</strong></h6>
                                    </div>
                                </CCol>
                            )
                        }) ||
                        <div className=" border border-1 w-25 text-center shadow-sm py-3">
                            Nil
                        </div>
                    }
                </CRow>

            </div>
            <CImage src={HRIcon} style={{width: '100%'}}/>
        </div>
    )
}

export default PreferencesSection1