import TrainersCourses from "../../trainers/pages/dashboard/TrainersCourses";

const PrivateTrainerRoutes = [
    {
        path: '/trainer/course',
        exact: true,
        element: TrainersCourses,
        allowedPermissions: ['can_view_jobs']
    },
    {
        path: '/trainer/kpi',
        exact: true,
        element: TrainersCourses,
        allowedPermissions: ['can_view_jobs']
    },
    {
        path: '/trainer/attendance',
        exact: true,
        element: TrainersCourses,
        allowedPermissions: ['can_view_jobs']
    },

]

export default PrivateTrainerRoutes
