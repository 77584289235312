import React, {useCallback} from 'react';
import {
    CBadge,
    CButton,
    CCol,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react-pro";
import {MdClear} from "react-icons/md";
import uploadIcon from "../../assets/images/upload.svg";
import {Controller} from "react-hook-form";

function DocumentUpload({watch, resetField, errors, control, Controller}) {
    const hiddenPassportInput = React.useRef(null)
    const hiddenIdInput = React.useRef(null)
    const hiddenDocInput = React.useRef(null)

    const clearValidId = useCallback(() => {
        resetField('validId')
    }, [resetField])

    const clearDocument = useCallback(() => {
        resetField('document')
    }, [resetField])

    const clearPassport = useCallback(() => {
        resetField('passport')
    }, [resetField])

    const handlePassportClick = () => {
        hiddenPassportInput.current.click()
    }
    const handleIdClick = () => {
        hiddenIdInput.current.click()
    }
    const handleDocClick = () => {
        hiddenDocInput.current.click()
    }
    return (
        <>
            <CFormLabel className="text-black">Upload Documents</CFormLabel>
            <div className="w-100 bg-light-blue p-2 text-primary-alert mb-3">
                Maximum of 2mb size per document type
            </div>
            <CRow className="gy-4">
                <CCol md={12} className="table-responsive">
                    <CTable bordered>
                        <CTableHead>
                            <CTableRow className="bg-table">
                                <CTableHeaderCell scope="col" className="border-1">
                                    Document Name
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">File Format</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Upload</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>

                        <CTableBody>
                            <CTableRow>
                                <CTableDataCell align="middle">
                                    Valid ID{' '}
                                    <span className="small text-medium-emphasis">
                                      (international passport, driver&apos;s license, NIMC ID)
                                    </span>
                                </CTableDataCell>
                                <CTableDataCell align="middle">PDF</CTableDataCell>
                                <CTableDataCell
                                    className="d-flex align-items-center justify-content-center">
                                    {watch('validId')?.[0] ? (
                                        <div className="d-flex gap-1 align-items-center">
                                            <div>{watch('validId')[0]?.name}</div>
                                            <MdClear
                                                onClick={clearValidId}
                                                color="red"
                                                className="clickable-icon"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <CButton onClick={handleIdClick}
                                                     className="p-0 d-flex border-0">
                                                <div
                                                    className="reg-btn h-100 p-2 w-75 border-l-5 d-flex justify-content-center align-items-center">
                                                    UPLOAD
                                                </div>
                                                <div
                                                    className=" border-r-5 reg-btn h-100 p-2 w-25 d-flex justify-content-center align-items-center">
                                                    <img src={uploadIcon} alt="upload"/>
                                                </div>
                                            </CButton>
                                            <Controller
                                                name="validId"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <CFormInput
                                                            type="file"
                                                            name={field.name}
                                                            ref={hiddenIdInput}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files)
                                                            }}
                                                            style={{display: 'none'}}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </>
                                    )}
                                </CTableDataCell>
                                <CTableDataCell align="middle">
                                    {!!errors.validId ? (
                                        <div>
                                            <CFormInput type="hidden" invalid={!!errors.validId}/>
                                            <CFormFeedback invalid={!!errors.validId}>
                                                {errors.validId?.message}
                                            </CFormFeedback>
                                        </div>
                                    ) : watch('validId')?.[0] ? (
                                        <CBadge color="success">file selected</CBadge>
                                    ) : null}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell align="middle">
                                    Passport Photograph{' '}
                                    <span className="small text-medium-emphasis">
                            (Not older than 3 months)
                          </span>
                                </CTableDataCell>
                                <CTableDataCell align="middle">Jpeg, PNG</CTableDataCell>
                                <CTableDataCell
                                    className="d-flex align-items-center justify-content-center">
                                    {watch('passport')?.[0] ? (
                                        <div className="d-flex gap-1 align-items-center">
                                            <div>{watch('passport')[0]?.name}</div>
                                            <MdClear
                                                onClick={clearPassport}
                                                color="red"
                                                className="clickable-icon"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <CButton
                                                onClick={handlePassportClick}
                                                className="p-0 d-flex border-0"
                                            >
                                                <div
                                                    className="reg-btn h-100 p-2 w-75 border-l-5 d-flex justify-content-center align-items-center">
                                                    UPLOAD
                                                </div>
                                                <div
                                                    className=" border-r-5 reg-btn h-100 p-2 w-25 d-flex justify-content-center align-items-center">
                                                    <img src={uploadIcon} alt="upload"/>
                                                </div>
                                            </CButton>
                                            <Controller
                                                name="passport"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <input
                                                            type="file"
                                                            ref={hiddenPassportInput}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files)
                                                            }}
                                                            style={{display: 'none'}}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </>
                                    )}
                                </CTableDataCell>
                                <CTableDataCell align="middle">
                                    {!!errors.passport ? (
                                        <div>
                                            <CFormInput type="hidden" invalid={!!errors.passport}/>
                                            <CFormFeedback invalid={!!errors.passport}>
                                                {errors.passport?.message}
                                            </CFormFeedback>
                                        </div>
                                    ) : watch('passport')?.[0] ? (
                                        <CBadge color="success">file selected</CBadge>
                                    ) : null}
                                </CTableDataCell>
                            </CTableRow>
                            <CTableRow>
                                <CTableDataCell align="middle">Other Relevant Documents</CTableDataCell>
                                <CTableDataCell align="middle"></CTableDataCell>
                                <CTableDataCell
                                    className="d-flex align-items-center justify-content-center">
                                    {watch('document')?.[0] ? (
                                        <div className="d-flex gap-1 align-items-center">
                                            <div>{watch('document')[0]?.name}</div>
                                            <MdClear
                                                onClick={clearDocument}
                                                color="red"
                                                className="clickable-icon"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <CButton onClick={handleDocClick}
                                                     className="p-0 d-flex border-0">
                                                <div
                                                    className="reg-btn h-100 p-2 w-75 border-l-5 d-flex justify-content-center align-items-center">
                                                    UPLOAD
                                                </div>
                                                <div
                                                    className=" border-r-5 reg-btn h-100 p-2 w-25 d-flex justify-content-center align-items-center">
                                                    <img src={uploadIcon} alt="upload"/>
                                                </div>
                                            </CButton>
                                            <Controller
                                                name="document"
                                                control={control}
                                                render={({field}) => (
                                                    <>
                                                        <input
                                                            type="file"
                                                            ref={hiddenDocInput}
                                                            onChange={(e) => {
                                                                field.onChange(e.target.files)
                                                            }}
                                                            style={{display: 'none'}}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </>
                                    )}
                                </CTableDataCell>
                                <CTableDataCell align="middle">
                                    {!!errors.document ? (
                                        <div>
                                            <CFormInput type="hidden" invalid={!!errors.document}/>
                                            <CFormFeedback invalid={!!errors.document}>
                                                {errors.document?.message}
                                            </CFormFeedback>
                                        </div>
                                    ) : watch('document')?.[0] ? (
                                        <CBadge color="success">file selected</CBadge>
                                    ) : null}
                                </CTableDataCell>
                            </CTableRow>
                        </CTableBody>

                    </CTable>
                </CCol>
            </CRow>

        </>
    );
}

export default DocumentUpload;