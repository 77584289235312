import React, {useEffect, useState} from 'react'
import {
    CButton,
    CContainer, CFormSelect
} from "@coreui/react-pro";
import ShowLoading from "../../components/ShowLoading";
import {useGetAllQuestionnairesQuery} from "../../hooks/user/userApiHooks";
import {FiEye, FiPlus} from "react-icons/fi";
import {useNavigate} from "react-router-dom";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";

const ViewTest = () => {
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const { isLoading, data, refetch } = useGetAllQuestionnairesQuery(page, limit)

    useEffect(() => {
        refetch()
    }, [page, limit]);

    return (
        <>
            {isLoading && <ShowLoading/>}

            <CContainer>
                <div className="flex-wrap d-flex gap-2">

                </div>

                <hr/>

                <div className="my-4 d-flex justify-content-center">

                    <div className="table-responsive mt-2 w-100">
                        <table className="table border-0" id="reports-table">
                            <thead className="thead-inverse">
                            <tr className="bg-transparent text-primary">
                                <th scope="col">S/N</th>
                                <th scope="col">Name</th>
                                <th scope="col" style={{width: "120px"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data?.data?.data?.map((item, index) => {
                                return <tr key={index}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {item.title.toUpperCase()}
                                    </td>
                                    <td>
                      <span className="d-flex gap-1">
                          <CButton className="cursor-pointer py-0 px-2 rounded border text-white bg-primary m-1"
                                   onClick={() => {
                                       navigate(`/admin/tests/${item.quizUuid}`, {
                                           replace: false,
                                       })
                                   }}
                          >
                              <FiEye color={"white"}/>
                          </CButton>

                          <CButton className="cursor-pointer py-0 px-2 rounded border text-white bg-primary m-1"
                                   onClick={() => {
                                       navigate(`/admin/upload-questions/${item.quizUuid}/${item.title.replace(' ', '-')}`, {
                                           replace: false,
                                       })
                                   }}
                          >
                              <FiPlus color={"white"}/>
                          </CButton>
                      </span>
                                    </td>


                                </tr>
                            })}
                            {data?.data?.data?.length < 1 &&
                                <tr>
                                    <td><h6 className="text-black-50 border-0">No record found</h6></td>
                                    <td></td>
                                </tr>
                            }
                            </tbody>
                        </table>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className={"d-flex flex-row gap-3 w-23"}>
                                <div className={"m-2"}>Show</div>
                                <CFormSelect
                                    aria-label="Default select example"
                                    options={[
                                        {label: '10', value: '10'},
                                        {label: '25', value: '25'},
                                        {label: '100', value: '100'},
                                        {label: '200', value: '200'}
                                    ]}
                                    onChange={(e) => setLimit(e.target.value)}
                                />
                                <div className={"m-2"}>Entries</div>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <CButton onClick={() => setPage(page - 1)}
                                         className={`mx-3 text-white py-1 pb-2 px-3 ${page === 1 ? 'disabled' : ''}`}>
                                    <BiLeftArrow/>
                                </CButton>
                                <CButton onClick={() => setPage(page + 1)}
                                         className="mx-3 text-white py-1 pb-2 px-3">
                                    <BiRightArrow/>
                                </CButton>
                            </div>
                        </div>
                    </div>

                </div>
            </CContainer>

        </>
    )
}

export default ViewTest
