import Faqs from "../user/pages/Faqs";
import UserTrainings from "../user/pages/UserTrainings";
import Jobs from "../user/pages/Jobs";
import JobDetail from "../user/pages/JobDetail";
import TrainingDetail from "../user/pages/TrainingDetail";
import Registration from "../user/pages/Registration";
import RegSuccess from "../user/pages/user-registration/RegSuccess";
import Verified from "../user/pages/user-registration/Verified";
import VerifiedStakeholder from 'src/employer/pages/Verified'
import LandingPage2 from "../user/pages/LandingPage2";
import WorknationProfile from "../user/pages/dashboard/WorknationProfile";

const GuestRoutes = [
    {path: 'faqs', exact: true, element: Faqs},
    {path: '/', exact: true, element: LandingPage2},
    {path: '/trainings', exact: true, element: UserTrainings},
    {path: '/jobs', exact: true, element:Jobs},
    {path: '/job-detail/:slug', exact: true, element:JobDetail},
    {path: '/training-detail/:slug', exact: true, element:TrainingDetail},
    {path: '/registration', exact: true, element:Registration},
    {path: '/sign-up-success', exact: true, element:RegSuccess},
    {path: '/verified-success', exact: true, element:Verified},
    {path: '/verification-successful', exact: true, element:VerifiedStakeholder},
    // {path: '/user/shared-profile/:profileSlug', exact: true, element: ApplicantProfile},
    {path: '/:profileSlug', exact: true, element: WorknationProfile}

]

export default GuestRoutes