import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CFormSelect, CRow} from "@coreui/react-pro";
import {useGetShortlistedApplicants} from "../../hooks/employer/employerApiHooks";
import DataNotFound from "../../components/DataNotFound";
import {fail_notify} from "../../constant";
import ShowLoading from "../../components/ShowLoading";
import {ref} from "yup";

const ShortlistedApplicants = () => {

    const [limit, setLimit] = useState(10)
    const {data, isError, error, isLoading, refetch} = useGetShortlistedApplicants(limit);

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [error?.response?.data?.message, isError]);

    useEffect(() => {
        refetch();
    }, [limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer>
            <h2 className="text-2">Shortlisted Applicants</h2>
            <hr/>
            <CRow>
                <CCol md={12}>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                            <tr className="bg-transparent text-primary">
                                <th>Name</th>
                                <th>Email</th>
                                <th>Job Title</th>
                                <th>Date/Time</th>
                                <th>Attendees</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data?.data?.data?.items?.map(({
                                                           user,
                                                           job,
                                                           interviewDate,
                                                           interviewTime,
                                                           emailCC,
                                                           interviewLink
                                                       }, index) => {
                            return <tr key={index}>
                                <td className="td-single-line fw-bolder">{user?.firstName} {user?.lastName}</td>
                                <td>{user?.email}</td>
                                <td>{job?.title}</td>
                                <td>{interviewDate} - {interviewTime}</td>
                                <td>{
                                    emailCC?.map((email, index) => <span className="p-2 border"
                                                                         key={index}>{email}</span>)
                                }</td>
                                <td>
                                    <a href={interviewLink} target='_blank' rel="noreferrer">
                                        <button className="btn btn-sm btn-primary text-white">Join</button>
                                    </a>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    {
                        data?.data?.data?.items?.length > 10 && <div className="d-flex align-items-center gap-1">
                            <small className={""}>Items per page:</small>
                            <div><CFormSelect
                                aria-label="Default select example"
                                value={limit}
                                options={[
                                    {label: '5', value: '5'},
                                    {label: '10', value: '10'},
                                    {label: '20', value: '20'},
                                    {label: '50', value: '50'},
                                    {label: '100', value: '100'}
                                ]}
                                onChange={(e) => setLimit(e.target.value)}
                            /></div>
                        </div>
                    }
                    {data?.data?.data?.items?.length === 0 && <DataNotFound/>}
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default ShortlistedApplicants;