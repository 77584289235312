import React, {useState} from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import AcademiesImage from '../../assets/images/academies-image.svg';
import AcademiesAccountIcon from '../../assets/icons/academies-acount-icons.svg';
import AcademiesTrainingIcon from '../../assets/icons/academies-training-icon.svg';
import AcademiesModulesIcon from '../../assets/icons/academies-module-icon.svg';
import AcademiesTestingIcon from '../../assets/icons/academies-testing-icon.svg';
import TrackTraineeProgress from '../../assets/images/track-trainee-progress.jpg';
import ViewResults from '../../assets/images/view-results.jpg';
import GenerateReports from '../../assets/images/generate-reports.jpg';

const AcademiesSection = () => {
    const [current, setCurrent] = useState(1);

    return <CContainer className="p-5">
        <CRow>
            <CCol md={6}>
                <p className="text-small text-primary">Welcome to Our Academies</p>
                <h1 className="text-primary-dark">Academies</h1>
                <p className="text-small">At our Academies, we&apos;re here to make your learning experience as smooth
                    and user-friendly as possible. Explore what we offer.</p>
            </CCol>
        </CRow>
        <CRow className="m-0 p-0 mt-4 border-bottom border-bottom-3 border-bottom-primary">
            <CCol
                md={3}
                className={`p-0 m-0 ${current === 1 ? 'bg-primary-dark text-white' : 'bg-white gray-paragraph'} d-flex flex-row justify-content-center align-items-center cursor-pointer`}
                onClick={() => setCurrent(1)}
            >
                <div className="p-2">
                    <p className="text-center">Creating Courses and Training Programs</p>
                </div>
            </CCol>
            <CCol
                md={3}
                className={`p-0 m-0 ${current === 2 ? 'bg-primary-dark text-white' : 'bg-white gray-paragraph'} d-flex flex-row justify-content-center align-items-center cursor-pointer`}
                onClick={() => setCurrent(2)}
            >
                <div className="p-2">
                    <p className="text-center">Managing Trainees and Courses</p>
                </div>
            </CCol>
        </CRow>
        <CRow className="bg-white m-0 p-0">
            {current === 1 &&
                <>
                    <p className="text-primary-dark py-4"><strong>Ready to start your learning journey? It&apos;s easy!
                        Here&apos;s how:</strong></p>
                    <CCol md={5}>
                        <CImage src={AcademiesImage} alt="Academy" className="w-100 mt-3"/>
                    </CCol>
                    <CCol md={7} className="pb-2 mb-1">
                        <CRow>
                            <CCol md={6} className="mt-3">
                                <div className="p-3 border border-radius-12 border-primary">
                                    <CImage src={AcademiesAccountIcon} alt="Create an Account"/>
                                    <h6 className="text-primary-dark my-1"><strong>Create an Account</strong></h6>
                                    <p className="text-small">Begin by setting up your account to access our extensive
                                        range of training programs.</p>
                                </div>
                            </CCol>
                            <CCol md={6} className="mt-3">
                                <div className="p-3 border border-radius-12 border-primary">
                                    <CImage src={AcademiesTrainingIcon} alt="Create an Account"/>
                                    <h6 className="text-primary-dark my-1"><strong>Training Courses</strong></h6>
                                    <p className="text-small">Choose from a variety of training courses tailored to your
                                        needs and interests.</p>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={6} className="mt-3">
                                <div className="p-3 border border-radius-12 border-primary">
                                    <CImage src={AcademiesModulesIcon} alt="Create an Account"/>
                                    <h6 className="text-primary-dark my-1"><strong>Interactive Modules</strong></h6>
                                    <p className="text-small">Dive into engaging, interactive modules that will help you
                                        grasp the material effectively.</p>
                                </div>
                            </CCol>
                            <CCol md={6} className="mt-3">
                                <div className="p-3 border border-radius-12 border-primary">
                                    <CImage src={AcademiesTestingIcon} alt="Create an Account"/>
                                    <h6 className="text-primary-dark my-1"><strong>Knowledge Testing</strong></h6>
                                    <p className="text-small">Assess your understanding with tests designed to reinforce
                                        your learning.</p>
                                </div>
                            </CCol>
                        </CRow>
                    </CCol>
                </>
            }
            {current === 2 &&
                <>
                    <p className="text-center text-primary-dark py-3">
                        <strong>
                            We understand the importance of keeping things organized.<br/>
                            Our platform offers the following features to make your experience hassle-free
                        </strong>
                    </p>
                    <CRow className="d-flex flex-row justify-content-center align-items-center">
                        <CCol md={3} style={{height: '300px'}}>
                            <div className="border-radius-12 shadow-sm h-100">
                                <CImage src={TrackTraineeProgress} className="w-100 rounded-top" />
                                <div className="mt-4 p-2">
                                    <h6 className="text-primary-dark"><strong>Track Trainee Progress</strong></h6>
                                    <p className="text-small">Keep an eye on the progress of trainees within your course.</p>
                                </div>
                            </div>
                        </CCol>
                        <CCol md={3} className="my-sm-3" style={{height: '300px'}}>
                            <div className="border-radius-12 shadow-sm h-100">
                                <CImage src={ViewResults} className="w-100 rounded-top" />
                                <div className="mt-4 p-2">
                                    <h6 className="text-primary-dark"><strong>View Results and Performance Records</strong></h6>
                                    <p className="text-small">Access detailed information on individual trainee results and their performance records.</p>
                                </div>
                            </div>
                        </CCol>
                        <CCol md={3} style={{height: '300px'}}>
                            <div className="border-radius-12 shadow-sm h-100">
                                <CImage src={GenerateReports} className="w-100 rounded-top" />
                                <div className="mt-4 p-2">
                                    <h6 className="text-primary-dark"><strong>Generate Impact Reports</strong></h6>
                                    <p className="text-small">Easily generate reports to evaluate the impact of our courses on your learners.</p>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </>
            }
            <p className="text-center small-text-gray py-4">
                <em>Join Our Academies today and take control of your learning journey with our user-friendly
                    platform!</em>
            </p>
        </CRow>
    </CContainer>
}

export default AcademiesSection;