import React, {useState} from 'react'
import {useGetQuestionnaires} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import UnTimedQuestionnaireFormTemp from "./UnTimedQuestionnaireFormTemp";
import {isMobile} from "react-device-detect";
import NoTestOnMobileModal from "../../../components/modals/NoTestOnMobileModal";
import GeneralQuestionnaireWelcomeModal from "../../../components/modals/GeneralQuestionnaireWelcomeModal";
import {useParams} from "react-router-dom";
import {modalPrompts} from "../../../constant/modalPrompts";

const UntimedQuestionnaireLayout = () => {
    const { slug } = useParams();
    const url = slug.replace(/-/g, ' ')
    const test = slug.replace(/-/g, '').toLowerCase()
    const {isLoading, data} = useGetQuestionnaires(url)
    const [showWelcomeText, setShowWelcomeText] = useState(true)
    const modalPrompt = modalPrompts[test]


    if (isMobile) {
        return (
            <NoTestOnMobileModal/>
        )
    }

    if (isLoading) {
        return (
            <ShowLoading/>
        )
    }

    if (showWelcomeText) {
        return (
            <GeneralQuestionnaireWelcomeModal
                visible={showWelcomeText}
                setVisible={setShowWelcomeText}
                title={modalPrompt.title}
                text={modalPrompt.msgContent}
            />
        )
    }

    return (
        <>
            <UnTimedQuestionnaireFormTemp
                data={data}
                title={data?.data?.data?.title}
                caption={"Excited to have you here! Get Started"}
                url={test}
            />
        </>
    )
}

export default UntimedQuestionnaireLayout
