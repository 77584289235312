import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
    CCol,
    CContainer,
    CRow,
    CImage, CFormLabel, CFormInput, CFormSelect, CButton
} from "@coreui/react-pro";
import {BiEnvelope, BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png"
import ShowLoading from "../../components/ShowLoading";
import {useGetAllEmployers} from "../../hooks/employer/employerApiHooks";
import appApiCalls from "../../services/api";
import {isMobile} from "react-device-detect";
import {debounce} from "lodash";
import {Link} from "react-router-dom";
import DataNotFound from "../../components/DataNotFound";

const Employers = () => {

    const [employersData, setEmployersData] = useState([])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [organizationStatus, setOrganizationStatus] = useState("")
    const [institutionType, setInstitutionType] = useState("")
    const [tinNumber, setTinNumber] = useState("")
    const [isImgError, setImgError] = useState(false)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const loadedData = useGetAllEmployers(limit, page)
    let employers = useRef(loadedData)
    const debounceNameHandler = useCallback(debounce(e => setName(e.target.value), 600), [])
    const debounceEmailHandler = useCallback(debounce(e => setEmail(e.target.value), 600), [])
    const debounceTinHandler = useCallback(debounce(e => setTinNumber(e.target.value), 600), [])

    useEffect(() => {
        setEmployersData(employers.current?.data?.data?.data?.items?.map((item, index) => {
            return {
                id: index,
                user: [item.name, item.logoUrl],
                phoneNumber: item.phoneNumber,
                email: item.email,
                status: item.organizationStatus,
                type: item.institutionsType,
                registered: item.createdAt.substring(0, 10),
                action: item.stakeholderUuid
            }
        }))
    }, [employers.current?.data?.data?.data])

    useEffect(() => {
        const updateEmployers = async () => {
            employers.current = await appApiCalls.getAllEmployers(limit, page, "", email, name, organizationStatus, institutionType, tinNumber)
            if (!(employers.current?.data?.data?.items)) return
            setEmployersData(employers.current?.data?.data?.items?.map((item, index) => {
                return {
                    id: index,
                    user: [item.name, item.logoUrl],
                    phoneNumber: item.phoneNumber,
                    email: item.email,
                    status: item.organizationStatus,
                    type: item.institutionsType,
                    registered: item.createdAt.substring(0, 10),
                    action: item.stakeholderUuid
                }
            }))
        }
        updateEmployers()
    }, [email, name, organizationStatus, institutionType, tinNumber, limit])

    const convertToDate = (date) => {
        const _date = new Date(Date.parse(date))
        return _date.toLocaleDateString()
    }

    const convertUserDisplay = (data) => {
        //console.log(data[1], 'data')
        return (
            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                {isImgError ? <CImage src={DummyProfilePic} alt="Profile" style={{
                        width: '44px',
                        height: '44px',
                        marginRight: '10px',
                        objectFit: 'contain',
                    }}/> :
                    <CImage src={data[1]} alt="Profile" style={{
                        width: '44px',
                        height: '44px',
                        marginRight: '10px',
                        objectFit: 'contain',
                    }} isError={() => setImgError(true)}/>}
                <p style={{marginBottom: "0"}} className="w-75 text-truncate"><b>{data[0]}</b></p>
            </div>
        )
    }

    const handleResetFilter = () => {
        setName("")
        setEmail("")
        setOrganizationStatus("")
        setTinNumber("")
        setInstitutionType("")

        // select first dropdown option
        document.querySelector("#name").value = ""
        document.querySelector("#email").value = ""
        document.querySelector("#tinNumber").value = ""
        const dropdown = [...document.querySelectorAll('select')]
        dropdown?.map((box) => {
            return box.selectedIndex = 0
        })
    }

    const convertAction = (action) => {
        return <Link to={`/admin/employer/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }

    return (
        <>
            {!employersData && <ShowLoading/>}
            {employersData &&
                <CContainer>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <h5 className="m-0">{`${employersData ? employersData?.length : 0} Employers`}</h5>
                        </div>
                    </div>
                    {!isMobile && <div>
                        <div className="d-flex flex-row justify-content-end align-items-center px-1">
                            <p
                                className="text-primary cursor-pointer"
                                onClick={handleResetFilter}
                            >Clear all</p>
                        </div>
                        <CRow>
                            <CCol md={4}>
                                <CFormLabel htmlFor="name" className="m-1 label">Name</CFormLabel>
                                <CFormInput type="text" id="name"
                                            aria-describedby="searchByText"
                                            onChange={debounceNameHandler}
                                            className="rounded-0"
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="email" className="m-1 label">Email</CFormLabel>
                                <CFormInput type="text" id="email"
                                            aria-describedby="searchByEmail"
                                            onChange={debounceEmailHandler}
                                            className="rounded-0"
                                />
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="organizationStatus" className="m-1 label">Organization
                                    Status</CFormLabel>
                                <CFormSelect aria-label="Select companyName status" id="organizationStatus"
                                             onChange={(e) => setOrganizationStatus(e.target.value)}
                                             className="form-control mb-2 rounded-0"
                                >
                                    <option value="">Select Organization Status</option>
                                    <option value="Local">Local</option>
                                    <option value="International">International</option>
                                </CFormSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={4}>
                                <CFormLabel htmlFor="institutionsType" className="m-1 label">Institution
                                    Type</CFormLabel>
                                <CFormSelect aria-label="Select Institution Type" id="institutionsType"
                                             onChange={(e) => setInstitutionType(e.target.value)}
                                             className="form-control mb-2 rounded-0">
                                    <option value="">Select Institution Type</option>
                                    <option value="Vocational">Vocational</option>
                                    <option value="Academic">Academic</option>
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="tinNumber" className="m-1 label">TIN Number</CFormLabel>
                                <CFormInput type="text" id="tinNumber"
                                            aria-describedby="tinNumber"
                                            onChange={debounceTinHandler}
                                            className="form-control mb-2 rounded-0"
                                />
                            </CCol>
                        </CRow>
                    </div>}
                    <CRow className="mt-3 mb-5">
                        <CCol md={12}>
                            <table className="table border-0" id="reports-table">
                                <thead className="thead-inverse">
                                <tr className="bg-transparent text-primary">
                                    <th>User</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Type</th>
                                    <th>Registered</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    employersData?.map((item, index) => {
                                        return <tr key={index}>
                                            {loadedData?.isLoading && <ShowLoading/> }
                                            <td>{convertUserDisplay(item.user)}</td>
                                            <td><BsTelephone/> {item.phoneNumber}</td>
                                            <td><BiEnvelope/> {item.email}</td>
                                            <td>{item.status || 'NIL'}</td>
                                            <td>{item.type || 'NIL'}</td>
                                            <td>{convertToDate(item.registered)}</td>
                                            <td>{convertAction(item.action)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            {(!loadedData?.isLoading && employersData?.length < 1) && <DataNotFound/>}

                            {loadedData?.data?.data?.data?.meta?.totalPages > 0 && <div className={"d-flex justify-content-between flex-wrap gap-2 w-100"}>
                                <div className={"d-flex flex-row gap-3"}>
                                    <div className={"m-2"}>Show</div>
                                    <CFormSelect
                                        aria-label="Default select example"
                                        options={[
                                            {label: '10', value: '10'},
                                            {label: '25', value: '25'},
                                            {label: '100', value: '100'},
                                            {label: '200', value: '200'}
                                        ]}
                                        onChange={(e) => setLimit(e.target.value)}
                                    />
                                    <div className={"m-2"}>Entries</div>
                                </div>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                             className={`mx-3 text-white py-1 pb-2 px-3`}>
                                        <BiLeftArrow/>
                                    </CButton>

                                    <CButton
                                        disabled={page === loadedData?.data?.data?.data?.meta?.totalPages || loadedData?.isLoading || loadedData?.isError}
                                        onClick={() => setPage(page + 1)} className="mx-3 text-white py-1 pb-2 px-3">
                                        <BiRightArrow/>
                                    </CButton>
                                </div>
                            </div>}


                        </CCol>
                    </CRow>
                </CContainer>
            }
        </>
    )
}

export default Employers
