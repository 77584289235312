import React, {createContext, useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useGeneratePDF, useGetCVBuilderQuery} from "../../hooks/user/cvBuilderHooks";
import {useGetUserByUUID} from "../../hooks/user/userApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import VerticalBrownResume from "./vertical-brown-template/VerticalBrownResume";
import ScatteredGrayTemplate from "./scattered-gray-template/ScatteredGrayTemplate";
import HorizontalGreen from "./horizontal-green-template/HorizontalGreen";

const CVBuilderContext = createContext(null);

function CvBuilderProvider({children}) {
    const {user} = useAppContext()
    const location = useLocation()
    const navigate = useNavigate()
    const [status, setStatus] = useState(1)
    const [personalData, setPersonalData] = useState(null)
    const [experienceData, setExperienceData] = useState(null)
    const [educationsData, setEducationData] = useState(null)
    const [achievementsData, setAchievementsData] = useState(null)
    const [skillsData, setSkillsData] = useState(null)
    const [publicationsData, setPublicationsData] = useState(null)
    const [languagesData, setLanguagesData] = useState(null)
    const [saveCVForJob, setSaveCVForJob] = useState(false)

    const {mutate, data, isSuccess, isLoading} = useGeneratePDF();

    const cvData = useGetCVBuilderQuery()
    const preData = useGetUserByUUID(user?.data?.userUuid)
    const themes = cvData?.data?.data?.data[0]?.themes

    const AvailableTemplates = [
        {title: 'vertical', defaultColor: '#510505', element: VerticalBrownResume},
        {title: 'scattered', defaultColor: '#ACA6A6', element: ScatteredGrayTemplate},
        {title: 'horizontal', defaultColor: '#045F2D', element: HorizontalGreen}]
    const Template = AvailableTemplates.filter(item => item.title === location?.state?.title)[0].element
    const [mainColor, setMainColor] = useState(Template.defaultColor);

    useEffect(() => {
        if (themes?.mainColor) {
            setMainColor(themes?.mainColor)
        }
    }, [themes])

    const value = {
        setStatus,
        status,
        setPersonalData,
        personalData,
        navigate,
        isLoading,
        mutate,
        data,
        isSuccess,
        cvData,
        preData,
        themes,
        experienceData,
        setExperienceData,
        educationsData,
        setEducationData,
        achievementsData,
        setAchievementsData,
        skillsData,
        setSkillsData,
        publicationsData,
        setPublicationsData,
        languagesData,
        setLanguagesData,
        mainColor,
        setMainColor,
        Template,
        saveCVForJob,
        setSaveCVForJob
    }
    return (
        <CVBuilderContext.Provider value={value}>
            {children}
        </CVBuilderContext.Provider>
    );
}

export default CvBuilderProvider;

export const useCVBuilderContext = () => useContext(CVBuilderContext)