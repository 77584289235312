import React from 'react';
import {CAlert, CButton, CForm, CModalTitle} from "@coreui/react-pro";
import ErrorReports from "../../components/ErrorReports";
import {Controller} from "react-hook-form";
import RadioButton from "../../components/buttons/RadioButton";
import {tagOptions} from "../../constant/matchingAndProfileConst";

function AdditionalQuestions({
                                 setNext,
                                 errors,
                                 isError,
                                 matchingQuestions,
                                 tags,
                                 setVisible,
                                 handleMatchProfileSubmit,
                                 handleSubmit,
                                 error,
                                 control,
                                 cvId
                             }) {

    return (
        <div>
            <CModalTitle className=" px-lg-5 mb-2 mt-3 text-title"> ADDITIONAL QUESTIONS </CModalTitle>
            {
                Object.keys(errors).length > 0 &&
                <CAlert color="danger" className={'text-center'}>
                    One or More Questions Not Answered
                </CAlert>
            }

            {
                isError &&
                <ErrorReports error={error}/>
            }
            <CForm onSubmit={handleSubmit(handleMatchProfileSubmit)}>
                <div className="px-lg-5 d-flex gap-5 justify-content-between">
                    {
                        matchingQuestions?.map((question, index) => (
                            <div className="" key={index}>
                                <p>
                                    {index + 1}. {question.question}
                                </p>
                                <div className="ps-5">
                                    <Controller
                                        control={control}
                                        name={`question${index}`}
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange}
                                                 }) => (
                                            <>
                                                {question.options.map((o, i) => (
                                                    <RadioButton
                                                        type="radio"
                                                        key={i}
                                                        name={`question${index}`}
                                                        onChange={onChange}
                                                        value={`${o.id}`}
                                                        label={o.option}
                                                        autoFocus
                                                    />
                                                ))}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="px-lg-5 d-flex  flex-wrap gap-5 justify-content-between">
                    {
                        tags?.map((skill, index) => (
                            <div className="" key={index}>
                                <p>
                                    {2 + index + 1}. {tagOptions.question} {skill}
                                </p>
                                <div className="ps-5">
                                    <Controller
                                        control={control}
                                        name={skill}
                                        rules={{required: true}}
                                        render={({
                                                     field: {onChange}
                                                 }) => (
                                            <>
                                                {tagOptions.options.map((o, i) => (
                                                    <RadioButton
                                                        type="radio"
                                                        key={i}
                                                        name={skill}
                                                        onChange={onChange}
                                                        value={o.option}
                                                        label={o.option}
                                                        autoFocus
                                                    />
                                                ))}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center py-3">
                    <CButton onClick={() => {
                        setNext(1)
                    }} className="text-white btn-warning mx-1 px-5">
                        Back
                    </CButton>
                    <CButton type={"submit"} className="text-white px-5 mx-1"
                    >
                        Submit
                    </CButton>


                </div>
            </CForm>
        </div>
    );
}

export default AdditionalQuestions;