import React, {useEffect, useState} from "react";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import EducationIcon from "../../../assets/icons/Education.svg";
import InfoTitle from "./InfoTitle";
import {fail_notify} from "../../../constant";
import {useGetInstitutions} from "../../../hooks/user/userApiHooks";
import {
    educationFormat,
    EducationSchema,
} from "../../../form-schema/user-form-schema/cv-builder/EducationCertificates";
import education from "../../../constant/cvBuilder/education";
import {CButton, CCol, CForm, CLoadingButton, CRow} from "@coreui/react-pro";
import EducationCard from "./EducationCard";
import EducationForm from "./Education/EducationForm";
import {useCVBuilder} from "../../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

const Education = () => {
    const {status, setStatus, setEducationData, cvData} = useCVBuilderContext()
    const [educations, setEducations] = useState(cvData?.data?.data?.data[0]?.educations || [educationFormat]);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder();
    const [editorState, setEditorState] = useState(educations);
    const institution = useGetInstitutions();
    const [activeSchoolId, setActiveSchoolId] = useState(-1);
    const {
        register,
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(EducationSchema),
        defaultValues: education(editorState),
    });

    const {append: appendEducation} = useFieldArray({
        control,
        name: "educations"
    });

    useEffect(() => {
        setValue("educations", editorState)
    }, [editorState])
    const watchAllFields = useWatch({
        control
    });

    useEffect(() => {
        setEducationData(watchAllFields);
    }, [watchAllFields])

    useEffect(() => {
        if (isSuccess) {
            cvData.data.data.data[0].educations = getValues().educations;
            setValue("educations", editorState);
            if (editorState?.length == 0) {
                setActiveSchoolId(0);
                appendEducation();
            } else {
                setActiveSchoolId(-1);
            }

        }
        if (isError) {
            fail_notify(error?.response?.data?.message);
        }
    }, [mutate, data, isSuccess, isError, error, isLoading])

    useEffect(() => {
        if (activeSchoolId === -1 && editorState?.length == 0) {
            setActiveSchoolId(0);
            appendEducation();
        }
    }, [])

    const onSubmit = (data) => {
        mutate(data);
    }
    const deleteExperience = (id) => {
        editorState.pop(id);
        mutate({educations: editorState});
    }

    return (<>
        {
            status === 3 &&
            <>
                <InfoTitle title={"EDUCATION"} icon={EducationIcon}/>
                {
                    activeSchoolId < 0 &&
                    <>
                        {
                            editorState.map((school, index) => {
                                return (
                                    <EducationCard
                                        key={index}
                                        schoolId={index}
                                        setActiveSchoolId={setActiveSchoolId}
                                        school={school}
                                        deleteExperience={deleteExperience}
                                    />

                                )

                            })
                        }

                        <CRow className="g-3 py-2">
                            <CCol>
                                <CButton onClick={() => {
                                    setActiveSchoolId(editorState.length)
                                    appendEducation()
                                }}
                                         className="border rounded text-black-50 bg-transparent d-flex  cursor-pointer justify-content-center py-2 px-3">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor"
                                             className="bi bi-plus-lg" viewBox="0 0 16 16">
                                            <path
                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                                        </svg>
                                    </div>
                                    <div className="">
                                        <div className="ps-2">Add Qualification</div>
                                    </div>
                                </CButton>
                            </CCol>

                        </CRow>
                        <center
                            className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                            <CButton
                                onClick={() => {
                                    setStatus(2)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Back
                            </CButton>
                            <CLoadingButton
                                onClick={() => {
                                    setStatus(4)
                                }}
                                className="py-3 cv-btn m-1 text-white">
                                Next
                            </CLoadingButton>
                        </center>
                    </>
                }
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    {
                        activeSchoolId >= 0 &&
                        <>
                            <div className="d-flex">
                                <EducationForm
                                    watchAllFields={watchAllFields}
                                    setEditorState={setEditorState}
                                    errors={errors}
                                    index={activeSchoolId}
                                    register={register}
                                    institution={institution}
                                />
                            </div>

                            <center
                                className="d-flex flex-column flex-sm-row align-items-center justify-content-center">
                                <CButton
                                    onClick={() => {
                                        setActiveSchoolId(-1)
                                    }}
                                    className="py-3 cv-btn m-1 text-white">
                                    Back
                                </CButton>
                                <CLoadingButton loading={isLoading} disabled={isLoading} type="submit"
                                                className="py-3 cv-btn m-1 text-white">
                                    SAVE
                                </CLoadingButton>
                            </center>
                        </>
                    }
                </CForm>

            </>
        }
    </>)
}
export default Education