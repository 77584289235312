import {useEffect} from 'react'
import {useAppContext} from "../../context/user/UserAppProvider";
import {useNavigate, useParams} from "react-router-dom";


const AuthRoute = ({ protectedRoute, openRoute}) => {



    const {user} = useAppContext()
    const navigate = useNavigate()
    const { slug } = useParams();


    useEffect(() => {
        if(user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Government') || user?.data?.roles?.includes('Academy'))){
            return
        }
        if(user?.accessToken){
            if(slug){
                navigate(protectedRoute + slug, {
                    replace: true,
                })
            }
            else{
                navigate(protectedRoute, {
                    replace: true,
                })
            }

        }
        else {
            if(slug){
                navigate(openRoute + slug, {
                    replace: true,
                })
            }
            else {
                navigate(openRoute, {
                    replace: true,
                })
            }

        }
    }, [user])


}

export default AuthRoute
