import React, {useEffect, useState} from 'react'
import moment from "moment/moment";
import {useGetjobDetailQuery, useUpdateMessageStatus} from "../../hooks/user/userApiHooks";
import ShowLoading from "../ShowLoading";
import {CButton, CImage} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import DOMPurify from "dompurify";
import logo from "../../assets/brand/WN-logo.svg";

const DisplayMessage = ({message, userId}) => {

    const [jobData, setJobData] = useState({})
    const {
        isLoading: isLoadingJobDetail,
        data
    } = useGetjobDetailQuery(message?.url, userId)
    const {mutate} = useUpdateMessageStatus()

    useEffect(() => {
        setJobData(data?.data?.data)
        mutate(message?.id)
    }, [data?.data?.data, message?.id])

    if (!message?.company) {
        return <p className="text-center p-3">No message, yet!</p>
    }

    if (isLoadingJobDetail) {
        return <ShowLoading/>
    }

    return (
        <>
            {data && <>
                <div className="d-flex flex-row justify-content-between align-items-center p-3"
                     style={{boxShadow: "gray 0px 3px 6px -4px"}}>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <div>
                            <CImage onError={(e) => {e.target.src = logo}} src={jobData?.stakeholder?.logoUrl || logo} alt="Logo" className="me-2"
                                    style={{width: '42px', height: 'auto'}}/>
                        </div>
                        <div>
                            <h6 className="m-0 mb-1 text-dark">{message?.company}</h6>
                            <h6><strong>{message?.title}</strong></h6>
                        </div>
                    </div>
                    <div><p className="small-text-gray">{moment(message?.createdAt).format('LLLL')}</p></div>
                </div>
                <div className="px-5 pt-3 pb-5">
                    <p className="pt-5 pb-4" style={{overflowWrap: "break-word"}}
                       dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(jobData?.description)}}
                    >
                    </p>
                    <Link to={`/user/job-detail/${message?.url}`} className="text-center">
                        <CButton className="py-2 px-4 text-white">View Job</CButton>
                    </Link>
                </div>
            </>
            }
        </>
    )
}

export default DisplayMessage
