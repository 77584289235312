import React, {useEffect, useRef, useState} from 'react'
import {useGetQuestionnaires} from "../../../hooks/user/userApiHooks";
import TimedQuestionnaireFormTemp from "./TimedQuestionnaireFormTemp";
import ShowLoading from "../../../components/ShowLoading";
import {useParams} from "react-router-dom";
import {isMobile} from "react-device-detect";
import NoTestOnMobileModal from "../../../components/modals/NoTestOnMobileModal";
import AssessmentTips from "./test-monitoring/AssessmentTips";

const TimedQuestionnaireLayout = () => {
    const { slug } = useParams();
    const url = slug.replace(/-/g, ' ')
    const {isLoading, data, isSuccess} = useGetQuestionnaires(url)
    const [testSteps, setTestSteps] = useState(0)
    const [startTest, setStartTest] = useState(true)
    const [webCamImages, setWebCamImages] = useState([]);
    const [screenShotsImages, setScreenShotsImages] = useState([]);
    const [totalTestTime, setTotalTestTime] = useState(null)
    const videoRef = useRef(null);
    const videoRefPreview = useRef(null);
    const canvasRef = useRef(null);
    const screenRef = useRef(null);
    const screenCanvasRef = useRef(null);
    const intervalIdRef = useRef(null);
    const [displayPrev, setDisplayPrev] = useState(false)
    const [isContinue, setIsContinue] = useState(false)
    const [webcamStream, setWebcamStream] = useState(null);
    const [screenShareStream, setScreenShareStream] = useState(null);
    const [changeTab, setChangeTab] = useState(false);
    const [isShareScreen, setIsShareScreen] = useState(false)
    const [isPermission, setIsPermission] = useState(false)
    const [settingUpDevice, setSettingUpDevice] = useState(false)

    /*
    * This Function Initializes the Screen and Web Recording
    * It Initializes the Screen Recording process
    * If the User Shares only a window or tab the initialization process will stop and the
    * Resources will be cleaned up
    * Else it Proceeds
    *
    * */
    const handleStartWebcamAndScreenShare = async () => {

        if (!videoRef?.current?.srcObject) {
            const constraints = {
                video: true,
            };
            try {
                setSettingUpDevice(true)
                const screenStreamData = await navigator.mediaDevices.getDisplayMedia({
                    video: {
                        displaySurface: "monitor",
                        cursor: "always",
                    },
                });
                const screenTrack = screenStreamData.getVideoTracks()[0];
                if (screenTrack?.label === "screen:0:0") {
                    // If User Shares the Entire Screen then Initialize the webcam recording
                    const webcamStream = await navigator.mediaDevices.getUserMedia(constraints);
                    setSettingUpDevice(false)
                    setWebcamStream(webcamStream); // Store webcam stream in state
                    videoRef.current.srcObject = webcamStream;
                    videoRef.current.play();
                    videoRefPreview.current.srcObject = webcamStream;
                    videoRefPreview.current.play();

                    setScreenShareStream(screenTrack);
                    screenRef.current.srcObject = new MediaStream([screenTrack]);
                    screenRef.current.play();

                    setIsContinue(true);
                } else {
                    setDisplayPrev(false)
                    setIsShareScreen(true)
                    setSettingUpDevice(false)
                    screenStreamData.getTracks().forEach(track => track.stop());
                }

            } catch (error) {
                setIsPermission(true)
                setDisplayPrev(false)
                setSettingUpDevice(false)
            }
        }
    };

    /*
    * This Function Handles the actual Screen Shot taking process of the WebCam*/
    const captureWebCamImage = () => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const imageDataURL = canvas.toDataURL('image/png');
            setWebCamImages((prevImages) => [...prevImages, imageDataURL]);
        }
    };

    /*
    * Handling of the Screen Shot Taking
    * */
    const takeBrowserScreenShots = () => {
        if (screenRef.current && screenCanvasRef.current) {
            const screenVideo = screenRef.current;
            const canvas = screenCanvasRef.current;
            canvas.width = screenVideo.videoWidth;
            canvas.height = screenVideo.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(screenVideo, 0, 0, canvas.width, canvas.height);
            const imageDataURL = canvas.toDataURL('image/png');
            setScreenShotsImages((prevImages) => [...prevImages, imageDataURL]);
        }
    };

    /*
    * This function Initiates the Screen and Webcam Sharing Process
    * */
    const handleFirstCapture = () => {
        handleStartWebcamAndScreenShare().then()
        setDisplayPrev(true)
        setIsPermission(false)
        setIsShareScreen(false)
    };

    /*
    * Function to handle resources cleaning if not in use
    * */
    const handlePageUnload = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        if (webcamStream) {
            webcamStream.getTracks().forEach((track) => track.stop());
            setWebcamStream(null)
        }
        if (screenShareStream) {
            const screenTrack = screenShareStream;
            const screenVideo = new MediaStream([screenTrack]);
            screenVideo.getTracks().forEach((track) => track.stop());
        }

    };

    useEffect(() => {
        /*
        * During the Test Taking Process the
        * Screenshots are taking at intervals
        *
        * */
        if (testSteps === 1 && isContinue) {
            takeBrowserScreenShots();
            captureWebCamImage();

            const shotsInterval = (totalTestTime * 60000) / 10
            intervalIdRef.current = setInterval(() => {
                takeBrowserScreenShots();
                captureWebCamImage();
            }, shotsInterval);
        }
        document.addEventListener("visibilitychange", function () {
            if (document.hidden) {

            } else {
                setChangeTab(true)
            }
        });
    }, [testSteps]);

    const handleTestTaking = () => {
        setTestSteps(1)
        handleStartTest()
    }

    useEffect(() => {
        if (isSuccess) {
            const totalQuestions = data?.data?.data?.questions.length
            const totalTimeCal = Math.round(totalQuestions * 35 / 60) - 1
            setTotalTestTime(totalTimeCal)
        }

    }, [isSuccess])

    useEffect(() => {
        window.addEventListener('beforeunload', handlePageUnload);

        return () => {
            window.removeEventListener('beforeunload', handlePageUnload);
        };
    }, []);

    if (isMobile) {
        return (
            <NoTestOnMobileModal/>
        )
    }

    if (isLoading) {
        return (
            <ShowLoading/>
        )
    }

    const handleStartTest = () => {
        setStartTest(false)
    }

    return (
        <>

            <div>
                <video ref={videoRef} style={{width: '100%', height: 'auto',  display: 'none'}}/>
                <canvas ref={canvasRef} style={{width: '100%', height: 'auto', display: 'none'}}/>
                <video ref={screenRef} autoPlay style={{display: 'none'}}/>
                <canvas ref={screenCanvasRef} style={{display: 'none'}}/>
            </div>
            {
                testSteps === 0 &&
                <AssessmentTips
                    setTestSteps={setTestSteps}
                    title={data?.data?.data?.title}
                    handleStartTest={handleStartTest}
                    setWebCamImages={setWebCamImages}
                    setScreenShotsImages={setScreenShotsImages}
                    testSteps={testSteps}
                    totalTestTime={totalTestTime}
                    displayPrev={displayPrev}
                    videoRef={videoRefPreview}
                    handleFirstCapture={handleFirstCapture}
                    isContinue={isContinue}
                    handleTestTaking={handleTestTaking}
                    isShareScreen={isShareScreen}
                    isPermission={isPermission}
                    settingUpDevice={settingUpDevice}

                />
            }
            {
                testSteps === 1 &&
                <>
                    <TimedQuestionnaireFormTemp
                        data={data}
                        title={data?.data?.data?.title}
                        caption={"Exciting to have you here. Get Started"}
                        startTest={startTest}
                        webCam={webCamImages}
                        screenShots={screenShotsImages}
                        totalTimeCal={totalTestTime}
                        changeTab={changeTab}
                        handlePageUnload={handlePageUnload}
                    />

                </>
            }

        </>

    )
}

export default TimedQuestionnaireLayout
