import * as Yup from 'yup'

export const UploadTestimonialSchema = Yup.object().shape({
    fullName: Yup.string().required('Full name is required'),
    jobTitle: Yup.string().required('Job title is required'),
    testimonial: Yup.string().required('Testimonial is required')
                    .min(120, "Testimonials must be at least 120 characters")
                    .max(300, "Testimonials must be less than 300 characters"),
    avatar: Yup.mixed().typeError('Avatar is Required').test("required", "You need to provide an Image", (value) => {
        return value?.length || false
    }),
})
