import React from 'react';
import {CButton, CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import SponsorsImage from '../../assets/images/sponsors-image.jpg';
import SponsorTopImage from '../../assets/images/sponsor-top-left.png';
import SponsorBottomImage from '../../assets/images/sponsor-bottom-right.png';

const SponsorsSection = () => {
    return <CContainer className="p-5">
        <CRow className="my-4">
            <CCol md={6} className="d-flex flex-column justify-content-center align-content-center">
                <h1 className="text-primary-dark">Sponsors</h1>
                <div className="p-5 my-2 bg-primary-dark text-white border-radius-12">
                    <h5><strong>Sponsor a Program/Internship</strong></h5>
                    <p className="mt-2">Create an account, browse available opportunities for
                        sponsorships</p>
                </div>
                <div className="p-5 my-2 bg-primary-dark text-white border-radius-12">
                    <h5><strong>Sponsorship Management</strong></h5>
                    <p className="mt-2">Keep track of sponsored programs/training, view your transaction
                        records and generate financial reports to assess sponsorship impact.</p>
                </div>
                <Link to="/employer/signup">
                    <CButton className="btn btn-primary text-white smaller-text-12 w-auto py-3 px-5 mt-3 my-sm-3">GET STARTED</CButton>
                </Link>
            </CCol>
            <CCol md={1}></CCol>
            <CCol md={5}>
                <div>
                    <CImage src={SponsorTopImage} alt="Sponsor top image" className="sponsor-top-image" />
                    <CImage src={SponsorsImage} alt="Sponsors" className="w-100"/>
                    <CImage src={SponsorBottomImage} alt="Sponsor bottom image" className="sponsor-bottom-image" />
                </div>
            </CCol>
        </CRow>
    </CContainer>
}

export default SponsorsSection;