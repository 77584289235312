import React, {useState} from 'react';
import DeviceSetup from "./DeviceSetup";
import AssessmentPrompt from "./AssessmentPrompt";

function AssessmentTips({
                            setTestSteps,
                            title,
                            displayPrev,
                            videoRef,
                            handleFirstCapture,
                            isContinue,
                            handleTestTaking,
                            isShareScreen,
                            isPermission,
                            settingUpDevice

                        }) {

    const [setupSteps, setSetupSteps] = useState(0);

    return (
        <div className={'bg-white  w-75 p-5 mx-auto '}>
            {
                setupSteps === 0 &&
                <AssessmentPrompt setSetupSteps={setSetupSteps} title={title}/>
            }
            {
                setupSteps === 1 &&
                <DeviceSetup
                    setTestSteps={setTestSteps}
                    displayPrev={displayPrev}
                    videoRef={videoRef}
                    handleFirstCapture={handleFirstCapture}
                    isContinue={isContinue}
                    handleTestTaking={handleTestTaking}
                    isShareScreen={isShareScreen}
                    isPermission={isPermission}
                    settingUpDevice={settingUpDevice}
                />
            }
        </div>
    );
}

export default AssessmentTips;