import React, {useEffect, useState} from 'react'
import {CAlert, CButton, CContainer, CForm, CLoadingButton} from '@coreui/react-pro'
import {useForm} from 'react-hook-form'
import ErrorReports from '../../../components/ErrorReports'
import {fail_notify} from '../../../constant'
import {useLevelOneAnswerQuizQuery} from '../../../hooks/user/userApiHooks'
import useQuestionnaire from '../../../hooks/user/questionnaire'
import {useNavigate} from 'react-router-dom'
import {useAppContext} from '../../../context/user/UserAppProvider'
import TestCompleteModal from '../../../components/modals/TestCompleteModal'
import MultiStepLine from "./MultiStepLine";
import GeneralQuestionnaireWelcomeModal from "../../../components/modals/GeneralQuestionnaireWelcomeModal";
import {modalPrompts} from "../../../constant/modalPrompts";
import DOMPurify from "dompurify";
import SingleChoice from "./SingleChoice";
import MultipleChoice from "./MultipleChoice";

const UnTimedQuestionnaireFormTemp = ({data, title, caption, url}) => {
    const {header} = useAppContext()
    const {handleSubmit, control,} = useForm()
    const navigate = useNavigate()
    const {userRole} = useAppContext()
    const [showSectionPrompt, setShowSectionPrompt] = useState(false)
    const modalPrompt = modalPrompts[url].sections
    const [currentSectionTitle, setCurrentSectionTitle] = useState({})
    const [passedSteps, setPassedSteps] = useState([])
    const romanNumerals = ["i", "ii", "iii", "iv", "v", "vi"]

    const {
        totalSteps,
        step,
        disablePrev,
        setQuestionnaireError,
        confirmAnswers,
        handleNextWithValidation,
        questionnaireError,
        handlePrev,
        questionsChunk,
        submitAnswer,
        questionnaireChecker,
        sections,
        sectionNumber,
        currentSection,
        allSectionAnswers
    } = useQuestionnaire(data)

    const {
        mutate,
        isSuccess: isSuccessQuestionnaire,
        isError: isErrorQuestionnaire,
        error: errorQuestionnaire,
        isLoading: isLoadingQuestionnaire,
    } = useLevelOneAnswerQuizQuery()

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setPassedSteps((prev) => [...prev, sectionNumber - 1])
    }, [sectionNumber, currentSection])

    useEffect(() => {
        if ((sectionNumber !== sections) && modalPrompt) {
            const titleJoin = currentSection.split(" ").join("").toLowerCase()
            setCurrentSectionTitle(modalPrompt[titleJoin])
            setShowSectionPrompt(true)
        }
    }, [currentSection, sections])

    useEffect(() => {
        const isAnswered = questionnaireChecker(title)
        // Redirect to Quality Assurance
        if (isAnswered && !isSuccessQuestionnaire) {
            if (userRole === "NASIMS") {
                navigate(`/user/questionnaire/Quality-Assurance`, {
                    replace: true,
                })
            } else {
                navigate(`/user/choose-skill-set`, {
                    replace: true,
                })
            }
        }
        if (isSuccessQuestionnaire) {
            setVisible(true)
        }

    }, [isSuccessQuestionnaire, questionnaireChecker])

    const handleQuestionnaire = (data) => {

        setQuestionnaireError("")
        if (confirmAnswers().length === questionsChunk.length) {
            const currentAnswers = Object.values(data)
            const answers = currentAnswers.concat(allSectionAnswers)
            mutate({answers, quizTitle: `${title}`, header})
        } else {
            fail_notify('One or More Fields are not Answered.')
            setQuestionnaireError("One or More Fields are not Answered.")
        }
    }
    return (

        <CContainer>
            <TestCompleteModal
                title={`${data?.data?.data?.title}`}
                setVisible={setVisible}
                visible={visible}
                role={userRole}
            />
            {
                currentSectionTitle &&
                <GeneralQuestionnaireWelcomeModal
                    visible={showSectionPrompt}
                    setVisible={setShowSectionPrompt}
                    title={currentSectionTitle?.title}
                    text={currentSectionTitle?.msgContent}
                />
            }

            <CForm className="w-100" onSubmit={handleSubmit(handleQuestionnaire)}>
                {
                    currentSectionTitle?.title &&
                    <div className={'front-one mb-2'}>
                        <MultiStepLine totalSteps={sections} activeStep={sectionNumber} passedSteps={passedSteps}/>
                    </div>

                }
                <div
                    className="bg-white w-100 min-vh-100 border-radius-12 py-3 px-3 d-flex flex-column  position-relative box-shadow">


                    <p className="text-medium-emphasis front-one mb-0 text-center">
                        {caption}
                    </p>
                    <h3 className="text-capitalize fw-bold front-one  text-title main-title_green ">
                        {title === "Level One Questionnaire" ? " General Questionnaire" : title}
                    </h3>

                    {
                        currentSectionTitle &&
                        <div className="d-flex flex-column justify-content-center align-content-center ">
                            <div className={'border front-one w-40 mx-auto mb-4 bg-green '}>
                                {(currentSection && currentSection !== "null") &&
                                    <p className=" front-one text-title text-white  ">
                                        {currentSection}
                                    </p>
                                }
                            </div>
                        </div>

                    }

                    <div className="  d-flex flex-column align-items-center ">
                        {questionnaireError && <CAlert color="danger">{questionnaireError}</CAlert>}
                        {isErrorQuestionnaire && <ErrorReports error={errorQuestionnaire}/>}
                        {isLoadingQuestionnaire && <p> Submitting Questionnaire</p>}
                    </div>

                    {
                        questionsChunk?.map((question, index) => (
                            <div className=" mb-4 w-100 px-5" key={index}>
                                <div className="d-flex gap-2">
                                    {question.i}.
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question?.question)}}>

                                    </div>
                                </div>
                                {
                                    question.isMultipleChoice ? <MultipleChoice
                                            submitAnswer={submitAnswer}
                                            question={question}
                                            romanNumerals={romanNumerals}
                                            control={control}
                                            index={index}
                                        /> :
                                        <SingleChoice
                                            submitAnswer={submitAnswer}
                                            question={question}
                                            romanNumerals={romanNumerals}
                                            control={control}
                                            index={index}
                                        />
                                }

                            </div>))
                    }

                    <CContainer className="d-flex justify-content-between w-75 front-one">
                        <CButton
                            className="d-flex justify-content-around reg-btn  align-items-center"
                            onClick={handlePrev}
                            disabled={disablePrev}
                            type={'button'}
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                                    fill="#3F3D3D"
                                />
                            </svg>
                            <p className="m-0 text-black">Previous</p>
                        </CButton>
                        {
                            (step < totalSteps || sectionNumber < sections)
                            && (
                                <CLoadingButton
                                    className="d-flex justify-content-around reg-btn align-items-center"
                                    onClick={handleNextWithValidation}

                                    type={'button'}
                                >
                                    <p className="m-0">Next</p>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.59 16.58L13.17 12L8.59 7.41L10 6L16 12L10 18L8.59 16.58Z"
                                            fill="white"
                                        />
                                    </svg>
                                </CLoadingButton>)
                        }
                        {
                            (step === totalSteps && sectionNumber === sections) &&
                            (<CLoadingButton
                                className="d-flex justify-content-around reg-btn align-items-center"
                                type={'submit'}
                                loading={isLoadingQuestionnaire}
                                disabled={isLoadingQuestionnaire}
                            >
                                <p className="m-0">Submit</p>
                            </CLoadingButton>)
                        }
                    </CContainer>
                </div>
            </CForm>
        </CContainer>
    )
}

export default UnTimedQuestionnaireFormTemp
