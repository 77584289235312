import React from 'react';
import {CContainer} from "@coreui/react-pro";

function ShowLoading() {
    return (
        <>
            <CContainer className="h-50 font-color d-flex justify-content-center align-items-center">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </CContainer>
        </>
    );
}

export default ShowLoading;