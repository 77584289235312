import React from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import TrainingForm from "../../components/employer/Training/TrainingForm";

function AddJobPosting() {

    return (
        <CContainer className="mb-xxl-5">
            <CRow>
                <CCol>
                    <h3 className="sub-title"> Create a Training</h3>
                </CCol>

            </CRow>
            <hr/>
            <CRow className="d-flex flex-row justify-content-center">

                <CCol sm={8} >
                    <TrainingForm width={12} />
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default AddJobPosting;