import * as Yup from 'yup'

export const SponsorshipApplicationSchema = Yup.object().shape({
    financialStatement: Yup.mixed(),
    anyScholarship: Yup.string(),
    personalStatement: Yup.string(),
    letterOfRecommendation: Yup.mixed(),
    applicantCV: Yup.string(),
    additionalInformation: Yup.string(),
    consent: Yup.bool()
})
