import React from 'react'
import moment from "moment";

import {CCard, CCol, CImage, CRow} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";
import SaveJob from "./SaveJob";
import {formatCurrency} from "../../constant";
import logo from "../../assets/brand/WN-logo.svg";
import {isMobile} from "react-device-detect";

function JobsCard({data, isJobApplied, isJobSaved, page, limit, filter, search}) {

    const {user, userId} = useAppContext()

    const ApplyButton = ({user, job}) => {
        return (user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) ?
            <Link to={`/employer/job-detail/${job?.slugUrl}`}
                  className="text-decoration-none w-100">
                <button className="btn btn-primary btn-sm text-white w-100">VIEW JOB
                </button>
            </Link> :
            <Link
                to={user?.accessToken ? `/user/job-detail/${job?.slugUrl}` : `/job-detail/${job?.slugUrl}`}
                className="text-decoration-none w-100">
                {
                    !isJobApplied &&
                    <button className="btn btn-primary btn-sm text-white w-100">
                        <small>{(job?.applyForJob?.length === 0 || (job?.applyForJob?.filter(user => user?.userId === userId).length === 0 || job?.applyForJob?.filter(user => user?.userId === userId)[0]?.isSave)) ? "APPLY FOR JOB" : "VIEW JOB"}</small>
                    </button>
                }
                {
                    isJobApplied &&
                    <button className="btn btn-primary btn-sm text-white w-100">
                        VIEW JOB
                    </button>
                }
            </Link>
    }

    return (
        <>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 3}} className="g-3">
                {data?.map((userJob, index) => {
                    let job
                    if (userJob?.job) {
                        job = userJob.job
                    } else {
                        job = userJob
                    }

                    const setDefaultSrc = (e) => {
                        e.target.src = logo
                    }

                    return (
                        <CCol key={index}>
                            <CCard className="h-100 job-card">
                                <CRow className="p-2">
                                    <CCol md={4}
                                          className="d-flex flex-column justify-content-between align-items-center">
                                        <div
                                            className="bg-primary bg-opacity-10 h-75 d-flex justify-content-center align-items-center w-100 img-div">
                                            <CImage onError={(e) => setDefaultSrc(e)}
                                                    src={job?.stakeholder?.logoUrl || logo} alt="Logo"
                                                    className="job-image"/>
                                        </div>
                                        {!isMobile && <ApplyButton user={user} job={job}/>}
                                    </CCol>
                                    <CCol md={8} className="d-flex flex-column justify-content-between ">
                                        <div
                                            className="d-flex flex-row justify-content-between align-items-center pb-4">
                                            <div className="text-truncate" style={{maxWidth: "93%"}}>
                                                <h6 className="text-truncate"><b
                                                    className="text-truncate">{job?.title}</b></h6>
                                                <p className="text-truncate">{job?.stakeholder?.name}, {job?.location}</p>
                                            </div>
                                            {
                                                (!(user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) && (!isJobSaved && !isJobApplied)) &&
                                                <span>{(job?.applyForJob?.length === 0 || (job?.applyForJob?.filter(user => user?.userId === userId).length === 0 || job?.applyForJob?.filter(user => user?.userId === userId)[0]?.isSave === true)) &&
                                                    <SaveJob search={search} page={page} limit={limit}
                                                             filter={filter} job={job}/>}</span>
                                            }
                                        </div>
                                        <div>
                                            <button
                                                className="btn btn-ghost-primary btn-outline-primary btn-sm text-primary p-1 disabled"
                                                style={{fontSize: '10px'}}>{job?.levelOfExperience}</button>
                                            <div
                                                className="d-flex flex-row justify-content-between align-items-center pt-1">
                                                <p><b>{job?.jobType}</b></p>
                                                <h6>
                                                    {(job.salary && job.salary !== "Negotiable") ? <>&#8358;{formatCurrency(job?.salary)}</> :
                                                        <small>Negotiable</small>}
                                                </h6>
                                            </div>
                                            <p>Date posted: {moment(job.createdAt).format("MMM Do YYYY")}</p>
                                        </div>
                                        {isMobile && <ApplyButton user={user} job={job} />}
                                    </CCol>
                                </CRow>
                            </CCard>
                        </CCol>
                    )
                })}
            </CRow>

        </>
    );
}

export default JobsCard;
