import {CCol, CImage, CRow} from "@coreui/react-pro";
import React from "react";
import HRIcon from "../../assets/images/shared-profile-hr.png";
import {BiArrowToRight} from "react-icons/bi";

const UserProfilePersonalInfo = ({userProfileData}) => {

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <h6 className="fw-bold">Professional Skills</h6>
            </div>
            <CImage src={HRIcon} style={{width: '100%'}}/>
            <CRow className="my-4">
                <CCol md={4}>
                    <h6 className="fw-bold">Education</h6>
                    <p>{userProfileData?.educations?.map(({degree, schoolName, fieldOfStudy}, index) => (
                        <span key={index}>
                            <BiArrowToRight/> {schoolName}, {degree}, {fieldOfStudy}
                        </span>
                    )) || 'Nil'}</p>
                </CCol>
                <CCol md={4}>
                    <h6 className="fw-bold">Work Experience</h6>
                    <p>{userProfileData?.workExperience?.map(({companyName, jobTitle, location}, index) => (
                        <span key={index}>
                            <BiArrowToRight/> {companyName}, {jobTitle}, {location}
                        </span>
                    )) || 'Nil'}</p>
                </CCol>
                <CCol md={4}>
                    <h6 className="fw-bold">Occupation</h6>
                    <p>{userProfileData?.occupation || 'Nil'}</p>
                </CCol>
            </CRow>
            <CRow className="my-4">
                <CCol md={4}>
                    <h6 className="fw-bold">Disability</h6>
                    <p>{userProfileData?.disability || 'Nil'}</p>
                </CCol>
            </CRow>
        </>
    )
}

export default UserProfilePersonalInfo