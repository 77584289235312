import React, {useState} from "react";
import {CFormInput, CLoadingButton} from "@coreui/react-pro";
import {SiMicrosoftexcel} from "react-icons/si";
import {BsCloudUpload} from "react-icons/bs";

const UploadTest = () => {
    const [excelFile, setExcelFile] = useState('')

    const handleFileSelect = (e) => {
        const fileName = e.target.files[0].name
        setExcelFile(fileName)
    }
    return (
        <>
            <center>
                <label className="border border-1 cursor-pointer border-dark rounded-3 p-5 mt-5"
                       htmlFor="avatar">
                    <SiMicrosoftexcel size={100} className=""/> <br/> Click to select your question
                    <br/>{excelFile}
                </label>
                <br/>
                <CLoadingButton disabled={!excelFile}
                                className="text-white mt-1 px-5 w-100"><BsCloudUpload/> Upload
                    File</CLoadingButton>
                <CFormInput
                    type="file"
                    id="avatar"

                    className="custom-file-input"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileSelect}
                    style={{maxWidth: '100vh'}}
                />
            </center>
        </>
    )
}

export default UploadTest
