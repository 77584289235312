import React, {useEffect, useState} from 'react'
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import MostRecentJobCard from "../../components/job/MostRecentJobCard";
import JobDetailSection from "../../components/job/JobDetailSection";
import {useGetjobDetailQuery, useGetJobsQuery, useSaveOrApplyJobQuery} from "../../hooks/user/userApiHooks";
import {useNavigate, useParams} from "react-router-dom";
import {fail_notify, success_notify} from "../../constant";
import AuthRoute from "../../components/User/AuthRoute";
import ErrorReports from "../../components/ErrorReports";
import ShowLoading from "../../components/ShowLoading";
import GoBack from "../../components/GoBack";
import {useAppContext} from "../../context/user/UserAppProvider";

function JobDetail() {
    const { slug } = useParams();
    const [save, setSave] = useState(false);
    const [applied, setApplied] = useState(false);
    const jobs = useGetJobsQuery(1, 5, "most recent")
    const {storeUser, user,} = useAppContext()
    const jobDetail = useGetjobDetailQuery(slug, user?.data.id)
    const navigate = useNavigate()

    const { isError:JobIsError,
        isSuccess:JobIsSuccess,
        isLoading:JobIsLoading,
        error:JobError,
        data:JobData,
        mutate } = useSaveOrApplyJobQuery(slug)


    useEffect(() => {
        if (JobIsSuccess && !save) {
            setApplied(true)
            success_notify('Job application successful')
        }
        if (JobIsSuccess && save) {
            success_notify('Job saved successfully')

        }
        if (JobIsError) {
            fail_notify(JobError?.response?.data?.message)
        }
        storeUser(user)

    }, [save, JobIsError, JobIsSuccess, JobIsLoading, JobError, JobData, mutate])

    const onSaveJob = (jobId, isSave) => {
        if(!user?.accessToken){
            localStorage.setItem("redirectUrl", "/job-detail/"+slug);
            navigate(`/login`, {
                replace: true,
            })
            return
        }

        const data = {
            jobId:jobId,
            isSave: isSave
        }
        mutate(data);

    }

    if (jobs.isError || jobDetail.isError) {
        return <ErrorReports error={"Oops Something went wrong"}/>
    }

    if (jobs.isLoading || jobDetail.isLoading) {
        return <ShowLoading/>
    }

    return (
        <>
            <AuthRoute protectedRoute={'/user/job-detail/'} openRoute={'/job-detail/'}/>
                <CContainer>
                    {!user?.accessToken && <br/>}
                    <GoBack/>
                    <br/>
                    <CRow className="d-flex flex-column-reverse flex-lg-row">
                        {(!(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) && <CCol lg={4} className="px-0">
                            <div className="job-detail-div">
                                <h2 className="text-2 fw-bold text-center">Most Recent</h2>
                                <br/>
                                <MostRecentJobCard save={save} setSave={setSave} JobIsLoading={JobIsLoading}
                                                   onSubmit={onSaveJob} jobs={jobs}/>
                            </div>

                        </CCol>}

                        <CCol lg={user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy')) ? 12 : 8} className="px-0">
                            <div className="job-detail-div h-100 mb-lg-4" id="top">
                                <JobDetailSection
                                    user={user}
                                    save={save}
                                    setSave={setSave}
                                    JobIsLoading={JobIsLoading}
                                    jobDetail={jobDetail}
                                    mutate={mutate}
                                    slug={slug}
                                    applied={applied}
                                    JobIsSuccess={JobIsSuccess}
                                />
                            </div>
                            <br/>
                        </CCol>
                    </CRow>
                </CContainer>
        </>
    )
}

export default JobDetail
