import React, {useEffect, useState} from 'react';
import {MdEmail} from "react-icons/md";
import {BsTelephoneInboundFill} from "react-icons/bs";
import {FaMapMarkerAlt} from "react-icons/fa";
import DOMPurify from "dompurify";
import {hasText, isDisplay} from "../../../services/cvbuilder/cvBuilderHelpers";

function VerticalBrownResume({
                                 educationsData,
                                 experienceData,
                                 achievementsData,
                                 skillsData,
                                 personalData,
                                 languagesData,
                                 publicationsData,
                                 mainColor = '#510505'
                             }) {

    const hasSkills = hasText(skillsData?.skills);
    const hasPublications = hasText(publicationsData?.publications);
    const hasLanguages = hasText(languagesData?.languages);
    const hasObjective = hasText(personalData?.careerObjective);
    const hasAchievements = hasText(achievementsData?.achievements);
    const [hasEducation, setHasEducation] = useState(false);
    const [hasExperience, setHasExperience] = useState(false);

    useEffect(() => {
        if (experienceData?.workExperience?.length > 0) {
            setHasExperience(isDisplay(experienceData?.workExperience));
        }
        if (educationsData?.educations?.length > 0 && educationsData?.educations[0] !== undefined) {
            setHasEducation(isDisplay(educationsData?.educations));
        }
    }, [experienceData, educationsData])

    return (
        <>
            <div className="bg-primary mx-auto bg-white d-flex flex-row flex-wrap main-content">
                <div className="col-3 min-vh-100 left pb-5 px-1 ">
                    <div>
                        <div className="mt-5 d-flex flex-column main-title-side text-center">
                            <h5 className=" cv-name "> {personalData?.firstName?.toUpperCase()}</h5>
                            <h5 className=" cv-name">{personalData?.lastName?.toUpperCase()}</h5>
                            <p>{personalData?.profession}</p>
                        </div>
                        <div className="px-3">
                            {
                                hasSkills &&
                                <div className="mt-2">
                                    <h5 className="justify-content-start main-title-side">SKILLS</h5>

                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(skillsData?.skills)}}
                                    >
                                    </div>
                                </div>
                            }
                            {
                                hasLanguages &&
                                <div className="mt-2">
                                    <h5 className="justify-content-start main-title-side">Languages</h5>
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(languagesData?.languages)}}
                                    >
                                    </div>

                                </div>
                            }
                            {
                                hasEducation &&
                                <div className="mt-2">
                                    <h5 className="justify-content-start main-title-side">Education</h5>
                                    {
                                        educationsData?.educations?.map((edu, index) => {
                                            return <div key={index}>
                                                <h5 className="sub-title-cv">{edu.schoolName}</h5>
                                                <li className="p-cv">{edu.degree} {edu.fieldOfStudy}</li>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-9 px-5 pb-10 d-flex  flex-column ">
                    <div className="d-flex flex-row ">
                        <div className="w-25"></div>
                        <div className="rect-contact pt-3 justify-content-center text-center ">
                            {
                                personalData?.phoneNumber && <p className={'py-1'}><BsTelephoneInboundFill/></p>
                            }
                            {
                                personalData?.email && <p className={'py-1'}><MdEmail/></p>
                            }
                            {
                                personalData?.address && <p className={'py-1'}><FaMapMarkerAlt/></p>
                            }
                        </div>
                        <div className="pt-3 px-2">
                            {
                                personalData?.phoneNumber && <p className={'py-1'}>{personalData?.phoneNumber}</p>
                            }
                            {
                                personalData?.email && <p className={'py-1'}>{personalData?.email}</p>
                            }
                            {
                                personalData?.address && <p className={'py-1'}>{`${personalData?.address}, 
                                         ${personalData?.city}, ${personalData?.nationality}`} </p>
                            }
                        </div>
                    </div>
                    {
                        hasObjective &&
                        <div className="mt-2">
                            <h5 className="main-title-cv"> CAREER  &nbsp;  &nbsp; OBJECTIVE</h5>
                            <p className={"text-justify"}
                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(personalData?.careerObjective)}}
                            >

                            </p>
                        </div>
                    }
                    <div className="mt-2">
                        <h5 className="main-title-cv"> EXPERIENCE</h5>
                        {
                            experienceData?.workExperience?.map((item, index) => {
                                return <div key={index} className="mb-3 ">
                                    <h5 className="sub-title-cv">{item?.jobTitle}</h5>
                                    {item?.companyName && <p>
                                        {`${item.companyName}  |  ${item.startDate
                                        } ${item.startMonth} - ${item.endDate ? item.endDate + " " + item.endMonth : "Till Date"}`}
                                    </p>}
                                    <p className={"text-justify"}
                                       dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item?.description)}}
                                    >

                                    </p>

                                </div>

                            })
                        }

                    </div>

                    {
                        hasAchievements &&
                        <div className="mt-2">
                            <h5 className="main-title-cv"> ACHIEVEMENTS</h5>
                            <div className="mb-3">
                                <p className={"text-justify"}
                                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(achievementsData?.achievements)}}
                                >
                                </p>
                            </div>

                        </div>
                    }
                    {
                        hasPublications &&
                        <div className="mt-2">
                            <h5 className="main-title-cv"> PUBLICATIONS</h5>
                            <div className="mb-3">
                                <p className={"text-justify"}
                                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(publicationsData?.publications)}}
                                >

                                </p>

                            </div>
                        </div>
                    }
                    {/*CV Footer*/}
                    {/*<CVFooter/>*/}
                </div>
                <style>
                    {
                        `
.rect-dash {
  width: 40.88px;
  height: 5.59px;
  left: -3.24px;
  top: 58.24px;
  background:  ${mainColor};
}

.rect-contact {
  width: 25.88px;
  left: -3.24px;
  top: 58.24px;
  background: ${mainColor};
  color: #FFFFFF;
}

.left {
  background: ${mainColor};
  color: #FFFFFF;
  break-inside: avoid;
  page-break-inside: avoid;
}

.main-content {
  font-size: 12px;
  word-wrap: break-word;
}

.mr {
  margin-right: 20px;
}

.ml {
  margin-left: 20px;
}

.h-60 {
  height: 60% !important;

}

.main-title-cv {
  font-size:15px;
  color:  ${mainColor};
  word-wrap: break-word;
}

.main-title-side {
  color:  #fff;
  word-wrap: break-word;
}
.cv-name {
  font-size:24px;
  word-wrap: break-word;
}


.sub-title-cv {
  font-size: 14px;
  font-weight: bolder;
}
 `
                    }
                </style>
            </div>
        </>
    );
}

export default VerticalBrownResume;
