import * as Yup from 'yup'

export const CreateAdminSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().matches(/^(\+)?(234|0)[0-9]{10}$/, 'Must be a valid phone number'),
  email: Yup.string().email('Must be a valid email').required('Email is required'),
  password: Yup.string().required('Password is required')
    .min(3, 'Password must be at 3 char long')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password field is mandatory')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
  role: Yup.array().required('At least one role is required').nullable(),
  permissions: Yup.array().required('At least one permission is required').nullable()
})
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const AddGovSchema = Yup.object().shape({
  gov_name: Yup.string().required('Agency name is required'),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Must be a valid phone number')
      .required(
          ("Phone number is required")
      ).typeError('Phone number is required'),
  email: Yup.string().typeError('Email is required').email('Must be a valid email').required('Email is required'),

})
