import React, {useEffect, useRef, useState} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import {useGetEmployerByUUID} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import {useNavigate, useParams} from "react-router-dom";
import {ImPhone} from "react-icons/im";
import {GoLocation} from "react-icons/go";
import {BiArrowBack, BiEnvelope} from "react-icons/bi";
import {AiOutlineCalendar} from "react-icons/ai";
import profile from "../../../assets/icons/profile.svg";
import employerPreloadedData from "../../../constant/employerPreloadedData";

const ViewEmployerProfile = () => {

    const navigate = useNavigate()
    const {uuid} = useParams()

    const {isLoading, isSuccess, data} = useGetEmployerByUUID(uuid)
    const [employerData, setEmployerData] = useState("")
    const [activeTap, setActiveTap] = useState(1)
    const profilePic = useRef(profile)
    useEffect(() => {
        if (isSuccess) {
            setEmployerData(employerPreloadedData(data?.data?.data))
        }
        profilePic.current = data?.data?.data?.user?.profile?.passportUrl
    }, [isSuccess, data, isLoading])

    if (isLoading) {
        return <ShowLoading/>
    }

    return <CContainer className="user-profile">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <h6><span className="cursor-pointer" onClick={()=>{navigate(-1)}}><BiArrowBack/>Back</span></h6>
        </div>
        {isSuccess &&
            <CRow>
                <CCol lg={4} className="shadow p-3 mb-5 bg-white rounded h-75">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex flex-row justify-content-between align-items-center my-3">
                            <h6 className="fw-bold">{`${employerData?.name}`}</h6>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="d-flex w-100 flex-column">
                            <p className="text-truncate">
                                <ImPhone size={14}/>
                                <span className="ps-1 text-truncate">
                                    {employerData?.phoneNumber || 'Nil'}
                                </span>
                            </p>

                            <p className="text-truncate mt-4">
                                <GoLocation size={16}/>
                                <span className="ps-1 text-truncate">
                                    {employerData?.state || 'Nil'}
                                </span>
                            </p>
                        </div>
                        <div className="d-flex w-50 flex-column">
                            <p className="text-truncate w-100 d-flex">
                                <BiEnvelope size={16}/>
                                <span className="ps-1 text-truncate w-100">
                                    {`${employerData?.email || 'Nil'}`}
                                </span>
                            </p>
                            <p className="text-truncate mt-4">
                                <AiOutlineCalendar size={16}/>
                                <span className="ps-1 text-truncate">
                                {employerData?.dateEstablished || 'Nil'}
                            </span>
                            </p>
                        </div>
                    </div>
                </CCol>
                <CCol lg={8} className="mb-5">
                    <div className="h-100 d-flex flex-column">
                        <div className="pb-1 flex-wrap d-flex justify-content-between">
                            <button onClick={() => {
                                setActiveTap(1)
                            }}
                                    className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'}`}>
                                <small>Basic Information</small>
                            </button>
                        </div>
                        <CCol className="shadow p-3 h-100 bg-white rounded">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="fw-bold">Basic Information</h6>
                            </div>
                            <hr/>
                            <CRow className="my-4">
                                <CCol md={4}>
                                    <h6 className="fw-bold">State</h6>
                                    <p>{`${employerData?.state || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">City</h6>
                                    <p>{`${employerData?.city || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Status</h6>
                                    <p>{`${employerData?.organizationStatus || 'Nil'}`}</p>
                                </CCol>
                            </CRow>
                            <CRow className="my-4">
                                <CCol md={4}>
                                    <h6 className="fw-bold">Institution Type</h6>
                                    <p>{`${employerData?.institutionsType || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Company Size</h6>
                                    <p>{`${employerData?.companySize || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Address</h6>
                                    <p>{`${employerData?.address || 'Nil'}`}</p>
                                </CCol>
                            </CRow>
                            <CRow className="my-4">
                                <CCol md={4}>
                                    <h6 className="fw-bold">Role</h6>
                                    <p>{`${employerData?.role || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Zip</h6>
                                    <p>{`${employerData?.zipCode || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">TIN</h6>
                                    <p>{`${employerData?.tin || 'Nil'}`}</p>
                                </CCol>
                            </CRow>
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="fw-bold">Contact Person</h6>
                            </div>
                            <hr/>
                            <CRow className="my-4">
                                <CCol md={4}>
                                    <h6 className="fw-bold">Name</h6>
                                    <p>{`${employerData?.contactPersonFirstName +' '+ employerData?.contactPersonLastName || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Phone</h6>
                                    <p>{`${employerData?.contactPersonPhoneNumber || 'Nil'}`}</p>
                                </CCol>
                                <CCol md={4}>
                                    <h6 className="fw-bold">Email</h6>
                                    <p>{`${employerData?.contactPersonEmail || 'Nil'}`}</p>
                                </CCol>
                            </CRow>
                        </CCol>
                    </div>
                </CCol>
            </CRow>
        }
    </CContainer>
}

export default ViewEmployerProfile;