import React from 'react';
import {ReactComponent as Users} from "../../../assets/icons/users.svg";
import {Link} from "react-router-dom";


function TrainingCard({detail, setProgramId, setTitle}) {
    const handleApplicants = (id, title)=>{
        setProgramId(id)
        setTitle(title)
    }
    return (

            <div className="border">
                <div className="px-2 py-1">
                    <div className='d-flex w-100 align-self-center'>
                        <div onClick={()=>{handleApplicants(detail?.id, detail?.title)}} className="cursor-pointer d-flex flex
                        align-self-center
                         circle card-bg-grey
                          justify-content-center
                           align-content-center flex-wrap">
                            <Users className="text-black px-2"/>
                        </div>

                        <div className="w-100 px-2 align-self-start">
                            <span className="cursor-pointer" onClick={()=>{handleApplicants(detail?.id, detail?.title)}}>
                            <div className="fw-bold ">{detail?.title}</div>
                            <small className=" align-self-start bg-transparent py-none px-2 rounded border border-1 border-primary text-primary">{detail?.modeOfTraining}</small>
                            <small className=" align-self-start bg-transparent py-none px-2 rounded border border-1 ms-1 border-primary text-primary">{detail?.trainingType}</small>
                            </span>
                            <Link to={`/employer/training-detail/${detail?.slugUrl}`} className="text-decoration-none">
                                <div className="job-body cursor-pointer text-end"><i>View Training Details</i></div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

    );
}

export default TrainingCard;