import React from 'react';
import CVBuilderProvider from "./CVBuilderProvider";
import CVBuilder from "./CVBuilder";

function CvGenerator() {
    return (
        <CVBuilderProvider>
            <CVBuilder/>
        </CVBuilderProvider>

    );
}

export default CvGenerator;