import React, {useEffect} from 'react'
import {CContainer, CSpinner,} from '@coreui/react-pro'
import 'react-datepicker/dist/react-datepicker.css'
import {useGetNasimUser} from "../../hooks/user/userApiHooks";
import {useLocation, useNavigate} from "react-router-dom";
import RegistrationForm from "./RegistrationForm";
import ErrorReports from "../../components/ErrorReports";
import {fail_notify} from "../../constant";

const Registration = () => {

    const Location = useLocation()
    const searchParams = new URLSearchParams(Location.search)
    const token = searchParams.get('token')
    const id = searchParams.get('id')
    const isRegistered = searchParams.get('register')
    const navigate = useNavigate()
    const {isError, isLoading, data, error} = useGetNasimUser({token, id})

    //Redirect if Already Registered
    useEffect(() => {
        if (isRegistered === "true") {
            navigate(`/login`, {
                replace: true,
            })
        }
    }, [])

    //Show Loading When Downloading Data
    if (isLoading) {
        return (
            <CContainer className=" font-color vh-100 d-flex justify-content-center align-items-center">
                <div
                    className=" ">
                    <div><CSpinner variant="grow"/></div>
                </div>
            </CContainer>)
    }

    //If Error Getting User Data Notify
    if (isError) {
        fail_notify(error?.response?.data?.message)
        return (
            <div className="vh-100 d-flex
                justify-content-center
                align-items-center"
            >
                <ErrorReports error={error}/>
            </div>
        )
    }

    //NASIM Registration form
    return (
        <RegistrationForm  {...data?.data?.data}/>
    )
}

export default Registration
