import React, {useEffect, useRef, useState} from 'react'
import {CCol, CContainer, CRow} from '@coreui/react-pro'
import {
    useGetUserAssessmentByIdQuery,
    useGetUserByUUID,
} from '../../../hooks/user/userApiHooks'
import filterPreloadedUserData from '../../../constant/userPreloadedData'
import ShowLoading from '../../../components/ShowLoading'
import UserProfileBasicInfo from '../../../components/User/UserProfileBasicInfo'
import UserProfilePersonalInfo from '../../../components/User/UserProfilePersonalInfo'
import {useParams} from 'react-router-dom'
import {ImPhone} from 'react-icons/im'
import {GoLocation} from 'react-icons/go'
import {BsGenderAmbiguous} from 'react-icons/bs'
import {BiEnvelope} from 'react-icons/bi'
import ProfileProgress from '../../../components/User/ProfileProgress'
import {AiOutlineCalendar} from 'react-icons/ai'
import ProfessionalSkills from '../../../components/User/ProfessionalSkills'
import PreferencesSection1 from '../../../components/User/PreferencesSection1'
import PreferencesSection2 from '../../../components/User/PreferencesSection2'
import profile from '../../../assets/icons/profile.svg'
import UserProfileTest from '../../../components/User/UserProfileTest'
import {Tips} from "../../../constant/testTips";
import GoBack from "../../../components/GoBack";

const ViewApplicantProfile = () => {
    const {uuid} = useParams()

    const {isLoading, isSuccess, data} = useGetUserByUUID(uuid)
    const [userData, setUserData] = useState('')
    //const tier = useGetUserTier(userData?.userId)
    const [activeTap, setActiveTap] = useState(1)
    const profilePic = useRef(profile)
    const [PT, setPT] = useState([])
    const [SKT, setSKT] = useState([])
    const [CFT, setCFT] = useState([])

    const assessment = useGetUserAssessmentByIdQuery(data?.data?.data?.user?.id)

    useEffect(() => {
        if (isSuccess) {
            setUserData(filterPreloadedUserData(data?.data?.data))
        }
        profilePic.current = data?.data?.data?.user?.profile?.passportUrl
    }, [isSuccess, data, isLoading])

    useEffect(() => {
        setPT([
            {
                data: [

                    {
                        content: 'Amiable:' + assessment?.data?.data?.data?.personalityTest?.amiablePercent,
                        tip: Tips?.personalityTest?.amiable
                    },

                    {
                        content: 'Analytical:' + assessment?.data?.data?.data?.personalityTest?.analyticalPercent,
                        tip: Tips?.personalityTest?.analytical
                    },
                    {
                        content: 'Driver:' + assessment?.data?.data?.data?.personalityTest?.driverPercent,
                        tip: Tips?.personalityTest?.driver
                    },
                    {
                        content: 'Expressive:' + assessment?.data?.data?.data?.personalityTest?.expressivePercent,
                        tip: Tips?.personalityTest?.expressive
                    },

                ],
            },
            {takeAssessment: false},
            {title: 'Personality Assessment'},
            {testType: 'Personality Test'},
        ])
        setSKT([
            {
                data: [
                    {
                        content: 'Rating:' + (assessment?.data?.data?.data?.softSkillsTest?.rating === 0 ? '0 Star' : assessment?.data?.data?.data?.softSkillsTest?.rating),
                    },
                    {
                        content: assessment?.data?.data?.data?.softSkillsTest?.skills?.['Adaptability Test'] ? 'Adaptability:' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Communication Test'] + '%' : 'Adaptability: 0%',
                    },
                    {
                        content: assessment?.data?.data?.data?.softSkillsTest?.skills?.['Communication Test'] ? 'Communication :' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Communication Test'] + '%' : 'Communication : 0%',
                    },
                    {
                        content: assessment?.data?.data?.data?.softSkillsTest?.skills?.['Problem Solving Test'] ? 'Problem Solving:' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Problem Solving Test'] + '%' : 'Problem Solving: 0%',
                    },
                    {
                        content: assessment?.data?.data?.data?.softSkillsTest?.skills?.['Relationship Building Test'] ? 'Relationship Building:' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Relationship Building Test'] + '%' : 'Relationship Building: 0%',
                    },

                ],
            },
            {takeAssessment: false},
            {title: 'Soft Skills Assessment'},
            {testType: 'Soft Skills Test'},
        ])
        setCFT([
            {
                data: [
                    {
                        content: 'Adhocracy:' + assessment?.data?.data?.data?.cultureFitTest?.adhocracyPercent,
                        tip: Tips?.cultureFitTest?.adhocracy
                    },
                    {
                        content: 'Clan:' + assessment?.data?.data?.data?.cultureFitTest?.clanPercent,
                        tip: Tips?.cultureFitTest?.clan
                    },
                    {
                        content: 'Hierarchy:' + assessment?.data?.data?.data?.cultureFitTest?.hierarchyPercent,
                        tip: Tips?.cultureFitTest?.hierarchy
                    },
                    {
                        content: 'Market:' + assessment?.data?.data?.data?.cultureFitTest?.marketPercent,
                        tip: Tips?.cultureFitTest?.market
                    },
                ],
            },
            {takeAssessment: false},
            {title: 'Culture Fitness Assessment'},
            {testType: 'Culture Fit Test'},
        ])
    }, [assessment?.data?.data?.data?.cultureFitTest?.adhocracyPercent, assessment?.data?.data?.data?.cultureFitTest?.clanPercent, assessment?.data?.data?.data?.cultureFitTest?.hierarchyPercent, assessment?.data?.data?.data?.cultureFitTest?.marketPercent, assessment?.data?.data?.data?.personalityTest?.amiablePercent, assessment?.data?.data?.data?.personalityTest?.analyticalPercent, assessment?.data?.data?.data?.personalityTest?.driverPercent, assessment?.data?.data?.data?.personalityTest?.expressivePercent, assessment?.data?.data?.data?.softSkillsTest?.rating, assessment?.data?.data?.data?.softSkillsTest?.skills, assessment.isSuccess])

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer className="user-profile">
            <div className="mb-3 w-100">
                <GoBack/>
            </div>
            {isSuccess && (
                <CRow>
                    <CCol lg={4} className="shadow p-3 mb-5 bg-white rounded h-75">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            {/*<div className="profile-pic-div">*/}
                            {/*    <img src={profilePic} id="DP" alt="Profile" className="profile-pic w-100 h-100 mb-2"/>*/}
                            {/*</div>*/}
                            <div className="d-flex flex-row justify-content-between align-items-center my-3">
                                <h6 className="fw-bold">{`${userData?.firstName} ${userData.lastName}`}</h6>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="d-flex w-100 flex-column">
                                <p className="text-truncate">
                                    <ImPhone size={14}/>
                                    <span className="ps-1 text-truncate">{userData?.phoneNumber || 'Nil'}</span>
                                </p>
                                <p className="text-truncate mt-4">
                                    <GoLocation size={16}/>
                                    <span className="ps-1 text-truncate">{userData?.stateOfResidence || 'Nil'}</span>
                                </p>

                                <p className="text-truncate mt-4">
                                    <BsGenderAmbiguous size={16}/>
                                    <span className="ps-1 text-truncate">{userData?.gender || 'Nil'}</span>
                                </p>
                            </div>
                            <div className="d-flex w-50 flex-column">
                                <p className="text-truncate w-100 d-flex">
                                    <BiEnvelope size={16}/>
                                    <span className="ps-1 text-truncate w-100">
                                        {`${userData?.email ? userData?.email : 'Nil'}`}
                                    </span>
                                </p>
                                <div className="text-truncate w-100 d-flex py-2">
                                    <span className="w-100 mt-n4">
                                      <ProfileProgress progress={userData?.profileCompleted || '0%'}/>
                                    </span>
                                </div>
                                <p className="text-truncate mt-4">
                                    <AiOutlineCalendar size={16}/>
                                    <span className="ps-1 text-truncate">{userData?.dateOfBirth || 'Nil'}</span>
                                </p>
                            </div>
                        </div>
                    </CCol>
                    <CCol lg={8} className="mb-5">
                        <div className="h-100 d-flex flex-column">
                            <div className="pb-1 flex-wrap d-flex justify-content-between">
                                <button
                                    onClick={() => {
                                        setActiveTap(1)
                                    }}
                                    className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${
                                        activeTap === 1 ? 'text-white bg-primary' : 'bg-grey'
                                    }`}
                                >
                                    <small>Basic / Professional Information</small>
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTap(2)
                                    }}
                                    className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${
                                        activeTap === 2 ? 'text-white bg-primary' : 'bg-grey'
                                    }`}
                                >
                                    <small>Professional & Skill Assessment</small>
                                </button>
                                <button
                                    onClick={() => {
                                        setActiveTap(3)
                                    }}
                                    className={`fw-normal sub-body btn-tap rounded-2 px-3 mb-2 py-2 border-0 ${
                                        activeTap === 3 ? 'text-white bg-primary' : 'bg-grey'
                                    }`}
                                >
                                    <small>Preferences</small>
                                </button>
                            </div>

                            {activeTap === 1 && (
                                <CCol className="shadow p-3 h-100 bg-white rounded">
                                    <UserProfileBasicInfo userProfileData={userData}/>
                                    <UserProfilePersonalInfo userProfileData={userData}/>
                                </CCol>
                            )}
                            {activeTap === 2 && (
                                <CCol className="shadow p-3 h-100 bg-white rounded">
                                    <ProfessionalSkills userProfileData={userData}/>
                                    <UserProfileTest data={CFT}/>
                                    <UserProfileTest data={PT}/>
                                    <UserProfileTest data={SKT}/>
                                    {/*<SkillsTest skillsTest={['user?.data?.quizTaken']}/>*/}
                                </CCol>
                            )}
                            {activeTap === 3 && (
                                <CCol className="shadow p-3 h-100 bg-white rounded">
                                    <PreferencesSection1 userProfileData={userData}/>
                                    <PreferencesSection2 userProfileData={userData}/>
                                </CCol>
                            )}
                        </div>
                    </CCol>
                </CRow>
            )}
        </CContainer>
    )
}

export default ViewApplicantProfile
