import {CImage, CRow} from "@coreui/react-pro";
import React from "react";
import UserProfileTag from "./UserProfileTag";
import HRIcon from "../../assets/images/shared-profile-hr.png";

const SkillsTest = ({skillsTest}) => {

    return (
        <div className="my-2">
            <h6 className="fw-bold">Skills Test</h6>
            <CRow className="gx-3">
                {
                    skillsTest?.map((skillTest, index) => {
                        return (
                            <UserProfileTag key={index} tag={skillTest}/>
                        )
                    })
                }
                {
                    skillsTest?.length === 0 && <UserProfileTag tag={"Nil"}/>
                }
            </CRow>
            <CImage src={HRIcon} style={{width: '100%'}}/>
        </div>
    )
}

export default SkillsTest
