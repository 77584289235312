import React, {useEffect, useState} from 'react'
import PersonalDetails from "../../components/User/cv-builder/PersonalDetails";
import Experience from "../../components/User/cv-builder/Experience";
import Education from "../../components/User/cv-builder/Education";
import {fail_notify} from "../../constant";
import ShowLoading from "../../components/ShowLoading";
import {CCol} from "@coreui/react-pro";
import Achievements from "../../components/User/cv-builder/Achievements";
import Skills from "../../components/User/cv-builder/Skills";
import Publications from "../../components/User/cv-builder/Publications";
import Languages from "../../components/User/cv-builder/Languages";
import CvBuilderHeader from "../../components/User/cv-builder/CVBuilderHeader";
import {CgTemplate} from "react-icons/cg";
import ColorFormatter from "../../components/User/cv-builder/ColorFormatter";
import {useCVBuilderContext} from "./CVBuilderProvider";
import NameCvModal from "./NameCvModal";


function CVBuilder() {

    const [visible, setVisible] = useState(false)
    const [isDownload, setIsDownload] = useState(true)

    const {
        mutate, data, isSuccess, preData,
        cvData, mainColor,
        educationsData,
        experienceData,
        achievementsData,
        skillsData,
        personalData,
        publicationsData,
        languagesData,
        setMainColor,
        Template,
        saveCVForJob,
    } = useCVBuilderContext()

    useEffect(() => {
        if (isSuccess && isDownload) {
            const blob = new Blob([data?.data], {type: 'application/pdf'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'resume.pdf'
            link.click()
        }
    }, [isSuccess])

    useEffect(() => {
        if (cvData.isError || preData.isError) {
            fail_notify('Ops!!. Something went wrong')
        }
    }, [cvData, preData])

    if (cvData.isLoading || preData.isLoading) {
        return <ShowLoading/>
    }

    const generatePdf = (data) => {
        const htmlContent = document.getElementById('resumeTemplate').innerHTML;
        const entirePageDOM = document.documentElement.outerHTML;
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(entirePageDOM, 'text/html');
        const bootstrapCssLink = document.createElement('link');
        bootstrapCssLink.rel = 'stylesheet';
        bootstrapCssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css'
        parsedDocument.head.appendChild(bootstrapCssLink);
        const rootElement = parsedDocument.getElementById('root');
        rootElement.innerHTML = htmlContent;
        const updatedRootElementContent = rootElement.outerHTML;
        parsedDocument.getElementById('root').outerHTML = updatedRootElementContent;

        if (data?.fileName) {
            setIsDownload(false)
            mutate({html: parsedDocument.documentElement.outerHTML, fileName: data.fileName})
            return
        }
        mutate({html: parsedDocument.documentElement.outerHTML})

    }

    return (
        <div className="pb-xxl-5 ">
            <CCol className="d-flex flex-row ">
                {
                    !saveCVForJob &&
                    <CCol lg="5">
                        <div className="bg-white rounded shadow cv-builder-editor px-2 px-md-5 pb-5 pt-4">
                            <CvBuilderHeader generatePdf={generatePdf}/>
                            <PersonalDetails/>
                            <Experience/>
                            <Education/>
                            <Achievements/>
                            <Skills/>
                            <Publications/>
                            <Languages/>
                        </div>
                    </CCol>
                }

                {
                    saveCVForJob &&
                    <CCol lg="5">
                        <div className="bg-white rounded shadow cv-builder-editor px-2 px-md-5 pb-5 pt-4">
                            <NameCvModal

                            />
                        </div>
                    </CCol>
                }
                <CCol lg="7"
                      className="d-flex flex-column cv-builder-editor justify-content-center align-items-center mt-3 mb-3"
                >
                    <div className="px-5" id={'resumeTemplate'}>
                        <Template
                            educationsData={educationsData}
                            experienceData={experienceData}
                            achievementsData={achievementsData}
                            skillsData={skillsData}
                            personalData={personalData}
                            languagesData={languagesData}
                            publicationsData={publicationsData}
                            mainColor={mainColor}
                        />
                    </div>
                    <br/>
                    <CCol>
                        <div className="d-flex gap-3 -mb-xxl-5">
                            <div className="text-black cursor-pointer" onClick={() => setVisible(!visible)}>
                                <CgTemplate size={30} color={'green'}/>
                                Change Color
                            </div>
                        </div>
                        {
                            visible &&
                            <ColorFormatter
                                visible={visible}
                                setVisible={setVisible}
                                setMainColor={setMainColor}
                            />
                        }
                    </CCol>

                </CCol>
            </CCol>

        </div>

    )
}

export default CVBuilder
