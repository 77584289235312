import React from 'react';
import {Controller} from "react-hook-form";
import DOMPurify from "dompurify";
import {CFormCheck} from "@coreui/react-pro";

function MultipleChoice({question, romanNumerals, submitAnswer, control, index}) {
    return (
        <div className="ps-5">
            <Controller
                control={control}
                name={`question${question.i}`}
                rules={{required: true}}
                render={({
                             field: {onChange, value},
                         }) => (
                    <>
                        {
                            question?.selections &&
                            <div>
                                {
                                    question?.selections.map((item, i) =>
                                        <p key={i} className="pb-2">
                                            {
                                                `(${romanNumerals[i]}).  ${item}`
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        }
                        {
                            question.options.map((o, i) => (
                                <div key={i} className="d-flex gap-2 center-item">
                                    <CFormCheck
                                    id={`exampleRadios${index}${i}`}
                                    value={o.id}
                                    checked={question.answer?.includes(o.id)}
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const newValue = isChecked
                                            ? [...(value || []), o.id]
                                            : (value || []).filter((id) => id !== o.id);
                                        onChange(newValue);
                                        submitAnswer(question.id, o.id);
                                    }}


                                    className="p-2 ps-3 mb-2 hov"
                                />

                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(o?.text)}}>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                )}
            />
        </div>
    );
}

export default MultipleChoice;