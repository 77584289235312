import React from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import WNLogo from '../assets/brand/WN-logo.svg';
import FaceBook from '../assets/icons/facebook.svg';
import Instagram from '../assets/icons/instagram.svg';
import Twitter from '../assets/icons/twitter.svg';
import LinkedIn from '../assets/icons/linkedin.svg';
import Youtube from '../assets/icons/youtube.svg';

import {AiFillPhone} from "react-icons/ai";
import {MdEmail} from "react-icons/md";
import {Link} from "react-router-dom";

const Footer = () => {
    return <CContainer className="p-5 p-sm-2">
        <div className="px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap gap-4">
                <div style={{maxWidth: "990px"}}>
                    <CImage src={WNLogo}/>
                    <p>Empowering the future of work in Africa.</p>
                </div>
                <div className="d-flex w-100 justify-content-start justify-content-lg-end gap-4 flex-wrap">


                    <div className="">
                        <h5 className="mb-2"><strong>Career Advice</strong></h5>
                        <p><Link to="/jobs" className="text-decoration-none small-text-gray">Finding the Right Job</Link>
                        </p>
                        <p><Link to="/login" className="text-decoration-none small-text-gray">CVs and Cover Letters</Link>
                        </p>
                        <p><Link to="/trainings" className="text-decoration-none small-text-gray">Interview Tips</Link></p>
                        <p><Link to="/jobs" className="text-decoration-none small-text-gray">Event and Job Fairs</Link></p>
                    </div>
                    <div className="">
                        <h5 className="mb-2"><strong>Benefits & Support</strong></h5>
                        <p><Link to="/trainings" className="text-decoration-none small-text-gray">Sponsorship</Link></p>
                        <p><Link to="/trainings" className="text-decoration-none small-text-gray">Starting Out In Your
                            Career</Link></p>
                    </div>
                    <div className="">
                        <h5 className="mb-2"><strong>Skills & Trainings</strong></h5>
                        <p><Link to="/trainings" className="text-decoration-none small-text-gray">Boosting Your Skills</Link></p>

                    </div>
                </div>

            </div>
            <br/>
            <div className="d-flex flex-row justify-content-between flex-wrap gap-4">
                <div className="">
                    <h5 className="mb-2"><strong>Social Media</strong></h5>
                    <div className="d-flex flex-row justify-content-between">
                        <a href="https://web.facebook.com/Worknation-100423836232607">
                            <CImage src={FaceBook} style={{height:"20px"}} className="social-image"/>
                        </a>
                        <a href="https://www.instagram.com/worknationng" target="_blank" rel="noreferrer"
                           className="px-2">
                            <CImage src={Instagram} style={{height:"20px"}} className="social-image"/>
                        </a>
                        <a href="https://twitter.com/WorknationNG" target="_blank" rel="noreferrer" className="px-2">
                            <CImage src={Twitter} style={{height:"20px"}} className="social-image"/>
                        </a>
                        <a href="https://www.linkedin.com/company/worknation" target="_blank" rel="noreferrer"
                           className="px-2">
                            <CImage src={LinkedIn} style={{height:"20px"}} className="social-image"/>
                        </a>
                        <a href="https://www.youtube.com/channel/UCTZ0d3nAeEtHwtq_ADARlzg" target="_blank"
                           rel="noreferrer" className="px-2">
                            <CImage src={Youtube} style={{height:"20px"}}/>
                        </a>
                    </div>
                </div>
                <div className="">
                    <div className="d-flex align-items-center">
                        <AiFillPhone size={18}/>
                        <span className="ms-2">0908 899 9387</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <MdEmail size={18}/>
                        <span className="ms-2">info@worknation.ng</span>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="d-flex flex-row justify-content-between flex-xs-column">
                <div>
                    <p className="small-text-gray">Copyright WORKNATION. {new Date().getFullYear()}</p>
                </div>
                <div className="d-flex flex-row justify-content-between flex-xs-column">
                    <Link to="#" className="text-decoration-none small-text-gray px-3 px-sm-0">Terms and
                        Conditions</Link>
                    <Link to="#" className="text-decoration-none small-text-gray mx-3 px-sm-0">Privacy Statement</Link>
                    <Link to="#" className="text-decoration-none small-text-gray px-sm-0">Data Protection</Link>
                </div>
            </div>
        </div>
    </CContainer>
}

export default Footer;
