import React, {useEffect, useState} from 'react';
import {CAlert, CButton, CForm, CFormInput, CModal, CModalBody} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {BsFillFileEarmarkArrowUpFill} from 'react-icons/bs'
import {AiFillCloseCircle} from "react-icons/ai";
import * as pdfjs from 'pdfjs-dist';
import {cvDictionary} from "../../constant/cvBuilder/cvDictionary";
import {useLocation, useNavigate} from "react-router-dom";
import {transformData} from "../../services/TransformCVData";
import {useCVBuilder} from "../../hooks/user/cvBuilderHooks";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadMyCV = ({visible, setVisible}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [selectedFile, setSelectedFile] = useState(null);
    const {handleSubmit, formState: {errors}} = useForm()
    const [resumeData, setResumeData] = useState(null);
    const {mutate, data, isSuccess, isError, error, isLoading} = useCVBuilder()

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        setSelectedFile(file.name)
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            const typedarray = new Uint8Array(fileReader.result);
            const loadingTask = pdfjs.getDocument(typedarray);
            const keywordDict = {};
            let recentKeyword = null;

            loadingTask.promise.then(async (pdf) => {
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const textContent = await page.getTextContent();
                    let lines = []
                    textContent.items.map((item) => {
                        const arrayAliases = Object.values(cvDictionary.titles)
                        const keywords = arrayAliases.flat()
                        const textCv = item.str
                        const lowerCaseWord = textCv.toLowerCase();
                        if (keywords.includes(lowerCaseWord)) {
                            keywordDict[lowerCaseWord] = [];
                            recentKeyword = lowerCaseWord;
                        } else {
                            if (recentKeyword !== null) {
                                lines.push(textCv.trim())
                            }
                        }
                        if (item.hasEOL) {
                            if (recentKeyword !== null) {
                                keywordDict[recentKeyword].push(lines.join(' '));
                                lines = []
                            }
                        }
                    })
                }
                if (keywordDict) {
                    const psText = transformData(keywordDict)
                    setResumeData(psText);
                }
            }).catch((error) => {
                console.error("Error loading PDF:", error);
            });
        };
        fileReader.readAsArrayBuffer(file);

    };

    const uploadCvData = () => {
        mutate(resumeData)
    }

    useEffect(() => {
        if (isSuccess) {
            navigate(`/user/build-resume`, {
                replace: true,
                state: {title: location.state.title, parsedData: resumeData}
            })
        }
    }, [isSuccess])

    return (
        <CModal size={'lg'} alignment={'center'} visible={visible}
                onClose={() => setVisible(false)}>
            <CModalBody className="px-lg-5">
                <div className={'d-flex justify-content-end mb-2'}>
                    <AiFillCloseCircle size={30} onClick={() => setVisible(false)} className={"text-primary "}/>
                </div>
                <h3 className="main-title_green text-center">Upload My Resume</h3>
                <div className={'mb-3'}>
                    <CForm onSubmit={handleSubmit(uploadCvData)}>
                        <center className="w-100">
                            <label htmlFor="cv" className="w-100 cursor-pointer">
                                <div className="py-3 w-100 border rounded dotted-border">
                                    <BsFillFileEarmarkArrowUpFill className={'text-green'} size={60}/>
                                    <div className="text-primary">
                                        <small>{selectedFile || "Browse for Resume Here"}</small>
                                    </div>
                                    <h4 className="sub-title mt-3">
                                        Acceptable file types: PDF
                                    </h4>
                                </div>
                            </label>
                            <CFormInput
                                type="file"
                                hidden={true}
                                name="cv"
                                id="cv"
                                accept=".pdf"
                                onChange={handleFileChange}
                            />

                            {
                                errors?.upload &&
                                <div className={"mt-2"}>
                                    <CAlert color="danger">
                                        {errors.upload?.message}
                                    </CAlert>
                                </div>
                            }
                        </center>
                        {
                            resumeData &&
                            <div className="d-flex justify-content-center  py-3">
                                <CButton
                                    type={"submit"}
                                    className="text-white px-5 mx-1"
                                >
                                    Next
                                </CButton>
                            </div>
                        }
                    </CForm>
                </div>
            </CModalBody>
        </CModal>

    );
};

export default UploadMyCV;


