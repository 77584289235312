import React from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import HeroImage from '../../assets/images/hero-image.svg';
import {Link} from "react-router-dom";

const NewLandingHero = () => {
  return <CContainer className="p-5 h-100 mb-3">
    <CRow className="min-vh-100 landing-hero-section">
      <CCol md={5} className="d-flex flex-column justify-content-center align-content-center">
        <h1><strong><span className="text-primary-dark">WorkNation:</span> Shaping the Future of Work in Africa</strong></h1>
        <p>Explore the limitless possibilities, partner with us, and join the journey towards a brighter tomorrow.</p>
        <Link to="/user/signup" className="mt-5 w-40">
          <button className="btn btn-primary btn-lg text-white py-3 smaller-text-12 w-100">REGISTER</button>
        </Link>
      </CCol>
      <CCol md={1} className="d-xs-none"></CCol>
      <CCol md={6} className="d-flex flex-column justify-content-center align-content-end d-xs-none">
        <CImage src={HeroImage} alt={'Hero'} />
      </CCol>
    </CRow>
  </CContainer>
}

export default NewLandingHero;
