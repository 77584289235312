import React, { useEffect, useState } from 'react'
import { CCol, CForm, CFormFeedback, CFormInput, CLoadingButton } from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginSchema } from '../form-schema/user-form-schema/loginSchema'
import { Link, useNavigate } from 'react-router-dom'
import { useResendVerificationLink, useSignIn } from '../hooks/user/userApiHooks'
import { fail_notify, success_notify } from '../constant'
import { useAppContext } from '../context/user/UserAppProvider'
import { BiBadgeCheck } from 'react-icons/bi'
import { encryptText } from './Utility'
import { VerificationSchema } from '../form-schema/user-form-schema/verificationSchema'
import VerificationModal from './modals/VerificationModal'
import AccountRecoveryModal from './modals/AccountRecoveryModal'
import appApiCalls from 'src/services/api'

const Login = () => {
  const [isCreateAccount, setIsCreateAccount] = useState(false)
  const [verify, setVerify] = useState(false)
  const [recovery, setRecovery] = useState(false)
  const {
    register: loginRegister,
    handleSubmit: loginHandleSubmit,
    formState: { errors: loginErrors },
    watch: loginWatch,
  } = useForm({
    resolver: yupResolver(LoginSchema),
  })

  const {
    register: verificationRegister,
    handleSubmit: verificationHandleSubmit,
    formState: { errors: verificationErrors },
  } = useForm({
    resolver: yupResolver(VerificationSchema),
  })

  const navigate = useNavigate()
  const { storeChatUser, storeUser } = useAppContext()
  const { isLoading, isSuccess, data, isError, error, mutate } = useSignIn()
  const mandatoryQuiz = ['Level One Questionnaire', 'Quality Assurance']
  const redirectUrl = localStorage.getItem('redirectUrl')

  const checker = (data) => {
    return data?.filter((item) => mandatoryQuiz?.includes(item))
  }
  const {
    isLoading: isLoadingEmail,
    isSuccess: isSuccessEmail,
    data: dataEmail,
    isError: isErrorEmail,
    error: errorEmail,
    mutate: mutateEmail,
  } = useResendVerificationLink()

  useEffect(() => {
    if (isErrorEmail) {
      fail_notify(errorEmail?.response?.data?.message)
    }

    if (isSuccessEmail) {
      setVerify(false)
      success_notify('Verification Successful')
    }
  }, [isLoadingEmail, isSuccessEmail, dataEmail, isErrorEmail, errorEmail, mutateEmail])

  const userData = data?.data?.data
  const authenticateChatUser = async () => {
    try {
      const chatData = await appApiCalls.getActiveChatUser(userData?.email)
      // const chatData = await appApiCalls.getActiveChatUser('yakubuh56@gmail.com')
      if (!chatData?.data) {
        //   initalize chat user
        const res = await appApiCalls.initializeChatUser({
          email: userData?.email,
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          role: 'User',
        })
        storeChatUser(res.data)
      } else {
        storeChatUser(chatData.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      navigate(`/user/choose-skill-set`, {
        replace: true,
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      data.data.LMSID = encryptText(loginWatch('password'), data.data.accessToken)
      const isProfileUpdated = data?.data?.data?.isProfileCompleted

      let storeData = {
          ...data?.data, // Spread the second-level object
          data: {
            ...data?.data?.data, // Spread the third-level object
            role: 'User', // Update only the role field
          },
      };
      storeUser(storeData)
      success_notify('Successfully Logged In')
      const role = data?.data?.data?.role
      const stakeholderRole = data?.data?.data?.roles

      if (
        stakeholderRole?.includes('Employer') ||
        stakeholderRole?.includes('Government') ||
        stakeholderRole?.includes('Academy')
      ) {
        navigate(`/employer/dashboard`, {
          replace: true,
        })
        return
      }

      const quiz = data?.data?.data?.quizTaken

      const isTaken = checker(quiz, mandatoryQuiz)
      if (redirectUrl) {
        navigate(redirectUrl, {
          replace: true,
        })
        return
      }

      if (role === 'User' || role === 'OG Academy' || role === 'NASIMS') {
        if (!isProfileUpdated) {
          navigate('/user/profile', { replace: true })
          return
        } else if (quiz.length === 0) {
          navigate(`/user/questionnaire/Level-One-Questionnaire`, {
            replace: true,
          })
        } else {
          authenticateChatUser()
        }
      }

      /*if (role === 'NASIMS') {
        if (!isProfileUpdated) {
          navigate('/user/profile', { replace: true })
          return
        } else if (isTaken.length >= 2) {
          navigate(`/user/choose-skill-set`, {
            replace: true,
          })
        } else {
          navigate(`/user/questionnaire/Level-One-Questionnaire`, {
            replace: true,
          })
        }
      }*/
    }

    if (isError) {
      if (error?.response?.data?.message === 'Your email has not been verified.') {
        setVerify(true)
      }

      fail_notify(error?.response?.data?.message)
    }
  }, [data, isSuccess, isError, error, navigate, storeUser])

  const onSubmit = (data) => {
    mutate(data)
  }

  const handleVerification = (data) => {
    mutateEmail(data)
  }

  return (
    <>
      {isCreateAccount && (
        <div>
          <h2 className="text-2 mt-4 my-4">Sign Up As:</h2>
          <div className="d-flex flex-row justify-content-center align-items-center mb-5 signup-options">
            <a href="/user/signup" className="text-decoration-none signup-option">
              <div className="sign-up-options ms-0">
                <h4>
                  Applicant <BiBadgeCheck className="mb-2" />
                </h4>
                <p>Find jobs that fit your description.</p>
              </div>
            </a>
            <a href="/employer/signup" className="text-decoration-none signup-option">
              <div className="sign-up-options ms-0 me-2">
                <h4>
                  Employer/Sponsor <BiBadgeCheck className="mb-2" />
                </h4>
                <p>Provide jobs, host or sponsor trainings.</p>
              </div>
            </a>
          </div>
          <p className="mb-4">
            Already have an account?{' '}
            <span>
              <Link
                to="/login"
                className="text-decoration-none font-color"
                onClick={(e) => {
                  e.preventDefault()
                  setIsCreateAccount(false)
                }}
              >
                Log in
              </Link>
            </span>
          </p>
        </div>
      )}
      {!isCreateAccount && (
        <>
          <h1 className="text-1">Log In</h1>
          <p className="mt-4 mb-2">
            Don’t have an account?{' '}
            <span>
              <Link
                to="/user/signup"
                className="text-decoration-non font-color"
                onClick={(e) => {
                  e.preventDefault()
                  setIsCreateAccount(true)
                }}
              >
                Create An Account
              </Link>
            </span>
          </p>

          <CForm noValidate className="needs-validation" onSubmit={loginHandleSubmit(onSubmit)}>
            {/* <CForm noValidate className="needs-validation"> */}
            <div className="d-flex flex-column gap-3">
              <CCol md={8}>
                <CFormInput
                  type="text"
                  id="inputEmail4"
                  placeholder="Enter Your Email Address"
                  {...loginRegister('email')}
                  invalid={!!loginErrors?.email}
                />
                <CFormFeedback invalid={!!loginErrors?.email}>
                  {loginErrors?.email?.message}
                </CFormFeedback>
              </CCol>
              <CCol md={8}>
                <CFormInput
                  type="password"
                  id="inputPassword4"
                  {...loginRegister('password')}
                  placeholder="Enter Your Password"
                  invalid={!!loginErrors?.password}
                />
                <CFormFeedback invalid={!!loginErrors?.password}>
                  {loginErrors?.password?.message}
                </CFormFeedback>
              </CCol>
            </div>
            <CCol md={8}>
              <div className="d-flex flex-column cursor-pointer justify-content-between flex-wrap m-0">
                <Link to="/forgot-password" className="text-decoration-none m-0 mt-2 font-color">
                  Forgot Password?
                </Link>
                <div
                  onClick={() => {
                    setVerify(true)
                  }}
                  className="text-decoration-none m-0 my-1 font-color"
                >
                  Resend Verification Link
                </div>
                <div
                  onClick={() => {
                    setRecovery(true)
                  }}
                  className="text-decoration-none m-0 font-color"
                >
                  Account Recovery
                </div>
              </div>
              {/*<div className="d-flex not-validated mb-5">*/}
              {/*    <CFormCheck label="Keep me logged in" className="login-form-check-label"/>*/}
              {/*</div>*/}
              <CLoadingButton
                type="submit"
                className="reg-btn px-4 w-100 py-2 mt-5"
                loading={isLoading}
                disabled={isLoading}
              >
                Log in
              </CLoadingButton>
            </CCol>
          </CForm>
        </>
      )}

      <VerificationModal
        handleVerification={handleVerification}
        verify={verify}
        setVerify={setVerify}
        isLoadingEmail={isLoadingEmail}
        verificationHandleSubmit={verificationHandleSubmit}
        verificationErrors={verificationErrors}
        verificationRegister={verificationRegister}
        isLoading={isLoading}
      />

      <AccountRecoveryModal recovery={recovery} setRecovery={setRecovery} />
    </>
  )
}

export default Login
