import React, {useEffect} from 'react';
import {CForm, CFormInput, CLoadingButton,} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {fail_notify, success_notify} from "../../constant";
import {useGeneratePDF} from "../../hooks/user/cvBuilderHooks";
import {useCVBuilderContext} from "./CVBuilderProvider";

function NameCvModal() {
    const {mutate, isSuccess, isError, error, isLoading} = useGeneratePDF();
    const {
        setSaveCVForJob
    } = useCVBuilderContext()

    const {
        register,
        handleSubmit,
    } = useForm()

    useEffect(() => {
        if (isSuccess) {
            setSaveCVForJob(false)
            success_notify('CV Generated Successfully')
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess])

    // const generatePdf = (data) => {
    //     setVisible(false)
    //     const htmlContent = document.getElementById('resumeTemplate').innerHTML;
    //     const entirePageDOM = document.documentElement.outerHTML;
    //     const parser = new DOMParser();
    //     const parsedDocument = parser.parseFromString(entirePageDOM, 'text/html');
    //     const rootElement = parsedDocument.getElementById('root');
    //     rootElement.innerHTML = htmlContent;
    //     const updatedRootElementContent = rootElement.outerHTML;
    //     parsedDocument.getElementById('root').outerHTML = updatedRootElementContent;
    //     mutate({html: parsedDocument.documentElement.outerHTML, fileName: data.fileName})
    // }

    const generatePdf = (data) => {
        const openModal = document.querySelector('.modal.show');
        if (openModal) {
            openModal.classList.remove('show');
            document.body.classList.remove('modal-open');
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
                modalBackdrop.remove();
            }
        }
        const htmlContent = document.getElementById('resumeTemplate').innerHTML;
        const entirePageDOM = document.documentElement.outerHTML;
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(entirePageDOM, 'text/html');
        const bootstrapCssLink = document.createElement('link');
        bootstrapCssLink.rel = 'stylesheet';
        bootstrapCssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css'
        parsedDocument.head.appendChild(bootstrapCssLink);
        const rootElement = parsedDocument.getElementById('root');
        rootElement.innerHTML = htmlContent;
        parsedDocument.getElementById('root').outerHTML = rootElement.outerHTML;
        mutate({html: parsedDocument.documentElement.outerHTML, fileName: data.fileName})
    }
    return (

        <CForm onSubmit={handleSubmit(generatePdf)}>
            <h3 className={'main-title_green text-center'}> Choose a CV Name</h3>
            <div className="d-flex flex-column justify-content-between align-items-center py-3">
                <CFormInput
                    type={'text'}
                    className={"w-1/2"}
                    {...register("fileName", {required: true})}
                    placeholder={"Example (latestCv, my-cv-latest)"}
                />
                <CLoadingButton
                    type={"submit"}
                    className="text-white px-5 mx-1 mt-3"
                    loading={isLoading}
                    disabled={isLoading}
                >
                    Generate CV
                </CLoadingButton>
            </div>
        </CForm>

    );
}

export default NameCvModal;