import React from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import HomeScreen from '../../assets/images/home-screen.svg';

const TalentsSection = () => {
    return <CContainer className="p-5 d-flex flex-row justify-content-between align-items-center w-100" id="talents">
        <CRow>
            <CCol md={5}>
                <div className="my-sm-3 talent-heading">
                    <p className="text-small text-primary">Get Involved</p>
                    <h1 className="text-primary-dark"><strong>Talents</strong></h1>
                    <p className="text-small text-secondary">Let us help you find your dream job</p>
                </div>

                <ul id="landing-talent-section">
                    <li>
                        <div className="talents-point">
                            <h5 className="text-primary-dark m-0">Create and Enhance Your Profile</h5>
                            <p className="small-text-gray">Craft a professional profile and refining your image using our resume builder - all within minutes.</p>
                        </div>
                    </li>
                    <li>
                        <div className="talents-point">
                            <h5 className="text-primary-dark m-0">Smart Profiling & Matching</h5>
                            <p className="small-text-gray">Receive job recommendations to your inbox.</p>
                        </div>
                    </li>
                    <li>
                        <div className="talents-point">
                            <h5 className="text-primary-dark m-0">Get Contacted by Partners</h5>
                            <p className="small-text-gray">Receive interview invitations and respond with just a few
                                clicks.</p>
                        </div>
                    </li>
                    <li>
                        <div className="talents-point">
                            <h5 className="text-primary-dark m-0">Make Informed Career Choices</h5>
                            <p className="small-text-gray">Skills assessment Aptitude Test Trainings.</p>
                        </div>
                    </li>
                </ul>
            </CCol>
            <CCol md={7} className="d-flex flex-column justify-content-center align-items-center">
                <CImage src={HomeScreen} className="w-100"/>
            </CCol>
        </CRow>
    </CContainer>
}

export default TalentsSection;
