import React, { useEffect, useState } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
const Academy = () => {

  useEffect(() => {
    //window.location.replace('https://academy.outsourceglobal.com');
  }, [])

  const [visible, setVisible] = useState(true)
  const navigate = useNavigate()
  return (
      <>
        <CModal backdrop="static" alignment="center" visible={visible} onClose={() => {
          /*setVisible(false)*/
          //navigate(-1)
        }}>
          <div className="d-flex justify-content-end p-2">
          <AiOutlineClose className="cursor-pointer" onClick={() => {
            setVisible(false)
            navigate(-1)
          }}/>
          </div>
          <CModalBody>
            You’re about to transition from WorkNation to Outsource Academy. Please click the button to proceed.
          </CModalBody>
          <div className="d-flex gap-2 w-100 justify-content-start px-3 pb-3">
           {/* <CButton color="secondary " onClick={() => setVisible(false)}>
              Close
            </CButton>*/}
            <CButton color="primary text-white" onClick={() => {
              setVisible(false)
              window.location.replace('https://academy.outsourceglobal.com');
            }}>Continue</CButton>
          </div>
        </CModal>
      </>
  )
}

export default Academy




