import TrophyIcon from '../../assets/icons/noto_trophy.svg'
import {CCol, CFormInput, CImage, CLoadingButton, CRow} from '@coreui/react-pro'
import React, {useEffect, useState} from 'react'
import {fail_notify, success_notify} from '../../constant'
import {useProfileFileUpload} from '../../hooks/user/userApiHooks'
import maleDP from "../../assets/brand/useravater.png";
import fMaleDP from "../../assets/brand/useravater.png";
import CamIcon from "../../assets/icons/cam-icon.svg"
import {useAppContext} from "../../context/user/UserAppProvider";

const UserProfileCard = ({userData, tier}) => {
    const [file, setFile] = useState(null)
    const [preview, setPreview] = useState(null)
    const [imgError, setImgError] = useState(null)
    const {user, storeUser} = useAppContext()

    const {isLoading, isSuccess, data, isError, error, mutate} = useProfileFileUpload()
    const handleDPChange = (e) => {
        if (e?.target?.files) {
            if (e.target.files[0].size > 2601465) {
                setImgError('Maximum image size is 2.5MB')
                return
            }
            setFile(e.target.files[0])
            setPreview(URL?.createObjectURL(e.target.files[0]))
            setImgError(null)
        }
    }

    useEffect(() => {
        if (isSuccess) {
            success_notify('Profile Picture Uploaded Successfully')
            setFile(null)
            let updatedObject = {...user}
            updatedObject.data.isProfileCompleted = true
            storeUser(updatedObject)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])

    const onSubmit = () => {
        mutate({passport: file})
    }
    const setDefaultSrc = (e) => {
        e.target.src = (userData?.gender?.toLowerCase() === "M" || userData?.gender?.toLowerCase() === "male") ? maleDP : fMaleDP
    }
    const DP = () => {
        return (userData?.gender?.toLowerCase() === "M" || userData?.gender?.toLowerCase() === "male") ? maleDP : fMaleDP
    }
    return (
        <>
            <div className="py-3">
                <CRow>
                    <CCol md={3}></CCol>
                    <CCol md={6}>
                        <center>
                            {userData?.userDP || preview ? (
                                <div className="user-profile-card">
                                    <img
                                        src={preview || userData?.userDP}
                                        onError={(e) => setDefaultSrc(e)}
                                        id="DP"
                                        alt="Profile"
                                        className=" w-100 h-100 mb-2 profile-card-pic"
                                    />
                                </div>
                            ) : (
                                <img
                                    onError={(e) => setDefaultSrc(e)}
                                    src={DP()}
                                    alt="Profile"
                                    className="w-75 h-75 mb-2 profile-card-pic"
                                />
                            )}
                        </center>
                    </CCol>
                    <CCol md={3} className="p-0">
                        <p className="small-text text-primary">{userData?.profileCompleted || '0%'} Complete</p>
                    </CCol>
                </CRow>

                <label htmlFor="gffddfvdf" className="w-100 cursor-pointer profile-card-label">
                    <center>
                        <div>
                            <CImage src={CamIcon} style={{width: '28px', height: '28px'}}/>
                        </div>
                        <div>
                            <small className="text-danger text-truncate">{imgError}</small>
                        </div>
                    </center>
                </label>
                <CFormInput
                    type="file"
                    hidden={true}
                    name="profile"
                    id="gffddfvdf"
                    accept=".png, .jpeg, .png, .jpg"
                    onChange={handleDPChange}
                />
                <center>
                    {file?.name && (
                        <CLoadingButton
                            className="w-100 text-white rounded-0"
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={() => onSubmit()}
                        >
                            Upload Profile Picture
                        </CLoadingButton>
                    )}
                </center>
                <h5 className="fw-bold text-center">{`${userData?.firstName} ${userData.lastName}`}</h5>
                <div className="d-flex flex-row justify-content-center">
                    <div
                        className="d-flex flex-row justify-content-around align-items-center px-1 py-2 rounded-0 w-23"
                        style={{border: '1px solid #c1c1c1'}}
                    >
                        <img src={TrophyIcon} alt="Trophy" style={{width: '18px', height: '18px'}}/>
                        <p className="m-0 ps-1"><strong>{tier}</strong></p>
                    </div>
                </div>
            </div>
            <div className="my-4">
                <h6 className="p-2 bg-grey-100">Personal Summary</h6>
                <p>{userData?.summary || 'Nil'}</p>
            </div>
            <div className="my-4">
                <h6 className="p-2 bg-grey-100">Personal Details</h6>
                <div className="d-flex">
                    <div className="d-flex w-100 flex-column">
                        <p className="gray-paragraph">PHONE NUMBER</p>
                        <p className="text-truncate">{userData?.phoneNumber || 'Nil'}</p>

                        <p className="gray-paragraph mt-4">LOCATION</p>
                        <p className="text-truncate">{userData?.stateOfResidence || 'Nil'}</p>

                        <p className="gray-paragraph mt-4">GENDER</p>
                        <p className="text-truncate">{userData?.gender || 'Nil'}</p>
                    </div>
                    <div className="d-flex w-50 flex-column">
                        <p className="gray-paragraph">EMAIL ADDRESS</p>
                        <p className="text-truncate w-100 d-flex">{`${userData?.email || 'Nil'}`}</p>
                        {/*<div className="text-truncate w-100 d-flex py-2">*/}
                        {/*    <span className="w-100 mt-n4">*/}
                        {/*      <ProfileProgress progress={userData?.profileCompleted || '0%'}/>*/}
                        {/*    </span>*/}
                        {/*</div>*/}

                        <p className="gray-paragraph mt-4">DATE OF BIRTH</p>
                        <p className="text-truncate">{userData?.dateOfBirth || 'Nil'}</p>
                    </div>
                </div>
            </div>
            <div className="my-4">
                <h6 className="p-2 bg-grey-100">Skills</h6>
                <p>{userData?.professionalSkills ? userData?.professionalSkills?.map((skill, index) => (
                        <span key={index} className="bg-grey-100 px-3 py-1 me-2 rounded-5">{skill}</span>
                    )) : 'Nil'}
                </p>
            </div>
            <div className="my-4">
                <h6 className="p-2 bg-grey-100">Preferences</h6>
                <div className="d-flex">
                    <div className="d-flex w-100 flex-column">
                        <p className="gray-paragraph">CAREER LEVEL</p>
                        <p className="text-truncate">{userData?.careerLevel || 'Nil'}</p>

                        <p className="gray-paragraph mt-4">JOB PREFERENCE</p>
                        <p className="text-truncate">{userData?.jobPreference || 'Nil'}</p>
                    </div>
                    <div className="d-flex w-50 flex-column">
                        <p className="gray-paragraph">EMPLOYMENT TYPE</p>
                        <p className="text-truncate">{`${userData?.employmentType || 'Nil'}`}</p>

                        <p className="gray-paragraph mt-4">AVAILABILITY</p>
                        <p className="text-truncate">{userData?.availability || 'Nil'}</p>
                    </div>
                </div>
            </div>
            {/*<div className="d-flex flex-row justify-content-between align-items-center mt-5">*/}
            {/*    <Link to="/user/profile-update">*/}
            {/*        <CButton className="text-white btn-primary btn-md">Edit Profile</CButton>*/}
            {/*    </Link>*/}
            {/*    <Link to="/user/change-password">*/}
            {/*        <CButton className="text-primary btn-md bg-transparent">Change Password</CButton>*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </>
    )
}

export default UserProfileCard
