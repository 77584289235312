import React from 'react';
import {CButton, CForm} from "@coreui/react-pro";
import {Controller, useForm} from "react-hook-form";
import RadioButton from "../../components/buttons/RadioButton";
import {useFetchUserCVs} from "../../hooks/user/jobApiHooks";
import {format} from "date-fns";
import {useNavigate} from "react-router-dom";

const CVUploadModal = ({setNext, setCVId}) => {
    const navigate = useNavigate()
    const {handleSubmit, control} = useForm()
    const {isSuccess, data} = useFetchUserCVs()
    const cvArrays = data?.data?.data

    const handleNext = (data) => {
        setCVId(Object.values(data)[0])
        setNext(2)
    }

    return (
        <div className={'mb-3'}>
            <CForm onSubmit={handleSubmit(handleNext)}>
                <center className="w-100">

                    <label htmlFor="cv" className="w-100 cursor-pointer">
                        <p className="text-green ">
                            Do you want to create a new resume or choose from a list of your existing resumes?
                        </p>

                    </label>
                    {
                        cvArrays?.length > 0 &&
                        <div className=" mt-2">
                            <Controller
                                control={control}
                                name={"upload"}
                                rules={{required: "Please Upload or Select a CV"}}
                                render={({field: {onChange}}) => (
                                    <>
                                        {
                                            cvArrays.map((cv, i) => (
                                                <div key={i}
                                                     className={"d-flex flex-row justify-content-between border rounded mb-2 px-3 border-b-grey"}>
                                                    <div className={"d-flex gap-4 "}>
                                                        <label className={"mt-4 "}>{cv.originalName}</label>
                                                        <label
                                                            className={"mt-4 text-small text-green"}>
                                                            {format(new Date(cv.createdAt), 'do, MMM, yyyy HH:mm')}
                                                        </label>
                                                    </div>
                                                    <RadioButton
                                                        type="radio"
                                                        key={i}
                                                        name={'upload'}
                                                        onChange={onChange}
                                                        value={`${cv.id}`}
                                                        autoFocus
                                                    />

                                                </div>
                                            ))}
                                    </>
                                )}
                            />
                        </div>
                    }
                </center>
                <div className="d-flex flex-row  py-3  justify-content-center">
                    <CButton
                        type={"button"}
                        className="text-white px-5 mx-1"
                        onClick={() => {
                            navigate(`/user/resume-builder`, {
                                replace: true,
                            })
                        }}
                    >
                     Create New Resume
                    </CButton>
                    {
                        (isSuccess && cvArrays.length > 0) &&
                        <CButton
                            type={"submit"}
                            className="text-white px-5 mx-1"
                        >
                            Submit
                        </CButton>
                    }
                </div>
            </CForm>

        </div>
    );
};

export default CVUploadModal;
