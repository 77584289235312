import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import DefaultBanner from "../../../assets/images/applicant-profile-banner.jpg";
import DummyProfilePic from "../../../assets/images/dummy-profile-pic.png";
import HRIcon from "../../../assets/images/shared-profile-hr.png";
import DummyInstitutionPic from "../../../assets/brand/default-company-logo.png";
import {useGetUserSharedProfile} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import {fail_notify} from "../../../constant";
import {useParams} from "react-router-dom";

const WorknationProfile = () => {

    const {profileSlug} = useParams()
    const [userData, setUserData] = useState({});
    const {data, isLoading, isError, error} = useGetUserSharedProfile(profileSlug)

    useEffect(() => {
        setUserData(data?.data?.data)
    }, [data?.data?.data])

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [error?.response?.data?.message, isError])

    if (isLoading) {
        return <ShowLoading/>
    }

    const setDefaultSrc = (e) => {
        e.target.src = DummyProfilePic
    }

    return (
        <CContainer>
            <CRow className="m-5 py-5">
                <div className="border-radius-12 bg-white border-bottom-3 p-0">
                    <CCol md={12} className="profile-banner-pic">
                        <CImage src={DefaultBanner} className="banner"/>
                        <CImage
                            src={userData?.profile?.passportUrl || DummyProfilePic}
                            onError={(e) => setDefaultSrc(e)}
                            id="DP"
                            alt="Profile"
                            className="picture rounded-circle"
                        />
                    </CCol>
                    <CCol md={12} className="">
                        <div className="px-4 mx-4">
                            <h2 className="m-0">{`${userData?.firstName} ${userData?.lastName}`}</h2>
                            <p className="small-text-gray">{userData?.occupation}</p>
                            <p className="mt-2 small-text-gray">
                                {userData?.profile?.address ? userData?.profile?.address+', ' : ''}
                                {userData?.profile?.localGovernmentOfResidence ? userData?.profile?.localGovernmentOfResidence+', ' : ''}
                                {userData?.profile?.stateOfResidence? userData?.profile?.stateOfResidence+', ' : ''}</p>
                        </div>
                        <div className="mx-4 mt-5 px-4">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <h5 className="mb-3">Personal Statement</h5>
                            </div>
                            <p>{userData?.profile?.summary || 'Nil'}</p>
                        </div>
                    </CCol>
                </div>
                <CCol md={12} className="mt-3 mb-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Experience</h5>
                        </div>
                        {userData?.profile?.workExperience?.map(({companyName, description, endDate, jobTitle, location, preference, startDate}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{companyName}</h6>
                                    <p className="small-text-gray">{jobTitle} - {preference}</p>
                                    <p className="small-text-gray">{startDate} - {endDate}</p>
                                    <p className="small-text-gray">{location}</p>
                                    <p className="small-text-gray mt-2">{description}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
                <CCol md={12} className="my-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Education</h5>
                        </div>
                        {userData?.profile?.educations?.map(({degree, description, endDate, fieldOfStudy, grade, location, majors, positionHeld, schoolName, startDate}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{schoolName} - {location}</h6>
                                    <p className="small-text-gray">{degree} - {fieldOfStudy} - {grade}</p>
                                    <p className="small-text-gray">{startDate} - {endDate}</p>
                                    <p className="small-text-gray">Majors: {majors?.map((major, index) => <small className="small-text-gray mx-1" key={index}>{major}</small>)}</p>
                                    <p className="small-text-gray mt-2">{description}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
                <CCol md={12} className="my-3 px-4 py-3 border-radius-12 bg-white">
                    <div className="mx-4">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <h5 className="mb-4">Licenses &amp; Certifications</h5>
                        </div>
                        {userData?.profile?.certificates?.map(({certificateId, certificateTitle, certificateUrl, expiredDate, issuedDate, majors, organization}, index) => (
                            <CRow key={index}>
                                <CCol md={2}>
                                    <CImage src={DummyInstitutionPic} style={{width: '100%'}}/>
                                </CCol>
                                <CCol md={10}>
                                    <h6 className="m-0 fw-bold">{organization}</h6>
                                    <p className="small-text-gray">{certificateTitle}</p>
                                    <p className="small-text-gray">{issuedDate} - {expiredDate}</p>
                                    <p className="small-text-gray">Majors: {majors?.map((major, index) => <small className="small-text-gray mx-1" key={index}>{major}</small>)}</p>
                                    <p className="small-text-gray">ID: {certificateId}</p>
                                    <p className="small-text-gray">URL: {certificateUrl}</p>
                                </CCol>
                            </CRow>
                        ))}
                        <CImage src={HRIcon} style={{width: '100%'}}/>
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default WorknationProfile