import React, {useEffect, useState} from 'react'
import {CButton, CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import {BiEnvelope} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png"
import ShowLoading from "../../components/ShowLoading";
import {useGetEmployerTeam} from "../../hooks/admin/adminApiHooks";
import {Link} from "react-router-dom";

const EmployerTeams = () => {

    const [employerTeam, setEmployerTeam] = useState([])

    const {
        isLoading,
        data
    } = useGetEmployerTeam()

    useEffect(() => {
        setEmployerTeam(data?.data?.data?.items)
    }, [data?.data?.data?.items])

    if (isLoading) {
        return <ShowLoading/>
    }

    const convertToDate = (date) => {
        const _date = new Date(Date.parse(date))
        return _date.toLocaleDateString()
    }

    const convertUserDisplay = (user) => {
        return (
            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                <CImage src={DummyProfilePic} alt="Profile" style={{
                    width: '32px',
                    height: '32px',
                    marginRight: '10px',
                    borderRadius: '50%',
                }}/>
                <p style={{marginBottom: "0"}}><b>{user.firstName + " " + user.lastName}</b></p>
            </div>
        )
    }

    const displayItems = (items) => {
        return items?.map((item, index) => (
            index === items.length - 1 ? item : `${item}, `
        ))
    }

    const convertAction = (action) => {
        return <Link to={`/admin/admin/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }
    //console.log(employerTeam)

    return (
        <CContainer>
            <div>
                <h5>{`${employerTeam ? employerTeam?.length : 0} Admins`}</h5>
            </div>
            <CRow className={'d-flex justify-content-between align-items-center'}>
                <CCol md={4}>
                    <Link to={'/employer/create-admin'}>
                        <CButton className="text-white px-5 users-action-buttons">+{'   '}ADD ADMIN</CButton>
                    </Link>
                </CCol>
            </CRow>
            <CRow className="mt-3 mb-5">
                <CCol md={12}>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>User</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Registered</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            employerTeam?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{convertUserDisplay(item)}</td>
                                    <td><BsTelephone/> {item.phoneNumber}</td>
                                    <td><BiEnvelope/> {item.email}</td>
                                    <td>{item.stakeholderRole?.name}</td>
                                    <td>{convertToDate(item.createdAt)}</td>
                                    <td>{convertAction(item.action)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>

                </CCol>
            </CRow>
        </CContainer>
    )
}

export default EmployerTeams
