import React from 'react';
import {HiOutlinePencilAlt} from "react-icons/hi";
import {RiDeleteBin6Line} from "react-icons/ri";
import {parseAndFormatHTML} from "../../../services/cvbuilder/cvBuilderHelpers";

function AchievementsCard({ setHasAchievements, hasAchievements, achievements, deleteAchievements}) {
    return (
        <>
            <div
                className="d-flex px-4 py-2 justify-content-between align-content-center rounded shadow-sm-user px-10 mt-4">
                <div className="d-flex">
                    <div>
                        <p>{parseAndFormatHTML(achievements)}</p>
                    </div>
                </div>
                <div className={"d-flex gap-3"}>
                    <div className="fw-bold" onClick={() => setHasAchievements(!hasAchievements)}>
                        <HiOutlinePencilAlt size={16} color={'currentColor'}/>
                    </div>
                    <div className="fw-bold" onClick={() => deleteAchievements()}>
                        <RiDeleteBin6Line size={16} color={'currentColor'}/>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AchievementsCard;
