import React, {useEffect, useState} from 'react';
import {
    CCol,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css'
import {sponsorSchema} from "../../../form-schema/stake-holder-schema/sponsorSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {useGetCBSRecords, useSponsorTraining,} from "../../../hooks/employer/employerApiHooks";
import {fail_notify, success_notify} from "../../../constant";
import TextEditor from "../../TextEditor";
import NaijaStates from 'naija-state-local-government';
import {useNavigate, useParams} from "react-router-dom";
import {useGetTrainingDetailQuery} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../ShowLoading";
import {useAppContext} from "../../../context/user/UserAppProvider";
import ErrorReports from "../../ErrorReports";
import LoanSection from "./LoanSection";
import DOMPurify from "dompurify";

function SponsorCourse() {

    const [benLG, setBenLG] = useState(null)
    const [benResLG, setBenResLG] = useState(null)

    const {
        isSuccess,
        isLoading,
        error, mutate,
        isError,
        data
    } = useSponsorTraining()

    const [purposeObjectives, setPurposeObjectives] = useState('');
    const {curseId} = useParams();
    const navigate = useNavigate()
    const {user} = useAppContext()

    const {
        data: employerCBSRecord
    } = useGetCBSRecords()

    const trainingDetail = useGetTrainingDetailQuery(curseId)

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: {errors},
        getValues
    } = useForm({resolver: yupResolver(sponsorSchema),})

    const sponsorshipType = watch('sponsorshipType')

    const handleRichTextChange = (content, name) => {
        setValue(name, content);
    };

    const handelSponsorship = async (data) => {
        const cbsRecords = employerCBSRecord?.data?.data
        data.courseId = curseId
        data.title = trainingDetail?.data?.data?.data?.fullname
        data.payerId = cbsRecords.payerId
        data.sponsorshipAmount = data.sponsorshipAmount.replace(',', '') + ".00"
        data.revenueHeadId = 2
        data.paymentMethod = "Parkway"
        mutate(data)
    }

    useEffect(() => {
        let beneficiaries = getValues('numberOfSlots')
        let courseAmount = getValues('amount')
        if (beneficiaries && courseAmount) {
            beneficiaries = parseInt(beneficiaries)
            courseAmount = parseInt(courseAmount)
            const sponsorshipAmount = beneficiaries * courseAmount
            setValue("sponsorshipAmount", sponsorshipAmount)
        }
    }, [watch('numberOfSlots'), watch('amount')])

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successful')
            navigate(`/employer/dashboard`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isLoading, error, isError, data])

    if (!user?.data?.isSponsor) {
        fail_notify("You don't have a sponsor role")
        navigate(-1)
        return
    }

    if (trainingDetail?.isError) {
        fail_notify("An error occurred")
        navigate(-1)

    }
    return (trainingDetail?.isLoading ? <ShowLoading/> :
            <CRow className="form-card p-4">
                <CRow className="d-flex flex-row  py-20">

                    <CCol sm={10}>
                        <h3 className="sub-title">{trainingDetail?.data?.data?.data?.fullname} Sponsorship</h3>
                        <hr/>
                    </CCol>

                </CRow>
                <CForm className="w-100" onSubmit={handleSubmit(handelSponsorship)}>
                    <CRow>
                        {
                            isError &&
                            <ErrorReports error={error}/>
                        }
                    </CRow>
                    <CRow>

                        <h3 className="sub-title pt-3"> Course Description</h3>
                        <div>
                            <div
                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(trainingDetail?.data?.data?.data?.summary)}}></div>
                            <p>
                                {}
                            </p>

                        </div>
                    </CRow>

                    <CRow>
                        <CCol className=" align-middle gap-3 mt-2">
                            <CCol className="">
                                <CFormLabel className="m-1 label">Relationship to Beneficiaries</CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="e.g., employer, mentor, family member"
                                    {...register('relationshipToBeneficiaries')}
                                    invalid={!!errors.relationshipToBeneficiaries?.toString()}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.relationshipToBeneficiaries?.toString()}>
                                    {errors.relationshipToBeneficiaries?.message}
                                </CFormFeedback>
                            </CCol>

                        </CCol>
                    </CRow>

                    <CRow className="">
                        <h3 className="sub-title pt-3">Financial Details</h3>
                        <CCol className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                            <div>
                                <CFormLabel className="m-1 label">No Of Students To Be Sponsored</CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="100"
                                    {...register('numberOfSlots')}
                                    invalid={!!errors.numberOfSlots?.toString()}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.numberOfSlots?.toString()}>
                                    {errors.numberOfSlots?.message}
                                </CFormFeedback>

                            </div>
                            <div>
                                <CFormLabel className="m-1 label">Course Amount (₦)</CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="10000000"
                                    {...register('amount')}
                                    invalid={!!errors.amount?.toString()}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.amount?.toString()}>
                                    {errors.amount?.message}
                                </CFormFeedback>
                            </div>
                            <div>
                                <CFormLabel className="m-1 label">Total Amount Sponsored (₦)</CFormLabel>
                                <CFormInput
                                    type="text"
                                    placeholder="10000000"
                                    {...register('sponsorshipAmount')}
                                    invalid={!!errors.sponsorshipAmount?.toString()}
                                    disabled={true}
                                    className="mb-2"
                                />
                                <CFormFeedback invalid={!!errors.sponsorshipAmount?.toString()}>
                                    {errors.sponsorshipAmount?.message}
                                </CFormFeedback>

                            </div>
                        </CCol>
                    </CRow>
                    <CRow className="">
                        <CCol className="d-flex flex-wrap align-items-center justify-content-between gap-5">
                            <div className={'col'}>
                                <CFormLabel className="m-1 label">Payment Type</CFormLabel>
                                <CFormSelect
                                    id="educationLevel"
                                    className="form-control mb-2"
                                    {...register('sponsorshipType')}
                                    invalid={!!errors.sponsorshipType}
                                >
                                    <option value="">Select Sponsorship type</option>
                                    <option value="loan">Loan</option>
                                    <option value="grant">Grant</option>
                                </CFormSelect>
                                <CFormFeedback
                                    invalid={!!errors.sponsorshipType}>{errors.sponsorshipType?.message}</CFormFeedback>
                            </div>
                            {
                                sponsorshipType === 'grant' &&
                                <>
                                    <div className={'col'}>
                                        <CFormLabel className="m-1 label">Grant Amount (₦)</CFormLabel>
                                        <CFormInput
                                            type="text"
                                            placeholder="10000000"
                                            {...register('grantAmount')}
                                            invalid={!!errors.grantAmount?.toString()}
                                            className="mb-2"
                                        />
                                        <CFormFeedback invalid={!!errors.grantAmount?.toString()}>
                                            {errors.grantAmount?.message}
                                        </CFormFeedback>
                                    </div>
                                    <div className={'col'}>
                                        <CFormLabel className="m-1 label">Payment Occurrence</CFormLabel>
                                        <CFormSelect
                                            id="paymentOccurrence"
                                            className="form-control mb-2"
                                            {...register('paymentOccurrence')}
                                            invalid={!!errors.paymentOccurrence}
                                        >
                                            <option value="">Select Recurring</option>
                                            <option value="one off ">One Off</option>
                                            <option value="weekly ">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </CFormSelect>
                                        <CFormFeedback
                                            invalid={!!errors.paymentOccurrence}>{errors.paymentOccurrence?.message}</CFormFeedback>
                                    </div>
                                </>
                            }

                        </CCol>
                    </CRow>
                    <div>
                        {
                            sponsorshipType === 'loan' &&
                            <LoanSection register={register} errors={errors}/>
                        }
                    </div>
                    <CRow>
                        <h3 className="sub-title pt-3">Purpose and Objectives</h3>
                        <div>
                            <TextEditor onChange={handleRichTextChange} name={`purposeObjectives`}
                                        editorState={purposeObjectives} setEditorState={setPurposeObjectives}/>

                            <label className="text-danger">
                                {errors.purposeObjectives?.message}
                            </label>
                        </div>
                    </CRow>
                    <CRow className="">
                        <h3 className="sub-title pt-3">Eligibility Criteria</h3>
                        <CCol className="d-flex flex-wrap align-items-center gap-5">
                            <div className={'col'}>
                                <CFormLabel className="m-1 label">State of origin</CFormLabel>
                                <CFormSelect
                                    id="educationLevel"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                        setValue('beneficiaryStateOfOrigin', e.target.value)
                                        setValue('beneficiaryLgaOrigin', null)
                                        if (e.target.value) {
                                            setBenLG(NaijaStates?.lgas(e.target.value))
                                        }
                                    }}
                                    invalid={!!errors.stateOfOrigin}
                                >
                                    <option value="">Select state of origin</option>
                                    {
                                        NaijaStates?.states()?.map((option, index) => (
                                            <option
                                                value={option}
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                                <CFormFeedback
                                    invalid={!!errors.stateOfOrigin}>{errors.stateOfOrigin?.message}</CFormFeedback>
                            </div>

                            <div className={'col'}>
                                <CFormLabel className="m-1 label">L.G.A</CFormLabel>
                                <CFormSelect
                                    id="beneficiaryLgaOrigin"
                                    className="form-control mb-2"
                                    {...register('beneficiaryLgaOrigin')}

                                    invalid={!!errors.lga}
                                >
                                    <option value="">Select Local Government</option>
                                    {
                                        benLG?.lgas?.map((option, index) => (
                                            <option
                                                value={option}
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                                <CFormFeedback
                                    invalid={!!errors.lgaOrigin}>{errors.lgaOrigin?.message}</CFormFeedback>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow className="">
                        <CCol className="d-flex flex-wrap align-items-center gap-5">
                            <div className={'col'}>
                                <CFormLabel className="m-1 label">State of residence</CFormLabel>
                                <CFormSelect
                                    id="educationLevel"
                                    className="form-control mb-2"
                                    onChange={(e) => {
                                        setValue('beneficiaryStateOfresidence', e.target.value)
                                        setValue('beneficiaryLgaResidence', null)
                                        if (e.target.value) {
                                            setBenResLG(NaijaStates?.lgas(e.target.value))
                                        }

                                    }}

                                    invalid={!!errors.stateOfResidence}
                                >
                                    <option value="">Select state of residence</option>
                                    {
                                        NaijaStates?.states()?.map((option, index) => (
                                            <option
                                                value={option}
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                                <CFormFeedback
                                    invalid={!!errors.stateOfResidence}>{errors.stateOfResidence?.message}</CFormFeedback>
                            </div>
                            <div className={'col'}>
                                <CFormLabel className="m-1 label">L.G.A</CFormLabel>
                                <CFormSelect
                                    id="educationLevel"
                                    className="form-control mb-2"
                                    {...register('beneficiaryLgaResidence')}
                                    invalid={!!errors.lga}
                                >
                                    <option value="">Select Local Government</option>
                                    {
                                        benResLG?.lgas?.map((option, index) => (
                                            <option
                                                value={option}
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                                <CFormFeedback
                                    invalid={!!errors.lgaResidence}>{errors.lgaResidence?.message}</CFormFeedback>
                            </div>

                        </CCol>
                    </CRow>
                    <CRow>
                        <h3 className="sub-title pt-3">Supporting Documents: (All optional)</h3>
                        <CCol className="d-flex flex-wrap align-items-center gap-2">
                            <div className="mb-3">
                                <CFormLabel htmlFor="proofOfIdentity">Proof of identity (e.g., ID, passport) (2MB
                                    Max)</CFormLabel>
                                <CFormInput invalid={!!errors.proofOfIdentity} {...register("proofOfIdentity")}
                                            accept="image/x-png,image/png,image/jpeg" type="file" id="proofOfIdentity"/>
                                <CFormFeedback
                                    invalid={!!errors.proofOfIdentity}>{errors.proofOfIdentity?.message}</CFormFeedback>
                            </div>
                            <div className="mb-3">
                                <CFormLabel htmlFor="proofOfFinancialCapability">Proof of financial capability (e.g bank
                                    statements) (2MB Max)</CFormLabel>
                                <CFormInput
                                    invalid={!!errors.proofOfFinancialCapability} {...register("proofOfFinancialCapability")}
                                    type="file" accept="image/x-png,image/png,image/jpeg"
                                    id="proofOfFinancialCapability"/>
                                <CFormFeedback
                                    invalid={!!errors.proofOfFinancialCapability}>{errors.proofOfFinancialCapability?.message}</CFormFeedback>
                            </div>
                            <div className="mb-3">
                                <CFormLabel htmlFor="letterOfReference">Letter of recommendation or reference (2MB
                                    Max)</CFormLabel>
                                <CFormInput invalid={!!errors.letterOfReference} {...register("letterOfReference")}
                                            accept="image/x-png,image/png,image/jpeg" type="file"
                                            id="letterOfReference"/>
                                <CFormFeedback
                                    invalid={!!errors.letterOfReference}>{errors.letterOfReference?.message}</CFormFeedback>
                            </div>

                        </CCol>
                    </CRow>

                    <br/>
                    <CRow>

                        <div className="d-flex gap-2">
                            <CFormCheck {...register("sponsorshipAgreement")}/>
                            <div>I agree to the <a className="text-primary" href="#">terms and conditions</a> of
                                sponsorship
                            </div>
                        </div>
                    </CRow>

                    <CRow className="d-flex flex-row justify-content-end">
                        <CCol sm={4} className="d-flex flex-row justify-content-end">
                            <CLoadingButton
                                type={"submit"} loading={isLoading}
                                disabled={!watch('sponsorshipAgreement') || isLoading || trainingDetail.isError}
                                className="text-white"> Submit</CLoadingButton>
                        </CCol>
                    </CRow>
                </CForm>

            </CRow>
    );
}

export default SponsorCourse;
