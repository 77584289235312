import React from 'react'
// import {Outlet} from "react-router-dom";
import { CContainer, CCol, CImage } from '@coreui/react-pro'
import { PropTypes } from 'prop-types'
import Logo from '../../assets/brand/WN-logo.svg'
import { Link } from 'react-router-dom'

const EntryFormLayout = ({ image, form }) => {
  return (
    <div className="entry-form-main-div">
      {image ? (
        <CContainer className="d-flex flex-row justify-content-center align-items-center min-vh-100">
          <CCol sm={5} className="d-none d-lg-block d-flex flex-column align-items-center">
            <CImage src={image} alt="Sign up" className="form-image" />
          </CCol>
          <CCol sm={1} className="d-md-none"></CCol>
          <CCol sm={6} className="px-3 py-5 my-2 d-flex flex-column custom-entry-form">
            <Link to="/">
              <CImage src={Logo} alt="logo" className="logo pb-3" />
            </Link>
            {form}
          </CCol>
        </CContainer>
      ) : (
        <CContainer className="d-flex flex-row justify-content-center align-items-center">
          <CCol sm={12} className="px-3 py-5 my-2 d-flex flex-column custom-entry-form">
            <Link to="/">
              <CImage src={Logo} alt="logo" className="logo pb-3" />
            </Link>
            {form}
          </CCol>
        </CContainer>
      )}
    </div>
  )
}

EntryFormLayout.propTypes = {
  image: PropTypes.string,
  form: PropTypes.element,
}

export default EntryFormLayout
