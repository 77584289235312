import AdminDashboard from "../../admin/pages/dashboard/AdminDashboard";
import Applicants from "../../admin/pages/Applicants";
import Admins from "../../admin/pages/Admins";
import Employers from "../../admin/pages/Employers";
import JobsReports from "../../admin/pages/dashboard/JobsReports";
import JobListing from "../../employer/pages/JobListing";
import CreateAdmin from "../../admin/pages/CreateAdmin";
import AddJobPosting from "../../employer/pages/AddJobPosting";
import AddTraining from "../../employer/pages/AddTraining";
import ViewApplicantProfile from "../../user/pages/dashboard/ViewApplicantProfile";
import ViewEmployerProfile from "../../employer/pages/dashboard/ViewEmployerProfile";
import ViewAdminProfile from "../../admin/pages/dashboard/ViewAdminProfile";
import UploadTestimonials from "../../admin/pages/UploadTestimonials";
import AllTestimonials from "../../admin/pages/AllTestimonials";
import AddTest from "../../admin/pages/AddTest";
import Sponsorship from "../../admin/pages/Sponsorship";
import ViewTests from "../../admin/pages/ViewTests";
import ViewTestQuestion from "../../admin/pages/ViewTestQuestion";
import AllSkillsReports from "../../admin/pages/dashboard/AllSkillsReports";
import AuditTrail from "../../admin/pages/AuditTrail";
import Government from "../../admin/pages/Government";
import KPIManager from "../../admin/pages/KPIManager";
import AddNewKPI from "../../admin/pages/AddNewKPI";
import Attendance from "../../admin/pages/Attendance";
import UserAssessments from "../../admin/pages/dashboard/SkillReports/UserAssessments";
import SponsoredCourses from "../../admin/pages/SponsoredCourses";
import LMSReport from "../../admin/pages/LMSReport";

const PrivateAdminRoutes = [
    {path: '/admin/dashboard', exact: true, element: AdminDashboard},
    {path: '/admin/applicants', exact: true, element: Applicants},
    {path: '/admin/admins', exact: true, element: Admins},
    {path: '/admin/government', exact: true, element: Government},
    {path: '/admin/upload-questions', exact: true, element: AddTest},
    {path: '/admin/upload-questions/:uuid/:title', exact: true, element: AddTest},
    {path: '/admin/sponsorship', exact: true, element: Sponsorship},
    {path: '/admin/sponsored-courses', exact: true, element: SponsoredCourses},
    // {path: '/admin/sponsored-courses', exact: true, element: Sponsorship},
    {path: '/admin/view-questions', exact: true, element: ViewTests},
    {path: '/admin/tests/:uuid', exact: true, element: ViewTestQuestion},
    {path: '/admin/employers', exact: true, element: Employers},
    {path: '/admin/reports', exact: true, element: JobsReports},
    {path: '/admin/user-assessments/:userId/:userUuid', exact: true, element: UserAssessments},
    {path: '/admin/attendance', exact: true, element: Attendance},
    {path: '/admin/lms-report', exact: true, element: LMSReport},
    {path: '/admin/assessments', exact: true, element: AllSkillsReports},
    {path: '/admin/job-posting', exact: true, element: JobListing},
    {path: '/admin/create-admin', exact: true, element: CreateAdmin},
    {path: '/admin/create-job-posting', exact: true, element: AddJobPosting},
    {path: '/admin/create-training', exact: true, element: AddTraining},
    {path: '/admin/applicant/:uuid', exact: true, element: ViewApplicantProfile},
    {path: '/admin/employer/:uuid', exact: true, element: ViewEmployerProfile},
    {path: '/admin/admin/:uuid', exact: true, element: ViewAdminProfile},
    {path: '/admin/upload-testimonial', exact: true, element: UploadTestimonials},
    {path: '/admin/testimonials', exact: true, element: AllTestimonials},
    {path: '/admin/audit-trail', exact: true, element: AuditTrail},
    {path: '/admin/kpi-manager', exact: true, element: KPIManager},
    {path: '/admin/add-new-kpi', exact: true, element: AddNewKPI}
]

export default PrivateAdminRoutes
