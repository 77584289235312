import React from 'react'
// import resendIcon from '../../assets/images/resend.svg'
import {CContainer} from '@coreui/react-pro'

import SignUpSuccessImage from '../../assets/images/sign-up-success.svg'

const SignUpSuccess = () => {
    //const { isSuccess, isLoading, isError, error } = useEmailReverificationQuery()

    return (
        <CContainer
            className="d-flex align-items-center flex-column justify-content-center bg-black min-vh-100 min-vw-100 bg-opacity-50 "
            style={{zIndex: 1000, position: 'fixed', top: '0px', left: '0px'}}
        >
            <div
                className={`d-flex align-items-center flex-column justify-content-center mt-5 bg-light border-radius-12 sign-up-success ${
                    window.innerWidth > 700 ? 'w-50 p-5' : 'w-100 p-2'
                }`}
            >
                <img src={SignUpSuccessImage} alt="Sign up"/>
                <h4 className="text-black fw-bold p-3">Congratulations!</h4>
                <p className="text-black m-0">Welcome to WORKNATION.</p>
                <p className="text-black text-center">
                    An email with a verification link has been sent to you, click on the verification link to
                    verify your email
                </p>

                {/* {
          isLoading &&
            <p> Resending Email Verification...</p>
            Please enter the six-digit OTP
          that was provided to your phone.
        }

        {
           isSuccess &&
            <p> Email Verification Resent</p>
        }
        {
          isError &&
            <ErrorReports error={error} />
        } */}
                <div className="d-flex w-100 justify-content-center gap-2 align-items-center mt-2 resend-group"></div>
            </div>
        </CContainer>
    )
}

export default SignUpSuccess
