import React, {useEffect, useState} from 'react';
import {CButton, CContainer, CDatePicker, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import {useGetJobsReports} from "../../../hooks/admin/adminApiHooks";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import appApiCalls from "../../../services/api";
import ShowLoading from "../../../components/ShowLoading";
import DataNotFound from "../../../components/DataNotFound";

const JobsReports = () => {
    const [page, setPage] = useState(1)
    const [jobsReports, setJobsReports] = useState([])
    const [limit, setLimit] = useState(10)
    const {isLoading, isSuccess, data} = useGetJobsReports(page, limit)

    useEffect(() => {
        setJobsReports(data?.data?.data?.items)
    }, [data])

    useEffect(() => {
        const getJobsReports = async () => {
            let res = await appApiCalls.getJobReports(page, limit)
            setJobsReports(res?.data?.data?.items)
        }
        getJobsReports()
    }, [page, limit])

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <>
            {isSuccess &&
                <CContainer>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <h5 className="text-primary">Job Report</h5>
                        <CButton className="text-white w-auto fs-6" size='lg'>Download</CButton>
                    </div>
                    <div className="shadow-sm p-3 my-3 bg-body rounded">
                        <CRow>
                            <div className="d-flex flex-row justify-content-between align-items-center my-3">
                                <CDatePicker placeholder="From"/>
                                <div className="d-flex flex-row justify-content-around align-items-center w-auto">
                                    <CFormLabel className="pe-3 my-0">Showing:</CFormLabel>
                                    <CFormSelect className="fs-6 px-5">
                                        <option>15 records/page</option>
                                    </CFormSelect>
                                </div>
                            </div>
                        </CRow>
                        {
                            jobsReports?.length > 0 && <>
                                <div>
                                    <table className="table border-0" id="reports-table">
                                        <thead className="thead-inverse">
                                        <tr className="bg-transparent text-primary">
                                            <th>Recruiter</th>
                                            <th>Job Title</th>
                                            <th>Resume Submitted</th>
                                            <th>Successful Resume</th>
                                            <th>Failed Resume</th>
                                            <th>Interviewed</th>
                                            <th>Job Views</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            jobsReports?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="fw-bolder">{item?.stakeholder?.name}</td>
                                                    <td>{item?.title}</td>
                                                    <td>{item?.applyForJob?.length || 0}</td>
                                                    <td className="text-primary">{item?.acceptedApplications?.length}</td>
                                                    <td className="text-danger">{item?.rejectedApplications?.length}</td>
                                                    <td className="text-primary">{item?.shortlist?.length}</td>
                                                    <td>{item?.jobViews?.length}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    jobsReports.length >= 10 &&
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <div className={"d-flex flex-row gap-3 w-23"}>
                                            <div className={"m-2"}>Show</div>
                                            <CFormSelect
                                                aria-label="Default select example"
                                                options={[
                                                    {label: '10', value: '10'},
                                                    {label: '25', value: '25'},
                                                    {label: '100', value: '100'},
                                                    {label: '200', value: '200'}
                                                ]}
                                                onChange={(e) => setLimit(e.target.value)}
                                            />
                                            <div className={"m-2"}>Entries</div>
                                        </div>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                            <CButton onClick={() => setPage(page - 1)}
                                                     className={`mx-3 text-white py-1 pb-2 px-3 ${page === 1 ? 'disabled' : ''}`}>
                                                <BiLeftArrow/>
                                            </CButton>
                                            <CButton onClick={() => setPage(page + 1)}
                                                     className="mx-3 text-white py-1 pb-2 px-3">
                                                <BiRightArrow/>
                                            </CButton>
                                        </div>
                                    </div>

                                }
                            </>
                        }
                        {jobsReports?.length === 0 && <DataNotFound/>}
                    </div>
                </CContainer>
            }
        </>
    )
}

export default JobsReports