import React from 'react'
// import resendIcon from '../../assets/images/resend.svg'

import SignUpSuccess from 'src/components/User/SignUpSuccess'

const RegSuccess = () => {
  //const { isSuccess, isLoading, isError, error } = useEmailReverificationQuery()

  return <SignUpSuccess />
}

export default RegSuccess
