import React from 'react';
import {CButton, CCol} from "@coreui/react-pro";
import {CultureFitPromptText, TestPromptText} from "../../../../constant";
import {useAppContext} from "../../../../context/user/UserAppProvider";
import excitement from "../../../../assets/icons/excited.jpg";

function AssessmentPrompt({setSetupSteps, title}) {

    const {user} = useAppContext()
    const userName =user?.data?.firstName || " "
    return (
        <>
            <div className={'d-flex flex-row gap-5'}>
                <CCol sm={6} className={'mx-auto'}>
                    <div className="d-flex align-items-center gap-2">
                        <h3>
                            Hi {userName}
                        </h3>
                        <img src={excitement} height={30} alt="MOG"/>
                    </div>
                    {
                        title === 'Culture Test' ?
                            <p className={'text-justify'}>
                                {
                                    CultureFitPromptText
                                }
                            </p> :
                            <p className={'text-justify'}>
                                {
                                    TestPromptText(title)
                                }
                            </p>
                    }
                </CCol>

                <CCol sm={6} className={'mx-auto pe-md-1'}>
                    <p className={'text-black'}>
                        Some things to keep in mind:
                    </p>
                    <ul className={'blue-list'}>
                        <li className={'mb-2'}>
                            The Assessment is timed
                        </li>
                        <li className={'mb-2'}>
                            Please ensure that you grant all the permission necessary for recording your screen
                        </li>
                    </ul>
                </CCol>
                <style>
                    {
                        `
                        .text-red {
                          color: red;
                        }
                        .blue-list li::marker {
                          color: #00B68A; 
                        }

                        `
                    }
                </style>
            </div>
            <div className={'mt-3 d-flex justify-content-center mx-auto '}>
                <CButton onClick={() => setSetupSteps(1)} className={'px-3 text-white'}>
                    Get Started
                </CButton>
            </div>
        </>
    );
}

export default AssessmentPrompt;
