import {CImage, CRow} from "@coreui/react-pro";
import React from "react";
import UserProfileTag from "./UserProfileTag";
import HRIcon from "../../assets/images/shared-profile-hr.png";

const ProfessionalSkills = ({userProfileData}) => {
  return (
      <>
          {/*<div className="d-flex justify-content-between align-items-center">*/}
            <h6 className="fw-bold">Professional Skills</h6>
              {/*<span className="" style={{border: "solid,3px black"}}>*/}
              {/*</span>*/}
          {/*</div>*/}

          <CRow className="gx-3">
              {userProfileData?.professionalSkills?.map((skill, index)=>{
                  return(
                      <UserProfileTag key={index} tag={skill}/>
                  )
              }) ||  <UserProfileTag tag={"Nil"}/>}

          </CRow>
          <CImage src={HRIcon} style={{width: '100%'}}/>
      </>
  )
}

export default ProfessionalSkills
