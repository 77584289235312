import * as Yup from 'yup'

export const sponsorSchema = Yup.object().shape({
    purposeObjectives: Yup.string().required('Purpose and Objectives is required')
        .typeError('Purpose and Objectives is required'),
    sponsorshipType: Yup.string().required('Sponsorship type is required')
        .typeError('Sponsorship type is required'),
    sponsorshipAmount: Yup.string().required('Total sponsorship amount is required')
        .typeError('Total sponsorship amount is required'),
    numberOfSlots: Yup.string().required('Total No of Beneficiaries required')
        .typeError('Total No of Beneficiaries Required'),
    amount: Yup.string().required('Course Amount Required')
        .typeError('Course Amount Required'),
    letterOfReference: Yup.mixed().test('fileSize', 'File size is too large', (value) => {
        if (!value || !value[0]) return true; // No file selected is valid
        return value[0].size <= 2 * 1024 * 1024; // 2MB
    }),

    grantAmount: Yup.number().when('paymentType', {
        is: 'grant',
        then: Yup.number().required('Grant Amount rate is required').typeError('Grant Amount is required'),
        otherwise: Yup.number()
    }),
    paymentOccurrence: Yup.string().when('paymentType', {
        is: 'grant',
        then: Yup.string().required('Loan Tenure is required'),
        otherwise: Yup.string()
    }),
    interestRate: Yup.number().when('paymentType', {
        is: 'loan',
        then: Yup.number().required('Interest rate is required').typeError('Interest rate is required'),
        otherwise: Yup.number()
    }),
    loanAmount: Yup.string().when('paymentType', {
        is: 'loan',
        then: Yup.string().required('Loan Amount rate is required'),
        otherwise: Yup.string()
    }),
    loanTenure: Yup.string().when('paymentType', {
        is: 'loan',
        then: Yup.string().required('Loan Tenure is required'),
        otherwise: Yup.string()
    }),
    moratorium: Yup.string().when('paymentType', {
        is: 'loan',
        then: Yup.string().required('Loan Tenure is required'),
        otherwise: Yup.string()
    }),
    interestRateType: Yup.string().when('paymentType', {
        is: 'loan',
        then: Yup.string().required('Interest Rate Type is required'),
        otherwise: Yup.string()
    }),
    bankCharge: Yup.number().when('paymentType', {
        is: 'loan',
        then: Yup.number().required('Bank Charge is required').typeError('Bank Charge is required'),
        otherwise: Yup.number()
    }),
})
