import React, {useEffect, useState} from 'react';
import {CButton, CContainer, CFormSelect} from "@coreui/react-pro";
import {useGetAuditTrail} from "../../hooks/admin/adminApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import ShowLoading from "../../components/ShowLoading";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {fail_notify} from "../../constant";

const AuditTrail = () => {
    const {user} = useAppContext()
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const {isLoading, data, error, isError, refetch} = useGetAuditTrail(user?.accessToken, page, limit)
    
    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
        setUsers(data?.data?.data?.items)
    }, [data?.data?.data?.items, error?.response?.data?.message, isError]);

    useEffect(() => {
        refetch()
        // setUsers(data?.data?.data?.items)
    }, [page, limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer className='mb-5'>
            <h3 className='text-3'>Audit Trail</h3>
            <table className="table border-0" id="reports-table">
                <thead className="thead-inverse">
                <tr className="bg-transparent text-primary">
                    <th>User</th>
                    <th>User Agent</th>
                    <th>IP Address</th>
                    <th>Action</th>
                    <th>Status Code</th>
                    <th>Created At</th>
                </tr>
                </thead>
                <tbody>
                {
                    users?.map(({user, userAgent, ipAddress, action, statusCode, updatedAt}, index) => (
                        <tr key={index}>
                            <td className="td-single-line fw-bolder">{user}</td>
                            <td>{userAgent}</td>
                            <td>{ipAddress}</td>
                            <td>{action?.includes('?') ? action?.substring(0, action.indexOf('?')) : action}</td>
                            <td>{statusCode}</td>
                            <td>{updatedAt?.substring(0, 10)}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            {users?.length >= 10 &&
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className={"d-flex flex-row gap-3 w-23"}>
                        <div className={"m-2"}>Show</div>
                        <CFormSelect
                            aria-label="Default select example"
                            options={[
                                {label: '10', value: '10'},
                                {label: '25', value: '25'},
                                {label: '100', value: '100'},
                                {label: '200', value: '200'}
                            ]}
                            onChange={(e) => setLimit(e.target.value)}
                        />
                        <div className={"m-2"}>Entries</div>
                    </div>
                    <p>{data?.data?.data?.meta?.currentPage} / {data?.data?.data?.meta?.totalPages}</p>
                    <div>
                        <CButton onClick={() => setPage(page - 1)}
                                 className={`mx-3 text-white py-1 pb-2 px-3 ${page === 1 ? 'disabled' : ''}`}>
                            <BiLeftArrow/>
                        </CButton>
                        <CButton onClick={() => setPage(page + 1)}
                                 className={`mx-3 text-white py-1 pb-2 px-3 ${data?.data?.data?.meta?.currentPage === data?.data?.data?.meta?.totalPages ? 'disabled' : ''}`}>
                            <BiRightArrow/>
                        </CButton>
                    </div>
                </div>
            }
        </CContainer>
    )
}

export default AuditTrail;
