import React, {useCallback, useState} from 'react'
import {CContainer, CFormInput} from "@coreui/react-pro";
import {BiArrowFromTop, BiArrowToTop, BiChart} from "react-icons/bi";
import {FaAngleDown, FaClock, FaRegCalendarAlt, FaRegClock, FaUser} from "react-icons/fa";
import AttendanceTable from "../../components/attendance/AttendanceTable";
import AttendanceProfile from "../../components/attendance/AttendanceProfile";
import AttendancePersonalInformation from "../../components/attendance/AttendancePersonalInformation";
import AttendanceTimesheet from "../../components/attendance/AttendanceTimesheet";
import AttendanceActivity from "../../components/attendance/AttendanceActivity";
import {useGetAttendance} from "../../hooks/admin/adminApiHooks";
import {debounce} from "lodash";
import {fail_notify} from "../../constant";

const Attendance = () => {
    const [tap, setTap] = useState("Trainee")
    const [profileDetail, setProfileDetail] = useState(null)
    const [profTap, setProfTap] = useState(0)
    const [pageTap, setPageTap] = useState(0)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [date, setDate] = useState(getTodayDate())
    const [search, setSearch] = useState(null)
    const {isLoading, isSuccess, data, error, isError} = useGetAttendance(search, limit, page, date)

    const headers = [
        {name: tap},
        {name: "Course"},
        {name: "Clock-In", className: "text-primary", rightIcon: <BiArrowFromTop size={20}/>},
        {name: "Clock-Out", className: "text-danger", rightIcon: <BiArrowToTop size={20}/>},
        {name: "Total Hours"},
        {name: "Status"},
        {name: "Action"}
    ]
    const timeSheetHeaders = [
        {name: "Day"},
        {name: "Clock-In", className: "text-primary", rightIcon: <BiArrowFromTop size={20}/>},
        {name: "Clock-Out", className: "text-danger", rightIcon: <BiArrowToTop size={20}/>},
        {name: "Total Hours"},
        {name: "Required Hours"},
    ]
    const timeSheetItems = [
        {
            day: "Monday",
            clockIn: "09:00",
            clockOut: "09:00",
            totalHours: "02:31:00",
            requiredHours: "02:31:00"
        },
        {
            day: "Tuesday",
            clockIn: "09:00",
            clockOut: "09:00",
            totalHours: "02:31:00",
            requiredHours: "02:31:00"
        },
        {
            day: "Wednesday",
            clockIn: "09:00",
            clockOut: "09:00",
            totalHours: "02:31:00",
            requiredHours: "02:31:00"
        },
        {
            day: "Thursday",
            clockIn: "09:00",
            clockOut: "09:00",
            totalHours: "02:31:00",
            requiredHours: "02:31:00"
        },
        {
            day: "Friday",
            clockIn: "09:00",
            clockOut: "09:00",
            totalHours: "02:31:00",
            requiredHours: "02:31:00"
        },

    ]

    const profileTab = [
        {
            title: "Personal Info",
            icon: <FaUser/>
        },
        {
            title: "Timesheet",
            icon: <FaClock/>
        },
        {
            title: "Activity",
            icon: <BiChart/>
        },
    ]
    const activities = [
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: false ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: true ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: true ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: true ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: true ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
        {
            time: "11:00",
            name: "Chukwudi Obed",
            activity: "ended session & clocked out",
            icon: true ? <FaClock className="text-primary"/> : <FaRegClock className="text-danger"/>
        },
    ]

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Add leading zeros if month or day is less than 10
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };
    const debounceSearchHandler = useCallback(debounce(e => setSearch(e.target.value), 600), [])

    if (isError) {
        fail_notify(error?.response?.data?.message)
    }
    return (
        <>

            <CContainer className="mt-4">

                <div className="d-flex justify-content-between align-items-center">
                    {pageTap == 0 && <div className="bg-white p-1 d-flex text-black-50">
                        {/*<small
                            className={`${tap == "Trainer" ? "bg-primary text-white" : ""} cursor-pointer px-3 py-1`}
                            onClick={() => {
                                setTap("Trainer")
                            }}
                        >Trainers</small>*/}
                        <small
                            className={`${tap == "Trainee" ? "bg-primary text-white" : ""} cursor-pointer px-3 py-1`}
                            onClick={() => {
                                setTap("Trainee")
                            }}
                        >Trainees</small>
                    </div>}
                    <div className={`${pageTap == 0 ? "w-70" : "w-100"} d-flex align-items-center gap-2`}>
                        {pageTap == 0 && <div style={{height: "40px"}}
                                              className="me-4 border-1 w-100 border-black border ps-2 rounded d-flex align-items-center">

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-search" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>

                            <CFormInput
                                type="search"
                                id="search"
                                placeholder="Search by state of origin, name, gender, email "
                                autoComplete="off"
                                onChange={debounceSearchHandler}
                                className="bg-transparent border rounded-0 border-white border-0 shadow-none"
                            />
                        </div>}
                        <div className="d-flex justify-content-end w-100 gap-2">
                            <div style={{height: "45px"}}
                                 className="rounded border border-dark gap-2 border-1 px-0 d-flex text-black-50 align-items-center">

                                <input
                                    type="date"
                                    value={date}
                                    max={new Date().toISOString().split('T')[0]}
                                    onChange={handleDateChange}
                                    className="bg-transparent border custom-date-input border-0 px-2 mx-0 text-black-50"
                                />
                            </div>
                            {profTap != 2 && <div style={{height: "45px"}}
                                                  className="rounded border border-dark gap-2 px-3 border-1 d-flex text-black-50 align-items-center">
                                <FaRegCalendarAlt/>
                                <small className="text-black-50"><span
                                    className="text-black fw-bolder">SHIFT:</span>09:00
                                    - 12:00</small>
                                <FaAngleDown/>
                            </div>}
                        </div>
                    </div>
                </div>
                {
                    pageTap == 0 &&
                    <AttendanceTable
                        isLoading={isLoading}
                        isError={isError}
                        data={data}
                        setLimit={setLimit}

                        page={page}
                        setPage={setPage}
                        setProfileDetail={setProfileDetail}
                        setPageTap={setPageTap}
                        headers={headers}
                        items={data?.data?.data?.items}
                    />
                }

                {pageTap == 1 &&
                    <div className="h-100 d-flex gap-3 mt-3">
                        <AttendanceProfile profileTab={profileTab} profileDetail={profileDetail} userType={tap}
                                           setProfTap={setProfTap}
                                           profTap={profTap}/>
                        <div className="bg-white rounded-2 py-4 h-100 d-flex flex-fill px-5">
                            <div className="w-100 h-100">
                                <div className="text-3">
                                    {profTap == 0 && "Personal Information"}
                                    {profTap == 1 && "Timesheet"}
                                    {profTap == 2 && "Activity"}
                                </div>
                                {profTap == 0 && <AttendancePersonalInformation profileDetail={profileDetail}/>}
                                {profTap == 1 &&
                                    <AttendanceTimesheet headers={timeSheetHeaders} data={timeSheetItems}/>}
                                {profTap == 2 && <AttendanceActivity activities={activities}/>}
                                <br/> <br/>
                                <div className="d-flex gap-3 justify-content-center">
                                    <small
                                        onClick={() => setPageTap(0)}
                                        className="text-small cursor-pointer py-1 px-2 bg-transparent rounded-1 border-grey text-black-50">GO
                                        BACK</small>
                                    {profTap == 0 && <small
                                        className="text-small cursor-pointer py-1 px-2 bg-primary rounded-1 border border-primary text-white">
                                        SAVE CHANGES
                                    </small>}
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                }

            </CContainer>
        </>
    )
}

export default Attendance
