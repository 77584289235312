import React from 'react';
import {CButton, CCol, CModal, CModalBody, CModalTitle, CRow} from "@coreui/react-pro";
import {retrieveLoggedInUser} from "../../localStorage";
import moment from "moment";
import {formatCurrency} from "../../constant";

function JobPreview({data, visible, setVisible, handleJobPosting}) {

    const user = retrieveLoggedInUser()

    return (
        <CModal size={"lg"} alignment="center" visible={visible} onClose={() => setVisible(false)}>

            <CModalBody style={{backgroundColor: '#F5F5F5'}} className="rounded">
                <>
                    <CModalTitle className="mb-2">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <p></p>
                            <h5>Job Preview</h5>
                            <p className="small-text-gray cursor-pointer" onClick={() => setVisible(false)}>Close</p>
                        </div>
                    </CModalTitle>
                    <div className="d-flex flex-row justify-content-start shadow-sm p-3 bg-white rounded">
                        {/*<div className="w-25">*/}
                        {/*    <CImage src={user?.data?.logoUrl || DefaultLogo} alt="Logo"/>*/}
                        {/*</div>*/}
                        <div>
                            <h5 className="text-primary">{user?.data?.name}</h5>
                            <p className="m-0">{user?.data?.email}</p>
                        </div>
                    </div>
                    <div className="shadow-sm p-3 my-3 bg-white rounded">
                        <CRow className="my-3">
                            <CCol md={3}>
                                <h6 className="text-primary">Job Title</h6>
                                <p className="m-0">{data?.title || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Number of Vacancies</h6>
                                <p className="m-0">{data?.numberOfVacancy || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Job Type</h6>
                                <p className="m-0">{data?.jobType || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Tags</h6>
                                <p className="m-0">{
                                    data?.tags?.map((item, index) => (
                                        item !== "" && <span
                                            key={index}
                                            className="border border-1 rounded py-1 px-2 me-1">
                                            {item}
                                        </span>
                                    ))
                                }</p>
                            </CCol>
                        </CRow>
                        <CRow className="my-3">
                            <CCol md={3}>
                                <h6 className="text-primary">Experience Level</h6>
                                <p className="m-0">{data?.levelOfExperience || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Dead Line</h6>
                                <p className="m-0">{moment(data?.closingDate).format('L') || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Location</h6>
                                <p className="m-0">{data?.location || '-'}</p>
                            </CCol>
                            <CCol md={3}>
                                <h6 className="text-primary">Salary</h6>
                                <p className="m-0">&#8358;{formatCurrency(data?.salary) || '-'}</p>
                            </CCol>
                        </CRow>
                        <CRow className="my-3">
                            <CCol md={6}>
                                <h6 className="text-primary">Requirements</h6>
                                <p className="m-0" style={{overflowWrap: "break-word"}}>{
                                    data?.requirement?.map(({value}, index) => (
                                        value !== "" && <span
                                            key={index}
                                            className="border border-1 rounded py-1 px-2 me-1">
                                            {value}
                                        </span>
                                    ))
                                }</p>
                            </CCol>
                            <CCol md={6}>
                                <h6 className="text-primary">Responsibilities</h6>
                                <p className="m-0">{
                                    data?.responsibilities?.map(({value}, index) => (
                                        value !== "" && <span
                                            key={index}
                                            className="border border-1 rounded py-1 px-2 me-1">
                                            {value}
                                        </span>
                                    ))
                                }</p>
                            </CCol>
                        </CRow>
                        <CRow className="my-3">
                            <CCol md={12}>
                                <h6 className="text-primary">Job Description</h6>
                                <p className="m-0">{data?.description || '-'}</p>
                            </CCol>
                        </CRow>
                    </div>
                </>
                <div className="d-flex justify-content-center">
                    <CButton className="w-50 text-white" onClick={() => handleJobPosting(data)}>Publish</CButton>
                </div>
            </CModalBody>
        </CModal>
    );
}

export default JobPreview;