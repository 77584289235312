import React from 'react';
import {CImage} from "@coreui/react-pro";
import {formatNumber} from "../../../constant";
import TestTakenIcon from "../../../assets/icons/total-tests-icon.png";
import TestPassedIcon from "../../../assets/icons/total-passed-icon.png";
import TestFailedIcon from "../../../assets/icons/total-failed-icon.png";

function TrainersHeader({totalBeneficiaries, active, absentee}) {

    const stats = [
        {
            total: totalBeneficiaries,
            title: "Total Trainees",
            icon: TestTakenIcon
        },
        {
            total: `${active}%`,
            title: "Active Participants %",
            icon: TestPassedIcon
        },
        {
            total: `${absentee}%`,
            title: "Absentee Participants %",
            icon: TestFailedIcon
        },
    ]

    return (
        <>
            <div className="py-2 mt-0 mx-1">
                <div className="d-flex flex-row bg-white shadow-sm rounded py-3 px-1">
                    {
                        stats?.map((stat, index) =>
                            <div className="w-100 bg-white py-3" key={index}>
                                <div className={`shadow-none px-5 ${index === 1 && 'assessment-stat-border'}`}>
                                    <div className="d-flex flex-row justify-content-start text-decoration-none">
                                        <div
                                            className="d-flex flex-row justify-content-center align-items-center">
                                            <CImage src={stat?.icon} className="assessment-icon"/>
                                        </div>
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center ms-3 py-2">
                                            <p>{stat?.title}</p>
                                            <h2 className="text-2 text-decoration-none mt-1 mb-0">{formatNumber(stat?.total)}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default TrainersHeader;