import React, {useEffect, useState} from "react";
import {
    CFormCheck, CFormSelect,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react-pro";
import TextEditor from "../TextEditor";
import {useUpdateQuestionQuery} from "../../hooks/admin/adminApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FaMinusCircle, FaPlus} from "react-icons/fa";

function EditTest({editData, setVisible}) {

    const [question, setQuestion] = useState(null)
    const [options, setOptions] = useState(null)
    const {isLoading, isSuccess, data, isError, error, mutate} = useUpdateQuestionQuery(editData?.id)

    const schema = yup.object().shape({
        question: yup.string().typeError('Question is required').required('Question is required'),
        level: yup.string().typeError('Question level is required').required('Question level is required'),
        options: yup.array().of(
            yup.object().shape({
                text: yup.string().typeError('Option text is required').required('Option text is required'),
            })
        ),
    });
    const {
        register,
        trigger, // Add trigger function
        setValue,
        watch,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: editData,
    });

    useEffect(() => {
        setQuestion(editData?.question)
        setOptions(editData?.options)

    }, [])
    const handleQuestionUpdate = () => {
        setValue('question', question)
        setValue('options', options)

        trigger().then((isValid) => {
            const questionUpdate = {
                question: question,
                options: options,
                level: watch('level'),
                isMultipleChoice: watch("isMultipleChoice")
            }
            if (isValid) {
                //console.log(questionUpdate)
                //return
                mutate(questionUpdate)

            }
        });
    }
    const handleAddOption = () => {
        setOptions([...options, {text: '', isCorrect: false}]);
    };

    const handleRemoveOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };
    const handleOptionCorrectChange = (event, index) => {
        const newOptions = [...options];
        newOptions[index].isCorrect = event.target.checked;
        setOptions(newOptions);
    };

    const handleOptionTextChange = (editorState, index) => {
        const newOptions = [...options];
        if(!editorState || editorState == "<p><br></p>"){
            newOptions[index].text = null;
        }else{
            newOptions[index].text = editorState;
        }
        setOptions(newOptions);
    };

    const handleQuestionChange = (editorState) => {

        if(!editorState || editorState == "<p><br></p>"){
            setQuestion(null);
            return
        }
        setQuestion(editorState);

    };

    useEffect(() => {
        if (isSuccess) {
            success_notify('Questions updated successfully')
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isLoading, isSuccess, data, isError, error, mutate])


    return (
        <CModal size="lg" visible={true} onClose={() => setVisible(false)}>
            <CModalHeader>
                <CModalTitle>Edit Question</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <strong>Assessment Level</strong>
                <CFormSelect
                    aria-label="Default select example"
                    className="rounded-0"
                    {...register(`level`)}
                >
                    <option value="">Select question Level</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                </CFormSelect>
                {errors.level && <p className="text-danger">{errors.level.message}</p>}
                <br/>
                <CFormCheck className='fw-semibold' id="flexCheckDefault" label="Multiple choice type question"
                            {...register(`isMultipleChoice`)}
                />
                <br/>
                <strong>Question</strong>
                <TextEditor
                    editorState={question}
                    setEditorState={(editorState) => handleQuestionChange(editorState)}
                />
                {errors.question && <p className="text-danger">{errors.question.message}</p>}
                {
                    options?.map((option, index) => {
                        return (
                            <div key={index}>
                                <br/>
                                 <div className="d-flex justify-content-between">
                    <strong>Option {index + 1}</strong>
                    <CFormCheck id="flexCheckDefault" label="Click if correct answer"
                                checked={option.isCorrect}
                                onChange={(event) => handleOptionCorrectChange(event, index)}/>
                </div>
                            <TextEditor
                                editorState={option?.text}
                                setEditorState={(editorState) => handleOptionTextChange(editorState, index)}
                            />
                                {index > 1 && <div onClick={() => handleRemoveOption(index)}
                                                   className="text-danger cursor-pointer">
                                    <FaMinusCircle
                                        className="text-red"/> Remove option
                                </div>}
                                {errors?.options?.[index]?.text &&
                                    <p className="text-danger">{errors.options[index].text.message}</p>}
                            </div>
                        )
                    })
                }
                <CLoadingButton className="text-white mt-2 px-3" onClick={handleAddOption}><FaPlus
                    className="text-white"/> Add Option</CLoadingButton>
            </CModalBody>
            <div className="d-flex justify-content-end pb-2 px-3">
                <CLoadingButton loading={isLoading} disabled={isLoading} className="text-white bg-primary"
                                onClick={handleQuestionUpdate}>Submit</CLoadingButton>
            </div>
        </CModal>
    );
}

export default EditTest;
