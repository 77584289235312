import React from 'react';
import {CCol, CRow} from "@coreui/react-pro";
import {HiOutlineLightBulb} from "react-icons/hi";

function CvTips() {
    return (
        <>
            <CRow className="g-3 py-2">
                <CCol>
                    <div
                        className="border rounded text-black-50 bg-transparent d-flex  cursor-pointer justify-content-center position-relative py-2 px-3 tooltip-btn">
                        <div>
                            <HiOutlineLightBulb size={30} color={'currentColor'} />
                        </div>
                        <div className="">
                            <div className="ps-2">Tips</div>
                        </div>
                        <div className="rounded position-absolute bg-primary text-white tooltip-text pe-3 ">
                            <div
                                className="border rounded m-4 text-white bg-transparent border-white d-flex px-3 position-relative py-2 px-3 tooltip-btn">
                                <div>
                                    <HiOutlineLightBulb size={30} color={'currentColor'} />
                                </div>
                                <div className="">
                                    <div className="ps-2">Tips</div>
                                </div>

                            </div>
                            <ol>
                                <li>Your profile is always placed at the top of your resume.</li>
                                <li>Having the position and job description in mind, sum up who you are in a few
                                    powerful sentences. However, try to be as succinct as you can. Avoid using
                                    one-liners.
                                </li>
                                <li>In order to make a strong profile, you should at the very least list your
                                    accomplishments, character traits, ambitions, goals, and what you are
                                    searching
                                    for.
                                </li>
                            </ol>
                        </div>
                    </div>
                </CCol>

            </CRow>
            <br/>
        </>
    );
}

export default CvTips;