import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ErrorReports from "../../components/ErrorReports";
import {CCol, CForm, CFormFeedback, CFormInput, CFormSelect, CLoadingButton, CRow} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useCreateTeam, useGetEmployerRoles} from "../../hooks/admin/adminApiHooks";
import {success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";
import * as Yup from "yup";

const createTeamSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string().matches(/^(\+)?(234|0)[0-9]{10}$/, 'Must be a valid phone number'),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    role: Yup.number().required('Role ID is required'),
})
const CreateEmployerTeam = () => {
    const navigate = useNavigate()
    const [rolesData, setRolesData] = useState(null)

    const {
        isLoading: isRolesLoading,
        isSuccess: isRolesSuccess,
        isError: isRolesError,
        error: rolesError,
        data: roles
    } = useGetEmployerRoles()

    const {
        isLoading: isCreateAdminLoading,
        isSuccess: isCreateAdminSuccess,
        isError: isCreateAdminError,
        error: createAdminError,
        mutate
    } = useCreateTeam()

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(createTeamSchema),
    })

    const handleSignUp = (data) => {
        mutate(data)
    }

    useEffect(() => {
        setRolesData(roles?.data?.data)
    }, [roles])

    if (isCreateAdminSuccess) {
        success_notify('Team successfully created')
        navigate('/employer/admins')
    }
    return (
        <div className="d-flex flex-column justify-content-center align-items-center bg-white w-60 mx-auto px-10">
            <h3 className="my-5">Create Team</h3>
            {
                (isRolesError) &&
                <ErrorReports error={rolesError}/>
            }
            {
                isCreateAdminError &&
                <ErrorReports error={createAdminError}/>
            }
            <CForm noValidate className="needs-validation  mb-5" onSubmit={handleSubmit(handleSignUp)}>
                <div className="d-flex flex-column gap-lg-4">
                    <CRow>
                        <CCol md={6}>
                            <CFormInput
                                type="text"
                                id="firstName"
                                placeholder="Enter First Name"
                                {...register('firstName')}
                                invalid={!!errors.firstName}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.firstName}>
                                {errors.firstName?.message}
                            </CFormFeedback>
                        </CCol>
                        <CCol md={6}>
                            <CFormInput
                                type="text"
                                id="lastName"
                                placeholder="Enter Last Name"
                                {...register('lastName')}
                                invalid={!!errors.lastName}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.lastName}>
                                {errors.lastName?.message}
                            </CFormFeedback>
                        </CCol>
                        <CCol md={6}>
                            <CFormInput
                                type="text"
                                id="phoneNumber"
                                placeholder="Enter Phone Number"
                                {...register('phoneNumber')}
                                invalid={!!errors.phoneNumber}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.phoneNumber}>
                                {errors.phoneNumber?.message}
                            </CFormFeedback>
                        </CCol>
                        <CCol md={6}>
                            <CFormInput
                                type="text"
                                id="email"
                                placeholder="Enter Email Address"
                                {...register('email')}
                                invalid={!!errors.email}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.email}>
                                {errors.email?.message}
                            </CFormFeedback>
                        </CCol>
                        <div >
                            <div className={"m-2"}>Choose A Role</div>
                            <CFormSelect
                                aria-label="Default select example"
                                defaultValue={''}
                                {...register('role')}
                                options={rolesData?.map((item, index) => ({
                                    label: item.name,
                                    value: item.id,
                                }))}
                            />
                            <CFormFeedback invalid={!!errors.role}>
                                {errors.role?.message}
                            </CFormFeedback>
                        </div>

                    </CRow>
                    <div className="text-center">
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-50 py-2 text-center"
                            loading={isCreateAdminLoading}
                            // disabled={isCreateAdminError}
                        >
                            Create Admin
                        </CLoadingButton>
                    </div>
                </div>
            </CForm>
        </div>
    )
}

export default CreateEmployerTeam