import {StyleSheet} from "@react-pdf/renderer";

const ScatteredGrayStyles = ({mainColor}) => {
    return StyleSheet.create({
        topLeftBox: {
            top: '0',
            left: '0',
            width: '243px',
            height: '55px',
            backgroundColor: mainColor
        },
        rightBox: {
            width: '336px',
            height: '120px',
            backgroundColor: mainColor,
        },
        leftLine: {
            width: 0,
            height: '240px',
            marginTop: '10px',
            backgroundColor: mainColor,
            border: '1px solid #000000'
        },
        name: {
            fontSize: 42,
            color: mainColor,
        },
        contactTag: {
            fontSize: '50px',
            color: '#ACA6A6'
        },
        contactText: {fontSize: '12px', fontWeight: 'light'},
        emailBorder: {
            width: '3px',
            height: '57px',
            backgroundColor: "#ACA6A6",
            border: '1px solid #ACA6A6'
        },
        phoneBorder: {
            width: '3px',
            height: '57px',
            backgroundColor: "#ACA6A6",
            border: '1px solid #ACA6A6'
        },
        bodyText: {
            fontSize: '14px'
        },
        leftPaneBorder: {
            width: '1px',
            height: '313px',
            backgroundColor: "#000000",
            border: '1px solid #000000'
        },
        rightPane: {
            left: '70px',
            top: '0px',
            width: '200px'
        },
    });
}

export default ScatteredGrayStyles