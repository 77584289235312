import React, {useEffect, useState} from 'react'
import {CContainer, CFormLabel, CFormSelect} from "@coreui/react-pro";
import TrainersHeader from "../../../../trainers/pages/dashboard/TrainersHeader";
import TraineesUnderTrainerTable from "../../../../trainers/pages/dashboard/TraineesUnderTrainerTable";
import {useGetAllCohorts, useGetCohortBeneficiaries} from "../../../../hooks/admin/adminApiHooks";

const TrainersDashboard = ({user}) => {
    const [cohorts, setCohorts] = useState([])
    const [beneficiaries, setBeneficiaries] = useState([])
    const [cohortId, setCohortId] = useState(1)
    const { data: cohortsData } = useGetAllCohorts()
    const { data: beneficiariesData, refetch } = useGetCohortBeneficiaries(cohortId)

    useEffect(() => {
        setCohorts(cohortsData?.data?.data?.items)
    }, [cohortsData]);

    useEffect(() => {
        refetch()
    }, [cohortId]);

    useEffect(() => {
        setBeneficiaries(beneficiariesData?.data?.data?.items)
    }, [beneficiariesData]);

    const headers = [
        {name: "Name"},
        {name: "Email", className: "text-primary"},
        {name: "Phone Number", className: "text-danger"},
        {name: "Occupation"},
        {name: "Location"},
    ]

    return <>
        <div className="ms-4">
            <h5 className="text-[12px] fw-bolder ps-1">Welcome, {user?.data?.firstName} {user?.data?.lastName}</h5>
        </div>
        <CContainer className="mb-5">
            <TrainersHeader
                totalBeneficiaries={beneficiaries?.length}
                active={beneficiaries?.length === 0 ? 0 : 76}
                absentee={beneficiaries?.length === 0 ? 0 : 24}
            />
        </CContainer>
        <CContainer className="mt-4">
            <div className={`d-flex flex-row  justify-content-between gap-20`}>
                <div>
                    <h3 className={'sub-title mb-0'}>List of Trainees</h3>
                    <p className={'text-[11px] text-black mt-0'}>{beneficiaries?.length} Trainees</p>
                </div>
                <div>
                    <div className="me-4 w-100 ps-2">
                        <CFormLabel className="m-0">Cohort</CFormLabel>
                        <CFormSelect
                            id="search"
                            className="form-control"
                            onChange={(e) => setCohortId(e?.target?.value)}
                        >
                            <option>Select</option>
                            {
                                cohorts?.map(({id, displayName}, index) => (
                                    <option value={id} key={index}>
                                        {displayName}
                                    </option>
                                ))
                            }
                        </CFormSelect>
                    </div>
                </div>
            </div>

            <TraineesUnderTrainerTable headers={headers} items={beneficiaries}/>
        </CContainer>
    </>
}

export default TrainersDashboard

