const employerPreloadedData = (stakeholderData) => {
  return {
    tin: stakeholderData?.tin,
    email: stakeholderData?.email,
    phoneNumber: stakeholderData?.phoneNumber,
    name: stakeholderData?.name,
    dateEstablished: stakeholderData?.dateEstablished,
    organizationStatus: stakeholderData?.organizationStatus,
    address: stakeholderData?.address,
    institutionsType: stakeholderData?.institutionsType,
    stakeholderType: stakeholderData?.stakeholderType?.name,
    industryType: stakeholderData?.industryType,
    createdAt: stakeholderData?.createdAt,
    isVerified: stakeholderData?.isVerified,
    role: stakeholderData?.role,
    state: stakeholderData?.state,
    city: stakeholderData?.city,
    zipCode: stakeholderData?.zipCode,
    companySize: stakeholderData?.companySize,
    contactPersonPhoneNumber: stakeholderData?.contactPersonPhoneNumber,
    contactPersonEmail: stakeholderData?.contactPersonEmail,
    contactPersonFirstName: stakeholderData?.contactPersonFirstName,
    contactPersonLastName: stakeholderData?.contactPersonLastName
  }
}

export default employerPreloadedData
