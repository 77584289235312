import React from 'react'
import TrainingDetailSection from "../../components/training/TrainingDetailSection";
import {

    useGetTrainingDetailQuery,
} from "../../hooks/user/userApiHooks";
import {useParams} from "react-router-dom";
import AuthRoute from "../../components/User/AuthRoute";
import GoBack from "../../components/GoBack";
import {CContainer} from "@coreui/react-pro";

function TrainingDetail(props) {

    const { slug } = useParams();

    const trainingDetail = useGetTrainingDetailQuery(slug)

    return (
        <>
            <AuthRoute protectedRoute={'/user/training-detail/'} openRoute={'/training-detail/'}/>
            <br/>
                <CContainer className=" px-lg-4">
                    <GoBack/>
                    <br/>
                    <TrainingDetailSection trainingDetail={trainingDetail}/>
                </CContainer>
            <br/>
        </>
    )
}

export default TrainingDetail
