import {CCol, CRow} from "@coreui/react-pro";
import React, {useEffect, useState} from "react";
import TransferIcon from "../../assets/icons/transfer.svg";
import ShowLoading from "../../components/ShowLoading";
import TraineeToCohortModal from "../../components/modals/TraineeToCohortModal";
import {Link} from "react-router-dom";
import TraineeToTrainerModal from "../../components/modals/TraineeToTrainerModal";
import TableNavigation from "../../components/TableNavigation";
import {useGetAllTrainees} from "../../hooks/admin/adminApiHooks";
import KPIManagerBanner from "./KPIManagerBanner";
import AddCohortModal from "../../components/modals/AddCohortModal";
import AddTrainerModal from "../../components/modals/AddTrainerModal";

const BeneficiariesDashboard = ({cohortModalVisible, setCohortModalVisible, trainerModalVisible, setTrainerModalVisible}) => {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [applicantId, setApplicantId] = useState(null)
    const [cohortVisible, setCohortVisible] = useState(false)
    const [trainerVisible, setTrainerVisible] = useState(false)

    const {data, isLoading, refetch} = useGetAllTrainees(page, limit)

    useEffect(() => {
        refetch()
    }, [page, limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    return <div>
        <CRow className="mb-3 bg-white p-3">
            <KPIManagerBanner
                setTrainerModalVisible={setTrainerModalVisible}
                setCohortModalVisible={setCohortModalVisible}
                title={'Beneficiaries'}
                totalItems={data?.data?.data?.meta?.totalItems}
            />
        </CRow>
        <CRow>
            <CCol md={12}>
                <table className="table border-0" id="reports-table">
                    <thead className="thead-inverse">
                    <tr className="bg-transparent text-primary">
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Occupation</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data?.data?.data?.items?.map(({id, firstName, lastName, email, phoneNumber, occupation}, index) => {
                            return <tr key={index}>
                                <td>
                                    <div className="d-flex flex-row align-items-center pt-2">
                                        <input type="checkbox" className="me-2 larger-checkbox"/>
                                        <h6 className="m-0">{firstName} {lastName}</h6>
                                    </div>
                                </td>
                                <td>{phoneNumber}</td>
                                <td>{email}</td>
                                <td>{occupation}</td>
                                <td className="td-single-line">
                                    <img src={TransferIcon} alt="Transfer"/>
                                    <span className="px-2 text-primary d-inline" onClick={() => {
                                        setApplicantId(id)
                                        setCohortVisible(true)
                                    }}>Assign Cohort</span>|
                                    <span className="px-2 text-primary d-inline" onClick={() => {
                                        setApplicantId(id)
                                        setTrainerVisible(true)
                                    }}>Assign Trainer</span>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                <TableNavigation
                    page={page}
                    setLimit={setLimit}
                    setPage={setPage}
                    totalPages={data?.data?.data?.meta?.totalPages}
                />
            </CCol>
        </CRow>

        <TraineeToCohortModal
            setVisible={setCohortVisible}
            visible={cohortVisible}
            applicantId={applicantId}
        />

        <TraineeToTrainerModal
            visible={trainerVisible}
            setVisible={setTrainerVisible}
            applicantId={applicantId}
        />

        <AddCohortModal
            setVisible={setCohortModalVisible}
            visible={cohortModalVisible}
        />

        <AddTrainerModal
            setVisible={setTrainerModalVisible}
            visible={trainerModalVisible}
        />
    </div>
}

export default BeneficiariesDashboard