import React from 'react';
import {sortTable} from "../../constant";
import {BiSort} from "react-icons/bi";
import {CFormCheck, CFormInput, CFormSelect, CImage} from "@coreui/react-pro";
import FailIcon from "../../assets/icons/assessment-fail-icon.png";
import PassIcon from "../../assets/icons/assessment-pass-icon.png";
import Table from "../tables/table";
import {FaEdit, FaTrashAlt} from "react-icons/fa";
import avatarUrl from "../../assets/images/jobseeker-header.jpg";


function AttendanceTimesheet({headers, data}) {
    return (
        <div>
            <Table headers={headers}>
                {data?.map((item, index) => (
                    <tr className="cursor-pointer bg-white border"
                        key={index}
                        //onClick={() => setIsAnalysisVisible(true)}
                    >
                        <td className="td-single-line fw-light">{item?.day}</td>
                        <td className="fw-light">{item?.clockIn}</td>
                        <td className="fw-light text-danger">{item?.clockOut}</td>
                        <td className="fw-light">{item?.totalHours}</td>
                        <td className="fw-light">{item?.requiredHours}</td>

                    </tr>
                ))}
            </Table>
        </div>
    );
}

export default AttendanceTimesheet;
