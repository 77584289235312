import React, {useEffect, useState} from 'react'
import {useDeleteTestimonial, useGetTestimonials} from "../../hooks/user/userApiHooks";
import ShowLoading from "../../components/ShowLoading";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {success_notify} from "../../constant";
import DataNotFound from "../../components/DataNotFound";
import EditTestimonialModal from "../../components/modals/EditTestimonialModal";
import {CButton, CFormSelect} from "@coreui/react-pro";

const AllTestimonials = () => {

    const [testimonials, setTestimonials] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const {data, isLoading, refetch} = useGetTestimonials(page, limit)
    const {isSuccess, mutate} = useDeleteTestimonial()
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [testimonial, setTestimonial] = useState('')
    const [tid, setTid] = useState('')
    const [avatar, setAvatar] = useState(null)

    useEffect(() => {
        setTestimonials(data?.data?.data?.items)
    }, [data])

    useEffect(() => {
        if (isSuccess) {
            success_notify("Testimonial Deleted Successfully!")
        }
    }, [isSuccess])

    useEffect(() => {
        refetch()
    }, [page, limit]);

    if (isLoading) {
        return <ShowLoading/>
    }

    const updateTestimonial = (testimonyId) => {
        const selected = testimonials?.filter(({id}) => id === testimonyId)
        setName(selected[0]?.fullName)
        setTitle(selected[0]?.jobTitle)
        setTestimonial(selected[0]?.testimonial)
        setAvatar(selected[0]?.avatar)
        setTid(testimonyId)
        setVisible(true)
    }

    const deleteTestimonial = (tid) => {
        setTestimonials(testimonials?.filter(({id}) => id !== tid))
        mutate(tid)
    }

    return (
        <div>
            <h2 className="text-2 text-primary mb-4">Testimonials</h2>
            {testimonials?.length > 0 &&
                <>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>No.</th>
                            <th>Name</th>
                            <th>Job Title</th>
                            <th>Testimonial</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {testimonials?.map(({id, fullName, jobTitle, testimonial}, index) => (
                            <tr key={index}>
                            <td className="td-single-line">{index + 1}</td>
                                <td className="td-single-line">{fullName}</td>
                                <td className="td-single-line">{jobTitle}</td>
                                <td style={{width: '115%'}}>{testimonial}</td>
                                <td className="td-single-line">
                                    <button className="btn btn-primary btn-sm px-3 mx-1 text-white" onClick={()=> updateTestimonial(id)}>Edit</button>
                                    <button className="btn btn-danger btn-sm px-3 mx-1 text-white" onClick={() => deleteTestimonial(id)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {testimonials?.length > 10 &&
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className={"d-flex flex-row gap-3 w-23"}>
                                <div className={"m-2"}>Show</div>
                                <CFormSelect
                                    aria-label="Default select example"
                                    options={[
                                        {label: '10', value: '10'},
                                        {label: '25', value: '25'},
                                        {label: '100', value: '100'},
                                        {label: '200', value: '200'}
                                    ]}
                                    onChange={(e) => setLimit(e.target.value)}
                                />
                                <div className={"m-2"}>Entries</div>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <CButton onClick={() => setPage(page - 1)}
                                         className={`mx-3 text-white py-1 pb-2 px-3 ${page === 1 ? 'disabled' : ''}`}>
                                    <BiLeftArrow/>
                                </CButton>
                                <CButton onClick={() => setPage(page + 1)}
                                         className="mx-3 text-white py-1 pb-2 px-3">
                                    <BiRightArrow/>
                                </CButton>
                            </div>
                        </div>
                    }
                    <EditTestimonialModal
                        setVisible={setVisible}
                        visible={visible}
                        name={name}
                        title={title}
                        testimonial={testimonial}
                        tid={tid}
                        avatar={avatar}
                    />
                </>
            }
            {testimonials?.length === 0 && <DataNotFound/>}
        </div>
    )
}

export default AllTestimonials