import * as React from 'react';
import {createContext, useContext, useEffect, useState} from 'react';
import {
    useGetAllQuestionnairesQuery, useGetAllStates,
    useGetCultureFitTestQuery,
    useGetPersonalityTestQuery,
    useGetSoftSkillTestQuery
} from "../../hooks/user/userApiHooks";
import {useGetSkillSetReports} from "../../hooks/admin/adminApiHooks";
import TestTakenIcon from "../../assets/icons/total-tests-icon.png";
import TestPassedIcon from "../../assets/icons/total-passed-icon.png";
import TestFailedIcon from "../../assets/icons/total-failed-icon.png";
import appApiCalls from "../../services/api";
import ShowLoading from "../../components/ShowLoading";

const ReportContext = createContext(null);

const ReportProvider = ({children}) => {
    const [page, setPage] = useState(1)
    const [fromPercent, setFromPercent] = useState('0')
    const [toPercent, setToPercent] = useState('100')
    const [assessmentCategory, setAssessmentCategory] = useState('')
    const [usersData, setUsersData] = useState({})
    const [personalityData, setPersonalityData] = useState([])
    const [cultureFitData, setCultureFitData] = useState([])
    const [softSkillsData, setSoftSkillsData] = useState([])
    const [limit, setLimit] = useState(20)
    const states = useGetAllStates()
    const [stateOfOrigin, setStateOfOrigin] = useState('')
    const {
        data: allSkills,
        isSuccess: isSkill
    } = useGetAllQuestionnairesQuery()

    const [quizType, setQuizType] = useState(allSkills?.data?.data[0]?.title)
    const {
        isLoading,
        isSuccess,
        data,
    } = useGetSkillSetReports(page, limit, quizType, fromPercent, toPercent, stateOfOrigin)

    const {data: statData} = useGetSkillSetReports(1, 10, " ", "0", "100")
    const {data: personalityTestData} = useGetPersonalityTestQuery(fromPercent, toPercent, assessmentCategory)
    const {data: cultureFitTestData} = useGetCultureFitTestQuery(fromPercent, toPercent, assessmentCategory)
    const {data: softSkillSetTestData} = useGetSoftSkillTestQuery(fromPercent, toPercent, assessmentCategory)
    const [isAnalysisVisible, setIsAnalysisVisible] = useState(false)
    const [userTestData, setUserTestData] = useState(null)

    const stats = [
        {
            total: statData?.data?.data?.assessments?.totalPassed + statData?.data?.data?.assessments?.totalFailed,
            title: "Total Test Taken by users",
            icon: TestTakenIcon
        },
        {
            total: statData?.data?.data?.assessments?.totalPassed,
            title: "Total Passed Test",
            icon: TestPassedIcon
        },
        {
            total: statData?.data?.data?.assessments?.totalFailed,
            title: "Total Failed Test",
            icon: TestFailedIcon
        },
    ]

    useEffect(() => {
        setUsersData(data)
    }, [data])

    useEffect(() => {
        if (isSkill) {
            setQuizType(" ")
        }
    }, [allSkills, isSkill]);

    useEffect(() => {
        setPersonalityData(personalityTestData)
        setCultureFitData(cultureFitTestData)
        setSoftSkillsData(softSkillSetTestData)
    }, [personalityTestData, cultureFitTestData, softSkillSetTestData])

    useEffect(() => {
        const updateUsers = async () => {
            let data
            if (quizType === "Personality Test") {
                data = await appApiCalls.getPersonalityResult(fromPercent, toPercent, assessmentCategory)
                setPersonalityData(data)
            }
            if (quizType === "Culture Fit Test") {
                data = await appApiCalls.getCultureFitResult(fromPercent, toPercent, assessmentCategory)
                setCultureFitData(data)
            }
            if (quizType === "Soft Skills Test") {
                data = await appApiCalls.getSoftSkillResult(fromPercent, toPercent, assessmentCategory)
                setSoftSkillsData(data)
            }
        }
        updateUsers()
    }, [assessmentCategory, fromPercent, quizType, toPercent])

    if (isLoading) {
        return <ShowLoading/>
    }

    const handleChangeAssessment = (event) => {
        setQuizType(event.target.value)
        setPage(1)
        setAssessmentCategory('')
    }

    const handleSelectUser = (data) => {
        setUserTestData(data)
        setIsAnalysisVisible(true)
    }

    const handleChangeState = (state) => {
        setStateOfOrigin(state)
    }

    const value = {
        isAnalysisVisible,
        isSuccess,
        stats,
        allSkills,
        setFromPercent,
        quizType,
        personalityData,
        cultureFitData,
        softSkillsData,
        data,
        fromPercent,
        toPercent,
        setToPercent,
        setAssessmentCategory,
        usersData,
        userTestData,
        setIsAnalysisVisible,
        setLimit,
        page,
        setPage,
        states,
        handleChangeAssessment,
        handleSelectUser,
        handleChangeState

    }

    return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
}

export const useReportContext = () => useContext(ReportContext)

export default ReportProvider
