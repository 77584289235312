import React from 'react';
import {HiOutlinePencilAlt} from "react-icons/hi";
import {RiDeleteBin6Line} from "react-icons/ri";

function EducationCard({setActiveSchoolId, schoolId, school,deleteExperience}) {
    return (
        <>
            <div
                className="d-flex px-4 py-2 justify-content-between align-content-center rounded shadow-sm-user px-10 mt-4">
                <div className="d-flex">
                    <div>
                        <small className="fw-bold ">
                            {school.schoolName}
                        </small>
                        <p>{school.degree}</p>
                    </div>
                </div>
                <div className={"d-flex gap-3"}>
                    <div className="fw-bold" onClick={() => setActiveSchoolId(schoolId)}>
                        <HiOutlinePencilAlt size={16} color={'currentColor'}/>
                    </div>
                    <div className="fw-bold" onClick={() => deleteExperience(schoolId)}>
                        <RiDeleteBin6Line size={16} color={'currentColor'}/>
                    </div>
                </div>

            </div>
        </>
    );
}

export default EducationCard;