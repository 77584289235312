import React from 'react';
import {BiArrowBack} from "react-icons/bi";
import CVProgress from "./CVProgress";
import {CLoadingButton} from "@coreui/react-pro";
import {useCVBuilderContext} from "../../../user/cv-builder/CVBuilderProvider";

function CvBuilderHeader({generatePdf}) {
    const {status, setStatus, navigate, isLoading: isDownloading, setSaveCVForJob} = useCVBuilderContext()

    return (
        <>
            <div className="d-flex flex-row justify-content-between mb-3">
                <div className="cursor-pointer" onClick={() => {
                    navigate(`/user/resume-builder`, {
                        replace: true,
                    })
                }}>
                    <BiArrowBack/> Go Back
                </div>
                <div className={'d-flex gap-3 -mt-3'}>
                    <CLoadingButton
                        onClick={() => {
                            setSaveCVForJob(true)
                        }}
                        className="text-white bg-dark">
                        Save In Profile
                    </CLoadingButton>
                    <CLoadingButton
                        className="text-white"
                        onClick={() => generatePdf()}
                        loading={isDownloading}
                        disabled={isDownloading}>
                        Download as PDF
                    </CLoadingButton>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <div>
                    {status === 1 && <h2 className="fw-bold text-center">PERSONAL DETAILS</h2>}
                    {status === 2 && <h2 className="fw-bold text-center">EXPERIENCE(S)</h2>}
                    {status === 3 && <h2 className="fw-bold text-center">EDUCATION </h2>}
                    {status === 4 && <h2 className="fw-bold text-center">Achievements</h2>}
                    {status === 5 && <h2 className="fw-bold text-center">Skills</h2>}
                    {status === 6 && <h2 className="fw-bold text-center">Publications</h2>}
                    {status === 7 && <h2 className="fw-bold text-center">Languages</h2>}


                </div>
            </div>
            <br/>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div onClick={() => {
                    setStatus(1)
                }}>
                    <CVProgress number={"1"} completed={status >= 1} title={""}/>
                </div>
                <div className={`${status >= 2 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(2)
                }}>
                    <CVProgress number={"2"} completed={status >= 2} title={""}/>
                </div>
                <div className={`${status >= 3 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(3)
                }}>
                    <CVProgress number={"3"} completed={status >= 3} title={""}/>
                </div>
                <div className={`${status >= 4 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(4)
                }}>
                    <CVProgress number={"4"} completed={status >= 4} title={""}/>
                </div>
                <div className={`${status >= 5 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(5)
                }}>
                    <CVProgress number={"5"} completed={status >= 5} title={""}/>
                </div>
                <div className={`${status >= 6 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(6)
                }}>
                    <CVProgress number={"6"} completed={status >= 6} title={""}/>
                </div>
                <div className={`${status >= 7 ? "border-primary" : ""} flex-fill cv-bar`}></div>
                <div onClick={() => {
                    setStatus(7)
                }}>
                    <CVProgress number={"7"} completed={status >= 7} title={""}/>
                </div>
            </div>
        </>
    );
}

export default CvBuilderHeader;