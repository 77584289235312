import React, {useState} from 'react'
import {CContainer} from "@coreui/react-pro";
import ShowLoading from "../../components/ShowLoading";
import PostTest from "../../components/test/PostTest";
import UploadTest from "../../components/test/UploadTest";
import {useParams} from "react-router-dom";

const AddTest = () => {

    const { uuid } = useParams()
    const [tab, setTab] = useState(1)
    return (
        <>
            {false && <ShowLoading/>}

            <CContainer>
                {(uuid == undefined || !uuid) && <div className="flex-wrap d-flex gap-2">
                    <button onClick={() => {
                        setTab(1)
                    }}
                            className={`fw-normal sub-body btn-tap rounded-2 px-3 py-2 border-0 ${tab === 1 ? 'text-white bg-primary' : 'bg-grey'}`}>
                        <small>Add Questions</small>
                    </button>
                    <button onClick={() => {
                        setTab(2)
                    }}
                            className={`fw-normal sub-body btn-tap rounded-2 px-3 py-2 border-0 ${tab === 2 ? 'text-white bg-primary' : 'bg-grey'}`}>
                        <small>Upload Questions from excel</small>
                    </button>

                </div>}

                <hr/>

                <div className="my-4 d-flex justify-content-center">
                    {tab === 1 && <PostTest/>}
                    {tab === 2 && <UploadTest/>}

                </div>
            </  CContainer>

        </>
    )
}

export default AddTest
