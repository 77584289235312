import React from 'react';
import {
    CButton,
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel, CImage,
    CRow
} from "@coreui/react-pro";
import GoBack from "../../components/GoBack";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {NewKPISchema} from "../../form-schema/admin-form-schema/newKPISchema";
import AddIcon from '../../assets/icons/add-icon.svg';
import DeleteIcon from '../../assets/icons/delete-icon.svg';

const AddNewKPI = () => {

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(NewKPISchema)
    })

    const handleNewKPI = () => {

    }

    return <CContainer>
        <CRow>
            <h5>Add New KPI</h5>
            <hr/>
            <CCol md={6}>
                <CForm onSubmit={handleSubmit(handleNewKPI)}>
                    <div className="my-3">
                        <CFormLabel>KPI</CFormLabel>
                        <div className="d-flex flex-row justify-content-between">
                            <CFormInput
                                className="form-control px-3 py-2"
                                type="text"
                                id="kpiName"
                                {...register('kpiName')}
                                invalid={!!errors.kpiName}
                            />
                            <CImage src={DeleteIcon} className="ms-3" />
                        </div>
                        <CFormFeedback invalid={!!errors.kpiName}>
                            {errors.kpiName?.message}
                        </CFormFeedback>
                    </div>
                    <div className="w-100 d-flex flex-row justify-content-center align-items-center py-2 my-5 add-new-kpi">
                        <CImage src={AddIcon} />
                    </div>
                    <div className="d-flex flex-row justify-content-between my-5">
                        <GoBack/>
                        <CButton
                            type="submit"
                            className="btn btn-primary px-5 py-2 text-small text-white"
                        >ADD</CButton>
                    </div>
                </CForm>
            </CCol>
        </CRow>
    </CContainer>
}

export default AddNewKPI;