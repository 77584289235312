export const _LOGIN_USER = 'nexit:applicant:auth'
export const _LOGIN_ADMIN = 'nexit:admin:auth'


// USER

export const retrieveLoggedInUser = () => JSON.parse(localStorage.getItem(_LOGIN_USER))

export const storeLoggedInUser = (user) => localStorage.setItem(_LOGIN_USER, JSON.stringify(user))

export const deleteUser = () => localStorage.removeItem(_LOGIN_USER)

// ADMIN

export const retrieveLoggedInAdmin = () => JSON.parse(localStorage.getItem(_LOGIN_ADMIN))

export const storeLoggedInAdmin = (admin) => localStorage.setItem(_LOGIN_ADMIN, JSON.stringify(admin))

export const deleteAdmin = () => localStorage.removeItem(_LOGIN_ADMIN)
