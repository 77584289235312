import React, {useState} from 'react'
import bookmarkWhite from "../../assets/icons/bookmarkWhite.svg";
import bookmark from "../../assets/icons/bookmark.svg";

import {
    CCard,
    CCardImage,
    CCol,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";
import {formatCurrency} from "../../constant";
import logo from "../../assets/brand/WN-logo.svg";

function MostRecentJobCard({jobs, onSubmit, JobIsLoading, setSave, save}) {
    const {isSuccess, data} = jobs
    const {user} = useAppContext()
    const [id, setId] = useState(null)

    return (
        <>
            {isSuccess &&
                <CRow xs={{cols: 1}} sm={{cols: 1}} md={{cols: 1}} lg={{cols: 1}} className="g-3">
                    {data?.data?.data?.items.map((job, index) => {
                        return (
                            <CCol key={index}>
                                <CCard className={`h-100 py-3 ${index === 1000 ? 'text-white bg-primary' : ''}`}>
                                    <Link
                                        to={user?.accessToken ? `/user/job-detail/${job?.slugUrl}` : `/job-detail/${job?.slugUrl}`}
                                        className="text-decoration-none text-black-50">
                                <span className={`h-100 py-3 ${index === 1000 ? 'text-white bg-primary' : ''}`}>
                                     <div className="px-2 d-flex">

                             <div className=" bg-green-700 job-logo-div align-self-start">
                                          <CCardImage onError={(e) => {e.target.src = logo}} alt="Company Logo" orientation="top" className="job-logo" src={job?.stakeholder?.logoUrl || logo}/>
                             </div>

                              <div className="px-2">

                                  <h6 className="fw-bold">{job.title}</h6>
                                  <p className={`sub-body py-none  ${index === 1000 ? 'text-white' : ''}`}>{job?.stakeholder?.name}, {job?.location}</p>

                              </div>

                          </div>
                                 <div className="p-2 d-flex justify-content-between ">
                              <div className="fw-bold ">{job?.levelOfExperience} yr(s)</div>
                              <div className="fw-bold ">{job?.jobType}</div>
                                     {(job.salary && job.salary !== "Negotiable") && <div className="fw-bold ">&#8358;{formatCurrency(job?.salary)}</div>}

                          </div>
                                </span>
                                    </Link>
                                    <div className="d-flex justify-content-between">
                                        <CRow className="px-3">

                                            {job?.tags.map((tag, index) => {
                                                return (
                                                    <CCol key={index} className=" m-1 job-tag"><small
                                                        className="bg-light-green-2 px-2 py-1 rounded-2">{tag}</small></CCol>
                                                )
                                            })}

                                        </CRow>
                                        {JobIsLoading && id === job.id && save ?
                                            <CLoadingButton loading={JobIsLoading && id === job.id && save}
                                                            className={`bg-transparent border-0 p-0 pb-2 ${index === 1000 ? 'text-white' : 'text-primary'}`}></CLoadingButton>
                                            : <CCardImage data-coreui-toggle="tooltip" title="Save Job" onClick={() => {
                                                setId(job.id)
                                                setSave(true)
                                                onSubmit(job.id, save)
                                            }} orientation="top" className="job-bookmark pb-0 mb-0 me-2 cursor-pointer"
                                                          src={index === 1000 ? bookmarkWhite : bookmark}/>}
                                    </div>

                                </CCard>

                            </CCol>
                        )
                    })}


                </CRow>
            }
        </>
    )
}

export default MostRecentJobCard
