import React, {useEffect} from 'react'
import {CCol, CForm, CFormFeedback, CFormInput, CLoadingButton,} from '@coreui/react-pro'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ForgotPasswordSchema} from '../../form-schema/user-form-schema/forgotPasswordSchema'
import {useNavigate} from 'react-router-dom'
import {useForgottenPasswordQuery} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";
import GoBack from "../../components/GoBack";

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(ForgotPasswordSchema),
    })
    const navigate = useNavigate()
    const {isLoading, isSuccess, data, isError, error, mutate} = useForgottenPasswordQuery()

    useEffect(() => {
        if (isSuccess) {

            success_notify('Password recovery sent. Please check you email')
            // navigate(`/reset-password`, {
            //   replace: true,
            // })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isSuccess, isError, error, navigate])

    const onSubmit = (data) => {
        mutate(data)
    }
    return (
        <>
            <CCol md={8}>
                <h2 className="text-2">Password Recovery</h2>
                <p className="mt-4">
                    Provide your registered email address
                </p>

                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    {/* <CForm noValidate className="needs-validation"> */}
                    <div className="d-flex flex-column gap-3">
                        <CCol md={12}>
                            <CFormInput
                                type="text"
                                id="inputEmail4"
                                placeholder="Enter Your Email Address"
                                {...register('email')}
                                invalid={!!errors.email}
                            />
                            <CFormFeedback invalid={!!errors.email}>{errors.email?.message}</CFormFeedback>
                        </CCol>
                    </div>
                    <CLoadingButton
                        type="submit"
                        className="reg-btn px-4 w-100 py-2 mt-5"
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Recover My Password
                    </CLoadingButton>
                    <div className="d-flex justify-content-center py-3">
                        <GoBack/>
                    </div>
                </CForm>
            </CCol>
        </>
    )
}

export default ForgotPassword
