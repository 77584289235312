//Preloaded Data from Nasims
const nasimPreloadedData = (nasimData) => {
    return {
        surname: nasimData?.surname,
        firstName: nasimData?.first_name,
        otherName: nasimData?.other_name,
        gender: nasimData?.gender,
        password: nasimData?.password,
        confirmPassword: nasimData?.password,
        dateOfBirth: nasimData?.date_of_birth,
        placeOfBirth: nasimData?.place_of_birth,
        email: nasimData?.email_address,
        phoneNumber: nasimData?.phone_number,
        altphone: nasimData?.alternative_phone_number,
        bvn: nasimData?.bvn,
        accountNumber: nasimData?.bank_account_number,
        disability: nasimData?.physical_disability,
        stateOfOrigin: nasimData?.state_of_origin,
        localGovernmentOfOrigin: nasimData?.lga,
        stateOfResidence: nasimData?.state_of_residence,
        localGovernmentOfResidence: nasimData?.local_government_of_residence,
        address: String(nasimData?.residential_address).replace(/[&\\#,+()$~%.'":*?<>{}]/g, ''),
        educationLevel: nasimData?.highest_level_of_education,
        nPowerNumber: nasimData?.npower_id,
        nPowerBatch: nasimData?.batch,
        nPowerStream: nasimData?.stream
    }
}

export default nasimPreloadedData;