import React from 'react';
import {CCol, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";

const loanTenures = [
    {label: '1 Months', value: 1},
    {label: '2 Months', value: 2},
    {label: '3 Months', value: 3},
    {label: '4 Months', value: 4},
    {label: '5 Months', value: 5},
    {label: '6 Months', value: 6},
    {label: '7 Months', value: 7},
    {label: '8 Months', value: 8},
    {label: '9 Months', value: 9},
    {label: '10 Months', value: 10},
    {label: '11 Months', value: 11},
    {label: '12 Months', value: 12},
]
const moratoriums = [
    {label: '0 Months', value: 0},
    {label: '1 Months', value: 1},
    {label: '2 Months', value: 2},
    {label: '3 Months', value: 3},
    {label: '4 Months', value: 4},

]

function LoanSection({register, errors}) {
    return (
        <>
            <div className={'mt-3 border border-grey px-4 pb-4 bg-grey-50 '}>
                <h3 className="sub-title pt-3">Loan Details</h3>
                <CRow className="">
                    <CCol className="d-flex flex-wrap  justify-content-between gap-5">
                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Loan Amount (₦)</CFormLabel>
                            <CFormInput
                                type="text"
                                placeholder="10000000"
                                {...register('loanAmount')}
                                invalid={!!errors.loanAmount?.toString()}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.loanAmount?.toString()}>
                                {errors.loanAmount?.message}
                            </CFormFeedback>
                        </div>
                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Loan Tenure</CFormLabel>
                            <CFormSelect
                                id="loanTenure"
                                className="form-control mb-2"
                                {...register('loanTenure')}
                                invalid={!!errors.loanTenure}
                            >
                                <option value="">Select Tenure</option>
                                {
                                    loanTenures.map(loanTenure => <option
                                        value={loanTenure.label} key={loanTenure.value + 1}>{loanTenure.label}</option>)
                                }

                            </CFormSelect>
                            <CFormFeedback
                                invalid={!!errors.loanTenure}>{errors.loanTenure?.message}</CFormFeedback>
                        </div>
                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Moratorium</CFormLabel>
                            <CFormSelect
                                id="moratorium"
                                className="mb-2"
                                {...register('moratorium')}
                                invalid={!!errors.moratorium}
                            >
                                <option value="">Select Moratorium</option>
                                {
                                    moratoriums.map(moratorium => <option
                                        value={moratorium.value} key={moratorium.value + 1}>{moratorium.label}</option>)
                                }
                            </CFormSelect>
                            <CFormFeedback
                                invalid={!!errors.moratorium}>{errors.moratorium?.message}</CFormFeedback>
                        </div>
                    </CCol>
                </CRow>
                <CRow className="">
                    <CCol className="d-flex flex-wrap align-items-center justify-content-between gap-5">
                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Interest Rate Type</CFormLabel>
                            <CFormSelect
                                id="interestRateType"
                                className="form-control mb-2"
                                {...register('interestRateType')}
                                invalid={!!errors.interestRateType}
                            >
                                <option value="">Select Interest Rate Type</option>
                                <option value={'0'}>Monthly</option>
                                <option value={'1'}>Yearly</option>
                                <option value={'2'}>Quarterly</option>
                            </CFormSelect>
                            <CFormFeedback
                                invalid={!!errors.interestRateType}>{errors.interestRateType?.message}</CFormFeedback>
                        </div>
                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Interest Rate (In %) </CFormLabel>
                            <CFormInput
                                type="number"
                                placeholder=""
                                {...register('interestRate')}
                                invalid={!!errors.interestRate}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.interestRate?.toString()}>
                                {errors.interestRate?.message}
                            </CFormFeedback>
                        </div>

                        <div className={'col'}>
                            <CFormLabel className="m-1 label">Bank Charge (₦)</CFormLabel>
                            <CFormInput
                                type="number"
                                placeholder="100"
                                {...register('bankCharge')}
                                invalid={!!errors.bankCharge?.toString()}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.bankCharge?.toString()}>
                                {errors.bankCharge?.message}
                            </CFormFeedback>
                        </div>

                    </CCol>
                </CRow>
            </div>
        </>
    );
}

export default LoanSection;