import * as Yup from 'yup'

export const TrainingSchema = Yup.object().shape({
    title: Yup.string().typeError('Training Title Required').required('Training Title Required'),
    trainingDuration: Yup.string().typeError('Training Duration Required').required('Training Duration Required'),
    // description: Yup.string().typeError('Training Description Required').required('Training Description Required'),
    trainingType: Yup.string('Training Type is required').required('Training Type is required'),
    modeOfTraining: Yup.string('Mode Of Training is required').required('Mode Of Training is required'),
    // requirements: Yup.string('Requirements is required').required('Mode Of Training is required'),
    // courseContent: Yup.string('Course content is required').required('Mode Of Training is required'),

    address: Yup.string().when('trainingType', {
        is: (modeOfTraining) => {

        },
        then: Yup.string().typeError('Training Address Required').required('Training Address Required'),
    }),

    file: Yup.mixed().typeError('Training Title Required').test("required", "You need to provide an Image", (value) => {
        return value?.length || false
    }).test("type", "Only the following formats are accepted: .jpeg, .jpg, and .png", (value) => {

            return value && (
                value[0]?.type === "image/jpeg" ||
                value[0]?.type === "image/bmp" ||
                value[0]?.type === "image/png"
            );

        }).test("fileSize", "Image must not be more 3MB", (value) => {


            return value[0]?.size <= 3 * 1024 * 1024


    }),


})
