import {CCol, CRow} from "@coreui/react-pro";
import React from "react";
import {useGetCohortBeneficiaries} from "../../hooks/admin/adminApiHooks";
import ShowLoading from "../../components/ShowLoading";
import DataNotFound from "../../components/DataNotFound";

const CohortBeneficiaries = ({cohort, setViewCohort, cohortId}) => {

    const {data, isLoading} = useGetCohortBeneficiaries(cohortId)

    if (isLoading) {
        return <ShowLoading/>
    }

    return <CRow>
        <CCol md={12}>
            <p onClick={() => setViewCohort(false)} className="cursor-pointer my-3">Go Back</p>
            <h2>Cohort &apos;{cohort}&apos; Beneficiaries</h2>
            {/*<h5>Instructor: Olalekan Gift</h5>*/}
            <hr/>
            {data?.data?.data?.meta?.itemCount === 0 ? <DataNotFound/> :
            <table className="table border-0" id="reports-table">
                <thead className="thead-inverse">
                <tr className="bg-transparent text-primary">
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Occupation</th>
                    <th>State of Residence</th>
                </tr>
                </thead>
                <tbody>
                {data?.data?.data?.items?.map(({applicant: {
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    occupation,
                    stateOfResidence,
                }, index}) => (
                    <tr key={index}>
                        <td>{firstName} {lastName}</td>
                        <td>{phoneNumber}</td>
                        <td>{email}</td>
                        <td>{occupation}</td>
                        <td>{stateOfResidence}</td>
                    </tr>
                ))}
                </tbody>
            </table>}
        </CCol>
    </CRow>
}

export default CohortBeneficiaries