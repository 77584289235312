import React, {useEffect, useState} from 'react'
import Table from "../../components/tables/table";
import {useGetEmployerInvoice} from "../../hooks/employer/employerApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import {useGetEmployerByUUID} from "../../hooks/user/userApiHooks";
import DataNotFound from "../../components/DataNotFound";
import {fail_notify} from "../../constant";
import {CButton, CFormSelect} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import ShowLoading from "../../components/ShowLoading";

const MySponsoredCourses = () => {
    const {user} = useAppContext()
    const [employerData, setEmployerData] = useState("")
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const employer = useGetEmployerByUUID(user?.data?.stakeholderUuid)
    const phoneNum = 0 + String(employerData?.phoneNumber || "").slice(-10);
    const {
        isSuccess,
        isLoading,
        error,
        isError,
        data
    } = useGetEmployerInvoice(user?.data?.email, phoneNum, page, limit);

    useEffect(() => {
        if (employer?.isSuccess) {
            setEmployerData(employer?.data?.data?.data)
        }
        if (employer?.isError) {
            fail_notify(employer?.error?.response?.data?.message)
        }
    }, [employer?.isSuccess, employer?.data, employer?.isLoading, employer?.isSuccess])

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, data, error, isError, isLoading])

    const headers = [
        {name: "No"},
        {name: "Course Title", sort: false},
        {name: "Sponsorship Type", sort: false},
        {name: "Amount", sort: false},
        {name: "Status", sort: true},
        {name: "Action"}
    ]

    //console.log(data?.data?.data?.items)
    return (
        <>
            <div className="mt-4">
                <div className="">
                    <h5 className="text-2 mb-3 ps-1">My Sponsored Courses</h5>
                    <hr/>
                </div>
                {isLoading ? <ShowLoading/> :
                    <>
                        {
                            data?.data?.data?.items?.length > 0 ?
                                <>
                                    <Table headers={headers}>
                                        {
                                            data?.data?.data?.items?.map((item, index) => (
                                                <tr className="bg-white border"
                                                    key={index}
                                                >
                                                    <td className="td-single-line fw-medium">{index + 1}</td>
                                                    <td className="fw-medium">{item?.description}</td>
                                                    <td className="fw-medium text-capitalize">{item?.sponsorship?.sponsorshipType}</td>
                                                    <td className="fw-medium">₦{item?.amountDue}</td>
                                                    {
                                                        item?.sponsorship.approvalStatus === 'Approved' ?
                                                            <td className={`fw-medium ${item?.inPaid ? "text-green" : "text-danger"}`}>
                                                                {item?.inPaid ? "Paid" : "Pending Payment"}
                                                            </td> :
                                                            <td className={`fw-medium ${item?.inPaid ? "text-green" : "text-danger"}`}>
                                                                {
                                                                    item?.sponsorship.approvalStatus === 'Pending' ?
                                                                        item?.sponsorship.approvalStatus + " " + "Admin Approval" :
                                                                        item?.sponsorship.approvalStatus
                                                                }
                                                            </td>
                                                    }
                                                    {
                                                        item?.sponsorship.approvalStatus === 'Approved' ?
                                                            <td className="fw-medium" style={{width: "130px"}}>
                                                                {
                                                                    item?.inPaid ?
                                                                        <center
                                                                            className="bg-primary text-decoration-none cursor-pointer px-1 rounded text-white"
                                                                            style={{width: "120px"}}>
                                                                            <a href={item?.invoicePreviewUrl}
                                                                               rel="noreferrer"
                                                                               target="_blank"
                                                                               className="text-decoration-none cursor-pointer text-white">View
                                                                                Invoice</a>

                                                                        </center> :
                                                                        <center
                                                                            className="bg-primary text-decoration-none cursor-pointer px-1 rounded text-white"
                                                                            style={{width: "120px"}}>
                                                                            <a className="text-decoration-none cursor-pointer text-white"
                                                                               href={item?.paymentURL} rel="noreferrer"
                                                                               target="_blank"
                                                                            >Make
                                                                                Payment</a>
                                                                        </center>
                                                                }
                                                            </td> :
                                                            <td className="fw-medium" style={{width: "130px"}}>
                                                                {
                                                                    item?.sponsorship.approvalStatus === 'Rejected' ?
                                                                        <center
                                                                            className="bg-danger disabled  cursor-pointer px-1 rounded"
                                                                            style={{width: "120px"}}>
                                                                            <a href={item?.invoicePreviewUrl}
                                                                               rel="noreferrer"
                                                                               target="_blank"
                                                                               className="text-decoration-none text-white disabled">
                                                                                Rejected
                                                                            </a>

                                                                        </center> :
                                                                        <center
                                                                            className="bg-blue disabled py-1 px-1 cursor-pointer px-1 rounded text-white"
                                                                            style={{width: "120px"}}>
                                                                            <a className="text-decoration-none disabled text-white"
                                                                               href={'#'} rel="noreferrer"
                                                                               target="_blank"
                                                                            >
                                                                                Waiting Approval
                                                                            </a>
                                                                        </center>
                                                                }
                                                            </td>

                                                    }
                                                </tr>
                                            ))
                                        }
                                    </Table>
                                    <div className={"d-flex justify-content-between pb-3"}>
                                        <div className="d-flex align-items-center gap-1">
                                            <small className={""}>Items per page:</small>

                                            <div><CFormSelect
                                                aria-label="Default select example"
                                                value={limit}
                                                options={[
                                                    {label: '5', value: '5'},
                                                    {label: '10', value: '10'},
                                                    {label: '20', value: '20'},
                                                    {label: '50', value: '50'},
                                                    {label: '100', value: '100'}
                                                ]}
                                                onChange={(e) => setLimit(e.target.value)}
                                            /></div>
                                        </div>
                                        <div className="d-flex  flex-row justify-content-center align-items-center">
                                            <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                                     className={`mx-3 bg-white text-primary py-1 pb-2 px-3`}>
                                                <BiLeftArrow/>
                                            </CButton>

                                            <CButton
                                                disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                                                onClick={() => setPage(page + 1)}
                                                className="mx-3 bg-white text-primary py-1 pb-2 px-3">
                                                <BiRightArrow/>
                                            </CButton>
                                        </div>
                                    </div>
                                </>
                                : <DataNotFound/>
                        }
                    </>
                }
            </div>


        </>
    )
}

export default MySponsoredCourses
