import React from 'react';
import Faq from "../../components/User/Faq";
import {CContainer} from "@coreui/react-pro";
import {useGetFAQS} from "../../hooks/user/userApiHooks";
import ShowLoading from "../../components/ShowLoading";

function Faqs(props) {
    const {isLoading, data} = useGetFAQS()
    const faqs = data?.data?.data

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <div className="wrapper d-flex flex-column min-vh-100">
            <CContainer className="mx-auto px-5">
                <div className="mb-5  mt-5">
                    <h3>Frequently Asked Questions and Answers</h3>
                </div>
                {faqs?.length === 0 ? <h5>No questions asked, yet.</h5> :
                    faqs?.map((item) => (
                        <Faq
                            title={item?.title}
                            index={item?.id}
                            key={item?.id}
                            text={item?.answers}
                        />)
                    )}
            </CContainer>
        </div>

    );
}

export default Faqs;
