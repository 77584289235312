import React, {useEffect, useState} from 'react';
import {CCol, CRow} from "@coreui/react-pro";
import {FaTrashAlt} from "react-icons/fa";
import {useGetTrainerTrainees} from "../../hooks/admin/adminApiHooks";
import ShowLoading from "../../components/ShowLoading";
import {fail_notify} from "../../constant";
import TableNavigation from "../../components/TableNavigation";
import DataNotFound from "../../components/DataNotFound";

function TrainerProfile({data, setViewProfile}) {

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const {data: traineesData, isLoading, isError, error} = useGetTrainerTrainees(data?.id, page, limit)

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, error]);

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CRow className="h-100 pb-5">
            <div>
                <h6 onClick={() => setViewProfile(null)} className="cursor-pointer">Back</h6>
            </div>
            <CCol className="" md={4}>
                <div className="bg-white rounded-2 p-3">
                    <div className="w-100 d-flex flex-column p-2">
                        <p className="bg-grey p-2 mb-3 w-100"><strong>Trainer</strong></p>
                        <h2 className="fw-bolder text-3 py-2 m-0">{data?.firstName} {data?.lastName}</h2>
                        <small className="py-2">Phone: {data?.phoneNumber}</small>
                        <small className="py-2">Email: {data?.email}</small>
                        <small className="py-2">Center: {data?.state}</small>
                        <small className="py-2">State: {data?.state}</small>
                    </div>
                    <div
                        onClick={() => {
                        }}
                        className="text-danger  border border-start-0 border-end-0 border-top-0 border-danger px-4 py-3 gap-2 d-flex align-items-center cursor-pointer">
                        <FaTrashAlt/> <small>Remove Trainer</small>
                    </div>
                </div>
            </CCol>
            <CCol md={8} className="bg-white py-4 rounded-2">
                <h5>Trainees</h5>
                <table className="table border-0" id="reports-table" style={{width: '50vw'}}>
                    <thead className="thead-inverse">
                    <tr className="bg-transparent text-primary">
                        <th>Trainee</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Occupation</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {traineesData?.data?.data?.items?.length === 0 ? <DataNotFound/> :
                        traineesData?.data?.data?.items?.map(({applicant: {
                        email,
                        firstName,
                        gender,
                        lastName,
                        lgaOfResidence,
                        occupation,
                        otherName,
                        phoneNumber,
                        stateOfResidence
                    }}, index) => (
                        <tr className="py-2 my-1" key={index}>
                            <td>
                                <div className="d-flex flex-row align-items-center">
                                    <input type="checkbox" className="me-2"/>
                                    <div className="ms-2">
                                        <h6 className="m-0 p-0">{firstName} {lastName} {otherName}</h6>
                                        <p className="m-0 p-0 kpi-table-email">{email}</p>
                                    </div>
                                </div>
                            </td>
                            <td>{phoneNumber}</td>
                            <td>{gender}</td>
                            <td>{occupation}</td>
                            <td>{lgaOfResidence}, {stateOfResidence}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <TableNavigation
                    page={page}
                    setLimit={setLimit}
                    setPage={setPage}
                    totalPages={1}
                />
            </CCol>
        </CRow>
    );
}

export default TrainerProfile;
