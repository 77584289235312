import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import appApiCalls from '../../services/api'

/*
* useStakeholderInSignInQuery
* Employer and Sponsors
* Sign In HooK
*  */
export function useStakeholderinSignInQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.signIn(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['LoggedIn'])
            return data
        },
    })
}

/*
* */
export function useGetStakeholderTypeQuery() {
    return useQuery(['Get Stakeholder Type'], () => appApiCalls.getStakeholderType(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetStakeholderQuery(stakeholderUuid) {
    return useQuery(['Get Stakeholder'], () => appApiCalls.getStakeholder(stakeholderUuid), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useStakehoderinSignUpQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.createStakeholder(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['createStakeholder'])
            return data
        },
    })
}

export function useSignUpEmployerLMS() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.signUpEmployerLMS(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['signUpEmployerLMS'])
            return data
        },
    })
}

export function useEmailVerificationQuery() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.stakeholderEmailVerification(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['stakeholderEmailVerification'])
            return data
        },
    })
}

export function useStakeholderUpdate() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateStakeholder(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useStakeholderUpdate'])
            return data
        },
    })
}

export function useGetJobPostedQuery() {
    return useQuery(['Get JobPosted'], () => appApiCalls.getJobPosted(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetJobStatQuery() {
    return useQuery(['GetJobStatQuery'], () => appApiCalls.getJobStat(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetUsersQuery() {
    return useQuery(['Get users'], () => appApiCalls.getUsers(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetTotalTestPassedQuery() {
    return useQuery(['GetTotalTestPassed'], () => appApiCalls.getTotalTestPassed(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetTotalTestFailedQuery() {
    return useQuery(['GetTotalTestFailed'], () => appApiCalls.getTotalTestFailed(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useStakeholderPostJobs() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.postJobStakeholder(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['post Jobs'])
            return data
        },
    })
}

export function useSponsorTraining() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.sponsorTraining(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['post Jobs'])
            return data
        },
    })
}

// getting all programs posted by specific Employer
export function useGetEmployersPrograms(page) {
    return useQuery(['useGetEmployersPrograms', page], () => appApiCalls.getProgramsByEmployer(page), {
        keepPreviousData: true,
        staleTime: 1
    })
}

// getting all jobs posted by specific Employer
export function useGetEmployersJobs(filter) {
    return useQuery(['useGetEmployersJobs', filter], () => appApiCalls.getJobsPostedByEmployer(filter), {
        keepPreviousData: true,
        staleTime: 1
    })
}

// getting all trainee for programs posted by specific Employer
export function useGetProgramTrainees(programID) {
    return useQuery(['useGetProgramTrainings', programID], () => appApiCalls.getProgramTrainees(programID), {
        keepPreviousData: true,
        staleTime: 1,
        enabled: !!programID
    })
}

// getting all applicant for job posted by specific Employer
export function useGetJobApplicants(queryKey, jobID, page, limit, fitLevel, assessment, jobStatus) {
    return useQuery([queryKey, jobID], () => appApiCalls.getJobApplicants(jobID, page, limit, fitLevel, assessment, jobStatus), {
        keepPreviousData: true,
        staleTime: 1,
        enabled: !!jobID
    })
}

// updating applicant Status
export function useApplicationStatusUpdate() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateApplicationStatus(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useApplicationStatusUpdate'])
            return data
        },
    })
}

export function useJobStatusUpdate(page, jobId) {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.updateJobStatus(jobId, arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useGetEmployersJobs', page])
            return data
        },
    })
}

export function useStakeholderCreateTraining() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.createTrainingStakeholder(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['CreateTraining'])
            return data
        },
    })
}

export function useGetTotalTestTakersQuery() {
    return useQuery(['Get Total Test Takers'], () => appApiCalls.getTotalTestTakers(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetTotalTestsPassed() {
    return useQuery(['Get Total AddTest Passed'], () => appApiCalls.getTotalTestsPassed(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetTotalTestsFailed() {
    return useQuery(['Get Total AddTest Failed'], () => appApiCalls.getTotalTestsFailed(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetAllEmployers(limit, page, roles) {
    return useQuery(['GetAllStakeholders', page, limit], () => appApiCalls.getAllEmployers(limit, page, roles), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

//Tin Verification
export function useValidateTin() {
    const queryClient = useQueryClient()
    return useMutation((tin) => appApiCalls.getTin(tin), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['Verify Tin'])
            return data
        },
    })
}

// Add Role
export function useAddRole() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.addRole(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['useAddRole'])
            return data
        },
    })
}

export function useAddSponsorRole() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.addSponsorRole(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['addSponsorRole'])
            return data
        },
    })
}

export function useScheduleInterview() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.scheduleInterview(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["JobApplicants", data.jobId])
            return data
        },
    })
}

export function useGetCBSRecords() {
    return useQuery(['GetCBSRecord'], () => appApiCalls.getEmployerCBSRecord(), {
        keepPreviousData: true,
        staleTime: 1,
    })
}

export function useGetEmployerInvoice(email, phoneNumber, page, limit) {
    return useQuery(['useGetEmployerInvoice', email, phoneNumber, page, limit], () => appApiCalls.getEmployerInvoice(email, phoneNumber, page, limit), {
        keepPreviousData: true,
        staleTime: 1
    })
}

export function useGenerateCBSInvoice() {
    const queryClient = useQueryClient()
    return useMutation((arg) => appApiCalls.generateCBSInvoice(arg), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['GenerateCBSInvoice'])
            return data
        },
    })
}

export function useGetShortlistedApplicants(limit) {
    return useQuery(['ShortlistedApplicants'],
        () => appApiCalls.getInterviewShortlist(limit), {
        staleTime: 1,
        keepPreviousData: true
    })
}

export function useUpdateJobApplicationStatus(data) {
    const queryClient = useQueryClient()
    return useMutation((data) => appApiCalls.updateJobApplicationStatus(data), {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['JobApplicants'])
            return data
        },
    })
}
