import React from 'react';
import Table from "../../../components/tables/table";

function TraineesUnderTrainerTable({headers, items}) {
    return (
        <Table headers={headers}>
            {items?.map(({applicant}, index) => (
                <tr className="cursor-pointer bg-white border"
                    key={index}
                >
                    <td className="td-single-line fw-light">{applicant?.firstName} {applicant?.lastName}</td>
                    <td className="fw-light">{applicant?.email}</td>
                    <td className="fw-light">{applicant?.phoneNumber}</td>
                    <td className="fw-light">
                        {/*<div className="d-flex align-items-center h-100 mt-3"><small*/}
                        {/*    className={`border ${applicant?.status === "Absent" && "border-danger"}  ${applicant?.status === "Present" && "border-primary"} justify-content-center align-items-center d-flex`}*/}
                        {/*    style={{width: '70px', height: '20px'}}>{applicant?.status}</small></div>*/}
                        {applicant?.occupation}
                    </td>
                    <td>{applicant?.lgaOfResidence}, {applicant?.stateOfResidence}</td>
                </tr>
            ))}
        </Table>
    );
}

export default TraineesUnderTrainerTable;
