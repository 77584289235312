import React, {useEffect, useState} from 'react';
import {sortTable} from "../../../../constant";
import {BiSort} from "react-icons/bi";
// import {CImage} from "@coreui/react-pro";
// import FailIcon from "../../../../assets/icons/assessment-fail-icon.png";
// import PassIcon from "../../../../assets/icons/assessment-pass-icon.png";

function AssessmentReportTable({usersData, quizType, handleSelectUser}) {

    const [filteredArray, setFilteredArray] = useState([])

    useEffect(() => {
        setFilteredArray(filterUniqueUsers(usersData?.data?.data?.items))
    }, [usersData]);

    function filterUniqueUsers(dataArray) {
        const userIdMap = {};
        return dataArray?.reduce((accumulator, item) => {
            if (!userIdMap[item.userId]) {
                userIdMap[item.userId] = true;
                accumulator.push(item);
            }
            return accumulator;
        }, []);
    }

    return (
        <>
            <table className="table border-0" id="reports-table">
                <thead className="thead-inverse">
                <tr className="bg-transparent text-primary">
                    <th onClick={() => sortTable("reports-table", 0)}>
                        Applicants
                        <BiSort size={20}/>
                    </th>
                    <th>Role</th>
                    <th>Action</th>
                    {/*{quizType === " " && <th>Assessment Type</th>}*/}
                    {/*<th>Q/Attempted</th>*/}
                    {/*<th>Q/Not_Answered</th>*/}
                    {/*<th>Q/Passed</th>*/}
                    {/*<th>Q/Failed</th>*/}
                    {/*<th onClick={() => sortTable("reports-table", 6)}>*/}
                    {/*    Score (%)*/}
                    {/*    <BiSort size={20}/>*/}
                    {/*</th>*/}
                    {/*<th onClick={() => sortTable("reports-table", 7)}>*/}
                    {/*    Status*/}
                    {/*    <BiSort size={20}/>*/}
                    {/*</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    filteredArray?.map(({
                                           user,
                                           quizType: quizTitle,
                                           passedPercent,
                                           userId,
                                            otherName,
                                            role
                                       }, index) => (
                        <tr key={index}>
                            <td className="td-single-line fw-bolder"
                                onClick={() => handleSelectUser({
                                    ...user,
                                    quizType: quizTitle,
                                    passedPercent
                                })}
                            >
                                {user?.firstName} {user?.lastName} {user?.otherName}
                            </td>
                            <td>{user?.role}</td>
                            <td>
                                <a
                                    href={`/admin/user-assessments/${userId}/${user?.userUuid}`}
                                    className="text-decoration-none text-dark"
                                >View Results</a>
                            </td>
                            {/*<td>{parseInt(totalQuestionAttempted)}</td>*/}
                            {/*<td>{parseInt(totalQuestion) - parseInt(totalQuestionAttempted)}</td>*/}
                            {/*<td className="text-primary">{quizTitle === "Level One Questionnaire" ? '-' : passed}</td>*/}
                            {/*<td className="text-danger">{quizTitle === "Level One Questionnaire" ? '-' : failed}</td>*/}
                            {/*<td>{quizTitle === "Level One Questionnaire" ? '-' : `${passedPercent}%`}</td>*/}
                            {/*<td>*/}
                            {/*    {*/}
                            {/*        quizTitle === "Level One Questionnaire" ? '-'*/}
                            {/*            : parseFloat(passedPercent) < 50 ? <CImage src={FailIcon}/> : <CImage src={PassIcon}/>*/}
                            {/*    }*/}
                            {/*</td>*/}
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </>
    );
}

export default AssessmentReportTable;