import React, {useEffect, useState} from 'react'
import {CButton, CCol, CContainer, CRow} from "@coreui/react-pro";
import Table from "../../components/tables/table";
import SponsorshipAnalysisPage from "./dashboard/SkillReports/SponsorshipAnalysisPage";
import {useGetAppliedBeneficiaries, useGetSponsorshipGraphData} from "../../hooks/user/jobApiHooks";
import {useLocation} from 'react-router-dom';
import ShowLoading from "../../components/ShowLoading";
import DataNotFound from "../../components/DataNotFound";
import StatCard from "../../components/employer/StatCard";
import {FaSchool, FaUsers} from "react-icons/fa";
import {BsFillBriefcaseFill} from "react-icons/bs";
import ChartComponent from "../../components/ChartComponent";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";

const Sponsorship = () => {
    const card_colors = ["card-border-green-500", "card-border-red", "card-border-yellow", "card-border-green-900"]

    const location = useLocation();
    const stateParams = location.state;
    const [page, setPage] = useState(1)
    const {
        isLoading,
        isError,
        data
    } = useGetAppliedBeneficiaries(stateParams?.sponsorshipId, page)
    const [gender, setGender] = useState([])
    const [genderData, setGenderData] = useState([])
    const [stateLabels, setStateLabels] = useState([])
    const [stateData, setStateData] = useState([])

    const {data: graphData} = useGetSponsorshipGraphData(stateParams?.sponsorshipId)

    //https://worknation-development.ogtlprojects.com/api/v1/sponsorships/graph-data/9
    useEffect(() => {
        let genderCounts = graphData?.data?.data?.genderCounts || {};
        setGender([{
            name: 'Male',
            gender: genderCounts?.male,
        },
            {
                name: 'Female',
                gender: genderCounts?.female,
            }])


        let stateCounts = graphData?.data?.data?.stateOfOriginCounts || {};
        let state = []
        Object?.entries(stateCounts).forEach(element => {
            state.push({
                name: element[0],
                state: element[1],
            });

        });
        setStateData(state);

    }, [graphData]);

    const [isAnalysisVisible, setIsAnalysisVisible] = useState(false)
    const [userTestData, setUserTestData] = useState(null)

    if (isLoading) {
        return <ShowLoading/>
    }

    const headers = [
        {name: "Applicants", sort: true},
        {name: "Scholarship Type", sort: true},
        {name: "Gender", sort: true},
        {name: "Employment Status", sort: true},
        {name: "Action"}]

    const items = data?.data?.data?.items
    const statsData = data?.data?.data

    const stats = [
        {
            total: statsData?.totalApplicants || "0",
            title: "Total Applicants",
            icon: FaUsers,
        },
        {
            total: statsData?.totalApplicants || "0",
            title: "Total Approved",
            icon: FaSchool
        },
        {
            total: statsData?.totalApplicantsAccepted || "0",
            title: "Total Rejected",
            icon: BsFillBriefcaseFill
        }
    ]
    return (
        <>
            {!isAnalysisVisible ?
                <CContainer className="mt-4">

                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <h5 className="text-2 mb-3 ps-1">List of Sponsorship Applicants</h5>
                    </div>
                    <CRow>
                        {
                            stats?.map((stat, index) =>
                                <CCol className="w-100 mt-2" key={index}>
                                    <StatCard pinned={stat} index={index} width={12} card_colors={card_colors}
                                              textColor={"text-black-50"}/>
                                </CCol>
                            )
                        }
                    </CRow>
                    <Table headers={headers}>
                        {
                            items?.map((item, index) => (
                                <tr className="cursor-pointer bg-white border"
                                    key={index}
                                    onClick={() => {
                                        setUserTestData(item)
                                        setIsAnalysisVisible(true)
                                    }}
                                >
                                    <td className="td-single-line fw-medium">
                                        {item?.user?.firstName + " " + item?.user?.lastName}
                                    </td>
                                    <td className="fw-medium">Loan</td>
                                    <td className="fw-medium">
                                        {item?.user?.profile?.gender}
                                    </td>
                                    <td className="fw-medium">  {
                                        item?.user?.profile?.employmentStatus ? item?.user?.profile?.employmentStatus : "NA"
                                    }</td>
                                    <td className="fw-medium">view</td>
                                </tr>
                            ))
                        }
                    </Table>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <CButton
                            disabled={page <= 1} onClick={() => setPage(page - 1)}
                            className={`mx-3 text-white py-1 pb-2 px-3`}>
                            <BiLeftArrow/>
                        </CButton>
                        <CButton
                            onClick={() => setPage(page + 1)}
                            className="mx-3 text-white py-1 pb-2 px-3"
                            disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                        >
                            <BiRightArrow/>
                        </CButton>
                    </div>
                    {
                        items.length < 1 &&
                        <DataNotFound/>
                    }
                    {items.length > 0 && <CRow>
                        <CCol md={12}>
                            <h3 className='my-4'>Infographics</h3>
                            <ChartComponent data={gender} chart_data={genderData} title={'gender'}/>
                            <ChartComponent data={stateData} chart_data={stateData} title={'state'} />
                        </CCol>
                    </CRow>}
                </CContainer> :
                <CContainer className="mt-4">
                    <SponsorshipAnalysisPage
                        userData={userTestData}
                        setIsAnalysisVisible={setIsAnalysisVisible}
                    />
                </CContainer>
            }
        </>
    )
}

export default Sponsorship
