import React, {useEffect, useState} from 'react';
import {CImage, CRow} from "@coreui/react-pro";
import LeftArrow from '../../assets/icons/left-arrow.svg';
import RightArrow from '../../assets/icons/right-arrow.svg';
import {useGetTestimonials} from "../../hooks/user/userApiHooks";
import ShowLoading from "../ShowLoading";
import {isMobile} from "react-device-detect";

const Testimonials = () => {

    const [testimonials, setTestimonials] = useState([]);
    const {data, isLoading} = useGetTestimonials();
    const [currentIndex, setCurrentIndex] = useState(0);
    const testimonialGapValue = isMobile ? 1 : 2;

    useEffect(() => {
        setTestimonials(data?.data?.data?.items)
    }, [data])

    if (isLoading) {
        return <ShowLoading/>
    }

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + testimonialGapValue < testimonials.length ? prevIndex + testimonialGapValue : 0));
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - testimonialGapValue >= 0 ? prevIndex - testimonialGapValue : testimonials.length - testimonialGapValue));
    };

    return <div className="bg-primary-dark text-white m-0 p-0 my-3">
        <CRow className="p-5 m-0">
            <div className="d-flex flex-row justify-content-between">
                <h1>Testimonials</h1>
                <div>
                    <CImage src={LeftArrow} className="me-4" onClick={prevSlide}/>
                    <CImage src={RightArrow} onClick={nextSlide}/>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-between flex-xs-column">
                {testimonials?.slice(currentIndex, currentIndex + testimonialGapValue).map(({avatarUrl, fullName, jobTitle, testimonial}, index) => (
                    <div className={`my-5 px-3 w-50 w-xs-100 ${(index % 2 === 0 && !isMobile) && 'testimonial-border'}`} key={index}>
                        <p>{testimonial}</p>
                        <div className="d-flex flex-row align-items-center mt-4">
                            <CImage src={avatarUrl} className="rounded-circle"
                                    style={{width: '32px', height: '32px'}}/>
                            <div className="ms-2">
                                <h6 className="m-0"><strong>{fullName}</strong></h6>
                                <p className="text-small text-white">{jobTitle}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </CRow>
    </div>
}

export default Testimonials;
