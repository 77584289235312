import * as Yup from 'yup'

export const EducationSchema = Yup.object().shape({
    educations: Yup.array().of(
        Yup.object().shape({
            //degree: Yup.string().required('Education Level is required'),
            //schoolName: Yup.string().required('Institution is required'),
            //fieldOfStudy: Yup.string().required('Field of Study is required'),
            //startMonth: Yup.string().required('Start Month is required'),
            //startDate: Yup.string().required('Start Year is required'),
            //endMonth: Yup.string().required('End Month is required'),
            //endDate: Yup.string().required('End Year is required'),
            //description: Yup.string().required('Description is required'),
            //city: Yup.string().required('City/Town is required'),
        })
    ),
});

export const educationFormat = {
    schoolName: "",
    degree: "",
    fieldOfStudy: "",
    startMonth: "",
    startDate: "",
    endMonth: "",
    endDate: "",
    description: "",
    city: ""
}
