import React, {useEffect} from 'react'
import {CCol} from '@coreui/react-pro'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useRegistrationConfirmationQuery} from "../../../hooks/user/userApiHooks";
import {fail_notify} from "../../../constant";
import ShowLoading from "../../../components/ShowLoading";

const ConfirmUser = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {isError, isLoading, isSuccess, data, mutate, error} = useRegistrationConfirmationQuery()

  const token = searchParams.get('token');
  const id = searchParams.get('id');

  useEffect(() => {
    mutate({token, id})
  }, [token, id, mutate])

  useEffect(() => {
    if (isSuccess) {
      navigate('/verified-success', {replace: true})
    }
    if (isError) {
      fail_notify(error?.response?.data?.message || "Invalid Email Verification Request")
    }
  }, [data, isError, error, isSuccess, navigate])

  if (isLoading) <ShowLoading/>

  return (
    <>
      <CCol md={8}>
        <h1 className="text-1">Email Verification</h1>
        {!!isLoading && <p className="mt-1 mb-5">Verifying email...</p>}
      </CCol>
    </>
  )
}

export default ConfirmUser
