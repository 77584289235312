import React from 'react';
import {CCol, CFormFeedback, CFormInput, CFormLabel, CRow} from "@coreui/react-pro";

function BioData({register, errors, nasimData}) {
    return (
        <>
            <CCol md={12} className="mb-5">
                <CFormLabel className="text-black mb-0">BIO Data</CFormLabel>
                <hr className="mt-0"/>
                <CRow className="gy-4">
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="surname"
                            placeholder="Last Name"
                            {...register('surname')}
                            invalid={!!errors.surname}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.lastName}>
                            {errors.lastName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="firstName"
                            placeholder="First Name"
                            {...register('firstName')}
                            invalid={!!errors.firstName}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.firstName}>
                            {errors.firstName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="otherName"
                            placeholder="Other Names"
                            {...register('otherName')}
                            invalid={!!errors.otherName}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.otherName}>
                            {errors.otherName?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="gender"
                            placeholder="Gender"
                            {...register('gender')}
                            invalid={!!errors.gender}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.gender}>
                            {errors.gender?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="dateOfBirth"
                            placeholder="Date Of Birth"
                            {...register('dateOfBirth')}
                            invalid={!!errors.dateOfBirth}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.dateOfBirth}>
                            {errors.dateOfBirth?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="placeOfBirth"
                            placeholder="Place of Birth"
                            {...register('placeOfBirth')}
                            invalid={!!errors.placeOfBirth}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.placeOfBirth}>
                            {errors.placeOfBirth?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="Email AddressMeansOfId"
                            id="email"
                            readOnly
                            {...register('email')}
                            invalid={!!errors.email}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.email}>{errors.email?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="Phone Number"
                            id="phoneNumber"
                            readOnly
                            {...register('phoneNumber')}
                            invalid={!!errors.phoneNumber}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback
                            invalid={!!errors.phoneNumber}>{errors.phoneNumber?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="Alternate Phone Number"
                            id="altphone"
                            readOnly
                            {...register('altphone')}
                            invalid={!!errors.altphone}

                        />
                        <CFormFeedback invalid={!!errors.altphone}>
                            {errors.altphone?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="BVN"
                            id="bvn"
                            readOnly
                            {...register('bvn')}
                            invalid={!!errors.bvn}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.bvn}>{errors.bvn?.message}</CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="Bank Account Number"
                            id="accountNumber"
                            readOnly
                            {...register('accountNumber')}
                            invalid={!!errors.accountNumber}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.accountNumber}>
                            {errors.accountNumber?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            placeholder="Disabilities"
                            id="disability"
                            readOnly
                            {...register('disability')}
                            invalid={!!errors.disability}
                        />
                        <CFormFeedback invalid={!!errors.disability}>
                            {errors.disability?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="stateOfOrigin"
                            placeholder="State Of Origin"
                            {...register('stateOfOrigin')}
                            invalid={!!errors.stateOfOrigin}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.stateOfOrigin}>
                            {errors.stateOfOrigin?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="localGovernmentOfOrigin"
                            placeholder="Local Government Area"
                            {...register('localGovernmentOfOrigin')}
                            invalid={!!errors.localGovernmentOfOrigin}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.localGovernmentOfOrigin}>
                            {errors.localGovernmentOfOrigin?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
            </CCol>
            <CCol md={12} className="mb-5">
                <CFormLabel className="text-black mb-0">AddressMeansOfId</CFormLabel>
                <hr className="mt-0"/>
                <CRow className="gy-4">
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="stateOfResidence"
                            placeholder="State Of Residence"
                            {...register('stateOfResidence')}
                            invalid={!!errors.stateOfResidence}
                            disabled={!!nasimData}

                        />
                        <CFormFeedback invalid={!!errors.stateOfResidence}>
                            {errors.stateOfResidence?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="localGovernmentOfResidence"
                            placeholder="Local Government Of Residence"
                            {...register('localGovernmentOfResidence')}
                            invalid={!!errors.localGovernmentOfResidence}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.localGovernmentOfResidence}>
                            {errors.localGovernmentOfResidence?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="address"
                            placeholder="Home AddressMeansOfId"
                            {...register('address')}
                            invalid={!!errors.address}
                            disabled={!!nasimData}
                        />
                        <CFormFeedback invalid={!!errors.address}>
                            {errors.address?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
            </CCol>
        </>
    );
}

export default BioData;