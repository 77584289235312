import React, {useEffect, useState} from 'react'
import {
    CButton,
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect, CImage,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow
} from "@coreui/react-pro";
import {BiEnvelope, BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import {useAddGov,} from "../../hooks/admin/adminApiHooks";
import {Link} from "react-router-dom";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {AddGovSchema} from "../../form-schema/admin-form-schema/createAdminSchema";
import {fail_notify, success_notify} from "../../constant";
import {useGetAllEmployers} from "../../hooks/employer/employerApiHooks";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png";
import DataNotFound from "../../components/DataNotFound";
import ShowLoading from "../../components/ShowLoading";

const Government = () => {
    const [agenciesData, setAgenciesData] = useState([])
    const [visible, setVisible] = useState(false)
    const {isLoading, isSuccess, data, isError, error, mutate} = useAddGov()
    const [isImgError, setImgError] = useState(false)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const {
        data: agencies,
        isLoading: isLoadingAgencies,
        isSuccess: isSuccessAgencies,
        isError: isErrorAgencies,
        error: errorAgencies,
        refetch
    } = useGetAllEmployers(limit, page, 'Government')

    useEffect(() => {

        if (isSuccess) {
            success_notify('Successful')
            setVisible(false)
        }

        if (isError || isErrorAgencies) {
            fail_notify(error?.response?.data?.message || errorAgencies?.response?.data?.message)
        }


        //console.log(agencies?.data?.data?.items)

        setAgenciesData(agencies?.data?.data?.items?.filter((item) => {
            return !item?.institutionsType
        }).map((item, index) => {
            if (item?.institutionsType) {
                return
            } else {
                return {
                    id: index,
                    user: [item?.name, item?.logoUrl || DummyProfilePic],
                    phoneNumber: item.phoneNumber,
                    email: item.email,
                    registered: item?.createdAt.substring(0, 10),
                    action: item?.stakeholderUuid
                }
            }
        }))

    }, [isLoading, isSuccess, data, isError, error, mutate, agencies, isLoadingAgencies, isSuccessAgencies, isErrorAgencies, errorAgencies])

    useEffect(() => {
        refetch()
    }, [limit]);

    const {
        register, handleSubmit, formState: {errors}
    } = useForm({
        resolver: yupResolver(AddGovSchema),
    })
    const handleSignUp = (data) => {
        mutate(data)
    }

    const convertToDate = (date) => {
        const _date = new Date(Date.parse(date))
        return _date.toLocaleDateString()
    }

    const convertUserDisplay = (user) => {
        return (

            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                {isImgError ? <CImage src={DummyProfilePic} alt="Profile" style={{
                        width: '44px',
                        height: '44px',
                        marginRight: '10px',
                        objectFit: 'contain',
                    }}/> :
                    <CImage src={user[1]} alt="Profile" style={{
                        width: '44px',
                        height: '44px',
                        marginRight: '10px',
                        objectFit: 'contain',
                    }} isError={() => setImgError(true)}/>}
                <p style={{marginBottom: "0"}} className="w-75 text-truncate"><b>{user[0]}</b></p>
            </div>
        )
    }

    const convertAction = (action) => {
        return <Link to={`/admin/employer/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }


    return (<CContainer>
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div>
                <h5>Government Agencies</h5>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
                <CButton onClick={() => setVisible(!visible)} className="text-white px-5 users-action-buttons">+ ADD
                    GOV</CButton>
            </div>
        </div>
        {/*{!isMobile && <div>
                <div className="d-flex flex-row justify-content-end align-items-center px-1">
                    <p
                        className="text-primary cursor-pointer"

                    >Clear all</p>
                </div>
                <CRow>
                    <CCol md={4}>
                        <CFormLabel htmlFor="name" className="m-1 label">Name</CFormLabel>
                        <CFormInput type="text" id="name"
                                    aria-describedby="searchByText"

                                    className="rounded-0"
                        />
                    </CCol>
                    <CCol md={4}>
                        <CFormLabel htmlFor="email" className="m-1 label">Email</CFormLabel>
                        <CFormInput type="text" id="email"
                                    aria-describedby="searchByEmail"

                                    className="rounded-0"
                        />
                    </CCol>
                </CRow>
            </div>}*/}
        <CRow className="mt-3 mb-5">
            <CCol md={12}>
                <div className="table-responsive">

                    <table className="table table-responsive" id="reports-tables">
                        <thead className="thead-inverse bg-transparent text-primary">
                        <tr className="bg-transparent text-primary">
                            <th>User</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Registered</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {agenciesData?.map((item, index) => {
                            return <tr key={index}>
                                {isLoadingAgencies && <ShowLoading/> }
                                <td >{convertUserDisplay(item.user)}</td>
                                <td >
                                    <div className="d-flex align-items-center gap-1"><BsTelephone/> {item.phoneNumber}
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center gap-1 text-truncate">
                                        <BiEnvelope/> {item.email}</div>
                                </td>
                                <td className="flex-fill">{convertToDate(item.registered)}</td>
                                <td  >{convertAction(item.action)}</td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    {(!isLoadingAgencies && agenciesData?.length < 1) && <DataNotFound/>}
                </div>

                {agencies?.data?.data?.meta?.itemCount && <div className={"d-flex justify-content-between flex-wrap gap-2 w-100"}>
                    <div className={"d-flex flex-row gap-3"}>
                        <div className={"m-2"}>Show</div>
                        <CFormSelect
                            aria-label="Default select example"
                            options={[{label: '10', value: '10'}, {label: '25', value: '25'}, {
                                label: '100',
                                value: '100'
                            }, {label: '200', value: '200'}]}
                            onChange={(e) => setLimit(e.target.value)}
                        />
                        <div className={"m-2"}>Entries</div>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        Prev<CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                     className={`mx-3 text-white py-1 pb-2 px-3`}>
                        <BiLeftArrow/>
                    </CButton>

                        <CButton
                            disabled={page === agencies?.data?.data?.meta?.totalPages || isLoadingAgencies || isErrorAgencies}
                            onClick={() => setPage(page + 1)} className="mx-3 text-white py-1 pb-2 px-3">
                            <BiRightArrow/>
                        </CButton> Next
                    </div>
                </div>}

            </CCol>
        </CRow>
        <CModal
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="LiveDemoExampleLabel"
            alignment="center"
        >
            <CModalHeader onClose={() => setVisible(false)}>
                <CModalTitle id="LiveDemoExampleLabel">Add Government Agency</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CForm noValidate className="needs-validation" onSubmit={handleSubmit(handleSignUp)}>
                    <div className="w-100">
                        <div>
                            <CFormLabel htmlFor="searchBy" className="m-1 label">Name</CFormLabel>
                            <CFormInput type="text" id="gov_name"
                                        placeholder="Ministry of Works"
                                        {...register('gov_name')}
                                        invalid={!!errors.gov_name}
                            />
                            <CFormFeedback invalid={!!errors.gov_name}>
                                {errors.gov_name?.message}
                            </CFormFeedback>
                        </div>
                        <div className="">
                            <CFormLabel htmlFor="searchBy" className="m-1 label">Email</CFormLabel>
                            <CFormInput type="email" id="email"
                                        placeholder="info@ministry.gov.ng"
                                        {...register('email')}
                                        invalid={!!errors.email}
                            />
                            <CFormFeedback invalid={!!errors.email}>
                                {errors.email?.message}
                            </CFormFeedback>
                        </div>
                        <div>
                            <CFormLabel htmlFor="searchBy" className="m-1 label">Phone Number</CFormLabel>
                            <CFormInput type="text" id="phoneNumber"
                                        placeholder="09067898765"
                                        {...register('phoneNumber')}
                                        invalid={!!errors.phoneNumber}
                            />
                            <CFormFeedback invalid={!!errors.phoneNumber}>
                                {errors.phoneNumber?.message}
                            </CFormFeedback>
                        </div>
                        <br/>
                        <div className="text-center">
                            <CLoadingButton
                                type="submit"
                                className="reg-btn px-4 w-50 py-2 text-center"
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Submit
                            </CLoadingButton>
                        </div>
                    </div>
                </CForm>
            </CModalBody>

        </CModal>
    </CContainer>)
}

export default Government
