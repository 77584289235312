import React from 'react';
import {CCard, CCardBody, CCardText, CCardTitle, CCol} from "@coreui/react-pro";
// import {MdCardMembership, MdOutlineDashboard, MdOutlineHelpCenter,} from 'react-icons/md'
import {useNavigate} from "react-router-dom";
import {formatNumber} from "../../constant";

function StatCard({pinned, index, width, card_colors, textColor}) {

    // const icons = [MdCardMembership, MdOutlineDashboard, MdOutlineHelpCenter]
    // const icon = icons[index]
    //
    // function classNames(...classes) {
    //     return classes.filter(Boolean).join(' ')
    // }
    const navigate = useNavigate()
    const handleRoute = (to)=>{
        navigate(to, {
            replace: true,
        })
    }

    return (
        <CCol sm={width} className="cursor-pointer" onClick={()=>handleRoute(pinned?.to)}>
            <CCard className={`${card_colors[index]}`}>
                <CCardBody className="d-flex flex-row justify-content-between align-items-center text-decoration-none px-1 py-2">
                    <CCol className="d-flex flex-row justify-content-end" md={3}>
                        <pinned.icon size={50} className={`${textColor ? textColor : 'text-white'} p-2`} style={{backgroundColor: "rgba(0, 128, 0, 0.1)", borderRadius: '50%'}}/>
                    </CCol>
                    <CCol className="align-content-start ms-2" md={9}>
                        <h6 className={`text-1 text-decoration-none text-black p-0 m-0`}>{formatNumber(pinned?.total)}</h6>
                        <p className={`text-black-50 text-truncate p-0 m-0`}>
                            {pinned?.title}
                        </p>
                    </CCol>
                    </CCardBody>
            </CCard>
        </CCol>

    );
}

export default StatCard;