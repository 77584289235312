import React from 'react'
import {useAppContext} from "../../../context/user/UserAppProvider";
import PrimaryAccountDashboard from "./components/PrimaryAccountDashboard";
import TrainersDashboard from "./components/TrainersDashboard";
import {INSTRUCTOR, STAKEHOLDERS} from "../../../constant/roles";

const EmployerDashboard = () => {
    const {user} = useAppContext()
    const userRole = user?.data?.role

    return <>
        {
            STAKEHOLDERS.includes(userRole) &&
            <PrimaryAccountDashboard/>
        }
        {
            userRole === INSTRUCTOR &&
            <TrainersDashboard user={user}/>
        }
    </>
}

export default EmployerDashboard

