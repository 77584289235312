export function transformData(input) {
    const educationArray = input?.education;
    const experienceArray = input?.experience?.join("\n") || input['professional experience']?.join("\n");
    const skillsArray = input.skills || [];
    const publications = input.publications || [];
    const achievements = input.achievements || [];
    const languages = input.languages || [];

    const output = {
        educations: [],
        publications: publications,
        workExperience: [],
        achievements: achievements,
        skills: skillsArray,
        languages: languages,
    };

    output.educations = extractSchoolInfo(educationArray)
    output.workExperience = extractJobExperience(experienceArray);
    return output;
}

function extractSchoolInfo(array) {
    let schools = [];
    let currentSchool = "";
    let currentDescription = [];
    let currentDegree = ""

    for (let i = 0; i < array.length; i++) {
        if (array[i].includes("University")) {
            if (currentSchool !== "") {
                schools.push({
                    schoolName: currentSchool,
                    description: currentDescription.join("/n"),
                    degree: currentDegree
                });
            }
            currentSchool = array[i];
            currentDescription = [];
        } else if ((array[i].includes("Master") && currentSchool !== "")) {
            currentDegree = array[i]
        } else if ((array[i].includes("Bachelor") && currentSchool !== "")) {
            currentDegree = array[i]
        } else {
            currentDescription.push(array[i]);
        }
    }

    if (currentSchool !== "") {
        schools.push({
            schoolName: currentSchool,
            description: currentDescription.join("/n"),
            degree: currentDegree
        });
    }

    return schools;
}

function extractJobExperience(text) {
    if (!text) {
        return []
    }
    // Split the input text into lines
    const lines = text.trim().split('\n');

    // Initialize variables to store information
    const jobExperiences = [];
    let companyName = null;
    let jobTitle = null;
    const description = [];

    // Helper function to create a job experience object
    const createJobExperience = () => {
        if (companyName !== null && jobTitle !== null) {
            jobExperiences.push({
                companyName: companyName,
                jobTitle: jobTitle,
                description: description.slice().join(" ")
            });
        }
        companyName = null;
        jobTitle = null;
        description.length = 0;
    };

    // Keyword Patterns to Decide if the text is a company
    const keywords = ['Till Date', 'Limited', "LTD", 'Inc'];
    const datePattern = /\b\d{4}\s*-\s*(?:\d{4}|\bTill Date\b)\b/;

    // Loop through each line to extract information
    for (let line of lines) {

        const keywordPattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'i');

        if (datePattern.test(line) || keywordPattern.test(line)) {
            // If the line matches the date-like pattern or a keyword, it's the companyName
            createJobExperience();
            companyName = line.trim();
        } else if (companyName !== null && jobTitle === null) {
            // The block immediately after companyName is the job title
            jobTitle = line.trim();
        } else if (companyName !== null && jobTitle !== null) {
            // Subsequent blocks are duties
            description.push(line.trim());
        }
    }

    // Create the last job experience
    createJobExperience();

    return jobExperiences;
}

