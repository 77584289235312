export const modalPrompts = {
    personalitytest: {
        title: "Welcome to the Worknation personality test!",
        msgContent: "Get ready to discover your hidden traits with our personality test. This quick assessment will provide valuable insights into your personality type. \n" + "It'll only take a few minutes of your time, so let's get started!\n"
    },
    softskillstest: {
        title: "Welcome to the Worknation soft skill test!",
        msgContent: "Prepare to showcase your exceptional soft skills with our comprehensive test. \n" + "This assessment is designed to evaluate various aspects of your interpersonal abilities, communication, adaptability, and problem-solving skills. The test consists of four sections, each targeting different soft skills crucial for success. We value your time, and the test will take approximately 20 minutes to complete. \n" + "Let's dive in and discover your strengths in these key areas!\n",
        sections:{
            communicationtest:{
                title:"Welcome to the Communication Test section of our Soft Skills assessment! \n" ,
                msgContent:"This test aims to evaluate your ability to effectively convey ideas, listen actively, and engage in meaningful dialogue. \n" +
                    "It will only take a few minutes of your time, so let's assess your communication prowess!\n"
            },
            problemsolvingtest:{
                title:"Welcome to the Problem Solving section of our Soft Skills test! \n",
                msgContent:"This segment assesses your critical thinking and analytical abilities. In just a few minutes, you'll tackle thought-provoking scenarios and demonstrate your innovative problem-solving skills. \n" +
                    "Get ready to showcase your strategic mindset and find effective solutions!\n"
            },
            relationshipbuildingtest:{
                title:"Welcome to the Relationship Building section of our Soft Skills Test!\n",
                msgContent:"This segment assesses your ability to forge meaningful connections, collaborate effectively, and nurture professional relationships. Take a few minutes to showcase your interpersonal prowess and build a strong foundation for success. \n" +
                    "Let's get started! \n"
            },
            adaptabilitytest:{
                title:"Welcome to the Adaptability test section! \n",
                msgContent:"This segment of our Soft Skills assessment focuses on your ability to embrace change and navigate challenging situations. In just a few minutes, we'll evaluate your adaptability skills and how well you handle uncertainty.\n" +
                    "Let's see how quickly you can adapt to new scenarios!\n"
            },

        }
    },
    levelonequestionnaire:{
        title:'Welcome Note',
        msgContent:"Greetings! We appreciate your decision to choose Worknation. You are now about to embark on the general questionnaire, which merely reflects your fundamental knowledge and enables us to get to know you better."
    },
    culturefittest:{
        title:'Welcome Note',
        msgContent:"Greetings! We appreciate your decision to choose Worknation. You are now about to embark on the Culture Fit Questionnaire which merely reflects your adaptability in the work environment. Thank you"
    },
    qualityassurance:{
        title:'Welcome Note',
        msgContent:"Greetings! We appreciate your decision to choose Worknation. You are now about to embark on the Quality Assurance, which merely reflects your fundamental knowledge and enables us to get to know you better."
    },

    integritytest:{
        title:'Welcome Note',
        msgContent:"Greetings! We appreciate your decision to choose Worknation. You are now about to embark on the Integrity Test, which merely reflects your fundamental knowledge and enables us to get to know you better."
    }

}