import * as Yup from 'yup'

export const ExperienceSchema = Yup.object().shape({
    workExperience: Yup.array().of(
        Yup.object().shape({
            companyName: Yup.string() .typeError('Employer name is required').required('Employer name is required'),
            currentlyWorking: Yup.boolean().typeError(),
            jobTitle: Yup.string().typeError(),
            startMonth: Yup.string().typeError(),
            startDate: Yup.string().typeError(),
            endMonth: Yup.string().when('currentlyWorking', {
                is: false,
                then: Yup.string().required('End month is required'),}),
            endDate: Yup.string().when('currentlyWorking', {
                is: false,
                then: Yup.string().required('End year is required'),
            }),
            city: Yup.string().typeError(),
            description: Yup.string().typeError(),
        })
    ),
})

export const initialExperience = {
    companyName: "",
    currentlyWorking: false,
    jobTitle: "",
    startMonth: "",
    startDate: "",
    endMonth: "",
    endDate: "",
    city: "",
    description: ""
}