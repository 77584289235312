import React, {useEffect} from 'react';
import {CCol, CForm, CFormCheck, CFormFeedback, CFormSelect, CLoadingButton, CRow} from "@coreui/react-pro";
import {useSetSkillQuery} from "../../hooks/user/userApiHooks";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {useAppContext} from "../../context/user/UserAppProvider";
import ErrorReports from "../ErrorReports";
import {fail_notify, success_notify} from "../../constant";
import {useNavigate} from "react-router-dom";

function SkillSetForm({availableSkills, skill}) {
    const {header} = useAppContext()
    const navigate = useNavigate()
    const initialValues = {skillSet: Array(availableSkills?.length).fill({title: "", yearOfExperience: ""})}

    const {
        mutate,
        isSuccess,
        isError,
        error,
        data,
        isLoading
    } = useSetSkillQuery()

    const {
        register,
        handleSubmit,
        formState: {errors},
        control,

    } = useForm({
        defaultValues: {...initialValues}
    })

    //UseField Array for IncomeTax Table Manipulation
    const {fields} = useFieldArray({
        control, name: "skillSet",
    });

    const value = useWatch({
        name: "skillSet",
        control
    });

    useEffect(() => {

        if (isSuccess) {
            success_notify('Skills Set Submitted Successfully')
            navigate(`/user/dashboard`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isSuccess, isError])

    const handleSkillSetSubmission = (data) => {
        const skills = []
        Object.values(data?.skillSet).map((item) => {
                if (item.title) {
                    skills.push(item)
                }
        })

        if (skills.length >= 3 && skills.length <= 5) {
            mutate({skills, header})
        } else {
            fail_notify('The Number of Career Path Chosen Must be Greater 3 and less than 5 ')
        }
    }
    const checkSkill = (data) => {
        const usersSkills = skill?.data?.data?.skills
        return usersSkills?.find((item) => item?.title === data)?.title
    }

    //console.log(data)

    return (
        <CForm className="w-100" onSubmit={handleSubmit(handleSkillSetSubmission)}>
            {
                isError && <ErrorReports error={error}/>
            }
            <CRow className="mb-5" xs={{cols: '2'}}>
                {
                    fields.map((item, index) => (

                         <CRow key={index} className="mt-4 px-4">
                                <CCol className="d-flex flex-row form-group" sm={6}>
                                    <CFormCheck
                                        name={`skillSet.${index}.title`}
                                        label={`${availableSkills[index].title}`}
                                        value={`${availableSkills[index].title}`}
                                        {...register(`skillSet.${index}.title`)}
                                        checked={checkSkill(availableSkills[index].title)}
                                    />
                                </CCol>
                                {
                                    ((value[index].title === availableSkills[index].title) || checkSkill(availableSkills[index]?.title) === availableSkills[index].title) &&
                                    <CCol>
                                        <CFormSelect
                                            name={`skillSet.${index}.yearOfExperience`}
                                            aria-label="Default select example"
                                            {...register(`skillSet.${index}.yearOfExperience`, {required: true})}
                                            invalid={!!errors.skillSet?.[index]?.yearOfExperience}
                                        >
                                            <option value="">Select Proficiency Level</option>
                                            <option value="Novice">Novice</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Intermediate">Intermediate</option>
                                            <option value="Advanced">Advanced</option>
                                            <option value="Expert">Expert</option>
                                        </CFormSelect>
                                        {
                                            errors.skillSet?.[index]?.yearOfExperience &&
                                            <CFormFeedback invalid={!!errors.skillSet?.[index]?.yearOfExperience}>
                                                Please Select Proficiency Level
                                            </CFormFeedback>
                                        }
                                    </CCol>
                                }
                            </CRow>

                    ))
                }
            </CRow>


            <div className="d-flex justify-content-center align-items-center">
                <CCol xs={6}>
                    <CLoadingButton
                        type="submit"
                        loading={isLoading}
                        disabled={isLoading}
                        className="reg-btn px-4 w-100 py-2"
                    >
                        Submit
                    </CLoadingButton>
                </CCol>
            </div>
        </CForm>
    );
}

export default SkillSetForm;
