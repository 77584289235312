import React from 'react';
import {CCol, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import {countries} from "../../../../constant";
import {useGetNigerianCities} from "../../../../hooks/user/userApiHooks";

function BioDetails({errors, register}) {
    const {data: cities} = useGetNigerianCities()

    return (
        <>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">First Name</CFormLabel>
                    <CFormInput
                        type="text"
                        id="firstName"
                        placeholder="Enter First Name"
                        {...register('firstName')}
                        invalid={!!errors.firstName}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.firstName}>
                        {errors.firstName?.message}
                    </CFormFeedback>
                </CCol>
                <CCol>
                    <CFormLabel className="text-black mb-0">Last Name</CFormLabel>
                    <CFormInput
                        type="text"
                        id="lastName"
                        placeholder="Enter Last Name"
                        {...register('lastName')}
                        invalid={!!errors.lastName}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.lastName}>
                        {errors.lastName?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Middle Name</CFormLabel>
                    <CFormInput
                        type="text"
                        id="otherName"
                        placeholder="Enter Middle Name"
                        {...register('otherName')}
                        invalid={!!errors.otherName}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.otherName}>
                        {errors.otherName?.message}
                    </CFormFeedback>
                </CCol>
                <CCol>
                    <CFormLabel className="text-black mb-0">Email Address</CFormLabel>
                    <CFormInput
                        type="email"
                        id="email"
                        placeholder="example@example.com"
                        {...register('email')}
                        invalid={!!errors.email}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.email}>
                        {errors.email?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Phone Number</CFormLabel>
                    <CFormInput
                        type="text"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        {...register('phoneNumber')}
                        invalid={!!errors.phoneNumber}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.phoneNumber}>
                        {errors.phoneNumber?.message}
                    </CFormFeedback>
                </CCol>
                <CCol>
                    <CFormLabel className="text-black mb-0">Contact Address</CFormLabel>
                    <CFormInput
                        type="text"
                        id="address"
                        placeholder="Plot 841 Hill town"
                        {...register('address')}
                        invalid={!!errors.address}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.address}>
                        {errors.address?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Zip Code</CFormLabel>
                    <CFormInput
                        type="number"
                        id="zipCode"
                        placeholder="00001"
                        {...register('zipCode')}
                        invalid={!!errors.zipCode}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.zipCode}>
                        {errors.zipCode?.message}
                    </CFormFeedback>
                </CCol>
                <CCol>
                    <CFormLabel className="text-black mb-0">City/Town</CFormLabel>
                    {/*<CFormInput*/}
                    {/*    type="text"*/}
                    {/*    id="city"*/}
                    {/*    placeholder="Abuja"*/}
                    {/*    {...register('city')}*/}
                    {/*    invalid={!!errors.city}*/}
                    {/*    className="mb-2"*/}
                    {/*/>*/}
                    <CFormSelect
                        id="city"
                        className="form-control mb-2"
                        {...register('city')}
                        invalid={!!errors.city}
                    >
                        {cities?.data?.data?.map(({name}, index) => (
                            <option value={name} key={index}>{name}</option>
                        ))}
                    </CFormSelect>
                    <CFormFeedback invalid={!!errors.city}>
                        {errors.city?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Nationality</CFormLabel>
                    <CFormSelect
                        {...register('nationality')}
                        invalid={!!errors.nationality}
                    >
                        <option value="">Select Nationality</option>
                        {countries.map((month, i) => (
                            <option key={i} value={month}>
                                {month}
                            </option>
                        ))}

                    </CFormSelect>
                    <CFormFeedback invalid={!!errors.nationality}>
                        {errors.nationality?.message}
                    </CFormFeedback>
                </CCol>
                <CCol>
                    <CFormLabel className="text-black mb-0">Social Links (LinkedIn, Twitter etc)</CFormLabel>
                    <CFormInput
                        type="url"
                        id="socialLinks"
                        placeholder="Linkedin.com/username"
                        {...register('socialLinks')}
                        invalid={!!errors.socialLinks}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.socialLinks}>
                        {errors.socialLinks?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 2}} className="g-3 py-2">
                <CCol>
                    <CFormLabel className="text-black mb-0">Profession</CFormLabel>
                    <CFormInput
                        type="text"
                        id="profession"
                        placeholder="Engineer"
                        {...register('profession')}
                        invalid={!!errors.profession}
                        className="mb-2"
                    />
                    <CFormFeedback invalid={!!errors.profession}>
                        {errors?.profession?.message}
                    </CFormFeedback>
                </CCol>
            </CRow>
        </>
    );
}

export default BioDetails;