import React, {useEffect, useState} from 'react'
import {CCol, CForm, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CLoadingButton, CRow,} from '@coreui/react-pro'
import {fail_notify, success_notify} from '../../constant'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useNavigate} from 'react-router-dom'
import ErrorReports from '../../components/ErrorReports'
import 'react-datepicker/dist/react-datepicker.css'
import {useAppContext} from 'src/context/user/UserAppProvider'
import {useGetStakeholderTypeQuery, useValidateTin,} from 'src/hooks/employer/employerApiHooks'
import {TinSchema} from 'src/form-schema/stake-holder-schema/TinSchema'
import EmployerRegistration from "./EmployerRegistration";

function ValidateTin() {
    // getting all employer types
    const {
        data: STdata,
    } = useGetStakeholderTypeQuery()

    const navigate = useNavigate()
    const {storeUser} = useAppContext()
    const [validatedUser, setValidatedUser] = useState(null)

    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
    } = useForm({
        resolver: yupResolver(TinSchema),
        shouldUnregister: true,
        defaultValues: {
            stakeHolderType: "",
            institutionType: "",
            organizationStatus: ""

        },
    })

    const employerType = watch("stakeHolderType")

    const {isLoading, isSuccess, isError, error, data, mutate} = useValidateTin()

    const handleRegistration = async (data) => {
        setValidatedUser(data)
        mutate(data?.tin)
    }

    useEffect(() => {
        if (isSuccess) {
            success_notify('successfully created')
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, navigate, error, storeUser, isSuccess])

    if (isSuccess) {
        return <EmployerRegistration prevState={validatedUser} validatedData={data?.data?.data}/>
    }
    return (
        <div className="d-flex flex-column w-100 px-md-5">

            <div className="border-radius-12 px-4 py-4 w-100 px-md-5 ">
                <CForm className="w-100" onSubmit={handleSubmit(handleRegistration)}>
                    <h2 className="text-2 mb-3 text-black">Create Account</h2>
                    <p className="text-medium-emphasis"></p>
                    {isError && <ErrorReports error={error}/>}


                    <CCol>
                        <CRow className="gy-4 ">
                            <CCol className="mt-4" md={12}>
                                <CFormLabel className="m-1 label">Company Type</CFormLabel>
                                <CFormSelect
                                    aria-label="Default select example"
                                    {...register('stakeHolderType')}
                                    invalid={!!errors.stakeHolderType}
                                >
                                    <option value="">Select Options</option>
                                    {
                                        STdata?.data?.data.map((st, id) =>
                                            <option value={st?.name} key={id}>{st?.name}</option>
                                        )
                                    }
                                </CFormSelect>
                                <CFormInput type="hidden" invalid={!!errors.organizationStatus}/>
                                <CFormFeedback invalid={!!errors.stakeHolderType}>
                                    {errors.stakeHolderType?.message}
                                </CFormFeedback>
                            </CCol>
                            <>
                                {
                                    employerType === "NGO" &&
                                    <CCol className="mt-4" md={12}>
                                        <CFormLabel className="m-1 label">Organization Status</CFormLabel>
                                        <CFormSelect
                                            aria-label="Default select example"
                                            id="organizationStatus"
                                            {...register('organizationStatus')}
                                            invalid={!!errors.organizationStatus}

                                        >
                                            <option value="">
                                                Organization Status
                                            </option>
                                            <option value={'Local'}>Local</option>
                                            <option value={'International'}>International</option>
                                        </CFormSelect>
                                        <CFormInput type="hidden" invalid={!!errors.organizationStatus}/>
                                        <CFormFeedback invalid={!!errors.organizationStatus}>
                                            {errors.organizationStatus?.message}
                                        </CFormFeedback>
                                    </CCol>
                                }
                                {
                                    employerType === "Institution" &&
                                    <CCol className="mt-4" md={12}>
                                        <CFormLabel className="m-1 label"> Institution type</CFormLabel>
                                        <CFormSelect
                                            aria-label="Default select example"
                                            id="institutionType"
                                            {...register('institutionType')}
                                            invalid={!!errors.institutionType}
                                        >
                                            <option value="">
                                                Institution type
                                            </option>
                                            <option value={'Vocational'}>Vocational</option>
                                            <option value={'Academic'}>Academic</option>
                                        </CFormSelect>
                                        <CFormFeedback invalid={!!errors.institutionType}>
                                            {errors.institutionType?.message}
                                        </CFormFeedback>
                                    </CCol>
                                }

                            </>
                            <CCol>
                                <CFormLabel className="m-1 label">Taxpayer Identification Number</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="tin"
                                    {...register('tin')}
                                    placeholder="Enter TIN"
                                    invalid={!!errors.tin}
                                />
                                <CFormFeedback invalid={!!errors.tin}>{errors.tin?.message}</CFormFeedback>
                            </CCol>

                        </CRow>


                        <div className="d-flex justify-content-left align-items-center mt-3">
                            <CCol md={4}>
                                <CLoadingButton
                                    type="submit"
                                    className="reg-btn px-4 w-100 py-2"
                                    loading={isLoading}
                                    disabled={isLoading}
                                >
                                    Continue
                                </CLoadingButton>
                            </CCol>
                        </div>
                    </CCol>
                </CForm>
            </div>
        </div>

    )
}

export default ValidateTin
