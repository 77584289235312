import React from 'react';
import {CModal, CModalBody} from "@coreui/react-pro";
import {useGetMatchingAndProfilingQuestions} from "../../hooks/user/userApiHooks";
import {useForm} from "react-hook-form";
import {formatMatchingAndProfileQuestions} from "../job/JobApplicatonUtility";
import CVUploadModal from "../../user/cv-builder/CVUploadModal";
import AdditionalQuestions from "../../user/cv-builder/AdditionalQuestions";
import {AiFillCloseCircle} from "react-icons/ai";

function MatchingAndProfilingModal({visible, JobIsSuccess,next, setNext, setVisible, tags, handler, jobInfo, setCVId, cvId}) {
    const {handleSubmit, control, formState: {errors}} = useForm()
    const {isSuccess,isLoading, isError, data, error} = useGetMatchingAndProfilingQuestions()
    const matchingQuestions = data?.data?.data

    const handleMatchProfileSubmit = (data) => {
        const formData = formatMatchingAndProfileQuestions(data, jobInfo)
        handler(formData)
    }
    return (
        <>
            <CModal size={JobIsSuccess ? 'sm' : 'lg'} alignment={'center'} visible={visible} onClose={() => setVisible(false)}>
                <CModalBody className="px-lg-5">
                    <div className={'d-flex justify-content-end mb-2'}>
                    <AiFillCloseCircle size={30} onClick={() => setVisible(false)} className={"text-primary "}/>
                </div>
                    {
                        (next === 1 && !!isSuccess) &&
                        <CVUploadModal setNext={setNext} setCVId={setCVId}/>
                    }
                    {
                        (next === 2  && cvId) &&
                        <AdditionalQuestions
                            handleMatchProfileSubmit={handleMatchProfileSubmit}
                            handleSubmit={handleSubmit}
                            errors={errors}
                            isError={isError}
                            matchingQuestions={matchingQuestions}
                            setVisible={setVisible}
                            tags={tags}
                            error={error}
                            control={control}
                            setNext={setNext}
                            cvId ={cvId}
                        />
                    }

                </CModalBody>
            </CModal>
        </>
    );
}

export default MatchingAndProfilingModal;