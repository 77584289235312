import React from 'react'
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import StatCard from "../../../components/employer/StatCard";
import {AiOutlineDiff, AiOutlineUser} from "react-icons/ai";
import {BsBriefcase} from "react-icons/bs";
import {useGetUsersQuery} from "../../../hooks/employer/employerApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import DataNotFound from "../../../components/DataNotFound";
import {
    useGetAllQuestionnairesQuery, useGetAppStats,
    // useGetJobsQuery,
    // useGetTrainingProgramQuery
} from "../../../hooks/user/userApiHooks";
import {IoSchoolOutline} from "react-icons/io5";
import {HiOutlineUsers} from "react-icons/hi";

const AdminDashboard = () => {
    const card_colors = ["text-primary"]
    const {isLoading} = useGetUsersQuery()

    // const jobs = useGetJobsQuery(1, 1, '', '', '', true);
    // const training = useGetTrainingProgramQuery(1, 1, '', '', true)
    const {data: allSkills} = useGetAllQuestionnairesQuery()
    const {data: statData} = useGetAppStats()

    const stats = [
        {
            total: statData?.data?.data?.users || "0",
            title: "Total Users",
            icon: HiOutlineUsers,
            to: '/admin/applicants'
        },
        {
            total: statData?.data?.data?.training || "0",
            title: "Total Trainings",
            icon: IoSchoolOutline,
            //to: '/employer/my-programs'
        },
        {
            total: statData?.data?.data?.jobs || "0",
            title: "Total Jobs",
            icon: BsBriefcase,
            to: '/admin/reports'
        },
        {
            total: allSkills?.data?.data?.length || "0",
            title: "Total Assessment",
            icon: AiOutlineDiff,
            to: '/admin/assessments'
        },
        {
            total: statData?.data?.data?.accountStatus?.unVerifiedCount || "0",
            title: "Unverified Users",
            icon: AiOutlineUser,
            to: '#'
        }
    ]

    if (isLoading) {
        return <ShowLoading/>
    }

    return <>
        <div>
            <CRow >
                {
                    stats?.map((stat, index) =>
                        <CCol className="w-100 mt-2" key={index}>
                            <StatCard pinned={stat} index={index} width={12} card_colors={card_colors} textColor={"text-black-50"}/>
                        </CCol>
                    )
                }
            </CRow>
            <div>
                <CContainer className="container-border mt-4 p-4">
                    <DataNotFound/>
                </CContainer>

            </div>
        </div>
    </>
}

export default AdminDashboard
