export const userApplied = (job, userId) => {
    let isApplied = false
    const jobsApplied = job?.applyForJob?.filter((item) => item.jobId === job.id )?.filter((item)=>item.userId === userId)
    if(jobsApplied?.length > 0){
        return !isApplied
    }
    return isApplied

}

export const userSavedJob = (job, userId) => {
    let isApplied = false
    const savedJobs = job?.applyForJob
        ?.filter((item) => item.jobId === job.id )
        ?.filter((item)=>item.userId === userId)
        ?.filter((item)=>item.isSave)

    if(savedJobs?.length > 0){
        return !isApplied
    }
    return isApplied
}

export const formatMatchingAndProfileQuestions =(data, jobInfo)  =>{
    const matchingQuestions = Object.entries(data)
    const optionIds = matchingQuestions.map((value, index) => {
        if (index < 2) {
            return value[1];
        }

    }).filter((item) => item && item);

    const skills = matchingQuestions.map((value, index) => {
        if (index > 1) {

            return {
                title: value[0],
                experience: value[1]
            }
        }
    }).filter((item) => item && item);

   return  {
        ...jobInfo,
        optionIds,
        skills: [...skills]
    }

}