import React from 'react';
import {CLoadingButton} from "@coreui/react-pro";
import {MdEmail} from "react-icons/md"
import {TiTick} from "react-icons/ti"
import {GrFormClose} from "react-icons/gr"
import {ReactComponent as Users} from "../../../assets/icons/users.svg";
import moment from "moment";
import ProfilePic from "../../User/ProfilePic";
import {useGetUserByUUID} from "../../../hooks/user/userApiHooks";


function TrainingDetail({detail}) {
    const {isLoading, isSuccess, data} = useGetUserByUUID(detail?.userUuid)
    //console.log(detail)
    return (
        <div className="border py-2 px-3 border-radius-12 bg-white d-flex flex-row justify-content-between w-100">

            <div className='d-flex w-100 align-self-center'>
                <div className=" d-flex flex
                        align-self-center
                         circle card-bg-grey
                          justify-content-center
                           align-content-center flex-wrap">
                    <ProfilePic passportUrl={detail?.profile}/>
                </div>

                <div className="w-100 px-2 align-self-start">

                    <div className="fw-medium"><small>{detail?.firstName} {detail?.lastName}</small></div>
                    <div className="job-body"><small>{detail?.profile?.occupation}</small></div>
                    <div className="job-body mt-3"><i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                             className="bi bi-mortarboard fw-bold" viewBox="0 0 16 16">
                            <path
                                d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z"/>
                            <path
                                d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.05l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z"/>
                        </svg> <small className="ps-1">{detail?.profile?.highestLevelOfEducation || 'Nil'}</small></i></div>

                </div>
            </div>

            <div className="w-100 h-100 px-2 d-flex flex-column justify-content-between align-self-center">

                <div className="job-body mb-2 fw-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-dash-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                    </svg>  <small className="ps-1">{detail?.profile?.gender}</small></div>
                <div className="job-body mb-2 fw-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                    </svg> <small className="ps-1">{detail?.profile?.address}</small></div>
                <div className="job-body">Applied on: <small className="fw-bold">
                    {(detail?.createdAt && moment().format("L")) || 'Nil'}
                </small></div>

            </div>

            <div className='flex-column flex-shrink-2  align-self-center'>

                <div className=" d-flex flex-row justify-content-end">
                    <div className="squared-icon text-primary"><MdEmail/></div>
                    <div className="squared-icon text-primary"><TiTick/></div>
                    <div className="squared-icon text-primary"><GrFormClose/></div>
                </div>


                {false && <CLoadingButton type="button" className="text-white mt-3 w-100"><small>View
                    CV</small></CLoadingButton>}

            </div>
        </div>
    );
}

export default TrainingDetail;
