import React, {useEffect, useState} from 'react'
import {CButton, CCol, CContainer, CImage, CListGroup, CListGroupItem, CRow} from "@coreui/react-pro";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png"
import ShowLoading from "../../components/ShowLoading";
import {useGetEmployerRoles} from "../../hooks/admin/adminApiHooks";
import {Link} from "react-router-dom";

const EmployerRolesAndPermissions = () => {

    const [roles, setRoles] = useState([])

    const {
        isLoading: isRolesLoading,
        data: loadedRoles
    } = useGetEmployerRoles()

    useEffect(() => {
        setRoles(loadedRoles?.data?.data)
    }, [loadedRoles?.data?.data])

    if (isRolesLoading) {
        return <ShowLoading/>
    }

    const displayItems = (items) => {
        return (
            <CListGroup className={'border-0 w-50'}>
                {
                    items?.map((item, index) => (
                        <CListGroupItem key={index} className={'border-0 m-0 p-0'}>
                            {index === items.length - 1 ? item : `${item}, `}
                        </CListGroupItem>
                    ))
                }
            </CListGroup>
        )

    }
    const convertAction = (action) => {
        return <Link to={`/admin/admin/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }

    return (
        <CContainer>
            <CCol md={4}>
                <Link to={'/employer/create-role-permissions'}>
                    <CButton className="text-white px-5 users-action-buttons">+{'   '}ADD NEW ROLE</CButton>
                </Link>
            </CCol>
            <CRow className="mt-3 mb-5">
                <CCol md={12}>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>Role</th>
                            <th>Permissions</th>
                            {/*<th>Registered</th>*/}
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            roles?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{displayItems(item.permissions)}</td>
                                    <td>{convertAction(item.action)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default EmployerRolesAndPermissions