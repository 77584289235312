import React from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import JobPostingForm from "../../components/employer/Jobs/JobPostingForm";

function AddJobPosting() {

    return (
        <CContainer className="mb-xxl-5">
            <CRow>
                <CCol>
                    <h3 className="sub-title"> Post a Job</h3>
                </CCol>
            </CRow>
            <hr/>
            <CRow className="d-flex flex-row justify-content-center">
                <CCol sm={8} >
                    <JobPostingForm width={12} />
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default AddJobPosting;