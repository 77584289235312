export const cvDictionary = {
    titles: {
        email:['email'],
        phoneNumber:['telephone', 'phone'],
        address:['address'],
        nationality:['nationality'],
        objective: ['objective', 'objectives'],
        summary: ['summary', "PROFESSIONAL SUMMARY"],
        technology: ['technology', 'technologies'],
        experience: ['experience', 'professional experience', "WORK HISTORY", "PROFESSIONAL EXPERIENCE"],
        education: ['education'],
        skills: ['Skills', 'skills', "CORE SKILLS"],
        languages: ['languages'],
        courses: ['courses'],
        trainings: ['trainings'],
        projects: ['projects'],
        publications:['publications'],
        links: ['links'],
        contacts: ['contacts'],
        positions: ['positions', 'position'],
        profiles: [
            'profiles',
            'social connect',
            'social-profiles',
            'social profiles',
        ],
        awards: ['awards', "AWARDS & CERTIFICATIONS"],
        honors: ['honors'],
        additional: ['additional'],
        certification: ['certification', 'certifications'],
        interests: ['research interests'],
    },
    inline: {
        address: 'address',
        skype: 'skype',
    },
    regular: {
        name: [/([A-Z][a-z]*)(\s[A-Z][a-z]*)/],
        email: [/([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})/],
        phone: [/((?:\+?\d{1,3}[\s-])?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]\d{4,5})/],
    },
}