import React, {useCallback, useEffect, useState} from 'react'
import {
    CButton,
    CCol,
    CContainer,
    CRow,
    CImage, CFormLabel, CFormInput, CFormText, CFormCheck, CFormSelect
} from "@coreui/react-pro";
import {BiEnvelope} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png"
import ShowLoading from "../../components/ShowLoading";
import {useGetAdminPermissionsQuery, useGetAdminRolesQuery, useGetAllAdmins} from "../../hooks/admin/adminApiHooks";
import {Link} from "react-router-dom";
import appApiCalls from "../../services/api";
import {isMobile} from "react-device-detect";
import {debounce} from "lodash";

const Admins = () => {

    const [adminsData, setAdminsData] = useState([])
    const [search, setSearch] = useState("")
    const [roles, setRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [permissions, setPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const debounceSearchHandler = useCallback(debounce(e => setSearch(e.target.value), 600), [])
    const [limit, setLimit] = useState(10)
    let admins = useGetAllAdmins(limit)

    const {
        isLoading: isRolesLoading,
        data: loadedRoles
    } = useGetAdminRolesQuery()

    const {
        isLoading: isPermissionsLoading,
        data: loadedPermissions
    } = useGetAdminPermissionsQuery()

    useEffect(() => {
        setRoles(loadedRoles?.data?.data)
        setPermissions(loadedPermissions?.data?.data)
    }, [loadedRoles?.data?.data, loadedPermissions?.data?.data])

    useEffect(() => {
        setAdminsData(admins?.data?.data?.data?.map((item, index) => {
            return {
                id: index,
                user: `${item.firstName} ${item.lastName}`,
                phoneNumber: item.phoneNumber,
                email: item.email,
                role: item.adminRole,
                permissions: item.permissions,
                registered: item.createdAt.substring(0, 10),
                action: item.adminUuid
            }
        }))

    }, [admins?.data?.data?.data])

    useEffect(() => {
        const updateAdmins = async () => {
            admins = await appApiCalls.getAllAdmins(search, selectedRoles, selectedPermissions, limit)
            if (!(admins?.data?.data)) return
            setAdminsData(admins?.data?.data?.map((item, index) => {
                return {
                    id: index,
                    user: `${item.firstName} ${item.lastName}`,
                    phoneNumber: item.phoneNumber,
                    email: item.email,
                    role: item.adminRole,
                    permissions: item.permissions,
                    registered: item.createdAt.substring(0, 10),
                    action: item.adminUuid
                }
            }))
        }
        updateAdmins()
    }, [search, selectedRoles, selectedPermissions, limit])

    if (admins.isLoading || isPermissionsLoading || isRolesLoading) {
        return <ShowLoading/>
    }

    const convertToDate = (date) => {
        const _date = new Date(Date.parse(date))
        return _date.toLocaleDateString()
    }

    const convertUserDisplay = (user) => {
        return (
            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                <CImage src={DummyProfilePic} alt="Profile" style={{
                    width: '32px',
                    height: '32px',
                    marginRight: '10px',
                    borderRadius: '50%',
                }}/>
                <p style={{marginBottom: "0"}}><b>{user}</b></p>
            </div>
        )
    }

    const displayItems = (items) => {
        return items?.map((item, index) => (
            index === items.length - 1 ? item : `${item}, `
        ))
    }

    const convertAction = (action) => {
        return <Link to={`/admin/admin/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }

    const handleResetFilter = () => {
        setSearch("")
        setSelectedRoles([])
        setSelectedPermissions([])

        // uncheck all checkboxes
        const checkboxes = [...document.querySelectorAll('input[type="checkbox"]')]
        checkboxes?.map((box) => {
            return box.checked = false
        })
        document.querySelector("#searchBy").value = ""
    }

    return (
        <CContainer>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                    <h5>{`${adminsData ? adminsData?.length : 0} Admins`}</h5>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <Link to={'/admin/create-admin'}>
                        <CButton className="text-white px-5 users-action-buttons">+{'   '}ADD ADMIN</CButton>
                    </Link>
                </div>
            </div>
            {!isMobile && <div>
                <div className="d-flex flex-row justify-content-end align-items-center px-2 mt-2">
                    <p
                        className="text-primary cursor-pointer"
                        onClick={handleResetFilter}
                    >Clear all</p>
                </div>
                <CRow>
                    <CCol md={4}>
                        <CFormLabel htmlFor="searchBy" className="m-1 label">Search</CFormLabel>
                        <CFormInput type="text" id="searchBy"
                                    aria-describedby="searchByText"
                                    onChange={debounceSearchHandler}
                                    className="rounded-0"
                        />
                        <CFormText component="span" id="searchByText">
                            By email, phone number, first/last name.
                        </CFormText>
                    </CCol>
                    <CCol md={4}>
                        <CFormLabel htmlFor="role">Role</CFormLabel>
                        {roles?.map((item, index) => {
                            return (
                                <CFormCheck
                                    key={index}
                                    id={`role-${index}`}
                                    value={item?.title}
                                    label={item?.title}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedRoles([...roles, e.target.value])
                                        } else {
                                            const index = selectedRoles.indexOf(e.target.value);
                                            if (index > -1) {
                                                selectedRoles.splice(index, 1);
                                            }
                                        }
                                    }}
                                />
                            )
                        })
                        }
                    </CCol>
                    <CCol md={4}>
                        <CFormLabel htmlFor="permission">Permission</CFormLabel>
                        {permissions?.map((item, index) => {
                            return (
                                <CCol md={6} key={index}>
                                    <CFormCheck
                                        id={`permission-${index}`}
                                        value={item?.title}
                                        label={item?.title}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedPermissions([...selectedPermissions, e.target.value])
                                            } else {
                                                const index = selectedPermissions.indexOf(e.target.value);
                                                if (index > -1) {
                                                    selectedPermissions.splice(index, 1);
                                                }
                                            }
                                        }}
                                    />
                                </CCol>
                            )
                        })}
                    </CCol>
                </CRow>
            </div>}
            <CRow className="mt-3 mb-5">
                <CCol md={12}>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>User</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Permissions</th>
                            <th>Registered</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            adminsData?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{convertUserDisplay(item.user)}</td>
                                    <td><BsTelephone/> {item.phoneNumber}</td>
                                    <td><BiEnvelope/> {item.email}</td>
                                    <td>{displayItems(item.role)}</td>
                                    <td>{displayItems(item.permissions)}</td>
                                    <td>{convertToDate(item.registered)}</td>
                                    <td>{convertAction(item.action)}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    {adminsData?.length > 10 && <div className={"d-flex flex-row gap-3 w-23"}>
                        <div className={"m-2"}>Show</div>
                        <CFormSelect
                            aria-label="Default select example"
                            options={[
                                {label: '10', value: '10'},
                                {label: '25', value: '25'},
                                {label: '100', value: '100'},
                                {label: '200', value: '200'}
                            ]}
                            onChange={(e) => setLimit(e.target.value)}
                        />
                        <div className={"m-2"}>Entries</div>
                    </div>}
                </CCol>
            </CRow>
        </CContainer>
    )
}

export default Admins