import {CButton, CFormSelect} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import React from "react";

const TableNavigation = ({setLimit, page, setPage, totalPages}) => {
  return (
      <div className="d-flex justify-content-between">
          <div className="d-flex flex-row gap-3">
              <div className="m-2">Show</div>
              <CFormSelect
                  aria-label="Default select example"
                  options={[
                      {label: '10', value: '10'},
                      {label: '25', value: '25'},
                      {label: '100', value: '100'},
                      {label: '200', value: '200'}
                  ]}
                  onChange={(e) => setLimit(e.target.value)}
              />
              <div className={"m-2"}>Entries</div>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center">
              <CButton
                  disabled={page <= 1} onClick={() => setPage(page - 1)}
                  className={`mx-3 text-white py-1 pb-2 px-3`}>
                  <BiLeftArrow/>
              </CButton>
              <CButton
                  onClick={() => setPage(page + 1)}
                  className="mx-3 text-white py-1 pb-2 px-3"
                  disabled={page >= totalPages}
              >
                  <BiRightArrow/>
              </CButton>
          </div>
      </div>
  )
}

export default TableNavigation