import React from 'react';
import {CCol, CFormFeedback, CFormInput, CFormLabel, CFormSelect, CRow} from "@coreui/react-pro";
import {employmentOptions, idTypeOptions} from "../../constant";

function AddressMeansOfId({register, errors}) {
    return (
        <>
            <CCol md={12} className="mb-5">
                <CFormLabel className="text-black mb-0">Education/Employment Data</CFormLabel>
                <hr className="mt-0"/>
                <CRow className="gy-4">
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="educationLevel"
                            placeholder="Educational Qualification"
                            {...register('educationLevel')}
                            invalid={!!errors.educationLevel}
                        />
                        <CFormFeedback invalid={!!errors.educationLevel}>
                            {errors.educationLevel?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormSelect
                            aria-label="Default select example"
                            id="employmentStatus"
                            {...register('employmentStatus')}
                            invalid={!!errors.employmentStatus}
                        >
                            <option>
                                Employment Status
                            </option>
                            {employmentOptions.map((e, index) => (
                                <option key={index} value={e}>
                                    {e}
                                </option>
                            ))}
                        </CFormSelect>
                        <CFormFeedback invalid={!!errors.employmentStatus}>
                            {errors.employmentStatus?.message}
                        </CFormFeedback>
                    </CCol>
                    <CCol md={4}>
                        <CFormInput
                            type="text"
                            id="occupation"
                            placeholder="Occupation"
                            {...register('occupation')}
                            invalid={!!errors.occupation}
                        />
                        <CFormFeedback invalid={!!errors.occupation}>
                            {errors.occupation?.message}
                        </CFormFeedback>
                    </CCol>
                </CRow>
            </CCol>
            <CCol md={12} className="mb-5">
            <CFormLabel className="text-black mb-0">Means of Identification</CFormLabel>
            <hr className="mt-0"/>
            <CRow className="gy-4">
                <CCol md={6}>
                    <CFormSelect
                        aria-label="Default select example"
                        id="meansOfIdentification"
                        {...register('meansOfIdentification')}
                        invalid={!!errors.meansOfIdentification}
                        required

                    >
                        <option>
                            ID Type
                        </option>
                        {idTypeOptions?.map((s, index) => (
                            <option key={index} value={s}>
                                {s}
                            </option>
                        ))}
                    </CFormSelect>
                    <CFormFeedback invalid={!!errors.meansOfIdentification}>
                        {errors.meansOfIdentification?.message}
                    </CFormFeedback>
                </CCol>{' '}
                <CCol md={6}>
                    <CFormInput
                        type="text"
                        id="nin"
                        {...register('nin')}
                        invalid={!!errors.nin}
                        placeholder="Identity Number"
                    />
                    <CFormFeedback invalid={!!errors.nin}>{errors.nin?.message}</CFormFeedback>
                </CCol>
            </CRow>
        </CCol>

        </>
    );
}

export default AddressMeansOfId;