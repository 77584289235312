import React from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import SponsorCourse from "../../components/employer/sponsor/SponsorCourse";

function AddSponsor() {

    return (
        <CContainer className="mb-xxl-5">
            <CRow>
                <CCol>
                    <h3 className="sub-title">SPONSOR TRAINING</h3>
                </CCol>
            </CRow>
            <hr/>
            <CRow className="d-flex flex-row justify-content-center">
                <CCol sm={8} >
                    <SponsorCourse width={12} />
                </CCol>
            </CRow>
        </CContainer>
    );
}

export default AddSponsor;
