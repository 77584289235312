import React from 'react';
import {CButton, CContainer} from "@coreui/react-pro";
import TestTakenIcon from "../../../assets/icons/total-tests-icon.png";
import TestPassedIcon from "../../../assets/icons/total-passed-icon.png";
import TestFailedIcon from "../../../assets/icons/total-failed-icon.png";

const courses = [
    {
        status: 'Start Class',
        courseTitle: "Machine Learning",
        icon: TestTakenIcon
    },
    {
        status: 'Start Class',
        courseTitle: "Deep Learning",
        icon: TestPassedIcon
    },
    {
        status: 'Completed',
        courseTitle: "Lang Chain",
        icon: TestFailedIcon
    },
]

function TrainersCourses() {
    return (
        <>
            <div className="ms-4">
                <h5 className="text-[12px] fw-bolder ps-1">Welcome Jeremy</h5>
            </div>
            <CContainer className="mb-5 d-flex flex-row gap-5 mt-5">
                {
                    courses.map((course, index) => {
                        return (
                            <div className="w-100 bg-white py-3" key={index + 1}>
                                <div className={`shadow-none px-5 `}>
                                    <div>
                                        <div className="d-flex flex-row  align-items-center gap-2">
                                            <img src={course?.icon} width={50} height={50} alt={'Course Icon'}/>
                                            <p>{course?.courseTitle}</p>
                                        </div>
                                        <div className="d-flex flex-row justify-content-end mt-5">
                                            <CButton className={' text-white'}>{course.status}</CButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </CContainer>

        </>
    );
}

export default TrainersCourses;