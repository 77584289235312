import React, { useRef } from 'react'
import { CFormCheck } from '@coreui/react-pro'
import PropTypes from 'prop-types'

const RadioButton = ({ type, name, id, label, value, onChange, checked }) => {
  const checkRef = useRef(null)

  const onClick = () => {
    if (checkRef) {
      checkRef.current.click()
    }
  }
  return (
    <div className="p-2 ps-3 mb-2 hov" onClick={onClick}>
      <CFormCheck
        type={type}
        name={name}
        id={id}
        value={value}
        label={label}
        ref={checkRef}
        onChange={onChange}
        checked={checked}
      />
    </div>
  )
}

RadioButton.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

export default RadioButton
