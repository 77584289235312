import React, {useEffect} from 'react'
import {CCol, CForm, CFormFeedback, CFormInput, CLoadingButton,} from '@coreui/react-pro'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {ResetPasswordSchema} from '../../form-schema/user-form-schema/resetPasswordSchema'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useResetPasswordQuery} from "../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../constant";
import {useAppContext} from "../../context/user/UserAppProvider";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const {storeUser} = useAppContext()
  const {isLoading, isSuccess, data, isError, error, mutate} = useResetPasswordQuery()

  const token = searchParams.get('token')
  const id = searchParams.get('id')
  const role = searchParams.get('role')

  useEffect(() => {
    if (isSuccess) {
      storeUser(data?.data)
      success_notify('Password Reset Done')
      navigate(`/login`, {
        replace: true,
      })
    }
    if (isError) {
      fail_notify(error?.response?.data?.message)
    }
  }, [data, isSuccess, isError, error, navigate, storeUser])

  const onSubmit = (data) => mutate({data, token, id, role})

  return (
    <>
      <h1 className="text-1">New Password</h1>
      <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column gap-3">
          <CCol md={12}>
            <CFormInput
              type="password"
              id="inputEmail4"
              placeholder="Enter New Password"
              {...register('newPassword')}
              invalid={!!errors.newPassword}
            />
            <CFormFeedback invalid={!!errors.newPassword}>{errors.newPassword?.message}</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormInput
              type="password"
              id="inputPassword4"
              {...register('confirmPassword')}
              placeholder="Confirm New Password"
              invalid={!!errors.confirmPassword}
            />
            <CFormFeedback invalid={!!errors.confirmPassword}>
              {errors.confirmPassword?.message}
            </CFormFeedback>
          </CCol>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Link to="/login" className="text-decoration-none m-0 font-color">
            Go to Login
          </Link>
        </div>
        <CLoadingButton
          type="submit"
          className="reg-btn px-4 w-100 py-2 mt-5"
          loading={isLoading}
          disabled={isLoading}
        >
          Reset My Password
        </CLoadingButton>
      </CForm>
    </>
  )
}

export default ResetPassword
