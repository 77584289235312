import React, {useEffect, useState} from 'react'
import {CCardImage, CLoadingButton} from "@coreui/react-pro";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";
import {userApplied} from "./JobApplicatonUtility";
import {useAppContext} from "../../context/user/UserAppProvider";
import bg_image from "../../assets/images/job-detail-bg.png";
import MatchingAndProfilingModal from "../modals/MatchingAndProfilingModal";
import {fail_notify, formatCurrency} from "../../constant";
import DOMPurify from "dompurify";
import logo from "../../assets/brand/WN-logo.svg";
import {useGetQuestionnaireAnswerQuery, useGetUserByUUID} from "../../hooks/user/userApiHooks";

function JobDetailSection({jobDetail, JobIsLoading, JobIsSuccess, save, setSave, user, mutate, slug, applied}) {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [jobInfo, setJobInfo] = useState({})
    const [isApplied, setIsApplied] = useState(applied)
    const job = jobDetail?.data?.data?.data
    const {userId, stakeholderRoles} = useAppContext()
    const [next, setNext] = useState(1)
    const [cvId, setCVId] = useState(null)
    const [skillsAssessment, setSkillsAssessment] = useState([])
    const {isSuccess, data} = useGetUserByUUID(user?.data?.userUuid)
    const userType = (stakeholderRoles?.includes('Employer') || stakeholderRoles?.includes('Government') || stakeholderRoles?.includes('Academy'))
    const personalityTest = useGetQuestionnaireAnswerQuery(userType ? null : userId, 'Personality Test')
    const softSkillsTest = useGetQuestionnaireAnswerQuery(userType ? null : userId, 'Soft Skills Test')

    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    useEffect(() => {
        setSkillsAssessment([

            data?.data?.data?.cultureFitCategory?.maxCategory !== 'Not Yet',
            personalityTest?.data?.data?.data?.amiable !== 0 ||
            personalityTest?.data?.data?.data?.analytical !== 0 ||
            personalityTest?.data?.data?.data?.driver !== 0 ||
            personalityTest?.data?.data?.data?.expressive !== 0,
            softSkillsTest?.data?.data?.data?.totalQuestionAttempted !== 0,

        ])
    }, [isSuccess, personalityTest.isSuccess, softSkillsTest.isSuccess])

    useEffect(() => {
        setIsApplied(userApplied(job, userId))
        if (applied) {
            setVisible(false)
            setCVId(null)
            setNext(1)
        }

    }, [applied, jobDetail, userId, job])

    const onSubmit = (info) => {
        if (!user?.accessToken) {
            localStorage.setItem("redirectUrl", "/job-detail/" + slug);
            navigate(`/login`, {
                replace: true,
            })
            return
        }
        info['cvId'] = cvId
        mutate(info);
    }
    useEffect(() => {
        if (!visible) {
            setNext(1)
        }
        if (JobIsSuccess) {
            setCVId(null)
        }
    }, [JobIsSuccess, visible])
    const setDefaultSrc = (e) => {
        e.target.src = logo
    }

    return (<>
            <div className="g-3  bg-light">

                <div>
                    <div className=" pb-0">
                        <div className="w-100 h-25">
                            <img alt={'Job Banner'} src={bg_image} className="w-100 job-detail-img rounded-top"/>
                        </div>
                        <div className="px-2 position-relative ">
                            <div className="bg-green-700 job-logo-div rounded align-self-start position-absolute">
                                <CCardImage onError={(e) => setDefaultSrc(e)} alt="Company Logo" orientation="top"
                                            className="job-logo"
                                            src={job?.stakeholder?.logoUrl || logo}/>
                            </div>


                        </div>
                        <br/><br/><br/>
                        <center className="">

                            <h2 className="text-2 fw-bolder">{job?.title}</h2>
                            <p className="sub-body py-none">{job?.stakeholder?.name}, {job?.location}</p>

                        </center>

                        <div className="px-1 px-md-3">
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                <CLoadingButton className="text-black fw-bold border-0 bg-transparent"><small>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-share-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                                    </svg>
                                </small></CLoadingButton>
                                <div
                                    className=" dateline bg-transparent py-1 px-2 rounded border border-1 border-primary text-primary">
                                    <small>Deadline: {moment(job?.closingDate).format('MMMM Do YYYY')}</small></div>
                            </div>
                            <div className="job-detail-summary d-flex justify-content-between mt-3 p-3">
                                <div className="border-end pe-2">
                                    <small className="fw-bold">Date Posted</small><br/>
                                    <small>{moment(job?.createdAt).format('MMMM Do YYYY')}</small>
                                </div>
                                <div className="border-end pe-2">
                                    <small className="fw-bold">Job Level</small><br/>
                                    <small>{job?.levelOfExperience} yr(s)</small>
                                </div>
                                <div className="border-end pe-2">
                                    <small className="fw-bold">Job Type</small><br/>
                                    <small>{job?.jobType}</small>
                                </div>
                                <div className="border-end pe-2">
                                    <small className="fw-bold">Job Location</small> <br/>
                                    <small>{job?.location}</small>
                                </div>
                                <div className="">
                                    <small className="fw-bold">No of Vacancy</small><br/>
                                    <small>{job?.numberOfVacancy}</small>
                                </div>

                            </div>
                            <br/>
                            <div className="mb-4">
                                <h6 className="fw-bold text-primary">About The Company</h6>
                                <div className="job-detail-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job?.stakeholder?.aboutCompany)}}>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h6 className="fw-bold text-primary">Role Overview</h6>
                                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job?.description)}}>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h6 className="fw-bold text-primary">Job Requirements</h6>
                                <div
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job?.requirement)}}

                                >

                                </div>
                            </div>
                            <div className="mb-4">
                                <h6 className="fw-bold text-primary">Duties and Responsibilities</h6>
                                <div
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job?.responsibilities)}}

                                >

                                </div>

                            </div>

                            <div className="mb-4">
                                <h6 className="fw-bold text-primary">Job Industry</h6>
                                <p className="job-detail-body">{job?.stakeholder?.industryType}</p>
                            </div>
                            {job?.tags.length > 0 && <div className="mb-4">
                                <h6 className="fw-bold text-primary">Job Tag</h6>
                                <div className=" d-flex flex-shrink-2">
                                    {job?.tags.map((tag, index) => {
                                        return (
                                            <div key={index} className=" m-1 job-tag"><small
                                                className="bg-light-green-2 px-2 py-1 rounded-2">{tag}</small></div>
                                        )
                                    })}
                                </div>

                            </div>}
                            {(job.salary && job.salary !== "Negotiable") && <div className="mb-4">
                                <h6 className="fw-bold text-primary">Remuneration</h6>
                                {(job.salary && job.salary !== "Negotiable") &&
                                    <p className="job-detail-body">&#8358;{formatCurrency(job.salary)}</p>}
                            </div>}
                            {(!(user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) && <>
                                {(job?.applyForJob?.length === 0 || (job?.applyForJob?.length > 0 && job?.applyForJob[0]?.isSave) || !user?.accessToken) ?
                                    <CLoadingButton className="text-white fw-bold my-1 mb-5 w-100"
                                                    disabled={JobIsLoading && !save}
                                                    loading={JobIsLoading && !save}
                                                    onClick={() => {
                                                        if (!user?.accessToken) {
                                                            localStorage.setItem("redirectUrl", "/job-detail/" + slug);
                                                            navigate(`/login`, {
                                                                replace: true,
                                                            })
                                                            return
                                                        }
                                                        if (skillsAssessment.includes(false)) {
                                                            fail_notify("You can not apply for a job without completing the mandatory skill assessment on your dashboard")
                                                            return;
                                                        }
                                                        setJobInfo({
                                                            jobId: job?.id, isSave: save
                                                        })
                                                        setSave(false)
                                                        setVisible(true)
                                                    }}><small>APPLY JOB</small></CLoadingButton> :
                                    <center className="text-primary py-5 fw-bolder">
                                        Already Applied</center>}
                            </>}
                        </div>
                        <MatchingAndProfilingModal
                            jobInfo={jobInfo}
                            title={job?.title}
                            setVisible={setVisible}
                            visible={visible}
                            handler={onSubmit}
                            tags={job?.tags}
                            JobIsSuccess={JobIsSuccess}
                            setNext={setNext}
                            next={next}
                            cvId={cvId}
                            setCVId={setCVId}

                        />
                    </div>

                </div>

            </div>

        </>
    )
}

export default JobDetailSection
