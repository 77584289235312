import React, {useEffect, useState} from 'react'
import {
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormTextarea, CLoadingButton,
    CModal,
    CModalBody,
    CModalTitle, CRow
} from "@coreui/react-pro";
import {TiImage} from "react-icons/ti";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {fail_notify, success_notify} from "../../constant";
import {useUpdateTestimonial} from "../../hooks/user/userApiHooks";
import {UpdateTestimonialSchema} from "../../form-schema/admin-form-schema/updateTestimonialSchema";

const EditTestimonialModal = ({visible, setVisible, name, title, testimonial, tid, avatar}) => {
    const {isError,error, isLoading, isSuccess, mutate} = useUpdateTestimonial(tid)
    const [fileText, setFileText] = useState("")
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue
    } = useForm({
        resolver: yupResolver(UpdateTestimonialSchema),
        defaultValues: {
            fullName: name,
            jobTitle: title,
            testimonial: testimonial
        }
    })

    useEffect(() => {
        setValue('fullName', name)
        setValue('jobTitle', title)
        setValue('testimonial', testimonial)
        setValue('avatar', avatar)
    }, [name, testimonial, title, avatar])

    useEffect(() => {
        if (isSuccess) {
            success_notify("Testimonial Uploaded Successfully!")
            setValue('fullName', "")
            setValue('jobTitle', "")
            setValue('testimonial', "")
            setValue('avatar', "")
            setVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, isSuccess])

    const handleFileSelect = (e) => {
        const fileName = e.target.files[0].name
        setFileText(fileName)
    }

    const handleClose = () => {
        setVisible(false)
    }

    const onSubmit = (data) => {
        const form = document.forms.namedItem('testimonial-update-form')
        const formData = new FormData(form)
        mutate(formData)
    }

    return (
        <CModal size={"lg"} alignment="center" visible={visible} onClose={handleClose}>

            <CModalBody style={{backgroundColor: '#F5F5F5'}} className="rounded">
                <CModalTitle className="d-flex flex-row justify-content-between align-items-center mb-2">
                    <span>Edit Testimonial</span>
                    <span className="small-text-gray cursor-pointer" onClick={() => setVisible(false)}>Close</span>
                </CModalTitle>
            </CModalBody>
            <CRow className="d-flex flex-row justify-content-center align-items-center m-3">
                <CCol md={12}>
                    <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)} name="testimonial-update-form">
                        <CRow className="my-3">
                            <CCol md={6}>
                                <CFormLabel>Full Name</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter Full Name"
                                    {...register('fullName')}
                                    invalid={!!errors.fullName}
                                />
                                <CFormFeedback invalid={!!errors.fullName}>{errors.fullName?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormLabel>Job Title</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="jobTitle"
                                    placeholder="Enter Job Title"
                                    {...register('jobTitle')}
                                    invalid={!!errors.jobTitle}
                                />
                                <CFormFeedback invalid={!!errors.jobTitle}>{errors.jobTitle?.message}</CFormFeedback>
                            </CCol>
                        </CRow>
                        <CRow className="my-3">
                            <CCol md={6}>
                                <CFormLabel>Testimonial</CFormLabel>
                                <CFormTextarea
                                    type="password"
                                    id="testimonial"
                                    {...register('testimonial')}
                                    placeholder="Type Testmonial Here..."
                                    rows={4}
                                    invalid={!!errors.testimonial}
                                />
                                <CFormFeedback invalid={!!errors.testimonial}>
                                    {errors.testimonial?.message}
                                </CFormFeedback>
                            </CCol>
                            <CCol md={6}>
                                <CFormLabel>Avatar</CFormLabel>
                                <br/>
                                <label className="custom-file-upload" htmlFor="avatar">
                                    <TiImage size={36} className="text-primary"/> Click to select image<br/>
                                    <br/>{fileText}
                                </label>
                                <CFormInput
                                    type="file"
                                    id="avatar"
                                    {...register('avatar')}
                                    invalid={!!errors.avatar}
                                    className="custom-file-input"
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                    style={{maxWidth: '100vh'}}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol>
                                <CFormFeedback invalid={!!errors.avatar}>
                                    {errors.avatar?.message}
                                </CFormFeedback>
                            </CCol>
                        </CRow>
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-100 py-2 mt-5"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Upload Testimonial
                        </CLoadingButton>
                    </CForm>
                </CCol>
            </CRow>
        </CModal>
    )
}

export default EditTestimonialModal