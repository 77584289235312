import React, {useEffect, useState} from 'react'
import {
  CButton,
  CCol, CForm,
  CFormInput,
  CLoadingButton,
  CRow,

} from '@coreui/react-pro'
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {employerDocUpdateSchema} from "../../../form-schema/stake-holder-schema/employerDocUpdateSchema";
import {useUpdateEmployerDocQuery} from "../../../hooks/user/userApiHooks";
import {fail_notify, success_notify} from "../../../constant";

function DocumentUpload({employerData}) {
  const [regCertFile, setRegCertFile] = useState(null);
  const [taxClearance, setTaxClearance] = useState(null);
  const [regCertPreview, setRegCertPreview] = useState( employerData?.data?.data?.certificateUrl);
  const [taxClearancePreview, setTaxClearancePreview] = useState( employerData?.data?.data?.taxClearanceUrl);
  const [regCertError, setRegCertError] = useState(null);
  const [taxClearanceError, setTaxClearanceError] = useState(null);

  const {isLoading: isUpdateLoading, data, mutate, isSuccess, isError, error} = useUpdateEmployerDocQuery()

  useEffect(() => {
    if (isSuccess) {
      success_notify('Successfully Updated')

    }
    if (isError) {
      fail_notify(error?.response?.data?.message)
    }
  }, [isUpdateLoading, data, isError, error, isSuccess, mutate, regCertError])

  const {
    register,
    handleSubmit,
      watch,
      setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(employerDocUpdateSchema),
    defaultValues: {regCert: employerData?.data?.data?.certificateUrl, taxClearance: employerData?.data?.data?.taxClearanceUrl},

  })

  const handleUpdate = (data) => {
    data.regCert = regCertFile
    data.taxClearance = taxClearance
    mutate(data)
  }
  //console.log(employerData?.data?.data)
  const regCertChange = (e)=>{
    if (e?.target?.files) {
      if(e.target.files[0].size > 3101400){
        setRegCertError("Maximum image size is 3MB")
        return
      }
      setRegCertFile(e.target.files[0]);
      setRegCertPreview(URL?.createObjectURL(e.target.files[0]))
      setRegCertError(null)
      setValue('regCert', e.target.files[0])

    }
  }

  const taxClearanceChange = (e)=>{
    if (e?.target?.files) {
      if(e.target.files[0].size > 3101400){
        setTaxClearanceError("")
        return
      }
      setTaxClearance( e.target.files[0]);
      setTaxClearancePreview(URL?.createObjectURL(e.target.files[0]))
      setTaxClearanceError(null)
      setValue('taxClearance', e.target.files[0])

    }
  }
  //console.log(taxClearancePreview)
  return (
    <>
      <CForm noValidate className="needs-validation w-100 update-profile-form"
             onSubmit={handleSubmit(handleUpdate)}>
      <div className="">
        <h5 className="text-black fw-bold">Documents Upload / Update</h5>
        <div className="w-100 bg-light-blue p-2 text-primary-alert mb-3">
          Maximum of 3mb size per document
        </div>
        <CRow xs={{cols: 1}} sm={{cols: 1}} md={{cols: 1}} lg={{cols: 1}} className="gy-4">

          <CCol className="">
            <div className="fw-bold">REGISTRATION CERTIFICATE</div>
            <div className="w-100 shadow-sm p-3 bg-white rounded border border-b-grey d-flex flex-column flex-lg-row justify-content-between">
              <div className="bg-light-blue employer-doc"><img src={regCertPreview} id="regCert" alt="regCert"  className=" w-100 h-100"/></div>
              <div className="-flex flex-column flex-lg-row justify-content-lg-end" style={{height:"40px"}}>
                <CButton className="text-white m-1" style={{width:"145px"}}>
                  <label htmlFor="regCertggg">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-cloud-upload" viewBox="0 0 16 16">
                  <path
                        d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                  <path
                        d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                </svg> Select File
                  </label>
                  </CButton>

                <CButton disabled={!regCertPreview}
                         onClick={()=> {
                           setRegCertPreview(null)
                           setValue('regCert', null)
                         }} className="text-white bg-danger m-1" style={{width:"145px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-trash3" viewBox="0 0 16 16">
                    <path
                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                  </svg> Delete File</CButton>

                <CFormInput
                    type="file" hidden={true} name="profile" id="regCertggg" accept=".jpg, .jpeg, .png, .pdf" onChange={regCertChange}/>

              </div>
            </div>
            <div><small className="text-danger text-truncate">{errors.regCert?.message}</small></div>

          </CCol>
          <CCol className="">
            <div className="fw-bold">TAX CLEARANCE CERTIFICATE</div>
            <div className="w-100 shadow-sm p-3 bg-white rounded border border-b-grey d-flex flex-column flex-lg-row justify-content-between">
              <div className="bg-light-blue employer-doc"><img src={taxClearancePreview} id="regCert" alt="taxClearance"  className=" w-100 h-100"/></div>
              <div className="-flex flex-column flex-lg-row justify-content-lg-end" style={{height:"40px"}}>
                <CButton className="text-white m-1" style={{width:"145px"}}>
                  <label htmlFor="taxClearanceggg">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-cloud-upload" viewBox="0 0 16 16">
                  <path
                        d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                  <path
                        d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/>
                </svg> Select File
                  </label>
                  </CButton>

                <CButton disabled={!taxClearancePreview} onClick={()=> {
                  setTaxClearancePreview(null)
                  setValue('taxClearance', null)
                }} className="text-white bg-danger m-1" style={{width:"145px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       className="bi bi-trash3" viewBox="0 0 16 16">
                    <path
                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
                  </svg> Delete File</CButton>

                <CFormInput

                    type="file" hidden={true} name="profile" id="taxClearanceggg" accept=".jpg, .jpeg, .png, .pdf" onChange={taxClearanceChange}/>

              </div>
            </div>
            <div><small className="text-danger text-truncate">{errors.taxClearance?.message}</small></div>

          </CCol>

        </CRow>
      </div>
        <CRow>
          <CCol md={12} className="text-center my-1">
            <CLoadingButton
                type="submit"
                className="text-white px-4 w-50 py-2 my-4"
                loading={isUpdateLoading}
                disabled={isUpdateLoading}
            >
              Update Document
            </CLoadingButton>
          </CCol>
        </CRow>
      </CForm>
    </>
  )
}

export default DocumentUpload
