import React, {useEffect, useState} from 'react';
import {
    CButton,
    CCol,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CRow,
    CTooltip
} from "@coreui/react-pro";
import ApplicantsStatCard from "./ApplicantsStatCard";
import {useParams} from "react-router-dom";
import {useGetJobApplicants, useUpdateJobApplicationStatus} from "../../../hooks/employer/employerApiHooks";
import ShowLoading from "../../ShowLoading";
import {fail_notify} from "../../../constant/employerData";
import assessments from "../../../constant/assessments";
import DataNotFound from "../../DataNotFound";
import InterviewSchedulingModal from "../../modals/InterviewSchedulingModal";
import JobApplicantView from "../../modals/JobAplicantView";
import {BsThreeDots} from "react-icons/bs";
import {ARCHIVE, HIRE, HOLD, INTERVIEW, OPEN_FOR_INTERVIEW, PHONE_SCREENING, REJECT} from "../../../constant/constants";
import ConfirmModal from "../../modals/ConfirmModal";

const jobStatuses = [OPEN_FOR_INTERVIEW, PHONE_SCREENING, INTERVIEW, ARCHIVE, HOLD, REJECT]

const actionsStatuses = [
    {
        jobStatus: OPEN_FOR_INTERVIEW,
        statuses: [PHONE_SCREENING, REJECT]
    },
    {
        jobStatus: PHONE_SCREENING,
        statuses: [INTERVIEW, REJECT]
    },
    {
        jobStatus: INTERVIEW,
        statuses: [HIRE, HOLD, ARCHIVE, REJECT]
    },
    {
        jobStatus: HOLD,
        statuses: [INTERVIEW, REJECT]
    },
    {
        jobStatus: ARCHIVE,
        statuses: [INTERVIEW, REJECT]
    },

]

const currentActions = (jobStatus, actionsStatuses) => {
    return actionsStatuses.find((detail) => detail.jobStatus === jobStatus)?.statuses

}
const JobApplicants = () => {
    const {jobId} = useParams();
    const [userId, setUserId] = useState()
    const [fitLevel, setFitLevel] = useState('');
    const [assessment, setAssessment] = useState(assessments[0]);
    const [jobStatus, setJobStatus] = useState(jobStatuses[0]);
    const [applicantsData, setApplicantsData] = useState([]);
    const [activeFitLevel, setActiveFitLevel] = useState(1);
    const [assessmentQuery, setAssessmentQuery] = useState("")
    const [page, setPage] = useState(1);
    const [interviewModalVisible, setInterviewModalVisible] = useState(false);
    const [userModalVisible, setUserModalVisible] = useState(false)
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [userData, setUserData] = useState('')
    const [updateData, setUpdateDate] = useState({})
    const actions = currentActions(jobStatus, actionsStatuses) || []
    const {
        isLoading,
        isError,
        data,
        error,
        isSuccess,
        refetch
    } = useGetJobApplicants("JobApplicants", jobId, page, 10, fitLevel, assessmentQuery, jobStatus)

    const {
        mutate
    } = useUpdateJobApplicationStatus("JobApplicants", jobId, page, 10, fitLevel, assessmentQuery, jobStatus)

    useEffect(() => {
        if (isError) {
            fail_notify(error?.error?.response?.data?.message);
        }
        if (isSuccess) {
            setApplicantsData(data?.data?.data);
        }

    }, [data?.data?.data, isSuccess, error, isError]);

    useEffect(() => {
        refetch().then(r => r);
    }, [page, fitLevel, assessmentQuery, refetch, jobStatus]);

    if (isLoading) {
        return <ShowLoading/>
    }

    const handleFitLevel = (level, position) => {
        if (level === '') {
            setFitLevel('')
            return;
        }
        setActiveFitLevel(position);
        setFitLevel('&fitLevel=' + level);
    }

    const handleAssessmentQuery = (query) => {
        if (!query) {
            setAssessmentQuery('');
            return;
        }
        setAssessmentQuery(`&${assessment?.category}=${query}`);
    }
    const updateApplicantJobStatus = (userId, jobStatus, applicationId) => {
        if (jobStatus === INTERVIEW) {
            setUserId(userId)
            setInterviewModalVisible(true);
        } else {
            setIsConfirmation(!isConfirmation)
            setUpdateDate({jobId, userId, jobStatus, applicationId})
        }

    }
    const convertAction = (action) => {
        return <CButton
            className="py-2 px-2 rounded text-white bg-primary"
            onClick={() => window.location.replace(action?.cvUrl)}>
            Download CV
        </CButton>
    }

    return (
        <div>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h3 className="text-3"><strong>{applicantsData?.items?.[0]?.title}</strong></h3>
                <div className="d-flex flex-row">
                    <select className="form-control me-3 shadow-sm"
                            onChange={(e) => setAssessment(assessments[+e.target.value])}>
                        {assessments?.map(({name}, index) => (
                            <option key={index} value={index}>{name}</option>
                        ))}
                    </select>
                    <select className="form-control me-3 shadow-sm"
                            defaultValue={jobStatus}
                            onChange={(e) => setJobStatus(e.target.value)}
                    >
                        {
                            jobStatuses.map((jobStatus, index) => (
                                <option key={index} value={jobStatus}>{jobStatus}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <hr/>
            <div
                className="d-flex flex-row justify-content-between align-items-center px-3 pt-3 pb-0 mb-4 interview-tabs">
                <p className={`interview-tab ${activeFitLevel === 1 && 'active'}`}
                   onClick={() => handleFitLevel('', 1)}>
                    All Applicants ({applicantsData?.items?.[0]?.applyForJob?.length || 0})
                </p>
                <p className={`interview-tab ${activeFitLevel === 2 && 'active'}`}
                   onClick={() => handleFitLevel('Good fit', 2)}>
                    Good Fit ({applicantsData?.categories?.goodFit || 0})
                </p>
                <p className={`interview-tab ${activeFitLevel === 3 && 'active'}`}
                   onClick={() => handleFitLevel('Maybe fit', 3)}>
                    Maybe ({applicantsData?.categories?.mayBeFit || 0})
                </p>
                <p className={`interview-tab ${activeFitLevel === 4 && 'active'}`}
                   onClick={() => handleFitLevel('Not a good fit', 4)}>
                    Not a Good Fit ({applicantsData?.categories?.notAGoodFit || 0})
                </p>
            </div>
            <CRow>
                {assessment?.value?.map(({title, description, key, color}, index) => {
                    return <CCol md={3} key={index}>
                        <ApplicantsStatCard
                            title={title}
                            description={description || "-"}
                            stat={applicantsData?.[assessment.count]?.[key] || 0}
                            color={color}
                            handleAssessmentQuery={handleAssessmentQuery}
                        />
                    </CCol>
                })}
            </CRow>
            {applicantsData?.items?.[0]?.applyForJob?.length > 0 ?
                <table className="table border-0 mt-3" id="reports-table">
                    <thead className="thead-inverse">
                    <tr className="bg-transparent text-primary">
                        <th><strong>Date</strong></th>
                        <th>Applicants</th>
                        <th>Contact</th>
                        <th>Email Address</th>
                        <th>Status</th>
                        <th>Category</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        applicantsData?.items?.[0]?.applyForJob?.map(({
                                                                          user,
                                                                          createdAt,
                                                                          applicationStatus,
                                                                          cvUrl,
                                                                          id
                                                                      }, index) => {
                            if (user.length === 0) {
                                return null;
                            }
                            return <tr key={index} onClick={() => {
                                setUserData({...user, cvUrl: cvUrl})
                            }}>

                                <td className="td-single-line"
                                    onClick={() => setUserModalVisible(!userModalVisible)}>{createdAt.toString().substring(0, 10)}</td>
                                <td onClick={() => setUserModalVisible(!userModalVisible)}>{user?.firstName} {user?.lastName}</td>
                                <td onClick={() => setUserModalVisible(!userModalVisible)}> {user?.phoneNumber}</td>
                                <td onClick={() => setUserModalVisible(!userModalVisible)}>{user?.email}</td>
                                <td className="td-single-line"
                                    onClick={() => setUserModalVisible(!userModalVisible)}>{applicationStatus}</td>
                                <td className="text-center td-single-line"
                                    onClick={() => setUserModalVisible(!userModalVisible)}>
                                    <CTooltip content={user?.roleFitAnalyzers?.filter(({jobId: jobNum}) => jobNum === +jobId)[0]?.reason}>
                                        <span className="text-warning border border-warning w-100 p-1 mt-2 text-capitalize">
                                            {user?.roleFitAnalyzers?.filter(({jobId: jobNum}) => jobNum === +jobId)[0]?.category}
                                        </span>
                                    </CTooltip>
                                </td>
                                <td style={{width: '20%'}}>
                                    <CDropdown>
                                        <CDropdownToggle color="white" className={'shadow-none'} caret={false}>
                                            <BsThreeDots className={'text-primary'} size={20}/>
                                        </CDropdownToggle>
                                        <CDropdownMenu>
                                            {
                                                actions.map((action) => {
                                                    return (
                                                        <CDropdownItem key={action} className={'p-0'} onClick={
                                                            () => updateApplicantJobStatus(user?.id, action, id)
                                                        }
                                                        >
                                                            {action}
                                                        </CDropdownItem>
                                                    )
                                                })
                                            }
                                        </CDropdownMenu>
                                    </CDropdown>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                : <DataNotFound/>
            }
            {
                applicantsData?.meta?.totalItems > 10 &&
                <div className="d-flex flex-row justify-content-between py-3 px-5">
                    <div>
                        <p className="text-primary" onClick={() => {
                            if (page === 1) {
                                return;
                            }
                            setPage(page - 1);
                        }}>Previous</p>
                    </div>
                    <div>
                        <p>Page {page} of {Number(applicantsData?.meta?.totalItems / 10)}</p>
                    </div>
                    <div>
                        <p className="text-primary" onClick={() => setPage(page + 1)}>Next</p>
                    </div>
                </div>}
            {/*{applicantsData?.meta?.totalItems === 0 && <DataNotFound/>}*/}
            {interviewModalVisible && <InterviewSchedulingModal
                jobId={+jobId}
                userId={userId}
                visible={interviewModalVisible}
                setVisible={setInterviewModalVisible}
            />}
            {
                userModalVisible &&
                <JobApplicantView
                    data={userData}
                    downloadCV={convertAction}
                    setVisible={setUserModalVisible}
                />
            }
            {
                isConfirmation &&
                <ConfirmModal
                    data={updateData}
                    handler={mutate}
                    message={"Are You Sure You want to "}
                    setVisible={setIsConfirmation}
                    visible={isConfirmation}
                    title={jobStatus}
                />
            }
        </div>
    )
}

export default JobApplicants;
