export const tagOptions =
    {
        "question": 'How many years of work experience do you have with',
        "options": [
            {
                "id": 5,
                "option": "Less than 1 year"
            },
            {
                "id": 6,
                "option": "1-3 years"
            },
            {
                "id": 7,
                "option": "3-5 years"
            },
            {
                "id": 8,
                "option": "More than 5 years"
            }
        ]
    }
