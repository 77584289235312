import * as Yup from 'yup'

export const stakeholderRegSchema = Yup.object().shape({
    email: Yup.string()
        .email('Must be a valid email')
        .typeError('Email is required')
        .required('Email is required'),
    phoneNumber: Yup.string()
        .typeError('Phone number is required')
        .required('Phone number is required')
        .matches(/^(\+)?(234|0)[0-9]{10}$/, 'Must be a valid phone number'),

    stakeHolderType: Yup.string().test('len', 'Stakeholder type is required', (val) => val !== '0'),

    name: Yup.string('Stakeholder name Must be a string')
        .required('Stakeholder name is required')
        .typeError('Stakeholder name is required')
        .matches(/^[A-Za-z\s'-]+$/, 'Stakeholder name can only contain letters, spaces, hyphens, and single quotes')
        .test('len', 'Must be longer than or equal to 3 characters', (val) => val?.length >= 3),

    //institutionType: Yup.string('Institution status must be a string'),
    //.oneOf(stakeHolderTypeOptions) // this should be coming from the database

    //organizationStatus: Yup.string('Organization status must be a string'),
    //.oneOf(stakeHolderTypeOptions) // this should be coming from the database

    address: Yup.string().required('Address is required'),

    dateEstablished: Yup.string('Established Date is required').required(
        'Established Date is required',
    ),

    institutionType: Yup.string().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Institution' || stakeHolderType === '3',
        then: Yup.string().test('len', 'Institution Type is required', (val) => val !== '0'),
    }),
    tin: Yup.string().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Private/NGO' || stakeHolderType === '2',
        then: Yup.string().required('TIN is required'),
    }),

    organizationStatus: Yup.mixed().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Private/NGO' || stakeHolderType === '2',
        then: Yup.string().test('len', 'OrganizationStatus Status is required', (val) => val !== '0'),
    }),

    password: Yup.string()
        .required('Password is required')
        .typeError('Password is required')
        .min(3, 'Password must be at 3 char long')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),

    confirmPassword: Yup.string()
        .required('Confirm Password field is mandatory')
        .typeError('Confirm Password field is mandatory')
        .oneOf([Yup.ref('password')], 'Passwords does not match'),

    regCert: Yup.mixed().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Private/NGO' || stakeHolderType === '2',
        then: Yup.mixed().test('required File', 'Please upload a registration certificate', (value) => {
            return value?.length || false
        }),
    }),

    taxClearance: Yup.mixed().when('stakeHolderType', {
        is: (stakeHolderType) => stakeHolderType === 'Private/NGO' || stakeHolderType === '2',
        then: Yup.mixed().test('required File', 'Please upload a Tax Clearance', (value) => {
            return value?.length || false
        }),
    }),
})
