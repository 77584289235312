import React, {useEffect, useState} from 'react'
import {CCol, CContainer, CImage, CRow} from "@coreui/react-pro";
import {GoLocation} from "react-icons/go";
import {BsGenderAmbiguous} from "react-icons/bs";
import DefaultPic from "../../../../assets/images/dummy-profile-pic.png"
import {AiOutlinePhone} from "react-icons/ai";
import {useParams} from "react-router-dom";
import {useGetUserAssessments, useGetUserByUUID} from "../../../../hooks/user/userApiHooks";
import {fail_notify, timeFormat} from "../../../../constant";
import ShowLoading from "../../../../components/ShowLoading";
import GoBack from "../../../../components/GoBack";

const UserAssessments = () => {
    const {userId} = useParams('userId')
    const {userUuid} = useParams('userUuid')

    const [data, setData] = useState([])
    const {data: assessmentsData, isLoading, isError, error} = useGetUserAssessments(userId)
    const {data: userData} = useGetUserByUUID(userUuid)

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [isError, error]);

    useEffect(() => {
        let newData = data
        for (const key in assessmentsData?.data?.data) {
            if (Object.hasOwnProperty.call(assessmentsData?.data?.data, key)) {
                const obj = assessmentsData?.data?.data[key];
                if (key === 'skillTestResult') {
                    obj?.forEach((item) => {
                        newData.push(item)
                    });
                } else {
                    newData.push(obj)
                }

                setData([...new Set(newData)])
            }
        }
    }, [assessmentsData]);

    if (isLoading) {
        return <ShowLoading/>
    }

    return (
        <CContainer className="mb-5">
            <GoBack/>
            <h4 className="my-3">Assessments Reports</h4>
            <CRow>
                <CCol md={4} style={{height: 'fit-content'}}>
                    <div className="p-2 bg-white rounded me-1">
                        <h6 className="bg-grey-100 p-2 gray-paragraph">Applicant Details</h6>
                        <CRow>
                            <CCol md={4} className="pe-0">
                                <CImage
                                    src={userData?.data?.data?.user?.profile?.passportUrl}
                                    style={{width: '100%'}}
                                    onError={(e) => {
                                        e.target.src = DefaultPic
                                    }}
                                />
                            </CCol>
                            <CCol md={8} className="d-flex flex-column justify-content-between ps-1">
                                <div>
                                    <h6 className="mb-0">{userData?.data?.data?.user?.firstName} {userData?.data?.data?.user?.lastName}</h6>
                                    <p className="smaller-text-12 gray-paragraph">{userData?.data?.data?.user?.role}</p>
                                </div>
                                <div>
                                    <p className="smaller-text-12 gray-paragraph">
                                        <AiOutlinePhone size={14}/> {userData?.data?.data?.user?.phoneNumber}
                                    </p>
                                    <p className="smaller-text-12 text-truncate gray-paragraph">
                                        <GoLocation size={16}/> {userData?.data?.data?.user?.email}
                                    </p>
                                    <p className="smaller-text-12 gray-paragraph">
                                        <BsGenderAmbiguous
                                            size={16}/> {userData?.data?.data?.user?.profile?.gender === 'F' ? 'Female' : 'Male'}
                                    </p>
                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol md={8} className="p-2 bg-white rounded">
                    <h6 className="bg-grey-100 p-2 gray-paragraph">All Assessments Results</h6>
                    <table className="table border-0" id="reports-table">
                        <thead className="thead-inverse">
                        <tr className="bg-transparent text-primary">
                            <th>Date/Time Stamp</th>
                            <th>Assessment Title</th>
                            <th>Total Question</th>
                            <th>Score (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.map(({passedPercent,quizType,totalQuestion,updatedAt}, index) => (
                            <tr key={index}>
                                <td>{timeFormat(updatedAt)}</td>
                                <td>{quizType}</td>
                                <td>{totalQuestion}</td>
                                <td>{passedPercent ? `${passedPercent}%` : '-'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </CCol>
            </CRow>
        </CContainer>
)
}

export default UserAssessments
