import React, {useEffect, useState} from "react";
import {CButton, CModal, CModalBody} from "@coreui/react-pro";

import UserProfileTest from "../User/UserProfileTest";
import {useGetQuestionnaireAnswerByIdQuery, useGetUserAssessmentByIdQuery} from "../../hooks/user/userApiHooks";
import ShowLoading from "../ShowLoading";
import SkillsTest from "../User/SkillsTest";
import {Tips} from "../../constant/testTips";
import {AiFillCloseCircle} from "react-icons/ai";
import ViewResumeModal from "./Employer/HRModule/ViewResumeModal";

function JobApplicantView({data, setVisible, downloadCV}) {
    const assessment = useGetUserAssessmentByIdQuery(data?.id)
    const [showPdf, setShowPdf] = useState(false)
    const userSkillSets = useGetQuestionnaireAnswerByIdQuery(data?.id)
    const [PT, setPT] = useState([])
    const [SKT, setSKT] = useState([])
    const [CFT, setCFT] = useState([])
    const [SAT, setSAT] = useState([])

    useEffect(() => {
        const result = userSkillSets?.data?.data?.data?.filter((skill) => {
            return skill?.quizType !== "Level One Questionnaire"
        })

        setSAT(result?.map((skill) => {
            return (
                skill?.quizType + " " + skill?.passedPercent + "%"
            )
        }))
        setPT([
            {
                data: [

                    {
                        content: 'Amiable ' + assessment?.data?.data?.data?.personalityTest?.amiablePercent,
                        tip: Tips?.personalityTest?.amiable
                    },

                    {
                        content: 'Analytical ' + assessment?.data?.data?.data?.personalityTest?.analyticalPercent,
                        tip: Tips?.personalityTest?.analytical
                    },
                    {
                        content: 'Driver ' + assessment?.data?.data?.data?.personalityTest?.driverPercent,
                        tip: Tips?.personalityTest?.driver
                    },
                    {
                        content: 'Expressive ' + assessment?.data?.data?.data?.personalityTest?.expressivePercent,
                        tip: Tips?.personalityTest?.expressive
                    },

                ],
            },
            {takeAssessment: false},
            {title: 'Personality Assessment'},
            {testType: 'Personality Test'},
        ])
        setCFT([
            {
                data: [
                    {
                        content: 'Adhocracy ' + assessment?.data?.data?.data?.cultureFitTest?.adhocracyPercent,
                        tip: Tips?.cultureFitTest?.adhocracy
                    },
                    {
                        content: 'Clan ' + assessment?.data?.data?.data?.cultureFitTest?.clanPercent,
                        tip: Tips?.cultureFitTest?.clan
                    },
                    {
                        content: 'Hierarchy ' + assessment?.data?.data?.data?.cultureFitTest?.hierarchyPercent,
                        tip: Tips?.cultureFitTest?.hierarchy
                    },
                    {
                        content: 'Market ' + assessment?.data?.data?.data?.cultureFitTest?.marketPercent,
                        tip: Tips?.cultureFitTest?.market
                    },
                ],
            },
            {takeAssessment: false},
            {title: 'Culture Fitness Assessment'},
            {testType: 'Culture Fit Test'},
        ])
        setSKT([
            {
                data: [
                    {
                        content: 'Rating: ' + (assessment?.data?.data?.data?.softSkillsTest?.rating === 0 ? '0 Star' : assessment?.data?.data?.data?.softSkillsTest?.rating),
                    },
                    {
                        content: 'Adaptability: ' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Adaptability Test'] + '%',
                    },
                    {
                        content: 'Communication: ' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Communication Test'] + '%',
                    },
                    {
                        content: 'Problem Solving: ' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Problem Solving Test'] + '%',
                    },
                    {
                        content: 'Relationship Building: ' + assessment?.data?.data?.data?.softSkillsTest?.skills?.['Relationship Building Test'] + '%',
                    },

                ],
            },
            {takeAssessment: false},
            {title: 'Soft Skills Assessment'},
            {testType: 'Soft Skills Test'},
        ])
    }, [assessment?.isSuccess, userSkillSets?.isSuccess])

    return (
        <CModal size="lg" visible={true} onClose={() => setVisible(false)}>
            <div className={'d-flex flex-row justify-content-between px-5 py-3 border-b-grey'}>
                <div className={'d-flex flex-row gap-3'}>
                    <div>
                        <CButton onClick={() => setShowPdf(true)}
                                 className={"py-2 px-2  border border-2 bg-white"}>
                            View CV
                        </CButton>
                    </div>
                    <div className={'text-3'}>
                        {downloadCV(data)}
                    </div>
                </div>
                <div className={'d-flex flex-row justify-content-end gap-3 '}>
                    <AiFillCloseCircle size={30} onClick={() => setVisible(false)}/>
                </div>
            </div>
            <div className={'text-center text-3 mt-2'}>
                {data?.firstName} {data?.lastName}
            </div>
            <CModalBody>
                {
                    (assessment?.isLoading || userSkillSets.isLoading) ?
                        <ShowLoading/> :
                        <>
                            <UserProfileTest data={PT}/>
                            <UserProfileTest data={CFT}/>
                            <UserProfileTest data={SKT}/>
                            <SkillsTest skillsTest={SAT}/>
                        </>
                }
            </CModalBody>
            {
                (showPdf && data )&&
                <ViewResumeModal
                    setVisible={setShowPdf}
                    pdfFile={data}
                />
            }
        </CModal>
    );
}

export default JobApplicantView;
