import React from 'react';
import {CCol, CImage, CRow} from "@coreui/react-pro";

const FeaturedJobsRow = ({ details }) => {
  return <CRow className="gx-3 my-3 py-0">
      {details?.map(({image, job}, index) => (
          <CCol xs={3} className="featured-job h-auto" key={index}>
              <CRow className="d-flex flex-row justify-content-between align-items-center shadow-sm-user p-0 m-1">
                  <CCol xs={5} className="p-0">
                      <CImage src={image} className="w-100" />
                  </CCol>
                  <CCol xs={7}>
                      <p className="ms-1 text-primary-dark text-center">{job}</p>
                  </CCol>
              </CRow>
          </CCol>
      ))}
  </CRow>
}

export default FeaturedJobsRow;