import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function CVTextEditor({editorState, setEditorState}) {
    const handleEditorChange = (content) => {
        setEditorState(content);
    };
    const toolbarOptions = [
        ['bold', 'italic', 'underline'],        // toggled buttons
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean']                                         // remove formatting button
    ];

    const modules = {
        toolbar: toolbarOptions,
    };
    return (
        <>
            <ReactQuill
                value={editorState}
                onChange={handleEditorChange}
                theme="snow"
                modules={modules}
            />
        </>
    );
}

export default CVTextEditor;
