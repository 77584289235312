import * as Yup from 'yup'

export const NewTrainerSchema = Yup.object().shape({
    firstName: Yup.string().typeError('First name is required').required('First name is required'),
    lastName: Yup.string().typeError('Last name is required').required('Last name is required'),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    phoneNumber: Yup.string()
        .typeError('Phone number is required')
        .required('Phone number is required')
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Must be a valid phone number'),
    center: Yup.string().typeError('Center is required').required('Center is required'),
    state: Yup.string().typeError('State is required').required('State is required'),
    // course: Yup.string().typeError('Course is required').required('Course is required'),
})

