import React, {useEffect, useState} from 'react';
import {
    CButton,
    CCol,
    CContainer,
    CForm,
    CFormCheck,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {useForm} from "react-hook-form";
import 'react-datepicker/dist/react-datepicker.css'
import {yupResolver} from "@hookform/resolvers/yup";
// import {fail_notify, success_notify} from "../../constant";
import TextEditor from "../../components/TextEditor";
import {SponsorshipApplicationSchema} from "../../form-schema/user-form-schema/sponsorshipApplicationSchema";
import {TiImage} from "react-icons/ti";
import {Link, useParams} from "react-router-dom";
import {useApplyForScholarship, useFetchUserCVs} from "../../hooks/user/jobApiHooks";
import ShowLoading from "../../components/ShowLoading";
import {fail_notify, success_notify} from "../../constant";

function SponsorshipApplication({sponsorshipId, sponsorshipData}) {
    // const {sponsorshipId} = useParams()
    const [personalStatementText, setPersonalStatementText] = useState('');
    const [additionalInformation, setAdditionalInformation] = useState('');
    const [statementText, setStatementText] = useState("")
    const [recommendationText, setRecommendationText] = useState('')
    // const [cvText, setCVText] = useState('')
    const [CVId, setCVId] = useState(null)
    const {isLoading, data} = useFetchUserCVs()
    const {mutate, isError, error, isSuccess, isLoading: isApplying} = useApplyForScholarship()

    const {
        register,
        handleSubmit,
        formState: {errors},
        clearErrors,
        getValues,
        setError,
        setValue
    } = useForm({
        resolver: yupResolver(SponsorshipApplicationSchema)
    })

    useEffect(() => {
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
        if (isSuccess) {
            setPersonalStatementText("")
            setAdditionalInformation("")
            setStatementText("")
            setRecommendationText("")
            // setCVText("")
            setCVId(null)
            setValue('anyScholarship', '')
            success_notify("Sponsorship Applied Successfully!")
        }
    }, [isSuccess, isError, error])

    if (isLoading) {
        return <ShowLoading/>
    }

    const handleSelectStatement = (e) => setStatementText(e.target.files[0].name)

    const handleSelectRecommendation = (e) => setRecommendationText(e.target.files[0].name)

    // const handleSelectCV = (e) => setCVText(e.target.files[0].name)

    const checkFormErrors = () => {
        let isErrorExist = false
        if (!CVId) {
            setError('applicantCV', { message: "CV is required"})
            isErrorExist  = true
        } else { clearErrors('applicantCV') }

        if (getValues('financialStatement')?.length === 0) {
            setError('financialStatement', { message: "Financial Statement is required"})
            isErrorExist  = true
        } else { clearErrors('financialStatement') }

        if (getValues('anyScholarship')?.length === 0) {
            setError('anyScholarship', { message: "Scholarship is required"})
            isErrorExist  = true
        } else { clearErrors('anyScholarship') }

        if (personalStatementText?.length === 0) {
            setError('personalStatement', { message: "Personal Statement is required"})
            isErrorExist  = true
        } else { clearErrors('personalStatement') }

        if (getValues('letterOfRecommendation')?.length === 0) {
            setError('letterOfRecommendation', { message: "Letter of Recommendation is required"})
            isErrorExist  = true
        } else { clearErrors('letterOfRecommendation') }

        if (additionalInformation?.length === 0) {
            setError('additionalInformation', { message: "Additional Information is required"})
            isErrorExist  = true
        } else { clearErrors('additionalInformation') }

        if (getValues('consent') === false) {
            setError('consent', { message: "Consent is required"})
            isErrorExist  = true
        } else { clearErrors('consent') }

        return isErrorExist
    }

    const handleSubmitApplication = (data) => {
        const form = document.forms.namedItem('sponsorship-application')
        const formData = new FormData(form)

        if (checkFormErrors()) return

        if (!!CVId) {
            data.cvId = Number(CVId)
        }

        formData.personalStatement = personalStatementText;
        formData.additionalInformation = additionalInformation;
        formData.sponsorshipId = sponsorshipId

        mutate({...formData, ...data})
    }

    return (
        <CContainer className="w-75 bg-white mb-5">
            <CRow className="p-4">
                <CRow className="d-flex flex-row  py-20">
                    <CCol sm={12}>
                        <h3 className="sub-title">SPONSORSHIP APPLICATION FORM</h3>
                        <hr/>
                    </CCol>
                </CRow>
                <CRow className="my-3">
                    <h5>Sponsorship Details</h5>
                    <CCol md={6}>
                        <p>Sponsor: {sponsorshipData?.sponsor?.name}</p>
                        <p>Title: {sponsorshipData?.title}</p>
                        <p>Sponsorship Type: {sponsorshipData?.sponsorshipType}</p>
                    </CCol>
                    <CCol md={6}>
                        <p>Loan Tenure: {sponsorshipData?.loanTenure}</p>
                        <p>Sponsorship Amount: {sponsorshipData?.sponsorshipAmount}</p>
                        <p>Interest Rate: {sponsorshipData?.interestRate}</p>
                    </CCol>
                </CRow>
                <hr/>
                <CForm className="w-100" onSubmit={handleSubmit(handleSubmitApplication)} name="sponsorship-application">
                    <CRow className="mt-2 mb-4">
                        <CCol className="">
                            <CFormLabel className="m-1 label">Attach CV / Resume</CFormLabel>
                            <CRow className="my-1 mb-3">
                                <CCol md={8}>
                                    <div>
                                        <CFormLabel>Select from your existing CVs:</CFormLabel><br/>
                                        {data?.data?.data?.map(({originalName, id}, index) => (
                                            <CButton key={index} className="btn btn-sm bg-transparent border border-primary border-1 text-primary px-2">
                                                <CFormCheck
                                                    {...register('applicantCV')}
                                                    invalid={!!errors.applicantCV?.toString()}
                                                    value={id}
                                                    onChange={(e) => setCVId(e.target.value)}
                                                /> {originalName}
                                            </CButton>
                                        ))}
                                        <CFormFeedback invalid={!errors.applicantCV?.toString()}>
                                            <span className="small-text-gray text-danger">{errors.applicantCV?.message}</span>
                                        </CFormFeedback>
                                    </div>
                                </CCol>
                                <CCol md={4}>
                                    <p>
                                        <Link to={'/user/resume-builder'} target="_blank" className="text-decoration-none">
                                            <strong className="text-primary">CREATE NEW CV</strong>
                                        </Link>
                                    </p>
                                </CCol>
                            </CRow>
                            {/*<label className="custom-file-upload" htmlFor="applicantCV">*/}
                            {/*    <TiImage size={36} className="text-primary"/> Upload CV<br/>*/}
                            {/*    <br/>{cvText}*/}
                            {/*</label>*/}
                            {/*<CFormInput*/}
                            {/*    type="file"*/}
                            {/*    id="applicantCV"*/}
                            {/*    {...register('applicantCV')}*/}
                            {/*    invalid={!!errors.applicantCV?.toString()}*/}
                            {/*    className="custom-file-input"*/}
                            {/*    accept="application/*"*/}
                            {/*    onChange={handleSelectCV}*/}
                            {/*    style={{maxWidth: '100vh'}}*/}
                            {/*/>*/}
                        </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CCol className="">
                            <CFormLabel className="m-1 label">Statement of Financial Need</CFormLabel>
                            <br/>
                            <label className="custom-file-upload" htmlFor="financialStatement">
                                <TiImage size={36} className="text-primary"/> Click to select file<br/>
                                <br/>{statementText}
                            </label>
                            <CFormInput
                                type="file"
                                id="financialStatement"
                                {...register('financialStatement')}
                                invalid={!!errors.financialStatement?.toString()}
                                className="custom-file-input"
                                accept="application/*"
                                onChange={handleSelectStatement}
                                style={{maxWidth: '100vh'}}
                            />
                            <CFormFeedback invalid={!!errors.financialStatement?.toString()}>
                                {errors.financialStatement?.message}
                            </CFormFeedback>
                        </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CCol className="">
                            <CFormLabel className="m-1 label">Any Other Existing Scholarship, Please state</CFormLabel>
                            <CFormInput
                                type="text"
                                placeholder="Enter Other Existing Scholarship"
                                {...register('anyScholarship')}
                                invalid={!!errors.anyScholarship?.toString()}
                                className="mb-2"
                            />
                            <CFormFeedback invalid={!!errors.anyScholarship?.toString()}>
                                {errors.anyScholarship?.message}
                            </CFormFeedback>
                        </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CFormLabel className="m-1 label">Personal Statement</CFormLabel>
                        <div>
                            <TextEditor
                                editorState={personalStatementText}
                                setEditorState={setPersonalStatementText}
                                placeholder={"Why are you interested in the course or program and how does it aligns with their goals? And how does this course help you achieve your career aspirations?"}
                            />
                            <CFormFeedback invalid={!errors.personalStatement?.toString()}>
                                <span className="small-text-gray text-danger">{errors.personalStatement?.message}</span>
                            </CFormFeedback>
                        </div>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CCol className="">
                            <CFormLabel className="m-1 label">Attach Letter of Recommendation</CFormLabel>
                            <label className="custom-file-upload" htmlFor="letterOfRecommendation">
                                <TiImage size={36} className="text-primary"/> Click to select file<br/>
                                <br/>{recommendationText}
                            </label>
                            <CFormInput
                                type="file"
                                id="letterOfRecommendation"
                                {...register('letterOfRecommendation')}
                                invalid={!!errors.letterOfRecommendation?.toString()}
                                className="custom-file-input"
                                accept="application/*"
                                onChange={handleSelectRecommendation}
                                style={{maxWidth: '100vh'}}
                            />
                            <CFormFeedback invalid={!!errors.letterOfRecommendation?.toString()}>
                                {errors.letterOfRecommendation?.message}
                            </CFormFeedback>
                        </CCol>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CFormLabel className="m-1 label">Additional Information</CFormLabel>
                        <div>
                            <TextEditor
                                editorState={additionalInformation}
                                setEditorState={setAdditionalInformation}
                                placeholder={"Provide any other information that may help you gain this sponsorship"}
                            />
                            <CFormFeedback invalid={!errors.additionalInformation?.toString()}>
                                <span className="small-text-gray text-danger">{errors.additionalInformation?.message}</span>
                            </CFormFeedback>
                        </div>
                    </CRow>
                    <CRow className="mt-2 mb-4">
                        <CCol className="">
                            <CFormLabel className="m-1 label">Consent</CFormLabel><br/>
                            <CFormCheck
                                id="consent"
                                {...register('consent')}
                                invalid={!!errors.consent?.toString()}
                                className="mb-2"
                            />
                            <span> I agree to share information with the sponsor or training provider</span>
                            <CFormFeedback invalid={!!errors.consent?.toString()}>
                                {errors.consent?.message}
                            </CFormFeedback>
                        </CCol>
                    </CRow>
                    <CRow className="d-flex flex-row justify-content-center my-2">
                        <CCol sm={4} className="d-flex flex-row justify-content-center">
                            <CLoadingButton
                                type={"submit"}
                                className="text-white px-3"
                                loading={isApplying}
                            >Submit</CLoadingButton>
                        </CCol>
                    </CRow>
                </CForm>

            </CRow>
        </CContainer>
    );
}

export default SponsorshipApplication;
