import React, {useCallback, useState} from 'react'
import {CButton, CContainer, CFormInput, CFormSelect} from "@coreui/react-pro";
import {useGetLMSReport} from "../../hooks/admin/adminApiHooks";
import {debounce} from "lodash";
import {fail_notify} from "../../constant";
import ShowLoading from "../../components/ShowLoading";
import Table from "../../components/tables/table";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import DataNotFound from "../../components/DataNotFound";
import NaijaStates from "naija-state-local-government";

const LMSReport = () => {

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [stateOfOrigin, setStateOfOrigin] = useState(null)
    const {isLoading, isSuccess, data, error, isError} = useGetLMSReport(stateOfOrigin, limit, page)
    console.log(data?.data?.data?.meta)
    const headers = [
        {name: "Name"},
        {name: "Email"},
        {name: "Course"},
        {name: "Category"},
        {name: "Completion-Time"},
        {name: "Completed"},

    ]

    const debounceSearchHandler = useCallback(debounce(e => setStateOfOrigin(e.target.value), 600), [])

    if (isError) {
        fail_notify(error?.response?.data?.message)
    }
    return (
        <>

            <CContainer className="mt-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <h5 className="text-2 mb-3 ps-1">LMS Report</h5>
                </div>
                <div className="d-flex justify-content-between align-items-center">

                    <div className={`d-flex align-items-center justify-content-between w-100 gap-2`}>
                        <div style={{height: "40px", minWidth:"250px"}}>

                            <CFormSelect
                                id="educationLevel"
                                className="form-control mb-2"
                                onChange={debounceSearchHandler}

                            >
                                <option value="">Filter by State of Origin</option>
                                {
                                    NaijaStates?.states()?.map((option, index) => (
                                        <option
                                            value={option}
                                            key={index}
                                        >
                                            {option}
                                        </option>
                                    ))
                                }
                            </CFormSelect>
                        </div>
                        <div className="pe-2">{false && <h5>Total: {data?.data?.data?.meta?.totalItems}</h5>}</div>
                    </div>
                </div>

                <Table headers={headers}>
                    {(!isLoading && data?.data?.data?.items?.length < 1) ? <DataNotFound/> :
                        <>
                            {data?.data?.data?.items?.map((item, index) => (
                                <tr className="cursor-pointer bg-white border"
                                    key={index}

                                >
                                    {isLoading && <ShowLoading/>}

                                    <>
                                        <td className="td-single-line fw-light">{`${item?.user_firstname}  ${item?.user_lastname}`}</td>
                                        <td className="fw-light">{item?.user_email}</td>
                                        <td className="fw-light">{item?.course_fullname}</td>
                                        <td className="fw-light">{item?.category_name}</td>
                                        <td className="fw-light">{item?.completion_time || 'Nil'}</td>
                                        <td className="fw-light">{item?.course_completed || 'Nil'}</td>

                                    </>
                                </tr>
                            ))}
                        </>
                    }
                </Table>
                <div className="d-flex flex-row justify-content-end align-items-center">
                    <CButton
                        disabled={page <= 1} onClick={() => setPage(page - 1)}
                        className={`mx-3 text-white py-1 pb-2 px-3`}>
                        Previous <BiLeftArrow/>
                    </CButton>
                    <CButton
                        onClick={() => setPage(page + 1)}
                        className="mx-3 text-white py-1 pb-2 px-3"
                        disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                    >
                        <BiRightArrow/> Next
                    </CButton>
                </div>


            </CContainer>
        </>
    )
}

export default LMSReport
