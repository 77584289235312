import React from 'react'
import { CButton, CContainer } from '@coreui/react-pro'
import { Link } from 'react-router-dom'
// import DefaultLayout from '../../layouts/DefaultLayout'

const Verified = () => {
  return (
    <CContainer>
      <div className="d-flex align-items-center flex-column vh-full w-100 mt-5">
        <p className="text-black fw-bold">Registration Completed!</p>
        <p className="text-black">
          Thank you for completing your registration. Your details have updated successfully.
        </p>
        <div className="d-flex justify-content-center gap-2 align-items-center mt-2 resend-group">
          <Link to="/login" className="w-100">
            <CButton variant="outline" className="w-100 py-2">
              <span className="me-2">Proceed</span> &#62;
            </CButton>
          </Link>
        </div>
      </div>
    </CContainer>
  )
}

export default Verified
