import React, {useState} from 'react'
import {
    CAlert,CContainer,
} from "@coreui/react-pro";
import ShowLoading from "../../components/ShowLoading";
import {
    useGetQuestionnairesByUUId
} from "../../hooks/user/userApiHooks";
import {useParams} from "react-router-dom";
import {AiOutlineEdit} from "react-icons/ai";
import EditTest from "../../components/modals/EditTest";
import DOMPurify from 'dompurify';
const ViewTestQuestion = () => {
    const { uuid } = useParams()
    const {isLoading:isLoadingQuestion, data:dataQuestion, isError: isErrorQuestion, error: errorQuestion} = useGetQuestionnairesByUUId(uuid)
    const [editData, setEditData] = useState(null)
    const [visible, setVisible] = useState(false)
    //console.log(dataQuestion)
    return (
        <>
            {isLoadingQuestion && <ShowLoading/>}

            <CContainer>
                <div className="bg-white w-100 min-vh-100 border-radius-12 py-5 px-3 d-flex flex-column align-items-center position-relative box-shadow">

                    <h3 className="text-capitalize text-black fw-bold front-one mb-3">{dataQuestion?.data?.data?.title}</h3>

                    {visible && <EditTest editData={editData} setVisible={setVisible}/>}
                    {isErrorQuestion && <CAlert color="danger">{errorQuestion}</CAlert>}

                    {
                        dataQuestion?.data?.data?.questions?.map((question, index) => (
                            <div className="front-one mb-4 w-100 px-5" key={index}>
                                <strong>Question</strong> <AiOutlineEdit
                                onClick={()=>{
                                    setEditData(question)
                                    setVisible(true)
                                }}
                                className="cursor-pointer"/>
                                <div className="d-flex gap-2">
                                 <p>{index + 1}.</p>

                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question?.question)}}></div>

                                </div>
                                <div className="ps-5">
                                    <strong>Options</strong>
                                    {question?.options?.map((o, i) => {
                                        return(<div className="d-flex gap-2" key={i}>
                                            {i + 1}. <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(o?.text)}}></div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        ))}
                </div>
            </CContainer>

        </>
    )
}

export default ViewTestQuestion
