import React, {useEffect, useState} from 'react'
import {useAppContext} from "../../context/user/UserAppProvider";
import {
    CButton,
    CCard,
    CCol,
    CContainer, CFormSelect, CImage,
    CRow
} from "@coreui/react-pro";
import ShowLoading from "../ShowLoading";
// import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {Link} from "react-router-dom";
import DataNotFound from "../DataNotFound";
import {AiOutlineDoubleRight} from "react-icons/ai";
import {userEnrolled} from "./TrainingErollmentUtility";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";

function TrainingsProgramsCard({interested, type, query , page, setPage, setLimit }) {

    const {isLoading, data} = query
    const {user, userId} = useAppContext()
    const [trainingData, setTrainingData] = useState(null)
    //console.log(data);
    useEffect(()=>{
        setTrainingData( data?.data?.data?.items ||  data?.data?.data)
    },[isLoading, data])
    const totalItems = () => {
        if (type === "1") {
            return "Training and Programs Found"
        }

        if (type === "2") {
            return "Training and Programs enrolled"
        }

        if (type === "3") {
            return "Sponsored Training and Programs enrolled"
        }
    }

    const batchColor = (index) => {

        if (index % 2 === 0) {
            return "linear-gradient(180deg, #003767 0%, #195A92 100%)"
        }
        if (index % 3 === 0) {
            return "linear-gradient(180deg, #CDA51D 0%, #E3C41F 100%)"
        }

        return "#1FC776"

    }

    return (
        <CContainer className="mb-5">
            {isLoading ? <ShowLoading/> :
                <div>
                    <>
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            {interested === true ? <div>
                                <strong>You might also be Interested in these <span
                                    className="text-primary">Training & Programs </span></strong>
                            </div> : <div className="mb-3">
                                <h6>
                                <strong>{data?.data?.data?.meta?.itemCount || data?.data?.data?.length} </strong>{totalItems() || " Training and Programs Found"}
                                </h6>
                                </div>}
                            <div className="d-flex flex-column flex-sm-row justify-content-left gap-2">
                                {/* {filterTraining}*/}
                                {/*<div className="d-flex">
                                    <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                             className="bg-white text-black border-0 mx-3 py-1 pb-2 px-3">
                                        <BiLeftArrow/>
                                    </CButton>
                                    <CButton
                                        disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                                        onClick={() => setPage(page + 1)}
                                        className="bg-white text-black mx-3 border-0 py-1 pb-2 px-3">
                                        <BiRightArrow/>
                                    </CButton>
                                </div>*/}
                            </div>

                        </div>
                        {/*{itemLimit}*/}
                        {data?.data?.data?.length === 0 ?
                            <>
                                <DataNotFound/>
                            </> :
                            <CRow xs={{cols: 1}} md={{cols: 2}} lg={{cols: 3}} xl={{cols: 4}} className="g-3 pb-2">
                                {trainingData?.map((training, index) => {
                                    if (type === "3" && !training?.sponsored) {
                                        return null
                                    }
                                    return (
                                        <CCol xs key={index}>
                                            <CCard
                                                className="d-flex flex-column justify-content-between align-items-start h-100"
                                                style={{minHeight: "350px"}}>
                                                <div style={{width: '100%', height: '250px'}}>
                                                    <CImage
                                                        src={training?.courseImageUrl}
                                                        alt="Training"
                                                        className="rounded-top"
                                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                    />
                                                    {(type === "3" || training?.sponsored) &&
                                                        <div className="tag rounded-2 px-3">Sponsored</div>}

                                                    <div className="badge-div"
                                                         style={{background: batchColor(index)}}></div>
                                                    <center className="pt-2">
                                                        <p className="py-0 m-0 text-truncate">
                                                            <b>{training?.shortname}</b></p>
                                                        <p className="text-truncate small-text-gray">{training?.stakeholder?.name || training?.training?.stakeholder?.name}</p>
                                                    </center>
                                                </div>
                                                {(user?.accessToken && (user?.data?.roles?.includes('Employer') || user?.data?.roles?.includes('Academy'))) ?
                                                    <Link
                                                        to={`/employer/training-detail/${training?.id}`}
                                                        className="text-decoration-none w-100 p-2">
                                                        <div
                                                            className="d-flex enroll-btn flex-row justify-content-between align-items-center w-100 p-2">
                                                            <b className="text-white">VIEW</b>
                                                            <AiOutlineDoubleRight className="text-white"/>
                                                        </div>
                                                    </Link> : <Link
                                                        to={user?.accessToken ? `/user/training-detail/${training?.id}` : `/training-detail/${training?.id}`}
                                                        //to={`/transit/lms`}
                                                        className="text-decoration-none w-100 p-2">
                                                        <div
                                                            className="d-flex enroll-btn flex-row justify-content-between align-items-center w-100 p-2">
                                                            {userEnrolled((training?.training || training), userId) ?
                                                                <b className="text-white">ENROLLED</b> : <>{type === "2" ?
                                                                    <b className="text-white">ENROLLED</b> :
                                                                    <b className="text-white">ENROLL</b>}</>}
                                                            <AiOutlineDoubleRight className="text-white"/>
                                                        </div>
                                                    </Link>}


                                            </CCard>
                                        </CCol>
                                    )
                                })}
                            </CRow>

                        }
                    </>
                    {data?.data?.data?.meta?.itemCount && <div className={"d-flex justify-content-between flex-wrap gap-2 w-100"}>
                        <div className={"d-flex flex-row gap-3"}>
                            <div className={"m-2"}>Show</div>
                            <CFormSelect
                                aria-label="Default select example"
                                options={[{label: '10', value: '10'}, {label: '25', value: '25'}, {
                                    label: '100',
                                    value: '100'
                                }, {label: '200', value: '200'}]}
                                onChange={(e) => setLimit(e.target.value)}
                            />
                            <div className={"m-2"}>Entries</div>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            Prev<CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                         className={`mx-3 text-white py-1 pb-2 px-3`}>
                            <BiLeftArrow/>
                        </CButton>

                            <CButton
                                disabled={page === data?.data?.data?.meta?.totalPages || isLoading}
                                onClick={() => setPage(page + 1)} className="mx-3 text-white py-1 pb-2 px-3">
                                <BiRightArrow/>
                            </CButton> Next
                        </div>
                    </div>}

                </div>}
        </CContainer>
    );
}

export default TrainingsProgramsCard
