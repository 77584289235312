import React, {useCallback, useEffect, useState} from 'react';
import {CCard, CCol, CContainer, CForm, CFormInput, CFormText, CImage, CRow} from "@coreui/react-pro";
import {debounce} from "lodash"

import {useForm} from "react-hook-form";
import pointer from "../../assets/icons/pointer.svg"
import {useAppContext} from "../../context/user/UserAppProvider";
import trainingBg from "../../assets/images/training-bg.png";
import trainingImage from "../../assets/images/trainingHeader.svg";
import {AiOutlineDoubleRight} from "react-icons/ai";


function BreadCrumbTraining({title, desc, setSearch, setPage,}) {
    const [md, setMd] = useState(false)
    const {user,} = useAppContext()
    const {
        register,
        handleSubmit,
        formState: {errors},

    } = useForm({
        defaultValues: { search: ''},
    })

    const handleJobSearchFilter = (e) => {
        let filter = '';
        if (e.target.value) {
            filter = filter + "&search=" + e.target.value
        }
        setPage(1)
        setSearch(filter)
    }

    useEffect(() => {
        function handleResize() {
            const windowWidth = window.innerWidth;

            if (windowWidth < 1000) {
                setMd(false)
            } else {
                setMd(true)
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const suggestedSearch = ["Software Development", "Machine Learning", "Project Management", "Basic Accounting Principles", "Data Analytics", "Data Analytics"]

    const debouncedSearchChangeHandler = useCallback(debounce(handleJobSearchFilter, 2000), []);

    return (
        <div className="w-100">
            {false ? <div className="position-relative mt-2 mt-md-4">
                    <div className="left-blur mb-hidden"></div>
                    <div className="right-blur mb-hidden"></div>
                    <CContainer>
                        <div>
                            <center>
                                <div className="training-card-header">
                                    <small className="text-primary bg-green-700 px-4 py-2 rounded-5">Access the Best Career
                                        Courses</small>
                                    <h1 className="fw-bolder my-3">Training <span className="text-primary">You</span> To Be
                                        <br/>
                                        <span className="border px-3 border-primary position-relative">
                                            <div className="figma-1 position-absolute border border-primary bg-white"></div>
                                            <div className="figma-2 position-absolute border border-primary bg-white"></div>
                                            <div className="figma-3 position-absolute border border-primary bg-white"></div>
                                            <div className="figma-4 position-absolute border border-primary bg-white"></div>
                                            <div className="figma-5 position-absolute">
                                            <img src={pointer}/>
                                            </div>
                                            Job Ready
                                        </span>
                                    </h1>

                                    <p className="text-black-50 py-2">{desc}</p>
                                </div>

                            </center>
                        </div>

                        {md && <div className="d-flex justify-content-start  pt-4 gap-5">
                            <div className=" ">
                                <CCol className="opacity-25">
                                        <CCard className="d-flex flex-column justify-content-between align-items-start h-100" style={{Height: "100px"}}>
                                            <div style={{width: '100%', height: '100px'}}>
                                                <CImage
                                                    src={trainingImage}
                                                    alt="Training"
                                                    className="rounded-top"
                                                    style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                />

                                                <div className="badge-div" style={{background:"#1FC776"}}></div>
                                                <center className="p-2">
                                                    <p className="py-0 m-0 text-truncate">
                                                        <b>Basic Software Development</b></p>
                                                    <p className="text-truncate small-text-gray">Outsource Global</p>
                                                </center>
                                            </div>
                                            <span className="text-decoration-none w-100 p-2"> <br/> <br/><br/>
                                                                        <div
                                                                            className="d-flex enroll-btn flex-row justify-content-between align-items-center w-100 p-2">
                                                                            <b className="text-white">ENROL</b>
                                                                            <AiOutlineDoubleRight className="text-white"/>
                                                                        </div>
                                                                    </span>
                                        </CCard>

                                </CCol>
                            </div>
                            <div className="ms-5 ps-5">
                                <div className="d-flex flex-wrap" style={{maxWidth: "600px"}}>

                                {suggestedSearch.map((search, index) => {
                                    return (
                                        <div key={index} className=" mb-5 pe-2">
                                            <small
                                                className="px-3 py-2 bg-grey rounded-2 text-black-50 cursor-pointer text-truncate">{search} &nbsp; &nbsp;
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                     fill="currentColor"
                                                     className="bi bi-search" viewBox="0 0 16 16">
                                                    <path
                                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                </svg>
                                            </small>
                                        </div>
                                    )
                                })}
                            </div>
                                <CForm onSubmit={handleSubmit()}>
                                    <div
                                        className="w-40 border-3 border-black border ps-2 rounded d-flex align-items-center" style={{minWidth: "500px"}}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                             className="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                        </svg>

                                        <CFormInput
                                            type="search"
                                            id="companyName"
                                            placeholder="Search"
                                            autoComplete="off"
                                            {...register('search')}
                                            className="bg-transparent border rounded-0 border-white border-0 shadow-none w-100"
                                            onChange={debouncedSearchChangeHandler}
                                        />
                                    </div>
                                    <CFormText component="span" id="searchByText" className="">
                                        Search by title, address,training duration, category name
                                    </CFormText>
                                </CForm>

                            </div>


                        </div>}
                        {!md && <center>
                            <br/>
                            <CForm onSubmit={handleSubmit()}>
                                <div
                                    className="w-40 border-3 border-black border ps-2 rounded d-flex align-items-center">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>

                                    <CFormInput
                                        type="search"
                                        id="companyName"
                                        placeholder="Search"
                                        autoComplete="off"
                                        {...register('search')}
                                        className="bg-transparent border rounded-0 border-white border-0 shadow-none"
                                        onChange={debouncedSearchChangeHandler}
                                    />
                                </div>
                                <CFormText component="span" id="searchByText" className="">
                                    Search by title, address,training duration, category name
                                </CFormText>
                            </CForm>
                        </center>}
                    </CContainer>
                </div>

                : <CContainer>
                    <div
                        className="d-flex training-force overflow-hidden rounded-3 justify-content-between align-items-center">
                        {!md && <center className="w-100 mx-2 p-4 h-100">
                            <h2 className="text-2 fw-bold">{title}</h2>
                            <small className="">{desc}</small>

                        </center>}
                        {md && <div className="w-75 mx-2 h-100">
                            <h2 className="text-2 fw-bold">{title}</h2>
                            <small className="">{desc}</small>

                        </div>}
                        {md && <div className="w-50">
                            <img style={{objectFit: "cover"}} src={trainingBg}/>
                        </div>}
                    </div>
                </CContainer>}
        </div>
    );
}

export default BreadCrumbTraining;
