import React from 'react';
import {CCard, CCardBody, CCardText, CCardTitle, CCol} from "@coreui/react-pro";

function UserStatCard({data, title, index}) {

    return (
    <div>
        <CCard className={`user-dashboard-stat user-dashboard-stat-${index} rounded`}>
            <CCardBody className="d-flex flex-row">
                <CCol className="d-flex flex-row justify-content-between align-content-start">
                    <h3 className="text-3 p-0 m-0">{title}</h3>
                    {/*<CCardText className="card-text p-0 m-0">*/}
                    {/*    */}
                    {/*</CCardText>*/}
                    <h3 className="text-3 text-green p-0 m-0">{data}</h3>
                    {/*<CCardTitle className="main-title_green p-0 m-0">{data}</CCardTitle>*/}
                </CCol>
            </CCardBody>
        </CCard>
    </div>

    );
}

export default UserStatCard;