import React from 'react';
import {CModal, CModalBody, CModalTitle} from "@coreui/react-pro";

function JobPreview({data, visible, setVisible}) {


    return (
        <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>

            <CModalBody className="text-center">
                {
                    <>
                        <CModalTitle className="text-title mb-2"> {data?.jobTitle} </CModalTitle>
                        <p>
                            {data?.jobType}
                        </p>

                        <p>
                            {data?.description}
                        </p>
                        <p className="scores">
                            {data?.experience}
                        </p>
                        <p className="scores">
                            {/*{data?.deadline}*/}
                        </p>
                        <p className="scores">
                            {data?.experienceLevel}
                        </p>
                    </>

                }
            </CModalBody>


        </CModal>
    );
}

export default JobPreview;