import React from 'react';
import HeroSection from "../../components/landing-page-2/HeroSection";
import WelcomeSection from "../../components/landing-page-2/WelcomeSection";
import FeaturedJobs from "../../components/landing-page-2/FeaturedJobs";
import TalentsSection from "../../components/landing-page-2/TalentsSection";
import EmployersSection from "../../components/landing-page-2/EmployersSection";
import SponsorsSection from "../../components/landing-page-2/SponsorsSection";
import AcademiesSection from "../../components/landing-page-2/AcademiesSection";
import Statistics from "../../components/landing-page-2/Statistics";
import Testimonials from "../../components/landing-page-2/Testimonials";
import TrainingLocations from "../../components/landing-page-2/TraningLocations";

const LandingPage2 = () => {
  return <div className="landing-page">
      <HeroSection/>
      <WelcomeSection/>
      <FeaturedJobs/>
      <TalentsSection/>
      <EmployersSection/>
      <SponsorsSection/>
      <AcademiesSection/>
      <Statistics/>
      <Testimonials/>
      <TrainingLocations/>
  </div>
}

export default  LandingPage2;
