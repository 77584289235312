import React, {useEffect} from 'react'
import {CCol, CContainer, CImage, CLoadingButton, CRow} from "@coreui/react-pro";
import {GoMail} from "react-icons/go";
import {BsGenderAmbiguous} from "react-icons/bs";
import DefaultPic from "../../../../assets/images/dummy-profile-pic.png"
import {BiArrowBack} from "react-icons/bi";
import {AiFillEye, AiFillFileText, AiOutlinePhone} from "react-icons/ai";
import DOMPurify from "dompurify";
import {useApproveForBeneficiaries} from "../../../../hooks/user/jobApiHooks";
import {fail_notify, success_notify} from "../../../../constant";

const SponsorshipAnalysisPage = ({userData, setIsAnalysisVisible}) => {
    const {
        mutate,
        isLoading,
        isSuccess,
        data,
        isError,
        error
    } = useApproveForBeneficiaries()

    useEffect(() => {
        if(isSuccess){
            success_notify('Beneficiary Approval Completed')
            setIsAnalysisVisible(false)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }

    }, [isSuccess, mutate, data, isError, error]);
    const handleSponsorshipApproval = (status) => {
        const formData = {
            userId: userData?.user?.id,
            sponsorshipId: userData?.id,
            isApprove: status
        }
        mutate(formData)
    }


    return (
        <CContainer className="mb-5">
            <p onClick={() => setIsAnalysisVisible(false)} className="cursor-pointer"><BiArrowBack/> Go Back</p>
            <br/>
            <CRow>
                <CCol md={5} style={{height: 'fit-content'}}>
                    <div className="p-2 bg-white rounded me-1">
                        <h6 className="bg-grey-100 p-2 gray-paragraph">Personal Informations</h6>
                        <CRow>
                            <CCol md={4} className="pe-0">
                                <CImage
                                    src={userData?.profile?.passportUrl || DefaultPic}
                                    style={{width: '100%'}}
                                    onError={(e) => {
                                        e.target.src = DefaultPic
                                    }}
                                />
                            </CCol>
                            <CCol md={8} className="d-flex flex-column justify-content-between ps-1">
                                <div>
                                    <h6 className="mb-0">{userData?.user?.lastName + " " + userData?.user?.firstName}</h6>
                                    <p className="smaller-text-12 gray-paragraph">Software developer</p>
                                </div>
                                <div>
                                    <p className="smaller-text-12 pt-1 gray-paragraph">
                                        <AiOutlinePhone size={14}/> {userData?.user?.phoneNumber}
                                    </p>
                                    <p className="smaller-text-12 pt-1 text-truncate gray-paragraph">
                                        <GoMail size={16}/> {userData?.user?.email}
                                    </p>
                                    <p className="smaller-text-12 pt-1 gray-paragraph">
                                        <BsGenderAmbiguous
                                            size={16}/> {userData?.profile?.gender === 'M' ? 'Male' : 'Male'}
                                    </p>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow className="mt-3">
                            <CCol>
                                <h6 className="bg-grey-100 p-2 gray-paragraph">Scholarship</h6>
                                <p className="smaller-text-12 pt-1">
                                    Nil
                                </p>
                            </CCol>
                        </CRow>
                        <CRow className="mt-3">
                            <CCol>
                                <h6 className="bg-grey-100 p-2 gray-paragraph">Personal Statement</h6>
                                <div className="smaller-text-12 pt-1 text-justify"
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(userData?.personalStatement)}}>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow className="mt-3">
                            <CCol>
                                <h6 className="bg-grey-100 p-2 gray-paragraph">Additional Information</h6>
                                <div className="smaller-text-12 pt-1 text-justify"
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(userData?.additionalInformation)}}>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow className="mt-3">
                            <CCol>
                                <h6 className="bg-grey-100 p-2 gray-paragraph">Uploaded Document(s)</h6>
                                <div className="d-flex justify-content-between border rounded rounded-2 p-2">
                                    <div className="d-flex align-items-center">
                                        <AiFillFileText color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 pt-1 text-justify">
                                            Statement of Financial Need
                                        </p>
                                    </div>
                                    <div className="d-flex cursor-pointer align-items-center">
                                        <AiFillEye color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 text-decoration-underline text-primary">Preview</p>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between border mt-2 rounded rounded-2 p-2">
                                    <div className="d-flex align-items-center">
                                        <AiFillFileText color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 pt-1 text-justify">
                                            Letter of Recommendation
                                        </p>
                                    </div>
                                    <div className="d-flex cursor-pointer align-items-center">
                                        <AiFillEye color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 text-decoration-underline text-primary">Preview</p>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between border mt-2 rounded rounded-2 p-2">
                                    <div className="d-flex align-items-center">
                                        <AiFillFileText color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 pt-1 text-justify">
                                            CV/Resume
                                        </p>
                                    </div>
                                    <div className="d-flex cursor-pointer align-items-center">
                                        <AiFillEye color="#1FAB89" size={18}/>
                                        <p className="smaller-text-12 text-primary text-decoration-underline">Preview</p>
                                    </div>

                                </div>
                            </CCol>
                        </CRow>
                    </div>
                </CCol>
                <CCol md={7} className="p-2 bg-black rounded">
                    <div className="w-100 h-100 bg-black p-2">
                        <div className="w-100 h-100 bg-white">

                        </div>
                    </div>
                </CCol>
            </CRow>
            <br/>
            <div className="d-flex flex-wrap justify-content-center gap-2">
                <CLoadingButton
                    className="text-white py-2 bg-danger"
                    onClick={() => handleSponsorshipApproval(false)}
                >
                    DECLINE SPONSORSHIP
                </CLoadingButton>
                <CLoadingButton
                    className="text-white py-2 bg-primary"
                    onClick={() => handleSponsorshipApproval(true)}
                >
                    APPROVE SPONSORSHIP
                </CLoadingButton>
            </div>
        </CContainer>
    )
}

export default SponsorshipAnalysisPage
