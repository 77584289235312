import React, {useState} from 'react';
import HeaderCard from "../HeaderCard";
import {ReactComponent as Users} from "../../../assets/icons/users.svg";
import {ReactComponent as PortFolio} from "../../../assets/icons/portfolio.svg";
import {ReactComponent as Viewers} from "../../../assets/icons/viewers.svg";
import TrainingDetail from "./TrainingDetail";

function ProgramTrainees({Trainees}) {

    const details = Trainees?.data?.data?.data?.items
    return (
        <div>
            {
                details?.map((detail, index) => {
                    return <div className="mt-2"  key={index}>
                        <TrainingDetail detail={detail}/>
                    </div>
                })
            }
        </div>
    );
}

export default ProgramTrainees;