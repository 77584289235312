import React, {useEffect, useState} from 'react';
import {CCol, CContainer, CImage, CNav, CNavItem, CNavLink, CRow} from "@coreui/react-pro";
import {useAppContext} from "../../../context/user/UserAppProvider";
import {useGetQuestionnaireAnswerQuery, useGetUserByUUID, useGetUserTier} from "../../../hooks/user/userApiHooks";
import filterPreloadedUserData from "../../../constant/userPreloadedData";
import ShowLoading from "../../../components/ShowLoading";
import UserProfileBasicInfo from "../../../components/User/UserProfileBasicInfo";
import UserProfilePersonalInfo from "../../../components/User/UserProfilePersonalInfo";
import UserProfileCard from "../../../components/User/UserProfileCard";
import ProfessionalSkills from "../../../components/User/ProfessionalSkills";
import SkillsTest from "../../../components/User/SkillsTest";
import PreferencesSection1 from "../../../components/User/PreferencesSection1";
import PreferencesSection2 from "../../../components/User/PreferencesSection2";
import CreateResume from "../../../components/User/CreateResume";
import UploadResume from "../../../components/User/UploadResume";
import {Tips} from "../../../constant/testTips";
import UserProfileTestWithPercent from "../../../components/User/UserProfileTestWithPercent";
import UserProfileTestWithRating from "../../../components/User/UserProfileTestWithRating";
import ApplicantProfile from "./ApplicantProfile";
import GoBack from "../../../components/GoBack";
import EditProfileIcon from "../../../assets/icons/edit-profile-icon.svg"
import ViewProfileIcon from "../../../assets/icons/eye-icon.svg"
import CompleteProfileModal from "../../../components/modals/CompleteProfileModal";

const Profile = () => {
    const {user, userId} = useAppContext()
    const {isLoading, isSuccess, data} = useGetUserByUUID(user?.data?.userUuid)
    const tier = useGetUserTier(userId)
    const [userData, setUserData] = useState("")
    const [activeTap, setActiveTap] = useState(1)
    const [PT, setPT] = useState([])
    const [SKT, setSKT] = useState([])
    const [CFT, setCFT] = useState([])

    const personalityTest = useGetQuestionnaireAnswerQuery(userId, "Personality Test")
    const softSkillsTest = useGetQuestionnaireAnswerQuery(userId, "Soft Skills Test")

    useEffect(() => {
        if (isSuccess) {
            setUserData(filterPreloadedUserData(data?.data?.data))
        }
    }, [isSuccess, data, isLoading])

    useEffect(() => {
        setPT([
            {
                "data": [
                    {
                        content: "Amiable:" + personalityTest?.data?.data?.data?.amiablePercent,
                        tip: Tips?.personalityTest?.amiable
                    },
                    {
                        content: "Analytical:" + personalityTest?.data?.data?.data?.analyticalPercent,
                        tip: Tips?.personalityTest?.analytical
                    },
                    {
                        content: "Driver:" + personalityTest?.data?.data?.data?.driverPercent,
                        tip: Tips?.personalityTest?.driver
                    },
                    {
                        content: "Expressive:" + personalityTest?.data?.data?.data?.expressivePercent,
                        tip: Tips?.personalityTest?.expressive
                    },
                ]
            },
            {"takeAssessment": (personalityTest?.data?.data?.data?.amiable === 0 && personalityTest?.data?.data?.data?.analytical === 0 && personalityTest?.data?.data?.data?.driver === 0 && personalityTest?.data?.data?.data?.expressive === 0)},
            {"title": "Personality Assessment"},
            {"testType": "Personality Test"}
        ])
        setSKT([
            {
                "data": [

                    {
                        content: "Rating:" + (softSkillsTest?.data?.data?.data?.totalQuestionAttempted === 0 ? "0 Star" : softSkillsTest?.data?.data?.data?.rating),
                    },
                    {
                        content: "Adaptability:" + (softSkillsTest?.data?.data?.data?.skills?.["Adaptability Test"] || '0') + "%",
                    },
                    {
                        content: "Communication:" + (softSkillsTest?.data?.data?.data?.skills?.["Communication Test"] || '0') + "%",
                    },
                    {
                        content: "Problem Solving:" + (softSkillsTest?.data?.data?.data?.skills?.["Problem Solving Test"] || '0') + "%",
                    },
                    {
                        content: "Relationship Building:" + (softSkillsTest?.data?.data?.data?.skills?.["Relationship Building Test"] || '0') + "%",
                    },

                ]
            },
            {
                "takeAssessment": (softSkillsTest?.data?.data?.data?.totalQuestionAttempted === 0)
            },
            {"title": "Soft Skills Assessment"},
            {"testType": "Soft Skills Test"}
        ])
        setCFT([
            {
                data: [
                    {
                        content: "Adhocracy:" + userData?.cultureFitCategory?.percentages?.Adhocracy + "%",
                        tip: Tips?.cultureFitTest?.adhocracy
                    },
                    {
                        content: "Clan:" + userData?.cultureFitCategory?.percentages?.Clan + "%",
                        tip: Tips?.cultureFitTest?.clan
                    },
                    {
                        content: "Hierarchy:" + userData?.cultureFitCategory?.percentages?.Hierarchy + "%",
                        tip: Tips?.cultureFitTest?.hierarchy
                    },
                    {
                        content: "Market:" + userData?.cultureFitCategory?.percentages?.Market + "%",
                        tip: Tips?.cultureFitTest?.market
                    },
                ],
            },
            {
                "takeAssessment": (userData?.cultureFitCategory?.maxCategory === "Not Yet")
            },
            {"title": "Culture Fitness Assessment"},
            {"testType": "Culture Fit Test"}
        ])

    }, [personalityTest?.data, softSkillsTest?.data, userData])

    if (isLoading && tier.isLoading) {
        return <ShowLoading/>
    }

    const handleTabSelection = (e, tabIndex) => {
        e.preventDefault()
        setActiveTap(tabIndex)
    }

    return <CContainer className="user-profile">

        {!user?.data?.isProfileCompleted && <CompleteProfileModal/>}

        <CRow className="mb-3">

            <CCol md={4}>
                {
                    user?.data?.isProfileCompleted ? <GoBack/> : ''
                }
            </CCol>


            <CCol md={8} className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <p>
                    <CImage src={ViewProfileIcon}/>{" "}
                    <a
                        href="/" className="text-small text-primary"
                        onClick={(e) => handleTabSelection(e, 5)}
                    >WORKNATION PROFILE</a>
                </p>
                <p>
                    <CImage src={EditProfileIcon}/>{" "}
                    <a href="/user/profile-update" className="text-small text-primary">EDIT PROFILE</a>
                    {" | "}
                    <a href="/user/change-password" className="text-small text-primary">CHANGE PASSWORD</a>
                </p>
            </CCol>
        </CRow>
        {isSuccess &&
            <CRow className="mb-5">
                <CCol lg={4} className="shadow p-3 mb-5 bg-white rounded">
                    <UserProfileCard
                        userData={userData}
                        tier={tier?.data?.data?.data.tier}
                    />
                </CCol>
                <CCol lg={8} className="mb-5">
                    <CNav variant={"underline"} className={'mb-3 d-flex flex-row justify-content-between'}>
                        <CNavItem>
                            <CNavLink
                                href="#"
                                onClick={(e) => handleTabSelection(e, 1)}
                                active={activeTap === 1}
                                className="profile-link-tab"
                            >BASIC INFORMATION</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                                href="#"
                                onClick={(e) => handleTabSelection(e, 2)}
                                active={activeTap === 2}
                                className="profile-link-tab"
                            >ASSESSMENT</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                                href="#"
                                onClick={(e) => handleTabSelection(e, 3)}
                                active={activeTap === 3}
                                className="profile-link-tab"
                            >PREFERENCES</CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                                href="#"
                                onClick={(e) => handleTabSelection(e, 4)}
                                active={activeTap === 4}
                                className="profile-link-tab"
                            >MY RESUME</CNavLink>
                        </CNavItem>
                    </CNav>
                    <div className="h-100 d-flex flex-column">
                        {activeTap === 1 && <CCol className="shadow p-3 h-100 bg-white rounded">
                            <UserProfileBasicInfo userProfileData={userData}/>
                            <UserProfilePersonalInfo userProfileData={userData}/>
                        </CCol>}
                        {activeTap === 2 && <CCol className="shadow p-3 h-100 bg-white rounded">
                            <ProfessionalSkills userProfileData={userData}/>
                            <SkillsTest skillsTest={user?.data?.quizTaken}/>
                            <UserProfileTestWithPercent data={PT}/>
                            <UserProfileTestWithPercent data={CFT}/>
                            <UserProfileTestWithRating data={SKT}/>
                        </CCol>}
                        {activeTap === 3 && <CCol className="shadow p-3 h-100 bg-white rounded">
                            <PreferencesSection1 userProfileData={userData}/>
                            <PreferencesSection2 userProfileData={userData}/>
                        </CCol>}
                        {activeTap === 4 && <CCol className="shadow p-3 h-100 bg-white rounded">
                            <CRow>
                                <CCol sm={12} lg={6}>
                                    <UploadResume userProfileData={userData}/>
                                </CCol>
                                <CCol sm={12} lg={6}>
                                    <CreateResume/>
                                </CCol>
                            </CRow>
                        </CCol>}
                        {activeTap === 5 && <CCol className="shadow p-3 h-100 bg-white rounded">
                            <CRow>
                                <CCol sm={12} lg={12}>
                                    <ApplicantProfile userData={userData}/>
                                </CCol>
                            </CRow>
                        </CCol>}
                    </div>
                </CCol>
            </CRow>
        }
    </CContainer>
}

export default Profile;
