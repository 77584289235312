import React from 'react';
import maleDP from "../../assets/brand/useravater.png";
import fMaleDP from "../../assets/brand/useravater.png";

function ProfilePic({passportUrl}) {
    const setDefaultSrc = (e) => {
        e.target.src = (passportUrl?.gender?.toLowerCase() === "m" || passportUrl?.gender?.toLowerCase() === "male") ? maleDP : fMaleDP
    }
    const DP = ()=>{
        return (passportUrl?.gender?.toLowerCase() === "m" || passportUrl?.gender?.toLowerCase() === "male") ? maleDP : fMaleDP
    }

    return (
        <>
            <div style={{borderRadius: "100%", border: '1px solid grey', height:"80px", width:"80px", maxHeight:"100%", maxWidth:"100%"}}>
                <img onError={(e)=>setDefaultSrc(e)} src={passportUrl?.passportUrl || DP()} id="DP" alt="Profile picture" style={{objectFit:"cover", borderRadius: "100%", border: '1px solid grey', height:"150px", width:"150px", maxHeight:"100%", maxWidth:"100%"}} className=" w-100 h-100 mb-2"/>
            </div>

        </>
    );
}

export default ProfilePic;
