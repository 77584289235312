import {
    CButton,
    CCol,
    CForm,
    CFormFeedback,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {fail_notify, success_notify} from "../../constant";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {personalSummarySchema,} from "../../form-schema/user-form-schema/userUpdateProfileSchema";
import {useUpdateUserQuery} from "../../hooks/user/userApiHooks";

const PersonalSummary = ({userData, tab, setTab}) => {

    const [skill, setSkill] = useState('')
    const [skills, setSkills] = useState(userData.professionalSkills || [])
    const {isError, isSuccess, isLoading: isLoadingUpdate, error, data, mutate} = useUpdateUserQuery()

    useEffect(() => {
        if (isSuccess) {
            success_notify('Successfully Updated')
            setTab(tab + 1)
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isError, error, isSuccess])

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(personalSummarySchema),
        defaultValues: {...userData}
    })

    const handleSkills = (e) => {
        e.preventDefault()
        if (skill.length === 0) return
        setSkills([...skills, skill])
        setValue('professionalSkills',skills)
        setSkill('')
    }

    const removeSkill = (index) => {
        setSkills(skills.filter((_, i) => i !== index));
        setValue('professionalSkills',skills)
    };

    const handleSignUp = (data) => {
        data.employmentType = [data.employmentType]
        mutate(data)
    }

    return (
        <CForm noValidate className="needs-validation w-100 update-profile-form"
               onSubmit={handleSubmit(handleSignUp)}>
           <div className="bg-white w-100 px-4 py-3 rounded-1">
               <CRow>
                   <small className="fw-bolder">Personal Summary</small>
                   <CCol md={12} className="my-1">
                       <CFormLabel className="m-1 label">Summary</CFormLabel>
                       <CFormTextarea
                           type="text"
                           id="summary"
                           className="form-control mb-2"
                           placeholder="Type here"
                           rows={3}
                           {...register('summary')}
                           invalid={!!errors.summary}
                       >
                       </CFormTextarea>
                       <CFormFeedback invalid={!!errors.summary}>{errors.summary?.message}</CFormFeedback>
                   </CCol>
               </CRow>
               <hr/>
               <CRow>
                   <small className="fw-bolder">Skills</small>

                   <CCol md={12} className="my-1">
                       <CFormLabel className="m-1 label">Professional Skills</CFormLabel>
                       <CFormInput
                           type="text"
                           id="professionalSkills"
                           className="form-control mb-2"
                           invalid={!!errors.professionalSkills}
                           disabled={skills.length >= 4}
                           value={skill}
                           onChange={(e) => setSkill(e.target.value)}
                       />
                       <div style={{fontSize: '14px'}} className={`${skills.length > 0 ? 'mb-1' : ''}`}>
                           <CButton disabled={skills.length >= 4} className="btn-sm text-white" onClick={handleSkills}>Add
                               Skill</CButton>
                           {
                               skills?.map((item, index) => (
                                   <span className="bg-light-green-2 mx-1 px-2 py-1 rounded-4" key={index}>
                                          {item} &nbsp;
                                       <svg onClick={() => removeSkill(index)} xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor" className="bi bi-x cursor-pointer text-red"
                                            viewBox="0 0 16 16">
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </span>
                               ))
                           }
                       </div>
                       <CFormFeedback
                           invalid={!!errors.professionalSkills}>{errors.professionalSkills?.message}</CFormFeedback>
                   </CCol>

               </CRow>
               <hr/>
               <CRow>
                   <small className="fw-bolder">Preference</small>

                   <CCol md={4} className="my-1">
                       <CFormLabel className="m-1 label">Career Level</CFormLabel>
                       <CFormSelect
                           id="careerLevel"
                           className="form-control mb-2"
                           {...register('careerLevel')}
                           invalid={!!errors.careerLevel}
                       >

                           <option value=""></option>
                           <option value="Beginner">Beginner</option>
                           <option value="Intermediate">Intermediate</option>
                           <option value="Professional">Professional</option>

                       </CFormSelect>
                       <CFormFeedback invalid={!!errors.careerLevel}>
                           {errors.careerLevel?.message}
                       </CFormFeedback>
                   </CCol>
                   <CCol md={4} className="my-1">

                       <CFormLabel className="m-1 label">Employment Type</CFormLabel>
                       <CFormSelect
                           id="jobPreference"
                           className="form-control mb-2"
                           invalid={!!errors.employmentType}
                           {...register('employmentType')}
                           onChange={(event) => {
                               const {value} = event.target;

                               setValue('employmentType', [value])
                           }}
                       >
                           <option value=""></option>
                           <option value="Full Time">Full Time</option>
                           <option value="Part Time">Part Time</option>

                       </CFormSelect>

                       <CFormFeedback invalid={!!errors.employmentType}>
                           {errors.employmentType?.message}
                       </CFormFeedback>
                   </CCol>
                   <CCol md={4} className="my-1">
                       <CFormLabel className="m-1 label">Job Preference</CFormLabel>
                       <CFormSelect
                           id="jobPreference"
                           className="form-control mb-2"
                           {...register('jobPreference')}
                           invalid={!!errors.jobPreference}
                       >
                           <option value=""></option>
                           <option value="Onsite">Onsite</option>
                           <option value="Remote">Remote</option>
                           <option value="Hybrid">Hybrid</option>

                       </CFormSelect>
                       <CFormFeedback invalid={!!errors.jobPreference}>
                           {errors.jobPreference?.message}
                       </CFormFeedback>
                   </CCol>
               </CRow>

               <CRow>
                   <CCol md={4} className="my-1">
                       <CFormLabel className="m-1 label">Availability</CFormLabel>
                       <CFormSelect
                           id="availability"
                           className="form-control mb-2"
                           {...register('availability')}
                           invalid={!!errors.availability}
                       >

                           <option value=""></option>
                           <option value="Immediately">Immediately</option>
                           <option value="1 week">1 week</option>
                           <option value="2 weeks">2 weeks</option>
                           <option value="1 month">1 month</option>
                           <option value="2 months">2 months</option>

                       </CFormSelect>
                       <CFormFeedback invalid={!!errors.availability}>
                           {errors.availability?.message}
                       </CFormFeedback>
                   </CCol>
               </CRow>

           </div>
            <center>

                <CCol md={6} lg={4}>
                    <CLoadingButton
                        type="submit"
                        className="text-white px-4 w-100 py-3 my-4"
                        loading={isLoadingUpdate}
                        disabled={isLoadingUpdate}
                    >
                        SAVE & CONTINUE
                    </CLoadingButton>
                </CCol>

            </center>

        </CForm>
    )
}

export default PersonalSummary
