import React, {useCallback, useEffect, useRef, useState} from 'react'
import {CButton, CCol, CContainer, CFormInput, CFormLabel, CFormSelect, CImage, CRow} from "@coreui/react-pro";
import {BiEnvelope, BiLeftArrow, BiRightArrow} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import DummyProfilePic from "../../assets/images/dummy-profile-pic.png"
import {useGetAllStates, useGetAllUsers, useGetInstitutions} from "../../hooks/user/userApiHooks";
import ShowLoading from "../../components/ShowLoading";
import appApiCalls from "../../services/api";
import {Link} from "react-router-dom";
import {useAppContext} from "../../context/user/UserAppProvider";
import {educationLevelOptions, formatNumber, removeFields} from "../../constant";
import {debounce} from "lodash";
import {isMobile} from "react-device-detect";
import {download, generateCsv, mkConfig} from "export-to-csv";
// import {adminAssessments} from "../../constant/adminAssessments";

const Applicants = () => {
    const {stakeholderRoles} = useAppContext()

    const isEmployer = stakeholderRoles?.includes('Employer') || stakeholderRoles?.includes('Government') || stakeholderRoles?.includes('Academy')

    const [usersData, setUsersData] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [searchTerm, setSearchTerm] = useState('')
    const [source, setRole] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [highestLevelOfEducation, setHighestLevelOfEducation] = useState('')
    const [stateOfOrigin, setStateOfOrigin] = useState('')
    const [stateOfResidence, setStateOfResidence] = useState('')
    const [careerLevel, setCareerLevel] = useState('')
    const [jobPreference, setJobPreference] = useState('')
    const [employmentType, setEmploymentType] = useState([])
    const [schoolAttended, setSchoolAttended] = useState("")
    const states = useGetAllStates()
    const {data: institutions} = useGetInstitutions()
    const debounceSearchHandler = useCallback(debounce(e => setSearchTerm(e.target.value), 600), [])
    let users = useRef(useGetAllUsers(page))
    const totalUsers = users.current?.data?.data?.meta?.totalItems
    const rolesOptions = ['NASIMS', 'User', 'NYSC', 'OG Academy']
    const csvConfig = mkConfig({useKeysAsHeaders: true});
    const [totalPageCount, setTotalPageCount] = useState(1)

    function timeAgo(date) {
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);

        let interval = Math.floor(seconds / 31536000);
        if (interval > 1) {
            return interval + " years ago";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months ago";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " days ago";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours ago";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    }



    useEffect(() => {
        setUsersData(users.current?.data?.data?.data?.items?.map((item, index) => {
            return {
                id: index,
                user: [item?.profile?.passportUrl, `${item?.firstName} ${item?.lastName}`, item?.userActivity],
                phoneNumber: item?.phoneNumber,
                email: item?.email,
                source: item?.role,
                registered: item?.createdAt.substring(0, 10),
                action: item?.userUuid
            }
        }))

    }, [])

    useEffect(() => {
        const updateGetUsers = async () => {
            users.current = await appApiCalls.getAllUsers(page, limit, searchTerm, source, from, to, stateOfOrigin, stateOfResidence, careerLevel, jobPreference, employmentType, highestLevelOfEducation, schoolAttended)
            if (!(users.current?.data?.data?.items)) return
            setTotalPageCount(users.current?.data?.data?.meta?.totalPages)
            setUsersData(users.current?.data?.data?.items?.map((item, index) => {
                return {
                    id: index,
                    user: [item?.profile?.passportUrl, `${item?.firstName} ${item?.lastName}`, item?.userActivity],
                    phoneNumber: item?.phoneNumber,
                    email: item?.email,
                    source: item?.role === 'User' ? 'WorkNation' : item?.role,
                    registered: item?.createdAt.substring(0, 10),
                    action: item?.userUuid
                }
            }))

        }
        updateGetUsers()

    }, [page, limit, searchTerm, stateOfOrigin, stateOfResidence, careerLevel, from, to, jobPreference, employmentType, source, highestLevelOfEducation, schoolAttended])

    const convertToDate = (date) => {
        const _date = new Date(Date.parse(date))
        return _date.toLocaleDateString()
    }

    const convertAction = (action) => {
        return <Link to={`/${isEmployer ? 'employer' : 'admin'}/applicant/${action}`}>
            <button className="btn btn-sm text-white btn-primary">View</button>
        </Link>
    }

    const setDefaultSrc = (e) => {
        e.target.src = DummyProfilePic
    }

    const convertUserDisplay = (user) => {

        return (
            <div className="d-flex flex-row justify-content-start align-items-center p-0">
                <div className="position-relative">
                    <CImage src={user[0] || DummyProfilePic} onError={(e) => setDefaultSrc(e)} alt="Profile" style={{
                        width: '32px',
                        height: '32px',
                        marginRight: '10px',
                        borderRadius: '50%',
                    }}/>
                <div className={`rounded-circle ${user[2]?.status ? "bg-primary" : "bg-grey-700"} position-absolute border border-2 border-white`} style={{width: '10px', height: '10px', left: 25, top: 25}}></div>
                </div>
                <p style={{marginBottom: "0"}}><b>{user[1]}</b></p>
            </div>
        )
    }

    const handleResetFilter = () => {
        setSearchTerm("")
        setFrom("")
        setTo("")
        setRole("")
        setHighestLevelOfEducation("")
        setStateOfOrigin("")
        setCareerLevel("")
        setJobPreference("")
        setEmploymentType([])
        setSchoolAttended("")

        // empty debounced fields and dropdown
        document.querySelector("#searchBy").value = ""
        const dropdown = [...document.querySelectorAll('select')]
        dropdown?.map((box) => {
            return box.selectedIndex = 0
        })
    }
    const exportUsersData = () => {
        const users_data = users.current?.data?.data?.items

        // Array of fields to remove
        const fieldsToRemove = ["id", "userUuid", "registrationCompleted", "marketingEmails", "profileSlugUrl", "emailVerified", "profile", "nPowerBatch", "nPowerNumber", "nPowerStream", "nawisNumber"];

        users_data.forEach(item => {
            removeFields(item, fieldsToRemove);
            item.skillSet = item?.skillSet?.[0]?.skills?.map(({title}) => {
                return title
            })?.join(', ')
            item.phoneNumber.toString()
        });

        const csv = generateCsv(csvConfig)(users_data);
        download(csvConfig)(csv);
    }

    return (
        <>
            {!usersData && <ShowLoading/>}
            {usersData &&
                <CContainer>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <h5>{`${totalUsers ? formatNumber(totalUsers) : 0} Total Applicants`}</h5>
                        {!isEmployer &&
                            <button className="btn btn-sm btn-primary text-white" onClick={exportUsersData}>Export Users
                                Data</button>}
                    </div>
                    {!isMobile && <div>
                        <div className="d-flex flex-row justify-content-end align-items-center">
                            <p
                                className="text-primary cursor-pointer"
                                onClick={handleResetFilter}
                            >Clear all</p>
                        </div>
                        <CRow>
                            <CCol md={4}>
                                <CFormLabel htmlFor="searchBy" className="m-1 label">Search</CFormLabel>
                                <CFormInput type="text" id="searchBy"
                                            placeholder="By email, phone number, first/last name."
                                            aria-describedby="searchByText"
                                            onChange={debounceSearchHandler}
                                            className="rounded-0"
                                />

                            </CCol>
                            <CCol md={4}>
                                <CFormLabel htmlFor="applicantSource" className="m-1 label">Role</CFormLabel>
                                <CFormSelect aria-label="Select applicant source" id="applicantSource"
                                             onChange={(e) => setRole(e.target.value)}
                                             className="rounded-0">
                                    <option value="">Select Role</option>
                                    {
                                        rolesOptions.map((item, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={item}
                                                    defaultValue={source === item ? item : ''}
                                                >
                                                    {item}
                                                </option>
                                            )
                                        })
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">Education Level</CFormLabel>
                                <CFormSelect
                                    id="educationLevel"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setHighestLevelOfEducation(e.target.value)}
                                >
                                    <option value="">Select Education Level</option>
                                    {
                                        educationLevelOptions?.map((option, index) => (
                                            <option
                                                value={option}
                                                key={index}
                                            >
                                                {option}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">School Attended</CFormLabel>
                                <CFormSelect
                                    id="schoolAttended"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setSchoolAttended(e.target.value)}
                                >
                                    <option value="">Select School Attended</option>
                                    {
                                        institutions?.data?.data.map(({name}, index) => {
                                            return (
                                                <option
                                                    key={index}
                                                    value={name}
                                                    defaultValue={schoolAttended === name ? name : ''}
                                                >
                                                    {name}
                                                </option>
                                            )
                                        })
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">Job Preference</CFormLabel>
                                <CFormSelect
                                    id="jobPreference"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setJobPreference(e.target.value)}
                                >
                                    <option value="">Select Job Preference</option>
                                    <option value="Remote">Remote</option>
                                    <option value="On site">On site</option>
                                    <option value="Hybrid">Hybrid</option>
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">Career Level</CFormLabel>
                                <CFormSelect
                                    id="careerLevel"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setCareerLevel(e.target.value)}
                                >
                                    <option value="">Select Career Level</option>
                                    <option value="Beginner">Beginner</option>
                                    <option value="Intermediate">Intermediate</option>
                                    <option value="Professional">Professional</option>
                                </CFormSelect>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">State of Origin</CFormLabel>
                                <CFormSelect
                                    id="stateOfResidence"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setStateOfOrigin(e.target.value)}
                                >
                                    <option value="">Select State</option>
                                    {
                                        states?.data?.data?.data?.map(({stateName}, index) => (
                                            <option
                                                value={stateName}
                                                key={index}
                                            >
                                                {stateName}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>
                                <CFormLabel className="m-1 label">State of Residence</CFormLabel>
                                <CFormSelect
                                    id="stateOfResidence"
                                    className="form-control mb-2 rounded-0"
                                    onChange={(e) => setStateOfResidence(e.target.value)}
                                >
                                    <option value="">Select State</option>
                                    {
                                        states?.data?.data?.data?.map(({stateName}, index) => (
                                            <option
                                                value={stateName}
                                                key={index}
                                            >
                                                {stateName}
                                            </option>
                                        ))
                                    }
                                </CFormSelect>
                            </CCol>
                            <CCol md={4}>

                                <CRow>
                                    <CCol md={6}>
                                        <CFormLabel className="m-1 label">Date From</CFormLabel>
                                        <CFormInput type="date" id="searchBy"
                                                    onChange={(e) => {
                                                        // console.log(e.target.value)
                                                        //return
                                                        setFrom(e.target.value)
                                                    }}
                                                    className="rounded-0"
                                        />
                                    </CCol>
                                    <CCol md={6}>
                                        <CFormLabel className="m-1 label">Date To</CFormLabel>
                                        <CFormInput type="date" id="searchBy"
                                                    onChange={(e) => {
                                                        // console.log(e.target.value)
                                                        //return
                                                        setTo(e.target.value)
                                                    }}
                                                    className="rounded-0"
                                        />
                                    </CCol>

                                </CRow>
                            </CCol>
                        </CRow>
                    </div>}
                    <CRow className="mt-3 mb-5">
                        <CCol md={12}>
                            <table className="table border-0" id="reports-table">
                                <thead className="thead-inverse">
                                <tr className="bg-transparent text-primary">
                                    <th>Applicant</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Registered</th>
                                    <th>Last Login</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    usersData?.map((item, index) => {
                                        return <tr key={index}>
                                            <td>{convertUserDisplay(item?.user)}</td>
                                            <td><BsTelephone/> {item?.phoneNumber}</td>
                                            <td><BiEnvelope/> {item?.email}</td>
                                            <td>{convertToDate(item?.registered)}</td>
                                            <td>{timeAgo(new Date(item?.user[2]?.lastLogin || item?.registered))}</td>
                                            <td>{convertAction(item?.action)}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </CCol>

                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-flex flex-row gap-3"}>
                                <div className={"m-2"}>Show</div>
                                <CFormSelect
                                    aria-label="Default select example"
                                    options={[
                                        {label: '10', value: '10'},
                                        {label: '25', value: '25'},
                                        {label: '100', value: '100'},
                                        {label: '200', value: '200'}
                                    ]}
                                    onChange={(e) => setLimit(e.target.value)}
                                />
                                <div className={"m-2"}>Entries</div>
                            </div>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                                         className={`mx-3 text-white py-1 pb-2 px-3`}>
                                    <BiLeftArrow/>
                                </CButton>

                                <CButton
                                    onClick={() => setPage(page + 1)}
                                    className="mx-3 text-white py-1 pb-2 px-3"
                                    disabled={page >= totalPageCount}
                                >
                                    <BiRightArrow/>

                                </CButton>
                            </div>
                        </div>
                    </CRow>
                </CContainer>
            }
        </>
    )
}

export default Applicants
