import React, {useCallback, useEffect, useState} from 'react';
import {CCol, CContainer, CForm, CFormInput, CFormText, CRow} from "@coreui/react-pro";
import {debounce} from "lodash"

import {useForm} from "react-hook-form";
import JobCardHeader from "./JobCardHeader";
import pointer from "../../assets/icons/pointer.svg"
import {useAppContext} from "../../context/user/UserAppProvider";


function BreadCrumb({title, desc, setSearch, setPage,}) {
    const [md, setMd] = useState(false)
    const {user,} = useAppContext()
    const {
        register,
        handleSubmit,
        formState: {errors},

    } = useForm({
        defaultValues: { search: ''},
    })

    const handleJobSearchFilter = (e) => {
        let filter = '';
        if (e.target.value) {
            filter = filter + "&search=" + e.target.value
        }
        setPage(1)
        setSearch(filter)
    }

    useEffect(() => {
        function handleResize() {
            const windowWidth = window.innerWidth;

            if (windowWidth < 1000) {
                setMd(false)
            } else {
                setMd(true)
            }
        }

        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const suggestedSearch = ["IT Support", "Software Developer", "Product Designer", "React Developer", "Java Developer", "Business Analyst"]

    const debouncedSearchChangeHandler = useCallback(debounce(handleJobSearchFilter, 2000), []);

    return (
        <div className="w-100">
            {!user?.accessToken ?
                <div className="position-relative mt-2 mt-md-4">
                    <div className="left-blur mb-hidden"></div>
                    <div className="right-blur mb-hidden"></div>
                    <CContainer>
                        <div>
                            <center>
                                <div className="job-card-header">
                                    <small className="text-primary bg-green-700 px-4 py-2 rounded-5">Africa’s No. 1 Job
                                        Seekers Platform</small>
                                    <h1 className="fw-bolder my-3">Few Minutes To &nbsp;
                                        <span className="border px-3 border-primary position-relative">
                                <div className="figma-1 position-absolute border border-primary bg-white"></div>
                                <div className="figma-2 position-absolute border border-primary bg-white"></div>
                                <div className="figma-3 position-absolute border border-primary bg-white"></div>
                                <div className="figma-4 position-absolute border border-primary bg-white"></div>
                                <div className="figma-5 position-absolute">
                                    <img src={pointer}/>
                                </div>
                                Finding
                            </span> Your <span className="text-primary">Desired</span> Job
                                    </h1>
                                    <p className="text-black-50">{desc}</p>
                                </div>

                            </center>
                        </div>

                        {md && <div className="d-flex justify-content-start  pt-4 gap-5">
                            <div className=" ">
                                <JobCardHeader/>
                            </div>

                            <div className="d-flex flex-wrap" style={{maxWidth: "485px"}}>

                                {suggestedSearch.map((search, index) => {
                                    return (
                                        <div key={index} className=" pt-1 pe-2">
                                            <small
                                                className="px-3 py-2 bg-grey rounded-2 text-black-50 cursor-pointer text-truncate">{search} &nbsp; &nbsp;
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                     fill="currentColor"
                                                     className="bi bi-search" viewBox="0 0 16 16">
                                                    <path
                                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                                </svg>
                                            </small>
                                        </div>
                                    )
                                })}

                            </div>

                        </div>}
                        <center>
                            <br/>
                            <CForm onSubmit={handleSubmit()}>
                                <div
                                    className="w-40 border-3 border-black border ps-2 rounded d-flex align-items-center">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>

                                    <CFormInput
                                        type="search"
                                        id="companyName"
                                        placeholder="Search"
                                        autoComplete="off"
                                        {...register('search')}
                                        className="bg-transparent border rounded-0 border-white border-0 shadow-none"
                                        onChange={debouncedSearchChangeHandler}
                                    />
                                </div>
                                <CFormText component="span" id="searchByText" className="">
                                    Search by title, location, level of experience, or salary
                                </CFormText>
                            </CForm>
                        </center>
                    </CContainer>
                </div>
                : <CContainer className="job-force w-100 bg-primary rounded-3">
                    <center className="w-100 text-white px-2 p-md-5 py-5">
                        <h1 className="text-1 fw-bold"> {title}</h1>
                        <small className="">{desc}</small>
                        <br/>
                        <br/>
                        <CForm onSubmit={handleSubmit()}>
                            <div className="w-40 border-white border ps-2 rounded d-flex align-items-center">

                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-search" viewBox="0 0 16 16">
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>

                                <CFormInput
                                    type="search"
                                    id="companyName"
                                    placeholder="Search"
                                    autoComplete="off"
                                    {...register('search')}
                                    className="bg-transparent text-white border rounded-0 border-white border-0 shadow-none"
                                    onChange={debouncedSearchChangeHandler}
                                />
                            </div>
                            <CFormText component="span" id="searchByText" className="text-white">
                                Search by title, location, level of experience, or salary
                            </CFormText>
                        </CForm>
                    </center>
                </CContainer>
            }
        </div>
    );
}

export default BreadCrumb;
