const adminloadedData = (adminData) => {
    return {
        createdAt: adminData?.createdAt,
        email: adminData?.email,
        phoneNumber: adminData?.phoneNumber,
        firstName: adminData?.firstName,
        lastName: adminData?.lastName,
        otherName: adminData?.otherName,
        adminRole: adminData?.adminRole,
        permissions: adminData?.permissions
    }
}

export default adminloadedData
