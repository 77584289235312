import React from 'react';
import {Controller} from "react-hook-form";
import RadioButton from "../../../components/buttons/RadioButton";
import DOMPurify from "dompurify";

function SingleChoice({question, romanNumerals, submitAnswer, control,index}) {
    return (
        <div className="ps-5">
            <Controller
                control={control}
                name={`question${question.i}`}
                rules={{required: true}}
                render={({
                             field: {onChange, onBlur, ref},
                         }) => (
                    <>
                        {
                            question?.selections &&
                            <div>
                                {
                                    question?.selections.map((item, i) =>
                                        <p key={i} className="pb-2">
                                            {
                                                `(${romanNumerals[i]}).  ${item}`
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        }
                        {
                            question.options.map((o, i) => (
                                <div key={i} className="d-flex gap-2 center-item">
                                    <RadioButton
                                        type="radio"
                                        id={`exampleRadios${index}${i}`}
                                        onBlur={onBlur} // notify when input is touched
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                            submitAnswer(question.id, o.id)
                                        }} // send value to hook form
                                        value={`${o.id}`}
                                        checked={`${question.answer}` === `${o.id}`}
                                        label=""
                                        inputRef={ref}
                                        autoFocus
                                    />
                                    <div
                                        dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(o?.text)}}>

                                    </div>
                                </div>
                            ))
                        }
                    </>
                )}
            />
        </div>
    );
}

export default SingleChoice;