import React from 'react';
import {sortTable} from "../../../../constant";
import {BiSort} from "react-icons/bi";
import {adminAssessments} from "../../../../constant/adminAssessments";

function QuestionnaireReportTable({quizType, personalityData, handleSelectUser, cultureFitData, softSkillsData}) {
    return (
        <>
            <table className="table border-0" id="reports-table">
                <thead className="thead-inverse">
                <tr className="bg-transparent text-primary">
                    <th onClick={() => sortTable("reports-table", 0)}>
                        Applicants
                        <BiSort size={20}/>
                    </th>
                    <th>Email</th>
                    <th>Phone</th>
                    {quizType === "Personality Test" && adminAssessments?.PersonalityTestCategory?.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                    {quizType === "Culture Fit Test" && adminAssessments?.CultureFitCategory?.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                    {quizType === "Soft Skills Test" && adminAssessments?.SoftSkillsCategory?.map((item, index) => (
                        <th key={index}>{item}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {quizType === "Personality Test" &&
                    personalityData?.data?.data?.items?.map((
                        {
                            user,
                            amiablePercent,
                            analyticalPercent,
                            driverPercent,
                            expressivePercent,
                            quizType,
                            passedPercent
                        }, index) => (
                        <tr key={index} style={{cursor: 'pointer'}}
                            onClick={() => handleSelectUser({
                                ...user,
                                quizType,
                                passedPercent
                            })}>
                            <td className="td-single-line fw-bolder">{user?.firstName} {user?.lastName}</td>
                            <td>{user?.email}</td>
                            <td>{user?.phoneNumber}</td>
                            <td>{driverPercent}%</td>
                            <td>{analyticalPercent}%</td>
                            <td>{amiablePercent}%</td>
                            <td>{expressivePercent}%</td>
                        </tr>
                    ))
                }
                {
                    quizType === "Culture Fit Test" &&
                    cultureFitData?.data?.data?.items?.map(({
                                                                user,
                                                                adhocracyPercent,
                                                                clanPercent,
                                                                hierarchyPercent,
                                                                marketPercent,
                                                                quizType,
                                                                passedPercent
                                                            }, index) => (
                        <tr key={index} style={{cursor: 'pointer'}}
                            onClick={() => handleSelectUser({
                                ...user,
                                quizType,
                                passedPercent
                            })}>
                            <td className="td-single-line fw-bolder">{user?.firstName} {user?.lastName}</td>
                            <td>{user?.email}</td>
                            <td>{user?.phoneNumber}</td>
                            <td>{clanPercent}%</td>
                            <td>{marketPercent}%</td>
                            <td>{adhocracyPercent}%</td>
                            <td>{hierarchyPercent}%</td>
                        </tr>
                    ))
                }
                {
                    quizType === "Soft Skills Test" &&
                    softSkillsData?.data?.data?.items?.map(({
                                                                user,
                                                                adaptabilityPercent,
                                                                communicationPercent,
                                                                problemSolvingPercent,
                                                                relationShipBuildingPercent,
                                                                quizType,
                                                                passedPercent
                                                            }, index) => (
                        <tr key={index} style={{cursor: 'pointer'}}
                            onClick={() => handleSelectUser({
                                ...user,
                                quizType: 'Soft Skills Test',
                                passedPercent
                            })}>
                            <td className="td-single-line fw-bolder">{user?.firstName} {user?.lastName}</td>
                            <td>{user?.email}</td>
                            <td>{user?.phoneNumber}</td>
                            <td>{communicationPercent + '%' || 'Nil'}</td>
                            <td>{problemSolvingPercent + '%' || 'Nil'}</td>
                            <td>{relationShipBuildingPercent + '%' || 'Nil'}</td>
                            <td>{adaptabilityPercent + '%' || 'Nil'}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </>
    );
}

export default QuestionnaireReportTable;