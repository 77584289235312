import React from 'react';
import Table from "../tables/table";
import {FaEdit, FaTrashAlt} from "react-icons/fa";
import {CButton, CFormSelect} from "@coreui/react-pro";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import ShowLoading from "../ShowLoading";
import DataNotFound from "../DataNotFound";


function AttendanceTable({headers, items, setPageTap, setProfileDetail, setPage, page, setLimit, isLoading, data, isError}) {

    return (
        <>

            <Table headers={headers}>
                {items?.map((item, index) => (
                    <tr className="cursor-pointer bg-white border"
                        key={index}
                        onClick={() => {
                            setProfileDetail(item)
                            setPageTap(1)
                        }}
                    >
                        {isLoading && <ShowLoading/> }

                        <>
                            <td className="td-single-line fw-light">{`${item?.user?.firstName}  ${item?.user?.lastName}`}</td>
                            <td className="fw-light">{item?.course}</td>
                            <td className="fw-light text-primary">{item?.clockIn}</td>
                            <td className="fw-light text-danger">{item?.clockOut}</td>
                            <td className="fw-light">{item?.totalHours}</td>
                            <td className="fw-light">
                                <div className="d-flex align-items-center h-100 mt-3"><small
                                    className={`border ${item?.status == "Absent" && "border-danger"}  ${item?.status == "Present" && "border-primary"} justify-content-center align-items-center d-flex`}
                                    style={{width: '70px', height: '20px'}}>{item?.status}</small></div>
                            </td>
                            <td className="fw-light">
                                <div className="d-flex align-items-center gap-3">
                                    <div><FaTrashAlt className="text-danger" size={15}/></div>
                                    <div><FaEdit className="text-black-50" size={15}/></div>
                                </div>
                            </td>
                        </>
                    </tr>
                ))}
            </Table>
            {(!isLoading && items?.length < 1) && <DataNotFound/>}
            {data?.data?.data?.meta?.itemCount && <div className={"d-flex justify-content-between flex-wrap gap-2 w-100"}>
                <div className={"d-flex flex-row gap-3"}>
                    <div className={"m-2"}>Show</div>
                    <CFormSelect
                        aria-label="Default select example"
                        options={[
                            {label: '10', value: '10'},
                            {label: '25', value: '25'},
                            {label: '100', value: '100'},
                            {label: '200', value: '200'}
                        ]}
                        onChange={(e) => setLimit(e.target.value)}
                    />
                    <div className={"m-2"}>Entries</div>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center">
                    <CButton disabled={page <= 1} onClick={() => setPage(page - 1)}
                             className={`mx-3 text-white py-1 pb-2 px-3`}>
                        <BiLeftArrow/>
                    </CButton>

                    <CButton
                        disabled={page === data?.data?.data?.meta?.totalPages || isLoading || isError}
                        onClick={() => setPage(page + 1)} className="mx-3 text-white py-1 pb-2 px-3">
                        <BiRightArrow/>
                    </CButton>
                </div>
            </div>}

        </>
    );
}

export default AttendanceTable;
