import React, {useEffect} from 'react';
import {useAppContext} from "../../../../context/user/UserAppProvider";
import {useGetJobStatQuery} from "../../../../hooks/employer/employerApiHooks";
import {fail_notify} from "../../../../constant";
import ShowLoading from "../../../../components/ShowLoading";
import StatisticCard from "../../../../components/employer/StatisticCard";
import {ReactComponent as PortFolio} from "../../../../assets/icons/portfolio.svg"
import {ReactComponent as Users} from "../../../../assets/icons/users.svg"
import {ReactComponent as ViewJobs} from "../../../../assets/icons/view_job.svg"
import {ReactComponent as HireSuccess} from "../../../../assets/icons/h_success.svg"
import EmployerJobList from "../../../../components/employer/Jobs/EmployerJobList";


function PrimaryAccountDashboard() {
    const {user} = useAppContext()

    const card_colors = ["bg-yellow", "bg-green-800", "bg-blue-200", "bg-blue-500"]
    const {isLoading, data: jobStat, error: errorJobStat, isError: isErrorJobStat} = useGetJobStatQuery()
    const stats = [
        {
            total: jobStat?.data?.data?.jobs,
            title: "Jobs Posted",
            icon: PortFolio,
            to: ''
        },
        {
            total: jobStat?.data?.data?.applicants,
            title: "Total Application Received",
            icon: Users,
            to: ''
        },
        {
            total: jobStat?.data?.data?.applicants,
            title: "Total views",
            icon: ViewJobs,
            to: ''
        },
        {
            total: jobStat?.data?.data?.hiringSuccess + "%",
            title: "Hiring Success",
            icon: HireSuccess,
            to: ''
        }
    ]

    useEffect(() => {
        if (isErrorJobStat) {
            fail_notify(errorJobStat?.response?.data?.message)
        }
    }, [isLoading, jobStat, errorJobStat, isErrorJobStat])


    if (isLoading) {
        return <ShowLoading/>
    }
    return <>
        <div className="">
            <div className="">
                <h5 className="text-2 fw-bolder ps-1">Welcome {user?.data?.name || ''}</h5>
                <hr/>
            </div>

            <div className="d-flex mb-4 justify-content-between w-100 gap-2 flex-wrap">
                {
                    stats?.map((stat, index) =>
                        <div className="mt-4 d-flex justify-content-center" key={index}>
                            <StatisticCard pinned={stat} index={index} width={12} card_colors={card_colors}/>
                        </div>
                    )
                }
            </div>

            <h5 className="text-3 fw-bolder ps-1">Active Job Posts</h5>
            <EmployerJobList/>
        </div>
    </>
}

export default PrimaryAccountDashboard;