import React from 'react';
import {CAlert} from "@coreui/react-pro";

function ErrorReports({error}) {
    return (
        <CAlert color="danger">
            {error?.response?.data?.message}
        </CAlert>
    );
}

export default ErrorReports;