import React, {useEffect, useState} from 'react'
import {CCol, CContainer, CForm, CFormFeedback, CFormInput, CLoadingButton, CRow,} from '@coreui/react-pro'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useNavigate} from 'react-router-dom'
import {ChangePasswordSchema} from "../form-schema/user-form-schema/changePasswordSchema";
import {fail_notify, success_notify} from "../constant";
import {useChangePasswordQuery} from "../hooks/user/userApiHooks";
import {useAppContext} from "../context/user/UserAppProvider";
import GoBack from "./GoBack";

const ChangePassword = () => {
    const [role, setRole] = useState('')
    const [uuid, setUuid] = useState('')

    const navigate = useNavigate()
    const {user} = useAppContext()
    const {isLoading, isSuccess, data, isError, error, mutate} = useChangePasswordQuery()

    useEffect(() => {
        setRole(user?.data?.role)
        setUuid(user?.data?.role === 'User' ? user?.data?.userUuid : user?.data?.stakeholderUuid)
    }, [user?.data])

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(ChangePasswordSchema),
    })

    useEffect(() => {
        if (isSuccess) {
            success_notify('Password Change Successful')
            navigate(`/login`, {
                replace: true,
            })
        }
        if (isError) {
            fail_notify(error?.response?.data?.message)
        }
    }, [data, isSuccess, isError, error, navigate])

    const onSubmit = (data) => mutate({data, uuid, role})

    return (
        <CContainer>
            <CRow className="d-flex flex-row justify-content-center align-items-center">
                <CCol md={5} className="mt-5">
                    <h1 className="text-center pb-4">Change Password</h1>
                    <CForm noValidate className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <div className="d-flex flex-column gap-3">
                            <CCol md={12}>
                                <CFormInput
                                    type="password"
                                    id="currentPassword"
                                    placeholder="Enter Current Password"
                                    {...register('currentPassword')}
                                    invalid={!!errors.currentPassword}
                                />
                                <CFormFeedback invalid={!!errors.currentPassword}>{errors.currentPassword?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormInput
                                    type="password"
                                    id="newPassword"
                                    placeholder="Enter New Password"
                                    {...register('newPassword')}
                                    invalid={!!errors.newPassword}
                                />
                                <CFormFeedback invalid={!!errors.newPassword}>{errors.newPassword?.message}</CFormFeedback>
                            </CCol>
                            <CCol md={12}>
                                <CFormInput
                                    type="password"
                                    id="confirmPassword"
                                    {...register('confirmPassword')}
                                    placeholder="Confirm New Password"
                                    invalid={!!errors.confirmPassword}
                                />
                                <CFormFeedback invalid={!!errors.confirmPassword}>
                                    {errors.confirmPassword?.message}
                                </CFormFeedback>
                            </CCol>
                        </div>
                        <CLoadingButton
                            type="submit"
                            className="reg-btn px-4 w-100 py-2 mt-5"
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            Change My Password
                        </CLoadingButton>
                        <CCol md={12} className="py-3">
                            <p className="text-warning text-center">Your will be logged out after password change. <br/>Log back in with your new password.</p>
                        </CCol>
                    </CForm>
                    <div className="d-flex justify-content-center align-items-center">
                        <GoBack/>
                    </div>
                </CCol>
            </CRow>

        </CContainer>
    )
}

export default ChangePassword
