import React, {useEffect, useState} from 'react';

function MultiStepLine({totalSteps, activeStep, passedSteps}) {
    const [lineWidth, setLineWidth] =useState("")
    useEffect(() => {
        const divElement = document.getElementsByClassName('step-list')[0];

        if (divElement) {
            const width = (divElement.offsetWidth/(totalSteps-1) -20);
            setLineWidth(width + "px")
        }
    }, [activeStep, totalSteps]);

    return (
        <>
            <div className="multi-step-line">
                <ul className="step-list">
                    {Array.from({ length: totalSteps }, (_, index) => {
                        const stepNumber = index + 1;
                        const isActive = stepNumber === activeStep;
                        const isPassed = passedSteps.includes(stepNumber);
                        const lineColor = isPassed ? '#28a745' : '#ccc';

                        let stepClassName = 'step';
                        if (isActive) stepClassName += ' active';
                        if (isPassed) stepClassName += ' passed';

                        return (
                            <li key={stepNumber} className={stepClassName}>
                                {stepNumber}
                                {index !== totalSteps - 1 && (
                                    <div className="line-connector" style={{ backgroundColor: lineColor,  width: lineWidth, }}>{stepNumber}</div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>

        </>
    );
}

export default MultiStepLine;
