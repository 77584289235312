import React, {useEffect, useState} from "react"
import {useAppContext} from "../../../context/user/UserAppProvider";
import {useGetEmployerByUUID} from "../../../hooks/user/userApiHooks";
import ShowLoading from "../../../components/ShowLoading";
import {CButton, CCol, CContainer, CRow} from "@coreui/react-pro";
import {ImPhone} from "react-icons/im";
import {GoLocation} from "react-icons/go";
import {BiEnvelope} from "react-icons/bi";
import {Link} from "react-router-dom";

const EmployerProfile = () => {
    const {user} = useAppContext()

    const {isLoading, isSuccess, data} = useGetEmployerByUUID(user?.data?.stakeholderUuid)
    const [employerData, setEmployerData] = useState("")
    const [logoError, setLogoError] = useState(false)


    useEffect(() => {
        if (isSuccess) {
            setEmployerData(data?.data?.data)
        }
    }, [isSuccess, data, isLoading])

    if (isLoading) {
        return <ShowLoading/>
    }

    return <CContainer className="user-profile">
        {isSuccess &&
            <CRow>
                <CCol lg={4} className="shadow p-3 mb-5 bg-white rounded">
                    <div className="my-3">
                        <h6 className="fw-bold">{`${employerData?.name}`}</h6>
                    </div>

                    <div className="d-flex">
                        <div className="d-flex w-100 flex-column">
                            <p className="text-truncate py-2">
                                <ImPhone size={14}/>
                                <span className="ps-1 text-truncate">
                                    {employerData?.phoneNumber || 'Nil'}
                                </span>
                            </p>

                            <p className="text-truncate py-2">
                                <GoLocation size={16}/>
                                <span className="ps-1 text-truncate">
                                    {employerData?.state || 'Nil'}
                                </span>
                            </p>

                            <p className="text-truncate py-2">Type:
                                <span className="ps-1 text-truncate">
                                    {employerData?.institutionsType || 'Nil'}
                                </span>
                            </p>
                        </div>
                        <div className="d-flex w-50 flex-column">
                            <p className="text-truncate w-100 py-2">
                                <BiEnvelope size={16}/>
                                <span className="ps-1 text-truncate w-100">
                                {`${employerData?.email || 'Nil'}`}
                            </span>
                            </p>
                            <p className="text-truncate w-100 py-2">
                                Status:
                                <span className="ps-1 text-truncate w-100">
                                    {`${employerData?.organizationStatus || 'Nil'}`}
                                </span>
                            </p>

                            <p className="text-truncate py-2">
                                Role:
                                <span className="ps-1 text-truncate">
                                    {employerData?.role || 'Nil'}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                        <Link to="/employer/profile-update">
                            <CButton className="text-white btn-md">
                                Edit Profile
                            </CButton>
                        </Link>
                        <Link to="/employer/change-password">
                            <CButton className="text-primary btn-md bg-transparent">
                                Change Password
                            </CButton>
                        </Link>
                    </div>
                </CCol>
                <CCol lg={8} className="mb-5">
                    <div className="h-100 d-flex flex-column">
                        <CCol className="shadow p-3 h-100 bg-white rounded">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="fw-bold">Basic Information</h6>

                            </div>
                            <hr/>
                            <div>
                                <h6 className="fw-bold">About Company</h6>
                                <p dangerouslySetInnerHTML={{__html: employerData?.aboutCompany}}></p>
                            </div>
                            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 3}} className="my-4">
                                <CCol>
                                    <h6 className="fw-bold">Company Address</h6>
                                    <p>{`${employerData?.address || 'Nil'}`}</p>
                                </CCol>
                                <CCol>
                                    <h6 className="fw-bold">LGA Located</h6>
                                    <p>{`${employerData?.localGovernment || 'Nil'}`}</p>
                                </CCol>
                                <CCol>
                                    <h6 className="fw-bold">Industry Type</h6>
                                    <p>{`${employerData?.industryType || 'Nil'}`}</p>
                                </CCol>
                            </CRow>
                            <CRow xs={{cols: 1}} sm={{cols: 2}} md={{cols: 2}} lg={{cols: 3}} className="my-4">

                                <CCol>
                                    <h6 className="fw-bold">Company Size</h6>
                                    <p>{`${employerData?.companySize || 'Nil'}`}</p>
                                </CCol>
                                <CCol>
                                    <h6 className="fw-bold">Logo</h6>
                                    {logoError ?
                                        <p>Nil</p> :
                                        <img src={employerData?.logoUrl} alt="Logo" className="w-50" onError={() => {
                                            setLogoError(true)
                                        }}/>
                                    }

                                </CCol>
                            </CRow>
                        </CCol>
                    </div>
                </CCol>
            </CRow>
        }
    </CContainer>
}

export default EmployerProfile
