import React from 'react';
import {CLoadingButton} from "@coreui/react-pro";
import {Link} from "react-router-dom";
import SaveJob from "../../job/SaveJob";
import moment from "moment/moment";
import logo from "../../../assets/brand/WN-logo.svg";
import {MdLocationOn} from 'react-icons/md'
import {BsBriefcase} from 'react-icons/bs'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {BiTime, BiMoney} from 'react-icons/bi'
import jobLocation from '../../../assets/icons/maps-home-work.svg'

function JobRecommendation({job}) {
    const setDefaultSrc = (e) => {
        e.target.src = logo
    }
    return (
        <div className=" w-100 p-1 text-black rounded-2 bg-white">
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="d-flex">
                    <div className="me-1 d-flex rounded-2 p-1 squaredBg bg-green-700
                    justify-content-center align-content-center flex-wrap me-3">
                        <img onError={(e) => setDefaultSrc(e)} src={job?.stakeholder?.logoUrl || logo}
                             className="employer-logo" alt="Company image"/>
                    </div>
                    <div className="w-100 d-flex flex-column justify-content-between ">
                        <div className="">
                            <div className="fw-bold job-title ">{job?.title}</div>
                            <div className="job-body mt-0 pt-0 pb-none text-wn-10">{job?.stakeholder?.name} </div>
                            <div className={'d-flex flex-row gap-1 text-wn-8'}>
                                <div className="job-body text-wn-10">
                                    <BiMoney/>
                                    {` ` + job?.salary}
                                </div>
                                <div className="job-body text-wn-10">
                                    <BsBriefcase/>
                                    {` ` + job?.levelOfExperience}
                                </div>
                                <div className={'job-body text-wn-10'}>

                                    <MdLocationOn/>
                                    {
                                        ` ` + job?.location
                                    }
                                </div>
                                <div className={'job-body text-wn-10'}>
                                    <BiTime/>
                                    {` `}
                                    Posted {moment(job.createdAt).startOf('day').fromNow()}
                                </div>
                            </div>

                        </div>
                        <div className="d-flex flex-column">
                            <small className="job-body d-flex ">
                                {
                                    job?.tags.map((tag, id) =>
                                        <span key={id} className={'me-3 rounded-2 bg-grey px-2 py-1 text-wn-8'}> {tag} </span>
                                    )
                                }
                            </small>
                        </div>

                    </div>


                </div>

                <div className="d-flex flex-md-column justify-content-between align-items-end px-3 py-2">
                    <div className={'d-flex flex-row gap-2'}>
                        <AiOutlineInfoCircle/>
                        <SaveJob job={job}/>
                        <div className={'job-body text-wn-10'}>
                            <img src={jobLocation} width={15} height={15} alt={'jobIcon'}/>
                            {
                                ` ` + job?.jobType
                            }
                        </div>

                    </div>


                    <div className="d-flex align-content-cent">
                        <Link to={`/user/job-detail/${job?.slugUrl}`} className="text-decoration-none">
                            <CLoadingButton type="button" className="text-white apply-job-btn">Apply for Job</CLoadingButton>
                        </Link>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default JobRecommendation;
