import {CImage} from "@coreui/react-pro";
import NoRecordImage from "../assets/images/no-data-found.svg";
import React from "react";

const DataNotFound = () => {
    return <div className="d-flex flex-column justify-content-center align-items-center py-5">
        <CImage src={NoRecordImage} alt="No record found" className="w-25"/>
        <h4 className="py-3">No Record Found!</h4>
    </div>;
}

export default DataNotFound