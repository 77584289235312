import React from 'react'
import {CContainer,} from "@coreui/react-pro";

const NoTestOnMobileModal = () => {

    return (

        <CContainer className=" font-color  d-flex justify-content-center align-items-center">
            <div
                className=" ">
                {
                    ` Please Login into a Computer to take the Test`
                }
            </div>
        </CContainer>

    )
}

export default NoTestOnMobileModal
