import React, {useState} from 'react';
import {CCol, CContainer, CRow} from "@coreui/react-pro";
import TrainerDashboard from "./TrainerDashboard";
import CohortDashboard from "./CohortDashboard";
import BeneficiariesDashboard from "./BeneficiariesDashboard";

const KPIManager = () => {
    const [view, setView] = useState(1);
    const [cohortModalVisible, setCohortModalVisible] = useState(false)
    const [trainerModalVisible, setTrainerModalVisible] = useState(false)

    return <CContainer>
        <CRow>
            <CCol md={12}
                  className="d-flex flex-column justify-content-center text-white border-radius-12 px-5 kpi-banner">
                <h3>Welcome to your KPI Manager</h3>
                <p>This is where you can add trainers, assign KPI’s, and view list of all trainers and KPI’s added</p>
            </CCol>
        </CRow>
        <CRow className="py-5">
            <CCol md={12}>
                <button
                    className={`btn ${view === 1 ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2 ms-2`}
                    onClick={() => setView(1)}>Cohorts
                </button>
                <button
                    className={`btn ${view === 2 ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2`}
                    onClick={() => setView(2)}>Trainers
                </button>
                <button
                    className={`btn ${view === 3 ? 'btn-primary text-white' : 'btn-transparent text-primary'} px-4 py-2`}
                    onClick={() => setView(3)}>Beneficiaries
                </button>
            </CCol>
        </CRow>
        <CContainer>
            {view === 1 && <CohortDashboard
                setTrainerModalVisible={setTrainerModalVisible}
                cohortModalVisible={cohortModalVisible}
                setCohortModalVisible={setCohortModalVisible}
                trainerModalVisible={trainerModalVisible}
            />}
            {view === 2 && <TrainerDashboard
                setTrainerModalVisible={setTrainerModalVisible}
                cohortModalVisible={cohortModalVisible}
                setCohortModalVisible={setCohortModalVisible}
                trainerModalVisible={trainerModalVisible}
            />}
            {view === 3 && <BeneficiariesDashboard
                setTrainerModalVisible={setTrainerModalVisible}
                cohortModalVisible={cohortModalVisible}
                setCohortModalVisible={setCohortModalVisible}
                trainerModalVisible={trainerModalVisible}
            />}
        </CContainer>
    </CContainer>
}

export default KPIManager;