import axios from 'axios'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import {deleteAdmin, deleteUser, retrieveLoggedInUser} from '../localStorage'
import {getApiEndpoint} from '../Environment.helpers'

axios.defaults.maxContentLength = 1000 * 1024 * 1024;

const apiClient = axios.create({
    baseURL: getApiEndpoint(),
    headers: {
        Accept: 'application/json',
    },
})
apiClient.interceptors.request.use(function (config) {
    const user = retrieveLoggedInUser()
    config.headers.Authorization = user ? `Bearer ${user?.accessToken}` : ''

    return config
})

apiClient.interceptors.request.use((config) => {
    Nprogress.start()
    return config
})
apiClient.interceptors.response.use(
    (response) => {
        Nprogress.done()
        return response
    },
    (error) => {
        Nprogress.done()
        if (error.response?.status === 403) {
            localStorage.clear()
            // window.location.reload()
        }
        return Promise.reject(error)
    },
)
apiClient.interceptors.response.use(
    (response) => {
        Nprogress.done()
        return response
    },
    (error) => {

        Nprogress.done()
        if (error?.response?.data?.statusCode === 401) {
            const userType = window?.location?.pathname.split('/')[1]

            if (userType === 'user') {
                deleteUser()
                localStorage.clear()
                window.location.href = '/login';

            } else {
                deleteAdmin()
                localStorage.clear()
                window.location.href = '/admin/login';

            }
        }
        return Promise.reject(error)
    },
)
export {apiClient}
