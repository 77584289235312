import React, {useEffect, useState} from 'react';
import {CButton, CContainer, CModal, CModalBody, CModalTitle, CSpinner} from "@coreui/react-pro";
import {useGetQuestionnaireAnswerQuery} from "../../hooks/user/userApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import ErrorReports from "../ErrorReports";

function ScoresModal({visible, setVisible, title}) {
    const {userId} = useAppContext()
    const [showScore, setShowScore] = useState(false)
    const {
        isError, isLoading, data, isSuccess, error
    } = useGetQuestionnaireAnswerQuery(userId, title)

    useEffect(() => {
        if (isSuccess) {
            setShowScore(true)
        }
    }, [isSuccess])
    //console.log(data);
    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
                {
                    isError &&
                    <ErrorReports error={error}/>
                }
                {
                    !isLoading && isSuccess ?
                        <CModalBody className="text-center">

                            <CModalTitle className="text-title mb-2"> {title.toUpperCase()}
                                &nbsp; RESULT </CModalTitle>
                            <p>
                                You Scored
                            </p>
                            <p className="scores">
                                {data?.data?.data?.passedPercent}
                            </p>
                            <CButton color="primary" onClick={() => setVisible(false)} className="text-white">
                                Close
                            </CButton>


                        </CModalBody>
                        : <CModalBody>
                            <CContainer className=" font-color vh-50 d-flex justify-content-center align-items-center">
                                <div
                                    className=" ">
                                    <div><CSpinner variant="grow"/></div>
                                </div>
                            </CContainer>
                        </CModalBody>

                }

            </CModal>
        </>
    )
}

export default ScoresModal;
