import React from "react";

const ApplicantsStatCard = ({title, description, stat, color = '', handleAssessmentQuery}) => {
    return <div
        className="d-flex flex-row justify-content-around align-items-center px-3 shadow-md border-radius-12 cursor-pointer applicant-stat-card"
        onClick={() => handleAssessmentQuery(title)}
    >
        <div>
            <h6 className={`m-0 ${color}`}><strong>{title}</strong></h6>
            <p className="gray-paragraph">{description}</p>
        </div>
        <div>
            <h5 className={`w-auto p-1 bg-grey ${color}`}><strong>{stat}</strong></h5>
        </div>
    </div>;
}

export default ApplicantsStatCard;
