import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichTextEditorForCv({editorState, descriptionId, setEditorState}) {
    const getcvDesc = editorState[descriptionId]?.description || " "
    const [cvDesc, setCvDesc] = useState(getcvDesc)
    const handleEditorChange = (descriptionId, content) => {
        const updatedEducation = [...editorState];
        updatedEducation[descriptionId].description = content;
        setEditorState(updatedEducation);
    };
    const toolbarOptions = [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean']
    ];
    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <>
            {
                <ReactQuill
                    defaultValue={cvDesc}
                    onChange={(content) => handleEditorChange(descriptionId, content)}
                    theme="snow"
                    modules={modules}
                />
            }
        </>
    );
}

export default RichTextEditorForCv;

