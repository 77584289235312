import React from 'react';
import {CButton, CCol} from "@coreui/react-pro";
import cvIcon from "../../../assets/images/cv-icon.svg";
import {useNavigate} from "react-router-dom";

function CvBuilderAnnouncement() {
    const navigate = useNavigate()

    return (

        <div className="cv-builder-announcement mx-auto text-white py-4 mb-4 mt-3">
            <div className={'position-relative'}>
                <div>
                    <h5>
                        Introducing Our CV Builder
                    </h5>
                    <p className="text-small">
                        Our cutting-edge CV Generator is here to revolutionize your job application process.
                    </p>
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <div className={'mt-4'}>
                        <CButton
                            className={`fw-normal rounded-2 px-3 py-2 border-0 text-white bg-primary mt-2`}
                            onClick={() => {
                                navigate(`/user/resume-builder`, {
                                    replace: true,
                                })
                            }}
                        >
                            Create/View CV
                        </CButton>
                    </div>
                    <CCol sm={3} className=" d-flex justify-content-end">
                        <img src={cvIcon} width={150} height={80} alt={""}/>
                    </CCol>
                </div>

            </div>
        </div>

    );
}

export default CvBuilderAnnouncement;