import React from 'react'
import {
  CButton,
  CModal,
  CModalTitle,
  CModalBody,
} from "@coreui/react-pro";
import {Link, useNavigate} from "react-router-dom";
import good from "../../assets/icons/good.jpg";
import {useAppContext} from "../../context/user/UserAppProvider";

const TestCompleteModal = ({visible, setVisible, title, role}) => {
  const {user, storeUser} = useAppContext()
  const navigate = useNavigate()

  return (
    <>
      <CModal alignment="center" visible={visible} onClose={() => setVisible(false)} backdrop="static" >
        <CModalBody className="text-center">
          <img src={good} height={70} alt="MOG"/>
          {title == "Level One Questionnaire" ?
            <>
              <CModalTitle className="text-title mb-2"> {title} Completed</CModalTitle>
              <p>
                Thank you for taking the General Questionnaire Test. Please proceed to the Career Selection Path.
              </p>
                <CButton className="text-white" onClick={()=>{
                  let updatedObject = {...user}
                  updatedObject.data.quizTaken = ["Level One Questionnaire"]
                  storeUser(updatedObject)
                  if(role === "NASIMS"){
                    navigate(`/user/questionnaire/Quality-Assurance`, {
                      replace: true,
                    })
                  }
                  else {
                    navigate(`/user/choose-skill-set`, {
                      replace: true,
                    })
                  }
                }}>Proceed</CButton>
            </>:
              <>
                <CModalTitle className="text-title mb-2"> {title} Completed </CModalTitle>
                <p>
                  Thank you for taking the {title}. You can view your assessment in your profile.
                </p>
                <Link to={'/user/dashboard'}>
                  <CButton className="text-white">Proceed</CButton>
                </Link>
              </>
          }
        </CModalBody>
      </CModal>
    </>
  )
}

export default TestCompleteModal
