import React from 'react'
import {CButton, CContainer, CModal, CModalBody, CSpinner,} from "@coreui/react-pro";
import {useGetQuestionnaireAnswerQuery} from "../../hooks/user/userApiHooks";
import {useAppContext} from "../../context/user/UserAppProvider";
import good from "../../assets/icons/good.jpg";

const TestCompleteModalOnTimer = ({visible, setVisible, title}) => {
    const {userId} = useAppContext()
    const {
        isLoading, data, isSuccess
    } = useGetQuestionnaireAnswerQuery(userId, title)

    const handleRedirect = () => {
        window.location.replace('/user/dashboard')
    }
    return (
        <>
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)} backdrop="static">
                {
                    !isLoading && isSuccess ?
                        <CModalBody className="text-center">
                            <img src={good} height={70} alt="MOG"/>
                            {
                                data?.data?.data?.passedPercent ?
                                    <p>
                                        {

                                            ` Thank you for taking the test, 
                                      you scored ${data?.data?.data?.passedPercent}.
                                    You can attempt more tests subsequently in
                                     the test section of your dashboard.`
                                        }
                                    </p> :
                                    <p>
                                        {

                                            ` Thank you for taking the test.
                                    You can attempt more tests subsequently in
                                     the test section of your dashboard.`
                                        }
                                    </p>

                            }

                            <CButton className="text-white" onClick={() => handleRedirect()}>Proceed</CButton>

                        </CModalBody>
                        :
                        <CModalBody>
                            <CContainer className=" font-color vh-50 d-flex justify-content-center align-items-center">
                                <div
                                    className=" ">
                                    <div><CSpinner variant="grow"/></div>
                                </div>
                            </CContainer>
                        </CModalBody>
                }

            </CModal>
        </>
    )
}

export default TestCompleteModalOnTimer
