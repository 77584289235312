import React, {useCallback, useEffect, useState} from 'react';
import {CButton, CFormCheck, CFormInput, CFormSelect, CLoadingButton} from "@coreui/react-pro";
import Table from "../../tables/table";
import {useGetEmployersJobs, useJobStatusUpdate} from "../../../hooks/employer/employerApiHooks";
import {useNavigate} from "react-router-dom";
import DataNotFound from "../../DataNotFound";
import {fail_notify, success_notify} from "../../../constant";
import {FaAngleDown} from "react-icons/fa";
import {ReactComponent as JobSearch} from "../../../assets/icons/jobSearch.svg"
import {ReactComponent as JobType} from "../../../assets/icons/jobType.svg"
import {ReactComponent as JobStatus} from "../../../assets/icons/jobStatus.svg"
import {debounce} from "lodash";
import {useForm} from "react-hook-form";
import {BiLeftArrow, BiRightArrow} from "react-icons/bi";
import ShowLoading from "../../ShowLoading";

function EmployerJobList() {
    const [jobPage, setJobPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [filter, setFilter] = useState(null)

    const [jobId, setJobId] = useState(null)
    const [dropDown, setDropDown] = useState(false)

    let employerJobs = useGetEmployersJobs(filter)
    let updateStatus = useJobStatusUpdate(filter, jobId)
    const navigate = useNavigate()

    const {
        register,
        watch,
        setValue

    } = useForm({})


    useEffect(() => {

        setFilter(filterQuery())
    }, [])

    useEffect(() => {

        if (employerJobs?.isError) {
            fail_notify(employerJobs?.error?.response?.data?.message)
        }
    }, [employerJobs?.isSuccess, employerJobs?.data, employerJobs?.isLoading, employerJobs?.isError])

    useEffect(() => {

        if (updateStatus?.isError) {
            fail_notify(updateStatus?.error?.response?.data?.message)
        }
        if (updateStatus?.isSuccess) {
            success_notify('Successful')
        }
    }, [updateStatus?.isSuccess, updateStatus?.data, updateStatus?.isLoading, updateStatus?.isError, updateStatus?.mutate])

    const filterQuery = () => {
        let filter = `limit=${limit}&page=${jobPage}${watch("search") && "&search=" + watch("search")}${watch("jobType") && "&jobType=" + watch("jobType")}${watch("isActive") && "&isActive=" + watch("isActive")}`
        return filter
    }
    const handleJobSearchFilter = (e) => {
        setValue(e.target.name,e.target.value)
        setFilter(filterQuery())
    }

    const debouncedSearchChangeHandler = useCallback(debounce(handleJobSearchFilter, 2000), []);


    const headers = [
        {name: "Date Posted"},
        {name: "Job"},
        {name: "Applicants"},
        {name: "Shortlisted"},
        {name: "Rejected"},
        {name: "Closing date"},
        {name: "Status"},
    ]
    const dateFormat = (dateString) => {
        const dateObject = new Date(dateString);

        // Format the date object to MM/DD/YYYY format
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        return formattedDate;
    }
    const jobDetail = (jobId) => {

        navigate(`/employer/job-listing/${jobId}`, {
            replace: false,
        })
    }
    const handleStatus = (status) => {
        //console.log(status)
        setDropDown(false)
        updateStatus?.mutate({"status": status})
    }
    return (
        <>
            <div className="d-flex mb-4 gap-3 flex-wrap flex-sm-nowrap">
                <div className="w-100 border border-2 ps-2 rounded d-flex align-items-center">

                    <JobSearch />

                    <CFormInput
                        type="text"
                        name="search"
                        placeholder="Search"
                        {...register('search')}
                        className="bg-transparent text-black border rounded-0 border-white border-0 shadow-none"
                        onChange={debouncedSearchChangeHandler}
                    />
                </div>
                <div className="w-100 border border-2 ps-2 rounded d-flex align-items-center">

                    <JobStatus/>
                    <CFormSelect
                        name="jobType"
                        {...register('jobType')}
                        onClick={handleJobSearchFilter}
                        className="bg-transparent text-black border rounded-0 border-white border-0 shadow-none"
                    >
                        <option value="" selected>Job Type</option>
                        <option value="Remote">Remote</option>
                        <option value="OnSite">OnSite</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Part Time">Part Time</option>
                        <option value="Internship">Internship</option>
                    </CFormSelect>

                </div>
                <div className="w-100 border border-2 ps-2 rounded d-flex align-items-center">

                    <JobType />

                    <CFormSelect
                        name="isActive"
                        {...register('isActive')}
                        onClick={handleJobSearchFilter}
                        className="bg-transparent text-black border rounded-0 border-white border-0 shadow-none"
                    >
                        <option value="" selected>Status</option>
                        <option value="true">Open</option>
                        <option value="false">Close</option>

                    </CFormSelect>
                </div>
            </div>

            {employerJobs?.data?.data?.data?.items?.length > 0 ?
                <>
                <Table checkBox={true} headers={headers}>
                {employerJobs?.data?.data?.data?.items.map((item, index) => (
                    <tr className="bg-white border cursor-pointer" key={index}

                    >
                        <td className="td-single-line fw-medium">
                            <CFormCheck id="flexCheckDefault" label=""/>
                        </td>

                        <td onClick={() => jobDetail(item?.id)} className="fw-medium">{dateFormat(item?.createdAt)}</td>
                        <td onClick={() => jobDetail(item?.id)} className="fw-medium">{item?.title}</td>
                        <td onClick={() => jobDetail(item?.id)} className="fw-medium">{item?.applyForJob?.length}</td>
                        <td onClick={() => jobDetail(item?.id)} className="fw-medium">{item?.shortlist?.length}</td>
                        <td onClick={() => jobDetail(item?.id)}
                            className="fw-medium text-danger">{item?.applyForJob?.length - item?.shortlist?.length}</td>
                        <td onClick={() => jobDetail(item?.id)}
                            className="fw-medium">{dateFormat(item?.closingDate)}</td>
                        <td className="fw-medium">
                            <center className="">
                                <CLoadingButton loading={updateStatus?.isLoading && jobId === item?.id} onClick={() => {
                                    setJobId(item?.id)
                                    setDropDown(!dropDown)
                                }}
                                       className={`${item?.isActive ? "bg-blue-500" : "bg-danger"} btn-sm rounded rounded-0 text-white`}>{item?.isActive ? "Open" : "Close"}
                                    &nbsp; {!(updateStatus?.isLoading && jobId === item?.id) && <FaAngleDown/>}
                                </CLoadingButton>
                                {(dropDown && jobId === item?.id) &&
                                    <div style={{minWidth: "150px", right: "5px", zIndex:"1000"}}
                                         className="position-absolute rounded shadow p-1 shadow-md mt-1 bg-white">
                                        <div onClick={() => {
                                            if(item?.isActive){
                                                return
                                            }
                                            handleStatus(true)
                                        }}>Open</div>
                                        <div onClick={() => {
                                            if(!item?.isActive){
                                                return
                                            }
                                            handleStatus(false)
                                        }}>Close
                                        </div>
                                    </div>}
                            </center>
                        </td>
                    </tr>
                ))}
            </Table>
                    <div className={"d-flex justify-content-between pb-3"}>
                        <div className="d-flex align-items-center gap-1">
                            <small className={""}>Items per page:</small>

                            <div><CFormSelect
                                aria-label="Default select example"
                                value={limit}
                                options={[
                                    {label: '5', value: '5'},
                                    {label: '10', value: '10'},
                                    {label: '20', value: '20'},
                                    {label: '50', value: '50'},
                                    {label: '100', value: '100'}
                                ]}
                                onChange={(e) => setLimit(e.target.value)}
                            /></div>
                        </div>
                        <div className="d-flex  flex-row justify-content-center align-items-center">
                            <CButton disabled={jobPage <= 1} onClick={() => setJobPage(jobPage - 1)}
                                     className={`mx-3 bg-white text-primary py-1 pb-2 px-3`}>
                                <BiLeftArrow/>
                            </CButton>

                            <CButton
                                disabled={jobPage === employerJobs?.data?.data?.data?.meta?.totalPages || employerJobs?.isLoading || employerJobs?.isError}
                                onClick={() => setJobPage(jobPage + 1)}
                                className="mx-3 bg-white text-primary py-1 pb-2 px-3">
                                <BiRightArrow/>
                            </CButton>
                        </div>
                    </div>
                </>
                : <>{employerJobs?.isLoading ? <ShowLoading/> : <DataNotFound/>}</>}
        </>
    );
}

export default EmployerJobList;



