import { AES } from 'crypto-js';
import { enc } from 'crypto-js';
export const encryptText = (text, password) => {
    const encrypted = AES.encrypt(text, password).toString();
    return encrypted;
}
export const decryptText = (encryptedText = null, password = null) => {
    if (!encryptedText || !password){
        return ""
    }
    const decrypted = AES.decrypt(encryptedText, password).toString(enc.Utf8);
    return decrypted;
};
